import { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";
import axios from "axios";
import BaseMultiStepForm from "../../../../components/base-multi-step-form/base-multi-step-form";
import {
  BaseMultiStepConfigToFormModel,
  BaseMultiStepCustomFunctionLibrary,
} from "../../../../components/base-multi-step-form/base-multi-step-form-utils";
import { CCType } from "../../rental/utils";
import { DocumentsService } from "../../../../services/hrmnet-api";
import {
  MESSAGE_SEVERITY,
  RESPONSE_MESSAGE_ERROR,
} from "../../../../constants";
import { store } from "../../../../redux/store";
import { isResponseOk } from "../../../../utils/utils";
import { scrollToErrorControl } from "../../../../components/base-form/utils";

const DocumentUploadSection = (
  t,
  loading = true,
  isFolderSelected = false,
  isDocumentTypeSelected = false,
  fileUploadHints = undefined
) => {
  return [
    {
      defaultExpanded: true,
      displayLayout: 2,
      displaySequence: 1,
      id: 100,
      isDefaultHide: false,
      fields: [
        {
          controlType: CCType.DROPDOWN_LIST,
          dataType: "string",
          fieldLabel: t("documentUpload_form_folderId_label"),
          fieldName: "folderId",
          inputName: "folderId",
          isAllowNull: false,
          isDefaultHide: false,
          isDetailView: false,
          isEditable: !loading,
          isElasticData: false,
          isHideLabelField: false,
          isHtmlField: false,
          isSpecialField: false,
          length: -1,
          storageDataType: "String",
          styleClass: "p-lg-12 p-md-12 p-sm-12",
          translationKey: "documentUpload_form_folderId_label",
          viewSectionId: 100,
          controlStyle: `${loading && !isFolderSelected && "loading"}`,
        },
        {
          controlType: CCType.DROPDOWN_LIST,
          dataType: "string",
          fieldLabel: t("documentUpload_form_documentType_label"),
          fieldName: "documentType",
          inputName: "documentType",
          isAllowNull: false,
          isDefaultHide: false,
          isDetailView: false,
          isEditable: !loading && isFolderSelected,
          isElasticData: false,
          isHideLabelField: false,
          isHtmlField: false,
          isSpecialField: false,
          length: -1,
          storageDataType: "String",
          styleClass: "p-lg-12 p-md-12 p-sm-12",
          translationKey: "documentUpload_form_documentType_label",
          viewSectionId: 100,
          config: {
            display: "chip",
          },
          controlStyle: `${
            loading && !isDocumentTypeSelected && isFolderSelected && "loading"
          }`,
        },
        {
          controlType: CCType.DATE_PICKER,
          dataType: "string",
          fieldLabel: t("documentUpload_form_releaseOnDate_label"),
          fieldName: "releaseOnDate",
          inputName: "releaseOnDate",
          isAllowNull: false,
          isDefaultHide: false,
          isDetailView: false,
          isEditable: !loading && isFolderSelected && isDocumentTypeSelected,
          isElasticData: false,
          isHideLabelField: false,
          isHtmlField: false,
          isSpecialField: false,
          length: -1,
          dataType: "datetime",
          storageDataType: "DateTime",
          styleClass: "p-lg-12 p-md-12 p-sm-12",
          translationKey: "documentUpload_form_releaseOnDate_label",
          viewSectionId: 100,
          config: {
            minDate: new Date(),
          },
        },
        {
          controlType: "TextArea",
          dataType: "string",
          fieldLabel: t("documentUpload_form_message_label"),
          fieldName: "message",
          inputName: "message",
          isAllowNull: false,
          isDefaultHide: false,
          isDetailView: false,
          isEditable: !loading && isFolderSelected && isDocumentTypeSelected,
          isElasticData: false,
          isHideLabelField: false,
          isHtmlField: false,
          isSpecialField: false,
          length: -1,
          dataType: "string",
          storageDataType: "String",
          styleClass: "p-lg-12 p-md-12 p-sm-12",
          translationKey: "documentUpload_form_message_label",
          viewSectionId: 100,
          controlStyle: "height-auto",
          config: {
            rows: 5,
          },
        },
        {
          controlType: CCType.ATTACHMENT,
          dataType: "string",
          fieldLabel: t("documentUpload_form_files_label"),
          fieldName: "files",
          inputName: "files",
          isAllowNull: false,
          isDefaultHide: false,
          isDetailView: false,
          isEditable: !loading && isFolderSelected && isDocumentTypeSelected,
          isElasticData: false,
          isHideLabelField: false,
          isHtmlField: false,
          isSpecialField: false,
          length: -1,
          storageDataType: "String",
          styleClass: "p-lg-12 p-md-12 p-sm-12",
          translationKey: "documentUpload_form_files_label",
          viewSectionId: 100,
          inputHint: fileUploadHints,
        },
      ],
    },
  ];
};

const DocumentUpload = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let toast = store.getState().global.toast;
  const formRef = useRef();
  const { cancel, token } = axios.CancelToken.source();
  const initFormState = {
    loading: true,
    touched: false,
    activeStep: 0,
    invalid: true,
    options: {},
  };
  const [formState, setFormState] = useState(initFormState);
  const [form, setForm] = useState({
    folderId: undefined,
    releaseOnDate: new Date(),
  });

  useEffect(() => {
    const loadForm = async () => {
      setFormState((state) => {
        return { ...state, loading: true };
      });
      const opt = {};

      try {
        if (!formState?.options?.folderId) {
          let folderRes = await DocumentsService.documentGetFolders(
            {
              upload: true,
            },
            {
              cancelToken: token,
            }
          );
          opt.folderId = folderRes?.data;
          if (folderRes?.data?.length === 1) {
            setForm((f) => {
              return {
                ...f,
                folderId: folderRes.data[0]?.id,
              };
            });
          }
        }

        let docTypeRes = await DocumentsService.documentGetDocumentType({
          cancelToken: token,
        });
        if (Array.isArray(docTypeRes?.data)) {
          opt.documentType = docTypeRes.data;
          if (docTypeRes?.data?.length === 1) {
            setForm((f) => {
              return {
                ...f,
                documentType: docTypeRes.data[0]?.value,
              };
            });
          }
        }
      } finally {
        setFormState((state) => {
          return {
            ...state,
            loading: false,
            options: { ...state.options, ...opt },
          };
        });
      }
    };

    loadForm();

    return () => {
      cancel(RESPONSE_MESSAGE_ERROR.CANCEL);
      setForm({});
      setFormState({ ...initFormState, options: {} });
    };
  }, []);

  const clear = () => {
    setFormState((fs) => {
      const _fs = {
        ...fs,
        loading: false,
        invalid: true,
        touched: false,
        options: { ...fs.options },
      };

      setForm((f) => {
        if (fs?.options?.folderId?.length === 1) {
          return { folderId: f.folderId };
        }

        return { releaseOnDate: new Date() };
      });

      return _fs;
    });
  };

  const upload = async () => {
    try {
      setFormState((state) => {
        return { ...state, loading: true, invalid: true, touched: true };
      });

      var releaseOnDate = new Date(form.releaseOnDate);
      releaseOnDate.setUTCHours(0, 0, 0, 0); // set to start of the day
      const res = await DocumentsService.documentUploadFiles({
        message: form.message,
        folderId: form.folderId,
        files: form.files,
        releaseOnDate: releaseOnDate.toISOString(),
        documentType: form.documentType,
      });
      if (isResponseOk(res)) {
        toast.show({
          severity: MESSAGE_SEVERITY.SUCCESS,
          detail: t("common_submitMessageSuccess"),
          life: 3000,
        });
        clear();
      }
    } finally {
      setFormState((state) => {
        return { ...state, loading: false, invalid: false };
      });
    }
  };

  // Navigation and action
  const validateCurrentStep = () => {
    const isValid = !formRef?.current?.getActiveFormState({ expand: true })
      ?.invalid;
    setTimeout(() => {
      scrollToErrorControl();
    }, 100);
    return isValid;
  };

  const confirmSubmitApplication = async () => {
    if (!validateCurrentStep()) {
      setFormState((state) => {
        return { ...state, touched: true };
      });
      return;
    }

    await upload();
  };

  const renderFooter = () => {
    return (
      <footer className="footer p-grid p-align-center p-justify-between">
        <Button
          onClick={() => clear()}
          className={`p-button-outlined secondary back-button ${
            formState?.loading && "p-disabled"
          }`}
        >
          {t("documentUpload_footer_button_cancel")}
        </Button>
        <Button
          iconPos="right"
          loading={formState?.loading}
          onClick={() => confirmSubmitApplication()}
          className={`p-button upload-button ${
            (formState?.invalid || formState?.loading) && "p-disabled"
          }`}
        >
          {t("documentUpload_footer_button_upload")}
        </Button>
      </footer>
    );
  };

  const renderForm = () => {
    let fileUploadHints = null;
    let allowedFileExtRegex = null;
    const options = { ...formState.options };

    if (formState?.options?.documentType?.length > 0) {
      let documentTypeOptions = [...formState?.options?.documentType];
      if (!!form?.documentType) {
        allowedFileExtRegex = documentTypeOptions?.find(
          (x) => x.value === form?.documentType
        )?.description;
        if (!!allowedFileExtRegex) {
          // convert (\\.|\\/)(gif|jpg|jpeg|bmp|png|pdf|tif)$
          // to  .GIF, .GIF, .JPG, .JPEG, .BMP, .PNG, .PDF, .TIF
          let extensionArray = [
            ...allowedFileExtRegex?.matchAll(/([a-z]+)/gi),
          ]?.reduce((prev, curr) => {
            if (!prev) {
              return (prev += `.${curr[0].toUpperCase()}`);
            }
            return (prev += `, .${curr[0].toUpperCase()}`);
          }, "");

          if (!!extensionArray) {
            fileUploadHints = t("documentUpload_form_files_hint", {
              extension: extensionArray,
            });
          }
        }
      }

      options.documentType = documentTypeOptions.map((x) => {
        let _x = { ...x };
        delete _x?.description;
        return _x;
      });
    }

    const sections = DocumentUploadSection(
      t,
      formState.loading,
      !!form?.folderId,
      !!form?.documentType,
      fileUploadHints
    );
    const config = BaseMultiStepConfigToFormModel({
      applicationId: "document-upload",
      stepConfig: [
        {
          displaySequence: 1,
          id: 1,
          sections: [
            {
              displaySequence: 1,
              isEditable: true,
              sectionId: 100,
              sectionName: t("documentUpload_title"),
            },
          ],
        },
      ],
      sectionFieldConfig: sections,
      customData: {
        customFunctionLibrary: BaseMultiStepCustomFunctionLibrary({
          dispatch,
        }),
        attachmentControlConfig: {
          allowedFileExt: allowedFileExtRegex,
        },
      },
      t: t,
      form: form,
      dropdownOptions: options,
    });

    return (
      <BaseMultiStepForm
        ref={formRef}
        form={form}
        activeStep={formState.activeStep}
        config={{ ...config }}
        touched={formState.touched}
        onChange={(changed) => {
          if (!form?.folderId) {
            return;
          }

          setForm((f) => {
            const _f = {
              ...f,
              [changed?.changed?.control?.key]: changed?.changed?.data?.value,
            };

            if (changed?.changed?.control?.key === "documentType") {
              delete _f.files;
            }

            setFormState((fs) => {
              return {
                ...fs,
                invalid:
                  changed?.state?.formState?.invalid ||
                  !changed?.state?.form?.folderId ||
                  !changed?.state?.form?.documentType,
              };
            });
            return _f;
          });
        }}
      />
    );
  };

  return (
    <article className="my-document-form">
      {/* Form */}
      {renderForm()}

      {/* Footer */}
      {renderFooter()}
    </article>
  );
};

export default DocumentUpload;
