import { TIMESHEET_CONFIGURATION_MENU_Tab_KEY } from "./constants";
import { ITabItem } from "./types/types";

export const tabConfig: ITabItem[] = [
  {
    value: 0,
    key: TIMESHEET_CONFIGURATION_MENU_Tab_KEY.TIMESHEET_ACTIVITY_CONFIGURATION,
    name: "admin_timesheet_activityConfiguration_title",
    type: "Action",
    description: "admin_timesheet_activityConfiguration_description",
  },
  {
    value: 1,
    key: TIMESHEET_CONFIGURATION_MENU_Tab_KEY.TIMESHEET_CALENDAR_CONFIGURATION,
    name: "admin_timesheet_calendarConfiguration_title",
    type: "Action",
    description: "admin_timesheet_calendarConfiguration_description",
  },
  {
    value: 2,
    key: TIMESHEET_CONFIGURATION_MENU_Tab_KEY.TIMESHEET_COST_CENTER_CONFIGURATION,
    name: "admin_timesheet_costCenterConfiguration_title",
    type: "Action",
    description: "admin_timesheet_costCenterConfiguration_description",
  },
];
