import { TIMESHEET_MENU_ACTION_KEY } from "../../constants";

export interface tabItem {
  /**
   * Action key to map data source
   */
  key: TIMESHEET_MENU_ACTION_KEY;
  /**
   * Value to be used in BaseTabScreen component
   */
  value: number;
  /**
   * Translation key for tab title
   */
  name: string;
  /**
   * Module type
   */
  type: "Action" | "Menu";
  /**
   * Description
   */
  description: string;
}

export const MyApplications: tabItem = {
  value: 0,
  name: "timesheet_list_my_timesheet_title",
  key: TIMESHEET_MENU_ACTION_KEY.TIMESHEET_MY_APPLICATIONS,
  type: "Action",
  description: "timesheet_list_my_timesheet_description",
};

export const DelegatedApplications: tabItem = {
  value: 1,
  name: "timesheet_list_delegated_timesheet_title",
  key: TIMESHEET_MENU_ACTION_KEY.TIMESHEET_DELEGATED_APPLICATIONS,
  type: "Action",
  description: "timesheet_list_delegated_timesheet_description",
};

export const HRApplications: tabItem = {
  value: 2,
  name: "timesheet_list_hr_view_title",
  key: TIMESHEET_MENU_ACTION_KEY.TIMESHEET_HR_APPLICATIONS,
  type: "Action",
  description: "timesheet_list_hr_view_description",
};
