import { BCType } from "../../../../../../components/base-control/base-control";

export const EmployeeCodeField = {
  key: "employeeCode",
  label: "timesheet_form_delegatedEmployee",
  type: BCType.select,
  required: false,
  hasFilterEnum: false,
  placeholder: "timesheet_form_delegatedEmployee_placeholder",
};

export const UserSubmitField = {
  key: "userSubmit",
  label: "timesheet_form_userSubmit",
  type: BCType.checkbox,
  required: false,
};

export const SelectionPeriodField = {
  key: "period",
  label: "timesheet_form_period",
  type: BCType.select,
  required: true,
  placeholder: "timesheet_form_period_placeholder",
};
