import { useTranslation } from "react-i18next";

import { CLAIM_STATUS } from "../../models";
import "./claim-status.scss";

type ClaimStatusProps = {
  status: CLAIM_STATUS;
  data: any;
  indicator?: boolean;
  showText?: boolean;
};

const ClaimStatus = ({
  status,
  data,
  indicator = true,
  showText = true,
}: ClaimStatusProps) => {
  const { t } = useTranslation();
  let indicatorClassName = "";
  let text = "";

  if (data?.isPending) {
    indicatorClassName =
      "claim-status__indicator claim-status__indicator--pending-for-approval";
  }
  if (data?.isCancelled) {
    indicatorClassName =
      "claim-status__indicator claim-status__indicator--cancelled";
  }
  if (data?.isApproved) {
    indicatorClassName =
      "claim-status__indicator claim-status__indicator--approved";
  }
  if (data?.isRejected) {
    indicatorClassName =
      "claim-status__indicator claim-status__indicator--rejected";
  }

  switch (status) {
    case CLAIM_STATUS.DRAFT:
      indicatorClassName =
        "claim-status__indicator claim-status__indicator--draft";
      text = t("leave_leaveStatus_draft");
      break;
    case CLAIM_STATUS.PENDING_FOR_APPROVAL:
      indicatorClassName =
        "claim-status__indicator claim-status__indicator--pending-for-approval";
      text = t("leave_leaveStatus_pendingForApproval");
      break;
    case CLAIM_STATUS.CANCELLED:
      indicatorClassName =
        "claim-status__indicator claim-status__indicator--cancelled";
      text = t("leave_leaveStatus_cancelled");
      break;
    case CLAIM_STATUS.APPROVED:
      indicatorClassName =
        "claim-status__indicator claim-status__indicator--approved";
      text = t("leave_leaveStatus_approved");
      break;
    case CLAIM_STATUS.REJECTED:
      indicatorClassName =
        "claim-status__indicator claim-status__indicator--rejected";
      text = t("leave_leaveStatus_rejected");
      break;

    // temp
    case CLAIM_STATUS.PERFORMING_APPLICATION_APPROVAL:
      indicatorClassName =
        "claim-status__indicator claim-status__indicator--pending-for-approval";
      text = status;
      break;
    case CLAIM_STATUS.CLAIM_APPLICATION_SUBMIT:
      indicatorClassName =
        "claim-status__indicator claim-status__indicator--pending-for-approval";
      text = status;
      break;
    default:
      text = status;
      break;
  }

  return (
    <div className="claim-status">
      {indicator && <span className={indicatorClassName}></span>}
      {showText && <span>{text}</span>}
    </div>
  );
};

export default ClaimStatus;
