import { BCType } from "../../../../../components/base-control/base-control";
import { IBaseFormControlProps } from "../../../timesheet/constants";

export enum CostCenterFormConfigFieldKey {
  employeeCode = "employeeCode",
  costCenter = "costCenter",
}

export const EmployeeCodeField: IBaseFormControlProps = {
  key: CostCenterFormConfigFieldKey.employeeCode,
  label: "admin_timesheet_costCenterConfiguration_employeeCode",
  type: BCType.select,
  required: true,
  hasFilterEnum: true,
  placeholder:
    "admin_timesheet_costCenterConfiguration_employeeCode_placeHolder",
};

export const CostCenterField: IBaseFormControlProps = {
  key: CostCenterFormConfigFieldKey.costCenter,
  label: "admin_timesheet_costCenterConfiguration_costCenter",
  type: BCType.select,
  required: true,
  hasFilterEnum: true,
  placeholder: "admin_timesheet_costCenterConfiguration_costCenter_placeHolder",
};

export const costCenterFormConfigFieldList = [
  EmployeeCodeField,
  CostCenterField,
];
