import moment from "moment";
import { ITimesheetDetail } from "../context/reducer/application";
import {
  ActivityInputModel,
  TimesheetApplicationModel,
} from "../models/timesheet-application-model";
import { IGetTimesheetData } from "../constants";

export const transformDetailToModel: (
  timesheetDetail: ITimesheetDetail
) => TimesheetApplicationModel = (timesheetDetail) => {
  const { activities, employeeCode, periodId, nElasticDataH } = timesheetDetail;
  const requestActivities: ActivityInputModel[] = activities.map((act) => {
    const { status, ...res } = act;
    return {
      ...res,
      activityId: res.activityId as number,
      fromDate: moment(res.fromDate).format("YYYY-MM-DDTHH:mm:ss") + "Z",
      fromTime: moment(res.fromTime).format("YYYY-MM-DDTHH:mm:ss") + "Z",
      toDate: moment(res.toDate).format("YYYY-MM-DDTHH:mm:ss") + "Z",
      toTime: moment(res.toTime).format("YYYY-MM-DDTHH:mm:ss") + "Z",
      excludeMealTime: res?.excludeMealTime,
      amended: res?.amended,
    };
  });
  return {
    employeeCode: employeeCode!,
    periodId: periodId!,
    activities: requestActivities,
    nElasticDataH: nElasticDataH,
  };
};

export const transformGetTimesheetResponseToDetail: (
  data: IGetTimesheetData
) => ITimesheetDetail = (data) => {
  let count = 0;
  let detailActivities = data.activities.map((act) => {
    count += Number(act.hours);
    return {
      id: Number(act.id),
      activityCode: act.activityCode,
      activityId: Number(act.activityId),
      fromDate: act.from,
      fromTime: act.from,
      toDate: act.to,
      toTime: act.to,
      appliedUnits: act.hours,
      remarks: act.remarks,
      aprvRemark: act.aprvRemark,
      status: act.status,
      excludeMealTime: act.excludeMealTime,
    };
  });
  return {
    periodId: data?.period?.id,
    period: data?.period,
    timesheetId: data.id,
    totalHours: data.totalHours,
    totalAppliedHours: count,
    employeeCode: data.employeeCode,
    employeeName: data.employeeName,
    activities: detailActivities,
    nElasticDataH: data?.nElasticDataH,
  };
};
