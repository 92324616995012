import { createContext, useReducer } from "react";
import {
  IApplicationContext,
  ILoadingContext,
  IModalContext,
  TimesheetContext,
} from "./interface";
import {
  applicationReducer,
  initialApplicationState,
} from "./reducer/application";
import { initialLoadingState, loadingReducer } from "./reducer/loading";
import { initialModalState, modalReducer } from "./reducer/modal";

export const ApplicationContext = createContext<IApplicationContext>([
  initialApplicationState,
  () => ({}),
]);

export const LoadingContext = createContext<ILoadingContext>([
  initialLoadingState,
  () => ({}),
]);

export const ModalContext = createContext<IModalContext>([
  initialModalState,
  () => ({}),
]);

interface IContextReturnType {
  state: TimesheetContext;
  Provider: React.Provider<TimesheetContext>;
}

export const useContextProviderAndState = () => {
  const applicationState = useReducer(
    applicationReducer,
    initialApplicationState
  );
  const loadingState = useReducer(loadingReducer, initialLoadingState);
  const modalState = useReducer(modalReducer, initialModalState);
  return [
    { Provider: ApplicationContext.Provider, state: applicationState },
    { Provider: LoadingContext.Provider, state: loadingState },
    { Provider: ModalContext.Provider, state: modalState },
  ] as IContextReturnType[];
};
