import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  IBaseFormControlProps,
  IBaseFormOnChangeProps,
  ICreateApplicationFormData,
  ITimesheetConfigData,
  ITimesheetDetailData,
  SHORT_DATE_FORMAT,
  createApplicationFormInitialState,
} from "../../../constants";
import React from "react";
import {
  getCreateApplicationFormControls,
  getCreateApplicationFormLayout,
  parseFieldKeyListIntoFormKeyList,
} from "../form-helper/create-application-form-create-helper";
import { SelectControlOption } from "../../../../../../components/base-control/select-control/select-control";
import {
  IDateTypeItemConfig,
  TIMESHEET_DETAIL_TYPE,
} from "../../../constants/type";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  FUNCTION_CODE,
  MESSAGE_SEVERITY,
  RESPONSE_MESSAGE_TYPE,
} from "../../../../../../constants";
import { openModal } from "../../../../../../redux/actions/modal";
import { PortalLink } from "../../../../../../services/utils";
import { toFormRequest } from "../../../utils/form-to-request";
import {
  validateInput,
  validateRequired,
} from "../form-helper/create-application-form-validation";
import { store } from "../../../../../../redux/store";
import { MessageSwitch2 } from "../../../../../../services/utils/message-2";
import moment from "moment";
import { isDateRangeOverlap } from "../../../utils/date-range";
import { ApplicationContext, ModalContext } from "../../../context";
import { ApplicationAction } from "../../../context/action/application";
import { ModalAction } from "../../../context/action/modal";
import { useTimesheetDetailContext } from "../../timesheet-detail/context/useTimesheetDetailContext";
import {
  DateField,
  ExcludeMealTimeField,
  FromTimeField,
  ToDateField,
  ToTimeField,
} from "../form-helper/create-application-form-field-config";

export interface IReceivedLocationState {
  period: string;
  periodId: string;
  editActivity: string;
  prevLocation: string;
  type: TIMESHEET_DETAIL_TYPE;
}

export const FormContextProvider: React.FC = ({ children }) => {
  const [
    {
      activityModalDetails: { editActivity },
    },
    modalDispatch,
  ] = useContext(ModalContext);
  const { mode } = useTimesheetDetailContext();

  const isEditingActivity = !!editActivity;
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [{ application }, dispatchApplicationAction] =
    useContext(ApplicationContext);
  const history = useHistory();
  const toast = store.getState().global.toast;

  const formRef = useRef();
  const [form, setForm] = useState<ICreateApplicationFormData>(
    createApplicationFormInitialState(editActivity!)
  );

  const [formTouched, setFormTouched] = useState<boolean>(false);

  const [formControls, setFormControls] = useState<IBaseFormControlProps[]>([]);
  const [formLayout, setFormLayout] = useState<any>({});

  const [periodDetail, setPeriodDetail] =
    useState<ITimesheetDetailData | undefined>();
  const [activityIds, setActivityIds] = useState<SelectControlOption[]>();
  const [calendarDates, setCalendarDates] = useState<
    { [key in string]: IDateTypeItemConfig }
  >({});
  const [timesheetConfig, setTimesheetConfig] =
    useState<ITimesheetConfigData>();

  const [from, to] = useMemo(() => {
    return [
      moment(timesheetConfig?.fromTime, "HH:mm:ss").toDate(),
      moment(timesheetConfig?.toTime, "HH:mm:ss").toDate(),
    ];
  }, [timesheetConfig]);

  const mealFromTime = moment(timesheetConfig?.mealFromTime, "HH:mm:ss");
  const mealToTime = moment(timesheetConfig?.mealToTime, "HH:mm:ss");
  const mealTotalHours = moment
    .duration(mealToTime.diff(mealFromTime))
    .asHours();

  const onDeleteActivityButtonClick = useCallback(
    (uuid: string) => {
      const cloneActivities = [...form.activities].filter(
        (activity) => activity.uuid !== uuid
      );

      const cloneForm: ICreateApplicationFormData = {
        ...form,
        activities: cloneActivities,
      };
      parseFieldKeyListIntoFormKeyList(uuid).forEach(
        (key) => (cloneForm[key] = undefined)
      );

      const { totalAppliedUnits } = toFormRequest(cloneForm as any);
      setForm({ ...cloneForm, totalPeriodsHours: totalAppliedUnits });
    },
    [form, setForm]
  );

  const onExcludeMealTimeChange = useCallback(
    (value: boolean, uuid: string) => {
      if (value) {
        const cloneForm: ICreateApplicationFormData = {
          ...form,
          excludeMealTimeHours: mealTotalHours,
        };
        const { totalAppliedUnits } = toFormRequest(
          cloneForm as any,
          undefined,
          uuid,
          value
        );
        setForm({
          ...cloneForm,
          totalPeriodsHours: totalAppliedUnits,
          [`${ExcludeMealTimeField.key}_${uuid}`]: value,
        });
      } else {
        const cloneForm: ICreateApplicationFormData = {
          ...form,
          excludeMealTimeHours: mealTotalHours,
        };
        const { totalAppliedUnits } = toFormRequest(
          cloneForm as any,
          undefined,
          uuid,
          value
        );
        setForm({
          ...cloneForm,
          totalPeriodsHours: totalAppliedUnits,
          [`${ExcludeMealTimeField.key}_${uuid}`]: value,
        });
      }
    },
    [form, setForm]
  );

  const onChange = useCallback(
    (change: IBaseFormOnChangeProps<ICreateApplicationFormData>) => {
      change.state.form["excludeMealTimeHours"] = mealTotalHours;
      // Update all toDate if inDate is updated
      if (change.changed.control.key === DateField.key) {
        change.state.form.activities.map(
          ({ uuid }) =>
            (change.state.form[`${ToDateField.key}_${uuid}`] =
              change.changed.data.value)
        );
      }

      // Update fromTimeField and toTimeField if toDateField is updated, to prevent user set the enddate earlier than startdate
      if (change.changed.control.key.split("_")[0] === ToDateField.key) {
        const uuid = change.changed.control.key.split("_")[1];
        const isSameDay =
          moment(form[`${DateField.key}`]).format(SHORT_DATE_FORMAT) ===
          moment(form[`${ToDateField.key}_${uuid}`]).format(SHORT_DATE_FORMAT);
        if (isSameDay) {
          if (change.state.form[`${FromTimeField.key}_${uuid}`]) {
            change.state.form[`${FromTimeField.key}_${uuid}`] = from;
          }

          if (change.state.form[`${ToTimeField.key}_${uuid}`]) {
            change.state.form[`${ToTimeField.key}_${uuid}`] = to;
          }
        }
      }

      if (
        change.changed.control.key.split("_")[0] === ToDateField.key &&
        moment(change.changed.data.value).format(SHORT_DATE_FORMAT) ===
          moment(periodDetail?.endDate)
            .add({ day: 1 })
            .format(SHORT_DATE_FORMAT)
      ) {
        const uuid = change.changed.control.key.split("_")[1];
        change.state.form[`${ToTimeField.key}_${uuid}`] = moment(
          change.state.form.date
        )
          .add({ day: 1 })
          .set({ hour: 0, minute: 0 })
          .toDate();
      }

      const { totalAppliedUnits } = toFormRequest(change.state.form as any);
      setForm({
        ...form,
        ...change.state.form,
        totalPeriodsHours: totalAppliedUnits,
      });
    },
    [periodDetail?.endDate, form, mode, from, to]
  );

  const formProps = useMemo(
    () => ({
      ref: formRef,
      config: {
        controls: formControls,
        layout: formLayout,
      },
      form,
      touched: formTouched,
      onChange,
      setForm,
    }),
    [form, formControls, formLayout, formRef, formTouched, onChange, setForm]
  );

  useEffect(() => {
    if (periodDetail) {
      const disableAll = !form?.period || !form?.periodId;
      const disabledDates = form.activities.reduce((acc: any, act: any) => {
        const returnArray = [
          ...acc,
          new Date(act.fromDate.format("YYYY-MM-DD")),
        ];
        return returnArray;
      }, []);
      const layout = getCreateApplicationFormLayout({
        t,
        form,
        setForm,
        periodDetail,
        disableAll,
        calendarDates,
        isEditingActivity,
        disabledDates,
        timesheetConfig,
      });
      const controls = getCreateApplicationFormControls({
        t,
        form,
        setForm,
        activityIds: activityIds || [],
        disableAll,
        periodDetail,
        onDeleteActivityButtonClick,
        onExcludeMealTimeChange,
        timesheetConfig,
      });
      setFormControls(controls);
      setFormLayout(layout);
    }
  }, [
    activityIds,
    calendarDates,
    timesheetConfig,
    form,
    isEditingActivity,
    onDeleteActivityButtonClick,
    periodDetail,
    setForm,
    setFormControls,
    setFormLayout,
    t,
  ]);

  const backToList = () => {
    if (form.isFromView) {
      modalDispatch({
        type: ModalAction.RESET_ACTIVITY_MODAL,
      });
    } else {
      // TODO: see if it need or not
      history.push(PortalLink(`${FUNCTION_CODE.Timesheet}`));
    }
  };

  const onSaveButtonClick = async () => {
    const { activitiesInput, totalAppliedUnits } = toFormRequest(form, mode);
    const validateRequiredResult = validateRequired(formRef, setFormTouched);
    if (validateRequiredResult) {
      return false;
    }

    const validateInputResult = validateInput(activitiesInput);
    if (validateInputResult?.length) {
      MessageSwitch2(
        {
          messages: validateInputResult.map((resultMessage) => ({
            statusText: MESSAGE_SEVERITY.ERROR,
            text: resultMessage,
            type: RESPONSE_MESSAGE_TYPE.ERROR,
          })),
        },
        toast,
        null,
        i18n
      );

      return false;
    }
    let overlapMessages: string[] = [];
    activitiesInput.forEach((newAct: any) => {
      // check overlap with current application activities
      const startTime = `${moment(newAct.fromDate).format(
        "YYYY-MM-DD"
      )}T${moment(newAct.fromTime).format("HH:mm:ss")}Z`;
      const endTime = `${moment(newAct.toDate).format("YYYY-MM-DD")}T${moment(
        newAct.toTime
      ).format("HH:mm:ss")}Z`;
      const dateRange2 = {
        start: moment(startTime).toDate(),
        end: moment(endTime).toDate(),
      };

      application.activities
        .filter((a: any) => {
          return a.id
            ? a.id !== newAct.id
            : a.uuid
            ? a.uuid !== newAct.uuid
            : true;
        })
        .forEach((currentAct: any) => {
          const startTime = `${moment(currentAct.fromDate).format(
            "YYYY-MM-DD"
          )}T${moment(currentAct.fromTime).format("HH:mm:ss")}Z`;
          const endTime = `${moment(currentAct.toDate).format(
            "YYYY-MM-DD"
          )}T${moment(currentAct.toTime).format("HH:mm:ss")}Z`;
          const dateRange1 = {
            start: moment(startTime).toDate(),
            end: moment(endTime).toDate(),
          };
          if (isDateRangeOverlap(dateRange1, dateRange2)) {
            overlapMessages.push(`Activities are overlapped.`);
          }
        });
    });
    if (overlapMessages.length > 0) {
      MessageSwitch2(
        {
          messages: overlapMessages.map((resultMessage) => ({
            statusText: MESSAGE_SEVERITY.ERROR,
            text: resultMessage,
            type: RESPONSE_MESSAGE_TYPE.ERROR,
          })),
        },
        toast,
        null,
        i18n
      );

      return false;
    }
    let currentActivities = application.activities;
    if (isEditingActivity) {
      currentActivities = application.activities.filter((act: any) => {
        return act.id
          ? act.id !== editActivity!.id
          : act.uuid
          ? act.uuid !== (editActivity as any).uuid
          : true;
      });
    }
    // activitesInput.push(currentAtivities);
    dispatchApplicationAction({
      type: ApplicationAction.SET_APPLICATION,
      payload: {
        application: {
          ...application,
          totalAppliedHours: application.totalAppliedHours + totalAppliedUnits,
          activities: [...currentActivities, ...activitiesInput],
        },
        hasEdit: true,
      },
    });
    modalDispatch({
      type: ModalAction.RESET_ACTIVITY_MODAL,
    });
  };

  const onBackButtonClick = () => {
    dispatch(
      openModal({
        title: t("timesheet_common_actionConfirmation"),
        content: t("timesheet_common_messageBackConfirmation"),
        classNameMainDialog: "confirm-message-modal",
        primaryButtonText: t("timesheet_common_actionConfirm"),
        primaryButtonClickFn: async ({ closeFn }: any) => {
          closeFn();
          backToList();
        },
        secondButtonClickFn: ({ closeFn }: any) => {
          closeFn();
        },
      })
    );
  };

  const isDisabledAddButton = useMemo(() => {
    const { activitiesInput, totalAppliedUnits } = toFormRequest(form);

    return activitiesInput.some(
      (input) =>
        !input.activityId ||
        input.appliedUnits <= 0 ||
        !input.fromTime ||
        !input.toTime ||
        totalAppliedUnits <= 0
    );
  }, [form]);

  return (
    <FormContext.Provider
      value={{
        formRef,
        form,
        setForm,
        formTouched,
        isDisabledAddButton: false,
        setFormTouched,
        formControls,
        setFormControls,
        formLayout,
        setFormLayout,
        periodDetail,
        setPeriodDetail,
        activityIds,
        setActivityIds,
        calendarDates,
        setCalendarDates,
        timesheetConfig,
        setTimesheetConfig,
        formProps,
        onSaveButtonClick,
        onBackButtonClick,
      }}
    >
      {children}
    </FormContext.Provider>
  );
};

export const FormContext = React.createContext<any | null>({});

export const useFormContext = () => useContext(FormContext);
