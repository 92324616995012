import "./calendar.scss";
import { Calendar } from "primereact/calendar";
import { useState } from "react";
import { useEffect } from "react";

interface CustomCalendarProps {
  className?: string;
  dateConfig?: DateConfig;
  [key: string]: any;
}

interface DateConfigItem {
  showMark: boolean;
  disabled: boolean;
  isRedDay: boolean;
}

interface DateConfig {
  [key: number]: DateConfigItem;
}

interface PDate {
  day: number;
  month: number;
  year: number;
  otherMonth: boolean;
  selectable: boolean;
}

const DEFAULT_DATE_CONFIG = {
  showMark: false,
  disabled: false,
  isRedDay: false,
};

const DateTemplate = ({
  date,
  dateConfig,
}: {
  date: PDate;
  dateConfig?: DateConfig;
}) => {
  if (date.otherMonth) {
    return <span>{date.day}</span>;
  }

  const getDateConfig = (day: number) => {
    if (dateConfig && dateConfig[day]) {
      return dateConfig[day];
    }
    return DEFAULT_DATE_CONFIG;
  };

  const config = getDateConfig(date.day);

  return (
    <div className="custom-date-template">
      <span
        className={`
          ${config?.disabled || config?.isRedDay ? "custom-date-disabled" : ""}
          ${config?.isRedDay ? "custom-date-red-day" : ""}
          ${config?.showMark ? "custom-date-marked" : ""}`}
      >
        {date.day}
      </span>

      {config.showMark && <div className="date-marker pi pi-circle-on"></div>}
    </div>
  );
};

const ClaimCalendar = ({
  className,
  dateConfig,
  ...props
}: CustomCalendarProps) => {
  const [disabledDates, setDisableDate] = useState(props?.disabledDates ?? []);
  const [viewDate, setViewDate] = useState(
    props.viewDate ? props.viewDate : new Date()
  );

  useEffect(() => {
    if (props.viewDate) {
      setViewDate(props.viewDate);
    }
  }, [props.viewDate]);

  useEffect(() => {
    const dates = Object.entries(dateConfig ?? {}).reduce(
      (acc, [day, config]) => {
        if (config.disabled || config.isRedDay) {
          acc = [...acc, new Date(new Date(viewDate).setDate(parseInt(day)))];
        }
        return acc;
      },
      disabledDates
    );
    setDisableDate(dates);
  }, [dateConfig, viewDate]);

  const onViewDateChange = (e: any) => {
    if (props.onViewDateChange) {
      props.onViewDateChange(e);
    }
    setViewDate(e.value);
  };

  return (
    <Calendar
      className={`custom-calendar ${className || ""}`}
      dateTemplate={(date: PDate) => (
        <DateTemplate date={date} dateConfig={dateConfig} />
      )}
      viewDate={props.viewDate ? props.viewDate : viewDate}
      {...props}
      onViewDateChange={onViewDateChange}
      disabledDates={disabledDates}
    />
  );
};

export default ClaimCalendar;
