import { useHistory } from "react-router-dom";
import { Footer } from "../../components";
import {
  IBaseFormFieldProps,
  IPeriodSelectFormData,
  timesheetRoute,
} from "../../constants";
import { useContext } from "react";
import { ApplicationContext } from "../../context";
import { ApplicationAction } from "../../context/action/application";
import { TIMESHEET_DETAIL_TYPE } from "../../constants/type";

export const PeriodSelectFooter = ({
  form,
}: {
  form: IPeriodSelectFormData & { period_obj?: IBaseFormFieldProps };
}) => {
  const history = useHistory();
  const [, dispatch] = useContext(ApplicationContext);
  const onViewButtonClick = () => {
    dispatch({
      type: ApplicationAction.SET_APPLICATION,
      payload: {
        application: {
          totalHours: 0,
          totalAppliedHours: 0,
          // Pass employee name to timesheet detail
          // For HR view
          employeeName: form.employeeName,
          employeeCode: form.employeeCode,
          activities: [],
        },
      },
    });

    history.push(timesheetRoute.applicationSubmit, {
      periodId: form.period,
      period: form.period_obj?.label,
      type: TIMESHEET_DETAIL_TYPE.SUBMIT,
    });
  };

  const isDisabled = form.userSubmit
    ? !form.period
    : !form.period || !form.employeeCode;

  return (
    <Footer
      form={form}
      primaryButton={{
        label: "timesheet_common_actionNext",
        onClick: onViewButtonClick,
        disabled: isDisabled,
      }}
    />
  );
};
