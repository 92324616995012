import { Button } from "primereact/button";
import { Dropdown, DropdownChangeParams } from "primereact/dropdown";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { ApplicationContext } from "../../context";
import { useTimesheetDetailContext } from "./context/useTimesheetDetailContext";
import moment from "moment";
import { useDispatch } from "react-redux";
import { openModal } from "../../../../../redux/actions/modal";
import { useTranslation } from "react-i18next";
import TimesheetService from "../../../../../services/hrmnet-api/timesheet";
import { isResponseOk } from "../../../../../utils/utils";
import { InputText } from "primereact/inputtext";
import { store } from "../../../../../redux/store";
import { MESSAGE_SEVERITY } from "../../../../../constants";
import { ApplicationAction } from "../../context/action/application";

const ApprovalActionBar = (props: any) => {
  const toast = store.getState().global.toast;
  const [filter, setFilter] = useState("all");
  const [comment, setComment] = useState("");
  const dispatch = useDispatch();
  const [
    {
      application: { timesheetId },
    },
    dispatchApplicationAction,
  ] = useContext(ApplicationContext);
  const { calendarDates, fetchData } = useTimesheetDetailContext();
  const { t } = useTranslation();
  const onClickCustomApprove = useCallback(() => {
    dispatch(
      openModal({
        title: t("timesheet_common_actionConfirmation"),
        content: t(
          filter === "all"
            ? "timesheet_approval_confirmApproveAllMessage"
            : "timesheet_approval_confirmApproveMessage",
          { timesheet_filter: moment(filter).format("YYYY-MM-DD") }
        ),
        classNameMainDialog: "confirm-message-modal",
        primaryButtonText: t("timesheet_common_actionConfirm"),
        primaryButtonClickFn: async ({ closeFn }: any) => {
          closeFn();
          // let targetApproveList = [];
          let res;
          if (filter === "all") {
            res = await TimesheetService.approveAllActivities({
              data: {
                id: timesheetId,
                comment,
              },
            });
          } else {
            res = await TimesheetService.approveActivitiesByDate({
              data: {
                id: timesheetId,
                comment,
                date: filter,
              },
            });
          }
          if (isResponseOk(res)) {
            await fetchData();
          } else {
            return;
          }
          toast.show({
            severity: MESSAGE_SEVERITY.SUCCESS,
            summary: t("misc_axios_notification"),
            detail: "Create Success!",
            life: 3000,
            sticky: false,
          });
        },
        secondButtonClickFn: ({ closeFn }: any) => {
          closeFn();
        },
      })
    );
  }, [
    filter,
    fetchData,
    timesheetId,
    comment,
    dispatch,
    dispatchApplicationAction,
    t,
    toast,
  ]);
  const onClickCustomReject = useCallback(() => {
    dispatch(
      openModal({
        title: t("timesheet_common_actionConfirmation"),
        content: t(
          filter === "all"
            ? "timesheet_approval_confirmRejectAllMessage"
            : "timesheet_approval_confirmRejectMessage",
          { timesheet_filter: filter }
        ),
        classNameMainDialog: "confirm-message-modal",
        primaryButtonText: t("timesheet_common_actionConfirm"),
        primaryButtonClickFn: async ({ closeFn }: any) => {
          closeFn();
          let res;
          if (filter === "all") {
            res = await TimesheetService.rejectAllActivities({
              data: {
                id: timesheetId,
                comment,
              },
            });
          } else {
            res = await TimesheetService.rejectActivitiesByDate({
              data: {
                id: timesheetId,
                comment,
                date: filter,
              },
            });
          }
          if (isResponseOk(res)) {
            await fetchData();
          } else {
            return;
          }
          toast.show({
            severity: MESSAGE_SEVERITY.SUCCESS,
            summary: t("misc_axios_notification"),
            detail: "Create Success!",
            life: 3000,
            sticky: false,
          });
        },
        secondButtonClickFn: ({ closeFn }: any) => {
          closeFn();
        },
      })
    );
  }, [
    filter,
    fetchData,
    timesheetId,
    comment,
    dispatch,
    dispatchApplicationAction,
    t,
    toast,
  ]);

  const onChange = (e: DropdownChangeParams) => {
    setFilter(e.value);
  };

  const dropdownOptions = useMemo(() => {
    return [
      { label: "All", value: "all" },
      ...calendarDates.map((d: any) => ({
        label: moment(d.targetDate).format("YYYY-MM-DD"),
        value: d.targetDate,
      })),
    ];
  }, [calendarDates]);

  return (
    <div className="approval-action-bar">
      <Dropdown
        inputId="approval-filter"
        name="approval-filter"
        value={filter}
        options={dropdownOptions}
        optionLabel="label"
        placeholder="Select a filter"
        onChange={onChange}
      />
      <InputText
        style={{ width: "100%" }}
        onBlur={(e) => setComment(e.target.value)}
        placeholder="Leave your comment for approval"
        maxLength={150}
      />
      <div className="button-container">
        <Button onClick={onClickCustomApprove}>Approve</Button>
        <Button className="p-button-secondary" onClick={onClickCustomReject}>
          Reject
        </Button>
      </div>
    </div>
  );
};

export default ApprovalActionBar;
