import "./report.scss";

//components
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown } from "primereact/dropdown";
import { TabMenu } from "primereact/tabmenu";
import { saveAs } from "file-saver";

import { resetBcDynamicPaths } from "../../../../../redux/actions/breadcrumb";
import LanguageLoader from "../../../../../components/language-loader/language-loader";
import {
  EEPORTAL_LABEL_BASE_TABLE,
  MESSAGE_SEVERITY,
} from "../../../../../constants";
import {
  CLAIMS_T_CONTEXT_KEY,
  CLAIMS_MENU_MODULE_KEY,
  CLAIMS_MENU_PAGE_KEY,
  CLAIMS_MENU_ACTION_KEY,
} from "../../constants";
import { useTranslation } from "react-i18next";
import { Animated } from "react-animated-css";
import Button from "../../../rental/components/action-button";
import { useHistory } from "react-router";
import { useWindowSize } from "../../../utils/window-size/useWindowSize";
import {
  getReportColumnConfig,
  getReportFilterConfig,
  CLAIMS_REPORT_TYPE,
  CLAIMS_REPORT_KEY,
  parseApiFilter,
  prepareDefaultFilterValue,
  prepareFilterPayload,
  getFilterOptions,
  WEB_PARAMETER_SETTING_TYPE,
  CLAIMS_REPORT_EXTRA_FIELDS,
} from "./config";
import BaseForm from "../../../../../components/base-form/base-form";
import BaseTable from "../../../../../components/base-table/base-table-portal-rental";
import {
  DEFAULT_BT_SEARCH,
  getBTConfig,
} from "../../../../../components/base-table/base-table-model";
import {
  ClaimsService,
  ClaimsReportService,
} from "../../../../../services/hrmnet-api";
import { isResponseOk } from "../../../../../utils/utils";
import { ClaimMenu, ScrollToClaimsMenuActiveItem } from "../../components";
import { showToast } from "../../../../../services/utils/message";

const Report = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const { isMobile } = useWindowSize();

  const [reportSelectIndex, setReportSelectIndex] = useState(0);
  const tableRef = useRef();

  const menus = useSelector((state) => state.menu.sideBar);
  const claimsMenu = menus.find(
    (x) => x.key === CLAIMS_MENU_MODULE_KEY
  )?.subMenus;
  const reportSubMenu = claimsMenu.find(
    (x) => x.key === CLAIMS_MENU_PAGE_KEY.ENQUIRY_REPORT
  )?.subMenus;

  const selectedLangKey = useSelector(
    (state) => state.language.language?.selectedLang?.key
  );

  // Filter
  const [reportFilterMap, setReportFilterMap] = useState({});
  const [reportFilterFormMap, setReportFilterFormMap] = useState({});
  const [isGenerateReport, setIsGenerateReport] = useState(false);
  const [isReportReady, setIsReportReady] = useState(false);
  const [response, setResponse] = useState([]);
  const [existingPayLoad, seteExistingPayLoad] = useState([]);
  const [isFilterLoading, setIsFilterLoading] = useState(true);

  //common filters used by all reports
  const [reportFilterFormConfig, setReportFilterFormConfig] = useState([]);

  let reportTabList = [
    ...(reportSubMenu &&
    reportSubMenu.find(
      (x) => x.key === CLAIMS_REPORT_KEY.OT_MEAL_ALLOWANCE_TRANSACTION_REPORT
    )
      ? [
          {
            label: t("claims_report_otClaimTransactionReport"),
            reportType: CLAIMS_REPORT_TYPE.OT_MEAL_ALLOWANCE_TRANSACTION_REPORT,
          },
        ]
      : []),
    ...(reportSubMenu &&
    reportSubMenu.find(
      (x) => x.key === CLAIMS_REPORT_KEY.OT_CLAIM_SUMMARY_REPORT
    )
      ? [
          {
            label: t("claims_report_otClaimSummaryReport"),
            reportType: CLAIMS_REPORT_TYPE.OT_CLAIM_SUMMARY_REPORT,
          },
        ]
      : []),
    ...(reportSubMenu &&
    reportSubMenu.find(
      (x) => x.key === CLAIMS_REPORT_KEY.OT_CLAIM_DETAILS_REPORT
    )
      ? [
          {
            label: t("claims_report_otClaimDetailsReport"),
            reportType: CLAIMS_REPORT_TYPE.OT_CLAIM_DETAILS_REPORT,
          },
        ]
      : []),
    ...(reportSubMenu &&
    reportSubMenu.find((x) => x.key === CLAIMS_REPORT_KEY.CLAIM_DETAILS_REPORT)
      ? [
          {
            label: t("claims_report_claimDetailsReport"),
            reportType: CLAIMS_REPORT_TYPE.CLAIM_DETAILS_REPORT,
          },
        ]
      : []),
    ...(reportSubMenu &&
    reportSubMenu.find((x) => x.key === CLAIMS_REPORT_KEY.CLAIM_SUMMARY_REPORT)
      ? [
          {
            label: t("claims_report_claimSummaryReport"),
            reportType: CLAIMS_REPORT_TYPE.CLAIM_SUMMARY_REPORT,
          },
        ]
      : []),
  ].map((report, idx) => ({
    ...report,
    value: idx, // for dropdown value
  }));

  useEffect(() => {
    dispatch(resetBcDynamicPaths());
  }, [dispatch]);

  /**
   * On Report select change, get filter
   */
  useEffect(() => {
    const reset = () => {
      setIsGenerateReport(false);
    };

    if (
      reportTabList.length &&
      !(reportTabList[reportSelectIndex].reportType in reportFilterMap)
    ) {
      loadFilter();
    }

    reset();
  }, [reportSelectIndex]);

  useEffect(() => {
    generateReport();
    ScrollToClaimsMenuActiveItem();
  }, [selectedLangKey]);

  const loadFilter = async (
    selectedReportingLine,
    selectedEmployeeCodes,
    currentForm = null
  ) => {
    setIsFilterLoading(true);
    const selectedReport = reportTabList[reportSelectIndex].reportType;
    const res = await getFilterOptions(
      selectedReport,
      selectedReportingLine,
      selectedEmployeeCodes
    );
    if (isResponseOk(res) && res.data) {
      const filterResult = parseApiFilter(selectedReport, res.data, t);
      const filters = filterResult.filters;
      setReportFilterFormConfig(filterResult.configs);

      setReportFilterMap((filterMap) => {
        let result = {
          ...filterMap,
          [selectedReport]: filters,
        };

        // column visiblity
        if (Array.isArray(res.data?.columns) && res.data?.columns?.length > 0) {
          result[selectedReport].tableColumnsSelect = res.data?.columns;
        }

        return result;
      });

      const defaultFilerValue = prepareDefaultFilterValue(
        selectedReport,
        filters
      );

      if (
        !!selectedReportingLine &&
        Array.isArray(filters?.reportingLineFilterOption)
      ) {
        var reportingLineValues = Array.from(
          filters.reportingLineFilterOption,
          (x) => x.value
        );
        defaultFilerValue.reportingLine = reportingLineValues.find(
          (x) => selectedReportingLine === x
        );
      }

      if (
        !!selectedEmployeeCodes &&
        Array.isArray(filters?.employeeCodeFilterOption)
      ) {
        var employeeCodeValues = Array.from(
          filters.employeeCodeFilterOption,
          (x) => x.value
        );
        var currentEmployeeCodeValues = employeeCodeValues.filter((x) =>
          selectedEmployeeCodes.includes(x)
        );
        if (Array.isArray(currentEmployeeCodeValues)) {
          defaultFilerValue.employeeCode = [...currentEmployeeCodeValues];
        }
      }

      if (!!currentForm) {
        //unselect value arent in the options
        const _currentForm = { ...currentForm };
        Object.entries(_currentForm).forEach((x) => {
          const [key, value] = x;
          defaultFilerValue[key] = value;
          if (!!filters[key] && Array.isArray(value)) {
            const filterValue = Array.from(filters[key], (x) => x.value);
            defaultFilerValue[key] = value.filter((f) =>
              filterValue.includes(f)
            );
            return;
          }
        });
      }
      setReportFilterFormMap((formMap) => ({
        ...formMap,
        [selectedReport]: {
          ...defaultFilerValue,
        },
      }));

      setIsFilterLoading(false);
    }
  };

  // Action
  const generateReport = () => {
    if (!isGenerateReport) {
      setIsGenerateReport(true);
    } else {
      tableRef.current.reload();
    }
  };

  const clear = () => {
    const selectedReport = reportTabList[reportSelectIndex].reportType;
    setReportFilterFormMap((formMap) => ({
      ...formMap,
      [selectedReport]: {},
    }));
    setIsGenerateReport(false);
  };

  const back = () => {
    history.goBack();
  };

  const addSelectedColumn = (payload) => {
    const selectedColumns = tableRef.current.getSelectedColumns();
    if (!!selectedColumns && selectedColumns.length > 0) {
      payload.selectedColumns = Array.from(selectedColumns, (x) => x.key);
    }
  };

  const exportReport = async () => {
    let res;
    const selectedReport = reportTabList[reportSelectIndex].reportType;
    const fileName = `${reportTabList[reportSelectIndex].label}.xlsx`;
    const payload = prepareFilterPayload(
      selectedReport,
      reportFilterFormMap[selectedReport]
    );

    showToast({
      summary: t("claims_report_informationOnGenerate"),
      severity: MESSAGE_SEVERITY.INFO,
    });

    if (!!reportFilterFormConfig && reportFilterFormConfig.length > 0) {
      reportFilterFormConfig.forEach((config) => {
        let formValues = reportFilterFormMap[selectedReport];
        if (!!formValues) {
          let value = formValues[config?.key];
          payload[config?.key] = value;
        }
      });
    }

    addSelectedColumn(payload);

    switch (selectedReport) {
      case CLAIMS_REPORT_TYPE.OT_MEAL_ALLOWANCE_TRANSACTION_REPORT:
        res =
          await ClaimsReportService.claimsReportExportOtClaimTransactionReport(
            {
              body: payload,
            },
            {
              responseType: "blob",
            }
          );
        break;
      case CLAIMS_REPORT_TYPE.OT_CLAIM_SUMMARY_REPORT:
        res = await ClaimsReportService.claimsReportExportOtClaimSummaryReport(
          {
            body: payload,
          },
          {
            responseType: "blob",
          }
        );
        break;
      case CLAIMS_REPORT_TYPE.OT_CLAIM_DETAILS_REPORT:
        res = await ClaimsReportService.claimsReportExportOtClaimDetailsReport(
          {
            body: payload,
          },
          {
            responseType: "blob",
          }
        );
        break;
      case CLAIMS_REPORT_TYPE.CLAIM_DETAILS_REPORT:
        res = await ClaimsReportService.claimsReportExportClaimDetailsReport(
          {
            body: payload,
          },
          {
            responseType: "blob",
          }
        );
        break;
      case CLAIMS_REPORT_TYPE.CLAIM_SUMMARY_REPORT:
        res = await ClaimsReportService.claimsReportExportClaimSummaryReport(
          {
            body: payload,
          },
          {
            responseType: "blob",
          }
        );
        break;
      default:
        break;
    }

    if (res && !res.TraceId) {
      saveAs(res, fileName);
    }
  };

  const renderHeader = () => {
    return (
      <div className="header">
        <ClaimMenu
          menu={claimsMenu}
          currentPageKey={CLAIMS_MENU_PAGE_KEY.ENQUIRY_REPORT}
        />
        <div className="title">{t("claims_report_title")}</div>
      </div>
    );
  };

  const renderFilterSection = () => {
    if (!reportTabList.length) return;
    const selectedReport = reportTabList[reportSelectIndex].reportType;
    const filterWithOption = [];
    if (reportFilterMap[selectedReport]) {
      Object.entries(reportFilterMap[selectedReport]).forEach(
        ([key, options]) => {
          if (options.length > 0) {
            filterWithOption.push(key);
          }
        }
      );
    }

    const formConfig = getReportFilterConfig(
      selectedReport,
      {
        ...reportFilterMap[selectedReport],
        t,
      },
      filterWithOption,
      reportFilterFormConfig
    );

    if (Array.isArray(formConfig.controls)) {
      formConfig.controls = [...formConfig.controls].map((cfg) => {
        if (!cfg.config) {
          cfg.config = {};
        }
        cfg.config.disabled = isFilterLoading;

        return cfg;
      });
    }

    return (
      <div className="section-container filter-container">
        <BaseForm
          id="leave-report-filter-form"
          config={formConfig}
          form={reportFilterFormMap?.[selectedReport]}
          onChange={(change) => {
            let isReportingLineReset = false;

            // clear reporting line whenever employee id updated
            const form = change.state.form;
            if (
              ["reportingLine", "employeeCode"].includes(
                change?.changed?.control?.key
              ) ||
              isReportingLineReset
            ) {
              // reactive to reporting line selection
              return loadFilter(form.reportingLine, form.employeeCode, form);
            }

            setReportFilterFormMap((formMap) => ({
              ...formMap,
              [selectedReport]: {
                ...change.state.form,
              },
            }));
          }}
        />
      </div>
    );
  };

  const renderTabSelect = () => {
    return (
      <div className="report-tab">
        <Animated
          animationIn="slideInRight"
          animationOut="slideOutRight"
          animationInDuration={200}
          animationOutDuration={200}
          isVisible={true}
        >
          {isMobile ? (
            <div className="dropdown-wrapper">
              <Dropdown
                value={reportTabList[reportSelectIndex].value}
                options={reportTabList}
                onChange={(e) => {
                  setReportSelectIndex(e.value);
                }}
              />
            </div>
          ) : (
            <TabMenu
              model={reportTabList}
              activeIndex={reportSelectIndex}
              onTabChange={(e) => {
                setReportSelectIndex(e.index);
              }}
            />
          )}
        </Animated>
      </div>
    );
  };

  const fetchReport = async (searchConfig = DEFAULT_BT_SEARCH) => {
    if (!searchConfig?.ps && !searchConfig?.pi) {
      searchConfig = DEFAULT_BT_SEARCH;
    }
    const { pi, ps } = searchConfig;
    const selectedReport = reportTabList[reportSelectIndex].reportType;
    const payload = prepareFilterPayload(
      selectedReport,
      reportFilterFormMap[selectedReport]
    );

    if (!!reportFilterFormConfig && reportFilterFormConfig.length > 0) {
      reportFilterFormConfig.forEach((config) => {
        let formValues = reportFilterFormMap[selectedReport];
        if (!!formValues) {
          let value = formValues[config?.key];
          payload[config?.key] = value;
        }
      });
    }

    addSelectedColumn(payload);

    let res;
    switch (selectedReport) {
      case CLAIMS_REPORT_TYPE.OT_MEAL_ALLOWANCE_TRANSACTION_REPORT:
        res = await ClaimsReportService.claimsReportGetOtClaimTransactionReport(
          {
            page: pi,
            pageSize: ps,
            body: payload,
          }
        );
        break;
      case CLAIMS_REPORT_TYPE.OT_CLAIM_SUMMARY_REPORT:
        // replace
        res = await ClaimsReportService.claimsReportGetOtClaimSummaryReport({
          page: pi,
          pageSize: ps,
          body: payload,
        });
        break;
      case CLAIMS_REPORT_TYPE.OT_CLAIM_DETAILS_REPORT:
        res = await ClaimsReportService.claimsReportGetOtClaimDetailsReport({
          page: pi,
          pageSize: ps,
          body: payload,
        });
        break;
      case CLAIMS_REPORT_TYPE.CLAIM_DETAILS_REPORT:
        res = await ClaimsReportService.claimsReportGetClaimDetailsReport({
          page: pi,
          pageSize: ps,
          body: payload,
        });
        break;
      case CLAIMS_REPORT_TYPE.CLAIM_SUMMARY_REPORT:
        res = await ClaimsReportService.claimsReportGetClaimSummaryReport({
          page: pi,
          pageSize: ps,
          body: payload,
        });
        break;
      default:
        break;
    }

    const tableSearchConfig = searchConfig
      ? {
          ...DEFAULT_BT_SEARCH,
          pi: pi,
          ps: ps,
        }
      : DEFAULT_BT_SEARCH;

    if (isResponseOk(res)) {
      return {
        datas: res.data,
        searchConfig: tableSearchConfig,
        total: res.pagination.totalRecords,
      };
    }

    return {
      datas: [],
      searchConfig: DEFAULT_BT_SEARCH,
      total: 0,
    };
  };

  const loadExtraFieldsConfig = async () => {
    let typeName = WEB_PARAMETER_SETTING_TYPE.OT_CLAIM_TRANS_LIST_EXTRA_FIELDS;
    let res = await ClaimsService.claimGetParameters({ typeName });
    return isResponseOk(res) && res.data ? res.data : "";
  };

  const renderReportSection = (configList) => {
    if (!reportTabList.length || !isGenerateReport) return null;

    let columns = getReportColumnConfig(
      reportTabList[reportSelectIndex].reportType,
      t
    );

    let defaultList = Object.keys(CLAIMS_REPORT_EXTRA_FIELDS);
    let removedList = defaultList
      .filter(
        (dl) => !configList.some((cl) => cl.toLowerCase() === dl.toLowerCase())
      )
      .map((key) => CLAIMS_REPORT_EXTRA_FIELDS[key]);

    columns = columns.filter(
      (cl) =>
        !removedList.find((rl) => rl.toLowerCase() === cl.key.toLowerCase())
    );

    if (
      Array.isArray(
        reportFilterMap[reportTabList[reportSelectIndex].reportType]
          ?.tableColumnsSelect
      )
    ) {
      let tableColumnsSelect = Array.from(
        reportFilterMap[
          reportTabList[reportSelectIndex].reportType
        ]?.tableColumnsSelect?.filter((x) => x.show),
        (x) => x.field.toLowerCase()
      );
      columns = columns.map((x) => {
        if (!tableColumnsSelect.includes(x.key.toLowerCase())) {
          x.isDefaultHide = true;
        }

        return x;
      });
    }

    const configModel = getBTConfig({
      columns: columns,
      hasIndex: false,
      mode: "list",
      defaultMode: "list",
      showGlobal: false,
      hasColumnSelector: false,
      rowHover: true,
    });

    const baseTableProp = {
      ref: tableRef,
      configModel: configModel,
      searchConfig: DEFAULT_BT_SEARCH,
      searchFn: fetchReport,
      disableMobile: true,
    };

    delete baseTableProp?.isClientSize;

    return (
      <div className="section-container report-container">
        <div className="report-title">
          {reportTabList[reportSelectIndex].label}
        </div>
        <BaseTable {...baseTableProp} />
      </div>
    );
  };

  const renderFooter = () => (
    <div className="footer p-grid p-align-center p-justify-between">
      <div className="left-button-group">
        <Button className="p-button-outlined secondary" onClick={back}>
          {t("leave_common_actionBack")}
        </Button>
        {reportTabList.length > 0 && (
          <Button className="p-button-outlined secondary" onClick={clear}>
            {t("leave_common_actionClear")}
          </Button>
        )}
      </div>

      <div className="right-button-group">
        {reportTabList.length > 0 && isGenerateReport && (
          <Button className="p-button" onClick={exportReport}>
            <span className="btn-desktop-text">
              {t("claims_report_actionExport")}
            </span>
            <span className="btn-mobile-text">
              {t("claims_report_actionExportMobile")}
            </span>
          </Button>
        )}

        {reportTabList.length > 0 && (
          <Button className="p-button" onClick={generateReport}>
            <span className="btn-desktop-text">
              {t("claims_report_actionGenerate")}
            </span>
            <span className="btn-mobile-text">
              {t("claims_report_actionGenerateMobile")}
            </span>
          </Button>
        )}
      </div>
    </div>
  );

  var [configList, setConfigList] = useState([]);
  useEffect(() => {
    loadExtraFieldsConfig().then((res) => {
      setConfigList(res.split(","));
    });
  }, []);

  return (
    <>
      <LanguageLoader
        contexts={[
          EEPORTAL_LABEL_BASE_TABLE.BASE_TABLE,
          CLAIMS_T_CONTEXT_KEY.COMMON,
          CLAIMS_T_CONTEXT_KEY.REPORT,
          "Claims.Report.Columns",
        ]}
      />

      <div className="leave-container leave-report">
        {/* Header */}
        {renderHeader()}

        {/* Filter */}
        <Animated
          animationIn="slideInRight"
          animationOut="slideOutRight"
          animationInDuration={200}
          animationOutDuration={200}
          isVisible={true}
        >
          {renderFilterSection()}
          {renderTabSelect()}
        </Animated>

        {/* Report */}
        <Animated
          animationIn="slideInRight"
          animationOut="slideOutRight"
          animationInDuration={200}
          animationOutDuration={200}
          isVisible={true}
        >
          {renderReportSection(configList)}
        </Animated>

        {/* Footer */}
        {renderFooter()}
      </div>
    </>
  );
};

export default Report;
