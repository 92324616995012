import TimesheetService from "../../../../../services/hrmnet-api/timesheet";
import { TIMESHEET_MENU_ACTION_KEY } from "../../constants";

export const getDataSource = (key: TIMESHEET_MENU_ACTION_KEY) => {
  switch (key) {
    case TIMESHEET_MENU_ACTION_KEY.TIMESHEET_DELEGATED_APPLICATIONS:
      return TimesheetService.getDelegatedApplications;

    case TIMESHEET_MENU_ACTION_KEY.TIMESHEET_HR_APPLICATIONS:
      return TimesheetService.getAllApplications;

    case TIMESHEET_MENU_ACTION_KEY.TIMESHEET_MY_APPLICATIONS:
    default:
      return TimesheetService.getMyApplications;
  }
};
