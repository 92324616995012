import { Reducer } from "react";
import { ApplicationAction, ApplicationPayload } from "../action/application";
import { ActionMap } from "../interface";
import { TIMESHEET_DETAIL_TYPE } from "../../constants/type";
import { TIMESHEET_ACTIVITY_STATUS } from "../../constants";
import { NElasticDataHModel } from "../../models/timesheet-application-model";

export interface ITimesheetPeriod {
  id: number;
  desc: string;
  startDate: string;
  endDate: string;
}

export interface ITimesheetDetailActivity {
  id?: number;
  activityCode?: string;
  activityId?: number;
  fromDate: string;
  toDate: string;
  fromTime: string;
  toTime: string;
  appliedUnits: number;
  excludeMealTime?: boolean;
  remarks?: string;
  aprvRemark?: string;
  status?: TIMESHEET_ACTIVITY_STATUS;
  amended?: boolean;
}

export interface ITimesheetDetail {
  periodId?: number;
  period?: ITimesheetPeriod;
  timesheetId?: number;
  totalHours: number;
  totalAppliedHours: number;
  // employeeName, employeeCode and approverName for approval
  employeeName?: string;
  employeeCode?: string;
  approverName?: string;
  activities: ITimesheetDetailActivity[];
  nElasticDataH?: NElasticDataHModel;
}
type ITimesheetDetailInitialState = ITimesheetDetail;

export interface IApplicationState {
  application: ITimesheetDetailInitialState;
  mode: TIMESHEET_DETAIL_TYPE;
  hasEdit: boolean;
}

export const initialApplicationState: IApplicationState = {
  application: {
    periodId: undefined,
    period: undefined,
    timesheetId: undefined,
    totalHours: 0,
    totalAppliedHours: 0,
    activities: [],
    nElasticDataH: undefined,
  },
  mode: TIMESHEET_DETAIL_TYPE.SUBMIT,
  hasEdit: false,
};

export type ApplicationReducer = Reducer<
  IApplicationState,
  ActionMap<ApplicationAction, ApplicationPayload>
>;

export const applicationReducer: ApplicationReducer = (
  state = initialApplicationState,
  action
) => {
  switch (action.type) {
    case ApplicationAction.SET_APPLICATION:
      return {
        ...state,
        application: action.payload.application,
        hasEdit: action.payload.hasEdit || false,
      };
    case ApplicationAction.RESET_APPLICATION:
      return initialApplicationState;
    case ApplicationAction.SET_TIMESHEET_DETAIL_TYPE:
      return {
        ...state,
        mode: action.payload.mode,
      };
    default:
      return state;
  }
};
