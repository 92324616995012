export enum TIMESHEET_STATUS {
  DRAFT = "Draft",
  SUBMITTED = "Timesheet application submit",
  DELETED = "Deleted",
  APPROVED = "Timesheet application approved",
  AMENDED = "TimesheetStatusAmended",
  RETURNED = "Returned",
  REJECTED = "Timesheet application rejected",
}

// TODO: Update the activity status with the confirmed (workflow) activity status
export enum TIMESHEET_ACTIVITY_STATUS {
  APPROVED = "Approved",
  DRAFT = "Draft",
  REJECTED = "Rejected",
  SUBMITTED = "Submitted",
}
