import merge from "deepmerge";
import {
  DEFAULT_DATETIME_FORMAT,
  HTML_ATTIBUTES_ONCLICK,
  HTML_ATTIBUTES_VALUE,
  MESSAGE_SEVERITY,
} from "../../constants";
import {
  CSVToArray,
  ToUIOption,
} from "../../portal/modules/public/login/components/utils";
import { setMainForm, setMainFormConfig } from "../../redux/actions/form";
import { store } from "../../redux/store";
import { showToast } from "../../services/utils/message";
import { processAttachmentControlConfig } from "../../utils/utils";
import { ArrayCRUDType } from "../base-control/array-control/array-control";
import { BCProps, BCType } from "../base-control/base-control";
import { downloadFile } from "../base-control/upload-control/utils";

// Config Control Type
enum CCType {
  HIDDEN_FIELD = "HiddenField",
  LABEL = "Label",
  HINT = "Hint",
  TEXT_BOX = "TextBox",
  DROPDOWN_LIST = "DropDownList",
  DROPDOWN_LIST_MULTISELECT = "DropDownListMultiselect",
  CHECKBOX = "CheckBox",
  NUMBERIC_BOX = "NumericBox",
  DATE_PICKER = "DatePicker",
  DATETIME_PICKER = "DateTimePicker",
  TIME_PICKER = "TimePicker",
  NOTES = "Notes",
  ATTACHMENT = "Attachment",
  COMMENT_LIST = "CommentList",
  CLAIM_SUMMARY = "ClaimList",
  APPLICATION_TYPE = "ApplicationType",
  CLAIM_SELECT_MONTH = "MonthSelection",
  APPROVE_CLAIM = "ApproveClaim",
  KENDO_EDITOR = "KendoEditor",
  AUTO_COMPLETE = "AutoComplete",
  TOGGLE = "Toggle",
  TEXT_AREA = "TextArea",
  CHIPS = "Chips",
  TREE_TABLE = "TreeTable",
  DATA_TABLE = "DataTable",
  INPUT_GROUP = "InputGroup",
  ARRAY_CONTROL = "ArrayControl",
}

enum CustomFunctionKey {
  UPLOAD_CONTROL_ON_DOWNLOAD = "uploadControlOnDownload",
  APPLICATION_TYPE_CONTROL_ON_SELECT = "applicationTypeControl/onSelect",
  APPLICATION_TYPE_CONTROL_ON_SELECT_NEW = "applicationTypeControl/onSelectNew",
  DEPENDENT_FIELD_ACTION = "common/dependentFieldAction",
  SELECT_EMPLOYEE = "select-employee",

  // event function
  EVENT_FUNCTION_DOWNLOAD_FILE = "downloadFile",
}

interface ConfigCustomFunction {
  [key: string]: any;
}

interface AttachmentControlConfig {
  description: string;
  allowedFileExt: string;
  personalContent: boolean;
  allowedFileSize: number;
  allowedNoOfFiles: number;
}

const LabelControl = (props: any) => (
  <span className="label-control">{props.value}</span>
);

interface customDataInterface {
  sectionFieldConfig?: any;
  customFunctionLibrary?: ConfigCustomFunction;
  comments?: any[]; // CommentList
  claims?: any[]; // ClaimList
  previousApplications?: any[]; // ApplicationList
  openClaims?: any[]; // MonthSelection
  attachmentControlConfig?: AttachmentControlConfig;
}

interface StepNavModel {
  label: string;
}

interface onTrueUpdateInterface {
  update: {
    prevValue?: any;
    value: any;
    controlState?: any;
  };
  control: FieldConfiguration;
  section: SectionConfig;
}

export const DEPENDENT_FIELD_ACTION_WILDCARD = "$any";
enum DEPENDENT_FIELD_ACTION_TYPE {
  HIDE = "Hide",
  SHOW = "Show",
  GROUP_FILTER_ENUM = "GroupFilterEnum",
}

interface StepConfig {
  id: number;
  stepName: string;
  displaySequnce: number;
  sections: StepSectionConfig[];
}

interface SectionConfig {
  id: number;
  sectionName: string;
  displaySequence: number;
  displayLayout?: number;
  defaultExpanded?: boolean;
  fields: FieldConfiguration[];
}

interface FieldConfiguration {
  id: number;
  viewSectionId?: number;
  fieldName: string;
  fieldLabel?: string;
  controlType: CCType;
  inputHint?: string;
  length?: number;
  formatString?: string;
  displaySequence: number;
  defaultValue?: any;
  value?: any;
  isAllowNull?: boolean;
  isEditable?: boolean;
  isHideLabelField?: boolean;
  isHtmlField?: boolean;
  isSpecialField?: boolean;
  isDefaultHide?: boolean;
  isDetailView?: boolean;
  styleClass?: string;
  eventFunctionName?: any;
  dependentFields?: string;
  fieldGroup?: number | null;
  config?: any;

  // frontend logic
  decimal?: number | null;
  [key: string]: any;
}

interface StepSectionConfig {
  isEditable?: boolean;
  sectionId: number;
  sectionName: string;
  displaySequence: number;
  isDefaultHide?: boolean;
  targetOrganizations?: string[];
}

interface DependentFieldActionInterface {
  Value: any;
  Action: DEPENDENT_FIELD_ACTION_TYPE;
  Fields: string;
  Sections: string;
}

interface FormConfigControlInterface extends BCProps {
  key: string;
  refKey: string;
  dependentFields: any;
  layoutConfig?: any;
  hintBottom?: any;
  hintRight?: any;
  fieldGroup?: any;
  targetOrganizations?: string[];
  [key: string]: any;
}

interface FormConfigSectionInterface {
  readOnly: boolean;
  sectionId: number;
  title: string;
  displayLayout?: number;
  defaultExpanded: boolean | null;
  controls: FormConfigControlInterface[];
  config: {
    hidden: boolean;
  };
  [key: string]: any;
}

interface FormConfigInterface {
  applicationId?: any;
  formValue?: any;
  dropdownOptions?: any;
  steps: {
    sections: FormConfigSectionInterface[];
  }[];
}

const DEPENDENT_FIELD_SUPPORT_CC_TYPE = [
  CCType.DROPDOWN_LIST,
  CCType.TEXT_BOX,
  CCType.DATE_PICKER,
  CCType.TOGGLE,
  CCType.AUTO_COMPLETE,
  CCType.DROPDOWN_LIST_MULTISELECT,
];

const DISPLAY_LAYOUT_TYPE = {
  HORIZONTAL: 1,
  GRID: 2,
  SECTION_TABLE: 3,
};

function createHint(hint: string | undefined | null) {
  return hint ? (
    <span dangerouslySetInnerHTML={{ __html: hint } as any}></span>
  ) : null;
}

export function BaseInitDependentFieldActionFn({
  formConfig,
  form,
}: {
  formConfig: FormConfigInterface;
  form: any;
}) {
  return initDependentFieldActionFn({
    formConfig,
    form,
  });
}

function initDependentFieldActionFn({
  formConfig,
  form,
}: {
  formConfig: FormConfigInterface;
  form: any;
}) {
  let _formConfig = { ...formConfig };

  function hideShow({
    action,
    show,
  }: {
    action: DependentFieldActionInterface;
    show: boolean;
  }) {
    action.Fields.split(",").forEach((field) => {
      field = field.trim();
      const controlConfig = controlConfigChangeMap[field] || {};
      const layoutConfig = controlConfig.layoutConfig || {};
      controlConfigChangeMap[field] = {
        ...controlConfig,
        layoutConfig: {
          ...layoutConfig,
          hidden: !show,
        },
      };
    });
    action.Sections.split(",").forEach((section) => {
      section = section.trim();
      const sectionConfig = sectionConfigChangeMap[section.toString()] || {};
      const sectionConfigConfig = sectionConfig.config || {};
      sectionConfigChangeMap[section] = {
        ...sectionConfig,
        config: {
          ...sectionConfigConfig,
          hidden: !show,
        },
      };
    });
  }

  function updateEnum({
    action,
    filterValue,
    sections,
  }: {
    action: DependentFieldActionInterface;
    filterValue: any;
    sections: any;
  }) {
    action.Fields.split(",").forEach((field) => {
      field = field.trim();
      const controlConfig = sections.controls.find((x: any) => x.key === field);
      if (controlConfig?.hasOwnProperty("enum")) {
        const _enum = controlConfig.enum?.filter((x: any) => {
          if (x.hasOwnProperty("group") && Array.isArray(filterValue)) {
            return filterValue.includes(x.group);
          }
          if (
            x.hasOwnProperty("group") &&
            typeof filterValue === "string" &&
            !!filterValue
          ) {
            return x.group === filterValue;
          }

          return false;
        });

        if (!!controlConfigChangeMap[field]) {
          controlConfigChangeMap[field] = {
            ...controlConfigChangeMap[field],
            enum: _enum,
          };
        } else {
          controlConfigChangeMap[field] = {
            enum: _enum,
          };
        }
      }
    });
  }

  // Create sectionConfigChangeMap and controlConfigChangeMap to save what to update for each section/control
  let sectionConfigChangeMap: any = {};
  let controlConfigChangeMap: any = {};
  formConfig.steps.forEach((step) => {
    step.sections.forEach((section) => {
      section.controls.forEach((control) => {
        const formValue = form[control.key] || control.value;
        if (control.dependentFields) {
          const actions = JSON.parse(
            control.dependentFields
          ) as DependentFieldActionInterface[];
          actions.forEach((action) => {
            const isChanging = changeDependentFieldFunction(formValue, action);
            if (!isChanging) return;

            switch (action.Action) {
              case DEPENDENT_FIELD_ACTION_TYPE.HIDE:
                hideShow({ action: action, show: false });
                break;
              case DEPENDENT_FIELD_ACTION_TYPE.SHOW:
                hideShow({ action: action, show: true });
                break;
              case DEPENDENT_FIELD_ACTION_TYPE.GROUP_FILTER_ENUM:
                updateEnum({
                  action: action,
                  filterValue: formValue,
                  sections: section,
                });
                break;
              default:
                break;
            }
          });
        }
      });
    });
  });

  // apply changes
  formConfig.steps.forEach((step, stepIdx) => {
    step.sections.forEach((section, sectionIdx) => {
      const sectionChange =
        sectionConfigChangeMap[section.sectionId.toString()];
      if (sectionChange) {
        _formConfig.steps[stepIdx].sections[sectionIdx] = merge(
          _formConfig.steps[stepIdx].sections[sectionIdx],
          sectionChange
        );
      }
      section.controls.forEach((control, ctrlIdx) => {
        const controlChange = controlConfigChangeMap[control.refKey];
        if (controlChange) {
          _formConfig.steps[stepIdx].sections[sectionIdx].controls[ctrlIdx] =
            merge(
              _formConfig.steps[stepIdx].sections[sectionIdx].controls[ctrlIdx],
              controlChange
            );

          //somehow not merging enum = [], shallow merging
          if (
            !!controlChange?.enum &&
            _formConfig.steps[stepIdx].sections[sectionIdx].controls[ctrlIdx]
              ?.enum !== controlChange?.enum
          ) {
            _formConfig.steps[stepIdx].sections[sectionIdx].controls[
              ctrlIdx
            ].enum = controlChange?.enum;
          }
        }
      });
    });
  });
  return _formConfig;
}

/**
 *
 * @param inputHint
 * @returns string[] : [hintBottom, hintRight]
 */
function extractInputHint(inputHint: string | undefined | null) {
  if (!inputHint) return [null, null];
  if (inputHint.startsWith("{(")) {
    let retArray = [null, null];
    try {
      let hintObj = JSON.parse(inputHint.slice(2, -2));
      retArray[0] = hintObj?.m?.text?.trim();
      retArray[1] = hintObj?.l?.text?.trim();
    } catch {}
    return retArray;
  } else {
    return [inputHint, null];
  }
}

function getFieldKey(field: any) {
  // return `${field.id}|${field.fieldName}`;
  return field.fieldName;
}

function convertConfigToControl(
  stepSectionConfig: StepSectionConfig,
  sectionConfig: SectionConfig,
  fieldConfig: FieldConfiguration | null,
  customData: customDataInterface,
  t: any,
  dropdownOptions?: any
) {
  if (!fieldConfig) return null;

  // Base configuration
  const [hintBottom, hintRight] = extractInputHint(fieldConfig.inputHint);
  let baseControlProps: FormConfigControlInterface = {
    span: 0,
    key: getFieldKey(fieldConfig),
    refKey: fieldConfig.fieldName,
    label: fieldConfig.fieldLabel || fieldConfig.fieldName,
    noLabel: !!fieldConfig.isHideLabelField,
    required:
      stepSectionConfig.isEditable &&
      fieldConfig.isEditable &&
      !fieldConfig.isAllowNull,
    // maxLength: fieldConfig.length ? fieldConfig.length : undefined,
    defaultValue: fieldConfig.defaultValue,
    hintBottom: createHint(hintBottom),
    hintRight: createHint(hintRight),
    config: {
      ...fieldConfig.config,
      readOnly: !fieldConfig.isEditable || !stepSectionConfig.isEditable,
    },
    layoutConfig: {
      className: `p-col-12 ${
        fieldConfig.styleClass
          ? fieldConfig.styleClass
          : "p-lg-4 p-md-6 p-sm-12"
      }`,
      holderClassName: fieldConfig.controlStyle
        ? `${fieldConfig.controlStyle}`
        : "",
      hidden: fieldConfig.isDefaultHide,
    },
    dependentFields: fieldConfig.dependentFields,
    fieldGroup: fieldConfig.fieldGroup,
    value: fieldConfig.value,
  };

  // Handle dynamic event function
  let eventFunctionName = fieldConfig.eventFunctionName || "";

  // Add custom eventFunction
  if (
    DEPENDENT_FIELD_SUPPORT_CC_TYPE.includes(fieldConfig.controlType) &&
    fieldConfig.dependentFields
  ) {
    eventFunctionName = eventFunctionName
      ? eventFunctionName
          .split(",")
          .concat(CustomFunctionKey.DEPENDENT_FIELD_ACTION)
          .join(",")
      : CustomFunctionKey.DEPENDENT_FIELD_ACTION;
  }

  // Populate all event functions into onTrueUpdateValue props
  if (eventFunctionName) {
    const { fields, ...restSectionConfig } = sectionConfig;
    const updateFunction = async (change: any) => {
      for (const fnName of eventFunctionName.split(",")) {
        if (customData?.customFunctionLibrary?.[fnName]) {
          await customData?.customFunctionLibrary?.[fnName]({
            update: change,
            control: fieldConfig,
            section: restSectionConfig,
          } as any);
        }
      }
    };
    baseControlProps.onTrueUpdateValue = updateFunction as any;
  }

  if (
    dropdownOptions?.hasOwnProperty(baseControlProps.refKey) &&
    !fieldConfig.dropdownOptions
  ) {
    fieldConfig.dropdownOptions = dropdownOptions[baseControlProps.refKey];
  }

  // html onclick handler for Hint/Note
  const htmlOnClickHandler = (e: any) => {
    if (e.target?.attributes?.[HTML_ATTIBUTES_ONCLICK]) {
      const fn = e.target.attributes[HTML_ATTIBUTES_ONCLICK].value;
      const value = e.target.attributes[HTML_ATTIBUTES_VALUE]?.value;
      if (
        fn &&
        customData.customFunctionLibrary &&
        fn in customData.customFunctionLibrary
      ) {
        customData.customFunctionLibrary[fn](value);
      }
    }
  };

  switch (fieldConfig.controlType) {
    case CCType.HIDDEN_FIELD:
      return {
        ...baseControlProps,
        span: 0,
        componentRender: () => (
          <input hidden value={fieldConfig.defaultValue} />
        ),
        layoutConfig: {
          ...baseControlProps.layoutConfig,
          className: `${baseControlProps.layoutConfig.className} hidden`,
        },
      };
    case CCType.LABEL:
      return {
        ...baseControlProps,
        type: "rental-label",
        required: false,
        componentRender: (props: any) => <LabelControl {...props} />,
      };
    case CCType.HINT:
      return {
        ...baseControlProps,
        type: "rental-hint",
        required: false,
        noLabel: true,
        componentRender: () => (
          <span
            onClick={htmlOnClickHandler}
            className="form-hint"
            dangerouslySetInnerHTML={
              {
                __html: fieldConfig.value
                  ? fieldConfig.value
                  : fieldConfig.defaultValue,
              } as any
            }
          ></span>
        ),
      };
    case CCType.TEXT_BOX: {
      const { maxLength, ...config } = baseControlProps.config;
      return {
        ...baseControlProps,
        type: BCType.input,
        placeholder: t("base_control_input_text"),
        maxLength: maxLength,
        config: config,
      };
    }
    case CCType.DROPDOWN_LIST: {
      return {
        ...baseControlProps,
        type: BCType.select,
        placeholder: t("base_control_select_choose"),
        enum: ToUIOption(fieldConfig.dropdownOptions),
        hasFilterEnum: false,
        config: {
          ...baseControlProps.config,
          showClear: !baseControlProps.required,
        },
      };
    }
    case CCType.DROPDOWN_LIST_MULTISELECT: {
      const ddoptions = ToUIOption(fieldConfig.dropdownOptions);

      if (
        !!baseControlProps.value &&
        typeof baseControlProps.value === "string"
      ) {
        baseControlProps.value = CSVToArray(ddoptions, baseControlProps.value);
      }
      if (
        !!baseControlProps.defaultValue &&
        typeof baseControlProps.defaultValue === "string"
      ) {
        baseControlProps.defaultValue = CSVToArray(
          ddoptions,
          baseControlProps.defaultValue
        );
      }
      if (baseControlProps.config.display === "chip") {
        delete baseControlProps.config.display;
      }

      const selectedItemTemplate = (options: any) => {
        if (options) {
          return (
            <div className="p-multiselect-token">
              <span className="p-multiselect-token-label">
                {fieldConfig?.dropdownOptions?.find(
                  (dropdownOption: any) => dropdownOption.value === options
                )?.name ?? options}
              </span>
            </div>
          );
        }
      };
      baseControlProps.config.selectedItemTemplate = selectedItemTemplate;
      return {
        ...baseControlProps,
        type: BCType.multiselect,
        placeholder: t("base_control_select_choose"),
        enum: ddoptions,
        config: { ...baseControlProps.config, ...{ filter: true } },
      };
    }
    case CCType.CHECKBOX:
      // if (
      //   !!baseControlProps.value &&
      //   typeof baseControlProps.value === "string"
      // ) {
      //   baseControlProps.value =
      //     baseControlProps.value.toLowerCase() === "true";
      // }
      // if (
      //   !!baseControlProps.defaultValue &&
      //   typeof baseControlProps.defaultValue === "string"
      // ) {
      //   baseControlProps.defaultValue =
      //     baseControlProps.defaultValue.toLowerCase() === "true";
      // }

      return {
        ...baseControlProps,
        type: BCType.checkbox,
      };
    case CCType.NUMBERIC_BOX:
      const readOnly =
        baseControlProps.config.readOnly || baseControlProps.config.isEditable;
      // special logic: if readonly, 2 decimal
      const decimal = readOnly ? 2 : 0;

      if (
        !!baseControlProps.value &&
        typeof baseControlProps.value === "string"
      ) {
        baseControlProps.value = parseInt(baseControlProps.value);
      }
      if (
        !!baseControlProps.defaultValue &&
        typeof baseControlProps.defaultValue === "string"
      ) {
        baseControlProps.defaultValue = parseInt(baseControlProps.defaultValue);
      }

      return {
        ...baseControlProps,
        type: BCType.number,
        min: 0,
        placeholder: t("base_control_number_input_number"),
        placeholderStrict: true,
        config: {
          ...baseControlProps.config,
          mode: decimal ? "decimal" : undefined,
          minFractionDigits: decimal,
          maxFractionDigits: decimal,
        },
      };

    case CCType.DATETIME_PICKER: {
      // Custom Tricor <-> Primereact DateFormat mapper
      let dateFormat = fieldConfig.formatString || "yyyy-MM-dd";
      const placeholder = dateFormat.toUpperCase();
      if (dateFormat.includes("yyyy")) {
        dateFormat = dateFormat.replace("yyyy", "yy");
      } else if (dateFormat.includes("yy")) {
        dateFormat = dateFormat.replace("yy", "y");
      }

      if (dateFormat.includes("MM")) {
        dateFormat = dateFormat.replace("MM", "mm");
      } else if (dateFormat.includes("mm")) {
        dateFormat = dateFormat.replace("mm", "MM");
      }

      //expected value && defaultValue
      //2021-12-09T09:00:00
      if (typeof baseControlProps.value === "string") {
        try {
          var _value = Date.parse(baseControlProps.value);
          if (isNaN(_value) === false) {
            baseControlProps.value = new Date(_value);
          }
        } catch (error) {}
      }
      if (typeof baseControlProps.defaultValue === "string") {
        try {
          var _defaultValue = Date.parse(baseControlProps.defaultValue);
          if (isNaN(_defaultValue) === false) {
            baseControlProps.defaultValue = new Date(_defaultValue);
          }
        } catch (error) {}
      }

      return {
        ...baseControlProps,
        config: {
          showTime: true,
          hourFormat: "12",
          stepMinute: 1,
          ...baseControlProps.config,
        },
        type: BCType.date,
        placeholder: placeholder,
        dateFormat: dateFormat,
        includeTimeZone: true,
        ruleList: baseControlProps?.config?.ruleList,
      };
    }
    case CCType.DATE_PICKER:
      // Custom Tricor <-> Primereact DateFormat mapper
      let dateFormat = fieldConfig.formatString || "yyyy-MM-dd";
      const placeholder = dateFormat.toUpperCase();
      if (dateFormat.includes("yyyy")) {
        dateFormat = dateFormat.replace("yyyy", "yy");
      } else if (dateFormat.includes("yy")) {
        dateFormat = dateFormat.replace("yy", "y");
      }

      if (dateFormat.includes("MM")) {
        dateFormat = dateFormat.replace("MM", "mm");
      } else if (dateFormat.includes("mm")) {
        dateFormat = dateFormat.replace("mm", "MM");
      }

      const { includeTimeZone, ...config } = baseControlProps.config;
      if (!!includeTimeZone) {
        baseControlProps.includeTimeZone = includeTimeZone;
      }
      return {
        ...baseControlProps,
        config: {
          ...config,
        },
        type: BCType.date,
        placeholder: placeholder,
        dateFormat: dateFormat, // TODO: should use fieldConfig.formatString
        ruleList: baseControlProps?.config?.ruleList,
      };
    case CCType.TIME_PICKER: {
      const placeholder = "hh:mm a".toUpperCase();
      if (typeof baseControlProps.value === "string") {
        try {
          var _value = Date.parse(baseControlProps.value);
          if (isNaN(_value) === false) {
            baseControlProps.value = new Date(_value);
          }
        } catch (error) {}
      }
      if (typeof baseControlProps.defaultValue === "string") {
        try {
          var _defaultValue = Date.parse(baseControlProps.defaultValue);
          if (isNaN(_defaultValue) === false) {
            baseControlProps.defaultValue = new Date(_defaultValue);
          }
        } catch (error) {}
      }

      // Custom Tricor <-> Primereact DateFormat mapper
      let dateFormat = fieldConfig.formatString || DEFAULT_DATETIME_FORMAT;
      return {
        ...baseControlProps,
        config: {
          timeOnly: true,
          hourFormat: "12",
          stepMinute: 1,
          ...baseControlProps.config,
        },
        includeTimeZone: true,
        type: BCType.date,
        placeholder: placeholder,
        dateFormat: dateFormat, // TODO: should use fieldConfig.formatString
        ruleList: baseControlProps?.config?.ruleList,
      };
    }
    case CCType.ATTACHMENT: {
      if (!!baseControlProps.config?.htmlToolBar) {
        baseControlProps.config.htmlOnClickHandler = htmlOnClickHandler;
      }
      const attachmentConfig = processAttachmentControlConfig(
        customData.attachmentControlConfig
      ) as any;

      return {
        ...baseControlProps,
        type: BCType.upload,
        defaultValue: null,
        layoutConfig: {
          ...baseControlProps.layoutConfig,
          className: `${baseControlProps.layoutConfig.className} p-lg-12 p-md-12 p-sm-12`,
        },
        config: {
          ...baseControlProps.config,
          accept: attachmentConfig.allowedFileExt,
          maxFileSize: attachmentConfig.allowedFileSize,
        },
        multiple: true,
        onDownload:
          customData?.customFunctionLibrary?.[
            CustomFunctionKey.UPLOAD_CONTROL_ON_DOWNLOAD
          ],
        onFileError: ({ defaultMessage }: any) =>
          showToast({
            summary: defaultMessage,
            severity: MESSAGE_SEVERITY.WARN,
          }),
      };
    }
    case CCType.NOTES:
      return {
        ...baseControlProps,
        required: false,
        noLabel: true,
        componentRender: () => (
          <div
            onClick={htmlOnClickHandler}
            className="form-note"
            style={{ flex: 1 }}
            dangerouslySetInnerHTML={
              {
                __html: fieldConfig.value
                  ? fieldConfig.value
                  : fieldConfig.defaultValue,
              } as any
            }
          ></div>
        ),
        layoutConfig: {
          ...baseControlProps.layoutConfig,
          className: `${baseControlProps.layoutConfig.className} p-lg-12 p-md-12 p-sm-12`,
        },
      };
    case CCType.KENDO_EDITOR: {
      if (
        !!fieldConfig.dropdownOptions &&
        baseControlProps?.config &&
        baseControlProps.config?.customInsertListMultiple
      ) {
        baseControlProps.config.customInsertListMultiple =
          baseControlProps.config?.customInsertListMultiple.map((x: any) => {
            if (fieldConfig.dropdownOptions[x.id] && !x.data) {
              return {
                ...x,
                data: fieldConfig.dropdownOptions[x.id],
              };
            }
            return x;
          });
      }
      return {
        ...baseControlProps,
        type: BCType.kendorEditor,
      };
    }
    case CCType.AUTO_COMPLETE:
      return {
        enum: fieldConfig.dropdownOptions,
        hasFilterEnum: false,
        config: {
          ...baseControlProps.config,
          showClear: false,
        },
        ...baseControlProps,
        type: BCType.autocomplete,
      };
    case CCType.TOGGLE:
      return {
        ...baseControlProps,
        type: BCType.toogle,
        trueValue: baseControlProps?.config?.truevalue,
        falseValue: baseControlProps?.config?.falsevalue,
      };
    case CCType.TEXT_AREA:
      return {
        ...baseControlProps,
        type: BCType.textarea,
      };
    case CCType.CHIPS:
      return {
        ...baseControlProps,
        type: BCType.chips,
        ruleList: baseControlProps?.config?.ruleList,
      };
    case CCType.TREE_TABLE:
      return {
        ...baseControlProps,
        type: BCType.treetable,
      };
    case CCType.DATA_TABLE:
      return {
        ...baseControlProps,
        type: BCType.datatable,
      };
    case CCType.INPUT_GROUP:
      return {
        ...baseControlProps,
        enum: fieldConfig.dropdownOptions?.map((option: any) => ({
          label: option.name,
          value: option.value,
        })),
        type: BCType.inputgroup,
        placeholderText: t("base_control_input_text"),
        placeholderSelect: t("base_control_select_choose"),
      };
    case CCType.ARRAY_CONTROL: {
      if (baseControlProps?.config?.headers) {
        baseControlProps.headers = baseControlProps?.config?.headers;
      }
      if (baseControlProps?.config?.crudType) {
        baseControlProps.crudType = baseControlProps?.config?.crudType;
      } else {
        baseControlProps.crudType = ArrayCRUDType.inlinetable;
      }
      if (baseControlProps?.config?.saveButtonLabel) {
        baseControlProps.saveButtonLabel =
          baseControlProps?.config?.saveButtonLabel;
      }
      return {
        ...baseControlProps,
        type: BCType.array,
      };
    }
    default:
      return {};
  }
}

const onChangeDependentFieldActionFn = ({
  dispatch,
  action,
  update,
  section,
  control,
}: {
  dispatch: any;
  action: DependentFieldActionInterface;
  update: {
    prevValue?: any;
    value: any;
    controlState?: any;
  };
  section: SectionConfig;
  control: FieldConfiguration;
}) => {
  const updateDynamicDisplay = ({ show }: { show: boolean }) => {
    const formConfig = store.getState().commonForm.formConfig;
    const form = store.getState().commonForm.form;

    let _formConfig: FormConfigInterface = { ...formConfig };
    let _form = { ...form };

    // extract dependent fields and sections
    const dependentFields = action.Fields.split(",").map((x) => x.trim());
    const dependentSections = action.Sections
      ? action.Sections.split(",").map((x) => Number(x.trim()))
      : [];

    // special handle for DISPLAY_LAYOUT_TYPE.SECTION_TABLE
    if (section.displayLayout === DISPLAY_LAYOUT_TYPE.SECTION_TABLE) {
      const fieldGroup = control.fieldGroup;
      const sectionId = section.id;
      // loop thru formConfig, update all dependent fields and sections
      _formConfig.steps.forEach((step, sIdx) => {
        step.sections.forEach((section, secIdx) => {
          const isUpdateSection = dependentSections.includes(
            Number(section.sectionId)
          );
          if (isUpdateSection) {
            _formConfig.steps[sIdx].sections[secIdx].config.hidden = !show;
          }
          if (isUpdateSection || section.sectionId === sectionId) {
            section.controls.forEach((control, cIdx) => {
              const isUpdateField =
                dependentFields.includes(control.refKey) &&
                fieldGroup === control.fieldGroup;
              if (isUpdateSection || isUpdateField) {
                _formConfig.steps[sIdx].sections[secIdx].controls[
                  cIdx
                ].layoutConfig.hidden = !show;

                // if hide, also reset value
                if (!show) {
                  _form[control.key] = undefined;
                }
              }
            });
          }
        });
      });
    } else {
      // loop thru formConfig, update all dependent fields and sections
      _formConfig.steps.forEach((step, sIdx) => {
        step.sections.forEach((section, secIdx) => {
          const isUpdateSection = dependentSections.includes(
            Number(section.sectionId)
          );
          if (isUpdateSection) {
            _formConfig.steps[sIdx].sections[secIdx].config.hidden = !show;
          }
          section.controls.forEach((control, cIdx) => {
            const isUpdateField = dependentFields.includes(control.refKey);

            if (isUpdateSection || isUpdateField) {
              _formConfig.steps[sIdx].sections[secIdx].controls[
                cIdx
              ].layoutConfig.hidden = !show;

              // if hide, also reset value
              if (!show) {
                _form[control.key] = undefined;
              }
            }
          });
        });
      });
    }

    const _newFormConfig = initDependentFieldActionFn({
      formConfig: _formConfig,
      form: _form,
    });
    dispatch(setMainFormConfig(_newFormConfig));
    dispatch(setMainForm(_form));
  };
  const updateEnumList = () => {
    const formConfig = store.getState().commonForm.formConfig;
    const form = store.getState().commonForm.form;

    let _formConfig: FormConfigInterface = { ...formConfig };
    let _form = { ...form };

    // extract dependent fields and sections
    const dependentFields = action.Fields.split(",").map((x) => x.trim());

    // loop thru formConfig, update all dependent fields and sections
    _formConfig.steps.forEach((step, sIdx) => {
      step.sections
        .filter((section: any) => !section.config?.hidden)
        .forEach((section, secIdx) => {
          section.controls.forEach((control, cIdx) => {
            const isUpdateField = dependentFields.includes(control.refKey);

            if (isUpdateField) {
              const _currentEnum =
                _formConfig.steps[sIdx].sections[secIdx].controls[cIdx]?.enum;
              if (
                !!_currentEnum &&
                !!_formConfig?.dropdownOptions &&
                _formConfig.dropdownOptions[control.refKey]
              ) {
                const _newEnum = _formConfig.dropdownOptions[
                  control.refKey
                ].filter((x: any) => {
                  if (
                    x.hasOwnProperty("group") &&
                    Array.isArray(update.value)
                  ) {
                    return update.value.includes(x.group);
                  }
                  if (
                    x.hasOwnProperty("group") &&
                    typeof update?.value === "string" &&
                    !!update?.value
                  ) {
                    return x.group === update.value;
                  }
                  return false;
                });
                _formConfig.steps[sIdx].sections[secIdx].controls[cIdx].enum =
                  _newEnum;

                var formValue: any;
                if (typeof _form[control.key] === "string") {
                  formValue = CSVToArray(_newEnum, _form[control.key]);
                } else if (
                  !!_form[control.key] &&
                  Array.isArray(_newEnum) &&
                  _newEnum.length === 0
                ) {
                  formValue = undefined;
                } else if (
                  !!_form[control.key] &&
                  Array.isArray(_form[control.key])
                ) {
                  formValue = _form[control.key]?.filter(
                    (x: any) => x.value === _newEnum.value
                  );
                }
                _form[control.key] = formValue;
              }
            }
          });
        });
    });

    // if form is provided, apply dependentFieldAction
    const _newFormConfig = initDependentFieldActionFn({
      formConfig: _formConfig,
      form: _form,
    });
    dispatch(setMainFormConfig(_newFormConfig));
    dispatch(setMainForm(_form));
  };

  switch (action.Action) {
    case DEPENDENT_FIELD_ACTION_TYPE.HIDE:
      updateDynamicDisplay({ show: false });
      break;
    case DEPENDENT_FIELD_ACTION_TYPE.SHOW:
      updateDynamicDisplay({ show: true });
      break;
    case DEPENDENT_FIELD_ACTION_TYPE.GROUP_FILTER_ENUM:
      updateEnumList();
      break;
    default:
      break;
  }
};

export function BaseMultiStepConfigToFormModel({
  applicationId,
  stepConfig,
  sectionFieldConfig,
  customData,
  t = (x: any) => x,
  form,
  dropdownOptions,
}: {
  applicationId: any;
  stepConfig: StepConfig[];
  sectionFieldConfig: SectionConfig[];
  customData: customDataInterface;
  t: any;
  form?: any;
  dropdownOptions?: any;
}): FormConfigInterface {
  var newForm: { [k: string]: any } = {};
  let sectionFieldConfigMap: { [key: number]: SectionConfig } =
    sectionFieldConfig.reduce((acc, cur) => ({ ...acc, [cur.id]: cur }), {});
  let formModel = {
    applicationId: applicationId,
    steps: [],
  } as any;
  stepConfig.forEach((conf) => {
    let fullStepConfig = { sections: [] } as any;
    conf.sections
      ?.sort((a, b) => {
        return a.displaySequence - b.displaySequence;
      })
      .forEach((section) => {
        if (section.sectionId in sectionFieldConfigMap) {
          let filterControl: string[] = [];
          let fullSectionConfig: FormConfigSectionInterface = {
            config: {
              hidden: !!section.isDefaultHide,
            },
            readOnly: !section.isEditable,
            sectionId: section.sectionId,
            title: section.sectionName,
            displayLayout:
              sectionFieldConfigMap[section.sectionId].displayLayout,
            defaultExpanded:
              !!sectionFieldConfigMap[section.sectionId].defaultExpanded,
            controls: sectionFieldConfigMap[section.sectionId].fields
              ?.sort((a, b) => {
                return a.displaySequence - b.displaySequence;
              })
              .map((field) => {
                if (!!field?.targetOrganizations) {
                  filterControl = [
                    ...filterControl,
                    ...field?.targetOrganizations,
                  ];
                }
                const control: any = convertConfigToControl(
                  section,
                  sectionFieldConfigMap[section.sectionId],
                  field,
                  {
                    ...customData,
                    sectionFieldConfig: sectionFieldConfig,
                  },
                  t,
                  dropdownOptions
                );

                if (!!form && Object.keys(form).length === 0) {
                  if (
                    control.hasOwnProperty("defaultValue") &&
                    !!control.defaultValue
                  ) {
                    newForm[control.key] = control.defaultValue;
                  }

                  if (
                    control.hasOwnProperty("defaultValue") &&
                    typeof control.defaultValue === "number"
                  ) {
                    newForm[control.key] = control.defaultValue;
                  }

                  if (control.hasOwnProperty("value") && !!control.value) {
                    newForm[control.key] = control.value;
                  }

                  if (
                    control.hasOwnProperty("value") &&
                    typeof control.value === "number"
                  ) {
                    newForm[control.key] = control.value;
                  }

                  if (
                    !newForm.hasOwnProperty(control.key) &&
                    !!control?.enum &&
                    control.enum.length > 0
                  ) {
                    var options = control?.enum?.find(
                      (x: any) => x.value === control.defaultValue
                    );
                    if (!!options && Object.keys(options).length > 0) {
                      newForm[control.key] = control.defaultValue;
                    }

                    options = control?.enum?.find(
                      (x: any) => x.value === control.value
                    );
                    if (!!options && Object.keys(options).length > 0) {
                      newForm[control.key] = control.value;
                    }
                  }
                }
                return control;
              }) as any,
          };

          if (!!filterControl && filterControl.length > 0) {
            fullSectionConfig.filterControl = [...new Set(filterControl)];
          } else if (!!section?.targetOrganizations) {
            fullSectionConfig.filterControl = section?.targetOrganizations;
          }

          fullStepConfig.sections.push(fullSectionConfig);
        }
      });

    formModel.steps.push(fullStepConfig);
  });

  // if form is provided, apply dependentFieldAction
  if (Object.keys(newForm).length !== 0) {
    formModel = initDependentFieldActionFn({
      formConfig: formModel,
      form: newForm,
    });

    formModel.formValues = newForm;
  }
  if (!!form && Object.keys(form).length !== 0) {
    formModel = initDependentFieldActionFn({
      formConfig: formModel,
      form: form,
    });

    formModel.formValues = form;
  }

  //saving all dropdown options in formConfig for latter retrieval
  if (!!dropdownOptions) {
    var _dropdownOptions: any = {};
    Object.keys(dropdownOptions).forEach((x: string) => {
      _dropdownOptions[x] = ToUIOption(dropdownOptions[x]);
    });
    formModel.dropdownOptions = _dropdownOptions;
  }

  return formModel;
}

export function BaseMultiStepConfigToStepNavModel(
  config: StepConfig[]
): StepNavModel[] {
  return config.map((conf) => ({ label: conf.stepName }));
}

function initFormValue(
  sectionFieldConfig: SectionConfig[],
  excludeFieldTypes?: string[]
) {
  const controlList = sectionFieldConfig.reduce(
    (accList: any[], section: SectionConfig) => {
      accList = accList.concat(section.fields);
      return accList;
    },
    []
  );
  return initFormValueByControlList(controlList, excludeFieldTypes);
}

function changeDependentFieldFunction(formValue: any, action: any) {
  if (typeof action.Value === "boolean") {
    if (typeof formValue === "boolean") {
      return action.Value === formValue;
    }
    if (typeof formValue === "string") {
      return action.Value === (formValue.toLowerCase() === "true");
    }
    if (typeof formValue === "string") {
      return action.Value === (formValue.toLowerCase() === "true");
    }
  }

  if (formValue === undefined && action.Value === "undefined") {
    return true;
  }

  let actionValue = undefined;
  if (action.Value !== "undefined") {
    actionValue = action.Value;
  }

  const _actionValue = new RegExp(action.Value);
  if (_actionValue instanceof RegExp) {
    return _actionValue.test(formValue ?? "");
  } else if (
    action.Value instanceof String &&
    formValue instanceof String &&
    action.Value.split(",").length > 0 &&
    !action.Value.split(",").find((x: any) => x === formValue)
  ) {
  } else if (
    action.Value !== DEPENDENT_FIELD_ACTION_WILDCARD &&
    formValue !== actionValue
  ) {
    return false;
  }

  return true;
}

export const BaseMultiStepCustomFunctionLibrary = ({ dispatch }: any) => ({
  [CustomFunctionKey.DEPENDENT_FIELD_ACTION]: ({
    update,
    control,
    section,
  }: onTrueUpdateInterface) => {
    const dependentFieldActions: DependentFieldActionInterface[] =
      control.dependentFields ? JSON.parse(control.dependentFields) : [];
    dependentFieldActions.forEach((action) => {
      let formValue = update.value;
      const isChanging = changeDependentFieldFunction(formValue, action);
      if (!isChanging) return;
      onChangeDependentFieldActionFn({
        dispatch,
        action,
        update,
        section,
        control,
      });
    });
    return;
  },
  [CustomFunctionKey.UPLOAD_CONTROL_ON_DOWNLOAD]: (file: any) => {
    downloadFile(file);
  },
  // [CustomFunctionKey.SELECT_EMPLOYEE]: async () => {
  //   const form = store.getState().rental.form;
  //   const formConfig = store.getState().rental.formConfig;

  //   let applicationDetailRes;
  //   if (form.Employee) {
  //     applicationDetailRes = await EmployeeDetailsService.employeeGetEmployeeAmendment({
  //       employeeCode: form.Employee
  //     });
  //   }

  //   const _sections = EmployeeDataAmendmentConfigurationFunction(applicationDetailRes.data).data.sections;
  //   const formUpdate = initFormValue(_sections, []);
  //   const newForm = {
  //     ...form,
  //     ...formUpdate,
  //   };
  //   dispatch(setMainForm(newForm));
  //   const newFormConfig = initDependentFieldActionFn({
  //     formConfig: formConfig,
  //     form: newForm,
  //   });
  //   dispatch(setMainFormConfig(newFormConfig));
  // },
});

export function BaseMultiStepFormInitFormValue(
  sectionFieldConfig: SectionConfig[],
  excludeFieldTypes?: string[]
) {
  const controlList = sectionFieldConfig.reduce(
    (accList: any[], section: SectionConfig) => {
      accList = accList.concat(section.fields);
      return accList;
    },
    []
  );
  return initFormValueByControlList(controlList, excludeFieldTypes);
}

/**
 * Construct Form for Recalculate APIs
 */
function initFormValueByControlList(
  controlList: FieldConfiguration[],
  excludeFieldTypes?: string[]
) {
  let retForm = {} as any;
  controlList.forEach((field) => {
    if (excludeFieldTypes?.includes(field.controlType)) {
      return;
    }
    const fieldKey = getFieldKey(field);
    retForm[fieldKey] = undefined;
    switch (field.controlType) {
      case CCType.ATTACHMENT:
        if (Array.isArray(field.fileAttachments)) {
          retForm[fieldKey] = field.fileAttachments.map((file) => ({
            id: file.fileId,
            name: file.fileName,
            size: file.fileSize,
            uploadId: file.uploadId,
          }));
        }
        break;
      case CCType.NUMBERIC_BOX:
        retForm[fieldKey] = field.value
          ? Number(field.value)
          : field.defaultValue;
        break;
      case CCType.CHECKBOX:
        retForm[fieldKey] =
          field.value?.toString()?.toLowerCase() === "true" ? true : false;
        break;
      default:
        retForm[fieldKey] =
          field.value !== undefined ? field.value : field.defaultValue;
        break;
    }
  });
  return retForm;
}
