export enum ERROR_PAGE {
  MAIN_ERROR_404 = "404",
  ERROR_500 = "error-500",
  MAIN_ERROR_500 = "500",
}

export const FILE_HOST = "http://localhost:5001";

export enum MODULE_CODE {
  Common = "home",
  EmploymentManagement = "profile",
  Leave = "leave",
  Nwow = "nwow",
  Claims = "claims",
  Messaging = "notifications",
  Rental = "rental",
  Document = "documents",
  Policy = "company-policy",
  CompanyPolicy = "main",
  Faq = "faq",
  NoSideBar = "no-side-bar",
  PrivacyPolicy = "privacy-policy",
  TermCondition = "terms-conditions",
  ChangePassword = "change-password",
  Support = "support",
  Admin = "admin",
  Timesheet = "timesheet",
  HealthCheck = "healthcheck",
}

export enum FUNCTION_CODE {
  Common = "home",
  EmploymentManagement = "profile",
  Leave = "leave",
  Nwow = "nwow",
  Claims = "claims",
  Rental = "rental",
  Document = "documents",
  Payslip = "payslip",
  Tax = "tax",
  Timesheet = "timesheet",
  Compensation = "compensation",
  HrLetters = "hr-letters",
  HrCorner = "hr-corner",
  OtherDocs = "other-docs",
  DocumentUpload = "document-upload",
  EmployeeConfiguration = "employee-configuration",
  EmployeeDataAmendment = "employee-data-amendment",
  EmailConfiguration = "email-configuration",
  EmailTemplate = "email-template",
  EmailReminder = "email-reminder",
  EmailReminderUpdate = "update",
  EmailReminderCreate = "create",
  EmailTemplateUpdate = "update",
  CompanyDocumentConfiguration = "company-document-configuration",
  CompanyDocumentConfigurationUpdate = "update",
  AccessPermission = "access-permission",
  AccessPermissionRole = "role",
  AccessPermissionRoleAdd = "add",
  AccessPermissionRoleUpdate = "update",
  AccessPermissionEmployeeAccess = "employee-access",
  AccessPermissionEmployeeAccessAdd = "add",
  AccessPermissionEmployeeAccessUpdate = "update",
  AccessPermissionRoleAccess = "role-access",
  AccessPermissionRoleAccessAdd = "add",
  AccessPermissionRoleAccessUpdate = "update",
  SettingConfiguration = "setting-configuration",
  LeaveConfiguration = "leave",
  LeaveConfigurationPolicy = "leave-policy",
  LeaveConfigurationPolicyUpdate = "update",
  LeaveConfigurationPolicyCreate = "create",
  UserManagementEmployeeList = "employee-list",
  UserManagementEmployeeCreate = "employee-create",
  UserManagementEmployeeDetails = "employee-details",
  UserManagementEmployeeBulkCreate = "employee-bulk-create",
  UserManagementEmployeeActivate = "employee-activate",
  TimesheetConfiguration = "timesheet-configuration",
}

export enum DOCTYPE {
  FAQ = "Faq",
  ASSISTANT = "Assistant",
}

export enum MESSAGE_TYPE {
  Tax = "TaxReturn",
  Payslip = "Payslip",
  HrLetter = "HrLetter",
  Compensation = "Compensation",
  Rental = "Rental",
  Leave = "Leave",
  Nwow = "Nwow",
  Claim = "Claim",
  Timesheet = "Timesheet",
}

export enum MESSAGE_CONTENT_TYPE {
  TITLE = "TITLE",
  FILENAME = "FILENAME",
  CONTENT = "CONTENT",
}

export enum GENERIC_CODE {
  NAMEPREFIX = "NAMEPREFIX",
  COUNTRY = "COUNTRY",
  GENDER = "GENDER",
  MARITALSTATUS = "MARITALSTATUS",
  NATIONALITY = "NATIONALITY",
  NATURE = "NATURE",
  AREA = "AREA",
  DISTRICT = "DISTRICT",
  RELATIONSHIP = "RELATIONSHIP",

  SCHEME_TYPE = "SCHEMETYPE",
  CONTRIBUTION_CYCLE = "CONTRIBUTION",
  PAYMENT_METHOD = "PAYMENTMETHOD",
  CURRENCY = "CURRENCY",
  STATUS = "STATUS",
  FEE_UNIT = "FEEUNIT",
  ROUNDING_TYPE = "ROUNDINGTYPE",
  LOCALE = "LOCALE",
  TERREASON = "TERREASON",
  SALARYUNIT = "SALARYUNIT",
  STAFFTAGS = "STAFFTAGS",
  EXTERNALJOBTITLE = "EXTERNALJOBTITLE",
  INTERNALJOBTITLE = "INTERNALJOBTITLE",
  INTERFACEFILEFORMAT = "INTERFACEFILEFORMAT",
  STATEMENTFORMAT = "STATEMENTFORMAT",
  REASONDEPARTURE = "REASONDEPARTURE",
  EESTATUS = "EESTATUS",
  STAFFTYPE = "STAFFTYPE",
  WORKCALENDAR = "WORKCALENDAR",
}
export enum GENERIC_CODE_RES {
  GET_COMPONENT_ITEM = "1", // [ locale]
  GET_COMPONENT = "2",
  GET_FILTER = "3",
  GET_TREE = "4",
}
export enum PAYROLL_ITEM_CODE {
  MC = "3",
  VC_EE = "4",
  VC_ER = "5",
}
export enum STATUS_CODE {
  ACTIVE = "AC",
}

export enum GENERIC_CODE_COST {
  MARRIED = "MA",
  CHINA = "CN",
  OTHER = "OT",
  CLOSED = "CLOSED",
}

export enum FILE_CATEGORIES {
  PAYSLIP = "PAYSLIP",
}

// export enum ENTITY_FILE_CATEGORY {
//   EE_ENTITY_BG = "EE_ENTITY_BG",
//   EE_ENTITY_LOGO = "EE_ENTITY_LOGO",
//   LOGO = "LOGO",
// }

export enum LOGIN_OPTION {
  LOGIN_ENABLE_SSO = "Sso",
  LOGIN_ENABLE_EML = "EmailId",
  LOGIN_ENABLE_USR = "UserName",
  LOGIN_ENABLE_Okta = "Okta",
  LOGIN_ENABLE_BIO = "Biometric",
}

export enum HEADER_KEY {
  HRMNET_ENTITY_NAME = "Entity-Name",
  HRMNET_TENANT_NAME = "Tenant-Name",
  MODULES = "Modules",
}

export enum STORAGE_KEY {
  HRMNET_LANGUAGE = "Language",
  GLOBAL = "persist:global",
  ROOT = "persist:root",
  PATH_AFTER_LOGIN = "path_after_login",
  PASSWORD_POLICY = "PASSWORD_POLICY",
  APP_CLIENT_ID = "C_ID",
  AUTH_EMPLOYEE_CODE = "AUTH_EMPLOYEE_CODE",
  IS_OKTA_LOGIN = "isOktaLogin",
}

export enum LOGIN_PATH {
  LOGIN = "login",
  FORGOT_PWD = "forgot-password",
  RST_PWD = "reset-password",
  PWD_EXP = "password-expired",
  FST_LOGIN = "first-login",
  OKTA = "okta",
  CLIENT_CONFIGURATION = "client-configuration",
}

export enum LOGOUT_PATH {
  LOGOUT = "logout",
}

export enum IMAGE_PATH {
  LOGIN_BACKGROUND = "/assets/images/login-bg.png",
}

export enum SSO_INTERACTION_TYPE {
  REDIRECT = "loginRedirect",
  POP_UP = "loginPopup",
}

export enum RESPONSE_MESSAGE_TYPE {
  ERROR = "Error",
  ALERT = "Alert",
  WARNING = "Warning",
  TOAST = "Toast",
  SUCCESS = "Success",
}

export enum MESSAGE_SEVERITY {
  ERROR = "error",
  SUCCESS = "success",
  WARN = "warn",
  INFO = "info",
}

export enum RENTAL_SUBMODULE_CODE {
  LIST_APPLICATION = "list",
  CREATE_APPLICATION = "create",
  VIEW_APPLICATION = "view",
  VIEW_APPLICATION_HISTORY = "view-history",
  EDIT_APPLICATION = "edit",
  CHANGE_APPLICATION = "change",
  WITHDRAW_APPLICATION = "withdraw",
  SUBMIT_CLAIM = "submit-claim",
  APPROVAL = "approval",
  APPROVE_APPLICATION = "approve-application",
  APPROVE_CLAIM = "approve-claim",
  FINALIZE_APPLICATION = "finalize-application",
  REPORT = "report",
  APPLICATIONS = "applications",
}

export enum LEAVE_SUBMODULE_CODE {
  SUBMIT_APPLICATION = "submit",
  APPLICATION = "application",
  APPLY_ENTITLEMENT = "apply-entitlement",
  ENTITLEMENT = "entitlement",
  EDIT = "edit",
  BALANCE = "balance",
  CALENDAR_VIEW = "calendar-view",
  DELEGATION = "delegation",
  APPROVAL = "approval",
  PLANNER = "planner",
  HOLIDAY = "holiday",
  BLOCK_LEAVE = "block-leave",
  REPORT = "report",
}

export enum CLAIM_SUBMODULE_CODE {
  SUBMIT_APPLICATION = "submit",
  REVIEW_APPLICATION = "review",
  APPLICATION = "application",
  APPLY_ENTITLEMENT = "apply-entitlement",
  ENTITLEMENT = "entitlement",
  EDIT = "edit",
  BALANCE = "balance",
  CALENDAR_VIEW = "calendar-view",
  DELEGATION = "delegation",
  APPROVAL = "approval",
  PLANNER = "planner",
  HOLIDAY = "holiday",
  BLOCK_LEAVE = "block-leave",
  REPORT = "report",
}

export enum TIMESHEET_SUBMODULE_CODE {
  AMEND = "amend",
  APPLICATION = "application",
  APPROVAL = "approval",
  APPROVAL_CORNER = "approval-corner",
  DELEGATION = "delegation",
  EDIT = "edit",
  REPORT = "report",
  SUBMIT = "submit",
  VIEW = "view",
}

export enum PROFILE_IMAGE_PLACEHOLDER {
  DEFAULT = "/assets/images/default.png",
  ERROR = "/assets/images/default.png",
}

export const enumsKeyToArray = (enumsKey: any[]) => {
  var translations_labels: any[][] = [];
  var translations_labels_concat: any[] = [];
  enumsKey.forEach((enumKey, index) => {
    translations_labels.push(
      Object.keys(enumKey).map((key) => enumsKey[index][key])
    );
  });
  translations_labels.forEach((t_labels) => {
    translations_labels_concat = translations_labels_concat.concat(t_labels);
  });
  return translations_labels_concat;
};

export enum EEPORTAL_LABEL_LOGIN {
  LOGIN = "eeportal_login.label",
  PWD_POLICY = "eeportal_pwd_policy.label",
}

export enum EEPORTAL_LABEL_BASE_CONTROL {
  BASE_CONTROL = "eeportal_base_control.label",
}

export enum EEPORTAL_LABEL_GENERAL {
  MISC = "eeportal_misc.label",
  COMMON = "eeportal_common.label",
  DIALOG = "eeportal_dialog.label",
  PROFILE = "eeportal_profile.label",
  AUTHENTICATION = "Authentication.ErrorMessages",
}

export enum EEPORTAL_LABEL_HOME {
  HOME = "eeportal_home.label",
}

export enum EEPORTAL_LABEL_MY_INFO {
  MOVEMENT = "eeportal_movement.label",
  CONTACT = "eeportal_contact.label",
  DEPENDANT = "eeportal_dependant.label",
  BNK = "eeportal_eeinfo_bnk.label",
  PSN = "eeportal_eeinfo_psn.label",
  EMP = "eeportal_eeinfo_emp.label",
}

export enum EEPORTAL_LABEL_BASE_TABLE {
  BASE_TABLE = "eeportal_base_table.label",
}

export enum EEPORTAL_LABEL_ADMIN {
  ADMIN = "eeportal_admin.label",
  EMPLOYEE_CONFIGURATION = "eeportal_employee_configuration.label",
  EMPLOYEE_DATA_AMENDMENT = "eeportal_employee_data_amendment.label",
  EMAIL_CONFIGURATION = "eeportal_email_configuration.label",
  COMPANY_DOCUMENT_CONFIGURATIOn = "eeportal_company_document_configuration.label",
  ACCESS_PERMISSION = "eeportal_access_permission.label",
  SETTING_CONFIGURATION = "SettingConfiguration.label",
  ADMIN_LEAVE = "eeportal_admin_leave.label",
}

export enum EEPORTAL_LABEL_MY_DOCS {
  PAYSLIP = "eeportal_payslip.label",
  TAX_RETURN = "eeportal_taxreturn.label",
  COMPENSATION = "eeportal_compensation.label",
  HR_LETTER = "eeportal_hrletter.label",
  HR_CORNER = "eeportal_hrCorner.label",
  OTHER_DOCS = "eeportal_otherDocs.label",
  DOCUMENT_UPLOAD = "eeportal_documentUpload.label",
  GENERAL = "eeportal_general.label",
}

export enum EEPORTAL_LABEL_NOTIFICATION {
  NOTIFICATION = "eeportal_notification.label",
}

export enum EEPORTAL_LABEL_CHANGE_PWD {
  CHANGE_PWD = "eeportal_profile_cpwd.label",
}

export enum DATE_TIME_FORMAT {
  DATE_TIME_GMT = "YYYY-MM-DDTHH:mm:ss.SSSSZ",
  DATE_TIME = "DD/MM/YYYY hh:mm:ss A",
}

export enum IMAGE_DEFAULT {
  TRICOR_LOGO = "/assets/images/logo.png",
  DEFAULT = "/assets/images/default.png",
}

export enum QUERY_PARAMS {
  SOURCE = "source",
  TOKEN = "t",
  SUPPORT = "support",
  UNIFY = "unify",
  TYPE = "type",
  MOBILE = "mobile",
  WEB = "web",
  SSO_ADFS_TOKEN = "adfs",
  SSO_CALLBACK_URL = "pre",
  ID = "id",
  ADFS_ERROR = "err",
}

export enum MENU_TYPE {
  MENU = "Menu",
  Action = "Action",
}

export const DEFAULT_DATE_FORMAT = "YYYY/MM/DD";
export const DEFAULT_DATETIME_FORMAT = "YYYY/MM/DD HH:mm:ss";
export const DEFAULT_MYINFO_DATE_FORMAT = "yy/mm/dd";

export const BIOMETRIC = "BIOMETRIC";
export const WEB_CONFIG_SUCCESS = "WEB_CONFIG_SUCCESS";

export enum BIOMETRIC_ACTION {
  BIOMETRIC_SUPPORT_CHECK_REQUEST = "BIOMETRIC_SUPPORT_CHECK_REQUEST",
  BIOMETRIC_AUTH_REQUEST = "BIOMETRIC_AUTH_REQUEST",
  BIOMETRIC_AUTH_KEY_EXIST_REQUEST = "BIOMETRIC_AUTH_KEY_EXIST_REQUEST",
  BIOMETRIC_AUTH_DELETE_KEY_REQUEST = "BIOMETRIC_AUTH_DELETE_KEY_REQUEST",
  BIOMETRIC_AUTH_GENERATE_SIGNATURE_REQUEST = "BIOMETRIC_AUTH_GENERATE_SIGNATURE_REQUEST",
  BIOMETRIC_AUTH_REGISTER_REQUEST = "BIOMETRIC_AUTH_REGISTER_REQUEST",
  BIOMETRIC_VALIDATE_REQUEST = "BIOMETRIC_VALIDATE_REQUEST",
}

export enum BIOMETRIC_STATUS {
  BIOMETRIC_AUTH_NOT_SUPPORT = "BIOMETRIC_AUTH_NOT_SUPPORT",
  BIOMETRIC_AUTH_SUPPORT = "BIOMETRIC_AUTH_SUPPORT",
  BIOMETRIC_AUTH_NOT_REGISTER = "BIOMETRIC_AUTH_NOT_REGISTER",
  BIOMETRIC_AUTH_SUCCESS = "BIOMETRIC_AUTH_SUCCESS",
  BIOMETRIC_AUTH_FAIL = "BIOMETRIC_AUTH_FAIL",
  BIOMETRIC_AUTH_REGISTER_SUCCESS = "BIOMETRIC_AUTH_REGISTER_SUCCESS",
  BIOMETRIC_AUTH_REGISTER_FAIL = "BIOMETRIC_AUTH_REGISTER_FAIL",
  BIOMETRIC_VALIDATE_SUCCESS = "BIOMETRIC_VALIDATE_SUCCESS",
  BIOMETRIC_VALIDATE_FAIL = "BIOMETRIC_VALIDATE_FAIL",
  BIOMETRIC_AUTH_REGISTERED = "BIOMETRIC_AUTH_REGISTERED",
}

export const COMPANY_POLICY_DEFAULT_DOCUMENT_NAME = "document.pdf";
export const HTML_ATTIBUTES_ONCLICK = "onclick";
export const HTML_ATTIBUTES_VALUE = "value";

export const BLOB = "blob";
export const DOCUMENT = "Document";
export const SUGGESTED_FILENAME = "x-suggested-filename";

export const FILE_RELEASE_DATE = "releaseDate";
export const COMPANY_NAME = "companyName";
export const FILE_NAME = "fileName";
export enum DEFAULT_FILE_NAME {
  COMPENSATION_DOC = "compensation-doc.pdf",
  HR_LETTER = "hr-letter.pdf",
  PAYSLIPS = "payslips.pdf",
  TAX_DOC = "tax-doc.pdf",
  DOCUMENT = "document",
}

export const TENANT_CODE = "tenantCode";
export const ENTITY_CODE = "entityCode";
export const MESSAGES = "messages";
export const STRING = "string";

export enum MATCH_MODE {
  CONTAINS = "contains",
  EQUALS = "equals",
  RANGE = "range",
}

export enum FILTER_MODE {
  MULTILPLE = "multiple",
  SINGLE = "single",
}

export enum HR_CORNER_OBJECT_KEY {
  FILE_NAME = "fileName",
  DOCUMENT_TYPE = "documentType",
  DESCRIPTION = "description",
  NUMBER_OF_FILES = "numberOfFiles",
  RELEASE_DATE = "releaseDate",
}

export enum SSO_PROVIDER {
  HRIS_Node_Passport = "HRIS_Node_Passport",
  MS_Azure = "MS Azure",
  Okta = "Okta",
}

export enum MENU_NAME {
  //admin
  Access_Permission_Role = "Access_Permission_Role",
  Access_Permission_Employee_Access = "Access_Permission_Employee_Access",
  Access_Permission_Role_Access = "Access_Permission_Role_Access",
  Access_Permission_Role_Edit = "Access_Permission_Role_Edit",
  Access_Permission_Role_Add = "Access_Permission_Role_Add",
  Access_Permission_Employee_Access_Add = "Access_Permission_Employee_Access_Add",
  Access_Permission_Employee_Access_Edit = "Access_Permission_Employee_Access_Edit",
  Access_Permission_Role_Access_Add = "Access_Permission_Role_Access_Add",
  Access_Permission_Role_Access_Edit = "Access_Permission_Role_Access_Edit",

  Email_Configuration = "Email_Configuration",
  Email_Template = "Email_Template",
  Email_Reminder = "Email_Reminder",
  Email_Template_Update = "Email_Template_Update",
  Email_Reminder_Update = "Email_Reminder_Update",
  Email_Reminder_Create = "Email_Reminder_Create",

  Leave_Configuration = "Leave_Configuration",
  Leave_Configuration_Policy = "Leave_Configuration_Policy",
  Leave_Configuration_Policy_Create = "Leave_Configuration_Policy_Create",
  Leave_Configuration_Policy_Update = "Leave_Configuration_Policy_Update",

  //notifications
  Notification = "Notifications",

  //leave
  Submit_Leave_Application = "Submit_Leave_Application",
  Apply_for_Entitlement = "Apply_for_Entitlement",
}

export enum SCHEDULER_TYPE {
  SCHEDULED = "Scheduled",
  IMMEDIATE = "Immediate",
}

export enum EWORKFLOW_DELEGATABLE_TASK_NAME {
  Apply_Leave = "Apply Leave",
}

export enum Target_Organization {
  Global = "Global",
}

export enum LookUpDsName {
  Target_Organizations_DS = "Target_Organizations_DS",
}

export enum RESPONSE_MESSAGE_ERROR {
  CANCEL = "Cancel",
  Invalid_User_Token = "Invalid user token",
  Forbidden_User_Session_Already_Expired_Message = "Forbidden_User_Session_Already_Expired_Message",
}

export enum BroughtForwardLeavePolicyModes {
  BringForwardAndForfeiture = "BringForwardAndForfeiture",
}

export enum ViewType {
  WEB = "web",
  MOBILE = "mobile",
}

export enum NWOW_SUBMODULE_CODE {
  SUBMIT_APPLICATION = "submit",
  APPLICATION = "application",
  EDIT = "edit",
  CHANGE = "change",
  CALENDAR_VIEW = "calendar-view",
  DELEGATION = "delegation",
  APPROVAL = "approval",
  HOLIDAY = "holiday",
  REPORT = "report",
  PLANNER = "planner",
}

export enum Permission {
  DelegatedByUser = "DelegatedByUser",
  Authorized_User = "Authorized User",
  HR_Authorized = "HR Authorized",
  Self = "Self",
}
