import { Dropdown } from "primereact/dropdown";
import {
  TIMESHEET_MENU_MODULE_KEY,
  TIMESHEET_MENU_PAGE_KEY,
  TIMESHEET_MENU_TYPE_KEY,
} from "../../constants";

export const HeaderDropdown = ({ sideBarMenus, history, t }: any) => {
  const timesheetMenu = sideBarMenus?.find(
    (sideBarMenu: any) => sideBarMenu.key === TIMESHEET_MENU_MODULE_KEY
  );

  if (timesheetMenu) {
    return (
      <Dropdown
        className="nav-dropdown"
        options={timesheetMenu.subMenus
          .filter((s: any) => s.type === TIMESHEET_MENU_TYPE_KEY.MENU)
          ?.map((sideBarMenu: any) => ({
            ...sideBarMenu,
            name: t(sideBarMenu.name),
            url: sideBarMenu.url.startsWith("/")
              ? sideBarMenu.url
              : "/" + sideBarMenu.url,
          }))}
        optionLabel="name"
        optionValue="url"
        onChange={(e) => history.push(e.value)}
        placeholder={t("leave_applicationList_pleaseSelectNav")}
      />
    );
  } else {
    return null;
  }
};
