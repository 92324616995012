import "./skeleton.scss";

import { Skeleton } from "primereact/skeleton";

const SkeletonColumn = ({
  column,
  hideLastColumn,
}: {
  column: number;
  hideLastColumn: boolean;
}) => {
  const columnWidth = 12 / column;
  const columnClass = `p-col-${columnWidth}`;

  return (
    <div className="p-grid p-my-3">
      {[...Array(column).keys()].map((key: number) => {
        return (
          <div key={key} className={columnClass}>
            {!(hideLastColumn && key === column - 1) && (
              <Skeleton height="40px"></Skeleton>
            )}
          </div>
        );
      })}
    </div>
  );
};

export const FormSkeleton = ({ row = 3, column = 3 }) => {
  return (
    <div className="skeleton-container">
      {[...Array(row).keys()].map((key: number) => (
        <SkeletonColumn
          key={key}
          column={column}
          hideLastColumn={key === row - 1}
        />
      ))}
    </div>
  );
};

export const CalendarViewSkeleton = () => {
  return (
    <div className="skeleton-container" style={{ minHeight: "70vh" }}>
      <div className="p-grid">
        <div
          className="p-col-12 p-sm-12 p-md-6 p-lg-6"
          style={{ padding: "3rem" }}
        >
          <Skeleton className="p-mb-5" height="300px"></Skeleton>
          <Skeleton className="p-mb-3" height="40px"></Skeleton>
          <Skeleton className="p-mb-5" height="40px"></Skeleton>
          <Skeleton className="p-mb-3" height="40px"></Skeleton>
          <Skeleton className="p-mb-5" height="40px"></Skeleton>
        </div>

        <div
          className="p-col-12 p-sm-12 p-md-6 p-lg-6"
          style={{ padding: "3rem" }}
        >
          <Skeleton className="p-mb-5" height="40px"></Skeleton>
          <Skeleton className="p-mb-5" height="40px"></Skeleton>
          <Skeleton className="p-mb-5" height="40px"></Skeleton>
          <Skeleton className="p-mb-5" height="40px"></Skeleton>
          <Skeleton className="p-mb-5" height="40px"></Skeleton>
          <Skeleton className="p-mb-5" height="40px"></Skeleton>
          <Skeleton className="p-mb-5" height="40px"></Skeleton>
          <Skeleton className="p-mb-5" height="40px"></Skeleton>
        </div>
      </div>
    </div>
  );
};
