import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  IForm,
  IFormControls,
  IFormLayout,
  IOnChange,
  ISetForm,
  ITimesheetConfigurationContext,
} from "./timesheetConfigurationContextTypes";
import { useTranslation } from "react-i18next";
import { parseTabConfigIntoTabs } from "./timesheetConfigurationContextHelper";
import {
  IGetEmployeeCostCenterData,
  TIMESHEET_CONFIGURATION_MENU_Tab_KEY,
} from "../constants";
import AdminTimesheetService from "../../../../../services/hrmnet-api/admin";
import {
  IActivityFormData,
  ICalendarFormData,
  ICostCenterFormData,
  ITabItem,
} from "../types/types";
import {
  calendarFormInitValue,
  getCalendarFormControls,
  getCalendarFormLayout,
  parseCalendarFormIntoRequestInput,
  parseCalendarResponseIntoForm,
} from "../form/calendar-form-helper";
import { MessageSwitch2 } from "../../../../../services/utils/message-2";
import { store } from "../../../../../redux/store";
import {
  MESSAGE_SEVERITY,
  RESPONSE_MESSAGE_TYPE,
} from "../../../../../constants";
import {
  IBaseFormOnChangeProps,
  ISelectOptionData,
} from "../../../timesheet/constants";
import { TimesheetConfigurationContext } from "./useTimesheetConfigurationContext";
import { tabConfig } from "../tab-config";
import { SelectControlOption } from "../../../../../components/base-control/select-control/select-control";
import { isResponseOk } from "../../../../../utils/utils";
import {
  costCenterFormInitValue,
  getCostCenterFormControls,
  getCostCenterFormLayout,
  parseCostCenterFormIntoRequestInput,
} from "../form/cost-center-form-helper";
import { CostCenterFormConfigFieldKey } from "../form/cost-center-form-field-config";
import {
  activityFormInitValue,
  getActivityFormControls,
  getActivityFormLayout,
  parseActivityFormIntoRequestInput,
  parseActivityResponseIntoForm,
} from "../form/activity-form-helper";
import { useDispatch } from "react-redux";
import { showSpinner } from "../../../../../redux/actions/spinner";
import { validateRequired } from "../form/form-validation";

export const TimesheetConfigurationContextProvider: React.FC = ({
  children,
}) => {
  const formRef = useRef();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const toast = store.getState().global.toast;
  const tabs = useMemo(() => parseTabConfigIntoTabs({ tabConfig, t }), [t]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOnChangeLoading, setIsOnChangeLoading] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState<ITabItem>(tabs[0]);
  const [formTouched, setFormTouched] = useState<boolean>(false);
  // Activity tab
  const [projectList, setProjectList] = useState<ISelectOptionData[]>([]);
  const [activityForm, setActivityForm] = useState<IActivityFormData>(
    activityFormInitValue
  );
  // Calendar tab
  const [calendarList, setCalendarList] = useState<string[]>([]);
  const [intervalList, setIntervalList] = useState<ISelectOptionData[]>([]);
  const [calendarForm, setCalendarForm] = useState<ICalendarFormData>(
    calendarFormInitValue
  );
  // Cost Center tab
  const [employeeList, setEmployeeList] = useState<ISelectOptionData[]>([]);
  const [costCenterList, setCostCenterList] = useState<ISelectOptionData[]>([]);
  const [costCenterForm, setCostCenterForm] = useState<ICostCenterFormData>(
    costCenterFormInitValue
  );

  const onTabChange = useCallback(
    (value: number) => {
      const selectedTab = tabs.find((tab: any) => tab.value === value);
      if (!!selectedTab) {
        setSelectedTab(selectedTab);
        setFormTouched(false);
      }
    },
    [tabs]
  );

  const loadActivityTab = useCallback(() => {
    (async () => {
      setIsLoading(true);
      const getPmActivitiesDetailResponse =
        await AdminTimesheetService.getPmActivitiesDetail();
      const getPmProjectsResponse = await AdminTimesheetService.getPmProjects();

      if (!getPmActivitiesDetailResponse?.data?.length) {
        MessageSwitch2(
          {
            messages: {
              statusText: MESSAGE_SEVERITY.ERROR,
              text: "Cannot find any existing calendar",
              type: RESPONSE_MESSAGE_TYPE.ERROR,
            },
          },
          toast,
          null,
          i18n
        );
      }

      setProjectList(
        getPmProjectsResponse?.data?.map((item: any) => ({
          ...item,
          label: item.name,
          value: parseInt(item.value),
        }))
      );
      setActivityForm(
        parseActivityResponseIntoForm(getPmActivitiesDetailResponse?.data)
      );
      setIsLoading(false);
    })();
  }, [i18n, toast]);

  const loadCalendarTab = useCallback(() => {
    (async () => {
      setIsLoading(true);
      const getCalendarConfigMaintenanceResponse =
        await AdminTimesheetService.getCalendarConfigMaintenance();
      const getCalendarConfigIntervaleResponse =
        await AdminTimesheetService.getCalendarConfigInterval();

      if (!getCalendarConfigMaintenanceResponse?.data?.length) {
        MessageSwitch2(
          {
            messages: {
              statusText: MESSAGE_SEVERITY.ERROR,
              text: "Cannot find any existing calendar",
              type: RESPONSE_MESSAGE_TYPE.ERROR,
            },
          },
          toast,
          null,
          i18n
        );
      }

      setIntervalList(
        getCalendarConfigIntervaleResponse?.data?.map((item: any) => ({
          ...item,
          label: item.name,
          value: parseInt(item.value),
        }))
      );
      setCalendarList(
        getCalendarConfigMaintenanceResponse?.data?.map(
          (config) => config.calendarClass
        )
      );
      setCalendarForm(
        parseCalendarResponseIntoForm(
          getCalendarConfigMaintenanceResponse?.data
        )
      );
      setIsLoading(false);
    })();
  }, [i18n, toast]);

  const loadCostCenterTab = useCallback(() => {
    (async () => {
      setIsLoading(true);
      const getCostCenterListResponse =
        await AdminTimesheetService.getCostCenterList();
      const getEmployeeListResponse =
        await AdminTimesheetService.getEmployeeList();
      if (!getCostCenterListResponse?.data?.length) {
        MessageSwitch2(
          {
            messages: {
              statusText: MESSAGE_SEVERITY.ERROR,
              text: "Cannot find any cost center",
              type: RESPONSE_MESSAGE_TYPE.ERROR,
            },
          },
          toast,
          null,
          i18n
        );
      }
      setCostCenterList(
        getCostCenterListResponse?.data?.map((item: any) => ({
          ...item,
          label: item.name,
        }))
      );
      setEmployeeList(
        getEmployeeListResponse?.map((item: any) => ({
          ...item,
          label: item.name,
        }))
      );
      setIsLoading(false);
    })();
  }, [i18n, toast]);

  useEffect(() => {
    (async () => {
      // Activity tab
      loadActivityTab();

      // Calendar tab
      loadCalendarTab();

      // Cost Center tab
      loadCostCenterTab();
    })();
  }, []);

  const formControls: IFormControls = useMemo(() => {
    switch (selectedTab.key) {
      case TIMESHEET_CONFIGURATION_MENU_Tab_KEY.TIMESHEET_ACTIVITY_CONFIGURATION:
        return getActivityFormControls({
          t,
          activityForm,
          setActivityForm,
          projectList,
        });
      case TIMESHEET_CONFIGURATION_MENU_Tab_KEY.TIMESHEET_COST_CENTER_CONFIGURATION:
        return getCostCenterFormControls({ t, costCenterList, employeeList });
      case TIMESHEET_CONFIGURATION_MENU_Tab_KEY.TIMESHEET_CALENDAR_CONFIGURATION:
      default:
        return getCalendarFormControls({
          t,
          calendarList,
          intervalList,
          calendarForm,
        });
    }
  }, [
    activityForm,
    calendarForm,
    calendarList,
    costCenterList,
    employeeList,
    intervalList,
    projectList,
    selectedTab.key,
    t,
  ]);

  const formLayout: IFormLayout = useMemo(() => {
    switch (selectedTab.key) {
      case TIMESHEET_CONFIGURATION_MENU_Tab_KEY.TIMESHEET_ACTIVITY_CONFIGURATION:
        return getActivityFormLayout({ t, activityForm, setActivityForm });
      case TIMESHEET_CONFIGURATION_MENU_Tab_KEY.TIMESHEET_COST_CENTER_CONFIGURATION:
        return getCostCenterFormLayout();
      case TIMESHEET_CONFIGURATION_MENU_Tab_KEY.TIMESHEET_CALENDAR_CONFIGURATION:
      default:
        return getCalendarFormLayout({ calendarList });
    }
  }, [activityForm, calendarList, selectedTab.key, t]);

  const form: IForm = useMemo(() => {
    switch (selectedTab.key) {
      case TIMESHEET_CONFIGURATION_MENU_Tab_KEY.TIMESHEET_ACTIVITY_CONFIGURATION:
        return activityForm;
      case TIMESHEET_CONFIGURATION_MENU_Tab_KEY.TIMESHEET_COST_CENTER_CONFIGURATION:
        return costCenterForm;
      case TIMESHEET_CONFIGURATION_MENU_Tab_KEY.TIMESHEET_CALENDAR_CONFIGURATION:
      default:
        return calendarForm;
    }
  }, [activityForm, calendarForm, costCenterForm, selectedTab.key]);

  const setForm: ISetForm = useMemo(() => {
    switch (selectedTab.key) {
      case TIMESHEET_CONFIGURATION_MENU_Tab_KEY.TIMESHEET_ACTIVITY_CONFIGURATION:
        return setActivityForm;
      case TIMESHEET_CONFIGURATION_MENU_Tab_KEY.TIMESHEET_COST_CENTER_CONFIGURATION:
        return setCostCenterForm;
      case TIMESHEET_CONFIGURATION_MENU_Tab_KEY.TIMESHEET_CALENDAR_CONFIGURATION:
      default:
        return setCalendarForm;
    }
  }, [selectedTab.key]);

  const onChange: IOnChange = useCallback(
    async (
      change:
        | IBaseFormOnChangeProps<IActivityFormData>
        | IBaseFormOnChangeProps<ICostCenterFormData>
        | IBaseFormOnChangeProps<ICalendarFormData>
    ) => {
      setIsOnChangeLoading(true);
      let parsedForm:
        | Partial<IActivityFormData>
        | Partial<ICostCenterFormData>
        | Partial<ICalendarFormData> = {};
      if (
        change.changed.control.key === CostCenterFormConfigFieldKey.employeeCode
      ) {
        const getEmployeeCostCenterResponse =
          await AdminTimesheetService.getEmployeeCostCenter({
            params: { employeeCode: change.state.form.employeeCode },
          });
        parsedForm.costCenter = getEmployeeCostCenterResponse?.data?.costCenter;
      }

      setForm({
        ...form,
        ...change.state.form,
        ...(parsedForm as any),
      });
      setIsOnChangeLoading(false);
    },
    [form, setForm]
  );

  const formProps = useMemo(() => {
    return {
      ref: formRef,
      config: {
        controls: formControls,
        layout: formLayout,
      },
      form,
      touched: formTouched,
      onChange,
      setForm,
    };
  }, [form, formControls, formLayout, formTouched, onChange, setForm]);

  const primaryButtonLabel = "timesheet_common_actionSubmit";

  const onPrimaryButtonClick = useCallback(() => {
    (async () => {
      setIsSubmitting(true);
      const validateRequiredResult = validateRequired(formRef, setFormTouched);
      if (validateRequiredResult) {
        setIsSubmitting(false);
        return;
      }

      let success = true;
      try {
        let res: any;

        switch (selectedTab.key) {
          case TIMESHEET_CONFIGURATION_MENU_Tab_KEY.TIMESHEET_ACTIVITY_CONFIGURATION:
            res = await AdminTimesheetService.updatePmActivitiesDetail({
              data: parseActivityFormIntoRequestInput({
                form: form as IActivityFormData,
              }),
            });
            break;
          case TIMESHEET_CONFIGURATION_MENU_Tab_KEY.TIMESHEET_COST_CENTER_CONFIGURATION:
            res = await AdminTimesheetService.updateCostCenterConfig({
              data: parseCostCenterFormIntoRequestInput({ form }),
            });
            break;
          case TIMESHEET_CONFIGURATION_MENU_Tab_KEY.TIMESHEET_CALENDAR_CONFIGURATION:
          default:
            res = await AdminTimesheetService.updateCalendarConfig({
              data: parseCalendarFormIntoRequestInput({ form, calendarList }),
            });
        }

        if (!isResponseOk(res)) {
          success = false;
          setIsSubmitting(false);
          return;
        }
        toast.show({
          severity: MESSAGE_SEVERITY.SUCCESS,
          summary: t("misc_axios_notification"),
          detail: "Update Success!",
          life: 3000,
          sticky: false,
        });
      } catch (err) {
        console.debug(err);
      } finally {
        if (success) {
          setFormTouched(false);
          switch (selectedTab.key) {
            case TIMESHEET_CONFIGURATION_MENU_Tab_KEY.TIMESHEET_ACTIVITY_CONFIGURATION:
              await loadActivityTab();
              break;
            case TIMESHEET_CONFIGURATION_MENU_Tab_KEY.TIMESHEET_COST_CENTER_CONFIGURATION:
              loadCostCenterTab();
              break;
            case TIMESHEET_CONFIGURATION_MENU_Tab_KEY.TIMESHEET_CALENDAR_CONFIGURATION:
            default:
              loadCalendarTab();
          }
          setIsSubmitting(false);
        }
      }
    })();
  }, [
    calendarList,
    form,
    loadActivityTab,
    loadCalendarTab,
    loadCostCenterTab,
    selectedTab.key,
    t,
    toast,
  ]);

  const isPrimaryButtonDisabled = useMemo(() => {
    switch (selectedTab.key) {
      case TIMESHEET_CONFIGURATION_MENU_Tab_KEY.TIMESHEET_ACTIVITY_CONFIGURATION:
      case TIMESHEET_CONFIGURATION_MENU_Tab_KEY.TIMESHEET_COST_CENTER_CONFIGURATION:
      case TIMESHEET_CONFIGURATION_MENU_Tab_KEY.TIMESHEET_CALENDAR_CONFIGURATION:
      default:
        return isLoading || isOnChangeLoading || isSubmitting;
    }
  }, [isLoading, isOnChangeLoading, isSubmitting, selectedTab.key]);

  useEffect(() => {
    dispatch(showSpinner(isPrimaryButtonDisabled));
  }, [dispatch, isPrimaryButtonDisabled]);

  return (
    <TimesheetConfigurationContext.Provider
      value={
        {
          form,
          formControls,
          formLayout,
          formProps,
          isOnChangeLoading,
          isLoading,
          isPrimaryButtonDisabled,
          onPrimaryButtonClick,
          onTabChange,
          primaryButtonLabel,
          selectedTab,
          tabConfig,
          tabs,
        } as ITimesheetConfigurationContext
      }
    >
      {children}
    </TimesheetConfigurationContext.Provider>
  );
};
