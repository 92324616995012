import { TIMESHEET_SUBMODULE_CODE } from "../../../../constants";
import { PortalLink } from "../../../../services/utils";
import { TIMESHEET_LIST_ACTIONS } from "../constants";
import { TIMESHEET_DETAIL_TYPE } from "../constants/type";

export const mapActionToMode = ({
  action,
}: {
  action: TIMESHEET_LIST_ACTIONS;
}) => {
  let mode: TIMESHEET_DETAIL_TYPE = TIMESHEET_DETAIL_TYPE.VIEW;
  switch (action) {
    case TIMESHEET_LIST_ACTIONS.VIEW_APPLICATION:
      mode = TIMESHEET_DETAIL_TYPE.VIEW;
      break;
    case TIMESHEET_LIST_ACTIONS.EDIT_APPLICATION:
      mode = TIMESHEET_DETAIL_TYPE.EDIT;
      break;
    case TIMESHEET_LIST_ACTIONS.AMEND_APPLICATION:
      mode = TIMESHEET_DETAIL_TYPE.AMEND;
      break;
    case TIMESHEET_LIST_ACTIONS.HR_AMEND_APPLICATION:
      mode = TIMESHEET_DETAIL_TYPE.HR_AMEND;
      break;
    case TIMESHEET_LIST_ACTIONS.APPROVE_APPLICATION:
      mode = TIMESHEET_DETAIL_TYPE.APPROVAL;
      break;
    case TIMESHEET_LIST_ACTIONS.DELETE_DRAFT:
    default:
      break;
  }

  return mode;
};

export const mapActionToLink = ({
  submodulePath,
  action,
  id,
}: {
  submodulePath: string;
  action: TIMESHEET_LIST_ACTIONS;
  id: string;
}) => {
  let actionPath = "";
  switch (action) {
    case TIMESHEET_LIST_ACTIONS.VIEW_APPLICATION:
      actionPath = TIMESHEET_SUBMODULE_CODE.VIEW;
      break;
    case TIMESHEET_LIST_ACTIONS.EDIT_APPLICATION:
      actionPath = TIMESHEET_SUBMODULE_CODE.EDIT;
      break;
    case TIMESHEET_LIST_ACTIONS.AMEND_APPLICATION:
    case TIMESHEET_LIST_ACTIONS.HR_AMEND_APPLICATION:
      actionPath = TIMESHEET_SUBMODULE_CODE.AMEND;
      break;
    case TIMESHEET_LIST_ACTIONS.APPROVE_APPLICATION:
      actionPath = TIMESHEET_SUBMODULE_CODE.APPROVAL;
      break;
    case TIMESHEET_LIST_ACTIONS.DELETE_DRAFT:
    default:
      break;
  }

  return `${submodulePath}/${id}/${actionPath}`;
};

export const GetLink = ({
  action,
  submodulePath,
  id,
}: {
  action: TIMESHEET_LIST_ACTIONS;
  submodulePath: string;
  id: string;
}) => {
  const link = mapActionToLink({ action, id, submodulePath });

  return PortalLink(link);
};
