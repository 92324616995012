import { useRef, useState, useEffect, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import BaseTable from "../../../../../components/base-table/base-table-portal-rental";
import { getColumns } from "./table-column-config";
import { ListConfigModel } from "./table-config";
import { getDataSource } from "./table-query";
import { DEFAULT_BT_SEARCH } from "../../../../../components/base-table/base-table-model";
import {
  IAxiosResponse,
  IGetDelegatedApplicationsData,
  IGetHrApplicationsData,
  IGetMyApplicationsData,
} from "../../constants/query-response";
import { IRequestConfig } from "../../../../../services/hrmnet-api/serviceOptions";
import { formatTwoDecimals } from "../../utils/format-helper";
import { useDispatch } from "react-redux";
import { openModal } from "../../../../../redux/actions/modal";
import TimesheetService from "../../../../../services/hrmnet-api/timesheet";
import { HRApplications } from "./tab-config";

type ITableDate =
  | IGetMyApplicationsData
  | IGetDelegatedApplicationsData
  | IGetHrApplicationsData;

export const Table = ({ selectedTabKey, year, toolbar }: any) => {
  const { t } = useTranslation();
  const tableRef = useRef();
  const history = useHistory() as any;
  const [tableConfig, setTableConfig] = useState<any>(null);
  const [expandedRows, setExpandedRows] = useState<any>(null);
  const dispatch = useDispatch();

  const searchConfig = useMemo(
    () => ({
      ...DEFAULT_BT_SEARCH,
      sortObj: {
        key: "submissionDate",
        direction: -1,
      },
    }),
    []
  );

  const onDeleteButtonClick = useCallback(
    (data: any) => async () => {
      dispatch(
        openModal({
          title: t("timesheet_common_actionConfirmation"),
          content: t("timesheet_common_messageDeleteConfirmation"),
          classNameMainDialog: "confirm-message-modal",
          primaryButtonText: t("timesheet_common_actionConfirm"),
          primaryButtonClickFn: async ({ closeFn }: any) => {
            closeFn();

            const res = await TimesheetService.deleteDraft({
              data: { id: data.id },
            });
            if (res.data.status) {
              // TODO: reload table instead
              window.location.reload();
            }
          },
          secondButtonClickFn: ({ closeFn }: any) => {
            closeFn();
          },
        })
      );
    },
    [dispatch, t]
  );

  useEffect(() => {
    const tableGetData = async () => {
      const dataSource: (config: IRequestConfig) => Promise<{
        data:
          | IGetDelegatedApplicationsData
          | IGetHrApplicationsData
          | IGetMyApplicationsData;
      }> = getDataSource(selectedTabKey);
      const response = (await dataSource({
        params: { year },
      })) as IAxiosResponse<ITableDate>;
      const data = response.data
        ?.filter((item) => !!item)
        .map((item) => ({
          ...item,
          totalHours: formatTwoDecimals(item.totalHours),
          onDeleteButtonClick: onDeleteButtonClick(item),
          actions:
            selectedTabKey === HRApplications.key
              ? item.hrActions
              : item.actions,
        }));

      return {
        datas: data,
        searchConfig: searchConfig,
        total: data?.length,
      };
    };

    setTableConfig({
      key: `${selectedTabKey} - ${year}`,
      isClientSize: true,
      configModel: ListConfigModel({
        t,
        toolbar: toolbar,
        columns: getColumns(selectedTabKey),
        redirect: history.push,
        useHrActions: selectedTabKey === HRApplications.key,
        expandedRows,
        setExpandedRows,
      }),
      searchConfig: searchConfig,
      searchFn: tableGetData,
    });
  }, [
    year,
    t,
    toolbar,
    selectedTabKey,
    history.push,
    onDeleteButtonClick,
    searchConfig,
    expandedRows,
    setExpandedRows,
  ]);

  return (
    <div className="timesheet-application-list-datatable">
      {tableConfig && <BaseTable ref={tableRef} {...tableConfig} />}
    </div>
  );
};
