import moment from "moment";
import { Button } from "primereact/button";
import {
  RefObject,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import LanguageLoader from "src/components/language-loader/language-loader";
import { EEPORTAL_LABEL_BASE_TABLE, FUNCTION_CODE } from "src/constants";
import { showSpinner } from "src/redux/actions/spinner";
import ClaimService from "src/services/hrmnet-api/claims";
import { PortalLink } from "src/services/utils";
import { showTimestampToastSuccess } from "src/services/utils/message";
import { isResponseOk } from "src/utils/utils";
import { v4 as uuidv4 } from "uuid";
import ConfirmButton from "../../components/claim-footer-action/confirm-button";
import ClaimForm from "../../components/claim-form";
import { HeaderMenu } from "../../components/header-menu";
import {
  CLAIMS_MENU_MODULE_KEY,
  CLAIMS_MENU_PAGE_KEY,
  CLAIMS_T_CONTEXT_KEY,
  CLAIM_STORE_ACTION,
} from "../../constants";
import { ClaimContext } from "../../context/claim-context-provider";
import { ToFormRequest } from "../../utils/form-to-request";
import "../create/create.scss";

const Header = () => {
  const { t } = useTranslation();

  return useMemo(() => {
    return (
      <div className="header">
        <HeaderMenu
          menuKey={CLAIMS_MENU_MODULE_KEY}
          pageKey={CLAIMS_MENU_PAGE_KEY.SUBMIT_CLAIM_APPLICATION}
        />
        <div className="title">{t("claims_edit_title")}</div>
      </div>
    );
  }, [t]);
};

const validate = (formRefs: RefObject<any>[], setFormTouch: any) => {
  setFormTouch(true);
  return !formRefs.some((formRef) => formRef?.current?.getFormState()?.invalid);
};

const Footer = ({ id, formRefs, setFormTouched }: any) => {
  const { t } = useTranslation();
  const history = useHistory();

  const { state } = useContext(ClaimContext);
  const dispatch = useDispatch();

  const { form, disabled } = state;

  const backToList = useCallback(() => {
    history.push(PortalLink(`${FUNCTION_CODE.Claims}`));
  }, [history]);

  const actionWrapper = useCallback(
    (action: Promise<any>, message: string) => {
      dispatch(showSpinner(true));
      action
        .then((response) => {
          if (isResponseOk(response)) {
            backToList();
            showTimestampToastSuccess({ message, t } as any);
          }
        })
        .finally(() => dispatch(showSpinner(false)));
    },
    [backToList, dispatch, t]
  );

  const deleteDraft = useCallback(
    () =>
      actionWrapper(
        ClaimService.deleteDraft(id),
        t("claims_editApplication_deleteSuccessMessage")
      ),
    [actionWrapper, id, t]
  );

  const save = useCallback(() => {
    if (!validate(formRefs, setFormTouched)) {
      return false;
    }

    actionWrapper(
      ClaimService.updateDraft(`${id}`, ToFormRequest(form)),
      t("claims_submit_saveSuccessMessage")
    );
  }, [actionWrapper, form, formRefs, id, setFormTouched, t]);

  const submit = useCallback(async () => {
    if (!validate(formRefs, setFormTouched)) {
      return false;
    }

    actionWrapper(
      ClaimService.submitDraft(id, ToFormRequest(form)),
      t("claims_submit_submitSuccessMessage")
    );
  }, [actionWrapper, form, formRefs, id, setFormTouched, t]);

  return useMemo(() => {
    return (
      <div className="footer p-grid p-align-center p-justify-between">
        <div className="left-button-group">
          <ConfirmButton
            text={t("claims_common_actionBack")}
            className="back-button"
            title={t("claims_message_confirmation")}
            content={t("claims_submit_confirmBackMessage")}
            primaryText={t("claims_common_actionConfirm")}
            primaryAction={backToList}
            outline={true}
          />

          <ConfirmButton
            text={t("claims_common_actionDelete")}
            className="delete-button"
            title={t("claims_editApplication_confirmDeleteTitle")}
            content={t("claims_editApplication_confirmDeleteMessage")}
            primaryText={t("claims_common_actionConfirm")}
            primaryAction={deleteDraft}
            outline={true}
          />
        </div>

        <div className="right-button-group">
          {form?.claimType && (
            <>
              <Button
                className="p-button-outlined secondary"
                onClick={save}
                disabled={disabled}
              >
                {t("claims_common_actionSave")}
              </Button>

              <ConfirmButton
                text={t("claims_common_actionSubmit")}
                className="p-button primary submit-button"
                title={t("claims_message_confirmation")}
                content={t("claims_submit_confirmSubmitMessage")}
                primaryText={t("claims_submit_confirmSubmitButton")}
                primaryAction={submit}
                outline={false}
                disabled={disabled}
              />
            </>
          )}
        </div>
      </div>
    );
  }, [backToList, deleteDraft, form?.claimType, save, submit, t, disabled]);
};

const EditDetail = ({ id }: any) => {
  const headerRef = useRef();
  const detailRef = useRef();
  const delegateRef = useRef();

  const [applicant, setApplicant] = useState("");
  const [formTouched, setFormTouched] = useState(false);
  const { dispatch } = useContext(ClaimContext);

  const getClaimTypeInfo = async (claimType: string, employeeCode: string) => {
    const response = (await ClaimService.getClaimTypeInfoByEmployee(claimType, {
      employeeCode,
    })) as any;
    return response?.data;
  };

  const convertAttachments = (attachments: any[]) =>
    attachments.reduce((records: any, attachment: any) => {
      const value = {
        id: attachment.id,
        name: attachment.fileName,
        uploadId: uuidv4(),
        ...attachment,
      };

      records[value.code] = [...(records[value.code] || []), value];

      return records;
    }, {});

  const getApplication = useCallback(async () => {
    const response = (await ClaimService.getApplication(id)) as any;

    const application = response?.data;
    if (!application) return;

    setApplicant(application.employeeCode);
    const claimType = await getClaimTypeInfo(
      application.claimType,
      application.employeeCode
    );

    const attachments = convertAttachments(application.attachments);
    dispatch({
      type: CLAIM_STORE_ACTION.FORM_UPDATE,
      payload: {
        claimType: application.claimType,
        employeeCode: application.employeeCode,
        fromDate: moment(application.from),
        fromTime: moment(application.from),
        toDate: moment(application.to),
        toTime: moment(application.to),
        units: application.unit,
        amounts: application.amount,
        remarks: application.remarks,
        meal: application.meal > 0,
        transport: application.transport,
        compLeave: application.compLeave > 0,
        isDayInLieuPayment: application.isDayInLieuPayment,
        otMealUnits: application.meal,
        otCompLeaveUnits: application.compLeave,
        requestedBy: application.requestedBy,
        mutualAgreed: application.mutualAgreed,
        ...attachments,
        ...claimType,
      },
    });

    return response?.data;
  }, [id, dispatch]);

  useEffect(() => {
    getApplication();
  }, [getApplication]);

  return (
    <div className="claim-container claim-submit-application">
      <Header />

      <ClaimForm
        isEdit={true}
        headerRef={headerRef}
        detailRef={detailRef}
        delegateRef={delegateRef}
        formTouched={formTouched}
        applicant={applicant}
      />

      <Footer
        id={id}
        formRefs={[headerRef, detailRef, delegateRef]}
        setFormTouched={setFormTouched}
      />
    </div>
  );
};

const Edit = (props: any) => {
  const { id } = props?.match?.params;

  return (
    <>
      <LanguageLoader
        contexts={[
          EEPORTAL_LABEL_BASE_TABLE.BASE_TABLE,
          CLAIMS_T_CONTEXT_KEY.COMMON,
          CLAIMS_T_CONTEXT_KEY.SUBMIT_APPLICATION,
          CLAIMS_T_CONTEXT_KEY.FORM,
        ]}
      />
      <EditDetail id={id} />
    </>
  );
};

export default Edit;
