import React, { useContext } from "react";
import { ITimesheetConfigurationContext } from "./timesheetConfigurationContextTypes";
import { tabConfig } from "../tab-config";
import { TIMESHEET_CONFIGURATION_MENU_Tab_KEY } from "../constants";
import { calendarFormInitValue } from "../form/calendar-form-helper";

const TimesheetConfigurationContextInitValue: ITimesheetConfigurationContext = {
  form: calendarFormInitValue,
  formControls: [],
  formLayout: { rows: [] },
  formProps: {
    ref: null,
    config: {
      controls: [],
      layout: { rows: [] },
    },
    form: calendarFormInitValue,
    touched: false,
    onChange: () => null,
    setForm: () => null,
  },
  isOnChangeLoading: false,
  isLoading: false,
  isPrimaryButtonDisabled: false,
  onPrimaryButtonClick: () => null,
  onTabChange: () => null,
  primaryButtonLabel: "",
  selectedTab: {
    key: TIMESHEET_CONFIGURATION_MENU_Tab_KEY.TIMESHEET_ACTIVITY_CONFIGURATION,
    value: 0,
    name: "",
    type: "Action",
    description: "",
  },
  tabConfig,
  tabs: [],
};

export const TimesheetConfigurationContext =
  React.createContext<ITimesheetConfigurationContext>(
    TimesheetConfigurationContextInitValue
  );

export const useTimesheetConfigurationContext: () => ITimesheetConfigurationContext =
  () => useContext(TimesheetConfigurationContext);
