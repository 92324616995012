import { useEffect, useState } from "react";
import LanguageLoader from "../../../../../components/language-loader/language-loader";
import { EEPORTAL_LABEL_BASE_TABLE } from "../../../../../constants";
import { store } from "../../../../../redux/store";
import { Header } from "../../components/header";
import {
  IPeriodSelectFormData,
  TIMESHEET_CONTEXT_KEY,
  TIMESHEET_MENU_PAGE_KEY,
  periodSelectFormInitialState,
} from "../../constants";
import "./period-select.scss";
import TimesheetService from "../../../../../services/hrmnet-api/timesheet";
import { SelectControlOption } from "../../../../../components/base-control/select-control/select-control";
import { FormSkeleton } from "../../components";
import { PeriodSelectFooter } from "./period-select-footer";
import { PeriodSelectForm } from "./period-select-form";
import { isResponseOk } from "../../../../../utils/utils";
import { Route } from "react-router-dom";
import PageNotFound from "../../../public/error/employee-error-404";

const PeriodSelection = () => {
  const { employeeCode } = store.getState().auth;
  const [loading, setLoading] = useState<boolean>(false);
  const [delegationEmployees, setDelegationEmployees] =
    useState<SelectControlOption[]>();
  const [filterTimePeriods, setFilterTimePeriods] =
    useState<SelectControlOption[]>();
  const [form, setForm] = useState<IPeriodSelectFormData>({
    ...periodSelectFormInitialState,
    employeeCode,
  });
  const [isPageNotFound, setIsPageNotFound] = useState<boolean>(false);

  function isSupport(enums: any[], currentEmployee: any) {
    return (
      enums.find(
        (option) =>
          option.value?.toLowerCase() === currentEmployee.toLowerCase()
      ) !== undefined
    );
  }

  useEffect(() => {
    setLoading(true);
    (async () => {
      const getDelegationEmployeesResponse =
        await TimesheetService.getDelegationEmployees();
      setDelegationEmployees(
        getDelegationEmployeesResponse.data?.map((item: any) => ({
          label: item.name,
          value: item.value,
          description: item.description,
          group: item.description,
        }))
      );
      if (getDelegationEmployeesResponse?.data != null) {
        setForm({
          ...form,
          userSubmit: isSupport(
            getDelegationEmployeesResponse?.data,
            employeeCode
          ),
        });
      }
    })().finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    setLoading(true);
    (async () => {
      const getSelectionPeriodsResponse =
        await TimesheetService.getSelectionPeriods({
          data: { employeeCode: form.employeeCode },
        });

      if (isResponseOk(getSelectionPeriodsResponse)) {
        if (getSelectionPeriodsResponse.data != null) {
          const periods = getSelectionPeriodsResponse.data.map((item: any) => ({
            label: item.name,
            value: item.value,
            description: "",
          }));

          setFilterTimePeriods(periods);
          setForm({
            employeeCode: form.employeeCode,
            employeeName: "",
            userSubmit: form.userSubmit,
            period: "",
          });
          setIsPageNotFound(false);
        } else {
          setFilterTimePeriods(undefined);
          setIsPageNotFound(false);
        }
      } else {
        setIsPageNotFound(true);
      }
    })().finally(() => setLoading(false));
  }, [form.employeeCode, form.userSubmit]);
  if (isPageNotFound) {
    return <Route component={PageNotFound} />;
  } else {
    return (
      <>
        <LanguageLoader
          contexts={[
            EEPORTAL_LABEL_BASE_TABLE.BASE_TABLE,
            TIMESHEET_CONTEXT_KEY.COMMON,
            TIMESHEET_CONTEXT_KEY.FORM,
            TIMESHEET_CONTEXT_KEY.DETAIL,
            TIMESHEET_CONTEXT_KEY.STATUS,
          ]}
        />
        <div className="timesheet-container timesheet-submit-application">
          <Header
            headerTitleKey={TIMESHEET_MENU_PAGE_KEY.PERIOD_SELECT}
            WithHeaderDropdown
          />
          {loading && <FormSkeleton column={2} />}
          {!loading && (
            <PeriodSelectForm
              form={form}
              setForm={setForm}
              filterTimePeriods={filterTimePeriods as SelectControlOption[]}
              delegationEmployees={delegationEmployees}
            />
          )}

          <PeriodSelectFooter form={form} />
        </div>
      </>
    );
  }
};

export default PeriodSelection;
