import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { openModal } from "src/redux/actions/modal";
import { ClaimContext } from "../context/claim-context-provider";

interface ILeaveAlertParams {
  allowRoutes: string[];
  customRules?: {
    [path: string]: (location: any) => boolean;
  };
}

export const useLeaveWithoutSaveAlert = ({
  allowRoutes = [],
  customRules,
}: ILeaveAlertParams) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const isLoading = useSelector((state: any) => {
    return state?.spinner?.isOpen;
  });

  const { state } = useContext(ClaimContext);
  const hasEdit = state?.hasEdit;
  const [triggerExit, setTriggerExit] = useState({
    onOk: false,
    path: "",
  });
  const handleGoToIntendedPage = useCallback(
    (location: string) => history.push(location),
    [history]
  );

  useEffect(() => {
    if (triggerExit.onOk) {
      handleGoToIntendedPage(triggerExit.path);
    }
    const unblock = history.block((location: any) => {
      if (allowRoutes.find((route) => route === location.pathname)) {
        setTriggerExit((obj) => ({
          ...obj,
          onOk: true,
          path: location.pathname,
        }));
        return true;
      }
      if (
        customRules &&
        customRules[location.pathname] &&
        customRules[location.pathname](location)
      ) {
        setTriggerExit((obj) => ({
          ...obj,
          onOk: true,
          path: location.pathname,
        }));
        return true;
      }
      if (!hasEdit || isLoading) {
        return true;
      }

      dispatch(
        openModal({
          title: t("claims_confirm_title"),
          content: t("claims_leave_without_save_confirmMessage"),
          classNameMainDialog: "confirm-message-modal",
          primaryButtonText: t("claims_common_actionConfirm"),
          primaryButtonClickFn: async ({ closeFn }: any) => {
            setTriggerExit((obj) => ({
              ...obj,
              onOk: true,
            }));
            closeFn();
          },
          secondButtonClickFn: ({ closeFn }: any) => {
            closeFn();
          },
        })
      );
      setTriggerExit((obj) => ({ ...obj, path: location.pathname }));
      if (triggerExit.onOk) {
        return true;
      }
      return false;
    });

    return () => {
      unblock();
    };
  }, [
    handleGoToIntendedPage,
    history,
    triggerExit.onOk,
    triggerExit.path,
    dispatch,
    t,
    isLoading,
    hasEdit,
  ]);
};
