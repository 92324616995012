import { createContext, useReducer } from "react";
import { ClaimReducer, initialState } from "./claim-reducer";

const ClaimContext = createContext<any>({
  state: initialState,
  dispatch: () => {},
});

export { ClaimContext };

const ClaimContextProdiver = ({ children }: any) => {
  const [state, dispatch] = useReducer(ClaimReducer, initialState);

  return (
    <ClaimContext.Provider value={{ state, dispatch }}>
      {children}
    </ClaimContext.Provider>
  );
};

export default ClaimContextProdiver;
