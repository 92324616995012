import { Dialog } from "primereact/dialog";
import CreateTimesheetActivities from "../create";
import { useCallback, useContext, useEffect } from "react";
import { ModalContext } from "../../context";
import { ModalAction } from "../../context/action/modal";
import { TIMESHEET_MENU_ACTION_KEY } from "../../constants";
import { useTranslation } from "react-i18next";

export const CreateActivityModal = (props: any) => {
  const [
    {
      isShowActivityModal,
      activityModalDetails: { editActivity },
    },
    dispatch,
  ] = useContext(ModalContext);
  const { t } = useTranslation();
  const onHideModal = useCallback(() => {
    dispatch({
      type: ModalAction.SET_IS_SHOW_ACTIVITY_MODAL,
      payload: false,
    });
  }, [dispatch]);

  useEffect(() => {
    document.body.style.overflow = isShowActivityModal ? "hidden" : "";
  }, [isShowActivityModal]);

  return (
    <>
      {isShowActivityModal && (
        <>
          <Dialog
            header={t(
              editActivity
                ? TIMESHEET_MENU_ACTION_KEY.EDIT_TIMESHEET_ACTIVITY
                : TIMESHEET_MENU_ACTION_KEY.ADD_TIMESHEET_ACTIVITY
            )}
            visible={isShowActivityModal}
            style={{ width: "75vw" }}
            onHide={onHideModal}
            className="p-timesheet-create-modal"
          >
            <CreateTimesheetActivities />
          </Dialog>
        </>
      )}
    </>
  );
};
