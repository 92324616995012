import moment from "moment";
import { DATE_FORMAT } from "../../leave/constants/contants";
import { ClaimLegend } from "../components/claim-legend";
import { TIME_FORMAT } from "../constants";
import {
  AmountField,
  ApproverRemarksField,
  ClaimTypeField,
  CompLeaveDisplayField,
  DateTypeDisplayField,
  FromDateField,
  FromTimeField,
  RemarksField,
  ToDateField,
  ToTimeField,
  UnitField,
} from "../modules/view/form-field-config";
import {
  convertToAttachmentControl,
  convertToCheckboxControl,
  convertToControl,
  convertToTextBoxControl,
  convertToNumberControl,
} from "./analysis-code-helper";
import { initFormAnalysisCode, initFormAttachment } from "./config-helper";
import { DisplayControl } from "./ui-helper";

export const InitForm = (application: any) => {
  let form: any = {
    ...application,
    employee: `${application.employeeName} (${application.employeeCode})`,
    claimType: application.claimType,
    claimTypeName: application.claimTypeName,
    fromDate: moment(application.from).format(DATE_FORMAT),
    fromTime: moment(application.from).format(TIME_FORMAT),
    toDate: moment(application.to).format(DATE_FORMAT),
    toTime: moment(application.to).format(TIME_FORMAT),
    approverRemarks: application?.approverRemarks?.join(" ") ?? "",
    meal: application.meal > 0,
    compLeave: application.compLeave > 0,
    isDayInLieuPayment: application.isDayInLieuPayment,
  };

  // analysis code
  if (application.analyses?.length) {
    const analysisCodeForm = initFormAnalysisCode(application.analyses);
    form = {
      ...form,
      ...analysisCodeForm,
    };
  }

  if (application.attachments?.length) {
    const attachmentForm = initFormAttachment(application.attachments);
    form = {
      ...form,
      ...attachmentForm,
    };
  }

  return form;
};

const ClaimTypeDropDownTemplate = ({ option }: any) => {
  if (!option) return null;
  return (
    <ClaimLegend
      legend="square"
      legendColor={option.color}
      text={option.label}
    />
  );
};

export const GetBasicControl = ({
  claimTypeInfo,
  application,
  claimTypeReadOnly = false,
  t,
}: {
  claimTypeInfo: any;
  application: any;
  claimTypeReadOnly: boolean;
  t: any;
}) => {
  const claimTypeField = {
    ...ClaimTypeField,
    enum: [
      {
        label: claimTypeInfo.itemDescription,
        value: claimTypeInfo.itemType,
      },
    ],
    config: {
      filter: false,
      showClear: false,
      readOnly: claimTypeReadOnly,
    },
    itemTemplate: (option: any) => (
      <ClaimTypeDropDownTemplate option={option} />
    ),
    valueTemplate: (option: any) => (
      <ClaimTypeDropDownTemplate option={option} />
    ),
  };

  return [
    claimTypeField,
    DisplayControl(DateTypeDisplayField, claimTypeInfo?.dateTypeDisplayMode),
    DisplayControl(FromDateField, claimTypeInfo?.transDateDisplayMode),
    DisplayControl(FromTimeField, claimTypeInfo?.transTimeDisplayMode),
    DisplayControl(ToDateField, claimTypeInfo?.transDateDisplayMode),
    DisplayControl(ToTimeField, claimTypeInfo?.transTimeDisplayMode),
    DisplayControl(UnitField, claimTypeInfo?.appliedUnitDisplayMode),
    DisplayControl(AmountField, claimTypeInfo?.appliedAmountDisplayMode),
    RemarksField,
  ];
};

export const getAllControls = ({
  claimTypeDropdownTemplate,
  claimTypeInfo,
  application,
  claimTypeReadOnly,
  t,
}: {
  claimTypeDropdownTemplate: any;
  claimTypeInfo: any;
  application: any;
  claimTypeReadOnly: boolean;
  t: any;
}) => {
  const basic = GetBasicControl({
    claimTypeInfo,
    application,
    claimTypeReadOnly,
    t,
  }).filter((control: any) => !!control);

  const analysesControls =
    application.analyses?.map((analysis: any) => {
      const control = convertToControl({ config: analysis, t });
      return {
        ...control,
        config: {
          ...control.config,
          readOnly: true,
        },
        required: false,
      };
    }) ?? [];

  const mealControls = convertToCheckboxControl({
    config: {
      analysisCode: "meal",
      description: t("claims_form_mealAllowance"),
    },
    t,
    controlConfig: {
      required: false,
      config: {
        readOnly: true,
        disabled: true,
      },
    },
  });

  const compLeaveControls = convertToCheckboxControl({
    config: {
      analysisCode: "isDayInLieuPayment",
      description: t("claims_form_compLeave"),
    },
    t,
    controlConfig: {
      required: false,
      config: {
        readOnly: true,
        disabled: true,
      },
    },
  });

  const transportControls = convertToNumberControl({
    config: {
      analysisCode: "transport",
      description: t("claims_form_transportFare"),
    },
    t,
    controlConfig: {
      required: false,
      config: {
        readOnly: true,
        disabled: true,
        showButtons: false,
      },
    },
  });

  const requestedByControls = convertToTextBoxControl({
    config: {
      analysisCode: "requestedBy",
      description: t("claims_form_requestedBy"),
    },
    t,
    controlConfig: {
      required: false,
      config: {
        readOnly: true,
        disabled: true,
        showButtons: false,
      },
    },
  });

  const mutualAgreedControls = convertToCheckboxControl({
    config: {
      analysisCode: "mutualAgreed",
      description: t("claims_form_mutualAgreed"),
    },
    t,
    controlConfig: {
      required: false,
      config: {
        readOnly: true,
        disabled: true,
        showButtons: false,
      },
    },
  });

  const attachmentControlSet = new Set();
  const attachmentControls =
    application.attachments
      ?.map((attachment: any) => {
        attachmentControlSet.add(attachment.code);

        return convertToAttachmentControl({
          config: {
            typeCode: attachment.code,
            description: attachment.description,
            ...attachment,
          },
          t,
          controlConfig: {
            config: {
              readOnly: true,
            },
          },
        });
      })
      ?.filter((control: any) => !!control) ?? [];

  const auxiliryControls = [
    DisplayControl(mealControls, claimTypeInfo.mealAllowanceDisplayMode),
    DisplayControl(transportControls, claimTypeInfo?.transportFareDisplayMode),
    DisplayControl(compLeaveControls, claimTypeInfo?.compLeaveDisplayMode),
    DisplayControl(
      CompLeaveDisplayField,
      claimTypeInfo?.compLeaveUnitDisplayMode
    ),
    DisplayControl(requestedByControls, claimTypeInfo?.requestedByDisplayMode),
    DisplayControl(
      mutualAgreedControls,
      claimTypeInfo?.mutualAgreedDisplayMode
    ),
  ].filter((control: any) => !!control);

  return [
    ...basic,
    ...auxiliryControls,
    ...analysesControls,
    ...attachmentControls,

    ApproverRemarksField,
  ];
};
