import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import BaseForm from "src/components/base-form/base-form";
import { CLAIM_STORE_ACTION } from "../../constants";
import { ClaimContext } from "../../context/claim-context-provider";
import {
  getDelegationFormControls,
  getDelegationFormLayout,
  isSupport,
} from "../../utils/form-create-helper";

const DelegationForm = ({
  formRef,
  formTouched,
  employees,
  applicant,
}: any) => {
  const { t } = useTranslation();
  const { state, dispatch } = useContext(ClaimContext);
  const [formControls, setFormControls] = useState<any[]>([]);
  const [formLayout, setFormLayout] = useState<any>({});

  const { form, forms } = state;

  useEffect(() => {
    dispatch({
      type: CLAIM_STORE_ACTION.FORM_UPDATE,
      payload: {
        userSubmit: !!forms?.length
          ? forms[0].userSubmit
          : isSupport(employees, applicant),
        employeeCode: !!forms?.length ? forms[0].employeeCode : applicant,
      },
    });
  }, [applicant, dispatch, forms, employees?.length, forms.length]);

  useEffect(() => {
    dispatch({
      type: CLAIM_STORE_ACTION.FORM_UPDATE,
      payload: {
        employeeCode: form.userSubmit ? applicant : null,
      },
    });
  }, [dispatch, applicant, form.userSubmit]);

  useEffect(() => {
    const controls = getDelegationFormControls({
      t,
      employees,
      userSubmit: form.userSubmit,
      disable: !!forms.length,
    });
    const layout = getDelegationFormLayout();

    setFormControls(controls);
    setFormLayout(layout);
  }, [employees, form.userSubmit, forms?.length, t]);

  const onChange = useCallback(
    (change: any) => {
      dispatch({
        type: CLAIM_STORE_ACTION.FORM_UPDATE,
        payload: change.state.form,
      });
    },
    [dispatch]
  );

  const formProps = useMemo(
    () => ({
      ref: formRef,
      config: {
        controls: formControls,
        layout: formLayout,
      },
      form,
      touched: formTouched,
      onChange,
    }),
    [form, formControls, formLayout, formRef, formTouched, onChange]
  );

  if (!employees?.length) {
    return <></>;
  }

  return (
    <div className="test">
      <BaseForm {...formProps} />
    </div>
  );
};

export default DelegationForm;
