import moment from "moment";
import {
  ICreateApplicationFormData,
  TIMESHEET_ACTIVITY_STATUS,
} from "../constants";
import { v4 as uuidv4 } from "uuid";
import { activityFieldList } from "../modules/create/form-helper/create-application-form-create-helper";
import {
  DeleteActivityButton,
  ExcludeMealTimeField,
} from "../modules/create/form-helper/create-application-form-field-config";
import { ConcatDateTime, ParseIntoRequestDateTime } from "./date-helper";
import {
  ITimesheetDetail,
  ITimesheetDetailActivity,
} from "../context/reducer/application";
import { TIMESHEET_DETAIL_TYPE } from "../constants/type";

// Only handle activity logic in this functions
export const toFormRequest: (
  form: ICreateApplicationFormData,
  mode?: TIMESHEET_DETAIL_TYPE,
  activityUuid?: string,
  value?: boolean
) => {
  activitiesInput: ITimesheetDetailActivity[];
  totalAppliedUnits: number;
} = (form, mode, activityUuid, value) => {
  let count = 0;
  let isNoFromTimeOrToTime = false;

  // Parse each activities
  const formActivities: ITimesheetDetail["activities"] = form.activities.map(
    (activity: any) => {
      const { uuid } = activity;

      activityFieldList.forEach((field) => {
        if (field.key !== DeleteActivityButton.key) {
          activity[field.key] = form[`${field.key}_${uuid}`];
        }
      });

      if (!activity.fromTime || !activity.toTime) {
        isNoFromTimeOrToTime = true;
      }

      activity.fromDate = ParseIntoRequestDateTime(form.date as Date);
      activity.toDate = ParseIntoRequestDateTime(activity.toDate);
      activity.fromTime = activity.fromTime
        ? ParseIntoRequestDateTime(activity.fromTime)
        : undefined;
      activity.toTime = activity.toTime
        ? ParseIntoRequestDateTime(activity.toTime)
        : undefined;
      activity.activityId = parseInt(activity.activityId);

      if (mode === TIMESHEET_DETAIL_TYPE.AMEND) {
        activity.status = TIMESHEET_ACTIVITY_STATUS.DRAFT;
      }

      const startTime = ConcatDateTime(
        form.date as Date,
        new Date(activity.fromTime as string)
      );
      const endTime = ConcatDateTime(
        new Date(activity.toDate as string),
        new Date(activity.toTime as string)
      );

      const timeDiff = moment.duration(endTime.diff(startTime)).asHours();

      if (form[`${ExcludeMealTimeField.key}_${uuid}`]) {
        if (timeDiff > form.excludeMealTimeHours) {
          activity.appliedUnits = timeDiff - form.excludeMealTimeHours;
        } else {
          activity.appliedUnits = timeDiff - timeDiff;
        }
      } else {
        activity.appliedUnits = timeDiff;
      }

      if (activityUuid === uuid) {
        if (value) {
          if (timeDiff > form.excludeMealTimeHours) {
            count += activity.appliedUnits - form.excludeMealTimeHours;
            activity.appliedUnits -= form.excludeMealTimeHours;
            activity.excludeMealTime = value;
          } else {
            count += activity.appliedUnits - activity.appliedUnits;
            activity.appliedUnits -= activity.appliedUnits;
            activity.excludeMealTime = value;
          }
        } else {
          if (timeDiff > form.excludeMealTimeHours) {
            count += activity.appliedUnits + form.excludeMealTimeHours;
            activity.appliedUnits += form.excludeMealTimeHours;
            activity.excludeMealTime = value;
          } else {
            count += timeDiff;
            activity.appliedUnits += timeDiff;
            activity.excludeMealTime = value;
          }
        }
      } else {
        count += activity.appliedUnits;
      }

      activity.remarks = form.remarks?.length ? form.remarks : null;

      activity.amended = true;

      return activity;
    }
  );

  // Duplicated activities with calendar dates
  let duplicateActivities: any[] = [];

  if (form.applyMultiple && form.calendarDates?.length > 0) {
    form.calendarDates.forEach((date, index) => {
      duplicateActivities = [
        ...duplicateActivities,
        ...formActivities.map((activity) => {
          let newUUID = uuidv4();
          const dateDifference = moment(activity.toDate).diff(
            activity.fromDate,
            "days"
          );
          count += activity.appliedUnits;
          return {
            ...activity,
            // Remove id if it is in edit mode, as it will have same id
            // BE will treat the two activity as the same one
            id: null,
            fromDate: ParseIntoRequestDateTime(date),
            toDate: ParseIntoRequestDateTime(
              moment(date).add(dateDifference, "days").toDate()
            ),
            fromTime: activity.fromTime,
            toTime: activity.toTime,
            uuid: newUUID,
          };
        }),
      ];
    });
  }

  // const requestInput: TimesheetApplicationModel = {
  //   periodId: parseInt(form.periodId as string),
  //   employeeCode: form.employeeCode as string,
  //   activities: [...formActivities, ...duplicateActivities],
  // };

  return {
    activitiesInput: [...formActivities, ...duplicateActivities],
    totalAppliedUnits: isNoFromTimeOrToTime ? 0 : count,
  };
};
