import moment from "moment";
import { BCType } from "../../../../../components/base-control/base-control";
import { generateBasicLayout } from "../../../../../components/base-form/utils";
import {
  AnalysisCode,
  convertToAttachmentControl,
  convertToControl,
  convertToNoteControl,
} from "./../../utils/analysis-code-helper";
import { getDisplayUom, extractUnit } from "../../utils/utils";
import { DATETIME_FORMAT, TIME_FORMAT } from "../../constants/contants";
import {
  LeaveFormConfig,
  BASIC_CONTROL_LAYOUT_CONFIG,
  BASIC_CONTROL_LAYOUT_CONFIG_FULL_COLUMN,
  BASIC_CONTROL_LAYOUT_CONFIG_HALF_COLUMN,
  TIME_SNAP_OPTION,
  LeaveApplicationModel,
  initFormAnalysisCode,
  initFormAttachment,
} from "../../utils/config-helper";
import { isAutoCalculateEndDate } from "./../../utils/config-helper";
import { htmlDecode } from "../../../../../utils/utils";

export interface LeaveInfo extends LeaveFormConfig {
  [key: string]: any;
}

export const getInitLayout = () => {
  return {
    rows: [
      {
        columns: [
          {
            control: "EmployeeCode",
            config: { ...BASIC_CONTROL_LAYOUT_CONFIG },
          },
          {
            control: "IsUserApplication",
            config: {
              ...BASIC_CONTROL_LAYOUT_CONFIG,
              className: `${BASIC_CONTROL_LAYOUT_CONFIG?.className} checkbox-custom`,
            },
          },
        ],
      },
      {
        columns: [
          {
            control: "LeaveCode",
            config: { ...BASIC_CONTROL_LAYOUT_CONFIG },
          },
        ],
      },
    ],
  };
};

export const getBasicLayout = (leaveInfo: LeaveFormConfig) => {
  const autoCalEndDate = isAutoCalculateEndDate(leaveInfo);
  return {
    rows: [
      {
        columns: [
          {
            control: "EmployeeCode",
            config: { ...BASIC_CONTROL_LAYOUT_CONFIG },
          },
          {
            control: "IsUserApplication",
            config: {
              ...BASIC_CONTROL_LAYOUT_CONFIG,
              className: `${BASIC_CONTROL_LAYOUT_CONFIG?.className} checkbox-custom`,
            },
          },
        ],
      },
      {
        columns: [
          {
            control: "LeaveCode",
            config: { ...BASIC_CONTROL_LAYOUT_CONFIG },
          },
          {
            control: "Balance",
            config: { ...BASIC_CONTROL_LAYOUT_CONFIG },
          },
        ],
      },
      {
        columns: [
          {
            control: "LeaveStartDate",
            config: { ...BASIC_CONTROL_LAYOUT_CONFIG_HALF_COLUMN },
          },
          ...(leaveInfo.useTimeSegmentName && !autoCalEndDate
            ? [
                {
                  control: "LeaveStartTimeSegment",
                  config: { ...BASIC_CONTROL_LAYOUT_CONFIG_HALF_COLUMN },
                },
              ]
            : [
                {
                  control: "LeaveStartTime",
                  config: { ...BASIC_CONTROL_LAYOUT_CONFIG_HALF_COLUMN },
                },
              ]),
        ],
      },
      {
        columns: [
          {
            control: "LeaveEndDate",
            config: { ...BASIC_CONTROL_LAYOUT_CONFIG_HALF_COLUMN },
          },
          ...(leaveInfo.useTimeSegmentName && !autoCalEndDate
            ? [
                {
                  control: "LeaveEndTimeSegment",
                  config: { ...BASIC_CONTROL_LAYOUT_CONFIG_HALF_COLUMN },
                },
              ]
            : [
                {
                  control: "LeaveEndTime",
                  config: { ...BASIC_CONTROL_LAYOUT_CONFIG_HALF_COLUMN },
                },
              ]),
        ],
      },
      {
        columns: [
          {
            control: "Units",
            config: { ...BASIC_CONTROL_LAYOUT_CONFIG },
          },
        ],
      },
      {
        columns: [
          {
            control: "Remarks",
            config: { ...BASIC_CONTROL_LAYOUT_CONFIG_FULL_COLUMN },
          },
        ],
      },
    ],
  };
};

export const getInitControl = ({
  leaveTypes,
  t,
  delegatedEmployeeList = [],
}: {
  leaveTypes: any[];
  t: any;
  delegatedEmployeeList?: any[];
}) => {
  const controls = [
    {
      key: "LeaveCode",
      label: t("leave_form_leaveType"),
      type: BCType.select,
      required: true,
      enum: leaveTypes.map((leave) => ({
        label: `${leave.leaveCode} - ${leave.leaveName}`,
        value: leave.leaveCode,
      })),
      hasFilterEnum: false,
      placeholder: t("leave_form_selectLeaveType"),
    },
    {
      key: "EmployeeCode",
      label: t("leave_form_delegatedEmployee"),
      type: BCType.select,
      required: true,
      enum: delegatedEmployeeList,
      hasFilterEnum: false,
      placeholder: t("leave_form_delegatedEmployee_placeholder"),
      config: {
        disabled: false,
      },
    },
    {
      key: "IsUserApplication",
      label: t("leave_form_userSubmit"),
      type: BCType.checkbox,
      required: false,
      placeholder: t("leave_form_userSubmit_placeholder"),
    },
  ];
  return controls;
};

export const getBasicControl = ({
  leaveTypes,
  t,
  leaveInfo,
  onSeeBalanceDetail,
  delegatedEmployeeList,
  setAllowCalculateUnit,
}: {
  leaveTypes: any[];
  t: any;
  leaveInfo: LeaveFormConfig;
  onSeeBalanceDetail: any;
  delegatedEmployeeList?: any[];
  setAllowCalculateUnit: (value: boolean) => void;
}) => {
  // Basic setting
  const _formConfig = { ...leaveInfo };
  const showTodayBalance = false;
  const unit = getDisplayUom(_formConfig.uom, t);

  const timePickerStep =
    _formConfig.periodFromSnapTo &&
    _formConfig.periodFromSnapTo in TIME_SNAP_OPTION
      ? TIME_SNAP_OPTION[_formConfig.periodFromSnapTo].step
      : _formConfig.periodToSnapTo &&
        _formConfig.periodToSnapTo in TIME_SNAP_OPTION
      ? TIME_SNAP_OPTION[_formConfig.periodToSnapTo].step
      : 5;

  const autoCalEndDate = isAutoCalculateEndDate(leaveInfo);
  const sampleTimeSegment = [
    { label: "AM", value: "AM" },
    { label: "PM", value: "PM" },
  ];

  const handleOnhide = () => {
    setAllowCalculateUnit(true);
  };

  return [
    ,
    {
      key: "EmployeeCode",
      label: t("leave_form_delegatedEmployee"),
      type: BCType.select,
      required: true,
      enum: delegatedEmployeeList,
      hasFilterEnum: false,
      placeholder: t("leave_form_delegatedEmployee_placeholder"),
      config: {
        disabled: false,
      },
    },
    {
      key: "IsUserApplication",
      label: t("leave_form_userSubmit"),
      type: BCType.checkbox,
      required: false,
      placeholder: t("leave_form_userSubmit_placeholder"),
    },
    {
      key: "LeaveCode",
      label: t("leave_form_leaveType"),
      type: BCType.select,
      required: true,
      enum: leaveTypes.map((leave) => ({
        label: `${leave.leaveCode} - ${leave.leaveName}`,
        value: leave.leaveCode,
      })),
      hasFilterEnum: false,
      placeholder: t("leave_form_selectLeaveType"),
    },
    ...(_formConfig.showBalanceOnApplication
      ? [
          {
            key: "Balance",
            noLabel: true,
            componentRender: () => (
              <div className="leave-application-balance">
                <div>
                  {showTodayBalance
                    ? t("leave_submit_yourBalanceAsOfToday", {
                        leaveType: _formConfig.leaveName,
                      })
                    : t("leave_submit_yourBalanceAsOfYeadEnd", {
                        leaveType: _formConfig.leaveName,
                      })}
                </div>
                <div onClick={onSeeBalanceDetail}>
                  (<u>{t("leave_submit_seeBalanceDetail")}</u>)
                </div>
                <div className="app-balance">{`${
                  showTodayBalance
                    ? _formConfig.leaveBalanceAsOfCalcDate
                    : _formConfig.leaveBalanceAsOfYearEnd
                } ${unit}`}</div>
              </div>
            ),
          },
        ]
      : []), // TODO
    {
      key: "LeaveStartDate",
      label: t("leave_form_leaveStartDate"),
      type: BCType.date,
      required: true,
      dateFormat: "yy-mm-dd",
      placeholder: t("leave_form_select"),
      config: {
        footerTemplate: () => (
          <div className="leave-submit-legend">
            <strong className="hollow-circle p-disabled" />
            <div className="description">
              {t("leave_form_calendarLegend_appliedLeave")}
            </div>
          </div>
        ),
      },
    },
    ...(_formConfig.useTimeSegmentName && !autoCalEndDate
      ? [
          {
            key: "LeaveStartTimeSegment",
            label: t("leave_form_leaveStartTime"),
            type: BCType.select,
            required: !autoCalEndDate,
            enum: sampleTimeSegment,
            config: {
              readOnly: autoCalEndDate,
              tooltip: null,
              filter: false,
              showClear: false,
            },
          },
        ]
      : [
          {
            key: "LeaveStartTime",
            label: t("leave_form_leaveStartTime"),
            type: BCType.date,
            required: !autoCalEndDate,
            includeTimeZone: true,
            config: {
              readOnly: autoCalEndDate,
              tooltip: null,
              timeOnly: true,
              showIcon: false,
              hourFormat: "12",
              stepMinute: timePickerStep,
              onHide: () => {
                handleOnhide();
              },
            },
          },
        ]),
    {
      key: "LeaveEndDate",
      label: t("leave_form_leaveEndDate"),
      type: BCType.date,
      required: !autoCalEndDate,
      dateFormat: "yy-mm-dd",
      placeholder: t("leave_form_select"),
      config: {
        readOnly: autoCalEndDate,
        footerTemplate: () => (
          <div className="leave-submit-legend">
            <strong className="hollow-circle" />
            <div className="description">
              {t("leave_form_calendarLegend_appliedLeave")}
            </div>
          </div>
        ),
      },
    },
    ...(_formConfig.useTimeSegmentName && !autoCalEndDate
      ? [
          {
            key: "LeaveEndTimeSegment",
            label: t("leave_form_leaveEndtime"),
            type: BCType.select,
            required: !autoCalEndDate,
            enum: sampleTimeSegment,
            config: {
              readOnly: autoCalEndDate,
              tooltip: null,
              filter: false,
              showClear: false,
            },
          },
        ]
      : [
          {
            key: "LeaveEndTime",
            label: t("leave_form_leaveEndtime"),
            type: BCType.date,
            required: !autoCalEndDate,
            includeTimeZone: true,
            config: {
              readOnly: autoCalEndDate,
              tooltip: null,
              timeOnly: true,
              showIcon: false,
              hourFormat: "12",
              stepMinute: timePickerStep,
              onHide: () => {
                handleOnhide();
              },
            },
          },
        ]),
    {
      key: "Units",
      label: t("leave_form_unit"),
      type: BCType.number,
      config: {
        readOnly: true,
        tooltip: null,
        suffix: ` ${unit}`,
        mode: "decimal",
        minFractionDigits: 1,
        maxFractionDigits: 1,
        showButtons: false,
      },
      placeholderStrict: true,
      placeholder: " ",
    },
    {
      key: "Remarks",
      label: t("leave_form_remarks"),
      type: BCType.textarea,
      config: {
        autoResize: true,
        rows: 1,
        tooltip: null,
      },
      placeholder: t("leave_form_entryRemarks"),
      maxLength: 70,
    },
  ];
};

// Create form
export const getControlsAndLayout = ({
  leaveTypes,
  leaveInfo,
  selectedLeaveCode,
  onSeeBalanceDetail,
  t,
  delegatedEmployeeList,
  form,
  editMode,
  delegateeMode,
  setAllowCalculateUnit,
}: {
  leaveTypes: any[];
  leaveInfo: any;
  selectedLeaveCode: string;
  onSeeBalanceDetail: any;
  t: any;
  delegatedEmployeeList: any[];
  form: any;
  editMode?: boolean;
  delegateeMode?: boolean;
  setAllowCalculateUnit: (value: boolean) => void;
}) => {
  let controls = [];
  let layout: any = {};
  let _editMode = editMode ?? false;
  let _delegateeMode = delegateeMode ?? false;

  if (selectedLeaveCode && Object.keys(leaveInfo).length) {
    controls = getBasicControl({
      leaveTypes,
      leaveInfo,
      t,
      onSeeBalanceDetail,
      delegatedEmployeeList,
      setAllowCalculateUnit,
    });
    layout = getBasicLayout(leaveInfo);

    // render extra fields
    const analysisCodeControls = leaveInfo.leaveAnalysisCode.map((code: any) =>
      convertToControl({ config: code, t })
    );
    if (analysisCodeControls?.length) {
      controls = controls.concat(analysisCodeControls);
      layout.rows = layout.rows.concat(
        (generateBasicLayout(analysisCodeControls) as any).rows
      );
    }

    // render reminder
    if (leaveInfo.reminderMessage) {
      const noteControls = [convertToNoteControl(leaveInfo.reminderMessage)];
      controls = controls.concat(noteControls);
      layout.rows = layout.rows.concat(
        (generateBasicLayout(noteControls) as any).rows
      );
    }

    // render required file attachment
    const requiredFileControls = leaveInfo.applyLeaveRequiredAttachmentType.map(
      (fileConfig: any) =>
        convertToAttachmentControl({
          config: fileConfig,
          t: t,
          controlConfig: {
            required: true,
          },
        })
    );
    if (requiredFileControls?.length) {
      controls = controls.concat(requiredFileControls);
      layout.rows = layout.rows.concat(
        (generateBasicLayout(requiredFileControls) as any).rows
      );
    }

    // render optional file attachment
    const optionalFileControls = leaveInfo.applyLeaveOptionalAttachmentType.map(
      (fileConfig: any) =>
        convertToAttachmentControl({
          config: fileConfig,
          t: t,
          controlConfig: {
            required: false,
          },
        })
    );
    if (optionalFileControls?.length) {
      controls = controls.concat(optionalFileControls);
      layout.rows = layout.rows.concat(
        (generateBasicLayout(optionalFileControls) as any).rows
      );
    }
  } else {
    controls = getInitControl({ leaveTypes, t, delegatedEmployeeList });
    layout = getInitLayout();
  }

  const _layout: any = { rows: [] };
  layout.rows.forEach((row: any) => {
    let _column = row?.columns?.filter((column: any) => {
      if (
        (!delegatedEmployeeList &&
          !Array.isArray(delegatedEmployeeList) &&
          ["EmployeeCode", "IsUserApplication"].includes(column.control)) ||
        (!form["EmployeeCode"] &&
          !form["IsUserApplication"] &&
          !["EmployeeCode", "IsUserApplication"].includes(column.control)) ||
        (_delegateeMode &&
          _editMode &&
          column.control === "IsUserApplication") ||
        (!_delegateeMode &&
          _editMode &&
          ["EmployeeCode", "IsUserApplication"].includes(column.control))
      ) {
        return false;
      }

      return true;
    });

    if (_column.length > 0) {
      _layout.rows.push({
        columns: _column,
      });
    }
  });

  controls = controls.map((x: any) => {
    if (x?.key === "EmployeeCode") {
      return {
        ...x,
        config: {
          disabled: form["IsUserApplication"] === true || _editMode,
        },
        required: !form["IsUserApplication"] && !_editMode,
      };
    }

    if (x?.key === "IsUserApplication" && _editMode) {
      return {
        ...x,
        config: {
          disabled: _editMode,
        },
        required: !_editMode,
      };
    }

    return x;
  });

  return [controls, _layout];
};

export const initFormWithLeaveApplication = (
  application: LeaveApplicationModel
) => {
  let form: any = {
    LeaveCode: application.leaveCode,
    Balance: application.balanceAsOfYearEnd,
    LeaveStartDate: new Date(application.leaveFrom),
    LeaveStartTime: new Date(application.leaveFrom),
    LeaveEndDate: new Date(application.leaveTo),
    LeaveEndTime: new Date(application.leaveTo),
    Units: extractUnit(application.unit),
    Remarks: htmlDecode(application.remarks),
  };

  // analysis code
  if (application.analyses?.length) {
    const analysisCodeForm = initFormAnalysisCode(application.analyses);
    form = {
      ...form,
      ...analysisCodeForm,
    };
  }

  if (application.attachment?.length) {
    const attachmentForm = initFormAttachment(application.attachment);
    form = {
      ...form,
      ...attachmentForm,
    };
  }

  return form;
};

export const prepareApplicationPayload = ({
  form,
  leaveInfo,
  amend,
  preApproval,
  includeAttachment = true,
  defaultUnits = 0, // custom logic, requried by backend. Allow to set to 1 when calculate unit
}: {
  form: any;
  leaveInfo: LeaveFormConfig;
  amend: boolean;
  preApproval: boolean;
  includeAttachment: boolean;
  defaultUnits: Number;
}) => {
  const {
    // Value that needs to process before submit
    LeaveStartDate: leaveStartDate,
    LeaveStartTime: leaveStartTime,
    LeaveStartTimeSegment: leaveStartTimeSegment,
    LeaveEndDate: leaveEndDate,
    LeaveEndTime: leaveEndTime,
    LeaveEndTimeSegment: leaveEndTimeSegment,
  } = form;

  let retForm: any = {
    LeaveCode: form["LeaveCode"],
    UnitOfMeasure: preApproval
      ? leaveInfo.leavePreApproval?.[0].unitOfMeasure
      : leaveInfo.uom,
    Remarks: form["Remarks"] || "",
    Units: form["Units"] || defaultUnits,
  };

  // Process LeaveStartDatetime
  // Backend required to always submit with a date even when date is not needed from use
  let retLeaveStartDatetime;
  retLeaveStartDatetime = moment(leaveStartDate || leaveEndDate || new Date());
  if (leaveInfo.useTimeSegmentName && leaveInfo?.segmentTimeInfo) {
    const leaveStartSegmentTime =
      leaveInfo.segmentTimeInfo["start"][leaveStartTimeSegment];
    retLeaveStartDatetime = retLeaveStartDatetime.set({
      hour: leaveStartSegmentTime.getHours() || 0,
      minute: leaveStartSegmentTime.getMinutes() || 0,
      second: leaveStartSegmentTime.getSeconds() || 0,
    });
  } else {
    retLeaveStartDatetime = retLeaveStartDatetime.set({
      hour: leaveStartTime?.getHours() || 0,
      minute: leaveStartTime?.getMinutes() || 0,
      second: leaveStartTime?.getSeconds() || 0,
    });
  }
  retLeaveStartDatetime = retLeaveStartDatetime.format(DATETIME_FORMAT);
  retForm["LeaveStartDateTimeOrDate"] = retLeaveStartDatetime;

  // Process LeaveEndDatetime
  // Backend requried logic: if empty, match start date or fill new Date()
  let retLeaveEndDatetime;
  retLeaveEndDatetime = moment(leaveEndDate || leaveStartDate || new Date());
  if (leaveInfo.useTimeSegmentName && leaveInfo?.segmentTimeInfo) {
    const leaveEndSegmentTime =
      leaveInfo.segmentTimeInfo["end"][leaveEndTimeSegment];
    retLeaveEndDatetime = retLeaveEndDatetime.set({
      hour: leaveEndSegmentTime.getHours() || 0,
      minute: leaveEndSegmentTime.getMinutes() || 0,
      second: leaveEndSegmentTime.getSeconds() || 0,
    });
  } else {
    retLeaveEndDatetime = retLeaveEndDatetime.set({
      hour: leaveEndTime.getHours() || 0,
      minute: leaveEndTime.getMinutes() || 0,
      second: leaveEndTime.getSeconds() || 0,
    });
  }
  retLeaveEndDatetime = retLeaveEndDatetime.format(DATETIME_FORMAT);
  retForm["LeaveEndDateTimeOrDate"] = retLeaveEndDatetime;

  // Amend
  retForm["Amend"] = !!amend;

  // Preapproval logic -- eventDateApplication
  // if (preApproval && leaveInfo.leavePreApproval?.[0]?.eventDateApplication) {
  //   retForm["LeaveEndDateTimeOrDate"] = retForm["LeaveStartDateTimeOrDate"];
  // }

  // PreApproval logic
  retForm["IsAccrual"] = !!preApproval;

  // analysis code
  let analysisCodeList = preApproval
    ? leaveInfo?.leavePreApproval?.[0]?.applyEntitlementAnalysisCode || []
    : leaveInfo.leaveAnalysisCode || [];

  analysisCodeList.forEach((code: AnalysisCode) => {
    if (!("Analysis" in retForm)) {
      retForm["Analysis"] = [];
    }
    if (form[code.analysisCode]) {
      let value = form[code.analysisCode];
      if (value instanceof Date) {
        value = moment(value).format(DATETIME_FORMAT);
      }
      // construct json
      let jsonValue: any = {
        AnalysisCodeId: code.id,
        AnalysisCodeValue: [
          {
            Value: value,
          },
        ],
      };
      // stringify
      retForm["Analysis"].push(jsonValue);
    }
  });
  if (retForm["Analysis"]) {
    retForm["Analysis"] = JSON.stringify(retForm["Analysis"]);
  }

  // documents
  if (includeAttachment) {
    // temp solution: use key=AttachmentList and assume there will be only 1 attachmentType
    retForm["Attachment"] = null;

    let documentList = preApproval
      ? [
          ...(leaveInfo.leavePreApproval?.[0]
            .applyEntitlementOptionalAttachmentType || []),
          ...(leaveInfo.leavePreApproval?.[0]
            .applyEntitlementRequiredAttachmentType || []),
        ]
      : [
          ...leaveInfo.applyLeaveOptionalAttachmentType,
          ...leaveInfo.applyLeaveRequiredAttachmentType,
        ];

    documentList.forEach((attachmentConfig: any) => {
      if (form[attachmentConfig.typeCode]) {
        retForm["AttachmentTypeCode"] = attachmentConfig.typeCode;
        // new files
        retForm["AttachmentList"] = form[attachmentConfig.typeCode]?.filter(
          (x: any) => x instanceof File
        );
        // existing files
        form[attachmentConfig.typeCode]
          ?.filter((x: any) => !(x instanceof File))
          .forEach((apiFile: any, idx: number) => {
            const { id, name, uploadId, ..._apiFile } = apiFile;
            delete retForm["Attachment"];
            Object.keys(_apiFile).forEach((key: any) => {
              retForm[`Attachment[${idx}][${key}]`] = _apiFile[key];
            });
          });
      }
    });
  }

  if (!!form["EmployeeCode"] && !form["IsUserApplication"]) {
    retForm["EmployeeCode"] = form["EmployeeCode"];
  }

  return retForm;
};

export const identifyTimeSegment = ({
  form,
  unitInfo,
}: {
  form: any;
  unitInfo: any;
}) => {
  let result = {
    start: "",
    end: "",
  };

  const appStartTime = moment(form["LeaveStartDate"]).format(TIME_FORMAT);
  const appEndTime = moment(form["LeaveEndDate"]).format(TIME_FORMAT);

  if (unitInfo?.startDateInformation?.shiftActualStartAt) {
    const shiftStartTime = moment(
      unitInfo?.startDateInformation?.shiftActualStartAt
    ).format(TIME_FORMAT);
    if (appStartTime === shiftStartTime) {
      result["start"] = "AM";
    }
  }
  if (unitInfo?.toDateInformation?.mealActualStartAt) {
    const mealStartTime = moment(
      unitInfo?.toDateInformation?.mealActualStartAt
    ).format(TIME_FORMAT);
    if (appEndTime === mealStartTime) {
      result["end"] = "AM";
    }
  }
  if (unitInfo?.startDateInformation?.mealActualEndAt) {
    const mealEndTime = moment(
      unitInfo?.startDateInformation?.mealActualEndAt
    ).format(TIME_FORMAT);
    if (appStartTime === mealEndTime) {
      result["start"] = "PM";
    }
  }
  if (unitInfo?.toDateInformation?.shiftActualEndAt) {
    const shiftEndTime = moment(
      unitInfo?.toDateInformation?.shiftActualEndAt
    ).format(TIME_FORMAT);
    if (appEndTime === shiftEndTime) {
      result["end"] = "PM";
    }
  }

  return result;
};
