import { TFunction } from "react-i18next";
import { BASIC_CONTROL_LAYOUT_CONFIG } from "../../../leave/utils/config-helper";
import { IGetPmActivitiesDetailData } from "../constants";
import {
  IActivityFormData,
  TimesheetPmActivitiesDetailConfigurationModel,
} from "../types/types";
import {
  ActivityFormConfigFieldKey,
  activityFormConfigFieldList,
} from "./activity-form-field-config";
import { v4 as uuidv4 } from "uuid";
import {
  BASIC_CONTROL_LAYOUT_CONFIG_ONE_FIFTH_COLUMN,
  IBaseFormControlProps,
  ISelectOptionData,
} from "../../../timesheet/constants";
import { IFormLayout } from "../context/timesheetConfigurationContextTypes";
import { Button } from "primereact/button";
import moment from "moment";

export const activityFormInitValue: IActivityFormData = { pmActivities: [] };

const parseFieldKeyListIntoFormKeyList = (uuid: string) =>
  activityFormConfigFieldList.map((control) => `${control.key}_${uuid}`);

export const parseActivityResponseIntoForm = (
  data: IGetPmActivitiesDetailData
) => {
  let toReturn: IActivityFormData = { pmActivities: [] };
  data?.forEach((config) => {
    const uuid = uuidv4();
    toReturn = {
      ...toReturn,
      [`${ActivityFormConfigFieldKey.activityCode}_${uuid}`]:
        config.activityCode,
      [`${ActivityFormConfigFieldKey.projectCode}_${uuid}`]: config?.projectId,
      [`${ActivityFormConfigFieldKey.effectiveFrom}_${uuid}`]: new Date(
        config.effectiveFrom
      ),
      [`${ActivityFormConfigFieldKey.effectiveTo}_${uuid}`]: new Date(
        config.effectiveTo
      ),
      [`${ActivityFormConfigFieldKey.description}_${uuid}`]: config.description,
      pmActivities: [...toReturn.pmActivities, { ...config, uuid }],
    };
  });

  return toReturn;
};

export const getActivityFormControls = ({
  t,
  activityForm,
  setActivityForm,
  projectList,
}: {
  t: TFunction<"translation">;
  activityForm: IActivityFormData;
  setActivityForm: React.Dispatch<React.SetStateAction<IActivityFormData>>;
  projectList: ISelectOptionData[];
}): IBaseFormControlProps[] => {
  const onDeleteActivityButtonClick = (uuid: string) => {
    const cloneActivities = [...activityForm.pmActivities].filter(
      (pmActivity) => pmActivity.uuid !== uuid
    );

    const cloneForm: IActivityFormData = {
      ...activityForm,
      pmActivities: cloneActivities,
    };
    parseFieldKeyListIntoFormKeyList(uuid).forEach(
      (key) => (cloneForm[key] = undefined)
    );

    setActivityForm(cloneForm);
  };

  return activityFormConfigFieldList
    .map((control) => {
      return activityForm?.pmActivities.map((pmActivity) => {
        return {
          ...control,
          key: `${control.key}_${pmActivity.uuid}`,
          label: `${t(control.label)}`,
          ...(control.key === ActivityFormConfigFieldKey.projectCode
            ? { enum: projectList }
            : {}),
          ...("placeholder" in control
            ? { placeholder: t(control.placeholder as string) }
            : {}),
          ...(control.key === ActivityFormConfigFieldKey.deletePmActivity
            ? {
                onClick: () => onDeleteActivityButtonClick(pmActivity.uuid),
              }
            : {}),
        };
      });
    })
    .flat();
};

export const getActivityFormLayout = ({
  t,
  activityForm,
  setActivityForm,
}: {
  t: TFunction<"translation">;
  activityForm: IActivityFormData;
  setActivityForm: React.Dispatch<React.SetStateAction<IActivityFormData>>;
}): IFormLayout => {
  return {
    rows: [
      ...activityForm.pmActivities.map((pmActivity) => ({
        columns: activityFormConfigFieldList.map((control) => ({
          control: `${control.key}_${pmActivity.uuid}`,
          config: {
            ...BASIC_CONTROL_LAYOUT_CONFIG,
            ...BASIC_CONTROL_LAYOUT_CONFIG_ONE_FIFTH_COLUMN,
          },
        })),
      })),
      {
        columns: [
          {
            component: () => (
              <div className={`base-control`}>
                <div className={`p-field`}>
                  {/* empty label to match with the height of other input */}
                  <label />
                  <div className="p-inputgroup">
                    <Button
                      label={t("timesheet_common_actionAddActivity")}
                      type="button"
                      onClick={() => {
                        const uuid = uuidv4();

                        const cloneForm: IActivityFormData = {
                          ...activityForm,
                        };
                        parseFieldKeyListIntoFormKeyList(uuid).forEach(
                          (key) => (cloneForm[key] = undefined)
                        );
                        setActivityForm({
                          ...cloneForm,
                          pmActivities: [
                            ...activityForm.pmActivities,
                            { uuid },
                          ],
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            ),
          },
        ],
      },
    ],
  };
};

export const parseActivityFormIntoRequestInput = ({
  form,
}: {
  form: IActivityFormData;
}): TimesheetPmActivitiesDetailConfigurationModel => {
  return {
    PmActivitiesList: form.pmActivities.map((pmActivity) => {
      return {
        Id: pmActivity?.id,
        ActivityCode:
          form[`${ActivityFormConfigFieldKey.activityCode}_${pmActivity.uuid}`],
        ProjectId: parseInt(
          form?.[`${ActivityFormConfigFieldKey.projectCode}_${pmActivity.uuid}`]
        ),
        EffectiveFrom: moment(
          form[`${ActivityFormConfigFieldKey.effectiveFrom}_${pmActivity.uuid}`]
        ).toISOString(true),
        EffectiveTo: moment(
          form[`${ActivityFormConfigFieldKey.effectiveTo}_${pmActivity.uuid}`]
        ).toISOString(true),
        Description:
          form[`${ActivityFormConfigFieldKey.description}_${pmActivity.uuid}`],
      };
    }),
  };
};
