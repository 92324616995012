import moment from "moment";
import { convertToTextBoxControl } from "../../leave/utils/analysis-code-helper";
import { BASIC_CONTROL_LAYOUT_CONFIG } from "../constants/layout";
import { ClaimTypeSettings } from "../models/claim-type-settings-model";
import { IFormField } from "../modules/view/form-field-config";
import {
  convertToAttachmentControl,
  convertToCheckboxControl,
  convertToControl,
  convertToNoteControl,
  convertToNumberControl,
} from "./analysis-code-helper";
import {
  BASIC_CONTROL_LAYOUT_CONFIG_FULL_COLUMN,
  BASIC_CONTROL_LAYOUT_CONFIG_HALF_COLUMN,
} from "./config-helper";
import {
  AmountField,
  ClaimTypesField,
  DisplayCompLeaveField,
  DisplayDateTypeField,
  EmployeeCodeField,
  FromDateField,
  FromTimeField,
  RemarksField,
  ToDateField,
  ToTimeField,
  UnitField,
  UserSubmitField,
} from "./form-field-config";
import {
  DisplayControl,
  EnableControl,
  GetDisplayUom,
  ToUIOption,
} from "./ui-helper";

const createColumn = (control: string, layoutConfig: any = {}) => ({
  control,
  config: {
    ...BASIC_CONTROL_LAYOUT_CONFIG,
    ...layoutConfig,
  },
});

export function isSupport(enums: any[], currentEmployee: any) {
  if (enums === null || enums === undefined) {
    // If enums is null or undefined, return true
    return true;
  } else {
    // If enums is not null, find if currentEmployee exists in the enums array
    return (
      enums.find(
        (option) =>
          option.value?.toLowerCase() === currentEmployee.toLowerCase()
      ) !== undefined
    );
  }
}

export const getDelegationFormControls = ({
  t,
  employees,
  userSubmit,
  disable,
}: any) => {
  const controls = [
    {
      ...EmployeeCodeField,
      enum: ToUIOption(employees, true),
      config: {
        ...EmployeeCodeField.config,
        readOnly: !!userSubmit || disable,
        disabled: !!userSubmit || disable,
      },
    },
    {
      ...UserSubmitField,
      config: {
        readOnly: disable,
        disabled: disable,
      },
    },
  ];

  return controls.map((control) => ({
    ...control,
    label: t(control.label),
    placeholder: t(control.placeholder),
  }));
};

export const getDelegationFormLayout = () => ({
  rows: [
    {
      columns: [
        createColumn(EmployeeCodeField.key),
        createColumn(UserSubmitField.key, {
          className: `${BASIC_CONTROL_LAYOUT_CONFIG?.className} checkbox-custom`,
        }),
      ],
    },
  ],
});

export const getHeaderFormControls = ({ t, claimTypes }: any) => {
  const controls = [
    {
      ...ClaimTypesField,
      enum: ToUIOption(claimTypes),
    },
  ];

  return controls.map((control) => ({
    ...control,
    label: t(control.label),
    placeholder: t(control.placeholder),
  }));
};

export const getHeaderFormLayout = () => ({
  rows: [
    {
      columns: [createColumn(ClaimTypesField.key)],
    },
  ],
});

export const getDetailFormLayout = () => ({
  rows: [
    {
      columns: [
        createColumn(FromDateField.key, {
          ...BASIC_CONTROL_LAYOUT_CONFIG_HALF_COLUMN,
        }),
        createColumn(ToDateField.key, {
          ...BASIC_CONTROL_LAYOUT_CONFIG_HALF_COLUMN,
        }),
        createColumn(DisplayDateTypeField.key, {
          ...BASIC_CONTROL_LAYOUT_CONFIG_HALF_COLUMN,
        }),
      ],
    },
    {
      columns: [
        createColumn(FromTimeField.key, {
          ...BASIC_CONTROL_LAYOUT_CONFIG_HALF_COLUMN,
        }),
        createColumn(ToTimeField.key, {
          ...BASIC_CONTROL_LAYOUT_CONFIG_HALF_COLUMN,
        }),
        createColumn(UnitField.key, {
          ...BASIC_CONTROL_LAYOUT_CONFIG_HALF_COLUMN,
        }),
        createColumn(DisplayCompLeaveField.key, {
          ...BASIC_CONTROL_LAYOUT_CONFIG_HALF_COLUMN,
        }),
        createColumn(AmountField.key),
      ],
    },
  ],
});

export const getDetailForm = ({
  t,
  claimTypeInfo,
  onTimeChange,
  calculateFields,
  fromTime,
  interval,
}: {
  t: any;
  claimTypeInfo: ClaimTypeSettings;
  onTimeChange: Function;
  calculateFields: any;
  fromTime: any;
  interval: any;
}) => {
  const { controls: additionalControls, layout: additionalLayout } =
    getAdditionalForm({
      t,
      claimTypeInfo,
      calculateFields,
    });

  const controls = getDetailFormControls({
    t,
    claimTypeInfo,
    onTimeChange,
    fromTime,
    interval,
  });
  const layout = getDetailFormLayout();

  layout.rows.push(...additionalLayout.rows);

  return {
    controls: [...controls, ...additionalControls],
    layout,
  };
};

const getDetailFormControls = ({
  t,
  claimTypeInfo,
  onTimeChange,
  fromTime,
  interval,
}: {
  t: any;
  claimTypeInfo: ClaimTypeSettings;
  onTimeChange: Function;
  fromTime: any;
  interval: any;
}) => {
  const unitField = {
    ...UnitField,
    config: {
      ...UnitField.config,
      suffix: ` ${GetDisplayUom(claimTypeInfo?.uom, t)}`,
    },
  };
  const fromTimeField = {
    ...FromTimeField,
    config: {
      ...FromTimeField.config,
      onBlur: onTimeChange,
      stepMinute: interval,
    },
  };
  const toTimeField = {
    ...ToTimeField,
    config: {
      ...ToTimeField.config,
      onBlur: onTimeChange,
      viewDate: moment(fromTime).add(1, "h").toDate(),
      stepMinute: interval,
    },
  };

  const controls: IFormField[] = [
    DisplayControl(FromDateField, claimTypeInfo?.transDateDisplayMode),
    DisplayControl(DisplayDateTypeField, claimTypeInfo?.dateTypeDisplayMode),
    DisplayControl(fromTimeField, claimTypeInfo?.transTimeDisplayMode),
    DisplayControl(ToDateField, claimTypeInfo?.transDateDisplayMode),
    DisplayControl(toTimeField, claimTypeInfo?.transTimeDisplayMode),
    DisplayControl(unitField, claimTypeInfo?.appliedUnitDisplayMode),
    DisplayControl(
      DisplayCompLeaveField,
      claimTypeInfo?.compLeaveUnitDisplayMode
    ),
    DisplayControl(AmountField, claimTypeInfo?.appliedAmountDisplayMode),
  ];

  return controls
    .filter((control) => !!control)
    .map((control) => ({
      ...control,
      label: t(control.label),
      placeholder: t(control.placeholder),
    }));
};

const getAdditionalFormLayout = (controlGroups: any[]) => {
  return {
    rows: controlGroups
      .filter((group) => group?.length > 0)
      .map((group) => ({
        columns: group?.map((control: any) =>
          createColumn(control.key, {
            ...BASIC_CONTROL_LAYOUT_CONFIG_FULL_COLUMN,
          })
        ),
      })),
  };
};

const getAdditionalForm = ({
  t,
  claimTypeInfo,
  calculateFields,
}: {
  t: any;
  claimTypeInfo: ClaimTypeSettings;
  calculateFields: any;
}) => {
  const mealControls = convertToCheckboxControl({
    config: {
      analysisCode: "meal",
      description: t("claims_form_mealAllowance"),
    },
    t,
    controlConfig: {
      required: false,
      config: {
        readOnly: !EnableControl(claimTypeInfo.mealAllowanceDisplayMode),
        disabled: !EnableControl(claimTypeInfo.mealAllowanceDisplayMode),
      },
    },
  });

  const compLeaveControls = convertToCheckboxControl({
    config: {
      analysisCode: "isDayInLieuPayment",
      description: t("claims_form_compLeave"),
    },
    t,
    controlConfig: {
      required: false,
      config: {
        readOnly: !EnableControl(claimTypeInfo.compLeaveDisplayMode),
        disabled: !EnableControl(claimTypeInfo.compLeaveDisplayMode),
      },
    },
  });

  const transportControls = convertToNumberControl({
    config: {
      analysisCode: "transport",
      description: t("claims_form_transportFare"),
    },
    t,
    controlConfig: {
      required: false,
      config: {
        readOnly:
          !EnableControl(claimTypeInfo.transportFareDisplayMode) ||
          !calculateFields.otTransportFare,
        disabled:
          !EnableControl(claimTypeInfo.transportFareDisplayMode) ||
          !calculateFields.otTransportFare,
        showButtons: false,
        tooltip: null,
        mode: "decimal",
        minFractionDigits: 1,
        maxFractionDigits: 2,
      },
    },
  });

  const requestedByControls = convertToTextBoxControl({
    config: {
      analysisCode: "requestedBy",
      description: t("claims_form_requestedBy"),
    } as any,
    t,
    controlConfig: {
      required: false,
      config: {
        readOnly: !EnableControl(claimTypeInfo.requestedByDisplayMode),
        disabled: !EnableControl(claimTypeInfo.requestedByDisplayMode),
      },
    },
  });

  const mutualAgreedControls = convertToCheckboxControl({
    config: {
      analysisCode: "mutualAgreed",
      description: t("claims_form_mutualAgreed"),
    } as any,
    t,
    controlConfig: {
      required: false,
      config: {
        readOnly: !EnableControl(claimTypeInfo.mutualAgreedDisplayMode),
        disabled: !EnableControl(claimTypeInfo.mutualAgreedDisplayMode),
      },
    },
  });

  const analysisControls = claimTypeInfo?.analysisCodes?.map((code: any) =>
    convertToControl({ config: code, t })
  );

  const reminderControls = convertToNoteControl(claimTypeInfo?.reminder);

  const attachmentControls = claimTypeInfo?.attachmentInfos?.map(
    (config: any) =>
      convertToAttachmentControl({
        t,
        config,
        controlConfig: {
          required: config.required,
        },
      })
  );

  const controls: IFormField[] = [
    DisplayControl(mealControls, claimTypeInfo?.mealAllowanceDisplayMode),
    DisplayControl(transportControls, claimTypeInfo?.transportFareDisplayMode),
    DisplayControl(compLeaveControls, claimTypeInfo?.compLeaveDisplayMode),
    DisplayControl(requestedByControls, claimTypeInfo?.requestedByDisplayMode),
    DisplayControl(
      mutualAgreedControls,
      claimTypeInfo?.mutualAgreedDisplayMode
    ),
    ...(analysisControls ?? []),
    RemarksField,
    reminderControls,
    ...(attachmentControls ?? []),
  ]
    .filter((control) => !!control)
    .map((control) => ({
      ...control,
      label: t(control.label),
      placeholder: t(control.placeholder),
    }));

  const layout = getAdditionalFormLayout([
    [mealControls],
    [transportControls],
    [compLeaveControls],
    [requestedByControls],
    [mutualAgreedControls],
    analysisControls,
    [RemarksField],
    [reminderControls],
    attachmentControls,
  ]);

  return { controls, layout };
};
