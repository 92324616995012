import moment from "moment";
import {
  DATETIME_FORMAT,
  INPUT_TIME_FORMAT,
  SHORT_DATE_FORMAT,
} from "../constants";
import { AnalysisCode } from "./analysis-code-helper";
import { ClaimRequestModel } from "../models/claim-request-model";
import { FORM_CONTROL_DISPLAY_MODE } from "../constants/layout";

export const ToFormsRequest = (forms: any[]) => {
  return forms.map((form: any) => ToFormRequest(form));
};

export const ToFormRequest = (form: any) => {
  const analysisCodeData: any[] = [];
  form.analysisCodes?.forEach((code: AnalysisCode) => {
    if (!form.code) {
      return;
    }

    if (form[code.analysisCode]) {
      let value = form[code.analysisCode];
      if (value instanceof Date) {
        value = moment(value).format(DATETIME_FORMAT);
      }

      const jsonValue: any = {
        analysisCodeId: code.id,
        analysisCodeValue: [{ value }],
      };
      analysisCodeData.push(jsonValue);
    }
  });

  const attachmentType = form.transport
    ? "MISC"
    : form.attachmentInfos?.length > 0
    ? form.attachmentInfos[0].typeCode
    : null;

  const request: ClaimRequestModel = {
    claimType: form.claimType,
    employeeCode: form.employeeCode,
    fromDate: moment(form.fromDate)?.format(SHORT_DATE_FORMAT),
    toDate: moment(form.toDate ?? form.fromDate)?.format(SHORT_DATE_FORMAT),
    fromTime: moment(form.fromTime)?.format(INPUT_TIME_FORMAT),
    toTime: moment(form.toTime)?.format(INPUT_TIME_FORMAT),
    remarks: form.remarks,
    appliedUnits: form.units,
    appliedAmount: form.amounts,
    transportFare: form.transport,
    mealAllowanceUnits: form.meal ? form.otMealUnits : null,
    compLeaveUnits: form.compLeave ? form.otCompLeaveUnits : null,
    requestedBy:
      form.otRequestedBy?.toLowerCase() !== FORM_CONTROL_DISPLAY_MODE.NA
        ? form.requestedBy
        : null,
    mutualAgreed:
      form.otMutualAgreed?.toLowerCase() !== FORM_CONTROL_DISPLAY_MODE.NA
        ? form.mutualAgreed
        : null,
    analysisCodeData: JSON.stringify(analysisCodeData),
    attachmentType: attachmentType,
    pendingAttachments: attachmentType ? form[attachmentType] : [],
    attachmentIds: attachmentType
      ? form[attachmentType]
          ?.filter((attachment: any) => !!attachment.id)
          ?.map((attachment: any) => attachment.id)
      : [],
  };
  return request;
};
