import { ITimesheetDetailActivity } from "../reducer/application";
import { IActivityModalDetails } from "../reducer/modal";

export enum ModalAction {
  SET_IS_SHOW_ACTIVITY_MODAL = "DISPLAY_ACTIVITY_MODAL",
  SET_ACTIVITY_MODAL_DETAILS = "SET_ACTIVITY_MODAL_DETAILS",
  RESET_ACTIVITY_MODAL = "RESET_ACTIVITY_MODAL",
}

export type ModalPayload = {
  [ModalAction.SET_IS_SHOW_ACTIVITY_MODAL]: boolean;
  [ModalAction.SET_ACTIVITY_MODAL_DETAILS]: {
    activityModalDetails: IActivityModalDetails;
  };
  [ModalAction.RESET_ACTIVITY_MODAL]: undefined;
};
