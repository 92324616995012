import { StorageKey, StorageSerive } from "./../storageService/index";

import { isResponseOk, makeId } from "./../../utils/utils";

import { clearSideBar } from "./../../redux/actions/sidebar";
import { setCountdownTimer } from "./../../redux/actions/countdown-timer";
import { closeModal } from "./../../redux/actions/modal";
import { clearRedux } from "./../../redux/actions/clear-redux";
import {
  clearLoadedContext,
  setUserSelectedLanguage,
} from "./../../redux/actions/language";

//const
import { ERROR_PAGE, LOGIN_PATH, QUERY_PARAMS } from "./../../constants/index";
import { STORAGE_KEY } from "./../../constants/index";
import {
  HRIS_Api_DocumentType,
  DocumentsService,
  AuthenticationService,
} from "../hrmnet-api";
import { store } from "../../redux/store";
import { useLocation } from "react-router-dom";
import React from "react";

export class ApiRequest {
  static createRay(batchName: string = "defaultBatch", id: string = makeId()) {
    return `${batchName}_${Math.floor(Date.now() / 1000)}_${id}`;
  }

  static async createParam() {
    let result = {
      parameterLocale: "en",
      parameterClientId: "",
      parameterEntityId: "",
      parameterGenericCodeList: [],
    };
    let tennantInfo = await StorageSerive.getItemJSON(StorageKey.TENNANT_INFO);
    let userInfo = await StorageSerive.getItemJSON(StorageKey.USER_INFO);
    if (tennantInfo) {
      result.parameterClientId = tennantInfo.data?.client?.clientId || 0;
      result.parameterEntityId = tennantInfo.data?.entity?.entityId || 0;
    }
    if (userInfo) {
      result.parameterLocale = userInfo.primaryLang || "en";
    }
    return result;
  }
  static async createParamBody() {
    let result = {
      locale: "en",
      clientId: 0,
      entityId: 0,
      genericCodeList: [],
    };
    let tennantInfo = await StorageSerive.getItemJSON(StorageKey.TENNANT_INFO);
    let userInfo = await StorageSerive.getItemJSON(StorageKey.USER_INFO);
    if (tennantInfo) {
      result.clientId = tennantInfo.data?.client?.clientId || 0;
      result.entityId = tennantInfo.data?.entity?.entityId || 0;
    }
    if (userInfo) {
      result.locale = userInfo.primaryLang || "en";
    }
    return result;
  }
}
/**
 * Get validate array
 * @param res
 */
export const getValidateResultItem = (
  validateResult: any[],
  index: number,
  prefix: string
) => {
  let result: any[] = [];
  validateResult.forEach((element) => {
    if (element.componentId.includes(`${prefix}[${index}]_`)) {
      let newName = element.componentId.replace(`${prefix}[${index}]_`, "");
      result.push({
        ...element,
        componentId: newName,
      });
    }
  });
  return result;
};

/**
 * Perform logout
 * @param clientInfo
 * @param history
 * @param dispath
 */
export const logout = async (
  clientInfo: any,
  history: any,
  newClientUrl?: string
) => {
  let url = newClientUrl ?? LoginLink(clientInfo);
  localStorage.removeItem(STORAGE_KEY.ROOT);
  localStorage.removeItem(STORAGE_KEY.IS_OKTA_LOGIN);
  sessionStorage.clear();
  store.dispatch(clearLoadedContext());
  store.dispatch(clearSideBar());
  store.dispatch(setCountdownTimer({ isCountingDown: false }));
  store.dispatch(closeModal());
  store.dispatch(clearRedux());
  // history.push(url);
  // Handle if user clear browser data scenario
  // persist:global is missing
  var pglobal = localStorage.getItem(STORAGE_KEY.GLOBAL);
  if (pglobal) {
    // console.log('persist:global still there - use history.push');
    history.push(url);
  } else {
    // need to force refresh the page to regenerate persist:global
    window.location.href = url;
  }
};

/**
 * Perform logout
 * @param clientInfo
 * @param history
 * @param dispath
 * @param currLang
 */
export const OnLogout = async (
  clientInfo: any,
  history: any = null,
  currLang: string = ""
) => {
  if (!!store.getState()?.auth?.token) {
    await AuthenticationService.authenticationLogout();
  }
  store.dispatch(clearLoadedContext());
  store.dispatch(clearRedux());

  await StorageSerive.removeToken();
  await StorageSerive.removeItem(StorageKey.USER_INFO);
  await StorageSerive.removeItem(StorageKey.TENNANT_INFO);
  sessionStorage.clear();
  if (currLang) {
    store.dispatch(setUserSelectedLanguage(currLang));
  }
  if (clientInfo && history) {
    const url = LoginLink(clientInfo);
    if (url !== window.location.pathname) {
      history.push(url);
    }
  }
};

export const EscapeRegexChar = (char: string) => {
  if (char.length === 0) {
    return "";
  }

  const escapedChar = char.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&");
  return escapedChar;
};

export const generateRule = (
  currentRule: string,
  translation: string,
  ruleParam: any,
  negate: boolean
) => {
  let _rule = {
    message: { translation: translation, param: ruleParam },
    param: currentRule,
    negate: negate,
  };
  return _rule;
};

export const FormatPasswordPolicy = async (data: any, dispatch: any) => {
  const _defaultPolicy = {
    charset: ".",
    letterRequired: true,
    lowerCaseLetterRequired: true,
    maxLength: -1,
    minLength: -1,
    numberRequired: true,
    punctuationRequired: false,
    upperCaseLetterRequired: false,
  };

  const defaultPolicy = { ..._defaultPolicy, ...data };
  let rule = [];
  let param = "";
  let maxLength = 20;
  let _param;

  //Letter Required
  if (defaultPolicy.letterRequired) {
    if (
      !defaultPolicy.lowerCaseLetterRequired &&
      !defaultPolicy.upperCaseLetterRequired
    ) {
      _param = "A-Za-z";
      param = `${param}(?=.*[${_param}])`;
      rule.push(
        generateRule("(.*[A-Za-z].*)", "passwordPolicy_oneLetter", {}, false)
      );
    }
  }

  //Lowercase Letter Required
  if (defaultPolicy.lowerCaseLetterRequired) {
    _param = "a-z";
    param = `${param}(?=.*[${_param}])`;
    rule.push(
      generateRule(
        "(.*[a-z].*)",
        "passwordPolicy_oneLowercaseLetter",
        {},
        false
      )
    );
  }

  //Uppercase Letter Required
  if (defaultPolicy.upperCaseLetterRequired) {
    _param = "A-Z";
    param = `${param}(?=.*[${_param}])`;
    rule.push(
      generateRule(
        "(.*[A-Z].*)",
        "passwordPolicy_oneUppercaseLetter",
        {},
        false
      )
    );
  }

  //Number Required
  if (defaultPolicy.numberRequired) {
    _param = "0-9";
    param = `${param}(?=.*[${_param}])`;
    rule.push(
      generateRule("(.*[0-9].*)", "passwordPolicy_oneNumber", {}, false)
    );
  }

  //Charset && Punctuation Required
  /**
   * Punction Required
   * When punction is required,
   * check passwordPunctuations (assumption: should be in sync with charset)
   * if passwordPunctuations' length == 0, punction required restriction will be turned off
   */
  if (
    defaultPolicy.punctuationRequired &&
    defaultPolicy.passwordPunctuations.length > 0
  ) {
    //passwordPunctuations is empty or not
    //if not empty - meaning special char entered are limited
    param = `${param}(?=.*[${
      defaultPolicy.passwordPunctuations
    }])[${EscapeRegexChar(defaultPolicy.charset)}]`;

    rule.push(
      generateRule(
        "(.*[" + defaultPolicy.passwordPunctuations + "].*)",
        "passwordPolicy_anyOnePunctuation",
        { 0: ":" + defaultPolicy.passwordPunctuations },
        false
      )
    );
  } else {
    //turn off Punctuation Required restriction

    rule.push(
      generateRule(
        "(.*[^A-Za-z0-9].*)",
        "passwordPolicy_noPunctuation",
        {},
        true
      )
    );
    param = `${param}[${EscapeRegexChar(defaultPolicy.charset)}]`;
  }

  //maxLength && minLength
  if (defaultPolicy.maxLength > 0 || defaultPolicy.minLength > 0) {
    let _param = `0,`;
    if (defaultPolicy.minLength > 0) {
      _param = `${defaultPolicy.minLength},`;
      let currParam = `^.{${defaultPolicy.minLength},}$`;
      rule.push(
        generateRule(
          currParam,
          "passwordPolicy_atLeastCharacters",
          { minLength: defaultPolicy.minLength },
          false
        )
      );
    }
    if (defaultPolicy.maxLength > 0) {
      maxLength = defaultPolicy.maxLength;
      _param = `${_param}${defaultPolicy.maxLength}`;
    }

    param = `${param}{${_param}}`;
  } else {
    let _param = `0,`;
    param = `${param}{${_param}}`;
  }

  //apply regex from beginning to last character
  param = `^${param}$`;
  const passwordPolicy = {
    passwordPolicyPattern: param,
    rule: rule,
    maxLength: maxLength,
    charset:
      defaultPolicy.passwordPunctuations.length > 0
        ? defaultPolicy.charset
        : "",
  };

  return passwordPolicy;
};

export const LoginLink = (clientInfo: any, loginUrl?: string) => {
  const url = loginUrl ?? LOGIN_PATH.LOGIN;
  const urlParams = new URLSearchParams(window.location.search).get(
    QUERY_PARAMS.TYPE
  );
  const deviceType = store.getState().global.deviceType ?? urlParams;
  const isWeb = !deviceType || deviceType === QUERY_PARAMS.WEB;

  //for first-login, reset-password, forgot-password, passsword-expired
  if (
    Object.values(LOGIN_PATH).find(
      (x) => x === window.location.pathname.split("/")[1]
    ) &&
    !clientInfo
  ) {
    clientInfo = {
      tenantCode: window.location.pathname.split("/")[2],
      entityCode: window.location.pathname.split("/")[3],
    };
  }

  if (clientInfo && !!clientInfo.tenantCode) {
    if (!!clientInfo.entityCode) {
      return isWeb
        ? `/${url}/${clientInfo?.tenantCode}/${clientInfo?.entityCode}`
        : `/${url}/${clientInfo?.tenantCode}/${clientInfo?.entityCode}?type=${deviceType}`;
    }
    return isWeb
      ? `/${url}/${clientInfo?.tenantCode}`
      : `/${url}/${clientInfo?.tenantCode}?type=${deviceType}`;
  }

  return `/`;
};

export const PortalSystemErrorLink = () => {
  return `/${ERROR_PAGE.ERROR_500}`;
};

export const PortalLink = (url: string) => {
  const portalBaseUrl = "/";
  if (!url) {
    return portalBaseUrl;
  }
  return `${portalBaseUrl}${url}`;
};

export const IsNotNullOrDefined = (data: any) => {
  if (data !== null && data !== undefined) {
    return true;
  }
  return false;
};

/**
 * Given an list of mixed existing files and new files in blob, upload new files and return all files with uploadId
 * @param files
 * @returns Promise
 */
export async function handleUploadFiles(
  files: [],
  docType: string = HRIS_Api_DocumentType.RentalReimbursement
) {
  if (!files?.length) {
    return [];
  }
  let existingFiles: any = [];
  let actualFiles: any = [];
  files.forEach((f: any) => {
    // if id => uploaded file
    if (f.uploadId == null) {
      actualFiles.push(f);
    } else {
      existingFiles.push({
        fileId: f.id,
        fileName: f.name,
        fileSize: f.size,
        uploadId: f.uploadId,
      });
    }
  });
  // upload new file
  let newUploadedFiles = [];
  if (actualFiles.length > 0) {
    const res = await DocumentsService.documentUploadFiles({
      message: "",
      files: actualFiles,
      releaseOnDate: new Date().toISOString(),
      documentType: docType,
    });
    if (!isResponseOk(res)) throw new Error("Upload Files Failed");
    newUploadedFiles = res.data.files.map((f: any) => ({
      ...f,
      uploadId: res.data.uploadId,
    }));
  }
  return [...existingFiles, ...newUploadedFiles];
}

export function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}
