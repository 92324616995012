import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import LanguageLoader from "src/components/language-loader/language-loader";
import {
  EEPORTAL_LABEL_BASE_TABLE,
  CLAIM_SUBMODULE_CODE,
  FUNCTION_CODE,
} from "src/constants";
import ClaimService from "src/services/hrmnet-api/claims";
import ClaimCalendar from "../../components/claim-calendar";
import { HeaderMenu } from "../../components/header-menu";
import {
  CLAIMS_MENU_MODULE_KEY,
  CLAIMS_MENU_PAGE_KEY,
  CLAIMS_T_CONTEXT_KEY,
  DATE_TYPE,
  DATE_TYPE_CONFIG,
  SHORT_DATE_FORMAT,
} from "../../constants";

import BaseForm from "src/components/base-form/base-form";
import { CalendarViewSkeleton } from "../../components";
import { GetMonthRange } from "../../utils/date-helper";
import { InitForm, getAllControls } from "../../utils/form-view-helper";
import { InputText } from "primereact/inputtext";
import { openModal } from "src/redux/actions/modal";
import { useDispatch } from "react-redux";
import { showSpinner } from "src/redux/actions/spinner";
import { isResponseOk } from "src/utils/utils";
import { showTimestampToastSuccess } from "src/services/utils/message";
import { ClaimsService } from "src/services/hrmnet-api";
import { PortalLink } from "src/services/utils";
import {
  EmployeeField,
  IFormField,
  RefNoField,
  StatusField,
} from "./form-field-config";
import "./view.scss";
import moment from "moment";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { TabMenu } from "primereact/tabmenu";
import { Button } from "primereact/button";

const View = (props: any) => {
  const { id } = props?.match?.params;
  const { aid } = props?.match?.params;
  const employeeCode = props?.employeeCode;

  const [state, setState] = useState<any>({
    isLoading: true,
    dateConfig: {},
    showWorkflow: false,
    isLoadingWorkflow: false,
    applicationWorkflow: null,
    applicationWorkflowInfo: null,
    workflowExpandedRows: null,
    isLoadingWorkflowExpansion: false,
    requestedApplicationId: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const [application, setApplication] = useState<any>(null);
  const [dates, setDates] = useState<any>({});
  const [claimTypeInfo, setClaimTypeInfo] = useState<any>({});

  const getClaimTypeInfo = async (claimType: string) => {
    const response = (await ClaimService.getClaimTypeInfo(claimType)) as any;

    setClaimTypeInfo(response?.data);
  };

  // const Main = ({ application }: any) => {
  //   const [isLoading, setIsLoading] = useState(false);
  //   // const [application, setApplication] = useState<any>(null);
  //   const [dates, setDates] = useState<any>({});

  useEffect(() => {
    setIsLoading(true);
    (async () => {
      const application =
        id > 0
          ? await getApplicationAsync(id)
          : await getApprovalApplication(aid);

      if (application) {
        setApplication(application);
        await getClaimTypeInfo(application.claimType);

        const { startDate, endDate } = GetMonthRange(application.from);
        const response = (await ClaimService.getDates({
          employeeCode: application.employeeCode,
          startDate: startDate.format(SHORT_DATE_FORMAT),
          endDate: endDate.format(SHORT_DATE_FORMAT),
        })) as any;

        const dates = response.data?.reduce((acc: any, item: any) => {
          const day = new Date(item.targetDate).getDate();
          acc[day] = { ...DATE_TYPE_CONFIG[item.dateType] };
          if (
            item.dateType === DATE_TYPE.HOLIDAY ||
            item.dateType === DATE_TYPE.STATUTORY
          ) {
            acc[day].holidayName = item.holidayName;
          }
          return acc;
        }, {});
        setDates(dates);
      }
    })().then(() => setIsLoading(false));
  }, [id]);

  const Main = ({ id }: any) => {
    if (isLoading || !application) {
      return (
        <div className="main">
          <CalendarViewSkeleton />
        </div>
      );
    }

    if (application) {
      return (
        <div className="main">
          <div className="main-l">
            <div className="main-l-container">
              <div className="claim-calendar">
                <ClaimCalendar
                  inline
                  value={[new Date(application.from), new Date(application.to)]}
                  dateConfig={dates}
                  viewDate={new Date(application.from)}
                  // onChange={(e) => onSelectDate(e.value)}
                  // onViewDateChange={onChangeViewDate}
                  className="hide-arrow"
                  monthNavigator={false}
                  yearNavigator={false}
                  yearRange={`${new Date().getFullYear() - 10}:${
                    new Date().getFullYear() + 1
                  }`}
                  selectionMode="range"
                />
              </div>
              {/* {renderFilter()}
                  {renderClaimTypeLegend()}
                  {renderDateLegend()} */}
            </div>
          </div>
          <div className="main-r">
            <ApplicationMeta application={application} />
            <ApplicationDetail
              application={application}
              claimTypeInfo={claimTypeInfo}
            />
          </div>
          <ApplicationWorkflow />
        </div>
      );
    } else {
      return null;
    }
  };

  const getApplication = async (id: string) => {
    const response = (await ClaimService.getApplicationDetailbyId(id)) as any;
    return response?.data;
  };

  const getApplicationAsync = async (id: string) => {
    const response = (await ClaimService.getApplicationAsync(id)) as any;
    return response?.data;
  };

  const getApprovalApplication = async (aid: string) => {
    const response = (await ClaimService.getApprovalApplication(aid)) as any;
    return response?.data;
  };

  const getApplicationWorkflow = async (refNo: string) => {
    if (state.isLoadingWorkflow) return;
    setState((state: any) => ({
      ...state,
      showWorkflow: true,
      isLoadingWorkflow: true,
    }));

    if (!state.applicationWorkflow) {
      let tranRes = (await ClaimService.getApplicationHeaderbyRefNo(refNo, {
        employeeCode,
      })) as any;
      if (tranRes && tranRes.data) {
        setState((state: any) => ({
          ...state,
          applicationWorkflow: tranRes.data,
        }));
      }
    }

    setState((state: any) => ({
      ...state,
      isLoading: false,
      isLoadingWorkflow: false,
    }));

    // scroll to section
    let element = document.querySelector(".claim-workflow");
    element?.scrollIntoView({ behavior: "smooth", block: "center" });
  };

  const getWorkflowRowExpansionData = async (appId: any) => {
    let tranDetailApi = ClaimService.getApplicationDetailbyId(appId, {
      employeeCode: employeeCode,
    });
    let tranWorkflowApi = ClaimService.getApplicationWorkflowbyId(appId, {
      employeeCode: employeeCode,
    });
    let [tranDetailRes, tranWorkflowRes]: any = await Promise.all([
      tranDetailApi,
      tranWorkflowApi,
    ]);

    let tranData: any = {
      displayTab: 0,
    };

    if (
      tranDetailRes &&
      tranDetailRes.data &&
      tranWorkflowRes &&
      tranWorkflowRes.data
    ) {
      tranData.detail = tranDetailRes.data;
      tranData.content = tranWorkflowRes.data;
    }

    let workflowDetailData = { ...state.applicationWorkflowInfo };
    workflowDetailData[appId] = tranData;

    setState((state: any) => ({
      ...state,
      applicationWorkflowInfo: workflowDetailData,
    }));
  };

  useEffect(() => {
    setState((state: any) => ({
      ...state,
      isLoadingWorkflowExpansion: false,
    }));
  }, [state.applicationWorkflowInfo]);

  const ApplicationMeta = ({ application }: any) => {
    const { t } = useTranslation();
    const formRef = useRef();

    const isAllowApproveReject = application?.isAllowApproveReject;

    if (!application) {
      return <></>;
    }

    const controls = [
      EmployeeField,
      RefNoField,
      {
        ...StatusField,
        componentRender: () => (
          <>
            <span className="p-ml-1">{application.status}</span>
            {!isAllowApproveReject && (
              <span
                className="claim-workflow-btn"
                onClick={() => getApplicationWorkflow(application.referenceNo)}
              >
                ({t("claims_calendarView_workflow_title")})
              </span>
            )}
          </>
        ),
      },
    ].map((field: IFormField) => ({
      ...field,
      label: t(field.label),
      placeholder: field.placeholder ? t(field.placeholder) : "",
    }));

    const formConfg = {
      config: { controls },
      form: InitForm(application),
      readOnly: true,
    };

    return (
      <div className="claim-application-meta">
        <BaseForm ref={formRef} {...formConfg}></BaseForm>
      </div>
    );
  };

  const ApplicationDetail = ({ application, claimTypeInfo }: any) => {
    const { t } = useTranslation();
    const formRef = useRef();

    // let claimTypes = [application];

    if (!application) {
      return <></>;
    }

    if (application) {
      const controls = getAllControls({
        claimTypeDropdownTemplate: [],
        claimTypeInfo,
        application,
        claimTypeReadOnly: true,
        t,
      }).map((field: IFormField) => ({
        ...field,
        label: field?.label ? t(field.label) : "",
        placeholder: field?.placeholder ? t(field.placeholder) : "",
      }));

      const formConfg = {
        config: { controls },
        form: InitForm(application),
      };

      return (
        <div className="claim-application-detail">
          <BaseForm ref={formRef} {...formConfg}></BaseForm>
        </div>
      );
    } else {
      return <></>;
    }
  };

  const ApplicationWorkflow = () => {
    const { t } = useTranslation();
    if (state.isLoading || state.isLoadingWorkflow || !state.showWorkflow) {
      return null;
    }

    const renderclaimWorkflowColumn = (columns: any) =>
      columns.map((column: any) => {
        const columnConfig = {
          field: column.key,
          header: column.header,
          style: column.style,
          body: (data: any) => (
            <>
              <div className="p-column-title">{column.header}: </div>
              <div className="p-column-value">
                {column.key === "from" || column.key === "to"
                  ? moment(data[column.key]).format("YYYY-MM-DD h:mm A (ddd)")
                  : column.key === "submissionDate" ||
                    column.key === "lastUpdated" ||
                    column.key === "activityUpdated"
                  ? moment(data[column.key]).format("YYYY-MM-DD HH:mm")
                  : data[column.key]}
              </div>
            </>
          ),
        };
        return <Column {...columnConfig} />;
      });

    const claimWorkflowHeaderColumnConfig = [
      {
        key: "id",
        header: "ID",
      },
      {
        key: "submissionDate",
        header: t("claims_calendarView_workflow_applicationDate"),
      },
      {
        key: "transactionReferenceNo",
        header: t("claims_calendarView_workflow_transactionSequence"),
      },
      {
        key: "transactionType",
        header: t("claims_calendarView_workflow_transactionType"),
      },
      {
        key: "from",
        header: t("claims_calendarView_workflow_startDate"),
      },
      {
        key: "to",
        header: t("claims_calendarView_workflow_endDate"),
      },
      {
        key: "appliedUnits",
        header: t("claims_calendarView_workflow_appliedUnitsInPeriod"),
      },
      {
        key: "approvedUnits",
        header: t("claims_calendarView_workflow_approvedUnitsInPeriod"),
      },
      {
        key: "status",
        header: t("claims_calendarView_workflow_status"),
      },
      {
        key: "workflowStatus",
        header: t("claims_calendarView_workflow_workflowStatus"),
      },
      {
        key: "lastUpdated",
        header: t("claims_calendarView_workflow_approvalRejectDate"),
        style: {
          wordWrap: "break-word",
        },
      },
    ];
    const claimWorkflowDetailColumnConfig = [
      {
        key: "year",
        header: t("claims_calendarView_workflow_year"),
        style: { width: "6rem" },
      },
      {
        key: "period",
        header: t("claims_calendarView_workflow_period"),
        style: { width: "6rem" },
      },
      {
        key: "periodDescription",
        header: t("claims_calendarView_workflow_periodDescription"),
      },
      {
        key: "transDateFrom",
        header: t("claims_calendarView_workflow_startDate"),
      },
      {
        key: "transDateTo",
        header: t("claims_calendarView_workflow_endDate"),
      },
      {
        key: "appliedUnits",
        header: t("claims_calendarView_workflow_appliedUnitsInPeriod"),
      },
      {
        key: "approvedUnits",
        header: t("claims_calendarView_workflow_approvedUnitsInPeriod"),
      },
    ];
    const claimWorkflowContentColumnConfig = [
      {
        key: "activityId",
        header: t("claims_calendarView_workflow_activity"),
        style: { width: "6rem" },
      },
      {
        key: "activityUpdated",
        header: t("claims_calendarView_workflow_updatedOn"),
        style: { width: "10rem" },
      },
      {
        key: "activityDescription",
        header: t("claims_calendarView_workflow_description"),
      },
      {
        key: "userName",
        header: t("claims_calendarView_workflow_employeeName"),
      },
      {
        key: "workflowActivityStatus",
        header: t("claims_calendarView_workflow_status"),
        style: { width: "8rem" },
      },
      {
        key: "comments",
        header: t("claims_calendarView_workflow_comments"),
      },
    ];

    const renderclaimWorkflowHeaderColumn = renderclaimWorkflowColumn(
      claimWorkflowHeaderColumnConfig
    );
    renderclaimWorkflowHeaderColumn.unshift(
      <Column
        expander
        style={{ width: "3em" }}
        className="workflow-expander-start"
      />
    );
    renderclaimWorkflowHeaderColumn.push(
      <Column
        expander
        style={{ width: "3em" }}
        className="workflow-expander-end"
      />
    );
    const renderClaimWorkflowDetailColumn = renderclaimWorkflowColumn(
      claimWorkflowDetailColumnConfig
    );
    const renderClaimWorkflowContentColumn = renderclaimWorkflowColumn(
      claimWorkflowContentColumnConfig
    );
    // };

    const workflowTabMenu = [
      { label: t("claims_calendarView_workflow_breakdownByPeriods") },
      { label: t("claims_calendarView_workflow_title") },
    ];

    const workflowRowExpansion = (data: any) => {
      if (!state.requestedApplicationId.includes(data.id)) {
        const reqAppId = [...state.requestedApplicationId];
        reqAppId.push(data.id);
        setState((state: any) => ({
          ...state,
          requestedApplicationId: reqAppId,
          isLoadingWorkflowExpansion: true,
        }));
        getWorkflowRowExpansionData(data.id);
      }

      if (state.isLoadingWorkflowExpansion) {
        return <div>{t("claims_calendarView_workflow_loading")} ...</div>;
      } else if (!state.applicationWorkflowInfo) {
        return <div>{t("claims_calendarView_workflow_loading")} ...</div>;
      }

      const workflowRowExpansionData = state.applicationWorkflowInfo[data.id];

      return (
        <>
          <TabMenu
            model={workflowTabMenu}
            activeIndex={workflowRowExpansionData?.displayTab}
            className="claim-workflow-tabmenu"
            onTabChange={(e) => {
              let applicationWorkflowInfo = {
                ...state.applicationWorkflowInfo,
              };
              applicationWorkflowInfo[data.id].displayTab = e.index;
              setState((state: any) => ({
                ...state,
                applicationWorkflowInfo,
              }));
            }}
          />
          {workflowRowExpansionData?.displayTab === 0 ? (
            <DataTable
              value={workflowRowExpansionData.detail}
              emptyMessage={t("claims_calendarView_workflow_noDetailsFound")}
              className="claim-workflow-detail-table"
            >
              {renderClaimWorkflowDetailColumn}
            </DataTable>
          ) : null}
          {workflowRowExpansionData?.displayTab === 1 ? (
            <DataTable
              value={workflowRowExpansionData.content}
              emptyMessage={t("claims_calendarView_workflow_noDetailsFound")}
              className="claim-workflow-content-table"
            >
              {renderClaimWorkflowContentColumn}
            </DataTable>
          ) : null}
        </>
      );
    };

    return (
      <div className="claim-workflow">
        <div className="claim-workflow-title">
          {t("claims_calendarView_workflow_claimWorkflow")}
        </div>
        <DataTable
          value={state.applicationWorkflow}
          className="claim-workflow-header-table"
          expandedRows={state.workflowExpandedRows}
          onRowToggle={(e) => {
            setState((state: any) => ({
              ...state,
              workflowExpandedRows: e.data,
            }));
          }}
          rowExpansionTemplate={workflowRowExpansion}
        >
          {renderclaimWorkflowHeaderColumn}
        </DataTable>
      </div>
    );
  };

  const Header = () => {
    const { t } = useTranslation();

    return (
      <div className="header">
        <HeaderMenu
          menuKey={CLAIMS_MENU_MODULE_KEY}
          pageKey={CLAIMS_MENU_PAGE_KEY.VIEW_ALL_APPLICATIONS}
        />

        <div className="title">{t("claims_viewApplication_title")}</div>
      </div>
      //   ),
      //   classNameMainDialog: "confirm-message-modal",
      //   primaryButtonText: t("claims_common_actionConfirm"),
      //   primaryButtonClickFn: async ({ closeFn }: any) => {
      //     closeFn();
      //     submitClaimAction(
      //       type === "approve" ? "approve" : type === "reject" ? "reject" : ""
      //     );
      //   },
      //   secondButtonClickFn: ({ closeFn }: any) => {
      //     closeFn();
      //   },
      // })
    );
  };

  const Footer = ({ application }: any) => {
    const history = useHistory() as any;
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { state } = useLocation();

    const isAllowApproveReject = application?.isAllowApproveReject;

    const remarksRef = useRef();
    const updateRemarks = (value: any) => {
      remarksRef.current = value;
    };

    const returnToApprovalList = (history: any) => {
      history.push(
        PortalLink(`${FUNCTION_CODE.Claims}/${CLAIM_SUBMODULE_CODE.APPROVAL}`)
      );
    };

    // submit
    const submitClaimAction = async (action: any) => {
      const applicationIdList: Array<number> = [];
      applicationIdList.push(application.id);

      const body: any = {
        Id: applicationIdList,
        Remarks: remarksRef.current,
        Action: action,
      };

      try {
        //show spinner
        dispatch(showSpinner(true));

        const res = await ClaimsService.claimApprovalAction({
          body: body,
        });

        if (!isResponseOk(res)) throw new Error(`${action} claim failed`);

        returnToApprovalList(history);
        showTimestampToastSuccess({
          message:
            action === "approve"
              ? t("claims_approval_toast_approveSuccess")
              : action === "reject"
              ? t("claims_approval_toast_rejectSuccess")
              : "",
          t: t,
        } as any);
      } catch (e) {
        // show notification?
        console.error(e);
      } finally {
        // end spinner
        dispatch(showSpinner(false));
      }
    };

    const confirmAction = async (type: string) => {
      updateRemarks("");

      const claimFromString =
        application.from && moment(application.from).isValid()
          ? moment(application.from).format(SHORT_DATE_FORMAT)
          : application.from;

      dispatch(
        openModal({
          title: t("claims_approval_modal_title"),
          renderModalBody: () => (
            <div className="finalize-claim-modal">
              <div>
                {type === "approve"
                  ? t("claims_approval_modal_approveClaimContent", {
                      claimDays: claimFromString,
                    })
                  : null}
                {type === "reject"
                  ? t("claims_approval_modal_rejectClaimContent", {
                      claimDays: claimFromString,
                    })
                  : null}
              </div>
              <label>{t("claims_approval_modal_remarks")}</label>
              <InputText onChange={(e) => updateRemarks(e.target.value)} />
            </div>
          ),
          classNameMainDialog: "confirm-message-modal",
          primaryButtonText: t("claims_common_actionConfirm"),
          primaryButtonClickFn: async ({ closeFn }: any) => {
            closeFn();
            submitClaimAction(
              type === "approve" ? "approve" : type === "reject" ? "reject" : ""
            );
          },
          secondButtonClickFn: ({ closeFn }: any) => {
            closeFn();
          },
        })
      );
    };

    return (
      <div className="footer p-grid p-align-center p-justify-between">
        <div className="button-group-1">
          <Button
            onClick={() => {
              if (state && state.fromPath) {
                history.push(state.fromPath, {
                  selectedTabKey: state.selectedTabKey,
                });
              } else {
                (history as any).goBack();
              }
            }}
            className="p-button-outlined secondary"
          >
            Back
          </Button>
        </div>
        {isAllowApproveReject && (
          <div className="button-group-2">
            <Button
              onClick={() => confirmAction("approve")}
              className="p-button"
            >
              {t("claims_common_actionApprove")}
            </Button>
            <Button
              onClick={() => confirmAction("reject")}
              className="p-button"
            >
              {t("claims_common_actionReject")}
            </Button>
          </div>
        )}
      </div>
    );
  };

  // const View = (props: any) => {
  //   const { id } = props?.match?.params;
  //   const [isLoading, setIsLoading] = useState(false);
  //   const [application, setApplication] = useState<any>(null);

  //   useEffect(() => {
  //     setIsLoading(true);
  //     (async () => {
  //       const application = await getApplication(id);

  //       if (application) {
  //         setApplication(application);
  //       }
  //     })().then(() => setIsLoading(false));
  //   }, [id]);

  //   if (isLoading || !application) {
  //     return (
  //       <div className="main">
  //         <CalendarViewSkeleton />
  //       </div>
  //     );
  //   }

  return (
    <>
      <LanguageLoader
        contexts={[
          EEPORTAL_LABEL_BASE_TABLE.BASE_TABLE,
          CLAIMS_T_CONTEXT_KEY.COMMON,
          CLAIMS_T_CONTEXT_KEY.CALENDAR_VIEW,
          CLAIMS_T_CONTEXT_KEY.FORM,
          CLAIMS_T_CONTEXT_KEY.VIEW_APPLICATION,
        ]}
      />
      <div className="claim-container claim-calendar-view">
        <Header />

        <Main id={id} />

        <Footer application={application} />
      </div>
    </>
  );
};

export default View;
