import "./index.scss";

//components
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown } from "primereact/dropdown";
import { TabMenu } from "primereact/tabmenu";
import { saveAs } from "file-saver";

import { resetBcDynamicPaths } from "../../../../../redux/actions/breadcrumb";
import LanguageLoader from "../../../../../components/language-loader/language-loader";
import {
  EEPORTAL_LABEL_BASE_TABLE,
  MESSAGE_SEVERITY,
} from "../../../../../constants";
import {
  TIMESHEET_MENU_PAGE_KEY,
  TIMESHEET_CONTEXT_KEY,
} from "../../constants/";
import { useTranslation } from "react-i18next";
import { Animated } from "react-animated-css";
import Button from "../../../rental/components/action-button";
import { useHistory } from "react-router";
import { useWindowSize } from "../../../utils/window-size/useWindowSize";
import {
  getReportColumnConfig,
  getReportFilterConfig,
  parseApiFilter,
  prepareDefaultFilterValue,
  prepareFilterPayload,
  getFilterOptions,
  TIMESHEET_REPORT_KEY,
  TIMESHEET_REPORT_TYPE,
} from "./config";
import BaseForm from "../../../../../components/base-form/base-form";
import BaseTable from "../../../../../components/base-table/base-table-portal-rental";
import {
  DEFAULT_BT_SEARCH,
  getBTConfig,
} from "../../../../../components/base-table/base-table-model";
import { isResponseOk } from "../../../../../utils/utils";
import { Header, ScrollTotimesheetMenuActiveItem } from "../../components";
import { showToast } from "../../../../../services/utils/message";
// import { mockReportResponse, mockResponse } from "./mockData";
import TimesheetService from "../../../../../services/hrmnet-api/timesheet";
import { formatTwoDecimals } from "../../utils/format-helper";
import { IStoreUser } from "../../constants/type";

const TimesheetReport = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const { isMobile } = useWindowSize();

  const [reportSelectIndex, setReportSelectIndex] = useState(0);
  const tableRef = useRef();

  const currentUser: IStoreUser["profile"] = useSelector(
    (state: any) => state.user.profile
  );

  const menus = useSelector((state: any) => state.menu.sideBar);
  // TODO: make it as constant
  const timesheetMenu = menus.find(
    (x: any) => x.key === "eTimesheet"
  )?.subMenus;
  const reportSubMenu = timesheetMenu.find(
    (x: any) => x.key === "eTimesheet_Report" // eTimesheet_Report
  )?.subMenus;

  const selectedLangKey = useSelector(
    (state: any) => state.language.language?.selectedLang?.key
  );

  // Filter
  const [reportFilterMap, setReportFilterMap] = useState<any>({});
  const [reportFilterFormMap, setReportFilterFormMap] = useState<any>({});
  const [isGenerateReport, setIsGenerateReport] = useState(false);
  const [isFilterLoading, setIsFilterLoading] = useState(true);
  const [hasPayslipName, setHasPayslipName] = useState(false);

  //common filters used by all reports
  const [reportFilterFormConfig, setReportFilterFormConfig] = useState([]);

  let reportTabList = [
    ...(reportSubMenu &&
    reportSubMenu.find(
      (x: any) => x.key === TIMESHEET_REPORT_KEY.TIMESHEET_ACTIVITY_REPORT
    )
      ? [
          {
            label: t("timesheet_report_timesheet_activityReport"),
            reportType: TIMESHEET_REPORT_TYPE.TIMESHEET_ACTIVITY_REPORT,
          },
        ]
      : []),
    ...(reportSubMenu &&
    reportSubMenu.find(
      (x: any) => x.key === TIMESHEET_REPORT_KEY.TIMESHEET_UPLOAD_REPORT
    )
      ? [
          {
            label: t("timesheet_report_timesheet_alstomUploadReport"),
            reportType: TIMESHEET_REPORT_TYPE.ALSTOM_UPLOAD_REPORT,
          },
        ]
      : []),
    ...(reportSubMenu &&
    reportSubMenu.find(
      (x: any) => x.key === TIMESHEET_REPORT_KEY.TIMESHEET_SUMMARY_REPORT
    )
      ? [
          {
            label: t("timesheet_report_timesheet_summaryReport"),
            reportType: TIMESHEET_REPORT_TYPE.TIMESHEET_SUMMARY_REPORT,
          },
        ]
      : []),
  ].map((report, idx) => ({
    ...report,
    value: idx, // for dropdown value
  }));

  useEffect(() => {
    dispatch(resetBcDynamicPaths());
  }, [dispatch]);

  /**
   * On Report select change, get filter
   */
  useEffect(() => {
    const reset = () => {
      setIsGenerateReport(false);
      generateReport();
    };

    if (
      reportTabList.length &&
      !(reportTabList[reportSelectIndex].reportType in reportFilterMap)
    ) {
      loadFilter();
    }
    // TODO: seems no need to reset, check after data ready
    reset();
  }, [reportSelectIndex]);

  useEffect(() => {
    generateReport();
    ScrollTotimesheetMenuActiveItem();
  }, [selectedLangKey]);

  const loadFilter: any = async (
    selectedReportingLine: any,
    selectedEmployeeCodes: any,
    currentForm: any = null
  ) => {
    setIsFilterLoading(true);
    const selectedReport = reportTabList[reportSelectIndex].reportType;
    let res = await getFilterOptions(
      selectedReport,
      selectedReportingLine,
      selectedEmployeeCodes
    );
    // res = mockResponse;

    if (isResponseOk(res) && res.data) {
      const filterResult = parseApiFilter(selectedReport, res.data, t);
      const filters = filterResult.filters;
      setReportFilterFormConfig(filterResult.configs);
      setReportFilterMap((filterMap: any) => {
        let result = {
          ...filterMap,
          [selectedReport]: filters,
        };

        // column visiblity
        if (Array.isArray(res.data?.columns) && res.data?.columns?.length > 0) {
          result[selectedReport].tableColumnsSelect = res.data?.columns;
        }

        return result;
      });

      const defaultFilerValue = prepareDefaultFilterValue(
        selectedReport,
        filters,
        currentUser
      );

      if (
        !!selectedReportingLine &&
        Array.isArray(filters?.reportingLineFilterOption)
      ) {
        var reportingLineValues = Array.from(
          filters.reportingLineFilterOption,
          (x: any) => x.value
        );
        defaultFilerValue.reportingLine = reportingLineValues.find(
          (x) => selectedReportingLine === x
        );
      }

      if (
        !!selectedEmployeeCodes &&
        Array.isArray(filters?.employeeCodeFilterOption)
      ) {
        var employeeCodeValues = Array.from(
          filters.employeeCodeFilterOption,
          (x: any) => x.value
        );
        var currentEmployeeCodeValues = employeeCodeValues.filter((x) =>
          selectedEmployeeCodes.includes(x)
        );
        if (Array.isArray(currentEmployeeCodeValues)) {
          defaultFilerValue.employeeCode = [...currentEmployeeCodeValues];
        }
      }

      if (!!currentForm) {
        //unselect value arent in the options
        const _currentForm = { ...currentForm };
        Object.entries(_currentForm).forEach((x) => {
          const [key, value] = x;
          defaultFilerValue[key] = value;
          if (!!filters[key] && Array.isArray(value)) {
            const filterValue = Array.from(filters[key], (x: any) => x.value);
            defaultFilerValue[key] = value.filter((f) =>
              filterValue.includes(f)
            );
            return;
          }
        });
      }

      setReportFilterFormMap((formMap: any) => ({
        ...formMap,
        [selectedReport]: {
          ...defaultFilerValue,
        },
      }));

      setIsFilterLoading(false);
    }
  };

  // Action
  const generateReport = () => {
    if (!isGenerateReport) {
      setIsGenerateReport(true);
    } else {
      (tableRef.current as any).reload();
    }
  };

  const clear = () => {
    const selectedReport = reportTabList[reportSelectIndex].reportType;
    setReportFilterFormMap((formMap: any) => ({
      ...formMap,
      [selectedReport]: {},
    }));
    setIsGenerateReport(false);
  };

  const back = () => {
    (history as any).goBack();
  };

  const addSelectedColumn = (payload: any) => {
    const selectedColumns = (tableRef.current as any).getSelectedColumns();
    if (!!selectedColumns && selectedColumns.length > 0) {
      payload.selectedColumns = Array.from(selectedColumns, (x: any) => x.key);
    }
  };

  const exportReport = async () => {
    let res;
    const selectedReport = reportTabList[reportSelectIndex].reportType;
    const fileName = `${reportTabList[reportSelectIndex].label}.xlsx`;
    const payload: any = prepareFilterPayload(
      selectedReport,
      reportFilterFormMap[selectedReport]
    );

    showToast({
      summary: t("timesheet_report_informationOnGenerate"),
      severity: MESSAGE_SEVERITY.INFO,
    });

    if (!!reportFilterFormConfig && reportFilterFormConfig.length > 0) {
      reportFilterFormConfig.forEach((config: any) => {
        let formValues = reportFilterFormMap[selectedReport];
        if (!!formValues) {
          let value = formValues[config?.key];
          payload[config?.key] = value;
        }
      });
    }

    addSelectedColumn(payload);
    res = await TimesheetService.exportTimesheetReport(
      {
        body: payload,
        reportType: selectedReport,
      },
      {
        responseType: "blob",
      }
    );

    if (res && !res.TraceId) {
      saveAs(res, fileName);
    }
  };

  const renderFilterSection = () => {
    if (!reportTabList.length) return;
    const selectedReport = reportTabList[reportSelectIndex].reportType;

    const filterWithOption: any = [];
    if (reportFilterMap[selectedReport]) {
      Object.entries(reportFilterMap[selectedReport]).forEach(
        ([key, options]: any) => {
          if (options.length > 0) {
            filterWithOption.push(key);
          }
        }
      );
    }

    const formConfig = getReportFilterConfig(
      selectedReport,
      {
        ...reportFilterMap[selectedReport],
        t,
      },
      filterWithOption,
      reportFilterFormConfig
    );

    if (Array.isArray(formConfig.controls)) {
      formConfig.controls = [...formConfig.controls].map((cfg) => {
        if (!cfg.config) {
          cfg.config = {};
        }
        cfg.config.disabled = isFilterLoading;

        return cfg;
      });
    }
    const formProps: any = {
      id: "timesheet-report-filter-form",
      config: formConfig,
      form: reportFilterFormMap?.[selectedReport],
      onChange: (change: any) => {
        let isReportingLineReset = false;

        // clear reporting line whenever employee id updated
        const form = change.state.form;
        if (
          ["reportingLine", "employeeCode"].includes(
            change?.changed?.control?.key
          ) ||
          isReportingLineReset
        ) {
          // reactive to reporting line selection
          return loadFilter(form.reportingLine, form.employeeCode, form);
        }

        setReportFilterFormMap((formMap: any) => ({
          ...formMap,
          [selectedReport]: {
            ...change.state.form,
          },
        }));
      },
    };

    return (
      <div className="section-container filter-container">
        <BaseForm {...formProps} />
      </div>
    );
  };

  const renderTabSelect = () => {
    return (
      <div className="report-tab">
        <Animated
          animationIn="slideInRight"
          animationOut="slideOutRight"
          animationInDuration={200}
          animationOutDuration={200}
          isVisible={true}
        >
          {isMobile ? (
            <div className="dropdown-wrapper">
              <Dropdown
                value={reportTabList[reportSelectIndex].value}
                options={reportTabList}
                onChange={(e) => {
                  setReportSelectIndex(e.value);
                }}
              />
            </div>
          ) : (
            <TabMenu
              model={reportTabList}
              activeIndex={reportSelectIndex}
              onTabChange={(e) => {
                setReportSelectIndex(e.index);
              }}
            />
          )}
        </Animated>
      </div>
    );
  };

  const fetchReport = async (searchConfig = DEFAULT_BT_SEARCH) => {
    if (!searchConfig?.ps && !searchConfig?.pi) {
      searchConfig = DEFAULT_BT_SEARCH;
    }
    const { pi, ps } = searchConfig;

    const selectedReport = reportTabList[reportSelectIndex].reportType;
    const payload: any = prepareFilterPayload(
      selectedReport,
      reportFilterFormMap[selectedReport]
    );

    if (!!reportFilterFormConfig && reportFilterFormConfig.length > 0) {
      reportFilterFormConfig.forEach((config: any) => {
        let formValues = reportFilterFormMap[selectedReport];
        if (!!formValues) {
          let value = formValues[config?.key];
          payload[config?.key] = value;
        }
      });
    }

    addSelectedColumn(payload);

    let res;
    res = await TimesheetService.getTimesheetReport({
      page: pi,
      pageSize: ps,
      requestBody: payload,
      reportType: selectedReport,
    });

    const tableSearchConfig = searchConfig
      ? {
          ...DEFAULT_BT_SEARCH,
          pi: pi,
          ps: ps,
        }
      : DEFAULT_BT_SEARCH;
    // res = mockReportResponse;

    if (isResponseOk(res)) {
      const data = res?.data?.map((dat: any) => ({
        ...dat,
        appliedUnits: formatTwoDecimals(Number(dat.appliedUnits)),
        regularOfficeHours: formatTwoDecimals(Number(dat.regularOfficeHours)),
        otNonOfficeHours: formatTwoDecimals(Number(dat.otNonOfficeHours)),
      }));
      setHasPayslipName(res.data.some((d: any) => d.payslipName));
      const selectedReport = reportTabList[reportSelectIndex].reportType;
      if (
        selectedReport === TIMESHEET_REPORT_TYPE.TIMESHEET_ACTIVITY_REPORT ||
        selectedReport === TIMESHEET_REPORT_TYPE.ALSTOM_UPLOAD_REPORT ||
        selectedReport === TIMESHEET_REPORT_TYPE.TIMESHEET_SUMMARY_REPORT
      ) {
        return {
          datas: data,
          searchConfig: tableSearchConfig,
          total: data.length,
        };
      }
      return {
        datas: data,
        searchConfig: tableSearchConfig,
        total: (res as any).pagination.totalRecords,
      };
    }

    return {
      datas: [],
      searchConfig: DEFAULT_BT_SEARCH,
      total: 0,
    };
  };

  const renderReportSection = () => {
    if (!reportTabList.length || !isGenerateReport) return null;
    let columns = getReportColumnConfig(
      reportTabList[reportSelectIndex].reportType,
      t,
      hasPayslipName
    );

    if (
      Array.isArray(
        reportFilterMap[reportTabList[reportSelectIndex].reportType]
          ?.tableColumnsSelect
      )
    ) {
      let tableColumnsSelect = Array.from(
        reportFilterMap[
          reportTabList[reportSelectIndex].reportType
        ]?.tableColumnsSelect?.filter((x: any) => x.show),
        (x: any) => x.field.toLowerCase()
      );
      columns = columns.map((x: any) => {
        if (!tableColumnsSelect.includes(x.key.toLowerCase())) {
          x.isDefaultHide = true;
        }

        return x;
      });
    }

    const customToolbar = <div className="bt-toolbar-content"></div>;

    const configModel = getBTConfig({
      columns: columns,
      hasIndex: false,
      mode: "list",
      defaultMode: "list",
      showGlobal: false,
      hasColumnSelector: false,
      rowHover: true,
      customToolbar: customToolbar,
    });

    const baseTableProp = {
      ref: tableRef,
      configModel: configModel,
      searchConfig: DEFAULT_BT_SEARCH,
      searchFn: fetchReport,
      disableMobile: true,
      isClientSize: true,
    };

    if (!baseTableProp.isClientSize) {
      delete (baseTableProp as any).isClientSize;
    }

    const selectedReport = reportTabList[reportSelectIndex].reportType;
    if (
      selectedReport == TIMESHEET_REPORT_TYPE.TIMESHEET_ACTIVITY_REPORT ||
      selectedReport == TIMESHEET_REPORT_TYPE.ALSTOM_UPLOAD_REPORT ||
      selectedReport == TIMESHEET_REPORT_TYPE.TIMESHEET_SUMMARY_REPORT
    ) {
      baseTableProp.isClientSize = true;
    }

    return (
      <div className="section-container report-container">
        <div className="report-title">
          {reportTabList[reportSelectIndex].label}
        </div>
        <BaseTable {...baseTableProp} />
      </div>
    );
  };

  const renderFooter = () => (
    <div className="footer p-grid p-align-center p-justify-between">
      <div className="left-button-group">
        <Button className="p-button-outlined secondary" onClick={back}>
          {t("timesheet_common_actionBack")}
        </Button>
        {reportTabList.length > 0 && (
          <Button
            className={`p-button-outlined secondary ${
              isFilterLoading ? "p-disabled" : ""
            }`}
            onClick={clear}
          >
            {t("timesheet_common_actionClear")}
          </Button>
        )}
      </div>

      <div className="right-button-group">
        {reportTabList.length > 0 && isGenerateReport && (
          <Button
            className={`p-button ${isFilterLoading ? "p-disabled" : ""}`}
            onClick={exportReport}
          >
            <span className="btn-desktop-text">
              {t("timesheet_report_actionExport")}
            </span>
            <span className="btn-mobile-text">
              {t("timesheet_report_actionExportMobile")}
            </span>
          </Button>
        )}

        {reportTabList.length > 0 && (
          <Button
            className={`p-button ${isFilterLoading ? "p-disabled" : ""}`}
            onClick={generateReport}
          >
            <span className="btn-desktop-text">
              {t("timesheet_report_actionGenerate")}
            </span>
            <span className="btn-mobile-text">
              {t("timesheet_report_actionGenerateMobile")}
            </span>
          </Button>
        )}
      </div>
    </div>
  );

  return (
    <>
      <LanguageLoader
        contexts={[
          EEPORTAL_LABEL_BASE_TABLE.BASE_TABLE,
          TIMESHEET_CONTEXT_KEY.COMMON,
          TIMESHEET_CONTEXT_KEY.REPORT,
        ]}
      />

      <div className="timesheet-container timesheet-report">
        {/* Header */}
        <Header
          headerTitleKey={"eTimesheet_Timesheet_Report"}
          // headerDescKey={TIMESHEET_MENU_PAGE_KEY.TIMESHEET_PERIOD}
          WithHeaderDropdown
        />
        {/* {renderHeader()} */}

        {/* Filter */}
        <Animated
          animationIn="slideInRight"
          animationOut="slideOutRight"
          animationInDuration={200}
          animationOutDuration={200}
          isVisible={true}
        >
          {renderFilterSection()}
          {renderTabSelect()}
        </Animated>

        {/* Report */}
        <Animated
          animationIn="slideInRight"
          animationOut="slideOutRight"
          animationInDuration={200}
          animationOutDuration={200}
          isVisible={true}
        >
          {renderReportSection()}
        </Animated>

        {/* Footer */}
        {renderFooter()}
      </div>
    </>
  );
};

export default TimesheetReport;
