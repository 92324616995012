import { TFunction } from "react-i18next";
import { BASIC_CONTROL_LAYOUT_CONFIG } from "../../../leave/utils/config-helper";
import {
  CalendarFormConfigFieldKey,
  FromTimeField,
  IntervalField,
  ToTimeField,
  calendarFormConfigFieldList,
} from "./calendar-form-field-config";
import { ISelectOptionData } from "../../../timesheet/constants";
import {
  ICalendarFormData,
  TimesheetCalendarConfigurationModel,
} from "../types/types";
import moment from "moment";
import { IGetCalendarConfigMaintenanceData } from "../constants";

export const calendarFormInitValue: ICalendarFormData = {};

const createColumn = (control: string, layoutConfig: any = {}) => ({
  control,
  config: {
    ...BASIC_CONTROL_LAYOUT_CONFIG,
    ...layoutConfig,
  },
});

export const parseCalendarResponseIntoForm = (
  data: IGetCalendarConfigMaintenanceData
) => {
  let toReturn = {};
  data?.forEach((config) => {
    toReturn = {
      ...toReturn,
      [`calendarClass_${config.calendarClass}`]: config.calendarClass,
      [`fromTime_${config.calendarClass}`]: moment(
        config.fromTime,
        "HH:mm:ss"
      ).toDate(),
      [`toTime_${config.calendarClass}`]: moment(
        config.toTime,
        "HH:mm:ss"
      ).toDate(),
      [`interval_${config.calendarClass}`]: config.interval,
    };
  });

  return toReturn;
};

export const getCalendarFormControls = ({
  t,
  calendarList,
  intervalList,
  calendarForm,
}: {
  t: TFunction<"translation">;
  calendarList: string[];
  intervalList: ISelectOptionData[];
  calendarForm: ICalendarFormData;
}) => {
  return calendarFormConfigFieldList
    .map((control) => {
      return calendarList.map((calendarClass) => {
        return {
          ...control,
          key: `${control.key}_${calendarClass}`,
          label: `${t(control.label)}`,
          ...(control.key === CalendarFormConfigFieldKey.interval
            ? { enum: intervalList }
            : {}),
          ...("placeholder" in control
            ? { placeholder: t(control.placeholder as string) }
            : {}),
          config: {
            ...control?.config,
            ...(control.key === FromTimeField.key ||
            control.key === ToTimeField.key
              ? {
                  stepMinute: (calendarForm as any)?.[
                    `${IntervalField.key}_${calendarClass}`
                  ],
                }
              : {}),
          },
        };
      });
    })
    .flat();
};

export const getCalendarFormLayout = ({
  calendarList,
}: {
  calendarList: string[];
}) => {
  return {
    rows: calendarList.map((calendarClass) => ({
      columns: calendarFormConfigFieldList.map((control) =>
        createColumn(`${control.key}_${calendarClass}`)
      ),
    })),
  };
};

export const parseCalendarFormIntoRequestInput = ({
  form,
  calendarList,
}: {
  form: any;
  calendarList: string[];
}): TimesheetCalendarConfigurationModel => {
  return {
    ConfigList: calendarList.map((calendarClass) => {
      return {
        calendarClass:
          form[`${CalendarFormConfigFieldKey.calendarClass}_${calendarClass}`],
        fromTime: moment(
          form[`${CalendarFormConfigFieldKey.fromTime}_${calendarClass}`]
        ).format("HH:mm:ss"),
        toTime: moment(
          form[`${CalendarFormConfigFieldKey.toTime}_${calendarClass}`]
        ).format("HH:mm:ss"),
        interval:
          form[`${CalendarFormConfigFieldKey.interval}_${calendarClass}`],
      };
    }),
  };
};
