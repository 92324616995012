import { getColumModel } from "src/components/base-table/base-table-model";
import ClaimStatus from "../../components/claim-status";
import {
  DATETIME_FORMAT,
  INPUT_TIME_FORMAT,
  LONG_DATE_FORMAT,
} from "../../constants";
import { GetDateFromString } from "../../utils/date-helper";

const employeeDisplayNameColumn = getColumModel({
  header: "claims_applicationList_delegation_employeeName",
  key: "employeeName",
  dataType: "string",
  sortable: true,
  hideInMobile: true,
});
const employeeCodeColumn = getColumModel({
  header: "claims_applicationList_delegation_employeeCode",
  key: "employeeCode",
  dataType: "string",
  sortable: true,
  hideInMobile: true,
});

const claimTypeColumn = getColumModel({
  header: "claims_applicationList_claimsType",
  key: "claimTypeName",
  dataType: "string",
  sortable: true,
  width: 12,
});

const transactionTypeColumn = getColumModel({
  header: "claims_applicationList_transactionType",
  key: "transactionType",
  dataType: "string",
  sortable: true,
  hideInMobile: true,
});

const dateColumn = getColumModel({
  header: "claims_applicationList_claimsPeriod",
  key: "from",
  dataType: "string",
  sortable: true,
  width: 11,
  render: (data) => {
    const date = GetDateFromString(data.from, LONG_DATE_FORMAT);
    return <span>{date}</span>;
  },
});

const claimFromTimeColumn = getColumModel({
  header: "claims_applicationList_startTime",
  key: "from",
  dataType: "string",
  sortable: true,
  width: 7,
  hideInMobile: true,
  render: (data) => {
    const time = GetDateFromString(data.from, INPUT_TIME_FORMAT);
    return <span>{time}</span>;
  },
});

const claimToTimeColumn = getColumModel({
  header: "claims_applicationList_endTime",
  key: "to",
  dataType: "string",
  sortable: false,
  width: 7,
  hideInMobile: true,
  render: (data) => {
    const time = GetDateFromString(data.to, INPUT_TIME_FORMAT);
    return <span>{time}</span>;
  },
});

const unitColumn = getColumModel({
  header: "claims_applicationList_units",
  key: "unit",
  dataType: "string",
  sortable: false,
  width: 8,
  hideInMobile: true,
  render: (data) => {
    return <span>{data.unit} Hour(s)</span>;
  },
});

const amountColumn = getColumModel({
  header: "claims_applicationList_amounts",
  key: "amount",
  dataType: "string",
  sortable: false,
  width: 8,
  hideInMobile: true,
});

const statusColumn = getColumModel({
  header: "claims_applicationList_status",
  key: "status",
  dataType: "string",
  sortable: true,
  width: 12,
  hideInMobile: true,
  render: (data) => <ClaimStatus status={data.status} data={data} />,
});

const submissionDateColumn = getColumModel({
  header: "claims_applicationList_submissionDate",
  key: "submissionDate",
  dataType: "string",
  sortable: true,
  width: 12,
  hideInMobile: true,
  render: (data) => {
    const datetime = GetDateFromString(data.submissionDate, DATETIME_FORMAT);
    return <span>{datetime}</span>;
  },
});

const referenceNoColumn = getColumModel({
  header: "claims_applicationList_refNo",
  key: "referenceNo",
  dataType: "string",
  sortable: true,
  width: 12,
  hideInMobile: true,
});

const submittedByColumn = getColumModel({
  header: "claims_applicationList_submittedBy",
  key: "submittedBy",
  dataType: "string",
  sortable: true,
  width: 12,
  hideInMobile: true,
});

const lastUpdateDateColumn = getColumModel({
  header: "claims_applicationList_lastUpdateDate",
  key: "lastUpdated",
  dataType: "string",
  sortable: true,
  width: 12,
  hideInMobile: true,
  render: (data) => {
    const datetime = GetDateFromString(data.lastUpdated, DATETIME_FORMAT);
    return <span>{datetime}</span>;
  },
});

const compLeaveDisplayColumn = getColumModel({
  header: "claims_applicationList_compLeaveDisplay",
  key: "compLeaveDisplay",
  dataType: "string",
  sortable: false,
  width: 10,
  hideInMobile: true,
});

const dateTypeDisplayColumn = getColumModel({
  header: "claims_applicationList_dateTypeDisplay",
  key: "dateTypeDisplay",
  dataType: "string",
  sortable: false,
  width: 10,
  hideInMobile: true,
});

export const MyListColumns = [
  claimTypeColumn,
  transactionTypeColumn,
  dateColumn,
  claimFromTimeColumn,
  claimToTimeColumn,
  dateTypeDisplayColumn,
  unitColumn,
  amountColumn,
  compLeaveDisplayColumn,
  statusColumn,
  submissionDateColumn,
  referenceNoColumn,
  submittedByColumn,
  lastUpdateDateColumn,
];

export const OtherListColumns = [
  employeeCodeColumn,
  employeeDisplayNameColumn,
  claimTypeColumn,
  transactionTypeColumn,
  dateColumn,
  claimFromTimeColumn,
  claimToTimeColumn,
  dateTypeDisplayColumn,
  unitColumn,
  amountColumn,
  compLeaveDisplayColumn,
  statusColumn,
  submissionDateColumn,
  referenceNoColumn,
  submittedByColumn,
  lastUpdateDateColumn,
];
