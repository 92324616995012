import { useContext, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { showSpinner } from "src/redux/actions/spinner";
import ClaimService from "src/services/hrmnet-api/claims";
import { ClaimContext } from "../../context/claim-context-provider";
import ClaimFormSummary from "../claim-form-summray";
import DelegationForm from "./delegation-form";
import DetailsForm from "./details-form";
import HeaderForm from "./header-form";

export default function ClaimForm({
  isEdit,
  applicant,
  delegateRef,
  headerRef,
  detailRef,
  formTouched,
}: any) {
  const dispatch = useDispatch();
  const { state } = useContext(ClaimContext);
  const { form } = state;

  const [claimLoading, setClaimLoading] = useState(false);
  const [employeeLoading, setEmployeeLoading] = useState(false);
  const [claimTypes, setClaimTypes] = useState<any[]>([]);
  const [employees, setEmployees] = useState<any[]>([]);

  const employee = useMemo(() => {
    if (applicant !== form?.employeeCode) {
      return form?.employeeCode;
    }
    return applicant;
  }, [applicant, form?.employeeCode]);

  const getClaimTypes = async (employeeCode?: string) => {
    const response = (await ClaimService.getClaimTypes({
      employeeCode,
    })) as any;
    setClaimTypes(response?.data);
  };

  const getDelegationEmployees = async () => {
    const response = (await ClaimService.getDelegationEmployees()) as any;
    setEmployees(response?.data);
  };

  useEffect(() => {
    setEmployeeLoading(true);
    (async () => {
      await getDelegationEmployees();
    })().finally(() => setEmployeeLoading(false));
  }, []);

  useEffect(() => {
    setClaimLoading(true);
    (async () => {
      await getClaimTypes(employee);
    })().finally(() => setClaimLoading(false));
  }, [employee]);

  useEffect(() => {
    dispatch(showSpinner(claimLoading || employeeLoading));
  }, [dispatch, claimLoading, employeeLoading]);

  return (
    <div className="main claim-application-form">
      <div className="p-d-flex p-jc-between p-flex-wrap p-flex-sm-wrap p-flex-md-wrap">
        <div className="flex-1">
          {!isEdit && (
            <DelegationForm
              formRef={delegateRef}
              formTouched={formTouched}
              employees={employees}
              applicant={applicant}
            />
          )}

          <HeaderForm
            formRef={headerRef}
            claimTypes={claimTypes}
            formTouched={formTouched}
            setLoading={setClaimLoading}
          />

          {form.claimType && (
            <DetailsForm formRef={detailRef} formTouched={formTouched} />
          )}
        </div>
        {!isEdit && <ClaimFormSummary />}
      </div>
    </div>
  );
}
