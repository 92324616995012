//#region Employee Form

export const EmployeeDetailsForm = {
  employeeCode: undefined,
  commencedDate: undefined,
  newSalaryEffDate: undefined,
  monthlyFixedWage: undefined,
  surname: undefined,
  otherNames: undefined,
  email: undefined,
  department: undefined,
  dateJoinGroup: undefined,
  employeeTerminates: undefined,
  lastWorkDate: undefined,
  companyCode: undefined,
  payslipName: undefined,
  grade: undefined,
  status: undefined,
  payrollIndicator: undefined,
  holidayclass: undefined,
  personalEmail: undefined,
  altEmployeeCode: undefined,
  lastMonthlyFixedWage: undefined,
  lastLoginDate: undefined,
  directReportTo: undefined,
};

//direct from api
export function EmployeeDetailsApiSteps(t) {
  return {
    data: [
      {
        displaySequence: 1,
        id: 1,
        stepName: t("User_Management_Employee_Details_Step1"), //"Start",
        translationKey: "User_Management_Employee_Details_Step1",
        sections: [
          {
            defaultExpanded: false,
            displaySequence: 1,
            isEditable: true,
            sectionId: 200,
            sectionName: t("User_Management_Employee_Details_BasicDetails"), //"Basic Details",
            translationKey: "User_Management_Employee_Details_BasicDetails",
          },
          {
            defaultExpanded: false,
            displaySequence: 2,
            isEditable: true,
            sectionId: 300,
            sectionName: t("User_Management_Employee_Details_OtherDetails"), //"Other Details",
            translationKey: "User_Management_Employee_Details_OtherDetails",
          },
        ],
      },
    ],
  };
}

export function EmployeeDetailsConfiguration(
  t,
  companyOptions,
  gradeOptions,
  statusOptions,
  employeeOptions,
  departmentOptions,
  holidayclassOptions,
  payrollIndicatorOptions
) {
  return {
    data: {
      formName: t("User_Management_Employee_Details_Formname"), //"Employee Data Amendment",
      id: 1000,
      sections: [
        {
          defaultExpanded: false,
          displayLayout: 2,
          displaySequence: 2,
          id: 200,
          isDefaultHide: false,
          sectionName: t("User_Management_Employee_Details_BasicDetails"), //"Basic Details",
          translationKey: "User_Management_Employee_Details_BasicDetails",
          fields: [
            {
              controlType: "TextBox",
              dataType: "string",
              displaySequence: 1,
              fieldGroup: 1,
              fieldLabel: t("User_Management_Employee_Details_Employee_Code"), //"Employee Code",
              fieldName: "EmployeeCode",
              id: 1,
              inputName: "EmployeeCode",
              isAllowNull: false,
              isDefaultHide: false,
              isDetailView: false,
              isEditable: false,
              isElasticData: false,
              isHideLabelField: false,
              isHtmlField: false,
              isSpecialField: false,
              length: -1,
              storageDataType: "String",
              styleClass: "p-lg-6 p-md-12 p-sm-12",
              translationKey: "User_Management_Employee_Details_Employee_Code",
              viewSectionId: 200,
            },
            {
              controlType: "TextBox",
              dataType: "string",
              displaySequence: 1,
              fieldGroup: 1,
              fieldLabel: t(
                "User_Management_Employee_Details_Alt_Employee_Code"
              ), //"Alt Employee Code",
              fieldName: "AltEmployeeCode",
              id: 2,
              inputName: "AltEmployeeCode",
              isAllowNull: true,
              isDefaultHide: false,
              isDetailView: false,
              isEditable: true,
              isElasticData: false,
              isHideLabelField: false,
              isHtmlField: false,
              isSpecialField: false,
              length: -1,
              storageDataType: "String",
              styleClass: "p-lg-6 p-md-12 p-sm-12",
              translationKey:
                "User_Management_Employee_Details_Alt_Employee_Code",
              viewSectionId: 200,
            },
            {
              controlType: "TextBox",
              dataType: "string",
              displaySequence: 1,
              fieldGroup: 1,
              fieldLabel: t("User_Management_Employee_Details_Surname"), //"Surname",
              fieldName: "Surname",
              id: 3,
              inputName: "Surname",
              isAllowNull: false,
              isDefaultHide: false,
              isDetailView: false,
              isEditable: true,
              isElasticData: false,
              isHideLabelField: false,
              isHtmlField: false,
              isSpecialField: false,
              length: -1,
              storageDataType: "String",
              styleClass: "p-lg-6 p-md-12 p-sm-12",
              translationKey: "User_Management_Employee_Details_Surname",
              viewSectionId: 200,
            },
            {
              controlType: "TextBox",
              dataType: "string",
              displaySequence: 1,
              fieldGroup: 1,
              fieldLabel: t("User_Management_Employee_Details_GivenName"), //"Given Name",
              fieldName: "OtherNames",
              id: 4,
              inputName: "OtherNames",
              isAllowNull: false,
              isDefaultHide: false,
              isDetailView: false,
              isEditable: true,
              isElasticData: false,
              isHideLabelField: false,
              isHtmlField: false,
              isSpecialField: false,
              length: -1,
              storageDataType: "String",
              styleClass: "p-lg-6 p-md-12 p-sm-12",
              translationKey: "User_Management_Employee_Details_GivenName",
              viewSectionId: 200,
            },
            {
              controlType: "TextBox",
              dataType: "string",
              displaySequence: 1,
              fieldGroup: 1,
              fieldLabel: t("User_Management_Employee_Details_Payslip_Name"), //"Payslip Name",
              fieldName: "PayslipName",
              id: 5,
              inputName: "PayslipName",
              isAllowNull: false,
              isDefaultHide: false,
              isDetailView: false,
              isEditable: true,
              isElasticData: false,
              isHideLabelField: false,
              isHtmlField: false,
              isSpecialField: false,
              length: -1,
              storageDataType: "String",
              styleClass: "p-lg-6 p-md-12 p-sm-12",
              translationKey: "User_Management_Employee_Details_Payslip_Name",
              viewSectionId: 200,
            },
            {
              controlType: "DropDownList",
              dataType: "string",
              displaySequence: 1,
              dropdownOptions: gradeOptions,
              fieldGroup: 1,
              fieldLabel: t("User_Management_Employee_Details_Grade"), //"Grade",
              fieldName: "Grade",
              id: 6,
              inputName: "Grade",
              isAllowNull: true,
              isDefaultHide: false,
              isDetailView: false,
              isEditable: true,
              isElasticData: false,
              isHideLabelField: false,
              isHtmlField: false,
              isSpecialField: false,
              length: -1,
              storageDataType: "String",
              styleClass: "p-lg-6 p-md-12 p-sm-12",
              translationKey: "User_Management_Employee_Details_Grade",
              viewSectionId: 200,
            },
            {
              controlType: "TextBox",
              dataType: "string",
              displaySequence: 1,
              fieldGroup: 1,
              fieldLabel: t("User_Management_Employee_Details_Email"), //"Given Name",
              fieldName: "Email",
              id: 7,
              inputName: "Email",
              isAllowNull: false,
              isDefaultHide: false,
              isDetailView: false,
              isEditable: true,
              isElasticData: false,
              isHideLabelField: false,
              isHtmlField: false,
              isSpecialField: false,
              length: -1,
              storageDataType: "String",
              styleClass: "p-lg-6 p-md-12 p-sm-12",
              translationKey: "User_Management_Employee_Details_Email",
              viewSectionId: 200,
            },
            {
              controlType: "TextBox",
              dataType: "string",
              displaySequence: 1,
              fieldGroup: 1,
              fieldLabel: t("User_Management_Employee_Details_Personal_Email"), //"Given Name",
              fieldName: "PersonalEmail",
              id: 8,
              inputName: "PersonalEmail",
              isAllowNull: true,
              isDefaultHide: false,
              isDetailView: false,
              isEditable: true,
              isElasticData: false,
              isHideLabelField: false,
              isHtmlField: false,
              isSpecialField: false,
              length: -1,
              storageDataType: "String",
              styleClass: "p-lg-6 p-md-12 p-sm-12",
              translationKey: "User_Management_Employee_Details_Personal_Email",
              viewSectionId: 200,
            },
            {
              controlType: "DropDownList",
              dataType: "string",
              displaySequence: 1,
              dropdownOptions: departmentOptions,
              fieldGroup: 1,
              fieldLabel: t("User_Management_Employee_Details_Department"), //"Department",
              fieldName: "Department",
              id: 9,
              inputName: "Department",
              isAllowNull: false,
              isDefaultHide: false,
              isDetailView: false,
              isEditable: true,
              isElasticData: false,
              isHideLabelField: false,
              isHtmlField: false,
              isSpecialField: false,
              length: -1,
              storageDataType: "String",
              styleClass: "p-lg-6 p-md-12 p-sm-12",
              translationKey: "User_Management_Employee_Details_Department",
              viewSectionId: 200,
            },
            {
              controlType: "DropDownList",
              dataType: "string",
              displaySequence: 1,
              dropdownOptions: companyOptions,
              fieldGroup: 1,
              fieldLabel: t("User_Management_Employee_Details_Company_Code"), //"Company Code",
              fieldName: "CompanyCode",
              id: 10,
              inputName: "CompanyCode",
              isAllowNull: false,
              isDefaultHide: false,
              isDetailView: false,
              isEditable: true,
              isElasticData: false,
              isHideLabelField: false,
              isHtmlField: false,
              isSpecialField: false,
              length: -1,
              storageDataType: "String",
              styleClass: "p-lg-6 p-md-12 p-sm-12",
              translationKey: "User_Management_Employee_Details_Company_Code",
              viewSectionId: 200,
            },
          ],
        },
        {
          defaultExpanded: false,
          displayLayout: 2,
          displaySequence: 3,
          id: 300,
          isDefaultHide: false,
          sectionName: "Other Details",
          translationKey: "User_Management_Employee_Details_OtherDetails",
          fields: [
            {
              controlType: "DatePicker",
              dataType: "string",
              // dependentFields: "[{\"Value\":\"$any\",\"Action\":\"SetMinDate\",\"Fields\":\"Rent_Free_Period_To\"},{\"Value\":\"$any\",\"Action\":\"SetMinDate\",\"Fields\":\"Rent_Free_Period_To\"}]",
              displaySequence: 1,
              fieldGroup: 1,
              fieldLabel: t("User_Management_Employee_Details_Commenced_Date"), //"CommencedDate",
              fieldName: "CommencedDate",
              id: 1,
              inputName: "CommencedDate",
              isAllowNull: true,
              isDefaultHide: false,
              isDetailView: false,
              isEditable: true,
              isElasticData: false,
              isHideLabelField: false,
              isHtmlField: false,
              isSpecialField: false,
              length: -1,
              storageDataType: "String",
              styleClass: "p-lg-6 p-md-12 p-sm-12",
              translationKey: "User_Management_Employee_Details_Commenced_Date",
              viewSectionId: 300,
            },
            {
              controlType: "DatePicker",
              dataType: "string",
              // dependentFields: "[{\"Value\":\"$any\",\"Action\":\"SetMinDate\",\"Fields\":\"Rent_Free_Period_To\"},{\"Value\":\"$any\",\"Action\":\"SetMinDate\",\"Fields\":\"Rent_Free_Period_To\"}]",
              displaySequence: 1,
              fieldGroup: 1,
              fieldLabel: t(
                "User_Management_Employee_Details_New_Salary_Effective_Date"
              ), //"New Salary Effective Date",
              fieldName: "NewSalaryEffDate",
              id: 2,
              inputName: "NewSalaryEffDate",
              isAllowNull: true,
              isDefaultHide: false,
              isDetailView: false,
              isEditable: true,
              isElasticData: false,
              isHideLabelField: false,
              isHtmlField: false,
              isSpecialField: false,
              length: -1,
              storageDataType: "String",
              styleClass: "p-lg-6 p-md-12 p-sm-12",
              translationKey:
                "User_Management_Employee_Details_New_Salary_Effective_Date",
              viewSectionId: 300,
            },
            {
              controlType: "NumericBox",
              dataType: "number",
              displaySequence: 2,
              // eventFunctionName: "calculateEligibleAmount",
              fieldGroup: 1,
              fieldLabel: t(
                "User_Management_Employee_Details_Monthly_Fixed_Wage"
              ), //"Monthly Fixed Salary",
              fieldName: "MonthlyFixedWage",
              id: 3,
              inputName: "MonthlyFixedWage",
              isAllowNull: true,
              isDefaultHide: false,
              isDetailView: false,
              isEditable: true,
              isElasticData: false,
              isHideLabelField: false,
              isHtmlField: false,
              isSpecialField: false,
              length: 0,
              storageDataType: "String",
              styleClass: "p-lg-6 p-md-12 p-sm-12",
              translationKey:
                "User_Management_Employee_Details_Monthly_Fixed_Wage",
              viewSectionId: 300,
              config: {
                isEditable: true,
                mode: "decimal",
                minFractionDigits: 2,
                maxFractionDigits: 2,
              },
            },
            {
              controlType: "NumericBox",
              dataType: "number",
              displaySequence: 2,
              // eventFunctionName: "calculateEligibleAmount",
              fieldGroup: 1,
              fieldLabel: t(
                "User_Management_Employee_Details_Last_Monthly_Fixed_Wage"
              ), //"Monthly Fixed Salary",
              fieldName: "LastMonthlyFixedWage",
              id: 4,
              inputName: "LastMonthlyFixedWage",
              isAllowNull: true,
              isDefaultHide: false,
              isDetailView: false,
              isEditable: true,
              isElasticData: false,
              isHideLabelField: false,
              isHtmlField: false,
              isSpecialField: false,
              length: 0,
              storageDataType: "String",
              styleClass: "p-lg-6 p-md-12 p-sm-12",
              translationKey:
                "User_Management_Employee_Details_Last_Monthly_Fixed_Wage",
              viewSectionId: 300,
              config: {
                isEditable: true,
                mode: "decimal",
                minFractionDigits: 2,
                maxFractionDigits: 2,
              },
            },
            {
              controlType: "DatePicker",
              dataType: "string",
              displaySequence: 3,
              fieldGroup: 2,
              fieldLabel: t("User_Management_Employee_Details_Last_Login_Date"), //"Last_Login_Date",
              fieldName: "LastLoginDate",
              id: 5,
              inputName: "LastLoginDate",
              isAllowNull: true,
              isDefaultHide: false,
              isDetailView: false,
              isEditable: true,
              isElasticData: false,
              isHideLabelField: false,
              isHtmlField: false,
              isSpecialField: false,
              length: -1,
              storageDataType: "String",
              styleClass: "p-lg-6 p-md-12 p-sm-12",
              translationKey:
                "User_Management_Employee_Details_Last_Login_Date",
              viewSectionId: 300,
            },
            {
              controlType: "DatePicker",
              dataType: "string",
              displaySequence: 4,
              fieldGroup: 2,
              fieldLabel: t("User_Management_Employee_Details_Date_Join_Group"), //"Date Join Group",
              fieldName: "DateJoinGroup",
              id: 6,
              inputName: "DateJoinGroup",
              isAllowNull: true,
              isDefaultHide: false,
              isDetailView: false,
              isEditable: true,
              isElasticData: false,
              isHideLabelField: false,
              isHtmlField: false,
              isSpecialField: false,
              length: -1,
              storageDataType: "String",
              styleClass: "p-lg-6 p-md-12 p-sm-12",
              translationKey:
                "User_Management_Employee_Details_Date_Join_Group",
              viewSectionId: 300,
            },
            {
              controlType: "DatePicker",
              dataType: "string",
              displaySequence: 3,
              fieldGroup: 2,
              fieldLabel: t(
                "User_Management_Employee_Details_Termination_Date"
              ), //"Termination Date",
              fieldName: "EmployeeTerminates",
              id: 7,
              inputName: "EmployeeTerminates",
              isAllowNull: true,
              isDefaultHide: false,
              isDetailView: false,
              isEditable: true,
              isElasticData: false,
              isHideLabelField: false,
              isHtmlField: false,
              isSpecialField: false,
              length: -1,
              storageDataType: "String",
              styleClass: "p-lg-6 p-md-12 p-sm-12",
              translationKey:
                "User_Management_Employee_Details_Termination_Date",
              viewSectionId: 300,
            },
            {
              controlType: "DatePicker",
              dataType: "string",
              displaySequence: 4,
              fieldGroup: 2,
              fieldLabel: t(
                "User_Management_Employee_Details_Last_Working_Date"
              ), //"Last Working Date",
              fieldName: "LastWorkDate",
              id: 8,
              inputName: "LastWorkDate",
              isAllowNull: true,
              isDefaultHide: false,
              isDetailView: false,
              isEditable: true,
              isElasticData: false,
              isHideLabelField: false,
              isHtmlField: false,
              isSpecialField: false,
              length: -1,
              storageDataType: "String",
              styleClass: "p-lg-6 p-md-12 p-sm-12",
              translationKey:
                "User_Management_Employee_Details_Last_Working_Date",
              viewSectionId: 300,
            },
            {
              controlType: "DropDownList",
              dataType: "string",
              displaySequence: 4,
              dropdownOptions: employeeOptions,
              fieldGroup: 2,
              fieldLabel: t(
                "User_Management_Employee_Details_Direct_Report_To"
              ), //"Direct Report To",
              fieldName: "DirectReportTo",
              id: 9,
              inputName: "DirectReportTo",
              isAllowNull: true,
              isDefaultHide: false,
              isDetailView: false,
              isEditable: true,
              isElasticData: false,
              isHideLabelField: false,
              isHtmlField: false,
              isSpecialField: false,
              length: -1,
              storageDataType: "String",
              styleClass: "p-lg-6 p-md-12 p-sm-12",
              translationKey:
                "User_Management_Employee_Details_Direct_Report_To",
              viewSectionId: 300,
            },
            {
              controlType: "DropDownList",
              dataType: "string",
              displaySequence: 4,
              dropdownOptions: statusOptions,
              fieldGroup: 2,
              fieldLabel: t("User_Management_Employee_Details_Status"), //"Status",
              fieldName: "Status",
              id: 10,
              inputName: "Status",
              isAllowNull: true,
              isDefaultHide: false,
              isDetailView: false,
              isEditable: true,
              isElasticData: false,
              isHideLabelField: false,
              isHtmlField: false,
              isSpecialField: false,
              length: -1,
              storageDataType: "String",
              styleClass: "p-lg-6 p-md-12 p-sm-12",
              translationKey: "User_Management_Employee_Details_Status",
              viewSectionId: 300,
            },
            {
              controlType: "DropDownList",
              dataType: "string",
              displaySequence: 4,
              dropdownOptions: holidayclassOptions,
              fieldGroup: 2,
              fieldLabel: t("User_Management_Employee_Details_Holiday_Class"), //"Holiday Class",
              fieldName: "HolidayClass",
              id: 11,
              inputName: "HolidayClass",
              isAllowNull: true,
              isDefaultHide: false,
              isDetailView: false,
              isEditable: true,
              isElasticData: false,
              isHideLabelField: false,
              isHtmlField: false,
              isSpecialField: false,
              length: -1,
              storageDataType: "String",
              styleClass: "p-lg-6 p-md-12 p-sm-12",
              translationKey: "User_Management_Employee_Details_Holiday_Class",
              viewSectionId: 300,
            },
            {
              controlType: "DropDownList",
              dataType: "string",
              displaySequence: 4,
              dropdownOptions: payrollIndicatorOptions,
              fieldGroup: 2,
              fieldLabel: t(
                "User_Management_Employee_Details_Payroll_Indicator"
              ), //"Payroll_Indicator",
              fieldName: "PayrollIndicator",
              id: 12,
              inputName: "PayrollIndicator",
              isAllowNull: true,
              isDefaultHide: false,
              isDetailView: false,
              isEditable: true,
              isElasticData: false,
              isHideLabelField: false,
              isHtmlField: false,
              isSpecialField: false,
              length: -1,
              storageDataType: "String",
              styleClass: "p-lg-6 p-md-12 p-sm-12",
              translationKey:
                "User_Management_Employee_Details_Payroll_Indicator",
              viewSectionId: 300,
            },
          ],
        },
      ],
    },
  };
}

export function EmployeeDetailsConfigurationFunction(
  t,
  {
    employeeCode,
    employeeCommenced,
    newSalaryEffDate,
    monthlyFixedWage,
    surname,
    otherNames,
    email,
    department,
    dateJoinGroup,
    employeeTerminates,
    lastWorkDate,
    companyCode,
    payslipName,
    grade,
    status,
    payrollIndicator,
    holidayclass,
    alternativeEmail,
    altEmployeeCode,
    lastMonthlyFixedWage,
    lastLoginDate,
    directReportTo,
  }
) {
  return {
    data: {
      formName: t("User_Management_Employee_Details_Formname"), //"Employee Data Amendment",
      id: 1000,
      sections: [
        {
          defaultExpanded: false,
          displayLayout: 2,
          displaySequence: 2,
          id: 200,
          isDefaultHide: false,
          sectionName: t("User_Management_Employee_Details_BasicDetails"), //"Basic Details",
          translationKey: "User_Management_Employee_Details_BasicDetails",
          fields: [
            {
              controlType: "TextBox",
              dataType: "string",
              displaySequence: 1,
              fieldGroup: 1,
              fieldLabel: t("User_Management_Employee_Details_Employee_Code"), //"Employee Code",
              fieldName: "EmployeeCode",
              id: 1,
              inputName: "EmployeeCode",
              isAllowNull: true,
              isDefaultHide: false,
              isDetailView: false,
              isEditable: true,
              isElasticData: false,
              isHideLabelField: false,
              isHtmlField: false,
              isSpecialField: false,
              length: -1,
              storageDataType: "String",
              styleClass: "p-lg-6 p-md-12 p-sm-12",
              translationKey: "User_Management_Employee_Details_Employee_Code",
              viewSectionId: 200,
              value: employeeCode,
            },
            {
              controlType: "TextBox",
              dataType: "string",
              displaySequence: 1,
              fieldGroup: 1,
              fieldLabel: t(
                "User_Management_Employee_Details_Alt_Employee_Code"
              ), //"Alt Employee Code",
              fieldName: "AltEmployeeCode",
              id: 2,
              inputName: "AltEmployeeCode",
              isAllowNull: true,
              isDefaultHide: false,
              isDetailView: false,
              isEditable: true,
              isElasticData: false,
              isHideLabelField: false,
              isHtmlField: false,
              isSpecialField: false,
              length: -1,
              storageDataType: "String",
              styleClass: "p-lg-6 p-md-12 p-sm-12",
              translationKey:
                "User_Management_Employee_Details_Alt_Employee_Code",
              viewSectionId: 200,
              value: altEmployeeCode,
            },
            {
              controlType: "TextBox",
              dataType: "string",
              displaySequence: 1,
              fieldGroup: 1,
              fieldLabel: t("User_Management_Employee_Details_Surname"), //"Surname",
              fieldName: "Surname",
              id: 3,
              inputName: "Surname",
              isAllowNull: true,
              isDefaultHide: false,
              isDetailView: false,
              isEditable: true,
              isElasticData: false,
              isHideLabelField: false,
              isHtmlField: false,
              isSpecialField: false,
              length: -1,
              storageDataType: "String",
              styleClass: "p-lg-6 p-md-12 p-sm-12",
              translationKey: "User_Management_Employee_Details_Surname",
              viewSectionId: 200,
              value: surname,
            },
            {
              controlType: "TextBox",
              dataType: "string",
              displaySequence: 1,
              fieldGroup: 1,
              fieldLabel: t("User_Management_Employee_Details_GivenName"), //"Given Name",
              fieldName: "OtherNames",
              id: 4,
              inputName: "OtherNames",
              isAllowNull: false,
              isDefaultHide: false,
              isDetailView: false,
              isEditable: true,
              isElasticData: false,
              isHideLabelField: false,
              isHtmlField: false,
              isSpecialField: false,
              length: -1,
              storageDataType: "String",
              styleClass: "p-lg-6 p-md-12 p-sm-12",
              translationKey: "User_Management_Employee_Details_GivenName",
              viewSectionId: 200,
              value: otherNames,
            },
            {
              controlType: "TextBox",
              dataType: "string",
              displaySequence: 1,
              fieldGroup: 1,
              fieldLabel: t("User_Management_Employee_Details_Payslip_Name"), //"Payslip Name",
              fieldName: "PayslipName",
              id: 5,
              inputName: "PayslipName",
              isAllowNull: true,
              isDefaultHide: false,
              isDetailView: false,
              isEditable: true,
              isElasticData: false,
              isHideLabelField: false,
              isHtmlField: false,
              isSpecialField: false,
              length: -1,
              storageDataType: "String",
              styleClass: "p-lg-6 p-md-12 p-sm-12",
              translationKey: "User_Management_Employee_Details_Payslip_Name",
              viewSectionId: 200,
              value: payslipName,
            },
            {
              controlType: "TextBox",
              dataType: "string",
              displaySequence: 1,
              fieldGroup: 1,
              fieldLabel: t("User_Management_Employee_Details_Grade"), //"Given Name",
              fieldName: "Grade",
              id: 6,
              inputName: "Grade",
              isAllowNull: true,
              isDefaultHide: false,
              isDetailView: false,
              isEditable: true,
              isElasticData: false,
              isHideLabelField: false,
              isHtmlField: false,
              isSpecialField: false,
              length: -1,
              storageDataType: "String",
              styleClass: "p-lg-6 p-md-12 p-sm-12",
              translationKey: "User_Management_Employee_Details_Grade",
              viewSectionId: 200,
              value: grade,
            },
            {
              controlType: "TextBox",
              dataType: "string",
              displaySequence: 1,
              fieldGroup: 1,
              fieldLabel: t("User_Management_Employee_Details_Email"), //"Email",
              fieldName: "Email",
              id: 7,
              inputName: "Email",
              isAllowNull: true,
              isDefaultHide: false,
              isDetailView: false,
              isEditable: true,
              isElasticData: false,
              isHideLabelField: false,
              isHtmlField: false,
              isSpecialField: false,
              length: -1,
              storageDataType: "String",
              styleClass: "p-lg-6 p-md-12 p-sm-12",
              translationKey: "User_Management_Employee_Details_Email",
              viewSectionId: 200,
              value: email,
            },
            {
              controlType: "TextBox",
              dataType: "string",
              displaySequence: 1,
              fieldGroup: 1,
              fieldLabel: t("User_Management_Employee_Details_Personal_Email"), //"Personal Email",
              fieldName: "PersonalEmail",
              id: 8,
              inputName: "PersonalEmail",
              isAllowNull: true,
              isDefaultHide: false,
              isDetailView: false,
              isEditable: true,
              isElasticData: false,
              isHideLabelField: false,
              isHtmlField: false,
              isSpecialField: false,
              length: -1,
              storageDataType: "String",
              styleClass: "p-lg-6 p-md-12 p-sm-12",
              translationKey: "User_Management_Employee_Details_Personal_Email",
              viewSectionId: 200,
              value: alternativeEmail,
            },
            {
              controlType: "TextBox",
              dataType: "string",
              displaySequence: 1,
              fieldGroup: 1,
              fieldLabel: t("User_Management_Employee_Details_Department"), //"Given Name",
              fieldName: "Department",
              id: 9,
              inputName: "Department",
              isAllowNull: true,
              isDefaultHide: false,
              isDetailView: false,
              isEditable: true,
              isElasticData: false,
              isHideLabelField: false,
              isHtmlField: false,
              isSpecialField: false,
              length: -1,
              storageDataType: "String",
              styleClass: "p-lg-6 p-md-12 p-sm-12",
              translationKey: "User_Management_Employee_Details_Department",
              viewSectionId: 200,
              value: department,
            },
            {
              controlType: "TextBox",
              dataType: "string",
              displaySequence: 1,
              fieldGroup: 1,
              fieldLabel: t("User_Management_Employee_Details_Company_Code"), //"Given Name",
              fieldName: "CompanyCode",
              id: 10,
              inputName: "CompanyCode",
              isAllowNull: true,
              isDefaultHide: false,
              isDetailView: false,
              isEditable: true,
              isElasticData: false,
              isHideLabelField: false,
              isHtmlField: false,
              isSpecialField: false,
              length: -1,
              storageDataType: "String",
              styleClass: "p-lg-6 p-md-12 p-sm-12",
              translationKey: "User_Management_Employee_Details_Company_Code",
              viewSectionId: 200,
              value: companyCode,
            },
          ],
        },
        {
          defaultExpanded: false,
          displayLayout: 2,
          displaySequence: 3,
          id: 300,
          isDefaultHide: false,
          sectionName: t("Employee_Data_Amendment_OtherDetails"), //"Other Details",
          translationKey: "Employee_Data_Amendment_OtherDetails",
          fields: [
            {
              controlType: "DatePicker",
              dataType: "string",
              // dependentFields: "[{\"Value\":\"$any\",\"Action\":\"SetMinDate\",\"Fields\":\"Rent_Free_Period_To\"},{\"Value\":\"$any\",\"Action\":\"SetMinDate\",\"Fields\":\"Rent_Free_Period_To\"}]",
              displaySequence: 1,
              fieldGroup: 1,
              fieldLabel: t("User_Management_Employee_Details_Commenced_Date"), //"CommencedDate",
              fieldName: "CommencedDate",
              id: 1,
              inputName: "CommencedDate",
              isAllowNull: true,
              isDefaultHide: false,
              isDetailView: false,
              isEditable: true,
              isElasticData: false,
              isHideLabelField: false,
              isHtmlField: false,
              isSpecialField: false,
              length: -1,
              storageDataType: "String",
              styleClass: "p-lg-6 p-md-12 p-sm-12",
              translationKey: "User_Management_Employee_Details_Commenced_Date",
              viewSectionId: 300,
              value: employeeCommenced,
            },
            {
              controlType: "DatePicker",
              dataType: "string",
              // dependentFields: "[{\"Value\":\"$any\",\"Action\":\"SetMinDate\",\"Fields\":\"Rent_Free_Period_To\"},{\"Value\":\"$any\",\"Action\":\"SetMinDate\",\"Fields\":\"Rent_Free_Period_To\"}]",
              displaySequence: 1,
              fieldGroup: 1,
              fieldLabel: t(
                "User_Management_Employee_Details_New_Salary_Effective_Date"
              ), //"New Salary Effective Date",
              fieldName: "NewSalaryEffDate",
              id: 2,
              inputName: "NewSalaryEffDate",
              isAllowNull: true,
              isDefaultHide: false,
              isDetailView: false,
              isEditable: true,
              isElasticData: false,
              isHideLabelField: false,
              isHtmlField: false,
              isSpecialField: false,
              length: -1,
              storageDataType: "String",
              styleClass: "p-lg-6 p-md-12 p-sm-12",
              translationKey:
                "User_Management_Employee_Details_New_Salary_Effective_Date",
              viewSectionId: 300,
              value: newSalaryEffDate,
            },
            {
              controlType: "NumericBox",
              dataType: "number",
              displaySequence: 2,
              // eventFunctionName: "calculateEligibleAmount",
              fieldGroup: 1,
              fieldLabel: t(
                "User_Management_Employee_Details_Monthly_Fixed_Wage"
              ), //"Monthly Fixed Salary",
              fieldName: "MonthlyFixedWage",
              id: 3,
              inputName: "MonthlyFixedWage",
              isAllowNull: true,
              isDefaultHide: false,
              isDetailView: false,
              isEditable: true,
              isElasticData: false,
              isHideLabelField: false,
              isHtmlField: false,
              isSpecialField: false,
              length: 0,
              storageDataType: "String",
              styleClass: "p-lg-6 p-md-12 p-sm-12",
              translationKey:
                "User_Management_Employee_Details_Monthly_Fixed_Wage",
              viewSectionId: 300,
              value: Number(monthlyFixedWage.replace(",", "")),
            },
            {
              controlType: "NumericBox",
              dataType: "number",
              displaySequence: 2,
              // eventFunctionName: "calculateEligibleAmount",
              fieldGroup: 1,
              fieldLabel: t(
                "User_Management_Employee_Details_Last_Monthly_Fixed_Wage"
              ), //"Monthly Fixed Salary",
              fieldName: "LastMonthlyFixedWage",
              id: 4,
              inputName: "LastMonthlyFixedWage",
              isAllowNull: true,
              isDefaultHide: false,
              isDetailView: false,
              isEditable: true,
              isElasticData: false,
              isHideLabelField: false,
              isHtmlField: false,
              isSpecialField: false,
              length: 0,
              storageDataType: "String",
              styleClass: "p-lg-6 p-md-12 p-sm-12",
              translationKey:
                "User_Management_Employee_Details_Last_Monthly_Fixed_Wage",
              viewSectionId: 300,
              value: Number(lastMonthlyFixedWage.replace(",", "")),
            },
            {
              controlType: "DatePicker",
              dataType: "string",
              displaySequence: 3,
              fieldGroup: 2,
              fieldLabel: t("User_Management_Employee_Details_Last_Login_Date"), //"Last_Login_Date",
              fieldName: "LastLoginDate",
              id: 5,
              inputName: "LastLoginDate",
              isAllowNull: true,
              isDefaultHide: false,
              isDetailView: false,
              isEditable: true,
              isElasticData: false,
              isHideLabelField: false,
              isHtmlField: false,
              isSpecialField: false,
              length: -1,
              storageDataType: "String",
              styleClass: "p-lg-6 p-md-12 p-sm-12",
              translationKey:
                "User_Management_Employee_Details_Last_Login_Date",
              viewSectionId: 300,
              value: lastLoginDate,
            },
            {
              controlType: "DatePicker",
              dataType: "string",
              displaySequence: 4,
              fieldGroup: 2,
              fieldLabel: t("User_Management_Employee_Details_Date_Join_Group"), //"Date Join Group",
              fieldName: "DateJoinGroup",
              id: 6,
              inputName: "DateJoinGroup",
              isAllowNull: true,
              isDefaultHide: false,
              isDetailView: false,
              isEditable: true,
              isElasticData: false,
              isHideLabelField: false,
              isHtmlField: false,
              isSpecialField: false,
              length: -1,
              storageDataType: "String",
              styleClass: "p-lg-6 p-md-12 p-sm-12",
              translationKey:
                "User_Management_Employee_Details_Date_Join_Group",
              viewSectionId: 300,
              value: dateJoinGroup,
            },
            {
              controlType: "DatePicker",
              dataType: "string",
              displaySequence: 3,
              fieldGroup: 2,
              fieldLabel: t(
                "User_Management_Employee_Details_Termination_Date"
              ), //"Termination Date",
              fieldName: "EmployeeTerminates",
              id: 7,
              inputName: "EmployeeTerminates",
              isAllowNull: true,
              isDefaultHide: false,
              isDetailView: false,
              isEditable: true,
              isElasticData: false,
              isHideLabelField: false,
              isHtmlField: false,
              isSpecialField: false,
              length: -1,
              storageDataType: "String",
              styleClass: "p-lg-6 p-md-12 p-sm-12",
              translationKey:
                "User_Management_Employee_Details_Termination_Date",
              viewSectionId: 300,
              value: employeeTerminates,
            },
            {
              controlType: "DatePicker",
              dataType: "string",
              displaySequence: 4,
              fieldGroup: 2,
              fieldLabel: t(
                "User_Management_Employee_Details_Last_Working_Date"
              ), //"Last Working Date",
              fieldName: "LastWorkDate",
              id: 8,
              inputName: "LastWorkDate",
              isAllowNull: true,
              isDefaultHide: false,
              isDetailView: false,
              isEditable: true,
              isElasticData: false,
              isHideLabelField: false,
              isHtmlField: false,
              isSpecialField: false,
              length: -1,
              storageDataType: "String",
              styleClass: "p-lg-6 p-md-12 p-sm-12",
              translationKey:
                "User_Management_Employee_Details_Last_Working_Date",
              viewSectionId: 300,
              value: lastWorkDate,
            },
            {
              controlType: "TextBox",
              dataType: "string",
              displaySequence: 4,
              dropdownOptions: [],
              fieldGroup: 2,
              fieldLabel: t(
                "User_Management_Employee_Details_Direct_Report_To"
              ), //"Direct Report To",
              fieldName: "DirectReportTo",
              id: 9,
              inputName: "DirectReportTo",
              isAllowNull: true,
              isDefaultHide: false,
              isDetailView: false,
              isEditable: true,
              isElasticData: false,
              isHideLabelField: false,
              isHtmlField: false,
              isSpecialField: false,
              length: -1,
              storageDataType: "String",
              styleClass: "p-lg-6 p-md-12 p-sm-12",
              translationKey:
                "User_Management_Employee_Details_Direct_Report_To",
              viewSectionId: 300,
              value: directReportTo,
            },
            {
              controlType: "TextBox",
              dataType: "string",
              displaySequence: 4,
              dropdownOptions: [],
              fieldGroup: 2,
              fieldLabel: t("User_Management_Employee_Details_Status"), //"Status",
              fieldName: "Status",
              id: 10,
              inputName: "Status",
              isAllowNull: true,
              isDefaultHide: false,
              isDetailView: false,
              isEditable: true,
              isElasticData: false,
              isHideLabelField: false,
              isHtmlField: false,
              isSpecialField: false,
              length: -1,
              storageDataType: "String",
              styleClass: "p-lg-6 p-md-12 p-sm-12",
              translationKey: "User_Management_Employee_Details_Status",
              viewSectionId: 300,
              value: status,
            },
            {
              controlType: "TextBox",
              dataType: "string",
              displaySequence: 4,
              dropdownOptions: [],
              fieldGroup: 2,
              fieldLabel: t("User_Management_Employee_Details_Holiday_Class"), //"Holiday Class",
              fieldName: "HolidayClass",
              id: 11,
              inputName: "HolidayClass",
              isAllowNull: true,
              isDefaultHide: false,
              isDetailView: false,
              isEditable: true,
              isElasticData: false,
              isHideLabelField: false,
              isHtmlField: false,
              isSpecialField: false,
              length: -1,
              storageDataType: "String",
              styleClass: "p-lg-6 p-md-12 p-sm-12",
              translationKey: "User_Management_Employee_Details_Holiday_Class",
              viewSectionId: 300,
              value: holidayclass,
            },
            {
              controlType: "TextBox",
              dataType: "string",
              displaySequence: 4,
              dropdownOptions: [],
              fieldGroup: 2,
              fieldLabel: t(
                "User_Management_Employee_Details_Payroll_Indicator"
              ), //"Payroll_Indicator",
              fieldName: "PayrollIndicator",
              id: 12,
              inputName: "PayrollIndicator",
              isAllowNull: true,
              isDefaultHide: false,
              isDetailView: false,
              isEditable: true,
              isElasticData: false,
              isHideLabelField: false,
              isHtmlField: false,
              isSpecialField: false,
              length: -1,
              storageDataType: "String",
              styleClass: "p-lg-6 p-md-12 p-sm-12",
              translationKey:
                "User_Management_Employee_Details_Payroll_Indicator",
              viewSectionId: 300,
              value: payrollIndicator,
            },
          ],
        },
      ],
    },
  };
}
//#endregion
