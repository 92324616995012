import { BCType } from "../../../../../components/base-control/base-control";
import { IBaseFormControlProps } from "../../../timesheet/constants";

export enum CalendarFormConfigFieldKey {
  calendarClass = "calendarClass",
  fromTime = "fromTime",
  toTime = "toTime",
  interval = "interval",
}

export const CalendarClassField: IBaseFormControlProps = {
  key: CalendarFormConfigFieldKey.calendarClass,
  label: "admin_timesheet_calendarConfiguration_calendarId",
  type: BCType.input,
  placeholder: "admin_timesheet_calendarConfiguration_calendarId_placeholder",
  config: { readOnly: true },
};

export const FromTimeField: IBaseFormControlProps = {
  key: CalendarFormConfigFieldKey.fromTime,
  label: "admin_timesheet_calendarConfiguration_fromTime",
  type: BCType.date2,
  includeTimeZone: true,
  required: true,
  config: {
    tooltip: null,
    timeOnly: true,
    showIcon: false,
    hourFormat: "24",
  },
};

export const ToTimeField: IBaseFormControlProps = {
  key: CalendarFormConfigFieldKey.toTime,
  label: "admin_timesheet_calendarConfiguration_toTime",
  type: BCType.date2,
  includeTimeZone: true,
  required: true,
  config: {
    tooltip: null,
    timeOnly: true,
    showIcon: false,
    hourFormat: "24",
  },
};

export const IntervalField: IBaseFormControlProps = {
  key: CalendarFormConfigFieldKey.interval,
  label: "admin_timesheet_calendarConfiguration_interval",
  type: BCType.select,
  required: true,
  hasFilterEnum: true,
  placeholder: "admin_timesheet_calendarConfiguration_interval_placeholder",
};

export const calendarFormConfigFieldList = [
  CalendarClassField,
  FromTimeField,
  ToTimeField,
  IntervalField,
];
