import { TIMESHEET_MENU_TYPE_KEY } from "../../constants";
import "./timesheet-menu.scss";

import { TabMenu } from "primereact/tabmenu";

interface TimesheetMenuProps {
  menu: any;
  currentPageKey?: string;
  redirectPage?: any;
  [key: string]: any;
}

export const TimesheetMenu = ({ menu, currentPageKey }: TimesheetMenuProps) => {
  if (!menu) return null;
  let selectedIndex = -1;

  const menuItems = menu
    .filter((_menu: any) => _menu.type === TIMESHEET_MENU_TYPE_KEY.MENU)
    .map((_menu: any, i: number) => {
      if (_menu.key === currentPageKey) {
        selectedIndex = i;
      }
      return {
        ..._menu,
        label: _menu.name,
        url: selectedIndex === 0 ? _menu.url : `/${_menu.url}`,
        icon: `timesheet-menu-icon-${_menu.key.toLowerCase()}`,
      };
    });

  return (
    <div className="timesheet-menu">
      <TabMenu
        className="timesheet-tab-menu"
        model={menuItems}
        activeIndex={selectedIndex}
      />
    </div>
  );
};

export const ScrollTotimesheetMenuActiveItem = () => {
  document
    .querySelector(".timesheet-menu .p-tabmenuitem.p-highlight")
    ?.scrollIntoView({
      behavior: "smooth",
      inline: "start",
      block: "nearest",
    });
};
