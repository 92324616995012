import { useContext, useEffect, useState } from "react";
import LanguageLoader from "../../../../../components/language-loader/language-loader";
import { EEPORTAL_LABEL_BASE_TABLE } from "../../../../../constants";
import {
  DATE_TYPE_CONFIG,
  IDatesData,
  SHORT_DATE_FORMAT,
  TIMESHEET_CONTEXT_KEY,
} from "../../constants";
import "./create.scss";
import TimesheetService from "../../../../../services/hrmnet-api/timesheet";
import { FormSkeleton } from "../../components";
import { CreateApplicationForm } from "./create-application-form";
import { MessageSwitch } from "../../../../../services/utils/message";
import { store } from "../../../../../redux/store";
import i18n from "../../../../../i18n";
import { useSelector } from "react-redux";
import moment from "moment";
import { DATE_TYPE, IDateTypeItemConfig } from "../../constants/type";
import { FormContextProvider, useFormContext } from "./context/useFormContext";
import {
  ToDateField,
  FromTimeField,
  ToTimeField,
  TotalPeriodsHoursField,
  ExcludeMealTimeField,
} from "./form-helper/create-application-form-field-config";
import { ApplicationContext, ModalContext } from "../../context";
import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";
import { useTimesheetDetailContext } from "../timesheet-detail/context/useTimesheetDetailContext";

const CreateTimesheet = ({ displayAsModal = false, ...props }: any) => {
  const {
    form,
    setForm,
    setTimesheetConfig,
    setPeriodDetail,
    setActivityIds,
    setCalendarDates,
    onSaveButtonClick,
    onBackButtonClick,
    isDisabledAddButton,
  } = useFormContext();

  const { config, calendarDates } = useTimesheetDetailContext();

  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(false);

  const employeeCode = useSelector((state: any) => state.auth?.employeeCode);

  const [
    {
      application,
      application: { timesheetId },
    },
  ] = useContext(ApplicationContext); // periodId
  const [
    {
      activityModalDetails: {
        isEdit,
        isFromView,
        period,
        periodId,
        editActivity,
      },
    },
  ] = useContext(ModalContext);
  let toast = store.getState().global.toast;
  let alert = store.getState().global.alert;

  // Initial rendering fetch data
  useEffect(() => {
    setLoading(true);
    (async () => {
      if (!periodId || !employeeCode) {
        MessageSwitch({ message: [""] }, toast, alert, i18n);
        return;
      }
      // fetch period detail
      const getPeriodDetailResponse = await TimesheetService.getPeriodDetail({
        params: { timetableId: periodId },
      });
      if (!getPeriodDetailResponse.data) {
        MessageSwitch({ message: [""] }, toast, alert, i18n);
        return;
      }
      setPeriodDetail(getPeriodDetailResponse.data);

      const dates = calendarDates?.reduce(
        (acc: { [key in string]: IDateTypeItemConfig }, item: IDatesData) => {
          const day = moment(new Date(item.targetDate)).format(
            SHORT_DATE_FORMAT
          );
          acc[day] = { ...DATE_TYPE_CONFIG[item.dateType] };
          if (
            item.dateType === DATE_TYPE.HOLIDAY ||
            item.dateType === DATE_TYPE.STATUTORY
          ) {
            acc[day].holidayName = item.holidayName;
          }
          return acc;
        },
        {}
      );

      setCalendarDates(dates);

      // fetch list of available activity codes
      const getActivityCodesResponse =
        await TimesheetService.getAvailableActivities({
          params: {
            employeeCode: application?.employeeCode || employeeCode,
            timetableId: periodId,
          },
        });
      setActivityIds(
        getActivityCodesResponse.data?.map((item: any) => ({
          label: item.name,
          value: parseInt(item.value),
          description: item.description,
        }))
      );

      let startDate = new Date(getPeriodDetailResponse.data.startDate);
      if (editActivity) {
        const { fromDate } = editActivity;
        startDate = new Date(fromDate);
      }

      setTimesheetConfig(config);

      const formStateToBeUpdated = {
        ...form,
        employeeCode,
        timesheetId,
        periodId,
        date: startDate,
        period,
        isFromView,
        isEdit,
      };
      if (!editActivity) {
        // setting default activity to date value as view date if there is no edit
        formStateToBeUpdated[
          `${ToDateField.key}_${form.activities?.[0].uuid}`
        ] = startDate;

        const defaultFromTime = moment(config?.fromTime, "HH:mm:ss");
        const defaultToTime = moment(config?.toTime, "HH:mm:ss");
        const defaultTotalHours = moment
          .duration(defaultToTime.diff(defaultFromTime))
          .asHours();

        const mealFromTime = moment(config?.mealFromTime, "HH:mm:ss");
        const mealToTime = moment(config?.mealToTime, "HH:mm:ss");
        const mealTotalHours = moment
          .duration(mealToTime.diff(mealFromTime))
          .asHours();

        formStateToBeUpdated[`${TotalPeriodsHoursField.key}`] =
          defaultTotalHours;

        formStateToBeUpdated[
          `${FromTimeField.key}_${form.activities?.[0].uuid}`
        ] = defaultFromTime;

        formStateToBeUpdated[
          `${ToTimeField.key}_${form.activities?.[0].uuid}`
        ] = defaultToTime;

        formStateToBeUpdated["excludeMealTimeHours"] = mealTotalHours;

        formStateToBeUpdated[
          `${ExcludeMealTimeField.key}_${form.activities?.[0].uuid}`
        ] = false;
      }
      // Update form field 'period'
      setForm(formStateToBeUpdated);
    })().finally(() => setLoading(false));
  }, []);

  return (
    <>
      <LanguageLoader
        contexts={[
          EEPORTAL_LABEL_BASE_TABLE.BASE_TABLE,
          TIMESHEET_CONTEXT_KEY.COMMON,
          TIMESHEET_CONTEXT_KEY.LIST,
        ]}
      />
      <div
        className={`timesheet-submit-application${
          editActivity ? " is-edit" : ""
        }`}
      >
        {loading && <FormSkeleton column={2} />}
        {!loading && (
          <>
            <CreateApplicationForm />
          </>
        )}
        <div className="timesheet-create-modal-action-button-bar">
          <Button
            className="p-button p-button-secondary"
            onClick={onBackButtonClick}
            disabled={loading}
          >
            {t("timesheet_common_actionBack")}
          </Button>
          <Button
            className="p-button"
            onClick={onSaveButtonClick}
            disabled={loading || isDisabledAddButton}
          >
            {t(
              !!editActivity
                ? "timesheet_common_actionEdit"
                : "timesheet_common_actionAddToTimesheet"
            )}
          </Button>
        </div>
      </div>
    </>
  );
};

const CreateTimesheetActivities = (props: any) => {
  return (
    <>
      <FormContextProvider>
        <CreateTimesheet {...props} />
      </FormContextProvider>
    </>
  );
};

export default CreateTimesheetActivities;
