import "./textarea-control.scss";

import {
  BCProps,
  BCStates,
  BCType,
  isError,
  renderError,
  validateControl,
} from "./../base-control";
import React, { useEffect, useRef, useState } from "react";
import { jsonEqual, sleep } from "./../../../utils/utils";

import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { useTranslation } from "react-i18next";
import { TextareaControlProps, TextareaControlState } from "./textarea-control";

const TextareaControl: React.FC<TextareaControlProps> = (props) => {
  const { t } = useTranslation();
  // extract props data
  const id = props.id || "";
  const lazy = props.fromFilter;
  const ruleList = props.ruleList || [];
  if (props.required) {
    ruleList.push({
      name: "required",
    });
  }
  let maxLength = props.maxLength;
  if (!maxLength) maxLength = 500;

  if (maxLength) {
    ruleList.push({
      name: "maxLength",
      param: maxLength,
    });
  }

  if (props.minLength) {
    ruleList.push({
      name: "minLength",
      param: props.minLength,
    });
  }

  if (props.pattern) {
    ruleList.push({
      name: "pattern",
      param: props.pattern,
      error: props.patternMessage || t("base_control_text_area_field_invalid"),
    });
  }

  // init state control
  let initState: TextareaControlState = {
    touched: false,
    value: props.value,
    valueStr: (props.value || "").toString(),
    controlState: {
      invalid: false,
    },
  };
  initState.controlState =
    props.controlState || validateControl(ruleList || [], initState.value, t);
  const [state, setState] = useState(initState);
  const mountedRef = useRef(true);
  // unsubcribe
  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);
  // Update state if control state changed
  useEffect(() => {
    const ruleList = props.ruleList || [];
    if (props.required) {
      ruleList.push({
        name: "required",
      });
    }

    let controlState =
      props.controlState || validateControl(ruleList || [], props.value, t);
    if (!mountedRef.current) return;
    setState({
      ...state,
      value: props.value,
      setDefault: true,
      controlState,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.controlState, props.required, props.value]);
  // Update if rule update
  // useEffect(() => {
  //   if (!mountedRef.current) return;
  //   onChange(state.value);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [props.required, props.ruleList]);

  useEffect(() => {
    if (
      props.defaultValue &&
      props.defaultValue !== state.lastDefault &&
      state.setDefault
    ) {
      onChange(props.defaultValue, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.defaultValue, state.lastDefault]);

  // Update display value if state value changed
  useEffect(() => {
    if (lazy) {
      try {
        if (id) {
          let elm = document.getElementById(id) as any;
          if (elm) elm.value = state.value || "";
        }
      } catch (error) {}
    }
  }, [id, lazy, state.value]);

  // onbeforeunload
  useEffect(() => {
    if (props.config?.readOnly) {
      try {
        let listTextArea = document.querySelectorAll(`#${id}`);
        [].forEach.call(listTextArea, function (textArea: any) {
          textArea.style.height = textArea.scrollHeight + "px";
        });
      } catch (error) {}
    }
  });

  const onChange = async (value: string, updateLastDefault = false) => {
    if (value && maxLength && state.value && value.length > maxLength) {
      value = value.substring(0, maxLength);
    }

    let valueStr = value ? value.toString() : "";

    const controlState = validateControl(ruleList, value, t);
    let _state = { ...state };
    if (updateLastDefault) {
      _state.lastDefault = props.defaultValue;
    }
    // onChange
    if (!lazy && props.onChange) {
      props.onChange({
        controlState: controlState,
        value: value,
        valueStr: valueStr,
      });
    }
    if (!lazy) {
      // do set state
      _state.value = value;
      _state.valueStr = valueStr;
      _state.controlState = controlState;
      if (!jsonEqual(_state, state)) {
        setState(_state);
      }
      return;
    }
    await localStorage.setItem(id, value);
    await sleep(500);
    const storageValue = await localStorage.getItem(id);
    if (storageValue === value) {
      if (props.onTrueUpdateValue) {
        props.onTrueUpdateValue({
          controlState: controlState,
          value: value,
          valueStr: valueStr,
        });
      }
      if (lazy && props.onChange) {
        props.onChange({
          controlState: controlState,
          value: value,
          valueStr: valueStr,
        });
      }

      if (lazy) {
        _state.value = value;
        _state.valueStr = valueStr;
        _state.controlState = controlState;
        if (_state !== state) {
          setState(_state);
        }
      }
    }
  };
  const onBlur = () => {
    if (props.onTrueUpdateValue) {
      props.onTrueUpdateValue({
        controlState: state.controlState,
        value: state.value,
        valueStr: state.valueStr,
      });
    }
  };
  const onKeyUp = (event: any) => {
    if (event.key === "Enter") {
      if (props.onTrueUpdateValue) {
        props.onTrueUpdateValue({
          controlState: state.controlState,
          value: state.value,
          valueStr: state.valueStr,
        });
      }
    }
  };
  const onKeyDown = (event: any) => {
    if (props.fromFilter) {
      try {
        if (id) {
          let elm = document.getElementById(id) as any;
          var startPos = elm.selectionStart;
          if (
            (!elm.value || elm.value.trim() === "" || startPos === 0) &&
            event.keyCode === 32
          ) {
            event.preventDefault();
          }
        }
      } catch (error) {}
    }
  };
  const onFocus = () => {
    if (props.onTouched) {
      props.onTouched();
      return;
    }
    if (!state.touched) {
      setState({
        ...state,
        touched: true,
      });
    }
  };
  const renderControl = () => {
    let commonProp = {
      tooltip: state.valueStr || "",
      tooltipOptions: { position: "top" },
      ...props.config,
      id,
      className: `${props.className} ${
        isError(state, props) ? "p-invalid" : ""
      } `,
      placeholder: props.placeholder,
      autoFocus: props.autoFocus,
      onChange: (event: any) => onChange(event.currentTarget.value),
      onBlur: () => onBlur(),
      onKeyUp: (event: any) => onKeyUp(event),
      onFocus: () => onFocus(),
      onKeyDown: (event: any) => onKeyDown(event),
    };
    if (!lazy) {
      commonProp.value = state.value || "";
    }
    if (props.type === BCType.textarea2) {
      if (props.config?.readOnly) {
        commonProp.rows = 1;
        commonProp.autoResize = true;
      }
      return <InputTextarea rows={4} {...commonProp} />;
    }
  };

  //aw
  const renderAction = () => {
    if (!props.action) {
      return null;
    } else {
      return (
        <Button
          label={props.action?.label}
          icon={props.action?.icon}
          tooltip={props.action?.tooltip}
          tooltipOptions={{ position: "top" }}
          onClick={props.action?.clickFn}
          onMouseDown={props.action?.onMouseDown} // added custom function - aw
          onMouseLeave={props.action?.onMouseLeave} // added custom function - aw
          onTouchStart={props.action?.onTouchStart} // added custom function - aw
          onTouchEnd={props.action?.onTouchEnd} // added custom function - aw
          onMouseUp={props.action?.onMouseUp} // added custom function - aw
          onTouchMove={props.action?.onTouchMove} // added custom function - aw
          className={props.action?.className + " custom-input-action-btn"}
          type="button"
        />
      );
    }
  };

  const renderHintBottom = (
    hint: string,
    textLength: number,
    maxLength: number
  ) => {
    if (hint == "text_count") {
      return maxLength - textLength + " character(s) remaining";
    } else return hint;
  };

  return (
    <>
      <div
        className={`textarea-control-inner p-field ${
          props.noLabel ? "no-label" : ""
        }`}
      >
        <label htmlFor={props.id}>
          {props.label}
          {props.required && !props.noRequiredLabel ? (
            <small className="required p-invalid">&nbsp;*</small>
          ) : null}
          {props.tooltip ? (
            <Button
              type="button"
              tooltip={props.tooltip}
              tooltipOptions={{ position: "top" }}
              icon="pi pi-info-circle"
              className="p-button-rounded label-help p-button-text p-button-plain"
            />
          ) : null}
        </label>
        <div
          className={`p-inputgroup ${
            isError(state, props) ? "p-inputgroup-error" : ""
          }`}
        >
          {renderControl()}
          {renderAction()}
          {/*aw*/}
        </div>
        {props.hintBottom && (
          <div className={"control-hint-bottom"}>
            {renderHintBottom(props.hintBottom, state.value.length, maxLength)}
          </div>
        )}
        {renderError(state, props, t)}
      </div>
    </>
  );
};

export default TextareaControl;
