import { ReactElement } from "react";
import { BCType } from "../../../../components/base-control/base-control";
import { v4 as uuidv4 } from "uuid";
import { ITimesheetDetailActivity } from "../context/reducer/application";

export interface IPeriodSelectFormData {
  employeeCode: string;
  employeeName: string;
  userSubmit: boolean;
  period: string;
}

export const periodSelectFormInitialState: IPeriodSelectFormData = {
  employeeCode: "",
  employeeName: "",
  userSubmit: true,
  period: "",
};

export interface IActivityInputFields {
  id?: string;
  uuid: string;
  activityId?: number;
  fromTime?: string;
  toDate?: string;
  toTime?: string;
}

export interface ICreateApplicationFormData {
  employeeCode?: string;
  periodId?: string;
  period?: string;
  date?: Date;
  totalPeriodsHours: number;
  excludeMealTimeHours: number;
  activities: IActivityInputFields[];
  remarks?: string;
  applyMultiple: boolean;
  calendarDates: Date[];
  [x: string]: any;
}

export const createApplicationFormInitialState: (
  editActivity: ITimesheetDetailActivity
) => ICreateApplicationFormData = (editActivity: any) => {
  let defaultValues: any = {};
  // deep clone to put inside form
  let uuid = uuidv4();
  const defaultActivity = editActivity ? { ...editActivity } : undefined;
  if (defaultActivity) {
    defaultActivity.fromDate = new Date(defaultActivity.fromDate);
    defaultActivity.fromTime = new Date(defaultActivity.fromTime);
    defaultActivity.toDate = new Date(defaultActivity.toDate);
    defaultActivity.toTime = new Date(defaultActivity.toTime);
    if (!defaultActivity.uuid) {
      defaultActivity.uuid = uuidv4();
    }
    defaultValues[`activityId_${defaultActivity.uuid}`] =
      defaultActivity.activityId;
    defaultValues[`fromDate_${defaultActivity.uuid}`] =
      defaultActivity.fromDate;
    // defaultValues["date"] = defaultActivity.fromDate;
    defaultValues[`fromTime_${defaultActivity.uuid}`] =
      defaultActivity.fromTime;
    defaultValues[`toDate_${defaultActivity.uuid}`] = defaultActivity.toDate;
    defaultValues[`toTime_${defaultActivity.uuid}`] = defaultActivity.toTime;
    defaultValues[`excludeMealTime_${defaultActivity.uuid}`] =
      defaultActivity.excludeMealTime;
    // defaultValues[`re`]
  }
  return {
    period: "",
    remarks: defaultActivity ? defaultActivity.remarks || "" : "",
    applyMultiple: false,
    calendarDates: [],
    activities: defaultActivity
      ? [defaultActivity]
      : [
          {
            uuid,
          },
        ],
    totalPeriodsHours: defaultActivity ? defaultActivity.appliedUnits : 0,
    excludeMealTimeHours: 0,
    ...defaultValues,
  };
};

export interface IBaseFormFieldProps {
  label: string;
  value: any;
  description: string;
}
export type IBaseFormOnChangeProps<T> = {
  changed: {
    control: {
      config: Object;
      enums: IBaseFormFieldProps[];
      key: string;
      label: string;
      placeholder: string;
      required: boolean;
      type: string;
    };
    data: {
      controlState: {
        invalid: boolean;
      };
      value: Record<keyof T, T>;
      valueObj: IBaseFormFieldProps;
      valueStr: string;
    };
  };

  state: {
    form: T;
    formAlert: any[];
    formState: Record<keyof T, { invalid: boolean }> & { invalid: boolean };
    layoutConfig: { rows: any[] };
    loading: boolean;
    submitting: boolean;
    touched?: boolean;
  };
};

export interface IBaseFormControlProps {
  key: string;
  label: string;
  noLabel?: boolean;
  componentRender?: (props: any) => ReactElement;
  type?: BCType;
  includeTimeZone?: boolean;
  required?: boolean;
  dateFormat?: string;
  placeholder?: string;
  hasFilterEnum?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  onClick?: (props: any) => void;
  maxLength?: number;
  viewMode?: boolean;
  fromFilter?: boolean;
  config?: {
    rows?: number;
    readOnly?: boolean;
    display?: boolean;
    disabled?: boolean;
    tooltip?: string | null;
    timeOnly?: boolean;
    showIcon?: boolean;
    hourFormat?: string;
    className?: string;
    viewDate?: any;
    minDate?: Date;
    maxDate?: Date;
    monthNavigator?: boolean;
    yearNavigator?: boolean;
    fromFilter?: boolean;
  };
}

export const BASIC_CONTROL_LAYOUT_CONFIG_TWO_FIFTH_COLUMN = {
  className: "p-col-6 p-lg-4 p-sm-6",
};

export const BASIC_CONTROL_LAYOUT_CONFIG_ONE_FIFTH_COLUMN = {
  className: "p-col-6 p-lg-1 p-sm-6 activity-row",
};

export interface IViewSelectFormData {
  employeeCode: string;
  userSubmit: boolean;
  period: string;
}

export const viewSelectFormInitialState: IViewSelectFormData = {
  employeeCode: "",
  userSubmit: true,
  period: "",
};
