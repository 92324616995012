import React, { useEffect } from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { setGlobal } from "../redux/actions/global";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  MODULE_CODE,
  FUNCTION_CODE,
  ERROR_PAGE,
  MENU_TYPE,
} from "../constants/index";
import PageNotFound from "./modules/public/error/employee-error-404";
import PageSystemError from "./modules/public/error/employee-error-500";

// components
import {
  MyInformation,
  Documents,
  Home,
  Rental,
  Leave,
  CompanyPolicies,
  Notifications,
  ChangePassword,
  PrivacyPolicy,
  TermsAndConditions,
  Admin,
  Nwow,
  Claims,
  Timesheet,
} from "./modules";
import MainLoading from "./layouts/components/main-loading/main-loading";
import { createSelector } from "reselect";
import { PortalLink } from "../services/utils";

export const PrivateRoutes = [
  {
    path: FUNCTION_CODE.Common,
    defaultLayout: "HorizontalLayout",
    component: Home,
    exact: true,
    restrictedContent: true,
  },
  {
    path: FUNCTION_CODE.EmploymentManagement,
    defaultLayout: "HorizontalLayout",
    component: MyInformation,
    exact: false,
    strict: true,
    restrictedContent: true,
  },
  {
    path: FUNCTION_CODE.Leave,
    defaultLayout: "HorizontalLayout",
    component: Leave,
    exact: false,
    restrictedContent: true,
  },
  {
    path: FUNCTION_CODE.Timesheet,
    defaultLayout: "HorizontalLayout",
    component: Timesheet,
    exact: false,
    restrictedContent: true,
  },
  {
    path: FUNCTION_CODE.Nwow,
    defaultLayout: "HorizontalLayout",
    component: Nwow,
    exact: false,
    restrictedContent: true,
  },
  {
    path: FUNCTION_CODE.Rental,
    defaultLayout: "HorizontalLayout",
    component: Rental,
    exact: false,
    restrictedContent: true,
  },
  {
    path: FUNCTION_CODE.Nwow,
    defaultLayout: "HorizontalLayout",
    component: Nwow,
    exact: false,
    restrictedContent: true,
  },
  {
    path: FUNCTION_CODE.Claims,
    defaultLayout: "HorizontalLayout",
    component: Claims,
    exact: false,
    restrictedContent: true,
  },
  {
    path: FUNCTION_CODE.Document,
    defaultLayout: "HorizontalLayout",
    component: Documents,
    exact: false,
    strict: true,
    restrictedContent: true,
  },
  {
    path: MODULE_CODE.Policy,
    defaultLayout: "HorizontalLayout",
    component: CompanyPolicies,
    exact: false,
    strict: true,
    restrictedContent: true,
  },
  {
    path: MODULE_CODE.Messaging,
    defaultLayout: "HorizontalLayout",
    component: Notifications,
    exact: false,
    strict: true,
    restrictedContent: true,
  },
  {
    path: MODULE_CODE.Admin,
    defaultLayout: "HorizontalLayout",
    component: Admin,
    exact: false,
    strict: true,
    restrictedContent: true,
  },
  {
    path: MODULE_CODE.ChangePassword,
    defaultLayout: "HorizontalLayout",
    component: ChangePassword,
    exact: false,
    strict: true,
    restrictedContent: false,
  },
  {
    path: MODULE_CODE.TermCondition,
    defaultLayout: "HorizontalLayout",
    component: TermsAndConditions,
    exact: false,
    strict: true,
    restrictedContent: false,
    redirectExclude: true, //flag to inform this is commom route for public and private routes
  },
  {
    path: MODULE_CODE.PrivacyPolicy,
    defaultLayout: "HorizontalLayout",
    component: PrivacyPolicy,
    exact: false,
    strict: true,
    restrictedContent: false,
    redirectExclude: true,
  },
  {
    path: ERROR_PAGE.ERROR_500,
    defaultLayout: "HorizontalLayout",
    component: PageSystemError,
    exact: true,
    restrictedContent: false,
  },
];

function EmployeeRouter() {
  const selectAccessibleUrl = createSelector(
    (state) => state.menu?.sideBar,
    (sideBar) => Array.from(sideBar, (x) => x.url)
  );
  const accessibleUrl = useSelector(selectAccessibleUrl);
  const isSideBarLoading = useSelector((state) => state.menu.isLoading);

  return (
    <>
      <Switch>
        {accessibleUrl && accessibleUrl.length > 0
          ? PrivateRoutes.filter(
              (x) =>
                x.restrictedContent === false || accessibleUrl.includes(x.path)
            ).map((route, i) => (
              <Route
                key={i}
                path={PortalLink(route.path)}
                exact={route?.exact}
                strict={route?.strict}
                render={() => {
                  return React.createElement(EmployeeContent, route);
                }}
              />
            ))
          : null}
        {accessibleUrl && accessibleUrl.length > 0 && (
          <Route exact path={`/`}>
            <Redirect to={`${accessibleUrl[0]}`} />
          </Route>
        )}
        {!isSideBarLoading && <Route component={PageNotFound} />}
        {isSideBarLoading && <MainLoading className="main-loading-overlay" />}
      </Switch>
    </>
  );
}

export default EmployeeRouter;

const normalizeUrl = (arr, isApiUrl = true) => {
  const regex = isApiUrl ? /(\/{)([a-zA-Z]*)(})/g : /(\/:)([a-zA-Z]*)/g;
  const normalizeString = "/:params";
  return Array.isArray(arr)
    ? arr.map((array) => array.replaceAll(regex, normalizeString))
    : arr.replaceAll(regex, normalizeString);
};

const EmployeeContent = React.forwardRef((props, ref) => {
  let match = useRouteMatch();
  const selectAccessibleRoutes = createSelector(
    (state) => state.menu?.sideBar,
    (sideBar) => {
      const subMenus = sideBar
        ?.find((w) => w?.url === props.path.replace("/", ""))
        ?.subMenus?.filter((s) => s?.url !== undefined);

      const variables = [];
      JSON.stringify(subMenus, (key, val) => {
        if (key === "url") {
          variables.push(`/${val}`);
        }
        return val;
      });

      const urlArray = props.restrictedContent ? variables : [];

      return urlArray;
    }
  );
  const accessibleRoutes = useSelector(selectAccessibleRoutes);
  const defaultRoute = useSelector((state) => state.menu?.sideBar);
  const normalizeRoutes = normalizeUrl(accessibleRoutes);

  const dispatch = useDispatch();
  const global = useSelector((state) => state.global) || null;
  useEffect(() => {
    if (global?.layout !== props.defaultLayout) {
      dispatch(
        setGlobal({
          layout: props.defaultLayout,
        })
      );
    }
  });

  const routeConfig = (routes) => {
    const singleRoute = routes.length === 1 ? routes[0] : undefined;
    if (singleRoute) {
      return (
        <Route
          key={singleRoute?.id}
          path={singleRoute.path}
          exact={singleRoute?.exact}
          strict={singleRoute?.strict}
          component={singleRoute.component}
        />
      );
    } else if (accessibleRoutes && accessibleRoutes.length > 0) {
      const defaultFirstRoute = defaultRoute
        .filter((x) => "/" + x.url === match.path)[0]
        ?.subMenus.filter((x) => x.type === MENU_TYPE.MENU)[0]?.url;

      let isParentPathAuthorized = accessibleRoutes.includes(match.path);

      const authorizedPath = routes
        .filter((x) => normalizeRoutes.includes(normalizeUrl(x.path, false)))
        .map((route, index) => {
          return (
            <Route
              key={route?.id}
              path={route.path}
              exact={route?.exact}
              strict={route?.strict}
              component={route.component}
            />
          );
        });

      if (!isParentPathAuthorized && defaultFirstRoute) {
        return [
          <Route key="default-path" path={`${match.path}`} exact={true}>
            <Redirect to={defaultFirstRoute} />
          </Route>,
          ...authorizedPath,
        ];
      }
      return authorizedPath;
    }
  };
  return React.createElement(props.component, {
    routeConfig: (routes) => routeConfig(routes),
  });
});
