import "./claim-menu.scss";

import { TabMenu } from "primereact/tabmenu";
import { CLAIMS_MENU_TYPE_KEY } from "../../constants";

interface ClaimMenuProps {
  menu: any;
  currentPageKey?: string;
  redirectPage?: any;
  [key: string]: any;
}

export const ClaimMenu = ({ menu, currentPageKey }: ClaimMenuProps) => {
  if (!menu) return null;
  let selectedIndex = -1;

  const menuItems = menu
    .filter((_menu: any) => _menu.type === CLAIMS_MENU_TYPE_KEY.MENU)
    .map((_menu: any, i: number) => {
      if (_menu.key === currentPageKey) {
        selectedIndex = i;
      }
      return {
        ..._menu,
        label: _menu.name,
        url: selectedIndex === 0 ? _menu.url : `/${_menu.url}`,
        icon: `claim-menu-icon-${_menu.key.toLowerCase()}`,
      };
    });

  return (
    <div className="claim-menu">
      <TabMenu
        className="claim-tab-menu"
        model={menuItems}
        activeIndex={selectedIndex}
      />
    </div>
  );
};

export const ScrollToClaimsMenuActiveItem = () => {
  document
    .querySelector(".claim-menu .p-tabmenuitem.p-highlight")
    ?.scrollIntoView({
      behavior: "smooth",
      inline: "start",
      block: "nearest",
    });
};
