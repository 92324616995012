import "./base-control.scss";

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import ArrayControl from "./array-control/array-control";
import CheckboxControl from "./checkbox/checkbox-control";
import DateControl from "./date-control/date-control";
import DateRangeControl from "./date-range-control/date-range-control";
import InputControl from "./input-control/input-control";
import MultiSelectControl from "./multiselect-control/multiselect-control";
import NumberControl from "./number-control/number-control";
import PasswordControl from "./password-control/password-control";
import RadioControl from "./radio-control/radio-control";
import SelectControl from "./select-control/select-control";
import TextareaControl from "./textarea-control/textarea-control";
import ToggleControl from "./toggle-control/toggle-control";
import UploadControl from "./upload-control/upload-control";
import KendoEditorControl from "./kendo-editor-control/kendo-editor";
import AutoCompleteControl from "./auto-complete-control/auto-complete-control";
import moment from "moment";
import { useTranslation } from "react-i18next";

import { ChangePasswordService } from "./../../services/hrmnet-api";
// import { StorageSerive } from "./../../services/storageService/index";
import {
  FormatPasswordPolicy,
  PortalSystemErrorLink,
} from "./../../services/utils/index";
import ChipControl from "./chip-control/chip-control";
import TreeTableControl from "./tree-table-control/tree-table-control";
import DataTableControl from "./datatable-control/datatable-control";
import InputGroup from "./input-group/input-group";
import TextareaControl2 from "./textarea-control/textarea-control-2";
import DateControl2 from "./date-control/date-control-2";

// import { STORAGE_KEY } from "./../../constants/index";

export enum BCType {
  input = "input",
  phone = "phone",
  email = "email",
  textarea = "textarea",
  textarea2 = "textarea2",
  password = "password",

  number = "number",

  array = "array",

  select = "select",
  multiselect = "multiselect",
  radio = "radio",

  checkbox = "checkbox",
  toogle = "toogle",

  date = "date",
  date2 = "date2",
  daterange = "daterange",

  upload = "upload",
  kendorEditor = "kendor-editor",
  autocomplete = "auto-complete",
  chips = "chips",
  treetable = "tree-table",
  datatable = "data-table",
  inputgroup = "input-group",
  // "input",
  // "phone",
  // "email",
  // "textarea",
  // "password",
  // "code",

  // "number",

  // "array",

  // "select",
  // "multiselect",
  // "radio",

  // "checkbox",
  // "toogle",

  // "date",
  // "daterange"
}
export interface BCRules {
  /**
   * Name of rule
   */
  name: string;
  /**
   * Param of rule
   */
  param?: any;
  /**
   * Default error message for rule. Using stranlate using param
   */
  error?: string;
}
export interface BCValidateResult {
  /**
   * Control invalid
   */
  invalid: boolean;
  /**
   * Error message if control invalid
   */
  error?: any;
  /**
   * Detail rule invalid
   */
  ruleDetail?: BCRules;

  isRequired?: boolean;
}
export interface BCProps {
  /**
   * Unix id
   */
  id?: string;
  /**
   * Type of control
   */
  label?: string;
  /**
   * Class name of control
   */
  className?: string;
  /**
   * Control without value
   */
  noLabel?: boolean;

  /**
   * Control required
   */
  required?: boolean;

  /**
   * Function on change
   * @returns is change parent state
   */
  onChange?: (param: {
    controlState: BCValidateResult;
    value: any;
    valueStr: string;
    [key: string]: any;
  }) => boolean;

  /**
   * Function on update data truely
   * @returns is change parent state
   */
  onTrueUpdateValue?: (param: {
    controlState: BCValidateResult;
    value: any;
    valueStr: string;
    [key: string]: any;
  }) => boolean;

  /**
   * Place holder
   */
  placeholder?: string;
  /**
   * Place holder
   */
  placeholderStrict?: string;
  /**
   * Value of control
   */
  value?: any;
  /**
   * Value of control
   */
  ruleList?: BCRules[];
  /**
   * Other config available
   */
  config?: any;
  /**
   * From filter control
   */
  fromFilter?: boolean;
  /**
   * Type of control
   */
  type?: BCType;
  /**
   * First focus element
   */
  autoFocus?: boolean;
  /**
   * Custom component render
   */
  componentRender?: any;
  /**
   * control state
   */
  controlState?: BCValidateResult;
  /**
   * Hide mark * required
   */
  noRequiredLabel?: boolean;
  /**
   * Default value of control
   */
  defaultValue?: any;
  /**
   * Tooltip label
   */
  tooltip?: string;
  /**
   *
   */
  showPasswordHint?: boolean; //aw
  /**
   *
   */
  maxLength?: number; //aw
  /**
   *
   */
  isPasswordUpdate?: boolean; //aw

  onTouched?: () => any;

  hintBottom?: string;
  hintRight?: string;
  touched?: boolean;
  fromFrom?: boolean;
  formSubmited?: boolean;
  /**
   * Addtional props
   */
  // [key: string]: any;
}
export interface BCStates {
  /**
   * Controler touched
   */
  touched: boolean;
  /**
   * Data of control
   */
  value: any;
  /**
   * Display text of value
   */
  valueStr: string;
  /**
   * State of control
   */
  controlState: BCValidateResult;
  /**
   * Controler loading state
   */
  loading?: boolean;
  /**
   * Addtional state
   */
  [key: string]: any;
}
export const validateControl = (
  ruleList: BCRules[],
  value: any,
  t: any,
  type?: string
): BCValidateResult => {
  let controlInvalid = false;
  let result: BCValidateResult = {
    invalid: false,
  };
  if (!ruleList) {
    return result;
  }
  ruleList.forEach((rule) => {
    const ruleName = rule.name;
    const errorDefault = rule.error;
    const ruleParam = rule.param || "";
    if (controlInvalid) {
      return;
    }
    switch (ruleName) {
      case "required":
        if (type === "daterange") {
          if (
            !value ||
            !Array.isArray(value) ||
            value.length !== 2 ||
            !value[0] ||
            !value[1]
          ) {
            controlInvalid = true;
            result = {
              invalid: true,
              error: errorDefault || "base_control_required_field",
              isRequired: true,
              ruleDetail: rule,
            };
          }
        } else if (type === "multiselect") {
          if (!value || !Array.isArray(value) || value.length === 0) {
            controlInvalid = true;
            result = {
              invalid: true,
              error: errorDefault || "base_control_required_field",
              isRequired: true,
              ruleDetail: rule,
            };
          }
        } else if (type === "date") {
          if (!value) {
            controlInvalid = true;
            result = {
              invalid: true,
              error: errorDefault || "base_control_required_field",
              isRequired: true,
              ruleDetail: rule,
            };
          }
        } else if (type === "checkbox") {
          if (!value) {
            controlInvalid = true;
            result = {
              invalid: true,
              error: errorDefault || "base_control_required_field",
              isRequired: true,
              ruleDetail: rule,
            };
          }
        } else if (type === "select") {
          if (typeof value === "object") {
            const isValueObjEmpty =
              value &&
              Object.keys(value).length === 0 &&
              Object.getPrototypeOf(value) === Object.prototype;
            if (isValueObjEmpty) {
              controlInvalid = true;
              result = {
                invalid: true,
                error: errorDefault || "base_control_required_field",
                isRequired: true,
                ruleDetail: rule,
              };
            }
          } else {
            if (
              value === undefined ||
              value === null ||
              value?.toString().trim() === ""
            ) {
              controlInvalid = true;
              result = {
                invalid: true,
                error: errorDefault || "base_control_required_field",
                isRequired: true,
                ruleDetail: rule,
              };
            }
          }
        } else {
          if (
            value === undefined ||
            value === null ||
            value?.toString().trim() === ""
          ) {
            controlInvalid = true;
            result = {
              invalid: true,
              error: errorDefault || "base_control_required_field",
              isRequired: true,
              ruleDetail: rule,
            };
          }
        }
        break;
      case "pattern":
        if (Array.isArray(value)) {
          value.forEach((val) => {
            val = val ? val?.toString().trim() : null;
            var pattern = new RegExp(ruleParam);
            if (!!val && !pattern.test(val) && !result.invalid) {
              controlInvalid = true;
              result = {
                invalid: true,
                error: errorDefault || "base_control_pattern_not_match",
                ruleDetail: rule,
              };
            }
          });
        } else {
          value = value ? value?.toString().trim() : null;
          var pattern = new RegExp(ruleParam);
          if (!!value && !pattern.test(value)) {
            controlInvalid = true;
            result = {
              invalid: true,
              error: errorDefault || "base_control_pattern_not_match",
              ruleDetail: rule,
            };
          }
        }
        break;
      case "maxLength":
        value = value ? value.toString().trim() : "";
        if (value.length > ruleParam) {
          controlInvalid = true;
          result = {
            invalid: true,
            error:
              errorDefault ||
              t("base_control_max_length", {
                number: ruleParam,
              }),
            ruleDetail: rule,
          };
        }

        break;
      case "minLength":
        value = value ? value?.toString().trim() : "";
        if (value.length < ruleParam) {
          controlInvalid = true;
          result = {
            invalid: true,
            error:
              errorDefault ||
              t("base_control_min_length", {
                number: ruleParam,
              }),
            ruleDetail: rule,
          };
        }

        break;
      case "uniqueDate":
        let list = ruleParam.map((x: any) => moment(x).format("DD/MM/YYYY"));
        if (list.includes(moment(value).format("DD/MM/YYYY"))) {
          controlInvalid = true;
          result = {
            invalid: true,
            error:
              errorDefault ||
              t("base_control_date_taken", {
                number: ruleParam,
              }),
            ruleDetail: rule,
          };
        }
        break;
      default:
        break;
    }
  });
  return result;
};

export const isError = (state: any, props: any) => {
  return state.controlState.invalid && (state.touched || props.touched);
};

export const renderError = (state: any, props: any, t: any) => {
  return (
    <>
      {isError(state, props) ? (
        <small
          id={`${props.id}-error`}
          className="p-invalid p-d-block p-invalid-custom"
        >
          {/* {!state.controlState.isRequired ||
          !props.fromFrom ||
          props.formSubmited
            ? state.controlState.error
            : ""} */}
          {t(state.controlState.error)}
        </small>
      ) : null}
    </>
  );
};

const BaseControl: React.FC<BCProps> = (props) => {
  let id = props.id || "BaseControl_default_id";
  const [policy, setPolicy] = useState({
    maxLength: 0,
    passwordPolicyPattern: "",
  });
  const dispatch = useDispatch();
  let history = useHistory();
  // prepare state

  useEffect(() => {
    const getPasswordPolicyPattern = async () => {
      try {
        const res1 = await ChangePasswordService.changePasswordPasswordPolicy();
        if (res1 && res1.data) {
          const passwordPolicyPattern = await FormatPasswordPolicy(
            res1.data,
            dispatch
          );
          if (passwordPolicyPattern) {
            setPolicy(passwordPolicyPattern);
          }
        }
      } catch {
        history.push(PortalSystemErrorLink());
      }
    };
    if (props.showPasswordHint) {
      getPasswordPolicyPattern();
    }
  }, [dispatch, props.showPasswordHint, history]);

  const getControl = () => {
    let _props = { ...props };
    if (_props.config?.readOnly) {
      _props.placeholder = "";
    }
    if (_props.componentRender) {
      return <CommonControl className="common-control" {..._props} id={id} />;
    }
    switch (_props.type) {
      case BCType.select:
        return <SelectControl className="select-control" {..._props} id={id} />;
      case BCType.multiselect:
        return (
          <MultiSelectControl
            className="multiselect-control"
            {..._props}
            id={id}
          />
        );
      case BCType.checkbox:
        return (
          <CheckboxControl className="checkbox-control" {..._props} id={id} />
        );
      case BCType.toogle:
        return (
          <ToggleControl className="checkbox-control" {..._props} id={id} />
        );
      case BCType.number:
        return <NumberControl className="number-control" {..._props} id={id} />;
      case BCType.radio:
        return <RadioControl className="radio-control" {..._props} id={id} />;
      case BCType.input || BCType.phone || BCType.email:
        return <InputControl className="input-control" {..._props} id={id} />;
      case BCType.textarea:
        return (
          <TextareaControl className="textarea-control" {..._props} id={id} />
        );
      case BCType.textarea2:
        return (
          <TextareaControl2 className="textarea-control" {..._props} id={id} />
        );
      case BCType.password: {
        //aw
        let props = _props;
        if (policy) {
          if (props.showPasswordHint && policy.passwordPolicyPattern) {
            props = {
              ...props,
              ...policy,
            };
          } else if (
            (props.maxLength === null || props.maxLength === undefined) &&
            props.isPasswordUpdate
          ) {
            props = { ...props, maxLength: policy?.maxLength };
          }
        } //aw
        return (
          <PasswordControl className="password-control" {...props} id={id} />
        );
      }
      case BCType.daterange:
        return (
          <DateRangeControl
            className="date-range-control"
            {..._props}
            id={id}
          />
        );
      case BCType.date:
        return <DateControl className="date-control" {..._props} id={id} />;
      case BCType.date2:
        return <DateControl2 className="date-control" {..._props} id={id} />;
      case BCType.array:
        return <ArrayControl className="array-control" {..._props} id={id} />;
      case BCType.upload:
        return <UploadControl className="" {..._props} id={id} />;
      case BCType.kendorEditor:
        return <KendoEditorControl className="" {..._props} id={id} />;
      case BCType.autocomplete:
        return <AutoCompleteControl className="" {..._props} id={id} />;
      case BCType.chips:
        return <ChipControl className="" {..._props} id={id} />;
      case BCType.treetable:
        return <TreeTableControl className="" {..._props} id={id} />;
      case BCType.datatable:
        return <DataTableControl className="" {..._props} id={id} />;
      case BCType.inputgroup:
        return <InputGroup className="" {..._props} id={id} />;
      default:
        return <InputControl className="input-control" {..._props} id={id} />;
    }
  };

  return (
    <div
      className={`base-control ${
        props.config?.readOnly ? "base-control-readonly" : ""
      }`}
    >
      {getControl()}
    </div>
  );
};

export interface CommonControlProps extends BCProps {
  /**
   * Get value string fn
   */
  getValueStr?: (value: any) => string;
}

export interface CommonControlState extends BCStates {}

const CommonControl: React.FC<CommonControlProps> = (props, ref) => {
  const { t } = useTranslation();
  // extract props
  const ruleList = props.ruleList || [];
  if (props.required) {
    ruleList.push({
      name: "required",
    });
  }
  // State
  let initState: BCStates = {
    touched: false,
    value: props.value,
    valueStr: props.getValueStr
      ? props.getValueStr(props.value || "")
      : (props.value || "").toString(),
    controlState: {
      invalid: false,
    },
  };

  initState.controlState =
    props.controlState || validateControl(ruleList || [], initState.value, t);

  // prepare state
  const [state, setState] = useState(initState);
  useEffect(() => {}, [props]);

  const onFocus = () => {
    if (props.onTouched) {
      return props.onTouched();
    }
    if (!state.touched) {
      setState({
        ...state,
        touched: true,
      });
    }
  };
  /**
   * On change
   * @param {*} event
   */
  const onChange = (value: any, valueStr?: any, controlState?: any) => {
    let _valueStr =
      valueStr ||
      (props.getValueStr
        ? props.getValueStr(props.value || "")
        : (props.value || "").toString());
    let _controlState = controlState || validateControl(ruleList, value, t);

    let _state = {
      ...state,
      value,
      valueStr: _valueStr,
      controlState: _controlState,
    };
    if (props.onChange) {
      props.onChange({
        controlState: _state.controlState,
        value: _state.value,
        valueStr: _state.valueStr,
      });
    }
    if (props.onTrueUpdateValue) {
      props.onTrueUpdateValue({
        controlState: state.controlState,
        value: state.value,
        valueStr: state.valueStr,
      });
    }
    setState(_state);
  };
  const commonControl = () => {
    return (
      <>
        <div className={`p-field ${props.noLabel ? "no-label" : ""}`}>
          <label htmlFor={props.id}>
            {props.label}
            {props.required && !props.noRequiredLabel ? (
              <small className="required p-invalid">&nbsp;*</small>
            ) : null}
          </label>
          <div
            className={`p-inputgroup ${
              isError(state, props) ? "p-inputgroup-error" : ""
            }`}
          >
            {props.componentRender({
              ...props,
              onFocus: onFocus,
              onChange: onChange,
            })}
            {props.hintRight && (
              <span className={"control-hint-right"}>{props.hintRight}</span>
            )}
          </div>
          {props.hintBottom && (
            <div className={"control-hint-bottom"}>{props.hintBottom}</div>
          )}
          {renderError(state, props, t)}
        </div>
      </>
    );
  };
  return <>{commonControl()}</>;
};
export default BaseControl;
