import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { MODULE_CODE } from "./../../../../constants/index";
import { PortalLink } from "../../../../services/utils";

const ContentFooter = () => {
  const { t } = useTranslation();
  var year = moment().format("YYYY");
  return (
    <div className="content-footer">
      <div className="footer-copyright">
        {t("common_footer_copyright") +
          year +
          " " +
          // user?.entityDisplayName +
          "TRICOR" +
          " " +
          t("common_footer_rights")}
      </div>
      <div className="footer-terms">
        <Link
          className="footer-font"
          to={PortalLink(MODULE_CODE.TermCondition)}
        >
          {t("common_footer_terms")}
        </Link>
        {" | "}
        <Link
          className="footer-font"
          to={PortalLink(MODULE_CODE.PrivacyPolicy)}
        >
          {t("common_footer_privacyPolicy")}
        </Link>
      </div>
    </div>
  );
};

export default ContentFooter;
