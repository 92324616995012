import moment from "moment";
import {
  IRequestConfig,
  IRequestOptions,
  axios,
  getConfigs,
} from "../serviceOptions";

const basePath = "/claims";
const applications = "applications";

const getApplication = async (id: string, options: IRequestOptions = {}) => {
  const applicationsUrl = `${basePath}/ot/application/${id}`;

  const configs: IRequestConfig = getConfigs(
    "get",
    "application/json",
    applicationsUrl,
    options
  );

  return await axiosAsync(configs);
};

const getApprovalApplication = async (
  aid: string,
  options: IRequestOptions = {}
) => {
  const applicationsUrl = `${basePath}/ot/approval/application/${aid}`;

  const configs: IRequestConfig = getConfigs(
    "get",
    "application/json",
    applicationsUrl,
    options
  );

  return await axiosAsync(configs);
};

const getApplicationAsync = async (
  aid: string,
  options: IRequestOptions = {}
) => {
  const applicationsUrl = `${basePath}/ot/applicationasync/${aid}`;

  const configs: IRequestConfig = getConfigs(
    "get",
    "application/json",
    applicationsUrl,
    options
  );

  return await axiosAsync(configs);
};

const getApplicationHeaderbyRefNo = async (
  referenceNo: string,
  params?: any,
  options: IRequestOptions = {}
) => {
  const applicationsUrl = `${basePath}/ot/applicationheader/${referenceNo}`;

  const configs: IRequestConfig = getConfigs(
    "get",
    "application/json",
    applicationsUrl,
    options
  );
  configs.params = params;

  return await axiosAsync(configs);
};

const getApplicationDetailbyId = async (
  id: string,
  params?: any,
  options: IRequestOptions = {}
) => {
  const applicationsUrl = `${basePath}/ot/applicationdetail/${id}`;

  const configs: IRequestConfig = getConfigs(
    "get",
    "application/json",
    applicationsUrl,
    options
  );
  configs.params = params;

  return await axiosAsync(configs);
};

const getApplicationWorkflowbyId = async (
  id: string,
  params?: any,
  options: IRequestOptions = {}
) => {
  const applicationsUrl = `${basePath}/ot/applicationworkflow/${id}`;

  const configs: IRequestConfig = getConfigs(
    "get",
    "application/json",
    applicationsUrl,
    options
  );
  configs.params = params;

  return await axiosAsync(configs);
};

const getApplications = async (params?: any, options: IRequestOptions = {}) => {
  const applicationsUrl = `${basePath}/ot/applications`;

  const configs: IRequestConfig = getConfigs(
    "get",
    "application/json",
    applicationsUrl,
    options
  );
  configs.params = params;

  return await axiosAsync(configs);
};

const getApprovalApplications = async (
  params?: any,
  options: IRequestOptions = {}
) => {
  const applicationsUrl = `${basePath}/ot/approval/pending-approval`;

  const configs: IRequestConfig = getConfigs(
    "get",
    "application/json",
    applicationsUrl,
    options
  );
  configs.params = params;

  return await axiosAsync(configs);
};

const getDelegatedApplications = async (
  params?: any,
  options: IRequestOptions = {}
) => {
  const applicationsUrl = `${basePath}/ot/applications/delegation`;

  const configs: IRequestConfig = getConfigs(
    "get",
    "application/json",
    applicationsUrl,
    options
  );
  configs.params = params;

  return await axiosAsync(configs);
};

const getAllApplications = async (
  params?: any,
  options: IRequestOptions = {}
) => {
  const applicationsUrl = `${basePath}/ot/applications/hr`;

  const configs: IRequestConfig = getConfigs(
    "get",
    "application/json",
    applicationsUrl,
    options
  );
  configs.params = params;

  return await axiosAsync(configs);
};

const getDates = async (params?: any, options: IRequestOptions = {}) => {
  const applicationsUrl = `${basePath}/general/dates`;

  const configs: IRequestConfig = getConfigs(
    "get",
    "application/json",
    applicationsUrl,
    options
  );
  configs.params = params;

  return await axiosAsync(configs);
};

const getPeriods = async (options: IRequestOptions = {}) => {
  const applicationsUrl = `${basePath}/general/periods`;

  const configs: IRequestConfig = getConfigs(
    "get",
    "application/json",
    applicationsUrl,
    options
  );

  return await axiosAsync(configs);
};

export type IAcceptedApplicationTypes = "myapplication" | "delegated" | "hr";

const getFilterYears = async (
  options: IRequestOptions = {},
  type: IAcceptedApplicationTypes = "myapplication"
) => {
  const applicationsUrl = `${basePath}/general/years/${type}`;

  const configs: IRequestConfig = getConfigs(
    "get",
    "application/json",
    applicationsUrl,
    options
  );

  return await axiosAsync(configs);
};

const getApplicationsTabs = async (options: IRequestOptions = {}) => {
  const applicationsUrl = `${basePath}/general/applications/tabs`;

  const configs: IRequestConfig = getConfigs(
    "get",
    "application/json",
    applicationsUrl,
    options
  );

  return await axiosAsync(configs);
};

const getClaimTypes = async (params?: any, options: IRequestOptions = {}) => {
  const applicationsUrl = `${basePath}/ot/application/claim-types/`;

  const configs: IRequestConfig = getConfigs(
    "get",
    "application/json",
    applicationsUrl,
    options
  );

  configs.params = params;

  return await axiosAsync(configs);
};

const getClaimTypeInfoByEmployee = async (
  claimType: string,
  params?: any,
  options: IRequestOptions = {}
) => {
  const applicationsUrl = `${basePath}/ot/application/claim-types/${claimType}`;

  const configs: IRequestConfig = getConfigs(
    "get",
    "application/json",
    applicationsUrl,
    options
  );

  configs.params = params;

  return await axiosAsync(configs);
};

const getClaimTypeInfo = async (
  claimType: string,
  options: IRequestOptions = {}
) => {
  const applicationsUrl = `${basePath}/ot/application/claim-type/${claimType}`;

  const configs: IRequestConfig = getConfigs(
    "get",
    "application/json",
    applicationsUrl,
    options
  );

  return await axiosAsync(configs);
};

const getDelegationEmployees = async (options: IRequestOptions = {}) => {
  const applicationsUrl = `${basePath}/delegation/employees`;

  const configs: IRequestConfig = getConfigs(
    "get",
    "application/json",
    applicationsUrl,
    options
  );

  return await axiosAsync(configs);
};

const getDefaultStartTime = async (data: {}, options: IRequestOptions = {}) => {
  const applicationsUrl = `${basePath}/ot/application/start-time`;

  const configs: IRequestConfig = getConfigs(
    "post",
    "multipart/form-data",
    applicationsUrl,
    options
  );

  configs.data = buildFormData(data);

  return await axiosAsync(configs);
};

const calculateFields = async (data: {}, options: IRequestOptions = {}) => {
  const applicationsUrl = `${basePath}/ot/application/calculate`;

  const configs: IRequestConfig = getConfigs(
    "post",
    "multipart/form-data",
    applicationsUrl,
    options
  );

  configs.data = buildFormData(data);

  return await axiosAsync(configs);
};

const validate = async (
  data: {},
  id: string = "",
  options: IRequestOptions = {}
) => {
  const applicationsUrl = `${basePath}/ot/application/validate/${id}`;

  const configs: IRequestConfig = getConfigs(
    "post",
    "multipart/form-data",
    applicationsUrl,
    options
  );

  configs.data = buildFormData(data);

  return await axiosAsync(configs);
};

const submit = async (data: {}, options: IRequestOptions = {}) => {
  const applicationsUrl = `${basePath}/ot/application/submit`;

  const configs: IRequestConfig = getConfigs(
    "post",
    "multipart/form-data",
    applicationsUrl,
    options
  );

  configs.data = buildFormData(data);

  return await axiosAsync(configs);
};

const saveDraft = async (data: {}, options: IRequestOptions = {}) => {
  const applicationsUrl = `${basePath}/ot/application`;

  const configs: IRequestConfig = getConfigs(
    "post",
    "multipart/form-data",
    applicationsUrl,
    options
  );

  configs.data = buildFormData(data);

  return await axiosAsync(configs);
};

const updateDraft = async (
  id: string,
  data: {},
  options: IRequestOptions = {}
) => {
  const applicationsUrl = `${basePath}/ot/application/${id}`;

  const configs: IRequestConfig = getConfigs(
    "put",
    "multipart/form-data",
    applicationsUrl,
    options
  );

  configs.data = buildFormData(data);

  return await axiosAsync(configs);
};

const submitDraft = async (
  id: string,
  data: {},
  options: IRequestOptions = {}
) => {
  const applicationsUrl = `${basePath}/ot/application/${id}/submit`;

  const configs: IRequestConfig = getConfigs(
    "put",
    "multipart/form-data",
    applicationsUrl,
    options
  );

  configs.data = buildFormData(data);

  return await axiosAsync(configs);
};

const deleteDraft = async (id: string, options: IRequestOptions = {}) => {
  const applicationsUrl = `${basePath}/ot/application/${id}`;

  const configs: IRequestConfig = getConfigs(
    "delete",
    "application/json",
    applicationsUrl,
    options
  );

  return await axiosAsync(configs);
};

const cancel = async (id: string, data: {}, options: IRequestOptions = {}) => {
  const applicationsUrl = `${basePath}/ot/application/${id}/cancel`;

  const configs: IRequestConfig = getConfigs(
    "put",
    "application/json",
    applicationsUrl,
    options
  );

  configs.data = data;

  return await axiosAsync(configs);
};

const downloadAttachment = async (
  id: string,
  options: IRequestOptions = {}
) => {
  const applicationsUrl = `${basePath}/attachments/download`;

  const configs: IRequestConfig = getConfigs(
    "post",
    "application/json",
    applicationsUrl,
    options
  );

  configs.data = { id };

  return await axiosAsync(configs);
};

const axiosAsync = async (config: IRequestConfig) => {
  const promise = new Promise((resolve, reject) =>
    axios(config, resolve, reject)
  );

  return await promise;
};

const buildFormData = (data: {}) => {
  const formData = new FormData();

  Object.entries(data).forEach(([key, value]: [key: string, value: any]) => {
    if (value === null || value === undefined) return;

    if (Array.isArray(value)) {
      value.forEach((item) => {
        formData.append(key, item);
      });
      return;
    }

    if (value instanceof Date || moment.isMoment(value)) {
      formData.append(key, moment(value).format("YYYY-MM-DD HH:mm:ss"));
      return;
    }

    if (typeof value === "object") {
      parseFormObject(formData, value, key);
      return;
    }

    formData.append(key, value);
  });

  return formData;
};

const parseFormObject = (formData: FormData, item: object, index: string) => {
  Object.entries(item).forEach(([key, value]) => {
    const name = `${applications}[${index}].${key}`;

    if (!value) {
      return;
    }

    if (Array.isArray(value)) {
      value.forEach((item) => {
        formData.append(name, item);
      });
      return;
    }

    if (value instanceof Date || moment.isMoment(value)) {
      formData.append(name, moment(value).format("YYYY-MM-DD HH:mm:ss"));
    }

    formData.append(name, value);
  });
};

const claimsGetDelegationClaimTask: () => Promise<any> = async (
  options: IRequestOptions = {}
) => {
  const applicationsUrl = `${basePath}/delegation/tasks`;

  const configs: IRequestConfig = getConfigs(
    "get",
    "application/json",
    applicationsUrl,
    options
  );

  return await axiosAsync(configs);
};

const claimsGetDelegationOptions: (options?: IRequestOptions) => Promise<any> =
  async (options = {}) => {
    const applicationsUrl = `${basePath}/delegation/options`;

    const configs: IRequestConfig = getConfigs(
      "get",
      "application/json",
      applicationsUrl,
      options
    );

    return await axiosAsync(configs);
  };

const claimsGetDelegationCurrent: (options?: IRequestOptions) => Promise<any> =
  async (options = {}) => {
    const applicationsUrl = `${basePath}/delegation/current`;

    const configs: IRequestConfig = getConfigs(
      "get",
      "application/json",
      applicationsUrl,
      options
    );

    return await axiosAsync(configs);
  };

const claimsGetDelegationFuture: (options?: IRequestOptions) => Promise<any> =
  async (options = {}) => {
    const applicationsUrl = `${basePath}/delegation/future`;

    options.headers = {
      ...options.headers,
    };
    const configs: IRequestConfig = getConfigs(
      "get",
      "application/json",
      applicationsUrl,
      options
    );

    return await axiosAsync(configs);
  };

const claimsGetDelegationHistory: (options?: IRequestOptions) => Promise<any> =
  async (options = {}) => {
    const applicationsUrl = `${basePath}/delegation/history`;

    options.headers = {
      ...options.headers,
    };
    const configs: IRequestConfig = getConfigs(
      "get",
      "application/json",
      applicationsUrl,
      options
    );

    return await axiosAsync(configs);
  };

const claimsGetDelegationLog: (options?: IRequestOptions) => Promise<any> =
  async (options = {}) => {
    const applicationsUrl = `${basePath}/delegation/log`;

    options.headers = {
      ...options.headers,
    };
    const configs: IRequestConfig = getConfigs(
      "get",
      "application/json",
      applicationsUrl,
      options
    );

    return await axiosAsync(configs);
  };

interface IAddDelegationRequestBody {
  taskId: number;
  delegateFrom: string;
  delegateTo: string;
  effectiveFrom: Date;
  effectiveTo: Date;
}

const claimsAddDelegation: (
  params: { body?: IAddDelegationRequestBody },
  options?: IRequestOptions
) => Promise<any> = async (params = {}, options: IRequestOptions = {}) => {
  const applicationsUrl = `${basePath}/delegation/add`;

  options.headers = {
    ...options.headers,
  };

  const configs: IRequestConfig = getConfigs(
    "post",
    "application/json",
    applicationsUrl,
    options
  );
  let data = params.body;

  configs.data = data;

  return await axiosAsync(configs);
};

const claimsCancelDelegation: (
  params: {
    /**  */
    delegateid?: number;
  },
  options?: IRequestOptions
) => Promise<any> = async (params = {}, options = {}) => {
  const applicationsUrl = `${basePath}/delegation/cancel`;

  options.headers = {
    ...options.headers,
  };

  const configs: IRequestConfig = getConfigs(
    "post",
    "application/json",
    applicationsUrl,
    options
  );
  configs.params = { delegateid: params["delegateid"] };
  // let data = params.body;

  // configs.data = data;

  return await axiosAsync(configs);
};

const getApprovalPeriods = async (options: IRequestOptions = {}) => {
  const applicationsUrl = `${basePath}/ot/approval/periods`;

  const configs: IRequestConfig = getConfigs(
    "get",
    "application/json",
    applicationsUrl,
    options
  );

  return await axiosAsync(configs);
};

const ClaimService = {
  downloadAttachment,

  getDates,
  getPeriods,
  getApplication,
  getApplicationHeaderbyRefNo,
  getApplicationDetailbyId,
  getApprovalApplication,
  getApplicationAsync,
  getApplicationWorkflowbyId,
  getApplications,
  getDelegatedApplications,
  getAllApplications,
  getApplicationsTabs,
  getClaimTypes,
  getClaimTypeInfo,
  getClaimTypeInfoByEmployee,
  getDelegationEmployees,
  getDefaultStartTime,

  calculateFields,
  validate,
  saveDraft,
  updateDraft,
  deleteDraft,
  submitDraft,
  submit,
  cancel,

  claimsGetDelegationClaimTask,
  claimsGetDelegationHistory,
  claimsAddDelegation,
  claimsCancelDelegation,
  claimsGetDelegationCurrent,
  claimsGetDelegationFuture,
  claimsGetDelegationLog,
  claimsGetDelegationOptions,
  getApprovalApplications,
  getApprovalPeriods,

  getFilterYears,
};

export default ClaimService;
