import { BASIC_CONTROL_LAYOUT_CONFIG } from "../../../../leave/utils/config-helper";
import {
  EmployeeCodeField,
  SelectionPeriodField,
  UserSubmitField,
} from "./period-select-form-field-config";

const createColumn = (control: string, layoutConfig: any = {}) => ({
  control,
  config: {
    ...BASIC_CONTROL_LAYOUT_CONFIG,
    ...layoutConfig,
  },
});

export const getSelectPeriodFormControls = ({
  t,
  filterTimePeriods,
  delegationEmployees,
  userSubmit,
}: any) => {
  const delegationControls = !!delegationEmployees?.length
    ? [
        {
          ...EmployeeCodeField,
          enum: delegationEmployees,
          config: { disabled: !delegationEmployees?.length || userSubmit },
        },
        UserSubmitField,
      ]
    : [];

  const controls = [
    {
      ...SelectionPeriodField,
      enum: filterTimePeriods,
    },
    ...delegationControls,
  ];

  return controls
    .filter((control) => !!control)
    .map((control) => ({
      ...control,
      label: t(control!.label),
      ...("placeholder" in control!
        ? { placeholder: t(control.placeholder) }
        : {}),
    }));
};

export const getSelectPeriodFormLayout = () => ({
  rows: [
    {
      columns: [
        createColumn(EmployeeCodeField.key),
        createColumn(UserSubmitField.key, {
          className: `${BASIC_CONTROL_LAYOUT_CONFIG?.className} checkbox-custom`,
        }),
      ],
    },
    {
      columns: [createColumn(SelectionPeriodField.key)],
    },
  ],
});
