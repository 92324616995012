import "./sso-login.scss";

import { useCallback, useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";

//service
import AzureAuthenticationButton from "../../../../../../azure/azure-authentication-component";
import { AuthenticationService } from "../../../../../../services/hrmnet-api";

import { OnLogin } from "./../utils";
import { ActiveSessionDialog } from "./../../login-load-info";

//const
import {
  MESSAGE_SEVERITY,
  QUERY_PARAMS,
  RESPONSE_MESSAGE_TYPE,
  SSO_INTERACTION_TYPE,
  SSO_PROVIDER,
} from "./../../../../../../constants/index";
import {
  closeLoading,
  openLoading,
} from "../../../../../../redux/actions/modal";
import { store } from "../../../../../../redux/store";
import { MSAL_CONFIG } from "../../../../../../azure/azure-authentication-config";
import { Decrypt, Ecrypt } from "../../../../../../utils/crypto";
import { STORAGE_KEY } from "../../../../../../constants/index";

const SSOLogin = () => {
  const [errorMsg, setErrorMsg] = useState();
  let history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const clientInfo = useSelector((state) => state.global?.clientInfo);
  const interactionType = SSO_INTERACTION_TYPE.REDIRECT;

  const changeSession = useCallback(
    async (confirm, { idToken, setIsError }) => {
      if (confirm) {
        try {
          setLoading(true);
          const res =
            await AuthenticationService.authenticationAuthenticateBySsoToken({
              body: {
                token: idToken,
              },
            });

          if (res.messages.length > 0) {
            res.messages.forEach((message) => {
              if (
                message.type === RESPONSE_MESSAGE_TYPE.ERROR &&
                !!setIsError
              ) {
                setIsError(true);
              }
            });
          } else if (res?.data?.token) {
            console.log("set isOktaLogin");
            localStorage.setItem(STORAGE_KEY.IS_OKTA_LOGIN, "true");
            await OnLogin(res, history);
            return true;
          } else if (!!setIsError) {
            setIsError(true);
          }
        } catch (error) {
          if (!!setIsError) {
            setIsError(true);
          }
        } finally {
          setLoading(false);
        }
      }
    },
    []
  );

  //azure
  const onAuthenticated = async (userAccountInfo, idToken, setIsError) => {
    if (!userAccountInfo.error && !isLoggedIn) {
      await ActiveSessionDialog(store, t, history, changeSession, {
        idToken,
        setIsError,
      });
    } else if (userAccountInfo.error?.errorCode) {
      setErrorMsg("sso_error_" + userAccountInfo.error?.errorCode);
    }
  };

  const renderAzure = () => {
    return (
      <>
        <AzureAuthenticationButton
          msalConfig={{
            ...MSAL_CONFIG,
            auth: {
              clientId: clientInfo?.appClientId,
              redirectUri: clientInfo?.redirectUrl,
            },
          }}
          interactionType={interactionType}
          onAuthenticated={onAuthenticated}
        />
        <small
          id={`sso-error`}
          className="p-invalid p-d-block p-invalid-custom"
        >
          {t(errorMsg)}
        </small>
      </>
    );
  };

  const renderOkta = (oktaEmbeddedUri = "") => {
    //okta pop up
    var windowObjectReference = null; // global variable
    var PreviousUrl; // global variable that will store the url currently in the secondary window

    function openRequestedSinglePopup(url) {
      if (windowObjectReference == null || windowObjectReference.closed) {
        windowObjectReference = window.open(
          url,
          "OktaPopUp",
          "resizable,scrollbars,status"
        );
      } else if (PreviousUrl !== url) {
        windowObjectReference = window.open(
          url,
          "OktaPopUp",
          "resizable=yes,scrollbars=yes,status=yes"
        );
        /* if the resource to load is different,
                   then we load it in the already opened secondary window and then
                   we bring such window back on top/in front of its parent window. */
        windowObjectReference.focus();
      } else {
        windowObjectReference.focus();
      }

      PreviousUrl = url;
      /* explanation: we store the current url in order to compare url
               in the event of another call of this function. */
    }

    return (
      <a
        className="p-button p-component authentication-button"
        href={oktaEmbeddedUri}
        target="OktaPopUp"
        onClick={() => {
          openRequestedSinglePopup(oktaEmbeddedUri);
          return false;
        }}
      >
        <span className="p-button-label p-c">{t("login_login")}</span>
      </a>
    );
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const adfsUrl = window.SSO_APP_URL;
    const errorParam = urlParams.get(QUERY_PARAMS.ADFS_ERROR);
    const tokenParam = urlParams.get(QUERY_PARAMS.SSO_ADFS_TOKEN);
    if (!!errorParam && adfsUrl) {
      samlAuth(adfsUrl, undefined, errorParam);
    }

    if (!!tokenParam && adfsUrl) {
      samlAuth(adfsUrl, tokenParam);
    }
  }, []);

  async function samlAuth(url, token = undefined, error = undefined) {
    window.history.pushState(null, null, window.location.pathname);
    if (!!error) {
      const idx = uuidv4();
      let alert = store.getState().global.alert;
      let decryptObject = Decrypt(
        Buffer.from(error, "base64").toString("ascii"),
        true
      );

      // add failed message when sso-login failing
      // add encrypted mail content when user clicked on the support email address

      if (!!clientInfo?.signInSupportEmail) {
        let mailContent = {
          clientInfo: clientInfo,
        };
        let encryptedMailContent = Ecrypt(JSON.stringify(mailContent));
        let content = `<p class='hris-custom-message'>Login failed</p>Please contact the <a href='mailto: ${clientInfo?.signInSupportEmail}?body=${encryptedMailContent}'> support email </a> for help.`;
        alert.clear();

        if (!!decryptObject && !!decryptObject?.traceId) {
          mailContent = {
            traceId: decryptObject.traceId,
            clientInfo: clientInfo,
          };
          encryptedMailContent = Ecrypt(JSON.stringify(mailContent));
          let message = ` ${decryptObject.message} Please contact the`;
          content = `<p class='hris-custom-message'>Login failed.</p> ${message.trim()} <a href='mailto: ${
            clientInfo?.signInSupportEmail
          }?subject=$[SSO]{decryptObject.traceId}&body=${encryptedMailContent}'> support email </a> for help.`;
        }

        alert.show({
          severity: MESSAGE_SEVERITY.ERROR,
          detail: (
            <div
              id={`message-switch-error-${idx}`}
              className="hris-message-error"
              dangerouslySetInnerHTML={{
                __html: content,
              }}
            />
          ),
          life: 6000,
          sticky: true,
        });
      }

      return;
    }

    if (!!token) {
      onAuthenticated({}, token);
      return;
    }

    const tenantName = store.getState().global?.clientInfo?.tenantCode;
    const entityName = store.getState().global?.clientInfo?.entityCode;
    if (!!entityName) {
      window.location.replace(`${url}/login/${tenantName}/${entityName}`);
    } else {
      window.location.replace(`${url}/login/${tenantName}`);
    }
    return;
  }

  function setLoading(isLoading) {
    if (isLoading) {
      dispatch(openLoading());
      return;
    }
    dispatch(closeLoading());
  }

  const renderAdfs = (url) => {
    return (
      <a
        className="p-button p-component authentication-button"
        onClick={() => {
          samlAuth(url);
          return false;
        }}
      >
        <span className="p-button-label p-c">{t("login_login")}</span>
      </a>
    );
  };

  const renderBody = () => {
    var oktaEmbeddedUri = window.OKTA_EMBEDDED_URI;

    switch (clientInfo?.ssoProvider) {
      case SSO_PROVIDER.HRIS_Node_Passport:
        return renderAdfs(window.SSO_APP_URL);
      case SSO_PROVIDER.MS_Azure:
        return renderAzure();
      case SSO_PROVIDER.Okta:
        return renderAdfs(window.SSO_APP_URL);
      default:
        return <></>;
    }
  };

  return <>{renderBody()}</>;
};

export default SSOLogin;
