// import "./employee-data-amendment.scss";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { resetBcDynamicPaths } from "../../../../redux/actions/breadcrumb";
import {
  resetMainForm,
  resetMainFormConfig,
  resetMainFormValue,
} from "../../../../redux/actions/form";
import EmployeeDetailsForm from "./employee-details-form";

const EmployeeDetails = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const employeeCode = props.match.params.employeeCode;
  const [state, setState] = useState({
    isLoading: true,
    form: {},
    formName: t("User_Management_Employee_Details_Formname"),
    isListLoaded: false,
  });

  const selectedLangKey = useSelector(
    (state) => state.language.language?.selectedLang?.key
  );
  const form = useSelector((state) => state.commonForm.form);
  const formLanguage = useSelector((state) => state.commonForm.formLanguage);

  useEffect(() => {
    const isLangChange = formLanguage != selectedLangKey;

    return () => {
      if (!isLangChange) {
        dispatch(resetMainFormConfig());
        dispatch(resetMainForm());
        dispatch(resetMainFormValue());
      }

      setState({
        ...state,
        isLoading: true,
        isListLoaded: false,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLangKey]);

  // Breadcrumb
  useEffect(() => {
    dispatch(resetBcDynamicPaths([{ label: state.formName || "" }]));
  }, [dispatch, t, state.formName]);

  // Rendering
  const renderFormTitle = () => (
    <div className="form-title">
      <h2>{state.formName}</h2>
    </div>
  );

  return (
    <>
      <div className="multimenu-style-tab">
        <div className="header header-sticky">
          {/* Title */}
          {renderFormTitle()}
        </div>
        <div className={`company-document-configuration-select-form`}>
          <EmployeeDetailsForm
            isLangChange={formLanguage != selectedLangKey}
            employeeCode={employeeCode}
          />
        </div>
      </div>
    </>
  );
};

export default EmployeeDetails;
