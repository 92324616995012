import moment from "moment";
import { Button } from "primereact/button";
import {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import CheckboxControl from "src/components/base-control/checkbox/checkbox-control";
import LanguageLoader from "src/components/language-loader/language-loader";
import {
  CLAIM_SUBMODULE_CODE,
  EEPORTAL_LABEL_BASE_TABLE,
  FUNCTION_CODE,
} from "src/constants";
import { showSpinner } from "src/redux/actions/spinner";
import ClaimService from "src/services/hrmnet-api/claims";
import { PortalLink } from "src/services/utils";
import { showTimestampToastSuccess } from "src/services/utils/message";
import { isResponseOk } from "src/utils/utils";
import ConfirmButton from "../../components/claim-footer-action/confirm-button";
import ClaimFormItemWrapper from "../../components/claim-form-item-wrapper";
import { HeaderMenu } from "../../components/header-menu";
import {
  CLAIMS_MENU_MODULE_KEY,
  CLAIMS_MENU_PAGE_KEY,
  CLAIMS_T_CONTEXT_KEY,
  CLAIM_STORE_ACTION,
  INPUT_TIME_FORMAT,
  LONG_DATE_FORMAT,
} from "../../constants";
import { FORM_CONTROL_DISPLAY_MODE } from "../../constants/layout";
import { ClaimContext } from "../../context/claim-context-provider";
import { useLeaveWithoutSaveAlert } from "../../hooks/useLeaveWithoutSaveAlert";
import { ToFormsRequest } from "../../utils/form-to-request";
import { GetDisplayUom } from "../../utils/ui-helper";
import "./review.scss";

const Header = memo(() => {
  const { t } = useTranslation();
  return (
    <div className="header">
      <HeaderMenu
        menuKey={CLAIMS_MENU_MODULE_KEY}
        pageKey={CLAIMS_MENU_PAGE_KEY.SUBMIT_CLAIM_APPLICATION}
      />

      <div className="title">{t("claims_submit_review_title")}</div>
    </div>
  );
});

const Details = ({ itemKeys, setItemKeys }: any) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { state } = useContext(ClaimContext);
  const { forms } = state;

  const isChecked = useMemo(() => {
    const formsUUId = forms.map((form: any) => form.uuid);

    return (
      itemKeys.length === formsUUId.length &&
      itemKeys.every((key: string) => formsUUId.includes(key))
    );
  }, [itemKeys, forms]);

  const selectAllLabel = useMemo(() => {
    return isChecked
      ? t("claims_submit_review_deselect")
      : t("claims_submit_review_select");
  }, [isChecked, t]);

  const onSelect = useCallback(
    (e: any) => {
      const formsUUId = forms.map((form: any) => form.uuid);

      if (e.value) {
        setItemKeys(formsUUId);
      } else {
        setItemKeys([]);
      }
      return true;
    },
    [forms, setItemKeys]
  );

  useEffect(() => {
    if (forms.length === 0) {
      history.push(
        PortalLink(
          `${FUNCTION_CODE.Claims}/${CLAIM_SUBMODULE_CODE.SUBMIT_APPLICATION}`
        )
      );
    }
  }, [forms.length, history]);

  return useMemo(() => {
    return (
      <>
        <CheckboxControl
          label={selectAllLabel}
          value={isChecked}
          onChange={onSelect}
        />
        {forms.map((form: any) => (
          <ReviewForm
            key={form.uuid}
            form={form}
            itemKeys={itemKeys}
            setItemKeys={setItemKeys}
          />
        ))}
      </>
    );
  }, [forms, isChecked, itemKeys, onSelect, selectAllLabel, setItemKeys]);
};

const ReviewForm = ({ form, itemKeys, setItemKeys }: any) => {
  const { t } = useTranslation();
  const { dispatch } = useContext(ClaimContext);

  useLeaveWithoutSaveAlert({
    allowRoutes: [],
    customRules: {
      [`/${FUNCTION_CODE.Claims}/${CLAIM_SUBMODULE_CODE.SUBMIT_APPLICATION}`]: (
        location: any
      ) => location.state?.uuid,
    },
  });

  const history = useHistory();

  const onEdit = useCallback(() => {
    dispatch({
      type: CLAIM_STORE_ACTION.FORM_UPDATE,
      payload: form,
    });
    history.push(
      PortalLink(
        `${FUNCTION_CODE.Claims}/${CLAIM_SUBMODULE_CODE.SUBMIT_APPLICATION}`
      ),
      { uuid: form.uuid }
    );
    // setEditing(false);
  }, [dispatch, form, history]);

  const onSelect = useCallback(
    (e: any) => {
      if (e.value) {
        setItemKeys([...itemKeys, form.uuid]);
      } else {
        const keys = itemKeys.filter((key: string) => key !== form.uuid);
        setItemKeys(keys);
      }
      return true;
    },
    [form.uuid, itemKeys, setItemKeys]
  );

  const onDelete = useCallback(() => {
    dispatch({
      type: CLAIM_STORE_ACTION.FORM_REMOVE,
      payload: form.uuid,
    });
  }, [dispatch, form.uuid]);

  const isChecked = useMemo(() => {
    return itemKeys.includes(form.uuid);
  }, [itemKeys, form.uuid]);

  return useMemo(() => {
    return (
      <div className="main">
        <div className="p-grid">
          <div className="p-col-12 p-md-6 p-lg-8">
            <CheckboxControl
              label={t("claims_submit_review_confirm")}
              value={isChecked}
              onChange={onSelect}
            />
          </div>

          <div className="p-col-12 p-md-6 p-lg-4 p-text-lg-right">
            <Button className="p-button-outlined secondary" onClick={onEdit}>
              {t("claims_submit_review_edit")}
            </Button>

            <ConfirmButton
              text={t("claims_submit_review_delete")}
              className="p-ml-2"
              title={t("claims_submit_review_sure")}
              content={t("claims_submit_review_delete_claim")}
              primaryText={t("claims_common_actionConfirm")}
              primaryAction={onDelete}
              outline={true}
            />
          </div>
        </div>

        <div className="item-header">
          {t("claims_submit_review_claim_title")}
          {form.index}
        </div>

        <div className="p-fluid p-formgrid p-grid p-my-2">
          <div className="p-field p-col-12 p-md-6">
            <label>{t("claims_form_claimType")}</label>
            <div>{form.itemDescription}</div>
          </div>

          <ClaimFormItemWrapper displayMode={form.transDateDisplayMode}>
            <div className="p-field p-col-12 p-md-6">
              <label>{t("claims_form_startDate")}</label>
              <div>{moment(form.fromDate).format(LONG_DATE_FORMAT)}</div>
            </div>
          </ClaimFormItemWrapper>

          <ClaimFormItemWrapper
            displayMode={form.transDateDisplayMode}
            condition={[FORM_CONTROL_DISPLAY_MODE.FROM]}
          >
            <div className="p-field p-col-12 p-md-6">
              <label>{t("claims_form_endDate")}</label>
              <div>{moment(form.toDate).format(LONG_DATE_FORMAT)}</div>
            </div>
          </ClaimFormItemWrapper>

          <ClaimFormItemWrapper displayMode={form.transTimeDisplayMode}>
            <div className="p-field p-col-12 p-md-6">
              <label>{t("claims_form_startTime")}</label>
              <div>{moment(form.fromTime).format(INPUT_TIME_FORMAT)}</div>
            </div>

            <div className="p-field p-col-12 p-md-6">
              <label>{t("claims_form_endTime")}</label>
              <div>{moment(form.toTime).format(INPUT_TIME_FORMAT)}</div>
            </div>
          </ClaimFormItemWrapper>

          <ClaimFormItemWrapper displayMode={form.appliedUnitDisplayMode}>
            <div className="p-field p-col-12 p-md-6">
              <label>{t("claims_form_unit")}</label>
              <div>
                {form?.units} {GetDisplayUom(form?.uom, t)}
              </div>
            </div>
          </ClaimFormItemWrapper>

          <ClaimFormItemWrapper displayMode={form.appliedAmountDisplayMode}>
            <div className="p-field p-col-12 p-md-6">
              <label>{t("claims_form_amount")}</label>
              <div>${(Math.round(form.amounts * 100) / 100).toFixed(2)}</div>
            </div>
          </ClaimFormItemWrapper>
          {form.remarks && (
            <div className="p-field p-col-12 p-md-6">
              <label>{t("claims_form_remarks")}</label>
              <div>{form.remarks}</div>
            </div>
          )}
        </div>
      </div>
    );
  }, [form, isChecked, onDelete, onEdit, onSelect, t]);
};

const Footer = ({ itemKeys }: any) => {
  const { state, dispatch: contextDispatch } = useContext(ClaimContext);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const { forms } = state;

  const backToList = () => {
    history.push(PortalLink(`${FUNCTION_CODE.Claims}`));
  };

  const actionWrapper = (action: Promise<any>, message: string) => {
    dispatch(showSpinner(true));
    action
      .then((response) => {
        if (isResponseOk(response)) {
          showTimestampToastSuccess({ message, t } as any);
          backToList();
          contextDispatch({
            type: CLAIM_STORE_ACTION.FORMS_RESET,
          });
        }
      })
      .finally(() => dispatch(showSpinner(false)));
  };

  const saveSelected = () => {
    const selectedForms = forms.filter((form: any) =>
      itemKeys.includes(form.uuid)
    );

    actionWrapper(
      ClaimService.saveDraft(ToFormsRequest(selectedForms)),
      t("claims_submit_saveSuccessMessage")
    );
  };

  const saveAll = () =>
    actionWrapper(
      ClaimService.saveDraft(ToFormsRequest(forms)),
      t("claims_submit_saveSuccessMessage")
    );

  const submit = async () => {
    const selectedForms = forms.filter((form: any) =>
      itemKeys.includes(form.uuid)
    );

    actionWrapper(
      ClaimService.submit(ToFormsRequest(selectedForms)),
      t("claims_submit_submitSuccessMessage")
    );
  };

  const selected = itemKeys?.length > 0 ? `(${itemKeys.length})` : "";

  return (
    <div className="footer p-grid p-align-center p-justify-between">
      <div className="left-button-group">
        <ConfirmButton
          text={`${t("claims_common_actionSaveSelected")} ${selected}`}
          className="save-button"
          title={t("claims_confirm_title")}
          content={t("claims_submit_confirmSaveMessage")}
          primaryText={t("claims_common_actionConfirm")}
          primaryAction={saveSelected}
          disabled={itemKeys?.length === 0}
          outline={true}
        />
      </div>

      <div className="right-button-group">
        <ConfirmButton
          text={t("claims_common_actionSaveAll")}
          className="p-button primary submit-button"
          title={t("claims_confirm_title")}
          content={t("claims_submit_confirmSaveMessage")}
          primaryText={t("claims_common_actionConfirm")}
          primaryAction={saveAll}
          outline={true}
        />
        <ConfirmButton
          text={`${t("claims_common_actionSubmitSelected")} ${selected}`}
          className="p-button primary submit-button"
          title={t("claims_confirm_title")}
          content={t("claims_submit_confirmSubmitMessage")}
          primaryText={t("claims_submit_confirmSubmitButton")}
          primaryAction={submit}
          disabled={itemKeys?.length === 0}
          outline={false}
        />
      </div>
    </div>
  );
};

const Review = () => {
  const [itemKeys, setItemKeys] = useState([]);
  return (
    <>
      <LanguageLoader
        contexts={[
          EEPORTAL_LABEL_BASE_TABLE.BASE_TABLE,
          CLAIMS_T_CONTEXT_KEY.COMMON,
          CLAIMS_T_CONTEXT_KEY.SUBMIT_APPLICATION,
          CLAIMS_T_CONTEXT_KEY.FORM,
        ]}
      />
      <div className="claim-container claim-application">
        <Header />

        <Details itemKeys={itemKeys} setItemKeys={setItemKeys} />

        <Footer itemKeys={itemKeys} />
      </div>
    </>
  );
};

export default Review;
