import { v4 as uuidv4 } from "uuid";
import { CLAIM_STORE_ACTION } from "../constants";
import moment from "moment";

export const initialState: any = {
  form: { fromDate: moment() },
  forms: [],
  delegationEmployees: [],
  disabled: false,
  hasEdit: false,
};

const ClaimReducer = (state: any, action: any) => {
  switch (action.type) {
    case CLAIM_STORE_ACTION.FORM_UPDATE:
      return {
        ...state,
        form: {
          ...state.form,
          ...action.payload,
        },
      };

    case CLAIM_STORE_ACTION.FORM_EDIT:
      return {
        ...state,
        hasEdit: true,
      };

    case CLAIM_STORE_ACTION.FORM_RESET:
      return { ...state, form: initialState.form };

    case CLAIM_STORE_ACTION.FORM_RESET_WITH_PREV:
      return { ...state, form: { ...initialState.form, ...action.payload } };

    case CLAIM_STORE_ACTION.FORMS_RESET:
      return { ...state, forms: initialState.forms };

    case CLAIM_STORE_ACTION.FORMS_ADD:
      if (state.form.uuid) {
        const index = state.forms
          .map((form: any) => form.uuid)
          .indexOf(state.form.uuid);

        state.forms.splice(index, 1, state.form);

        return {
          ...state,
          forms: [...state.forms],
        };
      }

      return {
        ...state,
        forms: [
          ...state.forms,
          {
            ...state.form,
            uuid: uuidv4(),
            index: (state.forms?.length ?? 0) + 1,
          },
        ],
      };

    case CLAIM_STORE_ACTION.FORM_REMOVE: {
      return {
        ...state,
        forms: state.forms
          .filter((form: any) => form.uuid !== action.payload)
          .map((form: any, index: number) => ({
            ...form,
            index: index + 1,
          })),
      };
    }

    case CLAIM_STORE_ACTION.FORM_ACTION_DISABLE:
      return { ...state, disabled: true };

    case CLAIM_STORE_ACTION.FORM_ACTION_ENABLE:
      return { ...state, disabled: false };

    case CLAIM_STORE_ACTION.DELEGATION_EMPLOYEES:
      return { ...state, delegationEmployees: action.payload };
    default:
      throw new Error();
  }
};

export { ClaimReducer };
