import { Button } from "primereact/button";
import { Card } from "primereact/card";
import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
import { Animated } from "react-animated-css";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { FileUpload } from "primereact/fileupload";
import {
  closeLoading,
  openLoading,
  openModal,
} from "../../../redux/actions/modal";
import "./../../../components/base-table/base-table.scss";
import "./admin.scss";
import { useTranslation } from "react-i18next";

/**
 * Render Default Container with header and description
 */
const AdminContainer = (props) => {
  return (
    <>
      <Card className="base-container" id={props.id}>
        <div className={`bt-table bt-table-custom`}>
          <div className="bt-header">
            <div className="bt-header-toolbar">
              <div className="bt-title-group">
                <div className="bt-title">{props.title}</div>
                <div className="bt-desc">{props.description}</div>
                {props?.toolbar}
              </div>
            </div>
          </div>
          {props.children}
        </div>
      </Card>
    </>
  );
};

export const SaveAsExcelFile = (buffer, fileName) => {
  import("file-saver").then((FileSaver) => {
    let EXCEL_TYPE =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    let EXCEL_EXTENSION = ".xlsx";
    const data = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    FileSaver.saveAs(
      data,
      fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
    );
  });
};

export const ExportExcel = (file) => {
  SaveAsExcelFile(file, file.name);
};

export const AdminUploader = React.forwardRef((props, ref) => {
  // init state control
  let initState = {
    controlState: {
      invalid: false,
    },
  };
  const [state, setState] = useState(initState);
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [error, setError] = useState();
  const [message, setMessage] = useState();
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const fileRefs = useRef([]);
  const fileUploadRef = useRef(null);
  const { t } = useTranslation();

  const INVALID_FILE_FORMAT = t(
    "employee_configuration_message_invalidFileFormat"
  );

  useImperativeHandle(ref, () => ({
    clear() {
      fileUploadRef.current.clear();
      setDisableSubmit(true);
      setLoading(false);
    },
    setError(res) {
      setLoading(false);
      setError(res);
    },
    setMessage(res) {
      setLoading(false);
      setMessage(res);
    },
    setLoading(res) {
      setLoading(res);
    },
  }));

  //ensure only ONE file is selected
  //when file is of INVALID_FILE_FORMAT, forcefully remove latter file
  useEffect(() => {
    if (fileRefs.current.length > 1 && error === INVALID_FILE_FORMAT) {
      fileRefs.current[1].onRemove();
    }
  }, [error]);

  //footer layout : submit && cancel
  const renderFooter = () => {
    return (
      <div className="footer p-grid p-align-center p-justify-between">
        <div />
        <Button
          onClick={() => {
            //disable file uploader
            setLoading(true);
            onSubmitDocument(props.submitAction);
          }}
          className="p-button next-button"
          disabled={disableSubmit}
        >
          {t("employee_configuration_footer_submit")}
        </Button>
      </div>
    );
  };

  //confirmation on uploading modal
  const onSubmitDocument = async (submitAction) => {
    dispatch(
      openModal({
        classNameMainDialog: "confirm-message-modal",
        primaryButtonClickFn: async ({ closeFn }) => {
          closeFn();
          if (submitAction) {
            //clear previous error or message
            setError();
            setMessage();

            //proceed with loading
            dispatch(openLoading());
            await submitAction(fileUploadRef.current.files[0]);
            dispatch(closeLoading());
          }
        },
        secondButtonClickFn: ({ closeFn }) => {
          //disable file uploader
          setLoading(false);
          closeFn();
        },
        ...props.config.modal,
      })
    );
  };

  //header template : cancel and choose button
  const headerTemplate = (options) => {
    const { className, chooseButton, cancelButton } = options;

    return (
      <div
        className={className}
        style={{
          backgroundColor: "transparent",
          display: "flex",
          alignItems: "center",
        }}
      >
        {chooseButton}
        {cancelButton}
      </div>
    );
  };

  const emptyTemplate = () => {
    return (
      <div className="p-d-flex p-ai-center p-dir-col">
        <span
          style={{ fontSize: "1.2em", color: "var(--text-color-secondary)" }}
          className="p-my-5"
        >
          {t("employee_configuration_uploader_content")}
        </span>
      </div>
    );
  };

  const itemTemplate = (file, props) => {
    return (
      <>
        <div className="p-d-flex p-flex-wrap file-uploader-items">
          <div
            className="p-d-flex p-ai-center support-document-item-template"
            style={{ width: "50%" }}
          >
            <span className="p-d-flex p-dir-col p-text-left p-ml-3">
              {file.name}
              <small>{new Date().toLocaleDateString()}</small>
            </span>
          </div>
          <Button
            ref={() => fileRefs.current.push(props)}
            type="button"
            icon="pi pi-file-excel"
            className="p-button-outlined p-button-rounded p-button-danger p-ml-auto"
            onClick={() => ExportExcel(file)}
          />
        </div>
      </>
    );
  };

  const onError = (e) => {};

  const onSelect = (e) => {
    const file = e.files[0];

    //checking file format
    if (
      file &&
      file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      //enable submit
      setDisableSubmit(false);

      //replace file
      if (state.value) {
        fileRefs.current[0].onRemove();
        setMessage();
        setError();
      }

      //update file
      let newState = state;
      newState.value = file;
      setState(state);
    } else {
      setError(INVALID_FILE_FORMAT);
      setMessage();
    }
  };

  const onClear = () => {
    setDisableSubmit(true);
    setState(initState);
    setMessage();
    setError();
  };

  return (
    <>
      <AdminContainer {...props.config?.container}>
        <Animated
          animationIn="slideInRight"
          animationOut="slideOutRight"
          animationInDuration={200}
          animationOutDuration={200}
          isVisible={true}
        >
          <div className="file-uploader-container">
            <FileUpload
              ref={fileUploadRef}
              headerTemplate={headerTemplate}
              itemTemplate={itemTemplate}
              emptyTemplate={emptyTemplate}
              headerClassName="file-upload__header"
              className="support-file-upload"
              accept=".xlsx, application / msexcel *"
              onError={onError}
              onSelect={onSelect}
              onClear={onClear}
              disabled={loading}
              {...props.config?.fileUpload}
            />
          </div>
        </Animated>
        {Array.isArray(error) ? (
          error.map((errorMessage) => (
            <small
              id={`support-document-file-upload-error`}
              className="p-invalid p-d-block p-invalid-custom"
            >
              {errorMessage}
            </small>
          ))
        ) : (
          <small
            id={`support-document-file-upload-error`}
            className="p-invalid p-d-block p-invalid-custom"
          >
            {error}
          </small>
        )}
        <small
          id={`support-document-file-upload-message`}
          className="p-d-block"
        >
          {message}
        </small>
        {renderFooter()}
      </AdminContainer>
    </>
  );
});

export default AdminUploader;
