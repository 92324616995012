import React from "react";
import BaseForm from "../../../../../components/base-form/base-form";
import { ICreateApplicationFormData } from "../../constants";
import { useFormContext } from "./context/useFormContext";

type ICreateApplicationForm<T> = () => React.ReactElement;

export const CreateApplicationForm: ICreateApplicationForm<ICreateApplicationFormData> =
  () => {
    const { formProps, formControls, formLayout } = useFormContext();
    return (
      <div className="main timesheet-application-form">
        {formControls?.length && formLayout && <BaseForm {...formProps} />}
      </div>
    );
  };
