import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Header } from "../../components";
import { ToolBar } from "./table-tool-bar";
import { SelectControlOption } from "../../../../../components/base-control/select-control/select-control";
import LanguageLoader from "../../../../../components/language-loader/language-loader";
import { EEPORTAL_LABEL_BASE_TABLE } from "../../../../../constants";
import {
  IGetApprovalApplicationData,
  TIMESHEET_CONTEXT_KEY,
  TIMESHEET_MENU_PAGE_KEY,
} from "../../constants";
import { TableFilterStatuses } from "./table-filter-config";
import TimesheetService from "../../../../../services/hrmnet-api/timesheet";
import { Table } from "./table";
import "./approval.scss";

const Main = () => {
  const { t } = useTranslation();
  const tableRef = useRef();
  const [filteredStatus, setFilteredStatus] = useState<
    SelectControlOption["value"]
  >(TableFilterStatuses[0].value.toString());
  const filterStatuses = TableFilterStatuses.map((status) => ({
    ...status,
    value: status.value.toString(),
    label: t(status.label),
  }));
  const [filteredYear, setFilteredYear] =
    useState<SelectControlOption["value"]>("");
  const [filterYears, setFilterYears] = useState<SelectControlOption[]>([
    { label: "", value: "" },
  ]);

  useEffect(() => {
    (async () => {
      const response = await TimesheetService.getYearlyPeriods();
      setFilteredYear(
        response.data?.find((item: any) => !!item)?.value.toString() ||
          filteredYear
      );
      setFilterYears(
        response.data?.map((item: any) => ({
          label: item.name,
          value: item.value,
        }))
      );
    })();
  }, []);

  return (
    <>
      <Table
        toolbar={
          <ToolBar
            filterYears={filterYears}
            filteredYear={filteredYear}
            setFilteredYear={setFilteredYear}
            filteredStatus={filteredStatus}
            filterStatuses={filterStatuses}
            setFilteredStatus={setFilteredStatus}
          />
        }
        filteredStatus={filteredStatus}
        filteredYear={filteredYear}
        tableRef={tableRef}
      />
    </>
  );
};

const TimesheetApprovalList = () => {
  return (
    <>
      <LanguageLoader
        contexts={[
          EEPORTAL_LABEL_BASE_TABLE.BASE_TABLE,
          TIMESHEET_CONTEXT_KEY.COMMON,
          TIMESHEET_CONTEXT_KEY.APPROVAL,
        ]}
      />
      <div className="timesheet-container timesheet-approval-list">
        <Header
          headerTitleKey={TIMESHEET_MENU_PAGE_KEY.APPROVAL_CORNER}
          headerDescKey="eTimesheet_Timesheet_Approval_Description"
          WithHeaderDropdown
        />

        <Main />
      </div>
    </>
  );
};

export default TimesheetApprovalList;
