import "./index.scss";

//components
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { resetBcDynamicPaths } from "../../../../../redux/actions/breadcrumb";
import LanguageLoader from "../../../../../components/language-loader/language-loader";
import {
  EEPORTAL_LABEL_BASE_TABLE,
  RESPONSE_MESSAGE_ERROR,
} from "../../../../../constants";
import { useTranslation } from "react-i18next";
import Button from "../../../rental/components/action-button";
import { Animated } from "react-animated-css";
import { useWindowSize } from "../../../utils/window-size/useWindowSize";
import { Dropdown } from "primereact/dropdown";
import { TabMenu } from "primereact/tabmenu";
import TimesheetService from "../../../../../services/hrmnet-api/timesheet";
import {
  DEFAULT_BT_SEARCH,
  getBTConfig,
} from "./../../../../../components/base-table/base-table-model";
import {
  DATE_FORMAT,
  TEST_DELEGATE_TYPE as DELEGATE_TYPE,
  getColumns,
  getDelegationFormConfigStepOne,
  getDelegationFormConfigStepTwo,
} from "./config";
import { closeModal, openModal } from "../../../../../redux/actions/modal";
import { isResponseOk } from "../../../../../utils/utils";
import { showSpinner } from "../../../../../redux/actions/spinner";
import BaseTable from "../../../../../components/base-table/base-table-portal-rental";
import moment from "moment";
import { showTimestampToastSuccess } from "../../../../../services/utils/message";
import { Skeleton } from "primereact/skeleton";
import axios from "axios";
import {
  TIMESHEET_MENU_MODULE_KEY,
  TIMESHEET_MENU_PAGE_KEY,
  TIMESHEET_CONTEXT_KEY,
} from "../../constants";
import {
  HeaderDropdown,
  ScrollTotimesheetMenuActiveItem,
  TimesheetMenu,
} from "../../components";
import { ISideBarMenu } from "../../../../../redux/reducers/menu";

// TEST_DELEGATE_TYPE

const Delegation = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isMobile } = useWindowSize();
  let history = useHistory();
  const menus = useSelector((state: any) => state.menu.sideBar);
  const timesheetMenu = menus.find(
    (x: any) => x.key === TIMESHEET_MENU_MODULE_KEY
  )?.subMenus;

  const sideBarMenus = useSelector(
    (state: any) => state.menu.sideBar as ISideBarMenu[]
  );

  useEffect(() => {
    dispatch(resetBcDynamicPaths());
  }, [dispatch]);

  const [tabIndex, setTabIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingOptions, setIsLoadionOptions] = useState(true);
  const [delegationTaskList, setDelegationTaskList] = useState([]);
  // map includes options for apply and approve leave
  const [delegateeOptions, setDelegateeOptions] = useState([]);
  const [controller, setController] = useState(axios.CancelToken.source());
  const tableRef = useRef([]);

  const selectedLangKey = useSelector(
    (state: any) => state.language.language?.selectedLang?.key
  );

  const delegationTabList = [
    {
      label: t("timesheet_delegation_currentTab"),
      value: 0,
      showTableAction: true,
    },
    {
      label: t("timesheet_delegation_futureTab"),
      value: 1,
      showTableAction: true,
    },
    {
      label: t("timesheet_delegation_historyTab"),
      value: 2,
      showTableAction: false,
    },
    // {
    //   label: t("timesheet_delegation_log"),
    //   value: 2,
    // },
  ];

  // Init
  useEffect(() => {
    const getTaskList = async () => {
      // TODO: change to TIMESHEETervice
      const res = await TimesheetService.timesheetGetDelegationLeaveTask();
      if (isResponseOk(res) && res.data) {
        const tasks = res.data
          /*.filter((task) => task.value !== "1")*/ // Custom logic, remove "Apply Leave"
          .map((task: any) => ({
            label: task.name,
            value: task.value,
          }));
        setDelegationTaskList(tasks);
      }
    };
    const getEmployeeList = async () => {
      const res = await TimesheetService.timesheetGetDelegationOptions();
      if (isResponseOk(res) && res.data) {
        let options: any = {};
        res.data.forEach((data: any) => {
          if (data.delegateType in DELEGATE_TYPE) {
            options[(DELEGATE_TYPE as any)[data.delegateType]] =
              data.employees.map((option: any) => ({
                label: option.name,
                value: option.value,
              }));
          }
        });
        setDelegateeOptions(options);
      }
    };
    const initOptions = async () => {
      try {
        setIsLoadionOptions(true);
        await getEmployeeList();
        await getTaskList();
      } catch (error) {
      } finally {
        setIsLoadionOptions(false);
      }
    };
    initOptions();
    ScrollTotimesheetMenuActiveItem();
  }, [selectedLangKey]);

  useEffect(() => {
    if (!isLoadingOptions) {
      setTimeout(() => {
        setIsLoading(false);
      }, 100);
    }

    return () => {
      closeModal();
      controller?.cancel(RESPONSE_MESSAGE_ERROR.CANCEL);
      setIsLoading(true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabIndex]);

  async function loadData(index: number) {
    setController(axios.CancelToken.source());
    let datas = [];
    try {
      let res = null;
      const requestConfig: any = {
        cancelToken: controller.token,
      };
      switch (index) {
        case 0: // Current Tab
          res = await TimesheetService.timesheetGetDelegationCurrent(
            requestConfig
          );

          break;
        case 1: // future Tab
          res = await TimesheetService.timesheetGetDelegationFuture(
            requestConfig
          );
          break;
        case 2: // History Tab
          res = await TimesheetService.timesheetGetDelegationHistory(
            requestConfig
          );
          break;
        case 99: // Log Tab
          res = await TimesheetService.timesheetGetDelegationLog(requestConfig);
          break;
        default:
          break;
      }
      if (res?.data) {
        datas = res?.data;
      }
    } catch (error) {
    } finally {
      return {
        datas: datas,
        searchConfig: DEFAULT_BT_SEARCH,
        total: datas?.length,
      };
    }
  }

  function configModel(showTableAction: any) {
    return getBTConfig({
      columns: [
        ...getColumns({
          showAction: showTableAction,
          t,
        }),
      ],
      hasIndex: false,
      mode: "list",
      defaultMode: "list",
      showGlobal: false,
      hasColumnSelector: false,
      // onRowClick: (e) => redirect({ applicationId: e.data.id }),
      rowHover: true,
      responsive: true,
      actionWidth: 5.3,
      actionsSingle: showTableAction
        ? [
            {
              isOverflow: true,
              name: "cancel",
              title: t("timesheet_delegation_cancelDelegation"),
              clickFn: (data) => {
                openCancelDelegationModal(data.delegateid);
              },
            },
          ]
        : undefined,
    });
  }

  // Action
  const openAddNewDelegationModal = () => {
    populateAddNewDelegationModal(null);
  };

  const populateAddNewDelegationModal = (form: any, validate = false) => {
    let _config;
    if (form?.["taskId"] == null) {
      _config = getDelegationFormConfigStepOne({
        taskOptions: delegationTaskList,
        t,
      });
      form = {};
    } else {
      _config = getDelegationFormConfigStepTwo({
        form: form,
        employeeOptions: delegateeOptions[form["taskId"]] || {},
        taskOptions: delegationTaskList,
        t,
      });
    }
    dispatch(
      openModal({
        title: t("timesheet_delegation_addNewDelegation"),
        classNameMainDialog: "confirm-message-modal form-modal",
        primaryButtonText: t("leave_common_actionSubmit"),
        form: {
          form: {
            ...form,
          },
          touched: validate ? true : false,
          config: _config,
          alwaysAllowSubmit: true,
          onChange: (change: any) =>
            populateAddNewDelegationModal(change.state.form, validate || false),
        },
        alwaysAllowSubmit: true,
        primaryButtonClickFn: async ({ closeFn, form, formState }: any) => {
          if (formState.invalid) {
            populateAddNewDelegationModal(form, true);
            return;
          }
          addNewDelegation({ closeFn, form });
        },
        secondButtonClickFn: ({ closeFn }: any) => {
          closeFn();
        },
      })
    );
  };

  const addNewDelegation = async ({ closeFn, form }: any) => {
    try {
      dispatch(showSpinner(true));
      // prepare payload
      const payload = {
        taskId: form.taskId,
        delegateTo: form.delegateTo,
        effectiveFrom: moment(form.effectiveFrom).format(DATE_FORMAT),
        effectiveTo: moment(form.effectiveTo).format(DATE_FORMAT),
      };
      const res = await TimesheetService.timesheetAddDelegation({
        body: payload as any,
      });
      if (isResponseOk(res)) {
        showTimestampToastSuccess({
          message: t("timesheet_delegation_addSuccess"),
          t: t,
        } as any);
        closeFn();
        if (tableRef.current[tabIndex]) {
          (tableRef.current[tabIndex] as any).reload(tabIndex);
        }
      }
    } catch (e) {
    } finally {
      dispatch(showSpinner(false));
    }
  };

  const openCancelDelegationModal = (delegateId: any) => {
    dispatch(
      openModal({
        title: t("timesheet_delegation_cancelDelegation"),
        classNameMainDialog: "confirm-message-modal",
        content: t("timesheet_delegation_cancelDelegationMessage"),
        primaryButtonClickFn: async ({ closeFn }: any) => {
          closeFn();
          cancelDelegation(delegateId);
        },
        secondButtonClickFn: ({ closeFn }: any) => {
          closeFn();
        },
      })
    );
  };

  const cancelDelegation = async (delegateId: any) => {
    try {
      dispatch(showSpinner(true));
      const res = await TimesheetService.timesheetCancelDelegation({
        delegateid: delegateId,
      });
      if (isResponseOk(res)) {
        showTimestampToastSuccess({
          message: t("timesheet_delegation_cancelSuccess"),
          t: t,
        } as any);
        if (tableRef.current[tabIndex]) {
          (tableRef.current[tabIndex] as any).reload(tabIndex);
        }
      }
    } catch (e) {
    } finally {
      dispatch(showSpinner(false));
    }
  };

  // render
  const renderHeader = () => {
    return (
      <div className="header">
        {/* <Header
          headerTitleKey={TIMESHEET_MENU_PAGE_KEY.TIMESHEET_PERIOD}
          headerDescKey={TIMESHEET_MENU_PAGE_KEY.TIMESHEET_PERIOD}
          WithHeaderDropdown
        /> */}
        <TimesheetMenu
          menu={timesheetMenu}
          currentPageKey={TIMESHEET_MENU_PAGE_KEY.DELEGATION}
        />
        <div className="title">
          {t("timesheet_delegation_title")}
          <div className="title-desc">&nbsp;</div>
        </div>
        {isLoadingOptions ? (
          <Skeleton height={"45px"} width={"200px"} />
        ) : (
          <div className="header-button-group">
            <Button className="p-button" onClick={openAddNewDelegationModal}>
              {t("timesheet_delegation_addNewDelegation")}
            </Button>
            <HeaderDropdown
              sideBarMenus={sideBarMenus}
              history={history}
              t={t}
            />
          </div>
        )}
      </div>
    );
  };

  const renderTabSelect = () => {
    return (
      <div className="delegation-tab">
        <Animated
          animationIn="slideInRight"
          animationOut="slideOutRight"
          animationInDuration={200}
          animationOutDuration={200}
          isVisible={true}
        >
          {isMobile ? (
            <div className="dropdown-wrapper">
              <Dropdown
                value={delegationTabList[tabIndex].value}
                options={delegationTabList}
                onChange={(e) => {
                  setTabIndex(e.value);
                }}
              />
            </div>
          ) : (
            <TabMenu
              model={delegationTabList}
              activeIndex={tabIndex}
              onTabChange={(e) => {
                setTabIndex(e.index);
              }}
            />
          )}
        </Animated>
      </div>
    );
  };

  const renderMain = () => {
    if (isLoading) {
      return (
        <div className="main" style={{ padding: "1rem" }}>
          <Skeleton height={"30px"} className="p-mb-2" />
          <Skeleton height={"30px"} className="p-mb-2" />
          <Skeleton height={"30px"} />
        </div>
      );
    }

    const tableProps = {
      id: "delegation-table",
      ref: (el: any) => ((tableRef.current[tabIndex] as any) = el),
      isClientSize: true,
      configModel: configModel(delegationTabList?.[tabIndex]?.showTableAction),
      searchConfig: DEFAULT_BT_SEARCH,
      searchFn: () => loadData(tabIndex),
      disableMobile: true,
    };

    return (
      <Animated
        animationIn="slideInRight"
        animationOut="slideOutRight"
        animationInDuration={200}
        animationOutDuration={200}
        isVisible={true}
      >
        <div className="main">
          <BaseTable {...tableProps} />
        </div>
      </Animated>
    );
  };

  const renderFooter = () => {
    if (isLoadingOptions) return;

    return (
      <div className="footer p-grid p-align-center p-justify-between">
        <div className="button-group-1">
          <Button
            onClick={() => (history as any).goBack()}
            className="p-button-outlined secondary"
          >
            {t("leave_common_actionBack")}
          </Button>
        </div>
      </div>
    );
  };

  return (
    <>
      <LanguageLoader
        contexts={[
          EEPORTAL_LABEL_BASE_TABLE.BASE_TABLE,
          TIMESHEET_CONTEXT_KEY.COMMON,
          TIMESHEET_CONTEXT_KEY.DELEGATION,
        ]}
      />

      <div className="timesheet-container timesheet-delegation">
        {/* Header */}
        {renderHeader()}

        {/* Tab */}
        {renderTabSelect()}

        {/* Main */}
        {renderMain()}

        {/* Footer */}
        {renderFooter()}
      </div>
    </>
  );
};

export default Delegation;
