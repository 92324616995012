import { TFunction } from "react-i18next";
import { BASIC_CONTROL_LAYOUT_CONFIG } from "../../../leave/utils/config-helper";
import {
  ICostCenterFormData,
  TimesheetCostCenterConfigurationModel,
} from "../types/types";
import {
  CostCenterFormConfigFieldKey,
  costCenterFormConfigFieldList,
} from "./cost-center-form-field-config";
import { ISelectOptionData } from "../../../timesheet/constants";

export const costCenterFormInitValue: ICostCenterFormData = {
  employeeCode: "",
  costCenter: "",
};

const createColumn = (control: string, layoutConfig: any = {}) => ({
  control,
  config: {
    ...BASIC_CONTROL_LAYOUT_CONFIG,
    ...layoutConfig,
  },
});

export const getCostCenterFormControls = ({
  t,
  costCenterList,
  employeeList,
}: {
  t: TFunction<"translation">;
  costCenterList: ISelectOptionData[];
  employeeList: ISelectOptionData[];
}) => {
  return costCenterFormConfigFieldList.map((control) => {
    return {
      ...control,
      label: `${t(control.label)}`,
      ...(control.key === CostCenterFormConfigFieldKey.costCenter
        ? { enum: costCenterList }
        : {}),
      ...(control.key === CostCenterFormConfigFieldKey.employeeCode
        ? { enum: employeeList }
        : {}),
      ...("placeholder" in control
        ? { placeholder: t(control.placeholder as string) }
        : {}),
    };
  });
};

export const getCostCenterFormLayout = () => {
  return {
    rows: [
      {
        columns: costCenterFormConfigFieldList.map((control) =>
          createColumn(control.key)
        ),
      },
    ],
  };
};

export const parseCostCenterFormIntoRequestInput = ({
  form,
}: {
  form: any;
}): TimesheetCostCenterConfigurationModel => {
  return {
    EmployeeCode: form.employeeCode,
    CostCenter: form.costCenter,
  };
};
