export const BASIC_CONTROL_LAYOUT_CONFIG = {
  className: "p-col-12 p-lg-6 p-sm-12",
};

export enum FORM_CONTROL_DISPLAY_MODE {
  NA = "na",
  HIDE = "hide",
  FROM = "from",
  BOTH = "both",
  DISPLAY = "display",
  REQUIRED = "required",
  OPTIONAL = "optional",
}
