import { useSelector } from "react-redux";
import { ISideBarMenu } from "../../../../../redux/reducers/menu";
import { HeaderDropdown } from "../header-dropdown";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

export const Header = ({
  headerTitleKey,
  headerDescKey,
  WithHeaderDropdown = false,
}: {
  headerTitleKey: string;
  headerDescKey?: string;
  WithHeaderDropdown?: boolean;
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const sideBarMenus = useSelector(
    (state: any) => state.menu.sideBar as ISideBarMenu[]
  );

  return (
    <div className="header">
      {/* TODO: change to source */}
      <div className="title">
        {t(headerTitleKey)}
        <div className="title-desc">
          {headerDescKey ? t(headerDescKey) : ""}
        </div>
      </div>

      {WithHeaderDropdown && (
        <HeaderDropdown sideBarMenus={sideBarMenus} history={history} t={t} />
      )}
    </div>
  );
};
