import React from "react";
import { useTimesheetConfigurationContext } from "./context/useTimesheetConfigurationContext";
import { TIMESHEET_CONFIGURATION_MENU_Tab_KEY } from "./constants";
import { CalendarPanel } from "./component/calendar-panel";
import { FormSkeleton } from "../../timesheet/components";
import { CostCenterPanel } from "./component/cost-center-panel";
import { ActivityPanel } from "./component/activity-panel";

export const TabContent: React.FC = () => {
  const { isLoading, selectedTab } = useTimesheetConfigurationContext();

  return (
    <div className="tab-content-container">
      <div className="title">
        {selectedTab?.name}
        <div className="title-desc">{selectedTab?.description}</div>
      </div>

      <div className="tab-content">
        {isLoading && <FormSkeleton column={2} />}

        {!isLoading &&
          selectedTab?.key ===
            TIMESHEET_CONFIGURATION_MENU_Tab_KEY.TIMESHEET_ACTIVITY_CONFIGURATION && (
            <ActivityPanel />
          )}
        {!isLoading &&
          selectedTab?.key ===
            TIMESHEET_CONFIGURATION_MENU_Tab_KEY.TIMESHEET_CALENDAR_CONFIGURATION && (
            <CalendarPanel />
          )}
        {!isLoading &&
          selectedTab?.key ===
            TIMESHEET_CONFIGURATION_MENU_Tab_KEY.TIMESHEET_COST_CENTER_CONFIGURATION && (
            <CostCenterPanel />
          )}
      </div>
    </div>
  );
};
