import { TFunction } from "react-i18next";
import {
  getBTConfig,
  getColumModel,
} from "src/components/base-table/base-table-model";
import { CLAIM_SUBMODULE_CODE, FUNCTION_CODE } from "src/constants";
import { PortalLink } from "src/services/utils";
import { ApprovalListColumns } from "./table-column-config";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";

const renderConfigColumns = ({
  t,
  isSelectable,
}: {
  t: TFunction;
  isSelectable: boolean;
}) => {
  return [
    getColumModel({
      header: "",
      key: "head-column-dummy",
      dataType: "string",
      frozen: true,
      sortable: false,
      width: 0.1,
      hideInMobile: true,
    }),
    ...ApprovalListColumns,
  ]
    .filter((column) => (!isSelectable ? !!column.header : true))
    .map((column) => ({
      ...column,
      header: t(`${column.header}`),
    }));
};

export const ListConfigModel = ({
  t,
  isSelectable,
  redirect,
  confirm,
  toolbar,
  setSelectedData,
  expandedRows,
  setExpandedRows,
}: {
  t: TFunction;
  isSelectable: boolean;
  redirect?: Function;
  confirm?: Function;
  toolbar?: JSX.Element;
  setSelectedData?: any;
  expandedRows?: any;
  setExpandedRows?: any;
}) => {
  return getBTConfig({
    columns: renderConfigColumns({ t, isSelectable }),
    hasIndex: false,
    mode: "list",
    defaultMode: "list",
    showGlobal: false,
    hasColumnSelector: false,
    rowHover: true,
    responsive: true,
    onRowClick: (e: any) => {
      if (!e.data.id) return false;

      const path = `${FUNCTION_CODE.Claims}/${CLAIM_SUBMODULE_CODE.APPROVAL}/${e.data.id}`;
      redirect && redirect(PortalLink(path));
    },
    hideItemSelectedToolbar: true,
    actionsMulti: isSelectable ? [{}] : undefined,
    onSelectionChangeGetSelected: (data: any) => setSelectedData(data),
    customToolbar: toolbar,
    isExpandable: true,
    isExpandInMobileOnly: true,
    expandedRows: expandedRows,
    onRowToggle: (e: any) => {
      setExpandedRows(e.data);
    },
    rowExpansionTemplate: (data: any) => {
      console.log(data);
      const renderTableCell = (rowData: any, column: any) => {
        if (column.render) {
          return <div className="bt-cell-render">{column.render(rowData)}</div>;
        } else {
          return rowData[column.key];
        }
      };

      const columnsRender: any[] = [];
      ApprovalListColumns.forEach((column, index) => {
        const columnConfig = {
          key: `bt-column-${index}`,
          field: column.key,
          header: column.header,
          className: `bt-${column.key}`,
          body: (rowData: any, config: any) => {
            return (
              <div className={`bt-row bt-row-${config.rowIndex} bt-cell`}>
                <span className="p-column-title mobile-p-column-title">
                  {t(column.header as any)}:
                </span>
                <div className="bt-cell-value">
                  {renderTableCell(rowData, column)}
                </div>
              </div>
            );
          },
        };
        columnsRender.push(<Column {...columnConfig} />);
      });

      return (
        <DataTable
          key="info-table"
          value={[data]}
          className="p-datatable-responsive table-expandable-header leave-application-list-mobile"
        >
          {columnsRender}
        </DataTable>
      );
    },
  } as any);
};
