import { Popup } from "@progress/kendo-react-popup";
import { SchedulerItem } from "@progress/kendo-react-scheduler";
import moment from "moment";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import {
  ApplicationContext,
  LoadingContext,
  ModalContext,
} from "../../context";
import { ApplicationAction } from "../../context/action/application";

import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { useDispatch } from "react-redux";
import { openModal } from "../../../../../redux/actions/modal";
import TimesheetService from "../../../../../services/hrmnet-api/timesheet";
import { isResponseOk } from "../../../../../utils/utils";
import Close from "../../../../icon/close";
import { TIMESHEET_ACTIVITY_STATUS } from "../../constants";
import { TIMESHEET_DETAIL_TYPE } from "../../constants/type";
import { ModalAction } from "../../context/action/modal";
import "./activity.scss";
import { LoadingAction } from "../../context/action/loading";
import { useTimesheetDetailContext } from "../../modules/timesheet-detail/context/useTimesheetDetailContext";

const TimesheetSchedulerActivity = (props: any) => {
  const ref = useRef(null);
  const [showPopup, setShowPopup] = useState(false);
  const type = props?.dataItem?.type;
  const hasConflict = props?.dataItem?.hasConflict;
  const [comment, setComment] = useState(props?.dataItem?.aprvRemark || "");
  const { onFocus } = props;
  const [{ application, mode }, dispatch] = useContext(ApplicationContext);
  const [_, dispatchLoadingAction] = useContext(LoadingContext);
  const { fetchData, disableScroll, enableScroll } =
    useTimesheetDetailContext();
  const mainDispatch = useDispatch();
  const { t } = useTranslation();
  const [, modalDispatch] = useContext(ModalContext);
  const [offsetTop, setOffsetTop] = useState(0);

  // Row height = CustomViewItem height 90 + border top and bottom 2
  const rowHeight = 92;

  const showFullDayBtn = document.getElementsByClassName(
    "k-button k-button-md k-button-rectangle k-button-solid k-button-solid-base k-rounded-md"
  );
  showFullDayBtn?.[0].addEventListener("click", () => {
    setTimeout(() => calculateOffset(), 100);
  });

  const calculateOffset = useCallback(() => {
    setOffsetTop(
      props.timetableDates
        .map((date: any) => date.value)
        .findIndex((value: string) => {
          return value === props?.dataItem?.resourceDate;
        }) * rowHeight
    );
  }, [props?.dataItem?.resourceDate, props.timetableDates]);

  useEffect(() => {
    if (props.data) {
      setTimeout(() => calculateOffset(), 100);
    }
  }, [
    props.data,
    props.dataItem,
    props.dataItem.actualStart,
    props.timetableDates,
    props.showWorkHours,
    calculateOffset,
  ]);

  useEffect(() => {
    setOffsetTop(
      document.getElementById(`timesheet-block-${props.dataItem.order}`)
        ?.offsetTop || 0
    );
  }, [props.dataItem.order]);

  const setLoading = useCallback(
    (isLoading: boolean) => {
      dispatchLoadingAction({
        type: LoadingAction.SET_LOADING,
        payload: isLoading,
      });
    },
    [dispatchLoadingAction]
  );

  const handleFocus = useCallback(
    (event) => {
      setShowPopup(true);
      disableScroll();

      if (onFocus) {
        onFocus(event);
      }
    },
    [disableScroll, onFocus]
  );

  const onRejectButtonClick = useCallback(async () => {
    setLoading(true);
    const response = await TimesheetService.rejectActivity({
      data: {
        id: props.dataItem.id,
        comment,
      },
    });

    if (isResponseOk(response)) {
      await fetchData();
    }
    setShowPopup(false);
    enableScroll();
    setLoading(false);
  }, [comment, enableScroll, fetchData, props.dataItem.id, setLoading]);

  const onApproveButtonClick = useCallback(async () => {
    setLoading(true);
    const response = await TimesheetService.approveActivity({
      data: {
        id: props.dataItem.id,
        comment,
      },
    });

    if (isResponseOk(response)) {
      await fetchData();
    }
    setShowPopup(false);
    enableScroll();
    setLoading(false);
  }, [comment, enableScroll, fetchData, props.dataItem.id, setLoading]);

  const onResetButtonClick = useCallback(async () => {
    setLoading(true);
    const response = await TimesheetService.resetActivity({
      data: {
        id: props.dataItem.id,
        comment,
      },
    });

    if (isResponseOk(response)) {
      await fetchData();
    }
    setShowPopup(false);
    enableScroll();
    setLoading(false);
  }, [comment, enableScroll, fetchData, props.dataItem.id, setLoading]);

  const onEditButtonClick = useCallback(() => {
    const activity: any = application.activities
      // deep clone
      .map((o) => ({
        ...o,
        isSameDay: moment(moment(o.fromDate).format("YYYY-MM-DD")).isSame(
          moment(moment(o.toDate).format("YYYY-MM-DD"))
        ),
      }))
      .find((act: any) =>
        !!act.id
          ? act.id === props.dataItem.id
          : act.uuid === props.dataItem.uuid
      );

    modalDispatch({
      type: ModalAction.SET_ACTIVITY_MODAL_DETAILS,
      payload: {
        activityModalDetails: {
          periodId: application.periodId as number,
          period: application.period?.desc as string,
          isFromView: true,
          isEdit:
            mode === TIMESHEET_DETAIL_TYPE.EDIT ||
            mode === TIMESHEET_DETAIL_TYPE.AMEND ||
            mode === TIMESHEET_DETAIL_TYPE.HR_AMEND,
          editActivity: activity,
        },
      },
    });
    modalDispatch({
      type: ModalAction.SET_IS_SHOW_ACTIVITY_MODAL,
      payload: true,
    });
    enableScroll();
  }, [
    application,
    enableScroll,
    modalDispatch,
    mode,
    props.dataItem.id,
    props.dataItem.uuid,
  ]);

  const onDelete = useCallback(() => {
    mainDispatch(
      openModal({
        title: t("timesheet_common_actionConfirmation"),
        content: t("timesheet_common_messageDeleteConfirmation"),
        classNameMainDialog: "confirm-message-modal",
        primaryButtonText: t("timesheet_common_actionConfirm"),
        primaryButtonClickFn: async ({ closeFn }: any) => {
          closeFn();
          setShowPopup(false);
          enableScroll();
          const newActivities = [...application.activities].filter((act: any) =>
            !!act.id
              ? act.id !== props.dataItem.id
              : act.uuid !== props.dataItem.uuid
          );
          dispatch!({
            type: ApplicationAction.SET_APPLICATION,
            payload: {
              application: {
                ...application,
                activities: newActivities,
              },
              hasEdit: true,
            },
          });
        },
        secondButtonClickFn: ({ closeFn }: any) => {
          setShowPopup(false);
          enableScroll();
          closeFn();
        },
      })
    );
  }, [
    mainDispatch,
    t,
    enableScroll,
    application,
    dispatch,
    props.dataItem.id,
    props.dataItem.uuid,
  ]);

  const { activityStart, activityEnd, statusClass, showApproval, popupTime } =
    useMemo(() => {
      const isSameTime = (date1: moment.Moment, date2: moment.Moment) =>
        date1.format("HH:mm") === date2.format("HH:mm");

      const start = moment(props.dataItem.actualStart);
      const end = moment(props.dataItem.actualEnd);
      const screenStart = moment(props.dataItem.start);
      const screenEnd = moment(props.dataItem.end);

      let statusClass = "pending";
      switch (props.dataItem.status) {
        case TIMESHEET_ACTIVITY_STATUS.APPROVED:
          statusClass = "approve";
          break;

        case TIMESHEET_ACTIVITY_STATUS.REJECTED:
          statusClass = "reject";
          break;
      }

      const popupTime: string = props.dataItem.isSameDay
        ? `${start.format("HH:mm")} - ${end.format("HH:mm")}`
        : `${
            isSameTime(start, screenStart) ? "" : "Previous Day"
          } ${start.format("HH:mm")} -
        ${isSameTime(end, screenEnd) ? "" : "Next Day"} ${end.format("HH:mm")}`;

      return {
        activityStart: screenStart.format("HH:mm"),
        activityEnd: screenEnd.format("HH:mm"),
        statusClass,
        showApproval: mode === TIMESHEET_DETAIL_TYPE.APPROVAL,
        popupTime,
      };
    }, [props.dataItem, mode]);
  return (
    <>
      {type === "activity" ? (
        <SchedulerItem
          className={`activity ${statusClass} ${hasConflict ? "half" : ""}`}
          {...props}
          ref={ref}
          onFocus={handleFocus}
          id={`timesheet-block-${props.dataItem.order}`}
          style={{ ...(hasConflict && { top: offsetTop }) }}
        >
          {hasConflict ? (
            <>
              <div className="period">
                {activityStart} - {activityEnd} {props.title}
              </div>

              <div className="status half">
                {props.dataItem.status !== "Draft" && props.dataItem.status}
              </div>
            </>
          ) : (
            <>
              <div className="period">
                {activityStart} - {activityEnd}
              </div>
              <div className="header">{props.title}</div>

              <div className="status">
                {props.dataItem.status !== "Draft" && props.dataItem.status}
              </div>
            </>
          )}
        </SchedulerItem>
      ) : (
        <SchedulerItem
          className={`leave ${statusClass} ${hasConflict ? "half" : ""}`}
          {...props}
          ref={ref}
          editable={false}
          style={{ ...(hasConflict && { top: offsetTop + 46 }) }}
          id={`timesheet-block-${props.dataItem.order}`}
        >
          <div className="leaveType">{`${props.dataItem.leaveType} (${props.dataItem.status})`}</div>
        </SchedulerItem>
      )}

      {showPopup && (
        <div
          className="p-dialog-mask p-component-overlay p-dialog-visible p-dialog-draggable p-dialog-resizable p-dialog-center"
          style={{ zIndex: 1101 }}
        >
          <Popup
            show={showPopup}
            anchorAlign={{
              horizontal: "left",
              vertical: "bottom",
            }}
            popupAlign={{
              horizontal: "left",
              vertical: "top",
            }}
            onPosition={(e) => {
              const offsetLeft = props.containerRef.current.offsetLeft || 0;
              const firstCellWidth =
                (
                  document.querySelector(
                    "div.k-sticky-cell > div:nth-child(1) > div"
                  ) as HTMLDivElement
                ).getBoundingClientRect().width || 0;
              const minimumLeft = offsetLeft + firstCellWidth;
              if (
                e.target &&
                (e.target as any)._popup &&
                Number((e.target as any)._popup.style.left.replace("px", "")) <
                  minimumLeft
              ) {
                (e.target as any)._popup.style.left = minimumLeft + "px";
              }
            }}
            anchor={ref.current && (ref.current as any).element}
            // TODO: handle mobile long text
            className={`popup${
              showApproval || props.dataItem?.aprvRemark
                ? " has-remark"
                : " has-remark"
            }`}
          >
            {(showApproval || props.dataItem?.aprvRemark) && (
              <div className="remarks">
                <div className="input-header">
                  {t("timesheet_detail_remarks")}
                </div>
                <InputText
                  style={{ width: "100%" }}
                  onBlur={(e) => setComment(e.target.value)}
                  disabled={!showApproval}
                  defaultValue={props.dataItem?.aprvRemark}
                  maxLength={150}
                />
              </div>
            )}
            <div
              className="container"
              style={{
                overflow: "hidden",
              }}
            >
              <div className="activity-detail">
                <button
                  className="close-button"
                  onClick={() => {
                    setShowPopup(false);
                    enableScroll();
                  }}
                >
                  <Close className="close" />
                </button>

                <div>{popupTime}</div>

                <div>{props.title}</div>

                {props.dataItem.remarks && (
                  <div className="remark">
                    {t("timesheet_detail_notes")}
                    {props.dataItem.remarks}
                  </div>
                )}
                {props.dataItem.aprvRemark && (
                  <div className="remark">
                    {t("timesheet_detail_aprvRemarks")}
                    {props.dataItem.aprvRemark}
                  </div>
                )}
              </div>
            </div>
            {mode !== TIMESHEET_DETAIL_TYPE.VIEW && (
              <div className="activity-action-bar">
                {(mode === TIMESHEET_DETAIL_TYPE.AMEND ||
                  mode === TIMESHEET_DETAIL_TYPE.HR_AMEND) && (
                  <>
                    <Button
                      className="p-button-outlined secondary"
                      onClick={onEditButtonClick}
                      disabled={false}
                    >
                      {t("timesheet_common_actionAmend")}
                    </Button>
                    <Button
                      className="p-button"
                      onClick={onDelete}
                      disabled={false}
                    >
                      {t("timesheet_common_actionDelete")}
                    </Button>
                  </>
                )}
                {mode !== TIMESHEET_DETAIL_TYPE.APPROVAL &&
                  mode !== TIMESHEET_DETAIL_TYPE.AMEND &&
                  mode !== TIMESHEET_DETAIL_TYPE.HR_AMEND && (
                    <>
                      <Button
                        className="p-button-outlined secondary"
                        onClick={onEditButtonClick}
                        disabled={false}
                      >
                        {t("timesheet_common_actionEdit")}
                      </Button>
                      <Button
                        className="p-button"
                        onClick={onDelete}
                        disabled={false}
                      >
                        {t("timesheet_common_actionDelete")}
                      </Button>
                    </>
                  )}
                {showApproval && (
                  <>
                    <Button
                      className="p-button-outlined secondary"
                      onClick={
                        props.dataItem?.status === "Draft"
                          ? onRejectButtonClick
                          : onResetButtonClick
                      }
                      disabled={false}
                    >
                      {t(
                        `timesheet_common_action${
                          props.dataItem?.status === "Draft"
                            ? "Reject"
                            : "Reset"
                        }`
                      )}
                    </Button>
                    <Button
                      className="p-button"
                      onClick={
                        props.dataItem?.status === "Approved"
                          ? onRejectButtonClick
                          : onApproveButtonClick
                      }
                      disabled={false}
                    >
                      {t(
                        `timesheet_common_action${
                          props.dataItem?.status === "Approved"
                            ? "Reject"
                            : "Approve"
                        }`
                      )}
                    </Button>
                  </>
                )}
              </div>
            )}
          </Popup>
        </div>
      )}
    </>
  );
};

export default TimesheetSchedulerActivity;
