import { Dropdown } from "primereact/dropdown";
import { SetStateAction } from "react";
import { SelectControlOption } from "../../../../../components/base-control/select-control/select-control";

export const ToolBar = ({
  filterYears,
  filteredYear,
  setFilteredYear,
  filterStatuses,
  filteredStatus,
  setFilteredStatus,
}: {
  filterYears: SelectControlOption[];
  filteredYear: SelectControlOption["value"];
  setFilteredYear: React.Dispatch<SetStateAction<string>>;
  filterStatuses: SelectControlOption[];
  filteredStatus: SelectControlOption["value"];
  setFilteredStatus: React.Dispatch<SetStateAction<string>>;
}) => {
  return (
    <div className="bt-toolbar-content">
      <div className="filter-status-container">
        <Dropdown
          className="filter-status-dropdown"
          value={filteredYear}
          options={filterYears}
          onChange={(e) => {
            setFilteredYear(e.value);
          }}
        />
        <Dropdown
          className="filter-status-dropdown"
          value={filteredStatus}
          options={filterStatuses}
          onChange={(e) => {
            setFilteredStatus(e.value);
          }}
        />
      </div>
    </div>
  );
};
