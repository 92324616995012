import "./calendar.scss";
import { Calendar, CalendarProps } from "primereact/calendar";
import { useMemo, useState } from "react";
import { useEffect } from "react";
import useWindowDimensions from "../../utils/useWindowDimensions";
import moment from "moment";
import { SHORT_DATE_FORMAT } from "../../constants";

interface CustomCalendarProps extends CalendarProps {
  className?: string;
  dateConfig?: DateConfig;
  selectedActivityDate: string;
  [key: string]: any;
}

interface DateConfigItem {
  showMark?: boolean;
  disabled: boolean;
  isRedDay: boolean;
}

interface DateConfig {
  [key: string]: DateConfigItem;
}

interface PDate {
  day: number;
  month: number;
  year: number;
  otherMonth: boolean;
  selectable: boolean;
}

const DEFAULT_DATE_CONFIG = {
  showMark: false,
  disabled: false,
  isRedDay: false,
};

const DateTemplate = ({
  date,
  dateConfig,
  selectedActivityDate,
}: {
  date: PDate;
  dateConfig?: DateConfig;
  selectedActivityDate: string;
}) => {
  if (date.otherMonth) {
    return <span>{date.day}</span>;
  }
  const dateKey: string = moment(
    new Date(date.year, date.month, date.day)
  ).format(SHORT_DATE_FORMAT);

  const getDateConfig = () => {
    let toReturn: DateConfigItem = DEFAULT_DATE_CONFIG;
    if (dateConfig && dateConfig[dateKey]) {
      toReturn = dateConfig[dateKey];
    }

    if (
      dateKey ===
      moment(new Date(selectedActivityDate)).format(SHORT_DATE_FORMAT)
    ) {
      toReturn = { ...toReturn, showMark: true };
    }

    return toReturn;
  };

  const config = getDateConfig();

  return (
    <div className="custom-date-template">
      <span
        className={`
          ${config?.disabled ? "custom-date-disabled" : ""}
          ${config?.isRedDay ? "custom-date-red-day" : ""}
          ${config?.showMark ? "custom-date-marked" : ""}`}
      >
        {date.day}
      </span>

      {config.showMark && <div className="date-marker pi pi-circle-on"></div>}
    </div>
  );
};

export const TimesheetCalendar = ({
  className,
  dateConfig,
  selectedActivityDate,
  ...props
}: CustomCalendarProps) => {
  const initialDisabledDates = useMemo(
    () => props?.disabledDates ?? [],
    [props?.disabledDates]
  );
  const [disabledDates, setDisableDate] =
    useState<Date[]>(initialDisabledDates);
  const [viewDate, setViewDate] = useState<Date>(
    props.viewDate ? props.viewDate : new Date()
  );
  const { width } = useWindowDimensions();

  useEffect(() => {
    if (props.viewDate) {
      setViewDate(props.viewDate);
    }
  }, [props.viewDate]);

  // set disabled dates
  useEffect(() => {
    const dates = Object.entries(dateConfig ?? {}).reduce(
      (acc, [day, config]) => {
        if (config.disabled) {
          acc = [...acc, new Date(new Date(viewDate).setDate(parseInt(day)))];
        }
        return acc;
      },
      initialDisabledDates
    );
    setDisableDate(dates);
  }, [dateConfig, initialDisabledDates, viewDate]);

  const onViewDateChange = (e: any) => {
    if (props.onViewDateChange) {
      props.onViewDateChange(e);
    }
    setViewDate(e.value);
  };

  return (
    <Calendar
      inline
      className={`custom-timesheet-calendar ${className || ""}`}
      dateTemplate={(date: PDate) => (
        <DateTemplate
          date={date}
          dateConfig={dateConfig}
          selectedActivityDate={selectedActivityDate}
        />
      )}
      viewDate={props.viewDate ? props.viewDate : viewDate}
      {...props}
      onViewDateChange={onViewDateChange}
      disabledDates={disabledDates}
      selectionMode="multiple"
      numberOfMonths={width > 1200 ? 2 : 1}
      monthNavigator={false}
      yearNavigator={false}
    />
  );
};
