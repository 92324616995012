import { BCType } from "src/components/base-control/base-control";

export interface IFormField {
  key: string;
  label: string;
  placeholder?: string;
  type?: string;
}

export const EmployeeField = {
  key: "employee",
  label: "claims_calendarView_employee",
  type: BCType.input,
};

export const RefNoField = {
  key: "referenceNo",
  label: "claims_calendarView_refNo",
  type: BCType.input,
};

export const StatusField = {
  key: "status",
  label: "claims_calendarView_status",
};

export const ClaimTypeField = {
  key: "claimType",
  label: "claims_form_claimType",
  type: BCType.select,
  hasFilterEnum: false,
};

export const FromDateField = {
  key: "fromDate",
  label: "claims_form_startDate",
  type: BCType.date,
  dateFormat: "yy-mm-dd",
  placeholder: "claims_form_select",
  config: {
    readOnly: true,
  },
};
export const FromTimeField = {
  key: "fromTime",
  label: "claims_form_startTime",
  type: BCType.input,
  config: {
    tooltip: null,
    readOnly: true,
  },
};

export const ToDateField = {
  key: "toDate",
  label: "claims_form_endDate",
  type: BCType.date,
  dateFormat: "yy-mm-dd",
  placeholder: "claims_form_select",
  config: {
    readOnly: true,
  },
};

export const ToTimeField = {
  key: "toTime",
  label: "claims_form_endTime",
  type: BCType.input,
  config: {
    tooltip: null,
    readOnly: true,
  },
};

export const UnitField = {
  key: "unit",
  label: "claims_form_unit",
  type: BCType.input,
  config: {
    tooltip: null,
    readOnly: true,
  },
};

export const CompLeaveDisplayField = {
  key: "compLeaveDisplay",
  label: "claims_form_compLeaveDisplay",
  componentRender: (data: any) => <div className="p-ml-1">{data.value}</div>,
};

export const DateTypeDisplayField = {
  key: "dateTypeDisplay",
  label: "claims_form_dateTypeDisplay",
  componentRender: (data: any) => <div className="p-ml-1">{data.value}</div>,
};

export const AmountField = {
  key: "amount",
  label: "claims_form_amount",
  type: BCType.input,
  config: {
    tooltip: null,
    readOnly: true,
    mode: "decimal",
    minFractionDigits: 1,
    maxFractionDigits: 2,
    showButtons: false,
  },
};

export const RemarksField = {
  key: "remarks",
  label: "claims_form_remarks",
  type: BCType.textarea,
  config: {
    autoResize: true,
    rows: 1,
    tooltip: null,
    readOnly: true,
  },
  placeholder: "claims_form_entryRemarks",
};

export const ApproverRemarksField = {
  key: "approverRemarks",
  label: "claims_form_approverRemarks",
  type: BCType.textarea,
  config: {
    tooltip: null,
    readOnly: true,
  },
};
