import { TFunction } from "react-i18next";
import {
  getColumModel,
  getBTConfig,
} from "../../../../../components/base-table/base-table-model";
import { ApprovalListColumns } from "./table-column-config";
import { GetLink, mapActionToMode } from "../../utils/ui-action-helper";
import { timesheetRoute } from "../../constants";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";

const excludedClasses = ["selection-column"];

const renderConfigColumns = ({
  t,
  isSelectable,
}: {
  t: TFunction;
  isSelectable: boolean;
}) => {
  return [
    getColumModel({
      header: "",
      key: "head-column-dummy",
      dataType: "string",
      frozen: true,
      sortable: false,
      width: 0.1,
      hideInMobile: true,
    }),
    ...ApprovalListColumns,
  ]
    .filter((column) => (!isSelectable ? !!column.header : true))
    .map((column) => ({
      ...column,
      header: t(`${column.header}`),
    }));
};

export const ListConfigModel = ({
  t,
  isSelectable,
  redirect,
  confirm,
  toolbar,
  expandedRows,
  setExpandedRows,
}: {
  t: TFunction;
  isSelectable: boolean;
  redirect?: Function;
  confirm?: Function;
  toolbar?: any;
  expandedRows: any;
  setExpandedRows: any;
}) => {
  return getBTConfig({
    columns: renderConfigColumns({ t, isSelectable }),
    hasIndex: false,
    mode: "list",
    defaultMode: "list",
    showGlobal: false,
    hasColumnSelector: false,
    rowHover: true,
    responsive: true,
    onRowClick: (e: any) => {
      const classString = e.originalEvent.target.classList.value;
      if (
        classString
          ?.split(" ")
          ?.some((_class: string) => excludedClasses.includes(_class))
      ) {
        return;
      }

      if (e.data.approverActions?.length && redirect) {
        const path = GetLink({
          action: e.data.approverActions[0],
          id: e.data.id,
          submodulePath: timesheetRoute.approvalCorner.slice(1),
        });

        redirect(path, {
          type: mapActionToMode({ action: e.data.approverActions[0] }),
          period: e.data.period,
          periodId: e.data.period.id,
          referenceNo: e.data.referenceNo,
        });
      }
    },
    hideItemSelectedToolbar: true,
    actionsMulti: isSelectable ? [{}] : undefined,
    customToolbar: toolbar,
    isExpandable: true,
    isExpandInMobileOnly: true,
    expandedRows: expandedRows,
    onRowToggle: (e: any) => {
      setExpandedRows(e.data);
    },
    rowExpansionTemplate: (data: any) => {
      const renderTableCell = (rowData: any, column: any) => {
        if (column.render) {
          return <div className="bt-cell-render">{column.render(rowData)}</div>;
        } else {
          return rowData[column.key];
        }
      };

      const columnsRender = ApprovalListColumns.map((column, index) => {
        const columnConfig = {
          key: `bt-column-${index}`,
          field: column.key,
          header: column.header,
          className: `bt-${column.key}`,
          body: (rowData: any, config: any) => {
            return (
              <div className={`bt-row bt-row-${config.rowIndex} bt-cell`}>
                <span className="p-column-title mobile-p-column-title">
                  {t(column.header as any)}:
                </span>
                <div className="bt-cell-value">
                  {renderTableCell(rowData, column)}
                </div>
              </div>
            );
          },
        };

        return <Column {...columnConfig} />;
      });

      return (
        <DataTable
          key="info-table"
          value={[data]}
          className="p-datatable-responsive table-expandable-header timesheet-approval-list-mobile"
        >
          {columnsRender}
        </DataTable>
      );
    },
  } as any);
};
