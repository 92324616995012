import "./claims.scss";

import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import { CLAIM_SUBMODULE_CODE } from "src/constants/index";
import { resetBcDynamicPaths } from "src/redux/actions/breadcrumb";
import PageNotFound from "../public/error/employee-error-404";
import ClaimApprovalList from "./modules/approval";
import { ClaimsReport } from "./modules";
import Create from "./modules/create";
import Review from "./modules/review";
import Edit from "./modules/edit";
import ClaimApplicationList from "./modules/history";
import View from "./modules/view";
import ClaimContextProdiver from "./context/claim-context-provider";
import Delegation from "./modules/delegation";

const Claims = (props: any) => {
  let match = useRouteMatch();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetBcDynamicPaths());
  }, [dispatch]);

  const routes = [
    {
      id: "approval",
      path: `${match.path}/${CLAIM_SUBMODULE_CODE.APPROVAL}`,
      component: ClaimApprovalList,
      exact: true,
      strict: true,
    },
    {
      id: "report",
      path: `${match.path}/${CLAIM_SUBMODULE_CODE.REPORT}`,
      component: ClaimsReport,
    },
    {
      id: "list",
      path: `${match.path}`,
      component: ClaimApplicationList,
      exact: true,
      strict: true,
    },
    {
      id: "create",
      path: `${match.path}/${CLAIM_SUBMODULE_CODE.SUBMIT_APPLICATION}`,
      component: Create,
      exact: true,
      strict: true,
    },
    {
      id: "review",
      path: `${match.path}/${CLAIM_SUBMODULE_CODE.REVIEW_APPLICATION}`,
      component: Review,
      exact: true,
      strict: true,
    },
    {
      id: "edit",
      path: `${match.path}/${CLAIM_SUBMODULE_CODE.EDIT}/:id`,
      component: Edit,
      exact: true,
      strict: true,
    },
    {
      id: "application-view",
      path: `${match.path}/${CLAIM_SUBMODULE_CODE.APPLICATION}/:id`,
      component: View,
      exact: true,
      strict: true,
    },
    {
      id: "application-approval-view",
      path: `${match.path}/${CLAIM_SUBMODULE_CODE.APPROVAL}/:aid`,
      component: View,
      exact: true,
      strict: true,
    },
    {
      id: "delegation",
      path: `${match.path}/${CLAIM_SUBMODULE_CODE.DELEGATION}`,
      component: Delegation,
      exact: true,
      strict: true,
    },
  ];
  return (
    <ClaimContextProdiver>
      <Switch>
        {props.routeConfig ? props.routeConfig(routes) : null}
        <Route component={PageNotFound} />
      </Switch>
    </ClaimContextProdiver>
  );
};

export default Claims;
