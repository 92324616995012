import { getColumModel } from "src/components/base-table/base-table-model";
import ClaimStatus from "../../components/claim-status";
import { GetDateFromString } from "../../utils/date-helper";

const DATE_FORMAT = "yyyy-MM-DD (ddd)";
const TIME_FORMAT = "HH:mm";

const roundNumberToDecimalPlacesWithThousandSeparator = (
  num: number,
  decimalPlaces: number
) => {
  return (
    Math.round(num * Math.pow(10, decimalPlaces)) / Math.pow(10, decimalPlaces)
  )
    .toFixed(decimalPlaces)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const employeeIdColumn = getColumModel({
  header: "claims_approval_table_EmployeeID",
  key: "employeeCode",
  dataType: "string",
  sortable: true,
  hideInMobile: true,
  width: 8,
});

const employeeColumn = getColumModel({
  header: "claims_approval_table_Employee",
  key: "employeeName",
  dataType: "string",
  hideInMobile: true,
  sortable: true,
});

const claimTypeColumn = getColumModel({
  header: "claims_approval_table_ClaimType",
  key: "claimType",
  dataType: "string",
  sortable: true,
  width: 11,
});

const transactionTypeColumn = getColumModel({
  header: "claims_approval_table_TransactionType",
  key: "transactionType",
  dataType: "string",
  sortable: true,
  width: 8,
  hideInMobile: true,
});

const dateColumn = getColumModel({
  header: "claims_approval_table_FromDate",
  key: "from",
  dataType: "string",
  sortable: true,
  width: 11,
  render: (data) => {
    const date = GetDateFromString(data.from, DATE_FORMAT);
    return <span>{date}</span>;
  },
});

const fromDateColumn = getColumModel({
  header: "claims_approval_table_FromTime",
  key: "from",
  dataType: "string",
  sortable: false,
  width: 5,
  hideInMobile: true,
  render: (data) => {
    const time = GetDateFromString(data.from, TIME_FORMAT);
    return time === "00:00" ? "" : <span>{time}</span>;
  },
});

const fromTimeColumn = getColumModel({
  header: "claims_approval_table_ToTime",
  key: "to",
  dataType: "string",
  sortable: false,
  width: 5,
  hideInMobile: true,
  render: (data) => {
    const time = GetDateFromString(data.to, TIME_FORMAT);
    return time === "00:00" ? "" : <span>{time}</span>;
  },
});

const unitColumn = getColumModel({
  header: "claims_approval_table_Units",
  key: "unit",
  dataType: "string",
  sortable: false,
  width: 8,
  hideInMobile: true,
  render: (data) => {
    const time = GetDateFromString(data.from, TIME_FORMAT);
    const appliedAmount = roundNumberToDecimalPlacesWithThousandSeparator(
      data.amount,
      2
    );
    // return time==="00:00"?<span>{`$${roundNumberToDecimalPlacesWithThousandSeparator(data.amount,2)}`}</span>:<span>{data.unit} Hour(s)</span>;
    return time === "00:00" ? (
      <span>{appliedAmount}</span>
    ) : (
      <span>{data.unit} Hour(s)</span>
    );
  },
});

const statusColumn = getColumModel({
  header: "claims_approval_table_Status",
  key: "status",
  dataType: "string",
  sortable: true,
  width: 12,
  hideInMobile: true,
  render: (data) => <ClaimStatus status={data.status} data={data} />,
});

const submissionDateColumn = getColumModel({
  header: "claims_approval_table_SubmissionDate",
  key: "submissionDate",
  dataType: "string",
  sortable: true,
  width: 11,
  hideInMobile: true,
  render: (data) => {
    const datetime = GetDateFromString(data.submissionDate, DATE_FORMAT);
    return <span>{datetime}</span>;
  },
});

const referenceNoColumn = getColumModel({
  header: "claims_approval_table_ReferenceNo",
  key: "referenceNo",
  dataType: "string",
  sortable: true,
  width: 12,
  hideInMobile: true,
});

const submittedByColumn = getColumModel({
  header: "claims_approval_table_submittedBy",
  key: "submittedBy",
  dataType: "string",
  sortable: true,
  width: 12,
  hideInMobile: true,
});

const approverNameColumn = getColumModel({
  header: "claims_approval_table_approverName",
  key: "approverName",
  dataType: "string",
  sortable: true,
  width: 12,
  hideInMobile: true,
});

const compLeaveDisplayColumn = getColumModel({
  header: "claims_approval_table_compLeaveDisplay",
  key: "compLeaveDisplay",
  dataType: "string",
  sortable: false,
  width: 10,
  hideInMobile: true,
});

const dateTypeDisplayColumn = getColumModel({
  header: "claims_approval_table_dateTypeDisplay",
  key: "dateTypeDisplay",
  dataType: "string",
  sortable: false,
  width: 10,
  hideInMobile: true,
});

export const ApprovalListColumns = [
  employeeIdColumn,
  employeeColumn,
  approverNameColumn,
  claimTypeColumn,
  transactionTypeColumn,
  dateColumn,
  fromDateColumn,
  fromTimeColumn,
  dateTypeDisplayColumn,
  unitColumn,
  compLeaveDisplayColumn,
  statusColumn,
  submissionDateColumn,
  referenceNoColumn,
  submittedByColumn,
];
