import { Dropdown } from "primereact/dropdown";
import { SetStateAction } from "react";
import { TFunction } from "react-i18next";
import { SelectControlOption } from "../../../../../components/base-control/select-control/select-control";

export const ToolBar = ({
  filterYears,
  filteredYear,
  setFilteredYear,
  t,
}: {
  filterYears: SelectControlOption[];
  filteredYear: SelectControlOption["value"];
  setFilteredYear: React.Dispatch<SetStateAction<string>>;
  t: TFunction<"translation">;
}) => {
  return (
    <div className="bt-toolbar-content">
      <b>{t("leave_applicationList_currentYear")}: </b>
      <div className="filter-year-container">
        <Dropdown
          value={filteredYear}
          options={filterYears}
          onChange={(e) => {
            setFilteredYear(e.value);
          }}
        />
      </div>
    </div>
  );
};
