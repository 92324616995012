import { Button } from "primereact/button";
import { BCType } from "../../../../../components/base-control/base-control";
import { IBaseFormControlProps } from "../../../timesheet/constants";

export enum ActivityFormConfigFieldKey {
  activityCode = "activityCode",
  projectCode = "projectCode",
  effectiveFrom = "effectiveFrom",
  effectiveTo = "effectiveTo",
  description = "description",
  deletePmActivity = "deletePmActivity",
}

export const ActivityCodeField: IBaseFormControlProps = {
  key: ActivityFormConfigFieldKey.activityCode,
  label: "admin_timesheet_activityConfiguration_activityCode",
  type: BCType.textarea2,
  placeholder: "admin_timesheet_activityConfiguration_activityCode_placeholder",
  required: true,
  maxLength: 50,
  fromFilter: true,
  config: {
    rows: 1,
    tooltip: null,
  },
};

export const ProjectCodeField: IBaseFormControlProps = {
  key: ActivityFormConfigFieldKey.projectCode,
  label: "admin_timesheet_activityConfiguration_projectCode",
  type: BCType.select,
  hasFilterEnum: true,
  placeholder: "admin_timesheet_activityConfiguration_projectCode_placeholder",
};

export const EffectiveFromField: IBaseFormControlProps = {
  key: ActivityFormConfigFieldKey.effectiveFrom,
  label: "admin_timesheet_activityConfiguration_effectiveFrom",
  type: BCType.date2,
  includeTimeZone: true,
  required: true,
  dateFormat: "yy-mm-dd",
  config: {
    monthNavigator: true,
    yearNavigator: true,
  },
};

export const EffectiveToField: IBaseFormControlProps = {
  key: ActivityFormConfigFieldKey.effectiveTo,
  label: "admin_timesheet_activityConfiguration_effectiveTo",
  type: BCType.date2,
  includeTimeZone: true,
  required: true,
  dateFormat: "yy-mm-dd",
  config: {
    monthNavigator: true,
    yearNavigator: true,
  },
};

export const DescriptionField: IBaseFormControlProps = {
  key: ActivityFormConfigFieldKey.description,
  label: "admin_timesheet_activityConfiguration_descriptionField",
  type: BCType.textarea2,
  placeholder:
    "admin_timesheet_activityConfiguration_descriptionField_placeholder",
  required: true,
  maxLength: 200,
  fromFilter: true,
  config: {
    rows: 1,
    tooltip: null,
  },
};

export const DeleteActivityButton: IBaseFormControlProps = {
  key: ActivityFormConfigFieldKey.deletePmActivity,
  label: "",
  componentRender: (props) => (
    <Button
      icon="pi pi-trash"
      onClick={props.onClick}
      disabled={props.disabled}
      type="button"
    />
  ),
};

export const activityFormConfigFieldList = [
  ActivityCodeField,
  ProjectCodeField,
  EffectiveFromField,
  EffectiveToField,
  DescriptionField,
  DeleteActivityButton,
];
