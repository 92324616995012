import { TIMESHEET_DETAIL_TYPE } from "../../constants/type";
import { ITimesheetDetail } from "../reducer/application";

export enum ApplicationAction {
  SET_APPLICATION = "SET_APPLICATION",
  RESET_APPLICATION = "RESET_APPLICATION",
  SET_TIMESHEET_DETAIL_TYPE = "SET_TIMESHEET_DETAIL_TYPE",
}

export type ApplicationPayload = {
  [ApplicationAction.SET_APPLICATION]: {
    application: ITimesheetDetail;
    hasEdit?: boolean;
  };
  [ApplicationAction.RESET_APPLICATION]: undefined;
  [ApplicationAction.SET_TIMESHEET_DETAIL_TYPE]: {
    mode: TIMESHEET_DETAIL_TYPE;
  };
};
