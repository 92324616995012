export const CLAIMS_T_CONTEXT_KEY = {
  APPROVAL: "UI.Claims.Approval",
  BALANCE: "UI.Claims.Balance",
  BLOCK_LEAVE: "UI.Claims.BlockLeave",
  CALENDAR_VIEW: "UI.Claims.CalendarView",
  COMMON: "UI.Claims.Common",
  DELEGATION: "UI.Claims.Delegation",
  ENTITLEMENT: "UI.Claims.Entitlement",
  FORM: "UI.Claims.Form",
  HOLIDAY: "UI.Claims.Holiday",
  LIST: "UI.Claims.List",
  PLANNER: "UI.Claims.Planner",
  REPORT: "UI.Claims.Report",
  SUBMIT_APPLICATION: "UI.Claims.SubmitApplication",
  VIEW_APPLICATION: "UI.Claims.ViewApplication",
};

export const CLAIMS_MENU_MODULE_KEY = "eClaim";

export const CLAIMS_MENU_TYPE_KEY = {
  ACTION: "Action",
  MENU: "Menu",
};

export const CLAIMS_MENU_PAGE_KEY = {
  APPLY_FOR_ENTITLEMENT: "Apply_for_Entitlement",
  BLOCK_LEAVE: "Block_Leave",
  CHANGE_TO_CALENDAR_VIEW: "Change_to_Calendar_View",
  DELEGATION: "Delegation",
  ENQUIRY_REPORT: "eClaim_Enquiry_Report",
  HOLIDAY_LIST: "Holiday_List",
  CLAIM_APPLICATIONS: "Claim_Applications",
  CLAIM_APPROVER_CORNER: "Claim_Approver_Corner",
  MY_LEAVE_BALANCE: "My_Leave_Balance",
  SUBMIT_CLAIM_APPLICATION: "Submit_Claim_Application",
  VIEW_ALL_APPLICATIONS: "View_All_Applications",
};

export const CLAIMS_MENU_ACTION_KEY = {
  EMPLOYEE_SEARCH: "Employee_Search",
  LEAVE_PLANNER: "Leave_Planner",
  SUBMIT_LEAVE_APPLICATION_DELEGATED_LIST:
    "Submit_Leave_Application_Delegated_List",
  SUBMIT_LEAVE_APPLICATION_OWN_LIST: "Submit_Leave_Application_Own_List",
  CLAIMS_MY_APPLICATIONS: "Submit_Leave_Application_Own_List",
  CLAIMS_DELEGATED_APPLICATIONS: "Submit_Leave_Application_Delegated_List",
  CLAIMS_ALL_APPLICATIONS: "Submit_Leave_Application_HR_List",
};
