import moment from "moment";
import { clearLoadedContext } from "../redux/actions/language";
import { closeLoading, closeModal } from "../redux/actions/modal";
import { clearAllRentalContext } from "../redux/actions/rental";
import { clearSpinner } from "../redux/actions/spinner";
import he from "he";

export const sleep = async (ms: any) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};
export const makeId = () => {
  return "hris_xxxxxxxxxx".replace(/[xy]/g, (c) => {
    // eslint-disable-next-line no-mixed-operators
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};
export const isNumber = (value: string) => {
  return /^-?\d+$/.test(value);
};
export const isDate = (value: string) => {
  let date_formart = [
    // moment.ISO_8601,
    // moment.RFC_2822,
    "DD/MM/YYYY",
    "MM/DD/YYYY",
    "YYYY/MM/DD",

    "DD/MM/YYYY hh:mm:ss",
    "MM/DD/YYYY hh:mm:ss",
    "YYYY/MM/DD hh:mm:ss",

    "DD/MM/YYYY hh:mm",
    "MM/DD/YYYY hh:mm",
    "YYYY/MM/DD hh:mm",

    "hh:mm:ss DD/MM/YYYY",
    "hh:mm:ss MM/DD/YYYY",
    "hh:mm:ss YYYY/MM/DD",

    "hh:mm DD/MM/YYYY",
    "hh:mm MM/DD/YYYY",
    "hh:mm YYYY/MM/DD",
  ];
  return moment(value, date_formart, true).isValid();
};
export const getDate = (value: string) => {
  let date_formart = [
    // moment.ISO_8601,
    // moment.RFC_2822,
    "DD/MM/YYYY",
    "MM/DD/YYYY",
    "YYYY/MM/DD",

    "DD/MM/YYYY hh:mm:ss",
    "MM/DD/YYYY hh:mm:ss",
    "YYYY/MM/DD hh:mm:ss",

    "DD/MM/YYYY hh:mm",
    "MM/DD/YYYY hh:mm",
    "YYYY/MM/DD hh:mm",

    "hh:mm:ss DD/MM/YYYY",
    "hh:mm:ss MM/DD/YYYY",
    "hh:mm:ss YYYY/MM/DD",

    "hh:mm DD/MM/YYYY",
    "hh:mm MM/DD/YYYY",
    "hh:mm YYYY/MM/DD",

    "DD-MM-YYYY",
    "MM-DD-YYYY",
    "YYYY-MM-DD",

    "YYYY-MM-DD HH:mm:ss",

    "M/D/YYYY hh:mm:ss A",
  ];
  return moment(value, date_formart, true);
};
export const getObjType = (obj: any) => {
  var dataType = typeof obj;
  if (dataType === "string") {
    if (isDate(obj)) {
      return "date";
    }
    if (isNumber(obj)) {
      return "number";
    }
  }
  return dataType;
};
export const sortArray = (list: any[], key: string, isDesc: boolean) => {
  let compare = (prev: any, next: any) => {
    let a: any;
    let b: any;
    if (isDesc) {
      a = next[key];
      b = prev[key];
    } else {
      a = prev[key];
      b = next[key];
    }
    var result;
    let type = getObjType(a);
    if (type === "undefined") {
      type = getObjType(b);
    }
    switch (type) {
      case "string":
        a = a || "";
        b = b || "";
        result = a.trim().localeCompare(b.trim());
        break;
      case "date":
        a = new Date(a);
        b = new Date(b);
        result = a - b;
        break;
      default:
        result = a - b;
        break;
    }
    return result;
  };
  return list.sort(compare);
};
export const urltoFile = (url: any, filename: any, mimeType: any) => {
  return fetch(url)
    .then(function (res) {
      return res.arrayBuffer();
    })
    .then(function (buf) {
      return new File([buf], filename, { type: mimeType });
    });
};
export const filterMultiProps = (
  list: any[],
  listPropSearch: string[],
  value: string
) => {
  let resultData = list.filter((x) => {
    let result = false;
    listPropSearch.forEach((prop) => {
      result = result || x[prop].includes(value.trim());
    });
    return result;
  });
  return resultData;
};
export const cloneExcludeObj = (obj: any, keys: string[]) => {
  var target: any = {};
  for (var i in obj) {
    if (keys.indexOf(i) >= 0) continue;
    if (!Object.prototype.hasOwnProperty.call(obj, i)) continue;
    target[i] = obj[i];
  }
  return target;
};
export const jsonEqual = (a: any, b: any) => {
  return JSON.stringify(a) === JSON.stringify(b);
};
export const isObjectEmpty = (obj: any) => {
  for (var prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      return false;
    }
  }

  return JSON.stringify(obj) === JSON.stringify({});
};
export const camelize = (str: string) => {
  return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, index) {
    if (+match === 0) return ""; // or if (/\s+/.test(match)) for white spaces
    return index === 0 ? match.toLowerCase() : match.toUpperCase();
  });
};

/**
 * check if the response contains error
 * @param res
 */
export const hasValidateError = (res: any) => {
  let rv = false;
  if (res && res.message) {
    if (res.message.validateResult && res.message.validateResult.length > 0) {
      for (let mi of res.message.validateResult) {
        if (mi.level === "ERROR") {
          return true;
        }
      }
    }
  }
  return rv;
};

/**
 * check if the response contains error
 * @param res
 */
export const partialObj = (obj: any, props: string[]) => {
  let result: any = {};
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      if (props.includes(key)) result[key] = obj[key];
    }
  }
  return result;
};

/**
 * Remove obj
 * @param res
 */
export const removeDetailObj = (obj: any) => {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (key.includes("_obj")) {
        delete obj[key];
      }
    }
  }
  return obj;
};

/**
 * BuildFile
 * @param res
 */
export const buildFileLink = (src: string) => {
  if (!src) {
    return "/assets/images/default.png";
  }
  if (
    src.startsWith("blob") ||
    src.startsWith("http") ||
    src.startsWith("data:") ||
    src.startsWith("/assets/")
  ) {
    return src;
  } else {
    let result = pathJoin([process.env.REACT_APP_FILE_HOST || "", src]);

    return result;
  }
};

/**
 * BuildFile
 * @param res
 */
export const buildFileFromId = (
  id: string,
  clientId: string,
  entityId: string
) => {
  if (!id) {
    return "/assets/images/default.png";
  }
  let result = pathJoin([
    process.env.REACT_APP_FILE_HOST || "",
    `v1.0/empl/COM/COM0401/File/${id}?Parameter.ClientId=${clientId}&Parameter.EntityId=${entityId}`,
  ]);
  return result;
};
export const pathJoin = (parts: string[]) => {
  var separator = "/";
  parts = parts.map((part, index) => {
    if (index) {
      part = part.replace(new RegExp("^" + separator), "");
    }
    if (index !== parts.length - 1) {
      part = part.replace(new RegExp(separator + "$"), "");
    }
    return part;
  });
  return parts.join(separator);
};
export const getValidateResultItem = (
  validateResult: any[],
  index: number,
  prefix: string
) => {
  let result: any[] = [];
  validateResult.forEach((element) => {
    if (element.componentId.includes(`${prefix}[${index}]_`)) {
      let newName = element.componentId.replace(`${prefix}[${index}]_`, "");
      result.push({
        ...element,
        componentId: newName,
      });
    }
  });
  return result;
};

/**
 * check if res is error for HRIS
 * @param res
 */
export const isResponseOk = (res: any) => {
  // unexpected resp
  if (res && res.TraceId) {
    return false;
  }
  if (res && res?.data?.status === false) {
    return false;
  }
  return (
    !!res && res.messages && res?.messages?.[0]?.type?.toLowerCase() !== "error"
  );
};

export function getDateArray(startDate: any, endDate: any) {
  var dateArray = [];
  var currentDate = new Date(startDate);
  var stopDate = new Date(endDate);
  while (currentDate <= stopDate) {
    dateArray.push(new Date(currentDate));
    currentDate.setDate(currentDate.getDate() + 1);
  }
  return dateArray;
}

/**
 * Return the startDate and endDate of the input's month
 * @param date
 */
export function getStartEndDateOfMonth(date: Date) {
  const year = date.getFullYear();
  const month = date.getMonth();
  return [new Date(year, month, 1), new Date(year, month + 1, 0)];
}

export const getResponseMessage = (res: any) => res?.messages?.[0]?.text;

export const extractAllowedFileExt = (input: string | undefined) =>
  input?.match(/\((.*)\)\((.*)\)/)?.[2];

export const processAttachmentControlConfig = (
  config:
    | {
        description: string;
        allowedFileExt: string;
        personalContent: boolean;
        allowedFileSize: number;
        allowedNoOfFiles: number;
      }
    | undefined
) => {
  if (!config) return {};

  let _config = { ...config };
  const fileExtString = extractAllowedFileExt(_config.allowedFileExt);
  _config.allowedFileExt = fileExtString
    ? fileExtString
        .split("|")
        .map((x) => "." + x)
        .join(",")
    : "";
  return _config;
};

export function convertApiDateFormatToPrime(dateFormat: string) {
  if (dateFormat.includes("yyyy")) {
    dateFormat = dateFormat.replace("yyyy", "yy");
  } else if (dateFormat.includes("yy")) {
    dateFormat = dateFormat.replace("yy", "y");
  }

  if (dateFormat.includes("MM")) {
    dateFormat = dateFormat.replace("MM", "mm");
  } else if (dateFormat.includes("mm")) {
    dateFormat = dateFormat.replace("mm", "MM");
  }

  return dateFormat;
}

export function isDateRangeOverlap(
  aStartDate: Date,
  aEndDate: Date,
  bStartDate: Date,
  bEndDate: Date
) {
  return aEndDate >= bStartDate && aStartDate <= bEndDate;
}

export function onWindowsUnload(dispatch: any) {
  dispatch(clearLoadedContext());
  dispatch(clearAllRentalContext());
  dispatch(clearSpinner());
  dispatch(closeLoading());
  dispatch(closeModal());
  window.removeEventListener("beforeunload", onWindowsUnload);
}

export function GetParamValue(param: string) {
  return new URLSearchParams(window.location.search).get(param);
}

export function CompareParamValue(param: string, paramValue: string) {
  const value = GetParamValue(param);
  return !!value && value === paramValue;
}

/**
 *
 * @param date
 * @param intervalMinutes
 * @returns
 */
export function TimeRoundUp(date = new Date(), intervalMinutes = 15) {
  var interval = intervalMinutes * 60 * 1000;
  var currentDate = date;
  if (new Date().getHours() - currentDate.getHours() >= 1) {
    currentDate = new Date();
  }

  if (new Date().getMinutes() - currentDate.getMinutes() > 0) {
    currentDate = new Date();
  }

  currentDate = new Date(
    Math.ceil(currentDate.getTime() / interval) * interval
  );

  return currentDate;
}

export const htmlDecode = (value: string) => {
  if (value) return he.decode(value);
  return value;
};
