import {
  IActivitiesData,
  IApplicationTabsData,
  IDelegationEmployeesData,
  IGetWorkHours,
  IGetActivities,
  IGetApplicationsTabs,
  IGetApprovalApplicationData,
  IGetApprovalApplications,
  IGetDates,
  IGetDatesData,
  IGetDelegatedApplications,
  IGetDelegatedApplicationsData,
  IGetDelegationEmployees,
  IGetHrApplications,
  IGetHrApplicationsData,
  IGetMyApplications,
  IGetMyApplicationsData,
  IGetPeriods,
  IGetTimesheetDetail,
  IGetYearlyPeriods,
  IPeriodsData,
  ISaveDraft,
  ISubmitTimesheet,
  ITimesheetDetailData,
  IGetTimesheet,
  IGetTimesheetData,
  ITimesheetConfigData,
  IGetTimesheetConfigData,
  IHrAmendTimesheet,
  IGetLeaveHours,
  IGetLeaves,
  ITimesheetLeavesData,
} from "../../../portal/modules/timesheet/constants/query-response";
import { TIMESHEET_REPORT_TYPE } from "../../../portal/modules/timesheet/modules/report/config";
import { IRequestConfig, axios, getConfigs } from "../serviceOptions";

const basePath = "/timesheet";

const getAllApplications: IGetHrApplications = async (props) => {
  const applicationsUrl = `${basePath}/applications/hr`;

  const configs: IRequestConfig = getConfigs(
    "get",
    "application/json",
    applicationsUrl,
    props?.options || {}
  );
  configs.params = props?.params;

  return await axiosAsync<IGetHrApplicationsData>(configs);
};

const getApplication: IGetTimesheet = async (props: any) => {
  const url = `${basePath}/application/${props.data.id}`;

  const configs: IRequestConfig = getConfigs(
    "get",
    "application/json",
    url,
    props?.options || {}
  );

  return await axiosAsync<IGetTimesheetData>(configs);
};

const getApplicationsTabs: IGetApplicationsTabs = async (props) => {
  const applicationsUrl = `${basePath}/general/applications/tabs`;

  const configs: IRequestConfig = getConfigs(
    "get",
    "application/json",
    applicationsUrl,
    props?.options || {}
  );

  return await axiosAsync<IApplicationTabsData>(configs);
};

const getApprovalApplications: IGetApprovalApplications = async (props) => {
  const applicationsUrl = `${basePath}/approval/applications`;

  const configs: IRequestConfig = getConfigs(
    "get",
    "application/json",
    applicationsUrl,
    props?.options || {}
  );
  configs.params = props?.params;

  return await axiosAsync<IGetApprovalApplicationData>(configs);
};

const getAvailableActivities: IGetActivities = async (props) => {
  const applicationsUrl = `${basePath}/general/activities`;

  const configs: IRequestConfig = getConfigs(
    "get",
    "application/json",
    applicationsUrl,
    props?.options || {}
  );

  configs.params = props?.params;

  return await axiosAsync<IActivitiesData>(configs);
};

const getDates: IGetDates = async (props) => {
  const applicationsUrl = `${basePath}/general/dates`;

  const configs: IRequestConfig = getConfigs(
    "get",
    "application/json",
    applicationsUrl,
    props?.options || {}
  );
  configs.params = props?.params;

  return await axiosAsync<IGetDatesData>(configs);
};

const getDelegatedApplications: IGetDelegatedApplications = async (props) => {
  const applicationsUrl = `${basePath}/applications/delegated`;

  const configs: IRequestConfig = getConfigs(
    "get",
    "application/json",
    applicationsUrl,
    props?.options || {}
  );
  configs.params = props?.params;

  return await axiosAsync<IGetDelegatedApplicationsData>(configs);
};

const getDelegationEmployees: IGetDelegationEmployees = async (props) => {
  const applicationsUrl = `${basePath}/general/delegation/employees`;

  const configs: IRequestConfig = getConfigs(
    "get",
    "application/json",
    applicationsUrl,
    props?.options || {}
  );

  return await axiosAsync<IDelegationEmployeesData>(configs);
};

const getMyApplications: IGetMyApplications = async (props) => {
  const applicationsUrl = `${basePath}/applications`;

  const configs: IRequestConfig = getConfigs(
    "get",
    "application/json",
    applicationsUrl,
    props?.options || {}
  );
  configs.params = props?.params;

  return await axiosAsync<IGetMyApplicationsData>(configs);
};

const getPeriodDetail: IGetTimesheetDetail = async (props) => {
  const applicationsUrl = `${basePath}/general/period`;

  const configs: IRequestConfig = getConfigs(
    "get",
    "application/json",
    applicationsUrl,
    props?.options || {}
  );

  configs.params = props?.params;

  return await axiosAsync<ITimesheetDetailData>(configs);
};

const getSelectionPeriods: IGetPeriods = async (props: any) => {
  const { employeeCode } = props?.data!;
  const applicationsUrl = `${basePath}/general/periods`;

  const configs: IRequestConfig = getConfigs(
    "get",
    "application/json",
    applicationsUrl,
    props?.options || {}
  );

  configs.params = { employeeCode };

  return await axiosAsync<IPeriodsData>(configs);
};

const getLeaveHours: IGetLeaveHours = async (props: any) => {
  const { employeeCode, timetableId } = props?.data!;
  const url = `${basePath}/general/leavehours?employeeCode=${employeeCode}&timetableId=${timetableId}`;
  const configs: IRequestConfig = getConfigs(
    "get",
    "application/json",
    url,
    props?.options || {}
  );
  return await axiosAsync<number>(configs);
};

const getWorkHours: IGetWorkHours = async (props: any) => {
  const { employeeCode, timetableId } = props?.data!;
  const url = `${basePath}/general/workhours?employeeCode=${employeeCode}&timetableId=${timetableId}`;
  const configs: IRequestConfig = getConfigs(
    "get",
    "application/json",
    url,
    props?.options || {}
  );
  return await axiosAsync<number>(configs);
};

const getLeaves: IGetLeaves = async (props: any) => {
  const { employeeCode, timetableId } = props?.data!;
  const url = `${basePath}/general/leaves?employeeCode=${employeeCode}&timetableId=${timetableId}`;
  const configs: IRequestConfig = getConfigs(
    "get",
    "application/json",
    url,
    props?.options || {}
  );
  return await axiosAsync<ITimesheetLeavesData[]>(configs);
};

const getYearlyPeriods: IGetYearlyPeriods = async (props) => {
  const applicationsUrl = `${basePath}/general/yearly_periods`;

  const configs: IRequestConfig = getConfigs(
    "get",
    "application/json",
    applicationsUrl,
    props?.options || {}
  );

  return await axiosAsync<IPeriodsData>(configs);
};

const getTimesheetConfig: IGetTimesheetConfigData = async (props) => {
  const applicationsUrl = `${basePath}/general/config`;

  const configs: IRequestConfig = getConfigs(
    "get",
    "application/json",
    applicationsUrl,
    props?.options || {}
  );

  configs.params = props?.params;

  return await axiosAsync<ITimesheetConfigData>(configs);
};

const hrAmendTimesheet: IHrAmendTimesheet = async (props: any) => {
  const { id, ...resData } = props.data;
  const applicationsUrl = `${basePath}/application/${id}/amend/hr`;

  const configs: IRequestConfig = getConfigs(
    "put",
    "application/json",
    applicationsUrl,
    props?.options || {}
  );

  configs.data = resData;

  return await axiosAsync(configs);
};

const saveDraft: ISaveDraft = async (props) => {
  const applicationsUrl = `${basePath}/application`;

  const configs: IRequestConfig = getConfigs(
    "post",
    "application/json",
    applicationsUrl,
    props?.options || {}
  );

  configs.data = props?.data;

  return await axiosAsync(configs);
};

const deleteDraft: any = async (props: any) => {
  const url = `${basePath}/application/${props.data.id}`;

  const configs: IRequestConfig = getConfigs(
    "delete",
    "application/json",
    url,
    props?.options || {}
  );

  return await axiosAsync<any>(configs);
};

const submitTimesheet: ISubmitTimesheet = async (props) => {
  const applicationsUrl = `${basePath}/application/submit`;

  const configs: IRequestConfig = getConfigs(
    "post",
    "application/json",
    applicationsUrl,
    props?.options || {}
  );

  configs.data = props?.data;

  return await axiosAsync(configs);
};

const editTimesheet: any = async (props: any) => {
  const { id, ...resData } = props.data;
  const applicationsUrl = `${basePath}/application/${id}/edit`;

  const configs: IRequestConfig = getConfigs(
    "put",
    "application/json",
    applicationsUrl,
    props?.options || {}
  );

  configs.data = {
    employeeCode: resData.employeeCode,
    activities: resData.activities,
    periodId: Number(resData.periodId),
    nElasticDataH: resData.nElasticDataH,
  };

  return await axiosAsync(configs);
};

const submitDraft: any = async (props: any) => {
  let { id, ...resData } = props.data;
  const applicationsUrl = `${basePath}/application/${id}/submit`;

  const configs: IRequestConfig = getConfigs(
    "put",
    "application/json",
    applicationsUrl,
    props?.options || {}
  );

  configs.data = {
    employeeCode: resData.employeeCode,
    activities: resData.activities,
    periodId: Number(resData.periodId),
    nElasticDataH: resData.nElasticDataH,
  };

  return await axiosAsync(configs);
};

const approveTimesheet: any = async (props: any) => {
  let { id, ...resData } = props.data;
  const applicationsUrl = `${basePath}/approval`;

  const configs: IRequestConfig = getConfigs(
    "put",
    "application/json",
    applicationsUrl,
    props?.options || {}
  );

  configs.data = {
    id,
    ids: resData.ids,
    comment: resData.comment,
  };

  return await axiosAsync(configs);
};

const returnTimesheet: any = async (props: any) => {
  let { id, ...resData } = props.data;
  const applicationsUrl = `${basePath}/approval/return`;

  const configs: IRequestConfig = getConfigs(
    "put",
    "application/json",
    applicationsUrl,
    props?.options || {}
  );

  configs.data = {
    id,
    ids: resData.ids,
    comment: resData.comment,
  };

  return await axiosAsync(configs);
};

const approveActivity: any = async (props: any) => {
  let { id, ...resData } = props.data;
  const url = `${basePath}/approval/activity/`;
  const configs: IRequestConfig = getConfigs(
    "put",
    "application/json",
    url,
    props?.options || {}
  );

  configs.data = {
    id,
    comment: resData.comment,
  };

  return await axiosAsync<any>(configs);
};

const approveAllActivities: any = async (props: any) => {
  let { id, ...resData } = props.data;
  const url = `${basePath}/approval/activities`;
  const configs: IRequestConfig = getConfigs(
    "put",
    "application/json",
    url,
    props?.options || {}
  );

  configs.data = {
    id,
    comment: resData.comment,
  };

  return await axiosAsync<any>(configs);
};

const rejectAllActivities: any = async (props: any) => {
  let { id, ...resData } = props.data;
  const url = `${basePath}/approval/activities/reject`;
  const configs: IRequestConfig = getConfigs(
    "put",
    "application/json",
    url,
    props?.options || {}
  );

  configs.data = {
    id,
    comment: resData.comment,
  };

  return await axiosAsync<any>(configs);
};

const approveActivitiesByDate: any = async (props: any) => {
  let { id, ...resData } = props.data;
  const url = `${basePath}/approval/activities/date`;
  const configs: IRequestConfig = getConfigs(
    "put",
    "application/json",
    url,
    props?.options || {}
  );

  configs.data = {
    id,
    date: resData.date,
    comment: resData.comment,
  };

  return await axiosAsync<any>(configs);
};

const rejectActivitiesByDate: any = async (props: any) => {
  let { id, ...resData } = props.data;
  const url = `${basePath}/approval/activities/reject/date`;
  const configs: IRequestConfig = getConfigs(
    "put",
    "application/json",
    url,
    props?.options || {}
  );

  configs.data = {
    id,
    date: resData.date,
    comment: resData.comment,
  };

  return await axiosAsync<any>(configs);
};

const rejectActivity: any = async (props: any) => {
  let { id, ...resData } = props.data;

  const url = `${basePath}/approval/activity/reject`;
  const configs: IRequestConfig = getConfigs(
    "put",
    "application/json",
    url,
    props?.options || {}
  );

  configs.data = {
    id,
    comment: resData.comment,
  };

  return await axiosAsync<any>(configs);
};

const resetActivity: any = async (props: any) => {
  let { id, ...resData } = props.data;

  const url = `${basePath}/approval/activity/reset`;
  const configs: IRequestConfig = getConfigs(
    "put",
    "application/json",
    url,
    props?.options || {}
  );

  configs.data = {
    id,
    comment: resData.comment,
  };

  return await axiosAsync<any>(configs);
};

export interface ITimesheetActivityReport {
  employeeCode: string;
  employeeName: string;
  referenceNo: string;
  tspFromDate: string;
  tspToDate: string;
  activityCode: string;
  activityName: string;
  activityFromDate: string;
  activityToDate: string;
  dateType: string;
  dateOfWeek: string;
  appliedUnits: string;
  unitOfMeasure: string;
  regularOfficeHours: string;
  otNonOfficeHours: string;
  remarks: string;
  approverRemarks: string;
}

const getTimesheetReportApiUrl = (reportType: string) => {
  let url = "";
  switch (reportType) {
    case TIMESHEET_REPORT_TYPE.TIMESHEET_ACTIVITY_REPORT:
      url = basePath + "/report/timesheetactivity";
      break;
    case TIMESHEET_REPORT_TYPE.ALSTOM_UPLOAD_REPORT:
      url = basePath + "/report/timesheetalstomupload";
      break;
    case TIMESHEET_REPORT_TYPE.TIMESHEET_SUMMARY_REPORT:
      url = basePath + "/report/timesheetsummary";
      break;
    default:
      break;
  }
  return url;
};

const getTimesheetReportFilterOptions = async (
  { reportType, body = {} }: { reportType: string; body: any },
  options: any = {}
) => {
  const url = getTimesheetReportApiUrl(reportType) + "/filters";
  options.headers = {
    ...options.headers,
  };
  const configs: IRequestConfig = getConfigs(
    "post",
    "application/json",
    url,
    options
  );
  let data = body;
  configs.data = data;
  return await axiosAsync<any>(configs);
};

const transactionTypes = [
  {
    id: 0,
    name: "Adjustment",
    description: "Adjustment",
    value: "ADJ",
  },
  {
    id: 1,
    name: "Application",
    description: "Application",
    value: "APPLICATION",
  },
  {
    id: 2,
    name: "Cancellation",
    description: "Cancellation",
    value: "CANCEL",
  },
  {
    id: 3,
    name: "Amendment",
    description: "Amendment",
    value: "AMEND",
  },
];

const getTimesheetReport = async (
  { reportType, requestBody = {} }: any,
  options: any = {}
) => {
  let url = getTimesheetReportApiUrl(reportType);

  const configs: IRequestConfig = getConfigs(
    "post",
    "application/json",
    url,
    options || {}
  );
  const { selectedColumns, ...body } = requestBody;
  body.transactionTypes = transactionTypes.map((t) => t.value);
  if (!body.activityCodes) {
    body.activityCodes = [];
  }
  let data = body;
  configs.data = data;
  // return mockReportResponse;
  return await axiosAsync<any>(configs);
};

const exportTimesheetReport: (params: any, options: any) => any = async (
  params,
  options: any = {}
) => {
  const { sorting, body, reportType } = params;
  body.transactionTypes = transactionTypes.map((t) => t.value);
  if (!body.activityCodes) {
    body.activityCodes = [];
  }
  if (!body.companyCode) {
    body.companyCode = [];
  }
  if (!body.department) {
    body.department = [];
  }
  if (!body.division) {
    body.division = [];
  }
  const url = getTimesheetReportApiUrl(reportType) + "/export";

  options.headers = {
    ...options.headers,
  };
  const configs: IRequestConfig = getConfigs(
    "post",
    "application/json",
    url,
    options
  );
  configs.params = { sorting: params["sorting"] };
  const { selectedColumns, ...data } = body;

  configs.data = data;
  return await axiosAsync<any>(configs);
};

const timesheetGetDelegationLeaveTask: any = async (
  params: {} = {} as any,
  props: any
) => {
  let url = basePath + "/delegation/tasks";
  const configs: IRequestConfig = getConfigs(
    "get",
    "application/json",
    url,
    props?.options || {}
  );
  return await axiosAsync<any>(configs);
};

const timesheetGetDelegationOptions: any = async (
  params: {} = {} as any,
  props: any
) => {
  let url = basePath + "/delegation/options";
  const configs: IRequestConfig = getConfigs(
    "get",
    "application/json",
    url,
    props?.options || {}
  );
  return await axiosAsync<any>(configs);
};

const timesheetGetDelegationCurrent: any = async (
  params: {} = {} as any,
  props: any
) => {
  let url = basePath + "/delegation/current";
  const configs: IRequestConfig = getConfigs(
    "get",
    "application/json",
    url,
    props?.options || {}
  );
  return await axiosAsync<any>(configs);
};

const timesheetGetDelegationFuture: any = async (
  params: {} = {} as any,
  props: any
) => {
  let url = basePath + "/delegation/future";
  const configs: IRequestConfig = getConfigs(
    "get",
    "application/json",
    url,
    props?.options || {}
  );
  return await axiosAsync<any>(configs);
};

const timesheetGetDelegationHistory: any = async (
  params: {} = {} as any,
  props: any
) => {
  let url = basePath + "/delegation/history";
  const configs: IRequestConfig = getConfigs(
    "get",
    "application/json",
    url,
    props?.options || {}
  );
  return await axiosAsync<any>(configs);
};

const timesheetGetDelegationLog: any = async (
  params: {} = {} as any,
  props: any
) => {
  let url = basePath + "/delegation/log";
  const configs: IRequestConfig = getConfigs(
    "get",
    "application/json",
    url,
    props?.options || {}
  );
  return await axiosAsync<any>(configs);
};

const timesheetAddDelegation: any = async (params: any, options: any) => {
  const applicationsUrl = `${basePath}/delegation/add`;

  const configs: IRequestConfig = getConfigs(
    "post",
    "application/json",
    applicationsUrl,
    options || {}
  );

  let data = params.body;

  configs.data = data;

  return await axiosAsync(configs);
};

const timesheetCancelDelegation: any = async (
  params: any = {},
  options: any = {}
) => {
  const applicationsUrl = `${basePath}/delegation/cancel`;

  const configs: IRequestConfig = getConfigs(
    "post",
    "application/json",
    applicationsUrl,
    options || {}
  );

  configs.params = { delegateid: params["delegateid"] };
  let data = null;

  configs.data = data;

  return await axiosAsync(configs);
};

const axiosAsync = async <T>(config: IRequestConfig): Promise<{ data: T }> => {
  const promise = new Promise<{ data: T }>((resolve, reject) =>
    axios(config, resolve, reject)
  );

  return await promise;
};

const TimesheetService = {
  getAllApplications,
  getApplicationsTabs,
  getApprovalApplications,
  getAvailableActivities,
  getDates,
  getDelegatedApplications,
  getDelegationEmployees,
  getMyApplications,
  getPeriodDetail,
  getSelectionPeriods,
  getApplication,
  getLeaveHours,
  getWorkHours,
  getLeaves,
  timesheetGetDelegationCurrent,
  timesheetGetDelegationFuture,
  timesheetGetDelegationHistory,
  timesheetGetDelegationLeaveTask,
  timesheetGetDelegationLog,
  timesheetGetDelegationOptions,
  timesheetAddDelegation,
  timesheetCancelDelegation,
  getYearlyPeriods,
  getTimesheetConfig,
  hrAmendTimesheet,
  approveActivity,
  approveTimesheet,
  deleteDraft,
  editTimesheet,
  returnTimesheet,
  rejectActivity,
  resetActivity,
  getTimesheetReport,
  getTimesheetReportFilterOptions,
  saveDraft,
  submitDraft,
  submitTimesheet,
  exportTimesheetReport,
  rejectActivitiesByDate,
  rejectAllActivities,
  approveActivitiesByDate,
  approveAllActivities,
};

export default TimesheetService;
