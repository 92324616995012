import { Button } from "primereact/button";
import { useDispatch } from "react-redux";
import { openModal } from "src/redux/actions/modal";
import { ClaimFormActionModel } from "../../models/claim-form-action-model";

export default function ConfirmButton(action: ClaimFormActionModel) {
  const dispatch = useDispatch();

  const confirmBack = async () => {
    dispatch(
      openModal({
        title: action.title,
        content: action.content,
        classNameMainDialog: "confirm-message-modal",
        primaryButtonText: action.primaryText,
        secondaryButtonText: action.secondaryText,
        primaryButtonClickFn: async ({ closeFn }: any) => {
          action?.primaryAction && action.primaryAction();
          closeFn();
        },
        secondButtonClickFn: ({ closeFn }: any) => {
          action?.secondaryAction && action.secondaryAction();
          closeFn();
        },
      })
    );
  };

  const buttonClass = action.outline ? "p-button-outlined secondary" : "";

  return (
    <Button
      className={`${buttonClass} ${action.className}`}
      onClick={confirmBack}
      loading={action.loading}
      disabled={action.disabled}
    >
      {action.text}
    </Button>
  );
}
