import moment from "moment";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { DEFAULT_BT_SEARCH } from "src/components/base-table/base-table-model";
import BaseTable from "src/components/base-table/base-table-portal-rental2";
import LanguageLoader from "src/components/language-loader/language-loader";
import { EEPORTAL_LABEL_BASE_TABLE } from "src/constants";
import { openModal } from "src/redux/actions/modal";
import { showSpinner } from "src/redux/actions/spinner";
import { ClaimsService } from "src/services/hrmnet-api";
import ClaimService from "src/services/hrmnet-api/claims";
import { showTimestampToastSuccess } from "src/services/utils/message";
import { isResponseOk } from "src/utils/utils";
import { HeaderMenu } from "../../components/header-menu";
import {
  CLAIMS_MENU_MODULE_KEY,
  CLAIMS_MENU_PAGE_KEY,
  CLAIMS_T_CONTEXT_KEY,
  CLAIMS_WORKFLOW_STATUS,
  SHORT_DATE_FORMAT,
} from "../../constants";
import "./approval.scss";
import { ListConfigModel } from "./table-config";
import { TableFilterStatuses } from "./table-filter-config";
import { FormSkeleton } from "../../components";

const Header = () => {
  const { t } = useTranslation();

  return (
    <div className="header">
      <HeaderMenu
        menuKey={CLAIMS_MENU_MODULE_KEY}
        pageKey={CLAIMS_MENU_PAGE_KEY.CLAIM_APPROVER_CORNER}
      />
      <div className="title">{t("claims_approval_title")}</div>
    </div>
  );
};

const Main = () => {
  const { t } = useTranslation();
  const tableRef = useRef();
  const [selectedData, setSelectedData] = useState([]);
  const [filterStatus, setFilterStatus] = useState(
    TableFilterStatuses[0].value
  );

  const filterStatuses = useMemo(() => {
    return TableFilterStatuses.map((status) => ({
      ...status,
      label: t(status.label),
    }));
  }, [t]);

  const [filterYear, setFilterYear] = useState(null);
  const [filterYears, setFilterYears] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const response = (await ClaimService.getApprovalPeriods()) as any;

      setFilterYear(response.data?.find((item: any) => !!item)?.value);
      setFilterYears(
        response.data?.map((item: any) => ({
          label: item.name,
          value: item.value,
        }))
      );

      setIsLoading(false);
    })();
  }, []);

  const ToolBar = () => {
    return (
      <div className="bt-toolbar-content">
        <div className="filter-status-container">
          <Dropdown
            className="filter-status-dropdown"
            value={filterYear}
            options={filterYears}
            onChange={(e) => {
              setFilterYear(e.value);
            }}
          />
          <Dropdown
            className="filter-status-dropdown"
            value={filterStatus}
            options={filterStatuses}
            onChange={(e) => {
              setFilterStatus(e.value);
            }}
          />
        </div>
      </div>
    );
  };

  if (isLoading) {
    return <FormSkeleton column={1} />;
  }

  return (
    <>
      <List
        toolbar={<ToolBar />}
        filterStatus={filterStatus}
        filterYear={filterYear}
        setSelectedData={setSelectedData}
        tableRef={tableRef}
      />
      <Footer
        selectedData={selectedData}
        setSelectedData={setSelectedData}
        tableRef={tableRef}
      />
    </>
  );
};

const List = ({
  toolbar,
  filterStatus,
  filterYear,
  setSelectedData,
  tableRef,
}: any) => {
  const { t } = useTranslation();
  const history = useHistory() as any;
  const [tableConfig, setTableConfig] = useState<any>(null);
  const [expandedRows, setExpandedRows] = useState(null);

  const searchConfig = useMemo(
    () => ({
      ...DEFAULT_BT_SEARCH,
      sortObj: {
        key: "lastUpdated",
        direction: -1,
      },
    }),
    []
  );

  useEffect(() => {
    const tableGetData = async () => {
      if (!filterYear) {
        return {
          datas: [],
          searchConfig: searchConfig,
          total: 0,
        };
      }
      const response = (await ClaimService.getApprovalApplications({
        year: filterYear,
        workflowStatus: filterStatus,
      })) as any;

      const data = response.data?.filter((item: any) => !!item);

      return {
        datas: data,
        searchConfig: searchConfig,
        total: response?.pagination?.totalRecords,
      };
    };

    setTableConfig({
      key: `${filterYear} - ${filterStatus}`,
      isClientSize: true,
      configModel: ListConfigModel({
        t,
        isSelectable: filterStatus === CLAIMS_WORKFLOW_STATUS.IN_PROGRESS,
        redirect: history.push,
        toolbar: toolbar,
        setSelectedData: setSelectedData,
        expandedRows,
        setExpandedRows,
      }),
      searchConfig: searchConfig,
      searchFn: tableGetData,
      approvalMode: true,
      selectableKey: "id",
    });
  }, [
    filterStatus,
    filterYear,
    t,
    history.push,
    toolbar,
    setSelectedData,
    expandedRows,
    setExpandedRows,
    searchConfig,
  ]);

  return (
    <div className="claim-approval-list-datatable">
      {tableConfig && <BaseTable ref={tableRef} {...tableConfig} />}
    </div>
  );
};

const Footer = ({ selectedData, tableRef, setSelectedData }: any) => {
  const { t } = useTranslation();
  const history = useHistory() as any;
  const dispatch = useDispatch();

  const remarksRef = useRef();
  const updateRemarks = (value: any) => {
    remarksRef.current = value;
  };

  // submit
  const submitClaimAction = async (action: any) => {
    // api
    const applicationIdList = selectedData.reduce((acc: any, a: any) => {
      acc.push(a.id);
      return acc;
    }, []);

    const body: any = {
      Id: applicationIdList,
      Remarks: remarksRef.current,
      Action: action,
    };

    try {
      //show spinner
      dispatch(showSpinner(true));

      const res = await ClaimsService.claimApprovalAction({
        body: body,
      });

      if (!isResponseOk(res)) throw new Error(`${action} claim failed`);

      showTimestampToastSuccess({
        message:
          action === "approve"
            ? t("claims_approval_toast_approveSuccess")
            : action === "reject"
            ? t("claims_approval_toast_rejectSuccess")
            : "",
        t: t,
      } as any);

      // reload table
      if (tableRef?.current) {
        // tableRef.current.refreshTable();
        tableRef.current.reload();
      }
      dispatch(showSpinner(false));
      setSelectedData([]);
    } catch (e) {
      // error
      dispatch(showSpinner(false));
    }
  };

  const confirmAction = async (type: string) => {
    updateRemarks("");

    const claimFromString = selectedData.reduce((acc: any, a: any) => {
      acc = acc.concat(
        acc.length ? ", " : "",
        a.from && moment(a.from).isValid()
          ? moment(a.from).format(SHORT_DATE_FORMAT)
          : a.from
      );
      return acc;
    }, "");

    dispatch(
      openModal({
        title: t("claims_approval_modal_title"),
        renderModalBody: () => (
          <div className="finalize-claim-modal">
            <div>
              {type === "approve"
                ? t("claims_approval_modal_approveClaimContent", {
                    claimDays: claimFromString,
                  })
                : null}
              {type === "reject"
                ? t("claims_approval_modal_rejectClaimContent", {
                    claimDays: claimFromString,
                  })
                : null}
            </div>
            <label>{t("claims_approval_modal_remarks")}</label>
            <InputText onChange={(e) => updateRemarks(e.target.value)} />
          </div>
        ),
        classNameMainDialog: "confirm-message-modal",
        primaryButtonText: t("claims_common_actionConfirm"),
        primaryButtonClickFn: async ({ closeFn }: any) => {
          closeFn();
          submitClaimAction(
            type === "approve" ? "approve" : type === "reject" ? "reject" : ""
          );
        },
        secondButtonClickFn: ({ closeFn }: any) => {
          closeFn();
        },
      })
    );
  };

  return (
    <div className="footer p-grid p-align-center p-justify-between">
      <div className="button-group-1">
        <Button
          onClick={() => (history as any).goBack()}
          className="p-button-outlined secondary"
        >
          {t("claims_common_actionBack")}
        </Button>
      </div>
      {selectedData.length > 0 && (
        <div className="button-group-2">
          <Button onClick={() => confirmAction("approve")} className="p-button">
            {t("claims_common_actionApprove")}
          </Button>
          <Button onClick={() => confirmAction("reject")} className="p-button">
            {t("claims_common_actionReject")}
          </Button>
        </div>
      )}
    </div>
  );
};

const ClaimApprovalList = () => {
  return (
    <>
      <LanguageLoader
        contexts={[
          EEPORTAL_LABEL_BASE_TABLE.BASE_TABLE,
          CLAIMS_T_CONTEXT_KEY.COMMON,
          CLAIMS_T_CONTEXT_KEY.APPROVAL,
          "UI.Claims.Approval",
        ]}
      />
      <div className="claim-container claim-approval-list">
        <Header />

        <Main />
      </div>
    </>
  );
};

export default ClaimApprovalList;
