import { RefObject } from "react";
import { ConcatDateTime } from "../../../utils/date-helper";
import { TimesheetApplicationModel } from "../../../models/timesheet-application-model";
import {
  ITimesheetDetail,
  ITimesheetDetailActivity,
} from "../../../context/reducer/application";

export const validateRequired = (
  formRef: RefObject<any>,
  setFormTouched: React.Dispatch<React.SetStateAction<boolean>>
) => {
  setFormTouched(true);
  return formRef?.current?.getFormState()?.invalid;
};

export const validateInput = (actvitiesInput: ITimesheetDetailActivity[]) => {
  const messageList: string[] = [];
  if (actvitiesInput) {
    actvitiesInput.forEach((activity) => {
      const isFromDateLessThanOrEqualToDate = lessThanOrEqualTo(
        new Date(activity.fromDate as string),
        new Date(activity.toDate as string),
        "Activity from date",
        "to date"
      );
      if (!isFromDateLessThanOrEqualToDate.isValid) {
        messageList.push(isFromDateLessThanOrEqualToDate.message);
      }

      const totalHoursIsEmpty = isEmpty(activity.appliedUnits);
      if (!totalHoursIsEmpty.isValid) {
        messageList.push(totalHoursIsEmpty.message);
      }

      const startTime = ConcatDateTime(
        new Date(activity.fromDate as string),
        new Date(activity.fromTime as string)
      );
      const endTime = ConcatDateTime(
        new Date(activity.toDate as string),
        new Date(activity.toTime as string)
      );
      const isFromTimeLessThanToTime = lessThan(
        startTime,
        endTime,
        "Activity from time",
        "to time"
      );

      if (!isFromTimeLessThanToTime.isValid) {
        messageList.push(isFromTimeLessThanToTime.message);
      }
    });

    const activitiesHasNoOverlap = hasNoOverlap(
      actvitiesInput.map((activity) => {
        const startTime = ConcatDateTime(
          new Date(activity.fromDate as string),
          new Date(activity.fromTime as string)
        );
        const endTime = ConcatDateTime(
          new Date(activity.toDate as string),
          new Date(activity.toTime as string)
        );

        return {
          startTime,
          endTime,
        };
      })
    );
    if (!activitiesHasNoOverlap.isValid) {
      messageList.push(activitiesHasNoOverlap.message);
    }
  }

  return messageList;
};

const lessThanOrEqualTo = (
  a: number | Date | moment.Moment,
  b: number | Date | moment.Moment,
  descriptionA: string,
  descriptionB: string
) => {
  return {
    isValid: a <= b,
    message: !(a <= b)
      ? `${descriptionA} cannot be larger than ${descriptionB}`
      : "",
  };
};

const lessThan = (
  a: number | Date | moment.Moment,
  b: number | Date | moment.Moment,
  descriptionA: string,
  descriptionB: string
) => {
  return {
    isValid: a < b,
    message: !(a < b)
      ? `${descriptionA} cannot be larger than or equal to ${descriptionB}`
      : "",
  };
};

const isEmpty = (a: number) => {
  return {
    isValid: a !== 0,
    message: !(a !== 0) ? "Applied Units cannot be zero" : "",
  };
};

const hasNoOverlap = (
  periodList: {
    startTime: moment.Moment;
    endTime: moment.Moment;
  }[]
) => {
  let isValid: boolean = true;
  if (periodList.length === 1) {
    isValid = true;
  } else {
    periodList.sort((period1, period2) =>
      period1["startTime"].isBefore(period2["startTime"]) ? -1 : 1
    );

    for (let i = 0; i < periodList.length - 1; i++) {
      const currentEndTime = periodList[i]["endTime"];
      const nextStartTime = periodList[i + 1]["startTime"];

      if (currentEndTime > nextStartTime) {
        isValid = false;
        break;
      }
    }
  }

  return {
    isValid,
    message: !isValid ? "Activities cannot have overlap" : "",
  };
};
