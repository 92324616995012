import { CLAIMS_MENU_ACTION_KEY } from "../../constants";

export const MyApplcations = {
  value: 0,
  name: "claims_applicationList_title",
  key: CLAIMS_MENU_ACTION_KEY.CLAIMS_MY_APPLICATIONS,
  type: "Action",
  description: "claims_applicationList_title",
};

export const DelegatedApplcations = {
  value: 1,
  name: "claims_applicationList_delegation_title",
  key: CLAIMS_MENU_ACTION_KEY.CLAIMS_DELEGATED_APPLICATIONS,
  type: "Action",
  description: "claims_applicationList_delegation_title",
};

export const AllApplcations = {
  value: 2,
  name: "claims_applicationList_hrView_title",
  key: CLAIMS_MENU_ACTION_KEY.CLAIMS_ALL_APPLICATIONS,
  type: "Action",
  description: "claims_applicationList_hrView_title",
};
