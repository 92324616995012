import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  DEFAULT_DATE_FORMAT,
  FUNCTION_CODE,
  MATCH_MODE,
  MENU_NAME,
  MODULE_CODE,
} from "../../../../constants";
import {
  resetMainForm,
  resetMainFormConfig,
  resetMainFormValue,
} from "../../../../redux/actions/form";
import BaseTable from "../../../../components/base-table/base-table-portal";
import { Animated } from "react-animated-css";
import {
  DEFAULT_BT_SEARCH,
  getBTConfig,
  getColumModel,
} from "../../../../components/base-table/base-table-model";
import { UserManagementService } from "./../../../../services/hrmnet-api";
import { useWindowSize } from "../../utils/window-size/useWindowSize";
import { Column } from "primereact/column";
import { Skeleton } from "primereact/skeleton";
import { DataTable } from "primereact/datatable";
import { getControlModel } from "../../../../components/base-control/base-cotrol-model";
import { BCType } from "../../../../components/base-control/base-control";
import moment from "moment";
import { GetCurrentActions } from "../../../layouts/components/main-sidebar/main-menu-tree";
import { Button } from "primereact/button";
import { PortalLink } from "../../../../services/utils/index";
import { showSpinner } from "../../../../redux/actions/spinner";
import { getResponseMessage, isResponseOk } from "../../../../utils/utils";
import {
  showErrorMessageModal,
  showTimestampToastSuccess,
} from "../../../../services/utils/message";

const EMAIL_CONFIGURATION_SORT_KEY = "module";

const EmployeeActivate = () => {
  const { isMobile } = useWindowSize();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const dataTable = useRef();
  const [rerenderLanguage, setRerenderLanguage] = useState(false);
  const [selectedEmployeeCodes, setSelectedEmployeeCode] = useState([]);

  /** Default search */
  const BT_SEARCH = {
    ...DEFAULT_BT_SEARCH,
    sortObj: {
      key: EMAIL_CONFIGURATION_SORT_KEY,
      direction: 1,
    },
  };

  const [state, setState] = useState({
    isLoading: true,
    detailExpandedRows: null,
    tableConfig: null,
  });

  const selectedLangKey = useSelector(
    (state) => state.language.language?.selectedLang?.key
  );
  useEffect(() => {
    const loadInit = async () => {
      const _state = state;

      _state.isLoading = false;
      setState(_state);

      if (rerenderLanguage) {
        dataTable.current?.rerenderLanguage();
      }

      dispatch(resetMainFormConfig());
      dispatch(resetMainForm());
      dispatch(resetMainFormValue());
    };
    loadInit();

    return () => {
      setState({
        ...state,
        isLoading: true,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLangKey]);

  const tableSearchFn = async () => {
    let _datas = [];
    let currentTable = {
      datas: [],
      total: 0,
      searchConfig: BT_SEARCH,
    };
    setRerenderLanguage(true);
    try {
      var cmd = await UserManagementService.getEmployeeList();
      console.log("cmd: ", cmd);
      const [res] = await Promise.all([cmd]);
      if (res && res.data) {
        _datas = res.data
          .filter((employee) => employee.oktaUserStatus === "STAGED")
          .map((data) => {
            return {
              ...data,
              employeeCommenced: moment(
                data.employeeCommenced,
                DEFAULT_DATE_FORMAT
              ),
              dateJoinGroup: moment(data.dateJoinGroup, DEFAULT_DATE_FORMAT),
              employeeTerminates: moment(
                data.employeeTerminates,
                DEFAULT_DATE_FORMAT
              ),
              lastWorkDate: moment(data.lastWorkDate, DEFAULT_DATE_FORMAT),
            };
          });
      }

      currentTable = {
        datas: _datas,
        total: _datas.length,
        searchConfig: BT_SEARCH,
      };
      return currentTable;
    } catch (error) {
      return currentTable;
    }
  };

  let configColumns = [
    getColumModel({
      header: t("User_Management_Employee_Details_Employee_Code"),
      key: "employeeCode",
      dataType: "string",
      config: {
        sortable: true,
      },
      isRowExpansion: false,
      mobileWidthAuto: true,
      control: getControlModel({
        placeholder: t(
          "User_Management_Employee_Details_Employee_Code_Placeholder"
        ),
      }),
      frozen: true,
    }),
    getColumModel({
      header: t("User_Management_Employee_Details_Alt_Employee_Code"),
      key: "altEmpCode",
      width: 12,
      dataType: "string",
      config: {
        sortable: true,
      },
      isRowExpansion: false,
      mobileWidthAuto: true,
      control: getControlModel({
        placeholder: t(
          "User_Management_Employee_Details_Employee_Code_Placeholder"
        ),
      }),
      frozen: false,
    }),
    getColumModel({
      header: t("User_Management_Employee_Details_Commenced_Date"),
      key: "employeeCommenced",
      dataType: BCType.date,
      config: {
        dateFormat: DEFAULT_DATE_FORMAT,
        sortable: true,
      },
      isRowExpansion: true,
      mobileWidthAuto: true,
      control: getControlModel({
        placeholder: t(
          "User_Management_Employee_Details_Commenced_Date_Placeholder"
        ),
        type: BCType.daterange,
      }),
      filterMatchMode: MATCH_MODE.RANGE,
      body: (e) => {
        let formattedDate = moment(e.employeeCommenced).format(
          DEFAULT_DATE_FORMAT
        );
        return (
          <div
            dangerouslySetInnerHTML={{
              __html: formattedDate === "Invalid date" ? "" : formattedDate,
            }}
          />
        );
      },
      frozen: false,
    }),
    getColumModel({
      header: t("User_Management_Employee_Details_New_Salary_Effective_Date"),
      key: "newSalaryEffDate",
      width: 12,
      dataType: BCType.date,
      config: {
        dateFormat: DEFAULT_DATE_FORMAT,
        sortable: true,
      },
      isRowExpansion: true,
      mobileWidthAuto: true,
      control: getControlModel({
        placeholder: t(
          "User_Management_Employee_Details_New_Salary_Effective_Date_Placeholder"
        ),
        type: BCType.daterange,
      }),
      filterMatchMode: MATCH_MODE.RANGE,
      body: (e) => {
        let formattedDate = moment(e.newSalaryEffDate).format(
          DEFAULT_DATE_FORMAT
        );
        return (
          <div
            dangerouslySetInnerHTML={{
              __html: formattedDate === "Invalid date" ? "" : formattedDate,
            }}
          />
        );
      },
      frozen: false,
    }),
    getColumModel({
      header: t("User_Management_Employee_Details_Monthly_Fixed_Wage"),
      key: "monthlyFixedWage",
      width: 12,
      dataType: "string",
      config: {
        sortable: true,
      },
      isRowExpansion: false,
      mobileWidthAuto: true,
      control: getControlModel({
        placeholder: t(
          "User_Management_Employee_Details_Monthly_Fixed_Wage_Placeholder"
        ),
      }),
      frozen: false,
    }),
    getColumModel({
      header: t("User_Management_Employee_Details_Last_Monthly_Fixed_Wage"),
      key: "lastMonthlyFixedWage",
      width: 15,
      dataType: "string",
      config: {
        sortable: true,
      },
      isRowExpansion: false,
      mobileWidthAuto: true,
      control: getControlModel({
        placeholder: t(
          "User_Management_Employee_Details_Last_Monthly_Fixed_Wage_placeholder"
        ),
      }),
      frozen: false,
    }),
    getColumModel({
      header: t("User_Management_Employee_Details_Surname"),
      key: "surname",
      dataType: "string",
      config: {
        sortable: true,
      },
      isRowExpansion: false,
      mobileWidthAuto: true,
      control: getControlModel({
        placeholder: t("User_Management_Employee_Details_Surname_Placeholder"),
      }),
      frozen: false,
    }),
    getColumModel({
      header: t("User_Management_Employee_Details_GivenName"),
      key: "otherNames",
      dataType: "string",
      config: {
        sortable: true,
      },
      isRowExpansion: false,
      mobileWidthAuto: true,
      control: getControlModel({
        placeholder: t(
          "User_Management_Employee_Details_GivenName_Placeholder"
        ),
      }),
      frozen: false,
    }),
    getColumModel({
      header: t("User_Management_Employee_Details_Email"),
      key: "email",
      dataType: "string",
      config: {
        sortable: true,
      },
      isRowExpansion: false,
      mobileWidthAuto: true,
      control: getControlModel({
        placeholder: t("User_Management_Employee_Details_Email_Placeholder"),
      }),
      frozen: false,
    }),
    getColumModel({
      header: t("User_Management_Employee_Details_Department"),
      key: "department",
      dataType: "string",
      config: {
        sortable: true,
      },
      isRowExpansion: false,
      mobileWidthAuto: true,
      control: getControlModel({
        placeholder: t(
          "User_Management_Employee_Details_Department_Placeholder"
        ),
      }),
      frozen: false,
    }),
    getColumModel({
      header: t("User_Management_Employee_Details_Date_Join_Group"),
      key: "dateJoinGroup",
      dataType: BCType.date,
      config: {
        dateFormat: DEFAULT_DATE_FORMAT,
        sortable: true,
      },
      isRowExpansion: true,
      mobileWidthAuto: true,
      control: getControlModel({
        placeholder: t(
          "User_Management_Employee_Details_Date_Join_Group_Placeholder"
        ),
        type: BCType.daterange,
      }),
      filterMatchMode: MATCH_MODE.RANGE,
      body: (e) => {
        let formattedDate = moment(e.dateJoinGroup).format(DEFAULT_DATE_FORMAT);
        return (
          <div
            dangerouslySetInnerHTML={{
              __html: formattedDate === "Invalid date" ? "" : formattedDate,
            }}
          />
        );
      },
      frozen: false,
    }),
    getColumModel({
      header: t("User_Management_Employee_Details_Termination_Date"),
      key: "employeeTerminates",
      dataType: BCType.date,
      config: {
        dateFormat: DEFAULT_DATE_FORMAT,
        sortable: true,
      },
      isRowExpansion: true,
      mobileWidthAuto: true,
      control: getControlModel({
        placeholder: t(
          "User_Management_Employee_Details_Termination_Date_Placeholder"
        ),
        type: BCType.daterange,
      }),
      filterMatchMode: MATCH_MODE.RANGE,
      body: (e) => {
        let formattedDate = moment(e.employeeTerminates).format(
          DEFAULT_DATE_FORMAT
        );
        return (
          <div
            dangerouslySetInnerHTML={{
              __html: formattedDate === "Invalid date" ? "" : formattedDate,
            }}
          />
        );
      },
      frozen: false,
    }),
    getColumModel({
      header: t("User_Management_Employee_Details_Last_Working_Date"),
      key: "lastWorkDate",
      width: 12,
      dataType: BCType.date,
      config: {
        dateFormat: DEFAULT_DATE_FORMAT,
        sortable: true,
      },
      isRowExpansion: true,
      mobileWidthAuto: true,
      control: getControlModel({
        placeholder: t(
          "User_Management_Employee_Details_Last_Working_Date_Placeholder"
        ),
        type: BCType.daterange,
      }),
      filterMatchMode: MATCH_MODE.RANGE,
      body: (e) => {
        let formattedDate = moment(e.lastWorkDate).format(DEFAULT_DATE_FORMAT);
        return (
          <div
            dangerouslySetInnerHTML={{
              __html: formattedDate === "Invalid date" ? "" : formattedDate,
            }}
          />
        );
      },
      frozen: false,
    }),
    getColumModel({
      header: t("User_Management_Employee_Details_Personal_Email"),
      key: "alternativeEmail",
      dataType: "string",
      config: {
        sortable: true,
      },
      isRowExpansion: false,
      mobileWidthAuto: true,
      control: getControlModel({
        placeholder: t(
          "User_Management_Employee_Details_Personal_Email_Placeholder"
        ),
      }),
      frozen: false,
    }),
    getColumModel({
      header: t("User_Management_Employee_Details_Company_Code"),
      key: "companyCode",
      dataType: "string",
      config: {
        sortable: true,
      },
      isRowExpansion: true,
      mobileWidthAuto: true,
      control: getControlModel({
        placeholder: t(
          "User_Management_Employee_Details_Company_Code_Placeholder"
        ),
      }),
    }),
    getColumModel({
      header: t("User_Management_Employee_Details_Payslip_Name"),
      key: "payslipName",
      dataType: "string",
      config: {
        sortable: true,
      },
      isRowExpansion: true,
      mobileWidthAuto: true,
      control: getControlModel({
        placeholder: t(
          "User_Management_Employee_Details_Payslip_Name_Placeholder"
        ),
      }),
    }),
    getColumModel({
      header: t("User_Management_Employee_Details_Direct_Report_To"),
      key: "directReportto",
      dataType: "string",
      config: {
        sortable: true,
      },
      isRowExpansion: true,
      mobileWidthAuto: true,
      control: getControlModel({
        placeholder: t(
          "User_Management_Employee_Details_Direct_Report_To_Placeholder"
        ),
      }),
    }),
    getColumModel({
      header: t("User_Management_Employee_Details_Grade"),
      key: "grade",
      dataType: "string",
      config: {
        sortable: true,
      },
      isRowExpansion: true,
      mobileWidthAuto: true,
      control: getControlModel({
        placeholder: t("User_Management_Employee_Details_Grade_Placeholder"),
      }),
    }),
    getColumModel({
      header: t("User_Management_Employee_Details_Status"),
      key: "status",
      dataType: "string",
      config: {
        sortable: true,
      },
      isRowExpansion: true,
      mobileWidthAuto: true,
      control: getControlModel({
        placeholder: t("User_Management_Employee_Details_Status_Placeholder"),
      }),
    }),
    getColumModel({
      header: t("User_Management_Employee_Details_Payroll_Indicator"),
      key: "payrollIndicator",
      dataType: "string",
      config: {
        sortable: true,
      },
      isRowExpansion: true,
      mobileWidthAuto: true,
      control: getControlModel({
        placeholder: t(
          "User_Management_Employee_Details_Payroll_Indicator_Placeholder"
        ),
      }),
    }),
    getColumModel({
      header: t("User_Management_Employee_Details_Holiday_Class"),
      key: "holidayclass",
      dataType: "string",
      config: {
        sortable: true,
      },
      isRowExpansion: true,
      mobileWidthAuto: true,
      control: getControlModel({
        placeholder: t(
          "User_Management_Employee_Details_Holiday_Class_Placeholder"
        ),
      }),
    }),
    getColumModel({
      header: t("User_Management_Employee_Details_Okta_User_Status"),
      key: "oktaUserStatus",
      dataType: "string",
      config: {
        sortable: true,
      },
      isRowExpansion: true,
      mobileWidthAuto: true,
      control: getControlModel({
        placeholder: t(
          "User_Management_Employee_Details_Okta_User_Status_Placeholder"
        ),
      }),
    }),
  ];

  const configModel = getBTConfig({
    columns:
      (isMobile && configColumns.filter((x) => !x.isRowExpansion)) ||
      configColumns,
    actionsSingle: [{}],
    actionWidth: 0,
    hasIndex: false,
    // hasColumnSelector: true,
    tableConfig: { emptyMessage: t("no_employees_pending_for_activation") },
    hasSelection: true,
    actionsMulti: [{}],
    onBaseSelectionChange: (event) => {
      setSelectedEmployeeCode(
        event.value.map((employee) => employee.employeeCode)
      );
    },
    childActionCancel: () => {
      //   console.log("cancel pressed");
      setSelectedEmployeeCode([]);
    },
    mode: "list",
    defaultMode: "list",
    showGlobal: true,
    isExpandable: isMobile,
    expandedRows: state.expandedRows,
    onRowToggle: (e) => {
      let newState = {
        ...state,
        expandedRows: e.data,
      };
      setState(newState);
    },
    rowExpansionTemplate: (data) => {
      const mobileColumnsRender = [];
      configColumns
        .filter((x) => x.isRowExpansion)
        .forEach((y, index) =>
          mobileColumnsRender.push(
            <Column
              {...{
                key: `bt-sub-column-${index}`,
                field: y.key,
                header: y.header,
                style: { width: "8rem" },
                headerStyle: { width: "8rem" },
                body: (rowData, config) => {
                  if (!data) {
                    return <Skeleton></Skeleton>;
                  } else {
                    return (
                      <div
                        className={`bt-row bt-row-${config.rowIndex} bt-cell table-expandable-row`}
                      >
                        <span className="p-column-title">{y.header}:</span>
                        <div className="bt-cell-value">
                          {y.dataType === BCType.date
                            ? moment(new Date(rowData[y.key])).format(
                                y.config?.dateFormat
                              )
                            : rowData[y.key]}
                        </div>
                      </div>
                    );
                  }
                },
              }}
            />
          )
        );

      return (
        <DataTable
          value={[data]}
          key="detail-mobile-table"
          className="p-datatable-mobiletable"
        >
          {mobileColumnsRender}
        </DataTable>
      );
    },
  });

  const renderFormTitle = () => (
    <div className="form-title">
      <h2>{t("User_Management_Employee_Activate")}</h2>
    </div>
  );

  const renderFooter = () => {
    return (
      <div className="footer p-grid p-align-center p-justify-between">
        <div>
          <Button
            label={t("admin_common_actionCancel")}
            className="p-button-secondary"
            onClick={() =>
              history.push(
                PortalLink(
                  `${MODULE_CODE.Admin}/${FUNCTION_CODE.UserManagementEmployeeList}`
                )
              )
            }
          />
        </div>
        <div className="right-button-group">
          <Button
            disabled={selectedEmployeeCodes.length === 0}
            onClick={() => activateEmployees()}
            className="p-button next-button"
          >
            {t("admin_common_actionActivate")}
          </Button>
        </div>
      </div>
    );
  };

  const activateEmployees = async () => {
    try {
      // show spinner
      dispatch(showSpinner(true));
      let res = await UserManagementService.activateEmployees(
        selectedEmployeeCodes
      );
      if (res.data.status === true) {
        showTimestampToastSuccess({
          message: t("User_Management_Employee_Activation_SuccessMessage"),
          t: t,
        });
        history.push(
          PortalLink(
            `${MODULE_CODE.Admin}/${FUNCTION_CODE.UserManagementEmployeeList}`
          )
        );
      } else {
        showErrorMessageModal({
          dispatch,
          t,
          content: getResponseMessage(res),
        });
      }
    } catch (e) {
      // show notification?
      console.error(e.message);
    } finally {
      // end spinner
      dispatch(showSpinner(false));
    }
  };

  return (
    <>
      <div className={`employee_activate-select-form`}>
        <div className="header header-sticky">
          {/* Title */}
          {renderFormTitle()}
        </div>
        <div className="employee_activate-ms-form">
          <Animated
            animationIn="slideInRight"
            animationOut="slideOutRight"
            animationInDuration={200}
            animationOutDuration={200}
            isVisible={true}
          >
            <div className="employee_activate-table">
              <BaseTable
                ref={dataTable}
                configModel={configModel}
                searchConfig={BT_SEARCH}
                searchFn={() => tableSearchFn()}
                isClientSize={true}
                id="employee_activate-table"
              />
            </div>
          </Animated>
        </div>
        {/* Footer */}
        {renderFooter()}
      </div>
    </>
  );
};

export default EmployeeActivate;
