import { getColumModel } from "../../../../../components/base-table/base-table-model";
import { TimesheetStatus } from "../../components";
import {
  SHORT_DATE_FORMAT,
  SHORT_DATE_WITH_WEEKDAY_FORMAT,
} from "../../constants";
import { getDateFromString } from "../../utils/date-helper";

const employeeCodeColumn = getColumModel({
  header: "timesheet_list_employeeCode",
  key: "employeeCode",
  dataType: "string",
  sortable: true,
  hideMobile: true,
});

const employeeDisplayNameColumn = getColumModel({
  header: "timesheet_list_employeeName",
  key: "employeeName",
  dataType: "string",
  sortable: true,
  hideMobile: false,
});

const approverDisplayNameColumn = getColumModel({
  header: "timesheet_list_approverName",
  key: "approverName",
  dataType: "string",
  sortable: true,
  hideMobile: true,
});

const fromColumn = getColumModel({
  header: "timesheet_list_from",
  key: "from",
  dataType: "string",
  sortable: true,
  width: 9,
  hideMobile: true,
  render: (data) => {
    const time = getDateFromString(data.from, SHORT_DATE_FORMAT);
    return <span>{time}</span>;
  },
});

const toColumn = getColumModel({
  header: "timesheet_list_to",
  key: "to",
  dataType: "string",
  sortable: true,
  width: 9,
  hideMobile: true,
  render: (data) => {
    const time = getDateFromString(data.to, SHORT_DATE_FORMAT);
    return <span>{time}</span>;
  },
});

const totalHoursColumn = getColumModel({
  header: "timesheet_list_totalHoursReported",
  key: "totalHoursDesc",
  dataType: "string",
  sortable: false,
  hideMobile: false,
});

const totalLeaveHoursColumn = getColumModel({
  header: "timesheet_list_totalLeaveHoursReported",
  key: "totalLeaveHoursDesc",
  dataType: "string",
  sortable: false,
  hideMobile: false,
});

const statusColumn = getColumModel({
  header: "timesheet_list_status",
  key: "status",
  dataType: "string",
  sortable: true,
  width: 12,
  hideMobile: true,
  render: (data) => <TimesheetStatus status={data.trnsStatus} data={data} />,
});

const submittedByColumn = getColumModel({
  header: "timesheet_list_submittedBy",
  key: "submittedBy",
  dataType: "string",
  sortable: true,
  hideMobile: true,
});

const submissionDateColumn = getColumModel({
  header: "timesheet_list_submissionDate",
  key: "submissionDate",
  dataType: "string",
  sortable: true,
  width: 11,
  hideInMobile: true,
  render: (data) => {
    const datetime = getDateFromString(
      data.submissionDate,
      SHORT_DATE_WITH_WEEKDAY_FORMAT
    );
    return <span>{datetime}</span>;
  },
});

const referenceNoColumn = getColumModel({
  header: "timesheet_list_ref_no",
  key: "referenceNo",
  dataType: "string",
  sortable: true,
  width: 12,
  hideMobile: false,
});

export const ApprovalListColumns = [
  employeeCodeColumn,
  employeeDisplayNameColumn,
  approverDisplayNameColumn,
  fromColumn,
  toColumn,
  totalHoursColumn,
  totalLeaveHoursColumn,
  statusColumn,
  submittedByColumn,
  submissionDateColumn,
  referenceNoColumn,
];
