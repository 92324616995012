import { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import BaseTable from "../../../../../components/base-table/base-table-portal-rental";
import TimesheetService from "../../../../../services/hrmnet-api/timesheet";
import { IAxiosResponse, IGetApprovalApplicationData } from "../../constants";
import { ListConfigModel } from "./table-config";
import { DEFAULT_BT_SEARCH } from "../../../../../components/base-table/base-table-model";
import { SelectControlOption } from "../../../../../components/base-control/select-control/select-control";
import { store } from "../../../../../redux/store";
import { MessageSwitch2 } from "../../../../../services/utils/message-2";
import {
  MESSAGE_SEVERITY,
  RESPONSE_MESSAGE_TYPE,
} from "../../../../../constants";
import { useHistory } from "react-router-dom";
import { formatTwoDecimals } from "../../utils/format-helper";

export const Table = ({
  toolbar,
  filteredStatus,
  filteredYear,
  tableRef,
}: {
  toolbar: JSX.Element;
  filteredStatus: SelectControlOption["value"];
  filteredYear: SelectControlOption["value"];
  tableRef: React.MutableRefObject<any>;
}) => {
  const { t, i18n } = useTranslation();
  const toast = store.getState().global.toast;
  const history = useHistory() as any;

  const [tableConfig, setTableConfig] = useState<any>(null);
  const [expandedRows, setExpandedRows] = useState<any>(null);

  const searchConfig = useMemo(
    () => ({
      ...DEFAULT_BT_SEARCH,
      sortObj: {
        key: "submissionDate",
        direction: -1,
      },
    }),
    []
  );

  useEffect(() => {
    const tableGetData = async () => {
      const response: IAxiosResponse<IGetApprovalApplicationData> =
        await TimesheetService.getApprovalApplications({
          params: { year: filteredYear, workflowStatus: filteredStatus },
        });
      if (!response) {
        MessageSwitch2(
          {
            messages: {
              statusText: MESSAGE_SEVERITY.ERROR,
              text: "Error fetching data",
              type: RESPONSE_MESSAGE_TYPE.ERROR,
            },
          },
          toast,
          null,
          i18n
        );
        return {
          datas: [],
          searchConfig: searchConfig,
          total: 0,
        };
      }
      const res = response.data?.filter((item: any) => !!item);

      const data = res?.map((dat: any) => ({
        ...dat,
        totalHours: formatTwoDecimals(Number(dat.totalHours)),
      }));

      return {
        datas: data,
        searchConfig: searchConfig,
        total: data.length,
      };
    };

    setTableConfig({
      key: `${filteredYear} - ${filteredStatus}`,
      isClientSize: true,
      configModel: ListConfigModel({
        t,
        isSelectable: false,
        toolbar: toolbar,
        redirect: history.push,
        expandedRows,
        setExpandedRows,
      }),
      searchConfig: searchConfig,
      searchFn: tableGetData,
      approvalMode: true,
      selectableKey: "id",
    });
  }, [
    filteredYear,
    t,
    toolbar,
    filteredStatus,
    toast,
    i18n,
    history.push,
    searchConfig,
    expandedRows,
    setExpandedRows,
  ]);

  return (
    <div className="timesheet-approval-list-datatable">
      {tableConfig && <BaseTable ref={tableRef} {...tableConfig} />}
    </div>
  );
};
