import { getColumModel } from "./../../../../../components/base-table/base-table-model";
import moment from "moment";
import { BCType } from "../../../../../components/base-control/base-control";
import { convertApiDateFormatToPrime } from "../../../../../utils/utils";
import { filter } from "lodash";
import { SHORT_DATE_FORMAT } from "../../constants";
import { ClaimsReportService } from "../../../../../services/hrmnet-api";
import { useTranslation } from "react-i18next";
import { ToUIOption } from "src/portal/modules/public/login/components/utils";

export const CLAIMS_REPORT_TYPE = {
  OT_MEAL_ALLOWANCE_TRANSACTION_REPORT: "OT_MEAL_ALLOWANCE_TRANSACTION",
  OT_CLAIM_SUMMARY_REPORT: "OT_CLAIM_SUMMARY",
  OT_CLAIM_DETAILS_REPORT: "OT_CLAIM_DETAILS",
  CLAIM_DETAILS_REPORT: "CLAIM_DETAILS",
  CLAIM_SUMMARY_REPORT: "CLAIM_SUMMARY",
};

export const CLAIMS_REPORT_KEY = {
  OT_MEAL_ALLOWANCE_TRANSACTION_REPORT:
    "eClaim_Ot_Meal_Allowance_Transaction_Report",
  OT_CLAIM_SUMMARY_REPORT: "eClaim_Ot_Claim_Summary_Report",
  OT_CLAIM_DETAILS_REPORT: "eClaim_Ot_Claim_Details_Report",
  CLAIM_DETAILS_REPORT: "eClaim_Claim_Details_Report",
  CLAIM_SUMMARY_REPORT: "eClaim_Claim_Summary_Report",
};

export const CLAIMS_REPORT_EXTRA_FIELDS = {
  OT_MEAL: "mealAllowanceUnits",
  OT_TRANSPORT: "TransportFare",
  DAY_IN_LIEU_PAYMENT: "dayInLieuPayment",
  OT_REQUESTED_BY: "RequestedBy",
  MUTUAL_AGREED: "MutualAgreed",
  TOTAL_OT_TRANSPORT: "TotalTransportFare",
  TOTAL_OT_MEAL: "TotalMealAllowanceUnits",
};

export const WEB_PARAMETER_SETTING_TYPE = {
  OT_CLAIM_TRANS_LIST_EXTRA_FIELDS: "OT_Claim_Trans_List_Extra_Fields",
};

// Filter config
const employeeCodeFilter = ({ employeeCodeFilterOption, t }) => ({
  key: "employeeCode",
  type: BCType.multiselect,
  label: t("claims_report_filter_employeeCode"),
  enum: employeeCodeFilterOption,
  placeholder: t("base_control_select_choose"),
  config: { filter: true },
});

const reportingLineFilter = ({ reportingLineFilterOption, t }) => ({
  key: "reportingLine",
  type: BCType.select,
  label: t("claims_report_filter_reportingLine"),
  enum: reportingLineFilterOption,
  placeholder: t("base_control_select_choose"),
});

const claimsDateFromFilter = ({ dateFormat = "yyyy-MM-dd", t }) => ({
  key: "claimDateFrom",
  type: BCType.date,
  label: t("claims_report_filter_claimDateFrom"),
  dateFormat: convertApiDateFormatToPrime(dateFormat),
  placeholder: dateFormat?.toUpperCase(),
});

const claimsDateToFilter = ({ dateFormat = "yyyy-MM-dd", t }) => ({
  key: "claimDateTo",
  type: BCType.date,
  label: t("claims_report_filter_claimDateTo"),
  dateFormat: convertApiDateFormatToPrime(dateFormat),
  placeholder: dateFormat?.toUpperCase(),
});

const claimsDateFromMonthFilter = ({ dateFormat = "yyyy-MM", t }) => ({
  key: "claimDateFromMonth",
  type: BCType.date,
  label: t("claims_report_filter_claimDateFrom"),
  dateFormat: convertApiDateFormatToPrime(dateFormat),
  placeholder: dateFormat?.toUpperCase(),
  config: {
    monthPicker: true,
    yearRange: `${new Date().getFullYear() - 10}:${
      new Date().getFullYear() + 1
    }`,
  },
});

const claimsDateToMonthFilter = ({ dateFormat = "yyyy-MM", t }) => ({
  key: "claimDateToMonth",
  type: BCType.date,
  label: t("claims_report_filter_claimDateTo"),
  dateFormat: convertApiDateFormatToPrime(dateFormat),
  placeholder: dateFormat?.toUpperCase(),
  config: {
    monthPicker: true,
    yearRange: `${new Date().getFullYear() - 10}:${
      new Date().getFullYear() + 1
    }`,
  },
});

const claimsItemFilter = ({ claimTypeFilterOptions, t }) => ({
  key: "claimType",
  type: BCType.select,
  label: t("claims_report_filter_claimItem"),
  enum: claimTypeFilterOptions,
  placeholder: t("base_control_select_choose"),
});

const claimsItemMultiSelectFilter = ({ claimTypeFilterOptions, t }) => ({
  key: "claimItemMulti",
  type: BCType.multiselect,
  label: t("claims_report_filter_claimItem"),
  enum: claimTypeFilterOptions,
  placeholder: t("base_control_select_choose"),
  config: {
    display: "chip",
    tooltip: false,
  },
});

const transactionTypeFilter = ({ transactionTypeFilterOptions, t }) => ({
  key: "transactionType",
  type: BCType.multiselect,
  label: t("claims_report_filter_transactionType"),
  enum: transactionTypeFilterOptions,
  placeholder: t("base_control_select_choose"),
  config: {
    display: "chip",
    tooltip: false,
  },
});

const transactionStatusFilter = ({ transactionStatusFilterOptions, t }) => ({
  key: "transactionStatus",
  type: BCType.multiselect,
  label: t("claims_report_filter_transactionStatus"),
  enum: transactionStatusFilterOptions,
  placeholder: t("base_control_select_choose"),
  config: {
    display: "chip",
    tooltip: false,
  },
});

// const transactionStatusFilter = ({ transactionStatusFilterOptions, t }) => ({
//   key: "transactionStatus",
//   type: BCType.multiselect,
//   label: t("claims_report_filter_transactionStatus"),
//   enum: transactionStatusFilterOptions,
//   placeholder: t("base_control_select_choose"),
//   config: {
//     display: "chip",
//     tooltip: false
//   }
// });

const otClaimTransactionReportFilter = (config, filterWithOption) => [
  [employeeCodeFilter(config), reportingLineFilter(config)],
  [claimsDateFromFilter(config), claimsDateToFilter(config)],
  [
    ...(filterWithOption.includes("claimTypeFilterOptions")
      ? [claimsItemMultiSelectFilter(config)]
      : []),
    transactionTypeFilter(config),
  ],
];

const otClaimSummaryReportFilter = (config, filterWithOption) => [
  [employeeCodeFilter(config), reportingLineFilter(config)],
  [claimsDateFromFilter(config), claimsDateToFilter(config)],
  [
    ...(filterWithOption.includes("claimTypeFilterOptions")
      ? [claimsItemMultiSelectFilter(config)]
      : []),
    transactionTypeFilter(config),
  ],
];

const otClaimDetailsReportFilter = (config, filterWithOption) => [
  [employeeCodeFilter(config), reportingLineFilter(config)],
  [claimsDateFromFilter(config), claimsDateToFilter(config)],
  [
    ...(filterWithOption.includes("claimTypeFilterOptions")
      ? [claimsItemMultiSelectFilter(config)]
      : []),
    transactionTypeFilter(config),
  ],
];

const claimDetailsReportFilter = (config, filterWithOption) => [
  [employeeCodeFilter(config), reportingLineFilter(config)],
  [claimsDateFromFilter(config), claimsDateToFilter(config)],
  [
    ...(filterWithOption.includes("claimTypeFilterOptions")
      ? [claimsItemMultiSelectFilter(config)]
      : []),
    transactionTypeFilter(config),
  ],
  [transactionStatusFilter(config)],
];

const claimSummaryReportFilter = (config, filterWithOption) => [
  [employeeCodeFilter(config), reportingLineFilter(config)],
  [claimsDateFromFilter(config), claimsDateToFilter(config)],
  [
    ...(filterWithOption.includes("claimTypeFilterOptions")
      ? [claimsItemMultiSelectFilter(config)]
      : []),
    transactionTypeFilter(config),
  ],
  [transactionStatusFilter(config)],
];

//#region Report Column Config
// Report Column Config
export const CLAIMS_REPORT_DEFAULT_COLUMN_CONFIG = {
  width: 11,
  dataType: "string",
  sortable: false,
};

// Claims Transaction
const submissionDate = (t) => ({
  ...CLAIMS_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("claims_report_column_SubmissionDate"),
  key: "submissionDate",
});

const submissionTime = (t) => ({
  ...CLAIMS_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("claims_report_column_SubmissionTime"),
  key: "submissionTime",
});

const claimReference = (t) => ({
  ...CLAIMS_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("claims_report_column_ClaimReference"),
  key: "referenceNo",
});

const seqNo = (t) => ({
  ...CLAIMS_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("claims_report_column_SeqNo"),
  key: "seqNo",
  width: "8",
});

const referenceNo = (t) => ({
  ...CLAIMS_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("claims_report_column_ReferenceNo"),
  key: "referenceNo",
});

const transactionSeq = (t) => ({
  ...CLAIMS_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("claims_report_column_TransactionSeq"),
  key: "transactionSeq",
});

const company = (t) => ({
  ...CLAIMS_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("claims_report_column_Company"),
  key: "company",
  width: "12",
});

const location = (t) => ({
  ...CLAIMS_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("claims_report_column_Location"),
  key: "location",
});

const employeeId = (t) => ({
  ...CLAIMS_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("claims_report_column_EmployeeId"),
  key: "employeeCode",
});

const employeeCode = (t) => ({
  ...CLAIMS_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("claims_report_column_EmployeeCode"),
  key: "employeeCode",
});

const employee = (t) => ({
  ...CLAIMS_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("claims_report_column_Employee"),
  key: "employeeName",
});

const employeeName = (t) => ({
  ...CLAIMS_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("claims_report_column_EmployeeName"),
  key: "employeeName",
});

const department = (t) => ({
  ...CLAIMS_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("claims_report_column_Department"),
  key: "department",
});

const claimItem = (t) => ({
  ...CLAIMS_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("claims_report_column_ClaimType"),
  key: "claimType",
});

const transactionType = (t) => ({
  ...CLAIMS_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("claims_report_column_TransactionType"),
  key: "transactionType",
});

const fromDate = (t) => ({
  ...CLAIMS_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("claims_report_column_FromDate"),
  key: "fromDate",
  width: "11",
});

const fromTime = (t) => ({
  ...CLAIMS_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("claims_report_column_FromTime"),
  key: "fromTime",
});

const toDate = (t) => ({
  ...CLAIMS_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("claims_report_column_ToDate"),
  key: "toDate",
  width: "11",
});

const toTime = (t) => ({
  ...CLAIMS_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("claims_report_column_ToTime"),
  key: "toTime",
});

const otApplicationDate = (t) => ({
  ...CLAIMS_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("claims_report_column_OtApplicationDate"),
  key: "fromDate",
});

const transDateFrom = (t) => ({
  ...CLAIMS_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("claims_report_column_TransDateFrom"),
  key: "transDateFrom",
});

const transTimeFrom = (t) => ({
  ...CLAIMS_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("claims_report_column_TransTimeFrom"),
  key: "transTimeFrom",
});

const transDateTo = (t) => ({
  ...CLAIMS_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("claims_report_column_TransDateTo"),
  key: "transDateTo",
});

const transTimeTo = (t) => ({
  ...CLAIMS_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("claims_report_column_TransTimeTo"),
  key: "transTimeTo",
});

const appliedUnits = (t) => ({
  ...CLAIMS_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("claims_report_column_AppliedUnits"),
  key: "appliedUnits",
});

const totalOtUnits = (t) => ({
  ...CLAIMS_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("claims_report_column_TotalOtUnits"),
  key: "appliedUnits",
  width: "8",
});

const unitOfMeasure = (t) => ({
  ...CLAIMS_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("claims_report_column_UnitOfMeasure"),
  key: "unitOfMeasure",
});

const mealAllowanceUnits = (t) => ({
  ...CLAIMS_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("claims_report_column_MealAllowanceUnits"),
  key: "mealAllowanceUnits",
});

const transportFare = (t) => ({
  ...CLAIMS_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("claims_report_column_TransportFare"),
  key: "transportFare",
});

const dayInLieuPayment = (t) => ({
  ...CLAIMS_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("claims_report_column_DayInLieuPayment"),
  key: "dayInLieuPayment",
});

const requestedBy = (t) => ({
  ...CLAIMS_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("claims_report_column_RequestedBy"),
  key: "requestedBy",
});

const compLeaveUnits = (t) => ({
  ...CLAIMS_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("claims_report_column_CompLeaveUnits"),
  key: "compLeaveUnits",
});

const totalCompLeaveUnits = (t) => ({
  ...CLAIMS_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("claims_report_column_TotalCompLeaveUnits"),
  key: "totalCompLeaveUnits",
});

const mutualAgreed = (t) => ({
  ...CLAIMS_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("claims_report_column_MutualAgreed"),
  key: "mutualAgreed",
});

const applicationStatus = (t) => ({
  ...CLAIMS_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("claims_report_column_ApplicationStatus"),
  key: "applicationStatus",
});

const transactionStatus = (t) => ({
  ...CLAIMS_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("claims_report_column_TransactionStatus"),
  key: "applicationStatus",
});

const workflowStatus = (t) => ({
  ...CLAIMS_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("claims_report_column_WorkflowStatus"),
  key: "workflowStatus",
});

const approvalStatus = (t) => ({
  ...CLAIMS_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("claims_report_column_ApprovalStatus"),
  key: "applicationStatus",
});

const approverName = (t) => ({
  ...CLAIMS_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("claims_report_column_ApproverName"),
  key: "approverName",
});

const approverCompletionDate = (t) => ({
  ...CLAIMS_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("claims_report_column_ApproverCompletionDate"),
  key: "approverCompletionDate",
});

const remarks = (t) => ({
  ...CLAIMS_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("claims_report_column_Remarks"),
  key: "remarks",
});

const approvalRemark = (t) => ({
  ...CLAIMS_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("claims_report_column_ApprovalRemark"),
  key: "approvalRemark",
});

const remarksFromApplicant = (t) => ({
  ...CLAIMS_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("claims_report_column_RemarksFromApplicant"),
  key: "remarksFromApplicant",
});

const remarksFromApprover = (t) => ({
  ...CLAIMS_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("claims_report_column_RemarksFromApprover"),
  key: "remarksFromApprover",
});

// OT Claim Summary
const status = (t) => ({
  ...CLAIMS_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("claims_report_column_Status"),
  key: "status",
});

const staffType = (t) => ({
  ...CLAIMS_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("claims_report_column_StaffType"),
  key: "staffType",
});

const dateJoinedGroup = (t) => ({
  ...CLAIMS_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("claims_report_column_DateJoinedGroup"),
  key: "dateJoinedGroup",
  width: "13",
});

const dateJoinedCompany = (t) => ({
  ...CLAIMS_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("claims_report_column_DateJoinedCompany"),
  key: "dateJoinedCompany",
  width: "13",
});

const lastEmploymentDate = (t) => ({
  ...CLAIMS_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("claims_report_column_LastEmploymentDate"),
  key: "lastEmploymentDate",
});

const grade = (t) => ({
  ...CLAIMS_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("claims_report_column_Grade"),
  key: "grade",
});

const costCenter = (t) => ({
  ...CLAIMS_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("claims_report_column_CostCenter"),
  key: "costCenter",
});

const email = (t) => ({
  ...CLAIMS_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("claims_report_column_Email"),
  key: "email",
});

const periodFrom = (t) => ({
  ...CLAIMS_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("claims_report_column_periodFrom"),
  key: "yearPeriodFrom",
  width: "13",
});

const periodTo = (t) => ({
  ...CLAIMS_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("claims_report_column_periodTo"),
  key: "yearPeriodTo",
  width: "13",
});

const totalLeaveBalanceBroughtForward = (t) => ({
  ...CLAIMS_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("claims_report_column_TotalLeaveBalanceBroughtForward"),
  key: "totalLeaveBalanceBroughtForward",
});

const priorYearAdjustment = (t) => ({
  ...CLAIMS_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("claims_report_column_PriorYearAdjustment"),
  key: "priorYearAdjustment",
});

const totalCurrentYearLeaveEntitlement = (t) => ({
  ...CLAIMS_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("claims_report_column_TotalCurrentYearLeaveEntitlement"),
  key: "totalCurrentYearLeaveEntitlement",
});

const netOpeningLeaveBalance = (t) => ({
  ...CLAIMS_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("claims_report_column_NetOpeningLeaveBalance"),
  key: "netOpeningLeaveBalance",
});

const currentYearAdjustment = (t) => ({
  ...CLAIMS_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("claims_report_column_CurrentYearAdjustment"),
  key: "currentYearAdjustment",
});

const totalAppliedUnits = (t) => ({
  ...CLAIMS_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("claims_report_column_TotalAppliedUnits"),
  key: "totalAppliedUnits",
});

const daysEncashed = (t) => ({
  ...CLAIMS_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("claims_report_column_DaysEncashed"),
  key: "daysEncashed",
});

const forfeiture = (t) => ({
  ...CLAIMS_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("claims_report_column_Forfeiture"),
  key: "forfeiture",
});

const yearEndTotalProjectedBalance = (t) => ({
  ...CLAIMS_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("claims_report_column_YearEndTotalProjectedBalance"),
  key: "yearEndTotalProjectedBalance",
});

// const sickLeavePeriod = (t) => ({
//   ...CLAIMS_REPORT_DEFAULT_COLUMN_CONFIG,
//   header: t("claims_report_column_SickLeavePeriod"),
//   key: "sickLeavePeriod",
//   width: "15",
// });

// const boughtForward = (t) => ({
//   ...CLAIMS_REPORT_DEFAULT_COLUMN_CONFIG,
//   header: t("claims_report_column_BoughtForward"),
//   key: "boughtForward",
//   width: "8",
// });

// const entitlement = (t) => ({
//   ...CLAIMS_REPORT_DEFAULT_COLUMN_CONFIG,
//   header: t("claims_report_column_Entitlement"),
//   key: "entitlement",
//   width: "10",
// });

const applied = (t) => ({
  ...CLAIMS_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("claims_report_column_Applied"),
  key: "applied",
  width: "10",
});

// const adjusted = (t) => ({
//   ...CLAIMS_REPORT_DEFAULT_COLUMN_CONFIG,
//   header: t("claims_report_column_Adjusted"),
//   key: "adjusted",
//   width: "8",
// });

// const balance = (t) => ({
//   ...CLAIMS_REPORT_DEFAULT_COLUMN_CONFIG,
//   header: t("claims_report_column_Balance"),
//   key: "balance",
//   width: "8",
// });

//OT Claim Details
const entity = (t) => ({
  ...CLAIMS_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("claims_report_column_Entity"),
  key: "entity",
  width: "8",
});

const companyCode = (t) => ({
  ...CLAIMS_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("claims_report_column_CompanyCode"),
  key: "companyCode",
  width: "8",
});

const sesaId = (t) => ({
  ...CLAIMS_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("claims_report_column_SesaId"),
  key: "sesaId",
  width: "8",
});

const businessUnit = (t) => ({
  ...CLAIMS_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("claims_report_column_BusinessUnit"),
  key: "businessUnit",
  width: "8",
});

const leaveTaken = (t) => ({
  ...CLAIMS_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("claims_report_column_LeaveTaken"),
  key: "leaveTaken",
  width: "8",
});

// Statutory Leave Balance
const coverPeriodFrom = (t) => ({
  ...CLAIMS_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("claims_report_column_CoverPeriodStartDate"),
  key: "coverPeriodFrom",
  width: "13",
});

// const coverPeriodStartTime = (t) => ({
//   ...CLAIMS_REPORT_DEFAULT_COLUMN_CONFIG,
//   header: t("claims_report_column_CoverPeriodStartTime"),
//   key: "coverPeriodStartTime",
// });

const lastUpdate = (t) => ({
  ...CLAIMS_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("claims_report_column_LastUpdate"),
  key: "lastUpdate",
  width: "13",
});

const totalTransportFare = (t) => ({
  ...CLAIMS_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("claims_report_column_TotalTransportFare"),
  key: "totalTransportFare",
});

const totalMealAllowance = (t) => ({
  ...CLAIMS_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("claims_report_column_TotalMealAllowanceUnits"),
  key: "totalMealAllowanceUnits",
});

// const coverPeriodEndTime = (t) => ({
//   ...CLAIMS_REPORT_DEFAULT_COLUMN_CONFIG,
//   header: t("claims_report_column_CoverPeriodEndTime"),
//   key: "coverPeriodEndTime",
// });
//#endregion

//#region  Column Config
const otClaimTransactionReportColumns = (t) => [
  employeeId(t),
  employee(t),
  company(t),
  department(t),
  //  location(t),
  submissionDate(t),
  //  submissionTime(t),
  claimReference(t),
  transactionSeq(t),
  claimItem(t),
  transactionType(t),
  transDateFrom(t),
  transTimeFrom(t),
  transTimeTo(t),
  appliedUnits(t),
  unitOfMeasure(t),
  mealAllowanceUnits(t),
  transportFare(t),
  dayInLieuPayment(t),
  requestedBy(t),
  compLeaveUnits(t),
  applicationStatus(t),
  remarks(t),
  approverCompletionDate(t),
];

const otClaimSummaryReportColumns = (t) => [
  referenceNo(t),
  submissionDate(t),
  transactionSeq(t),
  employeeCode(t),
  employeeName(t),
  company(t),
  department(t),
  costCenter(t),
  transactionType(t),
  fromDate(t),
  toDate(t),
  totalOtUnits(t),
  compLeaveUnits(t),
  applicationStatus(t),
  approverCompletionDate(t),
];

const otClaimDetailsReportColumns = (t) => [
  referenceNo(t),
  seqNo(t),
  submissionDate(t),
  employeeCode(t),
  employeeName(t),
  company(t),
  department(t),
  costCenter(t),
  fromDate(t),
  toDate(t),
  transactionSeq(t),
  claimItem(t),
  transactionType(t),
  otApplicationDate(t),
  transTimeFrom(t),
  transTimeTo(t),
  appliedUnits(t),
  compLeaveUnits(t),
  approvalStatus(t),
  remarksFromApplicant(t),
  remarksFromApprover(t),
  approverCompletionDate(t),
];

const claimDetailsReportColumns = (t) => [
  employeeId(t),
  employee(t),
  company(t),
  department(t),
  costCenter(t),
  submissionDate(t),
  referenceNo(t),
  transactionSeq(t),
  transactionType(t),
  claimItem(t),
  fromDate(t),
  toDate(t),
  transTimeFrom(t),
  transTimeTo(t),
  appliedUnits(t),
  unitOfMeasure(t),
  compLeaveUnits(t),
  transportFare(t),
  mealAllowanceUnits(t),
  dayInLieuPayment(t),
  mutualAgreed(t),
  applicationStatus(t),
  approverCompletionDate(t),
  remarksFromApplicant(t),
  remarksFromApprover(t),
  requestedBy(t),
  lastUpdate(t),
];

const claimSummaryReportColumns = (t) => [
  employeeId(t),
  employee(t),
  company(t),
  department(t),
  costCenter(t),
  transactionType(t),
  fromDate(t),
  toDate(t),
  totalAppliedUnits(t),
  totalCompLeaveUnits(t),
  totalTransportFare(t),
  totalMealAllowance(t),
  applicationStatus(t),
];

//#endregion Column Config

//#region  Filter Payload
const prepareFilterPayloadOtClaimTransaction = (form) => {
  let payload = {};
  if (!form) return payload;

  if (form.employeeCode) {
    payload.employeeCodes = form.employeeCode;
  }
  if (form.reportingLine) {
    payload.reportingLines = [form.reportingLine];
  }
  if (form.claimDateFrom) {
    const value = moment(form.claimDateFrom).format(SHORT_DATE_FORMAT);
    payload.claimDate = payload.claimDate
      ? { ...payload.claimDate, from: value }
      : { from: value };
  }
  if (form.claimDateTo) {
    const value = moment(form.claimDateTo).format(SHORT_DATE_FORMAT);
    payload.claimDate = payload.claimDate
      ? { ...payload.claimDate, to: value }
      : { to: value };
  }
  if (form.claimItemMulti) {
    payload.claimTypes = form.claimItemMulti;
  }
  if (form.transactionType) {
    payload.transactionTypes = form.transactionType;
  }
  return payload;
};

const prepareFilterPayloadOtClaimSummary = (form) => {
  let payload = {};
  if (!form) return payload;

  if (form.employeeCode) {
    payload.employeeCodes = form.employeeCode;
  }
  if (form.reportingLine) {
    payload.reportingLines = [form.reportingLine];
  }
  if (form.claimDateFrom) {
    const value = moment(form.claimDateFrom).format(SHORT_DATE_FORMAT);
    payload.claimDate = payload.claimDate
      ? { ...payload.claimDate, from: value }
      : { from: value };
  }
  if (form.claimDateTo) {
    const value = moment(form.claimDateTo).format(SHORT_DATE_FORMAT);
    payload.claimDate = payload.claimDate
      ? { ...payload.claimDate, to: value }
      : { to: value };
  }
  if (form.claimItemMulti) {
    payload.claimTypes = form.claimItemMulti;
  }
  if (form.transactionType) {
    payload.transactionTypes = form.transactionType;
  }
  return payload;
};

const prepareFilterPayloadOtClaimDetails = (form) => {
  let payload = {};
  if (!form) return payload;

  if (form.employeeCode) {
    payload.employeeCodes = form.employeeCode;
  }
  if (form.reportingLine) {
    payload.reportingLines = [form.reportingLine];
  }
  if (form.claimDateFrom) {
    const value = moment(form.claimDateFrom).format(SHORT_DATE_FORMAT);
    payload.claimDate = payload.claimDate
      ? { ...payload.claimDate, from: value }
      : { from: value };
  }
  if (form.claimDateTo) {
    const value = moment(form.claimDateTo).format(SHORT_DATE_FORMAT);
    payload.claimDate = payload.claimDate
      ? { ...payload.claimDate, to: value }
      : { to: value };
  }
  if (form.claimItemMulti) {
    payload.claimTypes = form.claimItemMulti;
  }
  if (form.transactionType) {
    payload.transactionTypes = form.transactionType;
  }
  return payload;
};

const prepareFilterPayloadClaimDetails = (form) => {
  let payload = {};
  if (!form) return payload;

  if (form.employeeCode) {
    payload.employeeCodes = form.employeeCode;
  }
  if (form.reportingLine) {
    payload.reportingLines = [form.reportingLine];
  }
  if (form.claimDateFrom) {
    const value = moment(form.claimDateFrom).format(SHORT_DATE_FORMAT);
    payload.claimDate = payload.claimDate
      ? { ...payload.claimDate, from: value }
      : { from: value };
  }
  if (form.claimDateTo) {
    const value = moment(form.claimDateTo).format(SHORT_DATE_FORMAT);
    payload.claimDate = payload.claimDate
      ? { ...payload.claimDate, to: value }
      : { to: value };
  }
  if (form.claimItemMulti) {
    payload.claimTypes = form.claimItemMulti;
  }
  if (form.transactionType) {
    payload.transactionTypes = form.transactionType;
  }
  if (form.transactionStatus) {
    payload.transactionStatus = form.transactionStatus;
  }
  return payload;
};

const prepareFilterPayloadClaimSummary = (form) => {
  let payload = {};
  if (!form) return payload;

  if (form.employeeCode) {
    payload.employeeCodes = form.employeeCode;
  }
  if (form.reportingLine) {
    payload.reportingLines = [form.reportingLine];
  }
  if (form.claimDateFrom) {
    const value = moment(form.claimDateFrom).format(SHORT_DATE_FORMAT);
    payload.claimDate = payload.claimDate
      ? { ...payload.claimDate, from: value }
      : { from: value };
  }
  if (form.claimDateTo) {
    const value = moment(form.claimDateTo).format(SHORT_DATE_FORMAT);
    payload.claimDate = payload.claimDate
      ? { ...payload.claimDate, to: value }
      : { to: value };
  }
  if (form.claimItemMulti) {
    payload.claimTypes = form.claimItemMulti;
  }
  if (form.transactionType) {
    payload.transactionTypes = form.transactionType;
  }
  if (form.transactionStatus) {
    payload.transactionStatus = form.transactionStatus;
  }
  return payload;
};
//#endregion

// Fetch Filter
const parseApiFilterOtClaimTransaction = (data, t) => {
  let config = {};
  if (data.employeeCodes) {
    config.employeeCodeFilterOption = data.employeeCodes.map((option) => ({
      label: option.name,
      value: option.value,
    }));
  }
  if (data.reportingLines) {
    config.reportingLineFilterOption = data.reportingLines.map((option) => ({
      label: translateReportingLine(option.name, t),
      value: option.value,
    }));
  }
  if (data.claimTypes) {
    config.claimTypeFilterOptions = data.claimTypes.map((option) => ({
      label: option.name,
      value: option.value,
    }));
  }
  if (data.transactionTypes) {
    config.transactionTypeFilterOptions = data.transactionTypes.map(
      (option) => ({
        label: option.name,
        value: option.value,
      })
    );
  }
  return config;
};

const parseApiFilterOtClaimSummary = (data, t) => {
  let config = {};
  if (data.employeeCodes) {
    config.employeeCodeFilterOption = data.employeeCodes.map((option) => ({
      label: option.name,
      value: option.value,
    }));
  }
  if (data.reportingLines) {
    config.reportingLineFilterOption = data.reportingLines.map((option) => ({
      label: translateReportingLine(option.name, t),
      value: option.value,
    }));
  }
  if (data.claimTypes) {
    config.claimTypeFilterOptions = data.claimTypes.map((option) => ({
      label: option.name,
      value: option.value,
    }));
  }
  if (data.transactionTypes) {
    config.transactionTypeFilterOptions = data.transactionTypes.map(
      (option) => ({
        label: option.name,
        value: option.value,
      })
    );
  }
  return config;
};

const parseApiFilterOtClaimDetails = (data, t) => {
  let config = {};
  if (data.employeeCodes) {
    config.employeeCodeFilterOption = data.employeeCodes.map((option) => ({
      label: option.name,
      value: option.value,
    }));
  }
  if (data.reportingLines) {
    config.reportingLineFilterOption = data.reportingLines.map((option) => ({
      label: translateReportingLine(option.name, t),
      value: option.value,
    }));
  }
  if (data.claimTypes) {
    config.claimTypeFilterOptions = data.claimTypes.map((option) => ({
      label: option.name,
      value: option.value,
    }));
  }
  if (data.transactionTypes) {
    config.transactionTypeFilterOptions = data.transactionTypes.map(
      (option) => ({
        label: option.name,
        value: option.value,
      })
    );
  }
  return config;
};

const parseApiFilterClaimDetails = (data, t) => {
  let config = {};
  if (data.employeeCodes) {
    config.employeeCodeFilterOption = data.employeeCodes.map((option) => ({
      label: option.name,
      value: option.value,
    }));
  }
  if (data.reportingLines) {
    config.reportingLineFilterOption = data.reportingLines.map((option) => ({
      label: translateReportingLine(option.name, t),
      value: option.value,
    }));
  }
  if (data.claimTypes) {
    config.claimTypeFilterOptions = data.claimTypes.map((option) => ({
      label: option.name,
      value: option.value,
    }));
  }
  if (data.transactionTypes) {
    config.transactionTypeFilterOptions = data.transactionTypes.map(
      (option) => ({
        label: option.name,
        value: option.value,
      })
    );
  }
  if (data.transactionStatus) {
    config.transactionStatusFilterOptions = data.transactionStatus.map(
      (option) => ({
        label: option.name,
        value: option.value,
      })
    );
  }
  return config;
};

const parseApiFilterClaimSummary = (data, t) => {
  let config = {};
  if (data.employeeCodes) {
    config.employeeCodeFilterOption = data.employeeCodes.map((option) => ({
      label: option.name,
      value: option.value,
    }));
  }
  if (data.reportingLines) {
    config.reportingLineFilterOption = data.reportingLines.map((option) => ({
      label: translateReportingLine(option.name, t),
      value: option.value,
    }));
  }
  if (data.claimTypes) {
    config.claimTypeFilterOptions = data.claimTypes.map((option) => ({
      label: option.name,
      value: option.value,
    }));
  }
  if (data.transactionTypes) {
    config.transactionTypeFilterOptions = data.transactionTypes.map(
      (option) => ({
        label: option.name,
        value: option.value,
      })
    );
  }
  if (data.transactionStatus) {
    config.transactionStatusFilterOptions = data.transactionStatus.map(
      (option) => ({
        label: option.name,
        value: option.value,
      })
    );
  }
  return config;
};

const translateReportingLine = (data, t) => {
  if (data != null) {
    if (data == "FUNCTION_REPORT_TO") {
      return t("claims_report_filter_function_report_to");
    } else {
      return data;
    }
  }
};

// Export
export const getReportFilterConfig = (
  reportType,
  config,
  filterWithOption,
  commonFilter
) => {
  let filters;
  switch (reportType) {
    case CLAIMS_REPORT_TYPE.OT_MEAL_ALLOWANCE_TRANSACTION_REPORT:
      filters = otClaimTransactionReportFilter(config, filterWithOption);
      break;
    case CLAIMS_REPORT_TYPE.OT_CLAIM_SUMMARY_REPORT:
      filters = otClaimSummaryReportFilter(config, filterWithOption);
      break;
    case CLAIMS_REPORT_TYPE.OT_CLAIM_DETAILS_REPORT:
      filters = otClaimDetailsReportFilter(config, filterWithOption);
      break;
    case CLAIMS_REPORT_TYPE.CLAIM_DETAILS_REPORT:
      filters = claimDetailsReportFilter(config, filterWithOption);
      break;
    case CLAIMS_REPORT_TYPE.CLAIM_SUMMARY_REPORT:
      filters = claimSummaryReportFilter(config, filterWithOption);
      break;
    default:
      break;
  }

  function convertToColumns(array, columns) {
    const result = [];
    let row = [];

    for (let i = 0; i < array.length; i++) {
      row.push(array[i]);

      if ((i + 1) % columns === 0 || i === array.length - 1) {
        result.push(row);
        row = [];
      }
    }

    return result;
  }

  if (Array.isArray(commonFilter) && commonFilter.length > 0) {
    let start = -1;
    let employeeCodeFilterIndex = filters.findIndex(
      (row) =>
        row.findIndex((column) =>
          ["employeeCode", "reportingLine"].includes(column.key)
        ) > -1
    );
    let claimDateFilterIndex = filters.findIndex(
      (row) =>
        row.findIndex((column) =>
          ["claimDateFrom", "claimDateTo"].includes(column.key)
        ) > -1
    );
    if (employeeCodeFilterIndex < 0 && claimDateFilterIndex > -1) {
      start = claimDateFilterIndex;
    }

    if (employeeCodeFilterIndex > -1) {
      start = employeeCodeFilterIndex;
      ++start;
    }

    if (start > -1) {
      let newRows = convertToColumns(commonFilter, 2);
      newRows.forEach((row, index) => {
        filters.splice(start + index, 0, row);
      });
    }
  }

  if (filters) {
    return {
      controls: filters.reduce((acc, cur) => {
        acc = acc.concat([...cur]);
        return acc;
      }, []),
      layout: {
        rows: filters.map((filterRow) => {
          return {
            columns: filterRow.map((filter) => {
              return {
                control: filter.key,
                config: {
                  className: "p-col-12 p-lg-4 p-md-6 p-sm-12",
                },
              };
            }),
          };
        }),
      },
    };
  }
  return {};
};

export const getReportColumnConfig = (reportType, t) => {
  let columns = [];
  switch (reportType) {
    case CLAIMS_REPORT_TYPE.OT_MEAL_ALLOWANCE_TRANSACTION_REPORT:
      columns = otClaimTransactionReportColumns(t);
      break;
    case CLAIMS_REPORT_TYPE.OT_CLAIM_SUMMARY_REPORT:
      columns = otClaimSummaryReportColumns(t);
      break;
    case CLAIMS_REPORT_TYPE.OT_CLAIM_DETAILS_REPORT:
      columns = otClaimDetailsReportColumns(t);
      break;
    case CLAIMS_REPORT_TYPE.CLAIM_DETAILS_REPORT:
      columns = claimDetailsReportColumns(t);
      break;
    case CLAIMS_REPORT_TYPE.CLAIM_SUMMARY_REPORT:
      columns = claimSummaryReportColumns(t);
      break;
    default:
      break;
  }
  return columns;
};

export const parseApiFilter = (reportType, data, translation) => {
  let filters = {};
  let configs = [];
  switch (reportType) {
    case CLAIMS_REPORT_TYPE.OT_MEAL_ALLOWANCE_TRANSACTION_REPORT:
      filters = parseApiFilterOtClaimTransaction(data, translation);
      break;
    case CLAIMS_REPORT_TYPE.OT_CLAIM_SUMMARY_REPORT:
      filters = parseApiFilterOtClaimSummary(data, translation);
      break;
    case CLAIMS_REPORT_TYPE.OT_CLAIM_DETAILS_REPORT:
      filters = parseApiFilterOtClaimDetails(data, translation);
      break;
    case CLAIMS_REPORT_TYPE.CLAIM_DETAILS_REPORT:
      filters = parseApiFilterClaimDetails(data, translation);
      break;
    case CLAIMS_REPORT_TYPE.CLAIM_SUMMARY_REPORT:
      filters = parseApiFilterClaimSummary(data, translation);
      break;
    default:
      return { filters, configs };
  }

  //adding common general filters
  if (!!data) {
    for (const property in data) {
      let filter = data[property];
      if (
        filter.hasOwnProperty("field") &&
        filter.hasOwnProperty("dataList") &&
        filter.hasOwnProperty("translationKey")
      ) {
        let dataList = ToUIOption(filter.dataList);
        let translationKey = filter.translationKey;
        if (Array.isArray(dataList)) {
          filters[property] = dataList;
          configs.push({
            key: property,
            type: filter?.ComponentType ?? BCType.multiselect,
            label: translation(translationKey),
            enum: dataList,
            placeholder: translation("base_control_select_choose"),
          });
        }
      }
    }
  }
  return { filters, configs };
};

const prepareDefaultFilterValueOtClaimTransaction = (filters) => {
  let defaultFilters = {};
  if (filters.employeeCodeFilterOption?.length === 1) {
    defaultFilters.employeeCode = [filters.employeeCodeFilterOption[0].value];
    defaultFilters.employeeCode_obj = [filters.employeeCodeFilterOption[0]];
  }
  // if (filters.reportingLineFilterOption.length > 0) {
  //   const targetReportingLineALL = filters.reportingLineFilterOption.filter(
  //     (rl) => rl.value === "ALL"
  //   );
  //   if (targetReportingLineALL.length > 0) {
  //     defaultFilters.reportingLine = targetReportingLineALL[0].value;
  //     defaultFilters.reportingLine_obj = targetReportingLineALL[0];
  //   }
  // }
  if (filters.claimTypeFilterOptions?.length > 0) {
    const targetClaimTypeOvertime = filters.claimTypeFilterOptions.filter(
      (lt) => lt.value === "Overtime"
    );
    const targeClaimTypeOT = filters.claimTypeFilterOptions.filter(
      (lt) => lt.value === "OT_Meal"
    );
    if (targetClaimTypeOvertime.length > 0) {
      defaultFilters.claimItemMulti = [targetClaimTypeOvertime[0].value];
      defaultFilters.claimItemMulti_obj = [targetClaimTypeOvertime[0]];
    } else if (targeClaimTypeOT.length > 0) {
      defaultFilters.claimItemMulti = [targeClaimTypeOT[0].value];
      defaultFilters.claimItemMulti_obj = [targeClaimTypeOT[0]];
    } else {
      defaultFilters.claimItemMulti = [filters.claimTypeFilterOptions[0].value];
      defaultFilters.claimItemMulti_obj = [filters.claimTypeFilterOptions[0]];
    }
  }
  if (filters.transactionTypeFilterOptions?.length > 0) {
    let transactionType = [];
    let transactionType_obj = [];
    filters.transactionTypeFilterOptions.forEach((tr) => {
      transactionType.push(tr.value);
      transactionType_obj.push(tr);
    });
    defaultFilters.transactionType = transactionType;
    defaultFilters.transactionType_obj = transactionType_obj;
  }
  defaultFilters.claimDateFrom = new Date(
    moment().startOf("month").format(SHORT_DATE_FORMAT)
  );
  defaultFilters.claimDateTo = new Date(
    moment().endOf("month").format(SHORT_DATE_FORMAT)
  );

  return defaultFilters;
};

const prepareDefaultFilterValueOtClaimSummary = (filters) => {
  let defaultFilters = {};
  if (filters.employeeCodeFilterOption?.length === 1) {
    defaultFilters.employeeCode = [filters.employeeCodeFilterOption[0].value];
    defaultFilters.employeeCode_obj = [filters.employeeCodeFilterOption[0]];
  }
  // if (filters.reportingLineFilterOption.length > 0) {
  //   const targetReportingLineALL = filters.reportingLineFilterOption.filter(
  //     (rl) => rl.value === "ALL"
  //   );
  //   if (targetReportingLineALL.length > 0) {
  //     defaultFilters.reportingLine = targetReportingLineALL[0].value;
  //     defaultFilters.reportingLine_obj = targetReportingLineALL[0];
  //   }
  // }
  if (filters.claimTypeFilterOptions?.length > 0) {
    const targetClaimTypeOvertime = filters.claimTypeFilterOptions.filter(
      (lt) => lt.value === "Overtime"
    );
    const targeClaimTypeOT = filters.claimTypeFilterOptions.filter(
      (lt) => lt.value === "OT_Meal"
    );
    if (targetClaimTypeOvertime.length > 0) {
      defaultFilters.claimItemMulti = [targetClaimTypeOvertime[0].value];
      defaultFilters.claimItemMulti_obj = [targetClaimTypeOvertime[0]];
    } else if (targeClaimTypeOT.length > 0) {
      defaultFilters.claimItemMulti = [targeClaimTypeOT[0].value];
      defaultFilters.claimItemMulti_obj = [targeClaimTypeOT[0]];
    } else {
      defaultFilters.claimItemMulti = [filters.claimTypeFilterOptions[0].value];
      defaultFilters.claimItemMulti_obj = [filters.claimTypeFilterOptions[0]];
    }
  }
  if (filters.transactionTypeFilterOptions?.length > 0) {
    let transactionType = [];
    let transactionType_obj = [];
    filters.transactionTypeFilterOptions.forEach((tr) => {
      transactionType.push(tr.value);
      transactionType_obj.push(tr);
    });
    defaultFilters.transactionType = transactionType;
    defaultFilters.transactionType_obj = transactionType_obj;
  }
  defaultFilters.claimDateFrom = new Date(
    moment().startOf("month").format(SHORT_DATE_FORMAT)
  );
  defaultFilters.claimDateTo = new Date(
    moment().endOf("month").format(SHORT_DATE_FORMAT)
  );

  return defaultFilters;
};

const prepareDefaultFilterValueOtClaimDetails = (filters) => {
  let defaultFilters = {};
  if (filters.employeeCodeFilterOption?.length === 1) {
    defaultFilters.employeeCode = [filters.employeeCodeFilterOption[0].value];
    defaultFilters.employeeCode_obj = [filters.employeeCodeFilterOption[0]];
  }
  // if (filters.reportingLineFilterOption.length > 0) {
  //   const targetReportingLineALL = filters.reportingLineFilterOption.filter(
  //     (rl) => rl.value === "ALL"
  //   );
  //   if (targetReportingLineALL.length > 0) {
  //     defaultFilters.reportingLine = targetReportingLineALL[0].value;
  //     defaultFilters.reportingLine_obj = targetReportingLineALL[0];
  //   }
  // }
  if (filters.claimTypeFilterOptions?.length > 0) {
    const targetClaimTypeOvertime = filters.claimTypeFilterOptions.filter(
      (lt) => lt.value === "Overtime"
    );
    const targeClaimTypeOT = filters.claimTypeFilterOptions.filter(
      (lt) => lt.value === "OT_Meal"
    );
    if (targetClaimTypeOvertime.length > 0) {
      defaultFilters.claimItemMulti = [targetClaimTypeOvertime[0].value];
      defaultFilters.claimItemMulti_obj = [targetClaimTypeOvertime[0]];
    } else if (targeClaimTypeOT.length > 0) {
      defaultFilters.claimItemMulti = [targeClaimTypeOT[0].value];
      defaultFilters.claimItemMulti_obj = [targeClaimTypeOT[0]];
    } else {
      defaultFilters.claimItemMulti = [filters.claimTypeFilterOptions[0].value];
      defaultFilters.claimItemMulti_obj = [filters.claimTypeFilterOptions[0]];
    }
  }
  if (filters.transactionTypeFilterOptions?.length > 0) {
    let transactionType = [];
    let transactionType_obj = [];
    filters.transactionTypeFilterOptions.forEach((tr) => {
      transactionType.push(tr.value);
      transactionType_obj.push(tr);
    });
    defaultFilters.transactionType = transactionType;
    defaultFilters.transactionType_obj = transactionType_obj;
  }
  defaultFilters.claimDateFrom = new Date(
    moment().startOf("month").format(SHORT_DATE_FORMAT)
  );
  defaultFilters.claimDateTo = new Date(
    moment().endOf("month").format(SHORT_DATE_FORMAT)
  );

  return defaultFilters;
};

const prepareDefaultFilterValueClaimDetails = (filters) => {
  let defaultFilters = {};

  if (filters.employeeCodeFilterOption?.length === 1) {
    defaultFilters.employeeCode = [filters.employeeCodeFilterOption[0].value];
    defaultFilters.employeeCode_obj = [filters.employeeCodeFilterOption[0]];
  }
  if (filters.claimTypeFilterOptions?.length > 0) {
    let claimType = [];
    let claimType_obj = [];
    filters.claimTypeFilterOptions.forEach((tr) => {
      claimType.push(tr.value);
      claimType_obj.push(tr);
    });
    defaultFilters.claimItemMulti = claimType;
    defaultFilters.claimType_obj = claimType_obj;
  }
  if (filters.transactionTypeFilterOptions?.length > 0) {
    let transactionType = [];
    let transactionType_obj = [];
    filters.transactionTypeFilterOptions.forEach((tr) => {
      transactionType.push(tr.value);
      transactionType_obj.push(tr);
    });
    defaultFilters.transactionType = transactionType;
    defaultFilters.transactionType_obj = transactionType_obj;
  }

  if (filters.transactionStatusFilterOptions?.length > 0) {
    let transactionStatus = [];
    let transactionStatus_obj = [];
    filters.transactionStatusFilterOptions.forEach((tr) => {
      transactionStatus.push(tr.value);
      transactionStatus_obj.push(tr);
    });
    defaultFilters.transactionStatus = transactionStatus;
    defaultFilters.transactionType_obj = transactionStatus_obj;
  }

  defaultFilters.claimDateFrom = new Date(
    moment().startOf("month").format(SHORT_DATE_FORMAT)
  );
  defaultFilters.claimDateTo = new Date(
    moment().endOf("month").format(SHORT_DATE_FORMAT)
  );

  return defaultFilters;
};

const prepareDefaultFilterValueClaimSummary = (filters) => {
  let defaultFilters = {};

  if (filters.employeeCodeFilterOption?.length === 1) {
    defaultFilters.employeeCode = [filters.employeeCodeFilterOption[0].value];
    defaultFilters.employeeCode_obj = [filters.employeeCodeFilterOption[0]];
  }
  if (filters.claimTypeFilterOptions?.length > 0) {
    let claimType = [];
    let claimType_obj = [];
    filters.claimTypeFilterOptions.forEach((tr) => {
      claimType.push(tr.value);
      claimType_obj.push(tr);
    });
    defaultFilters.claimItemMulti = claimType;
    defaultFilters.claimType_obj = claimType_obj;
  }
  if (filters.transactionTypeFilterOptions?.length > 0) {
    let transactionType = [];
    let transactionType_obj = [];
    filters.transactionTypeFilterOptions.forEach((tr) => {
      transactionType.push(tr.value);
      transactionType_obj.push(tr);
    });
    defaultFilters.transactionType = transactionType;
    defaultFilters.transactionType_obj = transactionType_obj;
  }

  if (filters.transactionStatusFilterOptions?.length > 0) {
    let transactionStatus = [];
    let transactionStatus_obj = [];
    filters.transactionStatusFilterOptions.forEach((tr) => {
      transactionStatus.push(tr.value);
      transactionStatus_obj.push(tr);
    });
    defaultFilters.transactionStatus = transactionStatus;
    defaultFilters.transactionType_obj = transactionStatus_obj;
  }
  defaultFilters.claimDateFrom = new Date(
    moment().startOf("month").format(SHORT_DATE_FORMAT)
  );
  defaultFilters.claimDateTo = new Date(
    moment().endOf("month").format(SHORT_DATE_FORMAT)
  );

  return defaultFilters;
};

export const prepareDefaultFilterValue = (reportType, filters) => {
  switch (reportType) {
    case CLAIMS_REPORT_TYPE.OT_MEAL_ALLOWANCE_TRANSACTION_REPORT:
      return prepareDefaultFilterValueOtClaimTransaction(filters);
    case CLAIMS_REPORT_TYPE.OT_CLAIM_SUMMARY_REPORT:
      return prepareDefaultFilterValueOtClaimSummary(filters);
    case CLAIMS_REPORT_TYPE.OT_CLAIM_DETAILS_REPORT:
      return prepareDefaultFilterValueOtClaimDetails(filters);
    case CLAIMS_REPORT_TYPE.CLAIM_DETAILS_REPORT:
      return prepareDefaultFilterValueClaimDetails(filters);
    case CLAIMS_REPORT_TYPE.CLAIM_SUMMARY_REPORT:
      return prepareDefaultFilterValueClaimSummary(filters);
    default:
      return {};
  }
};

export const prepareFilterPayload = (reportType, form) => {
  let payload = {};
  switch (reportType) {
    case CLAIMS_REPORT_TYPE.OT_MEAL_ALLOWANCE_TRANSACTION_REPORT:
      payload = prepareFilterPayloadOtClaimTransaction(form);
      break;
    case CLAIMS_REPORT_TYPE.OT_CLAIM_SUMMARY_REPORT:
      payload = prepareFilterPayloadOtClaimSummary(form);
      break;
    case CLAIMS_REPORT_TYPE.OT_CLAIM_DETAILS_REPORT:
      payload = prepareFilterPayloadOtClaimDetails(form);
      break;
    case CLAIMS_REPORT_TYPE.CLAIM_DETAILS_REPORT:
      payload = prepareFilterPayloadClaimDetails(form);
      break;
    case CLAIMS_REPORT_TYPE.CLAIM_SUMMARY_REPORT:
      payload = prepareFilterPayloadClaimSummary(form);
      break;
    default:
      break;
  }
  return payload;
};

export const getFilterOptions = async (
  reportType,
  reportingLine,
  employeeCode
) => {
  const body = {
    reportingLine,
    employeeCode,
  };

  if (!!reportingLine) {
    body.employeeCode = employeeCode ?? [];
  }

  switch (reportType) {
    case CLAIMS_REPORT_TYPE.OT_MEAL_ALLOWANCE_TRANSACTION_REPORT:
      return ClaimsReportService.claimsReportGetOtClaimTransactionFilter();
    case CLAIMS_REPORT_TYPE.OT_CLAIM_SUMMARY_REPORT:
      return ClaimsReportService.claimsReportGetOtClaimSummaryFilter();
    case CLAIMS_REPORT_TYPE.OT_CLAIM_DETAILS_REPORT:
      return ClaimsReportService.claimsReportGetOtClaimDetailsFilter();
    case CLAIMS_REPORT_TYPE.CLAIM_DETAILS_REPORT:
      return ClaimsReportService.claimsReportGetClaimDetailsFilter({ body });
    case CLAIMS_REPORT_TYPE.CLAIM_SUMMARY_REPORT:
      return ClaimsReportService.claimsReportGetClaimSummaryFilter({ body });
    default:
      return;
  }
};
