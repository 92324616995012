import { Button } from "primereact/button";
import { SelectControlOption } from "../../../../../../components/base-control/select-control/select-control";
import { BASIC_CONTROL_LAYOUT_CONFIG } from "../../../../leave/utils/config-helper";
import {
  BASIC_CONTROL_LAYOUT_CONFIG_ONE_FIFTH_COLUMN,
  BASIC_CONTROL_LAYOUT_CONFIG_TWO_FIFTH_COLUMN,
  IBaseFormControlProps,
  ICreateApplicationFormData,
  ITimesheetConfigData,
  ITimesheetDetailData,
  SHORT_DATE_FORMAT,
} from "../../../constants";

import { v4 as uuidv4 } from "uuid";
import { TFunction } from "react-i18next";
import { TimesheetCalendar } from "../../../components/timesheet-calendar";
import { IDateTypeItemConfig } from "../../../constants/type";
import {
  ActivityIdField,
  FromTimeField,
  ToDateField,
  ToTimeField,
  SelectionPeriodField,
  DateField,
  RemarksField,
  ApplyMultipleField,
  ExcludeMealTimeField,
  DeleteActivityButton,
  TotalPeriodsHoursField,
} from "./create-application-form-field-config";
import moment from "moment";
import { toFormRequest } from "../../../utils/form-to-request";

const createColumn = (control: string, layoutConfig: any = {}) => ({
  control,
  config: {
    ...BASIC_CONTROL_LAYOUT_CONFIG,
    ...layoutConfig,
  },
});

export const activityFieldList = [
  ActivityIdField,
  FromTimeField,
  ToDateField,
  ToTimeField,
  ExcludeMealTimeField,
  DeleteActivityButton,
];

export const parseFieldKeyListIntoFormKeyList = (uuid: string) =>
  activityFieldList.map((field) => `${field.key}_${uuid}`);

const parseFieldKeyListIntoControls = (
  uuid: string,
  form: ICreateApplicationFormData,
  activityIds: SelectControlOption[],
  disableAll: boolean,
  periodDetail: ITimesheetDetailData,
  onDeleteActivityButtonClick?: any,
  onExcludeMealTimeChange?: any,
  timesheetConfig?: ITimesheetConfigData,
  defaultTime?: { from: Date; to: Date }
) => {
  const isSameDay =
    moment(form[`${DateField.key}`]).format(SHORT_DATE_FORMAT) ===
    moment(form[`${ToDateField.key}_${uuid}`]).format(SHORT_DATE_FORMAT);
  const dateConfig = (date: any, defaultDate: any) => {
    if (date) {
      date = isSameDay ? moment(date).toDate() : undefined;
    } else {
      date = moment(defaultDate).toDate();
    }
    return date;
  };

  return activityFieldList.map((field) => {
    return {
      ...field,
      key: `${field.key}_${uuid}`,
      ...(field.key === "activityId" ? { enum: activityIds } : {}),
      config: {
        ...field?.config,
        ...(disableAll ? { readOnly: true } : {}),
        ...(field.key === ToDateField.key
          ? {
              minDate: form.date,
              maxDate: moment(form.date).add(1, "day").toDate(),
            }
          : {}),
        ...(field.key === FromTimeField.key
          ? {
              viewDate: defaultTime?.from,
              stepMinute: timesheetConfig?.interval,
              maxDate: dateConfig(
                form[`${ToTimeField.key}_${uuid}`],
                defaultTime?.to
              ),
            }
          : {}),
        ...(field.key === ToTimeField.key
          ? {
              viewDate: defaultTime?.to,
              stepMinute: timesheetConfig?.interval,
              readOnly:
                moment(form[`${ToDateField.key}_${uuid}`]).format(
                  SHORT_DATE_FORMAT
                ) ===
                moment(periodDetail?.endDate)
                  .add({ day: 1 })
                  .format(SHORT_DATE_FORMAT),
              minDate: dateConfig(
                form[`${FromTimeField.key}_${uuid}`],
                defaultTime?.from
              ),
            }
          : {}),
      },
      ...(field.key === "excludeMealTime"
        ? {
            value: form[`${ExcludeMealTimeField.key}_${uuid}`],
            onClick: (event: any) =>
              onExcludeMealTimeChange(event.checked, uuid),
          }
        : {}),
      ...(field.key === "deleteActivity"
        ? {
            disabled: disableAll,
            onClick: () => onDeleteActivityButtonClick(uuid),
          }
        : {}),
    };
  });
};

const parseFieldKeyListIntoColumns = (uuid: string) =>
  activityFieldList.map((field) => ({
    control: `${field.key}_${uuid}`,
    config: {
      ...BASIC_CONTROL_LAYOUT_CONFIG,
      ...BASIC_CONTROL_LAYOUT_CONFIG_ONE_FIFTH_COLUMN,
    },
  }));

export const getCreateApplicationFormControls = ({
  t,
  form,
  activityIds,
  disableAll,
  periodDetail,
  onDeleteActivityButtonClick,
  onExcludeMealTimeChange,
  timesheetConfig,
}: {
  t: TFunction<"translation">;
  form: ICreateApplicationFormData;
  setForm: React.Dispatch<React.SetStateAction<ICreateApplicationFormData>>;
  activityIds: SelectControlOption[];
  disableAll: boolean;
  periodDetail: ITimesheetDetailData;
  onDeleteActivityButtonClick?: any;
  onExcludeMealTimeChange?: any;
  timesheetConfig?: ITimesheetConfigData;
}) => {
  const defaultTime = {
    from: moment(timesheetConfig?.fromTime, "HH:mm:ss").toDate(),
    to: moment(timesheetConfig?.toTime, "HH:mm:ss").toDate(),
  };

  const controls: IBaseFormControlProps[] = [
    SelectionPeriodField,
    {
      ...DateField,
      config: {
        ...DateField?.config,
        minDate: new Date(periodDetail?.startDate),
        maxDate: new Date(periodDetail?.endDate),
      },
    },
    TotalPeriodsHoursField,
    ...form.activities
      .map((activity) =>
        parseFieldKeyListIntoControls(
          activity.uuid,
          form,
          activityIds,
          disableAll,
          periodDetail,
          onDeleteActivityButtonClick,
          onExcludeMealTimeChange,
          timesheetConfig,
          defaultTime
        )
      )
      .flat(),
    RemarksField,
    ApplyMultipleField,
  ];

  return controls.map((control) => ({
    ...control,
    label: `${t(control.label)}`,
    ...("placeholder" in control
      ? { placeholder: t(control.placeholder as string) }
      : {}),
  }));
};

export const getCreateApplicationFormLayout = ({
  t,
  form,
  setForm,
  periodDetail,
  disableAll,
  calendarDates,
  isEditingActivity,
  disabledDates,
  timesheetConfig,
}: {
  t: TFunction<"translation">;
  form: ICreateApplicationFormData;
  setForm: React.Dispatch<React.SetStateAction<ICreateApplicationFormData>>;
  periodDetail: ITimesheetDetailData;
  disableAll: boolean;
  calendarDates: { [key in string]: IDateTypeItemConfig };
  isEditingActivity: boolean;
  disabledDates: Date[];
  timesheetConfig?: ITimesheetConfigData;
}) => {
  const onChange = async (value: any, form: ICreateApplicationFormData) => {
    const cloneForm: ICreateApplicationFormData = {
      ...form,
      calendarDates: value,
    };
    const { totalAppliedUnits } = toFormRequest(cloneForm);
    setForm({
      ...cloneForm,
      totalPeriodsHours: totalAppliedUnits,
    });
  };
  return {
    rows: [
      {
        // TODO: see if can add onchange and bind two date
        columns: [
          createColumn(
            SelectionPeriodField.key,
            BASIC_CONTROL_LAYOUT_CONFIG_TWO_FIFTH_COLUMN
          ),
          createColumn(
            DateField.key,
            BASIC_CONTROL_LAYOUT_CONFIG_TWO_FIFTH_COLUMN
          ),
          createColumn(
            TotalPeriodsHoursField.key,
            BASIC_CONTROL_LAYOUT_CONFIG_TWO_FIFTH_COLUMN
          ),
        ],
      },
      ...form.activities.map((activity) => ({
        columns: [...parseFieldKeyListIntoColumns(activity.uuid)],
      })),
      {
        columns: [
          {
            component: () => (
              <div className={`base-control`}>
                <div className={`p-field`}>
                  {/* empty label to match with the height of other input */}
                  <label />
                  {!isEditingActivity && (
                    <div className="p-inputgroup">
                      <Button
                        label={t("timesheet_common_actionAddActivity")}
                        type="button"
                        onClick={() => {
                          const uuid = uuidv4();

                          const cloneForm: ICreateApplicationFormData = {
                            ...form,
                          };
                          const defaultFromTime = moment(
                            timesheetConfig?.fromTime,
                            "HH:mm:ss"
                          );
                          const defaultToTime = moment(
                            timesheetConfig?.toTime,
                            "HH:mm:ss"
                          );
                          const defaultTotalHours = moment
                            .duration(defaultToTime.diff(defaultFromTime))
                            .asHours();

                          parseFieldKeyListIntoFormKeyList(uuid).forEach(
                            (key) => (cloneForm[key] = undefined)
                          );
                          setForm({
                            ...cloneForm,
                            // setting default activity to date value as view date
                            [`${ToDateField.key}_${uuid}`]: form?.date,
                            [`${FromTimeField.key}_${uuid}`]: defaultFromTime,
                            [`${ToTimeField.key}_${uuid}`]: defaultToTime,
                            [`${TotalPeriodsHoursField.key}`]:
                              form?.totalPeriodsHours + defaultTotalHours,
                            [`${ExcludeMealTimeField.key}_${uuid}`]: false,

                            activities: [...form.activities, { uuid }],
                          });
                        }}
                        disabled={disableAll}
                      />
                    </div>
                  )}
                </div>
              </div>
            ),
          },
        ],
      },
      {
        columns: [createColumn(RemarksField.key)],
      },
      {
        columns: [createColumn(ApplyMultipleField.key)],
      },
      ...(form?.applyMultiple
        ? [
            {
              columns: [
                {
                  component: () => (
                    <TimesheetCalendar
                      value={form.calendarDates}
                      onChange={(e: any) => onChange(e.value, form)}
                      minDate={new Date(periodDetail?.startDate)}
                      maxDate={new Date(periodDetail?.endDate)}
                      viewDate={new Date(periodDetail?.startDate)}
                      dateConfig={calendarDates}
                      className="hide-arrow"
                      yearRange={`${new Date(
                        periodDetail?.startDate
                      ).getFullYear()}:${
                        new Date(periodDetail?.startDate).getFullYear() + 1
                      }`}
                      disabled={disableAll}
                      selectedActivityDate={form.date?.toDateString() as string}
                      disabledDates={disabledDates}
                    />
                  ),
                },
              ],
            },
          ]
        : []),
    ],
  };
};
