import moment from "moment";

export const getDateFromString = (
  value?: string,
  format?: string,
  toUpper = true
) => {
  if (!value) {
    return value;
  }

  const date = moment(value);

  if (!date.isValid()) {
    return value;
  }

  if (format) {
    const formatDate = date.format(format);

    return toUpper ? formatDate.toUpperCase() : formatDate;
  }

  return date;
};

export const GetMonthRange = (input?: string) => {
  const date = moment(input);

  const year = date.year();
  const month = date.month();

  return {
    startDate: moment(new Date(year, month, 1)),
    endDate: moment(new Date(year, month + 1, 0)),
  };
};

export const ConcatDateTime = (date: Date, time: Date) => {
  const hour = moment(time).hour();
  const minute = moment(time).minute();
  return moment(date).startOf("day").add(hour, "hour").add(minute, "minute");
};

export const ParseIntoRequestDateTime = (dateTime: Date) => {
  return moment(dateTime);
};

export const convertDateToUTC = (date: Date) => {
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  );
};

export const parseAdjust = (eventDate: any, isSameDay: boolean = true) => {
  const date = new Date(convertDateToUTC(new Date(eventDate)));
  let momentTime = moment()
    .startOf("date")
    .add(date.getHours(), "hour")
    .add(date.getMinutes(), "minute");
  if (!isSameDay) {
    momentTime = momentTime.add(1, "d");
  }
  return momentTime.toDate();
};
export const parseAdjustWithoutConvert = (
  eventDate: any,
  isSameDay: boolean = true
) => {
  const date = new Date(eventDate);
  let momentTime = moment()
    .startOf("date")
    .add(date.getHours(), "hour")
    .add(date.getMinutes(), "minute");
  if (!isSameDay) {
    momentTime = momentTime.add(1, "d");
  }
  return momentTime.toDate();
};

export const parseAdjustTwoDay = (start: string, end: string) => {
  //start : 2023/09/01 10:00:00 end : 2023/09/02 12:00:00
  // target : [{start : 2023/10/17 10:00:00, end: 2023/10/17 23:59:59},[start : 2023/10/18 00:00:00,end : 2023/10/18 12:00:00]]
  const date1 = new Date(convertDateToUTC(new Date(start)));
  const date2 = new Date(convertDateToUTC(new Date(end)));
  const momentTimeDay1Start = moment()
    .startOf("date")
    .add(date1.getHours(), "hour")
    .add(date1.getMinutes(), "minute");
  const momentTimeDay1End = moment()
    .startOf("date")
    .add(23, "hour")
    .add(59, "minute")
    .add(59, "seconds");
  const momentTimeDay2Start = moment()
    .startOf("date")
    .add(0, "hour")
    .add(0, "minute");
  // .add(0, "seconds");
  const momentTimeDay2End = moment()
    .startOf("date")
    .add(date2.getHours(), "hour")
    .add(date2.getMinutes(), "minute");
  return [
    { start: momentTimeDay1Start.toDate(), end: momentTimeDay1End.toDate() },
    { start: momentTimeDay2Start.toDate(), end: momentTimeDay2End.toDate() },
  ];
};
