import {
  createRef,
  useState,
  useEffect,
  useCallback,
  useMemo,
  useContext,
} from "react";
import { useTranslation } from "react-i18next";
import { SelectControlOption } from "../../../../../components/base-control/select-control/select-control";
import BaseForm from "../../../../../components/base-form/base-form";
import { IPeriodSelectFormData, IBaseFormOnChangeProps } from "../../constants";
import {
  getSelectPeriodFormControls,
  getSelectPeriodFormLayout,
} from "./form-helper/period-select-form-create-helper";
import { ApplicationContext } from "../../context";
import { ApplicationAction } from "../../context/action/application";
import { store } from "../../../../../redux/store";

interface IPeriodSelectForm {
  form: IPeriodSelectFormData;
  setForm: React.Dispatch<React.SetStateAction<IPeriodSelectFormData>>;
  filterTimePeriods: SelectControlOption[];
  delegationEmployees?: SelectControlOption[];
}

export const PeriodSelectForm = ({
  form,
  setForm,
  filterTimePeriods,
  delegationEmployees,
}: IPeriodSelectForm) => {
  const { employeeCode } = store.getState().auth;

  const formRef = createRef();
  const { t } = useTranslation();
  const [formControls, setFormControls] = useState<any[]>([]);
  const [formLayout, setFormLayout] = useState<any>({});

  const [, dispatch] = useContext(ApplicationContext);

  useEffect(() => {
    const controls = getSelectPeriodFormControls({
      t,
      filterTimePeriods,
      delegationEmployees,
      userSubmit: form.userSubmit,
    });
    const layout = getSelectPeriodFormLayout();

    setFormControls(controls);
    setFormLayout(layout);
  }, [delegationEmployees, filterTimePeriods, form.userSubmit, t]);

  const onChange = useCallback(
    (change: IBaseFormOnChangeProps<IPeriodSelectFormData>) => {
      let selectedEmployeeCode = form.employeeCode;
      let selectedEmployeeName = form.employeeName;
      // Set employee to self if userSubmit is true
      if (delegationEmployees) {
        const selectedEmployee = delegationEmployees?.find(
          ({ value }) =>
            value ===
            (form.userSubmit ? employeeCode : change.state.form.employeeCode)
        );
        selectedEmployeeCode = selectedEmployee?.value || "";
        // label is in format "[employeeName] ([employeeCode])"
        selectedEmployeeName =
          selectedEmployee?.label?.slice(
            0,
            selectedEmployee?.label?.indexOf("(")
          ) || "";
      }
      setForm({
        ...form,
        ...change.state.form,
        employeeName: selectedEmployeeName || "",
        employeeCode: selectedEmployeeCode,
      });
      dispatch!({ type: ApplicationAction.RESET_APPLICATION });
    },
    [delegationEmployees, dispatch, employeeCode, form, setForm]
  );

  const formProps = useMemo(
    () => ({
      ref: formRef,
      config: {
        controls: formControls,
        layout: formLayout,
      },
      form,
      touched: false,
      onChange,
      setForm,
    }),
    [form, formControls, formLayout, formRef, onChange, setForm]
  );

  return (
    <div className="main timesheet-application-form">
      <BaseForm {...formProps} />
    </div>
  );
};
