import { Button } from "primereact/button";
import {
  RefObject,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import LanguageLoader from "src/components/language-loader/language-loader";
import {
  CLAIM_SUBMODULE_CODE,
  EEPORTAL_LABEL_BASE_TABLE,
  FUNCTION_CODE,
  MESSAGE_SEVERITY,
} from "src/constants";
import { showSpinner } from "src/redux/actions/spinner";
import { store } from "src/redux/store";
import ClaimService from "src/services/hrmnet-api/claims";
import { PortalLink } from "src/services/utils";
import {
  showTimestampToastSuccess,
  showToast,
} from "src/services/utils/message";
import { isResponseOk } from "src/utils/utils";
import ConfirmButton from "../../components/claim-footer-action/confirm-button";
import ClaimForm from "../../components/claim-form";
import { HeaderMenu } from "../../components/header-menu";
import {
  CLAIMS_MENU_MODULE_KEY,
  CLAIMS_MENU_PAGE_KEY,
  CLAIMS_T_CONTEXT_KEY,
  CLAIM_STORE_ACTION,
} from "../../constants";
import { ClaimContext } from "../../context/claim-context-provider";
import { ConcatDateTime } from "../../utils/date-helper";
import { ToFormRequest, ToFormsRequest } from "../../utils/form-to-request";
import "./create.scss";
import { useLeaveWithoutSaveAlert } from "../../hooks/useLeaveWithoutSaveAlert";

const Header = () => {
  const { t } = useTranslation();
  const { state } = useContext(ClaimContext);
  const { form, delegationEmployees } = state;
  const { employeeCode } = store.getState().auth;

  return useMemo(() => {
    const delegatedEmployee = delegationEmployees?.find(
      (employee: any) => employee.value === form?.employeeCode
    );

    const delegateTitle = t("claims_submit_title_applyDelegated", {
      label: delegatedEmployee?.name,
    });

    const title = () => {
      if (
        employeeCode &&
        form?.employeeCode &&
        employeeCode !== form?.employeeCode &&
        !form?.userSubmit
      ) {
        return delegateTitle;
      }
      return t("claims_submit_title");
    };

    return (
      <div className="header">
        <HeaderMenu
          menuKey={CLAIMS_MENU_MODULE_KEY}
          pageKey={CLAIMS_MENU_PAGE_KEY.SUBMIT_CLAIM_APPLICATION}
        />

        <div
          className="title"
          dangerouslySetInnerHTML={{
            __html: `${title()}${form.index ? ` - Item ${form.index}` : ""}`,
          }}
        />
      </div>
    );
  }, [
    t,
    employeeCode,
    delegationEmployees,
    form?.index,
    form?.employeeCode,
    form?.userSubmit,
  ]);
};

const Footer = ({ formRefs, setFormTouched }: any) => {
  const location = useLocation();
  const { state, dispatch: contextDispatch } = useContext(ClaimContext);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const { form, disabled, forms } = state;

  const backToList = useCallback(() => {
    history.push(PortalLink(`${FUNCTION_CODE.Claims}`));
  }, [history]);

  const goToReview = useCallback(() => {
    history.push(
      PortalLink(
        `${FUNCTION_CODE.Claims}/${CLAIM_SUBMODULE_CODE.REVIEW_APPLICATION}`
      )
    );
  }, [history]);

  const actionWrapper = useCallback(
    (action: Promise<any>, message: string) => {
      dispatch(showSpinner(true));
      action
        .then((response) => {
          if (isResponseOk(response)) {
            backToList();
            showTimestampToastSuccess({ message, t } as any);
          }
        })
        .finally(() => dispatch(showSpinner(false)));
    },
    [backToList, dispatch, t]
  );

  const save = useCallback(() => {
    if (!validate(formRefs, setFormTouched)) {
      return false;
    }
    setFormTouched(false);

    actionWrapper(
      ClaimService.saveDraft(ToFormsRequest([form])),
      t("claims_submit_saveSuccessMessage")
    );
  }, [actionWrapper, form, formRefs, setFormTouched, t]);

  const hasOverlap = useCallback(() => {
    const currentFromDateTime = ConcatDateTime(form.fromDate, form.fromTime);
    const currentToDateTime = ConcatDateTime(
      form.toDate ?? form.fromDate,
      form.toTime ?? form.fromTime
    );

    return forms
      .filter((_form: any) => _form.uuid !== form.uuid)
      .some((_form: any) => {
        const fromDateTime = ConcatDateTime(_form.fromDate, _form.fromTime);
        const toDateTime = ConcatDateTime(
          _form.toDate ?? _form.fromDate,
          _form.toTime ?? _form.fromTime
        );

        return (
          form.claimType === _form.claimType &&
          fromDateTime <= currentToDateTime &&
          currentFromDateTime <= toDateTime
        );
      });
  }, [
    form.claimType,
    form.fromDate,
    form.fromTime,
    form.toDate,
    form.toTime,
    form.uuid,
    forms,
  ]);

  const addToForms = useCallback(async () => {
    dispatch(showSpinner(true));
    try {
      if (!validate(formRefs, setFormTouched)) {
        return false;
      }
      setFormTouched(false);

      if (hasOverlap()) {
        showToast({
          summary: "Overlapping record",
          severity: MESSAGE_SEVERITY.ERROR,
        });
        return false;
      }

      var response = await ClaimService.validate(ToFormRequest(form));

      if (!isResponseOk(response)) {
        return false;
      }

      contextDispatch({
        type: CLAIM_STORE_ACTION.FORMS_ADD,
      });

      const prevForm = {
        employeeCode: form.employeeCode,
        userSubmit: form.userSubmit,
      };
      contextDispatch({
        type: CLAIM_STORE_ACTION.FORM_RESET_WITH_PREV,
        payload: prevForm,
      });
    } finally {
      dispatch(showSpinner(false));
    }
  }, [contextDispatch, formRefs, setFormTouched, form, dispatch, hasOverlap]);

  const review = useCallback(async () => {
    if ((await addToForms()) === false) {
      return;
    }

    goToReview();
  }, [addToForms, goToReview]);

  return useMemo(() => {
    return (
      <div className="footer p-grid p-align-center p-justify-between">
        <div className="left-button-group">
          {!location?.state?.uuid ? (
            <Button
              className="back-button p-button-outlined secondary"
              onClick={backToList}
            >
              {t("claims_common_actionBack")}
            </Button>
          ) : (
            <Button
              className="back-button p-button-outlined secondary"
              onClick={goToReview}
            >
              {t("claims_common_actionBackToReview")}
            </Button>
          )}
        </div>

        <div className="right-button-group">
          {form?.claimType && (
            <>
              {forms.length <= 0 && (
                <Button
                  className="p-button-outlined secondary"
                  onClick={save}
                  disabled={!(form?.units || form?.amounts) || disabled}
                >
                  {t("claims_common_actionSave")}
                </Button>
              )}

              <ConfirmButton
                text={t(
                  !form?.uuid
                    ? "claims_common_actionProceed"
                    : "claims_common_actionUpdate"
                )}
                className="p-button primary submit-button"
                title={t("claims_confirm_title")}
                content={t("claims_submit_confirmProceedMessage")}
                primaryText={t("claims_submit_confirmProceedPrimaryText")}
                primaryAction={review}
                secondaryText={t("claims_submit_confirmProceedSecondaryText")}
                secondaryAction={addToForms}
                outline={false}
                disabled={!(form?.units || form?.amounts) || disabled}
              />
            </>
          )}
          {!form?.claimType && forms.length >= 1 && (
            <>
              <ConfirmButton
                text={t(
                  !form?.uuid
                    ? "claims_common_actionProceed"
                    : "claims_common_actionUpdate"
                )}
                className="p-button primary submit-button"
                title={t("claims_confirm_title")}
                content={t("claims_submit_confirmProceedMessage")}
                primaryText={t("claims_submit_confirmProceedPrimaryText")}
                primaryAction={goToReview}
                secondaryText={t(
                  "claims_submit_confirmProceedSecondaryRegretText"
                )}
                // secondaryAction={addToForms}
                outline={false}
                // disabled={!(form?.units || form?.amounts) || disabled}
              />
            </>
          )}
        </div>
      </div>
    );
  }, [
    t,
    backToList,
    goToReview,
    form?.claimType,
    form?.uuid,
    form?.units,
    form?.amounts,
    save,
    review,
    addToForms,
    disabled,
    forms,
    location?.state?.uuid,
  ]);
};

const validate = (formRefs: RefObject<any>[], setFormTouch: any) => {
  setFormTouch(true);
  return !formRefs.some((formRef) => formRef?.current?.getFormState()?.invalid);
};

const Create = () => {
  const location = useLocation();
  const headerRef = useRef();
  const detailRef = useRef();
  const delegateRef = useRef();

  const [formTouched, setFormTouched] = useState(false);
  const { dispatch } = useContext(ClaimContext);
  const { employeeCode } = store.getState().auth;

  useEffect(() => {
    if (!location.state?.uuid) {
      dispatch({
        type: CLAIM_STORE_ACTION.FORM_RESET,
      });
      dispatch({
        type: CLAIM_STORE_ACTION.FORMS_RESET,
      });
    }
  }, [dispatch, location.state?.uuid]);

  useLeaveWithoutSaveAlert({
    allowRoutes: [
      `/${FUNCTION_CODE.Claims}/${CLAIM_SUBMODULE_CODE.REVIEW_APPLICATION}`,
    ],
  });

  return (
    <>
      <LanguageLoader
        contexts={[
          EEPORTAL_LABEL_BASE_TABLE.BASE_TABLE,
          CLAIMS_T_CONTEXT_KEY.COMMON,
          CLAIMS_T_CONTEXT_KEY.SUBMIT_APPLICATION,
          CLAIMS_T_CONTEXT_KEY.FORM,
        ]}
      />
      <div className="claim-container claim-submit-application">
        <Header />

        <ClaimForm
          headerRef={headerRef}
          detailRef={detailRef}
          delegateRef={delegateRef}
          formTouched={formTouched}
          applicant={employeeCode}
        />

        <Footer
          formRefs={[headerRef, detailRef, delegateRef]}
          setFormTouched={setFormTouched}
        />
      </div>
    </>
  );
};

export default Create;
