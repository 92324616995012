export const SHORT_DATE_FORMAT = "YYYY-MM-DD";
export const LONG_DATE_FORMAT = "YYYY-MM-DD (ddd)";
export const DATE_FORMAT = "YYYY-MM-DD";
export const TIME_FORMAT = "HH:mm:ss";
export const INPUT_TIME_FORMAT = "HH:mm";
export const DATETIME_FORMAT = "YYYY-MM-DD HH:mm:ss";

export const CLAIMS_WORKFLOW_STATUS = {
  IN_PROGRESS: 0,
  COMPLETE: 1,
  REJECT: 2,
  SUSPEND: 3,

  // Special
  DRAFT: 6,
  DELETED: 8,
  ADMIN_ENTRY_CREATED: 9,
  LEAVE_STATUS_AMENDED: 10,
};

export const LEAVE_TYPE = {
  ANNUAL: "AL",
  COMPENSATION: "CEL",
  SICK: "L",
  COMPASSIONATE: "CL",
  OTHER: "OL",
  PATERNITY: "PL",
  MARRIAGE: "ML",
  BUSINESS: "BL",
  NO_PAY: "NPL",
  BIRTHDAY: "BL",
  JURY_DUTY: "JL",
  STUDY_EXAM: "STUDYL",
};

export const DATE_LEAVE_STATUS = {
  [CLAIMS_WORKFLOW_STATUS.COMPLETE]: {
    label: "leave_dateLeaveStatus_approved",
    color: "#000000",
  },
};

export const DATE_TYPE = {
  WORKING: "Y",
  REST: "P",
  NON_PAID_REST: "N",
  HOLIDAY: "H",
  STATUTORY: "S",
};

/**
 * interface DateConfigItem
 * showMark: boolean;
 * disabled: boolean;
 * isRedDay: boolean;
 */
export const DATE_TYPE_CONFIG = {
  [DATE_TYPE.WORKING]: {
    disabled: false,
    isRedDay: false,
    color: "#ff0000",
  },
  [DATE_TYPE.REST]: {
    disabled: true,
    isRedDay: false,
    color: "#2d7829",
  },
  [DATE_TYPE.NON_PAID_REST]: {
    disabled: true,
    isRedDay: false,
    color: "#fece2f",
  },
  [DATE_TYPE.HOLIDAY]: {
    disabled: false,
    isRedDay: true,
    color: "#0000ff",
  },
  [DATE_TYPE.STATUTORY]: {
    disabled: false,
    isRedDay: true,
    color: "#ff00ff",
  },
};

export const UOM = {
  DAY: "DAY",
  HOUR: "HOUR",
};

export const DISPLAY_UOM = {
  [UOM.DAY]: "leave_common_uomDay",
  [UOM.HOUR]: "leave_common_uomHour",
};

export const DELEGATE_TYPE = {
  "Apply Claim": 4,
  "Approve Claim": 5,
};
