import moment from "moment";

import { Button } from "primereact/button";
import { useCallback, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { openModal } from "src/redux/actions/modal";
import {
  CLAIM_STORE_ACTION,
  INPUT_TIME_FORMAT,
  LONG_DATE_FORMAT,
} from "../../constants";
import { FORM_CONTROL_DISPLAY_MODE } from "../../constants/layout";
import { ClaimContext } from "../../context/claim-context-provider";
import { GetDisplayUom } from "../../utils/ui-helper";
import ClaimFormItemWrapper from "../claim-form-item-wrapper";
import "./summary.scss";

const SummaryModalContent = ({ form }: any) => {
  const { t } = useTranslation();

  return (
    <div className="p-fluid p-formgrid p-grid p-my-2">
      <div className="p-field p-col-12 p-md-6">
        <label>{t("claims_form_claimType")}</label>
        <div>{form.itemDescription}</div>
      </div>

      <ClaimFormItemWrapper displayMode={form.transDateDisplayMode}>
        <div className="p-field p-col-12 p-md-6">
          <label>{t("claims_form_startDate")}</label>
          <div>{moment(form.fromDate).format(LONG_DATE_FORMAT)}</div>
        </div>
      </ClaimFormItemWrapper>

      <ClaimFormItemWrapper
        displayMode={form.transDateDisplayMode}
        condition={[FORM_CONTROL_DISPLAY_MODE.FROM]}
      >
        <div className="p-field p-col-12 p-md-6">
          <label>{t("claims_form_endDate")}</label>
          <div>{moment(form.toDate).format(LONG_DATE_FORMAT)}</div>
        </div>
      </ClaimFormItemWrapper>

      <ClaimFormItemWrapper displayMode={form.transTimeDisplayMode}>
        <div className="p-field p-col-12 p-md-6">
          <label>{t("claims_form_startTime")}</label>
          <div>{moment(form.fromTime).format(INPUT_TIME_FORMAT)}</div>
        </div>

        <div className="p-field p-col-12 p-md-6">
          <label>{t("claims_form_endTime")}</label>
          <div>{moment(form.toTime).format(INPUT_TIME_FORMAT)}</div>
        </div>
      </ClaimFormItemWrapper>

      <ClaimFormItemWrapper displayMode={form.appliedUnitDisplayMode}>
        <div className="p-field p-col-12 p-md-6">
          <label>{t("claims_form_unit")}</label>
          <div>
            {form?.units} {GetDisplayUom(form?.uom, t)}
          </div>
        </div>
      </ClaimFormItemWrapper>

      <ClaimFormItemWrapper displayMode={form.appliedAmountDisplayMode}>
        <div className="p-field p-col-12 p-md-6">
          <label>{t("claims_form_amount")}</label>
          <div>${(Math.round(form.amounts * 100) / 100).toFixed(2)}</div>
        </div>
      </ClaimFormItemWrapper>

      <div className="p-field p-col-12 p-md-6">
        <label>{t("claims_form_remarks")}</label>
        <div>{form?.remarks}</div>
      </div>
    </div>
  );
};

const SummaryCard = ({ form }: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { dispatch: contextDispatch } = useContext(ClaimContext);

  const reviewSummary = useCallback(async () => {
    dispatch(
      openModal({
        title: `${t("claims_form_item")} ${form.index}`,
        content: <SummaryModalContent form={form} />,
        classNameMainDialog: "confirm-message-modal",
        primaryButtonText: t("misc_idle_dialog_complete_primary_button"),
        secondaryButtonText: t("claims_submit_review_edit"),
        primaryButtonClickFn: async ({ closeFn }: any) => {
          closeFn();
        },
        secondButtonClickFn: ({ closeFn }: any) => {
          contextDispatch({
            type: CLAIM_STORE_ACTION.FORM_UPDATE,
            payload: form,
          });
          closeFn();
        },
      })
    );
  }, [form, dispatch, contextDispatch, t]);

  return useMemo(() => {
    return (
      <div className="claim-summary border p-px-3 p-my-2 p-as-stretch">
        <div className="p-formgrid p-grid p-my-3">
          <div className="p-field p-col-4">
            <Button
              label={`${t("claims_form_item")} ${form.index}`}
              onClick={reviewSummary}
              className="p-button-text p-item-button"
            />
          </div>

          <div className="p-field p-col-8 p-text-right">
            {moment(form.fromDate).format(LONG_DATE_FORMAT)}
          </div>
        </div>

        <div className="p-formgrid p-grid">
          <div className="p-field p-col-4 p-text-bold">
            {t("claims_form_claimType")}
          </div>

          <div className="p-field p-col-8">{form.itemDescription}</div>

          <div className="p-field p-col-4 p-text-bold">
            {form.transTimeDisplayMode?.toLowerCase() !==
            FORM_CONTROL_DISPLAY_MODE.NA
              ? t("claims_form_time")
              : t("claims_form_amount")}
          </div>

          <div className="p-field p-col-8">
            {form.transTimeDisplayMode?.toLowerCase() !==
            FORM_CONTROL_DISPLAY_MODE.NA
              ? `${moment(form.fromTime).format(INPUT_TIME_FORMAT)} -
                ${moment(form.toTime).format(INPUT_TIME_FORMAT)}
              `
              : `$${(Math.round(form.amounts * 100) / 100).toFixed(2)}`}
          </div>
        </div>
      </div>
    );
  }, [form, reviewSummary, t]);
};

export default function ClaimFormSummary() {
  const { t } = useTranslation();
  const { state } = useContext(ClaimContext);
  const { forms, form } = state;

  return useMemo(() => {
    const filteredForms = forms?.filter(
      (_form: any) => _form.uuid !== form.uuid
    );

    return !filteredForms?.length ? (
      <></>
    ) : (
      <div className="p-d-flex p-flex-column p-ai-center p-mx-2">
        <h4 className="p-as-start p-ac-start">
          {t("claims_form_pastSubmission")}
        </h4>
        {filteredForms.map((_form: any) => (
          <SummaryCard key={_form.uuid} form={_form} />
        ))}
      </div>
    );
  }, [forms, form.uuid]);
}
