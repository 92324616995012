import { useTimesheetConfigurationContext } from "../context/useTimesheetConfigurationContext";
import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";

export const TimesheetConfigurationFooter = () => {
  const { t } = useTranslation();
  const {
    form,
    isPrimaryButtonDisabled,
    onPrimaryButtonClick,
    primaryButtonLabel,
  } = useTimesheetConfigurationContext();

  return (
    <div className="footer p-grid p-align-center p-justify-end">
      <div className="right-button-group">
        {form && (
          <Button
            className="p-button"
            onClick={onPrimaryButtonClick}
            disabled={isPrimaryButtonDisabled}
          >
            {t(primaryButtonLabel)}
          </Button>
        )}
      </div>
    </div>
  );
};
