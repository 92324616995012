import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button } from "primereact/button";
import {
  EmployeeCreateApiSteps,
  EmployeeCreateConfiguration,
} from "./employee-form-fields";
import { scrollToErrorControl } from "../../../../components/base-form/utils";
import { showSpinner } from "../../../../redux/actions/spinner";
import {
  UserManagementService,
  AdminAccessPermissionService,
  MasterService,
} from "../../../../services/hrmnet-api";
import BaseMultiStepForm from "../../../../components/base-multi-step-form/base-multi-step-form";
import { BaseMultiStepFormSkeleton } from "../../../../components/base-multi-step-form/base-multi-step-form-skeleton";
import {
  BaseMultiStepConfigToFormModel,
  BaseMultiStepConfigToStepNavModel,
  BaseMultiStepCustomFunctionLibrary,
  BaseMultiStepFormInitFormValue,
} from "../../../../components/base-multi-step-form/base-multi-step-form-utils";
import { BaseMultiStepFormScrollToMultiStepFormMenuActiveItem } from "../../../../components/base-multi-step-form/base-multi-step-form-scroll";
import {
  setFormLanguage,
  setMainForm,
  setMainFormConfig,
  setMainFormSectionConfig,
} from "../../../../redux/actions/form";
import { useHistory } from "react-router-dom";
import { PortalLink } from "../../../../services/utils/index";
import { FUNCTION_CODE, MODULE_CODE } from "../../../../constants";
import { getResponseMessage, isResponseOk } from "../../../../utils/utils";
import {
  showErrorMessageModal,
  showTimestampToastSuccess,
} from "../../../../services/utils/message";

const EmployeeCreateForm = ({ isLangChange }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [state, setState] = useState({
    isLoading: true,
    activeStep: 0,
    formResp: {},
    formName: "",
    touched: false,
  });
  const history = useHistory();
  const form = useSelector((state) => state.commonForm.form);
  const setForm = (x) => dispatch(setMainForm(x));
  const formConfig = useSelector((state) => state.commonForm.formConfig);
  const setFormConfig = (x) => dispatch(setMainFormConfig(x));
  const setFormSectionConfig = (x) => dispatch(setMainFormSectionConfig(x));

  const formObjRef = React.createRef();

  const customFunctionLibrary = BaseMultiStepCustomFunctionLibrary({
    dispatch,
  });

  const selectedLangKey = useSelector(
    (state) => state.language.language?.selectedLang?.key
  );

  useEffect(() => {
    const loadForm = async () => {
      console.log("isLangChange", isLangChange);
      dispatch(setFormLanguage(selectedLangKey));

      //enum
      const dataAuthorizationListApi =
        AdminAccessPermissionService.accessGetFiltersAsync();
      let [dataAuthorizationList] = await Promise.all([
        dataAuthorizationListApi,
      ]);
      let companyOptions = dataAuthorizationList.data.find(
        (item) => item.name === "Company"
      ).dataList;
      let gradeOptions = dataAuthorizationList.data.find(
        (item) => item.name === "Grade"
      ).dataList;
      let statusOptions = dataAuthorizationList.data.find(
        (item) => item.name === "Status"
      ).dataList;
      let employeeOptions = dataAuthorizationList.data.find(
        (item) => item.name === "EmployeeAdmin"
      ).dataList;
      let departmentOptions = dataAuthorizationList.data.find(
        (item) => item.name === "Department"
      ).dataList;
      let holidayclassOptions = dataAuthorizationList.data.find(
        (item) => item.name === "HolidayClass"
      ).dataList;
      let payrollIndicatorOptions = ["Y", "N", "P"].map((value, index) => ({
        id: index,
        name: value,
        description: value,
        value: value,
      }));
      let stepRes = EmployeeCreateApiSteps(t);
      let formConfigRes = EmployeeCreateConfiguration(
        t,
        companyOptions,
        gradeOptions,
        statusOptions,
        employeeOptions,
        departmentOptions,
        holidayclassOptions,
        payrollIndicatorOptions
      );

      let stepList = [];
      let newForm = {};
      let newFormResp = {};
      let newFormConfig = {};
      let formName;
      let activeStep = 0;

      if (stepRes && stepRes.data && formConfigRes && formConfigRes.data) {
        let customData = {
          customFunctionLibrary: customFunctionLibrary,
        };
        stepList = BaseMultiStepConfigToStepNavModel(stepRes.data);
        console.log("stepList", stepList);
        // formName and breadcrumb
        if (formConfigRes.data.formName) {
          formName = formConfigRes.data.formName;
        }

        console.log("form config: ", formConfigRes.data.sections);
        newForm = BaseMultiStepFormInitFormValue(formConfigRes.data.sections);

        // newFormResp and formConfig
        newFormResp = formConfigRes.data;
        newFormConfig = BaseMultiStepConfigToFormModel({
          applicationId: "",
          stepConfig: stepRes.data,
          sectionFieldConfig: formConfigRes.data.sections,
          customData: customData,
          t: t,
          form: newForm,
        });
        console.log("stepRes.data", stepRes.data);
        console.log("newFormConfig", newFormConfig);
        newFormConfig.steps[0].sections[0].defaultExpanded = null;
        newFormConfig.steps[0].sections[1].defaultExpanded = null;
      }

      setForm(newForm);
      setFormConfig(newFormConfig);
      setFormSectionConfig(newFormResp);

      setState({
        ...state,
        formName,
        stepList,
        activeStep,
        isLoading: false,
      });
    };

    loadForm();

    BaseMultiStepFormScrollToMultiStepFormMenuActiveItem(
      ".employee-data-amendment-form-title"
    );

    return () => {
      setState({
        ...state,
        isLoading: true,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Navigation and action
  const validateCurrentStep = () => {
    const isValid = !formObjRef.current.getActiveFormState({ expand: false })
      .invalid;
    setTimeout(() => {
      scrollToErrorControl();
    }, 100);
    return isValid;
  };

  const confirmSubmitApplication = async () => {
    if (validateCurrentStep()) {
      submitApplicationAction();
    } else {
      setState({
        ...state,
        touched: true,
      });
    }
  };

  const submitApplicationAction = async () => {
    try {
      // show spinner
      dispatch(showSpinner(true));
      let res = await UserManagementService.createEmployee({
        body: form,
      });
      if (res.data.status === true) {
        showTimestampToastSuccess({
          message: t("User_Management_Employee_Create_SuccessMessageSubmit"),
          t: t,
        });
        history.push(
          PortalLink(
            `${MODULE_CODE.Admin}/${FUNCTION_CODE.UserManagementEmployeeList}`
          )
        );
      } else {
        showErrorMessageModal({
          dispatch,
          t,
          content: getResponseMessage(res),
        });
      }
    } catch (e) {
      // show notification?
      console.error(e.message);
    } finally {
      // end spinner
      dispatch(showSpinner(false));
    }
  };

  // Rendering

  const renderForm = () => (
    <BaseMultiStepForm
      form={form}
      activeStep={0}
      config={formConfig}
      ref={formObjRef}
      touched={state.touched}
    />
  );

  const renderFooter = () => {
    return (
      <div className="footer p-grid p-align-center p-justify-between">
        <div>
          <Button
            label={t("admin_common_actionCancel")}
            className="p-button-secondary"
            onClick={() =>
              history.push(
                PortalLink(
                  `${MODULE_CODE.Admin}/${FUNCTION_CODE.UserManagementEmployeeList}`
                )
              )
            }
          />
        </div>
        <div className="right-button-group">
          <Button
            onClick={() => confirmSubmitApplication()}
            className="p-button next-button"
          >
            {t("admin_common_actionSubmit")}
          </Button>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="admin-form-style">
        {state.isLoading ? (
          <div className="form-loading">{BaseMultiStepFormSkeleton}</div>
        ) : (
          renderForm()
        )}

        {/* Footer */}
        {renderFooter()}
      </div>
    </>
  );
};

export default EmployeeCreateForm;
