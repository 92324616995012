export const CLAIMS_LIST_ACTIONS = {
  VIEW_APPLICATION: "View Application",
  VIEW_APPLICATION_DETAILS: "View Application Details",
  EDIT_APPLICATION: "Edit Application",
  DELETE_DRAFTS: "Delete Draft",
  CANCEL_APPLICATION: "Cancel Application",
};

export const CLAIMS_LIST_ACTION_TYPE = {
  DELETE: "delete",
  CANCEL: "cancel",
};

export const CLAIM_STORE_ACTION = {
  FORMS_ADD: "FORMS_ADD",
  FORMS_RESET: "FORMS_RESET",

  FORM_RESET: "FORM_RESET",
  FORM_RESET_WITH_PREV: "FORM_RESET_WITH_PREV",
  FORM_UPDATE: "FORM_UPDATE",
  FORM_REMOVE: "FORM_REMOVE",

  FORM_EDIT: "FORM_EDIT",

  FORM_ACTION_ENABLE: "FORM_ACTION_ENABLE",
  FORM_ACTION_DISABLE: "FORM_ACTION_DISABLE",

  DELEGATION_EMPLOYEES: "DELEGATION_EMPLOYEES",
};
