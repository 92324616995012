import { ModalAction, ModalPayload } from "../action/modal";
import { ActionMap } from "../interface";
import { ITimesheetDetailActivity } from "./application";

export interface IActivityModalDetails {
  periodId: number;
  period: string;
  editActivity?: ITimesheetDetailActivity;
  isFromView: boolean;
  isEdit: boolean;
}

export interface IModalState {
  isShowActivityModal: boolean;
  activityModalDetails: IActivityModalDetails;
}

export const initialModalState: IModalState = {
  isShowActivityModal: false,
  activityModalDetails: {
    periodId: 0,
    period: "",
    editActivity: {} as ITimesheetDetailActivity,
    isFromView: false,
    isEdit: false,
  },
};

export type ModalReducer = React.Reducer<
  IModalState,
  ActionMap<ModalAction, ModalPayload>
>;
//   Reducer<S[K], A>
export const modalReducer: ModalReducer = (
  state = initialModalState,
  action
) => {
  switch (action.type) {
    case ModalAction.SET_IS_SHOW_ACTIVITY_MODAL:
      return {
        ...state,
        isShowActivityModal: action.payload,
      };
    case ModalAction.SET_ACTIVITY_MODAL_DETAILS:
      return {
        ...state,
        activityModalDetails: action.payload.activityModalDetails,
      };
    case ModalAction.RESET_ACTIVITY_MODAL:
      return initialModalState;
    default:
      return state;
  }
};
