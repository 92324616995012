import { DISPLAY_UOM } from "../constants";
import { FORM_CONTROL_DISPLAY_MODE } from "../constants/layout";

export function ToUIOption(enums: any[], isGrouped = false) {
  if (Array.isArray(enums)) {
    return enums?.map((option) => {
      const result: any = {
        label: option.name,
        value: option.value ?? option.id,
      };

      if (
        isGrouped &&
        option.description?.toLowerCase() !== option.name.toLowerCase()
      ) {
        if (!!option.description) {
          result.group = option.description;
        }
      }
      if (!!option?.disabled) {
        result.disabled = option.disabled;
      }
      return result;
    });
  }
  return enums;
}

export function GetDisplayUom(uom: any, t: any) {
  if (!uom) return uom;
  if (DISPLAY_UOM[uom]) return t(DISPLAY_UOM[uom]);
  return uom;
}

export function DisplayControl(field: any, mode?: string) {
  if (!mode) {
    return null;
  }

  if (field?.show) {
    return field.show.includes(mode.toLowerCase()) ? field : null;
  }

  return [
    `${FORM_CONTROL_DISPLAY_MODE.NA}`,
    `${FORM_CONTROL_DISPLAY_MODE.HIDE}`,
  ].includes(mode.toLowerCase())
    ? null
    : field;
}

export function EnableControl(mode?: string) {
  if (!mode) {
    return null;
  }

  return [
    `${FORM_CONTROL_DISPLAY_MODE.REQUIRED}`,
    `${FORM_CONTROL_DISPLAY_MODE.OPTIONAL}`,
  ].includes(mode.toLowerCase());
}
