import { BCType } from "src/components/base-control/base-control";
import { FORM_CONTROL_DISPLAY_MODE } from "../constants/layout";

export const EmployeeCodeField = {
  key: "employeeCode",
  label: "claims_form_delegatedEmployee",
  type: BCType.select,
  hasFilterEnum: false,
  required: true,
  placeholder: "claims_form_delegatedEmployee_placeholder",
  config: {
    disabled: false,
  },
};

export const ClaimTypesField = {
  key: "claimType",
  label: "claims_form_claimType",
  type: BCType.select,
  required: true,
  hasFilterEnum: false,
  placeholder: "claims_form_selectClaimType",
};

export const UserSubmitField = {
  key: "userSubmit",
  label: "claims_form_userSubmit",
  type: BCType.checkbox,
  required: false,
  placeholder: "claims_form_userSubmit_placeholder",
};

export const FromDateField = {
  key: "fromDate",
  label: "claims_form_startDate",
  type: BCType.date,
  required: true,
  dateFormat: "yy-mm-dd",
  placeholder: "claims_form_select",
  show: [
    `${FORM_CONTROL_DISPLAY_MODE.FROM}`,
    `${FORM_CONTROL_DISPLAY_MODE.BOTH}`,
  ],
};

export const FromTimeField = {
  key: "fromTime",
  label: "claims_form_startTime",
  type: BCType.date,
  required: true,
  includeTimeZone: true,
  config: {
    viewDate: new Date(),
    tooltip: null,
    timeOnly: true,
    showIcon: false,
    hourFormat: "24",
  },
};

export const ToDateField = {
  key: "toDate",
  label: "claims_form_endDate",
  type: BCType.date,
  required: true,
  dateFormat: "yy-mm-dd",
  placeholder: "claims_form_select",
  show: [`${FORM_CONTROL_DISPLAY_MODE.BOTH}`],
};

export const ToTimeField = {
  key: "toTime",
  label: "claims_form_endTime",
  type: BCType.date,
  includeTimeZone: true,
  required: true,
  config: {
    viewDate: new Date(),
    tooltip: null,
    timeOnly: true,
    showIcon: false,
    hourFormat: "24",
  },
};

export const AmountField = {
  key: "amounts",
  label: "claims_form_amount",
  type: BCType.number,
  required: true,
  config: {
    tooltip: null,
    mode: "decimal",
    minFractionDigits: 1,
    maxFractionDigits: 2,
    showButtons: false,
  },
  placeholderStrict: true,
  placeholder: " ",
};

export const UnitField = {
  key: "units",
  label: "claims_form_unit",
  type: BCType.number,
  required: true,
  config: {
    readOnly: true,
    tooltip: null,
    disabled: true,
    mode: "decimal",
    minFractionDigits: 1,
    maxFractionDigits: 2,
    showButtons: false,
  },
  placeholderStrict: true,
  placeholder: " ",
};

export const DisplayCompLeaveField = {
  key: "compLeaveDisplay",
  label: "claims_form_compLeaveDisplay",
  componentRender: (data: any) => <div className="p-ml-1">{data.value}</div>,
};

export const DisplayDateTypeField = {
  key: "dateTypeDisplay",
  label: "claims_form_dateTypeDisplay",
  componentRender: (data: any) => <div className="p-ml-1">{data.value}</div>,
};

export const RemarksField = {
  key: "remarks",
  label: "claims_form_remarks",
  type: BCType.textarea,
  config: {
    autoResize: true,
    rows: 1,
    tooltip: null,
  },
  placeholder: "claims_form_entryRemarks",
  maxLength: 70,
};
