import { TFunction } from "react-i18next";
import { MyListColumns } from "./table-column-config";
import {
  getColumModel,
  getBTConfig,
} from "../../../../../components/base-table/base-table-model";
import { TIMESHEET_LIST_ACTIONS, timesheetRoute } from "../../constants";
import { GetLink, mapActionToMode } from "../../utils/ui-action-helper";
import { TIMESHEET_DETAIL_TYPE } from "../../constants/type";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

const excludedClasses = [
  "action-column",
  "p-button-icon",
  "p-menu",
  "p-menuitem-text",
];

/*
 * 1. Add action column to the end
 * 2. Translate column header
 */
const renderConfigColumns = ({
  columns,
  t,
}: {
  columns: any[];
  t: TFunction;
}) => {
  return [
    ...columns,
    getColumModel({
      header: "freezeColumn",
      key: "",
      dataType: "string",
      sortable: false,
      width: 0.1,
      frozen: true,
      hideMobile: true,
    }),
  ].map((column) => ({
    ...column,
    header: t(`${column.header}`),
  }));
};

/**
 * Return timesheet table config
 */
export const ListConfigModel = ({
  columns = MyListColumns,
  t,
  redirect,
  confirm,
  toolbar,
  useHrActions,
  expandedRows,
  setExpandedRows,
}: {
  columns?: any[];
  t: TFunction;
  redirect?: Function;
  confirm?: Function;
  toolbar?: JSX.Element;
  useHrActions?: boolean;
  expandedRows: any;
  setExpandedRows: any;
}) => {
  return getBTConfig({
    columns: renderConfigColumns({ columns, t }),
    hasIndex: false,
    mode: "list",
    defaultMode: "list",
    showGlobal: true,
    hasColumnSelector: false,
    rowHover: true,
    responsive: true,
    customToolbar: toolbar,
    onRowClick: (e: any) => {
      if (!redirect) {
        return;
      }

      const classString = e.originalEvent.target.classList.value;
      if (
        classString
          ?.split(" ")
          ?.some((_class: string) => excludedClasses.includes(_class))
      ) {
        return;
      }

      let actions = useHrActions ? e.data.hrActions : e.data.actions;

      if (actions?.length && redirect) {
        const path = GetLink({
          action: actions?.[0],
          id: e.data.id,
          submodulePath: timesheetRoute.list.slice(1),
        });

        redirect(path, {
          type: mapActionToMode({ action: actions[0] }),
          period: e.data.period,
          periodId: e.data.period.id,
          referenceNo: e.data.referenceNo,
        });
      }
    },
    actionWidth: 5.3,
    actionsSingle: [
      {
        isOverflow: true,
        name: TIMESHEET_LIST_ACTIONS.AMEND_APPLICATION,
        title: t("timesheet_list_action_amendApplication"),
        clickFn: (data: any) =>
          redirect &&
          redirect(
            GetLink({
              action: TIMESHEET_LIST_ACTIONS.AMEND_APPLICATION,
              id: data.id,
              submodulePath: timesheetRoute.list.slice(1),
            }),
            {
              type: TIMESHEET_DETAIL_TYPE.AMEND,
              period: data.period,
              periodId: data.period.id,
              referenceNo: data.referenceNo,
            }
          ),
      },
      {
        isOverflow: true,
        name: TIMESHEET_LIST_ACTIONS.HR_AMEND_APPLICATION,
        title: t("timesheet_list_action_modifyApplication"),
        clickFn: (data: any) =>
          redirect &&
          redirect(
            GetLink({
              action: TIMESHEET_LIST_ACTIONS.HR_AMEND_APPLICATION,
              id: data.id,
              submodulePath: timesheetRoute.list.slice(1),
            }),
            {
              type: TIMESHEET_DETAIL_TYPE.HR_AMEND,
              period: data.period,
              periodId: data.period.id,
              referenceNo: data.referenceNo,
            }
          ),
      },
      {
        isOverflow: true,
        name: TIMESHEET_LIST_ACTIONS.DELETE_DRAFT,
        title: t("timesheet_list_action_deleteDraft"),
        clickFn: async (data: any) => {
          data.onDeleteButtonClick();
        },
      },
      {
        isOverflow: true,
        name: TIMESHEET_LIST_ACTIONS.EDIT_APPLICATION,
        title: t("timesheet_list_action_editApplication"),
        clickFn: (data: any) =>
          redirect &&
          redirect(
            GetLink({
              action: TIMESHEET_LIST_ACTIONS.EDIT_APPLICATION,
              id: data.id,
              submodulePath: timesheetRoute.list.slice(1),
            }),
            {
              type: TIMESHEET_DETAIL_TYPE.EDIT,
              period: data.period,
              periodId: data.period.id,
              referenceNo: data.referenceNo,
            }
          ),
      },
      {
        isOverflow: true,
        name: TIMESHEET_LIST_ACTIONS.VIEW_APPLICATION,
        title: t("timesheet_list_action_viewApplication"),
        clickFn: (data: any) => {
          redirect &&
            redirect(
              GetLink({
                action: TIMESHEET_LIST_ACTIONS.VIEW_APPLICATION,
                id: data.id,
                submodulePath: timesheetRoute.list.slice(1),
              }),
              {
                type: TIMESHEET_DETAIL_TYPE.VIEW,
                period: data.period,
                periodId: data.period.id,
                referenceNo: data.referenceNo,
              }
            );
        },
      },
    ],
    showActionsSingle: (action: any, actionsList: any) => {
      if (!action || !actionsList) return false;
      return actionsList.includes(action.title);
    },
    isExpandable: true,
    isExpandInMobileOnly: true,
    expandedRows: expandedRows,
    onRowToggle: (e: any) => {
      setExpandedRows(e.data);
    },
    rowExpansionTemplate: (data: any) => {
      const renderTableCell = (rowData: any, column: any) => {
        if (column.render) {
          return <div className="bt-cell-render">{column.render(rowData)}</div>;
        } else {
          return rowData[column.key];
        }
      };

      const columnsRender = columns.map((column, index) => {
        const columnConfig = {
          key: `bt-column-${index}`,
          field: column.key,
          header: column.header,
          className: `bt-${column.key}`,
          body: (rowData: any, config: any) => {
            return (
              <div className={`bt-row bt-row-${config.rowIndex} bt-cell`}>
                <span className="p-column-title mobile-p-column-title">
                  {t(column.header as any)}:
                </span>
                <div className="bt-cell-value">
                  {renderTableCell(rowData, column)}
                </div>
              </div>
            );
          },
        };

        return <Column {...columnConfig} />;
      });

      return (
        <DataTable
          key="info-table"
          value={[data]}
          className="p-datatable-responsive table-expandable-header timesheet-application-list-mobile"
        >
          {columnsRender}
        </DataTable>
      );
    },
  } as any);
};
