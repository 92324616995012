import "./base-form.scss";

import BaseControl, {
  BCType,
  validateControl,
} from "./../base-control/base-control";
import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
import { isObjectEmpty, jsonEqual, sleep } from "./../../utils/utils";

import { Button } from "primereact/button";
import { Menu } from "primereact/menu";
import { Message } from "primereact/message";
import { getControlModel } from "./../base-control/base-cotrol-model";
import { openModal } from "../../redux/actions/modal";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { GetComponentValue } from "./utils";

export const ProcessFormState = (form, controlsConfig, t) => {
  return initFormState(form, controlsConfig, t);
};

const initFormState = (form, controlsConfig, t) => {
  let formState = {
    invalid: false,
  };
  controlsConfig.forEach((control) => {
    if (control?.layoutConfig?.hidden) {
      return;
    }

    let ruleList = control.ruleList || [];
    if (control.required) {
      ruleList.push({
        name: "required",
      });
    }
    if (!form.hasOwnProperty(control.key)) {
      form[control.key] = undefined;
    }
    let controlState = validateControl(
      ruleList,
      GetComponentValue(control.type, form, control.key, control?.enum),
      t,
      control.type
    );

    if (!controlState.invalid && control.validateControl) {
      controlState = control.validateControl(form[control.key]);
    }
    formState[control.key] = controlState;
    if (controlState.invalid) {
      formState.invalid = true;
    }
  });
  return {
    form,
    formState,
  };
};

// BaseForm --- start---
const BaseForm = React.forwardRef((props, ref) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectedLangKey = useSelector(
    (state) => state.language.language?.selectedLang?.key
  );
  // extract props data
  const id = props.id || "BaseForm_default_id";
  // init state control
  let initState = {
    touched: false,
    loading: true,
    submiting: false,
    isSubmited: false,
    form: props.form || {},
    formState: {
      invalid: false,
    },
    formAlert: [],
  };

  let refGr = useRef({});
  const [state, setState] = useState(initState);
  // Unsubcribe function
  const mountedRef = useRef(true);
  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);
  // On change
  useEffect(() => {
    const initForm = async () => {
      await sleep(1);
      let _form = {};
      if (props.loadFornFn) {
        _form = await props.loadFornFn();
      } else {
        _form = props.form || {};
      }

      var initF = initFormState(_form, props.config?.controls || [], t);

      const _state = {
        loading: false,
        submiting: false,
        touched: props.defaultTouch,
        layoutConfig: props.config?.layout,
        ...initF,
        formAlert: [],
      };
      if (!mountedRef.current) return null;
      setState(_state);
    };
    initForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.loadFornFn, props.form, props.defaultTouch]);

  useEffect(() => {
    const _state = {
      ...state,
      touched: props.touched,
    };
    if (!mountedRef.current) return null;
    setState(_state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.touched]);

  // -- language change
  useEffect(() => {
    if (!state.touched) {
      return;
    }
    let _form = state.form || {};
    const _controlsConfig = props.config?.controls || [];
    const _initFormState = initFormState(_form, _controlsConfig, t);
    // re-render with new validate error messages
    let newState = {
      ...state,
      formState: _initFormState.formState,
    };
    setState(newState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLangKey]);

  // onbeforeunload
  useEffect(() => {
    if (props.readOnly) {
      window.onbeforeunload = function () {};
    } else {
      window.onbeforeunload = function () {};
      // window.onbeforeunload = function () {
      //   return "Your work will be lost.";
      // };
    }
  });
  useEffect(
    () => () => {
      window.onbeforeunload = function () {};
    },
    []
  );
  /** Watch change */
  useEffect(() => {
    if (props.onStateChange) {
      props.onStateChange({
        loading: state.loading,
        submitting: state.submitting,
        touched: state.touched,
        invalid: state.formState.invalid,
        formState: state.formState,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.loading, state.submitting, state.touched, state.formState]);

  /** Expose function  */
  useImperativeHandle(ref, () => ({
    submitForm() {
      return onSubmitForm();
    },
    submitFormResult(res) {
      return onSubmitFormResult(res);
    },
    getFormState() {
      let formState = initFormState(
        state.form,
        props.config?.controls || [],
        t
      ).formState;
      return {
        ...formState,
        touched: state.touched,
      };
    },
    getForm() {
      return {
        ...state.form,
      };
    },
    setValidation(validateResult) {
      return onSetValidation(validateResult);
    },
  }));

  const validateFormState = (_formState) => {
    let invalid = false;
    for (const key in _formState) {
      if (_formState[key].invalid) {
        invalid = true;
      }
    }
    return {
      ..._formState,
      invalid,
    };
  };

  const onChangeModelControl = (control, data) => {
    let _state = { ...state };

    if (data.valueObj) {
      _state.form[control.key + "_obj"] = data.valueObj;
    }
    _state.form[control.key] = data.value;

    _state.formState[control.key] = data.controlState;
    _state.formState = validateFormState(_state.formState);
    // if provided props.touched, no need to update state.touched
    if (props.touched === undefined) {
      _state.touched = true;
    }
    if (props.onChange) {
      props.onChange({
        state: _state,
        changed: {
          control,
          data,
        },
      });
    }
    if (!jsonEqual(_state, state)) {
      setState(_state);
    }
    if (props.onChangeFormState) {
      // if (
      //   state.formState.invalid !== _state.formState.invalid ||
      //   state.touched !== _state.touched
      // ) {
      props.onChangeFormState({
        ..._state.formState,
        touched: _state.touched,
      });
      // }
    }
    return true;
  };

  const onSubmitForm = async () => {
    // Validate 1st
    const _state = { ...state };
    const formState = validateFormState(state.formState);
    if (formState.invalid) {
      _state.formState = formState;
      setState(_state);
      return {
        form: {
          ...state.form,
        },
        formState: {
          ...state.formState,
        },
        touched: state.touched,
      };
    }
    setState({
      ...state,
      submiting: true,
      isSubmited: true,
    });
    await sleep(1);
    let res;

    if (props.config?.formSubmitFn) {
      try {
        res = await props.config?.formSubmitFn({
          form: _state.form,
          formState: _state.formState,
          touched: _state.touched,
        });
      } catch {
        //catch exception from backend
        _state.formState.invalid = true;
      }

      if (res && res.data && !isObjectEmpty(res.data)) {
        _state.formState.invalid = false;
      } else {
        _state.formState.invalid = true;
      }

      if (res && res.message && res.message.alert) {
        // _state.formAlert = res.message.alert; - aw
      }

      if (res && res.messages?.length === 0 && _state.formState.invalid) {
        _state.formAlert = [
          {
            level: "WARN",
            labelCode: "FORM_INVALID",
          },
        ];
      }

      if (res && res.message && res.message.validateResult) {
        res.message.validateResult.forEach((item) => {
          for (const key in _state.formState) {
            if (_state.formState.hasOwnProperty(key)) {
              if (key === item.componentId) {
                const param = {};
                if (item.placeholder) {
                  item.placeholder.forEach((element, placeIndex) => {
                    param["" + placeIndex] = element;
                  });
                }
                _state.formState[key] = {
                  invalid: true,
                  error: t(item.labelCode, param),
                  ruleDetail: {
                    name: t("From backend"),
                    param: null,
                  },
                };
              }
            }
          }
        });
      }
    }

    let isChangeState = false;
    if (props.config?.afterFormSubmitFn) {
      isChangeState = await props.config?.afterFormSubmitFn({
        res,
        form: _state.form,
        formState: _state.formState,
      });
    }

    if (!isChangeState) {
      // _state.touched = false;
      _state.submiting = false;
      if (props.onChange) {
        props.onChange({
          state: _state,
        });
      }
      setState(_state);
    }

    return {
      form: {
        ..._state.form,
      },
      formState: {
        ..._state.formState,
      },
    };
  };
  const onSetValidation = (validateResult) => {
    let _state = { ...state };
    let hasInvalid = false;
    validateResult.forEach((item) => {
      for (const key in _state.formState) {
        if (_state.formState.hasOwnProperty(key)) {
          if (key === item.componentId) {
            const param = {};
            if (item.placeholder) {
              item.placeholder.forEach((element, placeIndex) => {
                param["" + placeIndex] = element;
              });
            }
            hasInvalid = true;
            _state.formState[key] = {
              invalid: true,
              error: t(item.labelCode, param),
              ruleDetail: {
                name: t("From backend"),
                param: null,
              },
            };
          }
        }
      }
    });
    _state.formState.invalid = hasInvalid;
    if (hasInvalid) {
      _state.touched = true;
    } else {
      _state.touched = false;
    }
    setState(_state);
    return hasInvalid;
  };
  const onSubmitFormResult = async (res) => {
    let _state = { ...state };
    if (res && res.message && res.message.validateResult) {
      res.message.validateResult.forEach((item) => {
        for (const key in _state.formState) {
          if (_state.formState.hasOwnProperty(key)) {
            if (key === item.componentId) {
              _state.formState.invalid = true;
              const param = {};
              if (item.placeholder) {
                item.placeholder.forEach((element, placeIndex) => {
                  param["" + placeIndex] = element;
                });
              }
              _state.formState[key] = {
                invalid: true,
                error: t(item.labelCode, param),
                ruleDetail: {
                  name: t("From backend"),
                  param: null,
                },
              };
            }
          }
        }
      });
    }
    setState(_state);
  };
  const renderAction = (action) => {
    switch (action.type) {
      case "submit":
        return (
          <Button
            {...action.config}
            type="submit"
            icon={state.submiting ? "pi pi-spin pi-spinner" : null}
            disabled={
              state.submiting ||
              (state.formState.invalid &&
                (state.touched || state.touched === undefined)) // aw - dsabled button when undefined
            }
            onClick={(e) => onSubmitForm(e)}
            label={action.label}
          ></Button>
        );
      default:
        return (
          <Button
            {...action.config}
            icon={state.loading ? "pi pi-spin pi-spinner" : null}
            disabled={state.loading}
            label={action.label}
          ></Button>
        );
    }
  };
  let updateConfigForm = {
    controls: [
      getControlModel({
        key: "className",
        label: t("base_form_class"),
        placeholder: t("base_form_set_class"),
      }),
      getControlModel({
        key: "style",
        label: t("base_form_css"),
        placeholder: t("base_form_set_css"),
        type: "code",
      }),
    ],
    layout: {
      rows: [
        {
          columns: [
            {
              control: "className",
            },
          ],
        },
        {
          columns: [
            {
              control: "style",
            },
          ],
        },
      ],
    },
  };
  let setItemForm = {
    controls: [
      getControlModel({
        key: "control",
        label: t("base_form_control"),
        placeholder: t("base_form_set_control"),
        type: "select",
        enum: props.config?.controls.map((x) => {
          return {
            label: x.key,
            value: x.key,
          };
        }),
      }),
    ],
    layout: {
      rows: [
        {
          columns: [
            {
              control: "control",
            },
          ],
        },
      ],
    },
  };

  const generateDefaultLayout = () => {
    if (!props.config?.controls?.length) return null;
    let layout = {
      rows: [],
    };
    props.config.controls.forEach((control) => {
      layout.rows.push({ columns: [{ control: control.key }] });
    });
    return layout;
  };

  const buildRows = (rows) => {
    let autoFocus = props.autoFocus;
    let dataLayout = [];
    const controlsConfig = props.config ? [...props.config.controls] : [];

    rows.forEach((row, rowIndex) => {
      const columns = [];
      row.columns.forEach((column, columnIndex) => {
        if (column.rows) {
          columns.push(
            <div
              key={`${id}-${rowIndex}-${columnIndex}-holder`}
              id={`${id}-${rowIndex}-${columnIndex}-holder`}
              className={`bf-sub-row p-col`}
            >
              {buildRows(column.rows)}
            </div>
          );
        } else {
          let controlObj = controlsConfig.find((x) => x.key === column.control);
          const holder = [];
          if (controlObj) {
            let control = { ...controlObj };
            let controlState = state.formState[control.key];
            let value = state.form[column.control];
            if (!control.config) {
              control.config = {};
            }
            if (props.readOnly) {
              control.config.disabled = true;
              control.config.readOnly = true;
              control.viewMode = true;
            }
            holder.push(
              <div
                key={`${id}-${rowIndex}-${columnIndex}-holder`}
                id={`${id}-${rowIndex}-${columnIndex}-holder`}
                className={`bf-control ${
                  !control.config.disabled &&
                  !control.config.readOnly & autoFocus
                    ? "autofocus"
                    : ""
                } ${
                  column?.config?.holderClassName
                    ? column.config.holderClassName + " custom-holder"
                    : ""
                }`}
              >
                <BaseControl
                  id={`${id}-${control.key}`}
                  key={`${id}-${rowIndex}-${columnIndex}-holder-control-${state.touched}`}
                  onChange={(data) => onChangeModelControl(control, data)}
                  {...control}
                  controlState={controlState}
                  value={value}
                  autoFocus={
                    !control.config.disabled &&
                    !control.config.readOnly & autoFocus
                      ? true
                      : false
                  }
                  formSubmited={state.isSubmited}
                  fromFrom={true}
                  loading={props.loading}
                  touched={state.touched || props.touched}
                  onTouched={() => {
                    if (props.onTouched) {
                      props.onTouched();
                      return;
                    }
                    // if provided props.touched, no need to update state.touched
                    if (props.touched === undefined && !state.touched) {
                      setState({
                        ...state,
                        touched: true,
                      });
                    }
                  }}
                />
              </div>
            );
            if (!control.config.disabled && !control.config.readOnly) {
              autoFocus = false;
            }
          } else if (column.component) {
            holder.push(
              <div
                key={`${id}-${rowIndex}-${columnIndex}-holder`}
                id={`${id}-${rowIndex}-${columnIndex}-holder`}
                className={`bf-component`}
              >
                {column.component({
                  isSubmiting: state.submiting,
                  form: {
                    ...state.form,
                  },
                  formState: {
                    ...state.formState,
                  },
                  onSubmitForm,
                  setState,
                  state,
                })}
              </div>
            );
          } else if (column.action) {
            holder.push(
              <div
                key={`${id}-${rowIndex}-${columnIndex}-holder`}
                id={`${id}-${rowIndex}-${columnIndex}-holder`}
                className={`bf-action`}
              >
                {renderAction(column.action)}
              </div>
            );
          }

          let isUndefinedColumnControl = column.control !== "" && !controlObj; //aw
          if (
            //aw
            !isUndefinedColumnControl || //aw
            column.control === undefined || //aw
            column.action !== undefined || //aw
            column.component !== undefined //aw
          ) {
            //aw
            const { holderClassName, ...columnConfig } = column.config
              ? column.config
              : {};
            columns.push(
              <div
                {...columnConfig}
                key={`bf-column-${columnIndex}`}
                className={`bf-column bf-column-${columnIndex} p-col ${
                  column.config ? column.config.className : ""
                }`}
              >
                {props.builder ? (
                  <div className="bfb-panel">
                    <>
                      <Menu
                        ref={(el) =>
                          (refGr.current[`column-${rowIndex}.${columnIndex}`] =
                            el)
                        }
                        model={[
                          {
                            label: t("base_form_set_item"),
                            icon: "pi pi-inbox",
                            command: () => {
                              dispatch(
                                openModal({
                                  title: t("base_form_set_item"),
                                  primaryButtonText: t("base_form_confirm"),
                                  form: {
                                    config: setItemForm,
                                    form: {
                                      control: column.control,
                                    },
                                  },
                                  primaryButtonClickFn: async ({
                                    closeFn,
                                    form,
                                  }) => {
                                    const layoutConfig = state.layoutConfig;
                                    layoutConfig.rows[rowIndex].columns[
                                      columnIndex
                                    ].control = form.control;
                                    setState({
                                      ...state,
                                      layoutConfig,
                                    });
                                    closeFn();
                                  },
                                  secondButtonClickFn: ({ closeFn }) => {
                                    closeFn();
                                  },
                                })
                              );
                            },
                          },
                          {
                            label: t("base_form_config"),
                            icon: "pi pi-cog",
                            command: () => {
                              dispatch(
                                openModal({
                                  title: t("base_form_config_column"),
                                  primaryButtonText: t("base_form_confirm"),
                                  form: {
                                    config: updateConfigForm,
                                    form: {
                                      className: column.config?.className,
                                      style: column.config?.style,
                                    },
                                  },
                                  primaryButtonClickFn: async ({
                                    closeFn,
                                    form,
                                  }) => {
                                    const layoutConfig = state.layoutConfig;
                                    layoutConfig.rows[rowIndex].columns[
                                      columnIndex
                                    ].config = form;
                                    setState({
                                      ...state,
                                      layoutConfig,
                                    });
                                    closeFn();
                                  },
                                  secondButtonClickFn: ({ closeFn }) => {
                                    closeFn();
                                  },
                                })
                              );
                            },
                          },
                          {
                            label: t("base_form_delete"),
                            icon: "pi pi-trash",
                            command: () => {
                              const layoutConfig = state.layoutConfig;
                              layoutConfig.rows[rowIndex].columns.splice(
                                columnIndex,
                                1
                              );
                              setState({
                                ...state,
                                layoutConfig,
                              });
                            },
                          },
                        ]}
                        popup
                        className="p-menu-custom-overlay"
                        appendTo={document.body}
                        easing="ease-in"
                      ></Menu>
                      <Button
                        type="button"
                        className="p-button-sm"
                        icon={`pi pi-ellipsis-v`}
                        label={`${t("base_form_column")}: ${rowIndex + 1}.${
                          columnIndex + 1
                        }`}
                        onClick={(event) => {
                          refGr.current[
                            `column-${rowIndex}.${columnIndex}`
                          ].toggle(event);
                        }}
                      />
                    </>
                  </div>
                ) : null}
                {holder}
              </div>
            );
          } //aw
        }
      });

      if (columns.length > 0) {
        //aw
        dataLayout.push(
          <div
            {...row.config}
            key={`bf-row-${rowIndex}`}
            className={`bf-row bf-row-${rowIndex} p-grid ${
              row.config ? row.config.className : ""
            }`}
          >
            {props.builder ? (
              <div className="bfb-panel">
                <>
                  <Menu
                    ref={(el) => (refGr.current[`row-${rowIndex}`] = el)}
                    model={[
                      {
                        label: t("base_form_add_column"),
                        icon: "pi pi-plus",
                        command: () => {
                          const layoutConfig = state.layoutConfig;
                          layoutConfig.rows[rowIndex].columns.push({});
                          setState({
                            ...state,
                            layoutConfig,
                          });
                        },
                      },
                      {
                        label: t("base_form_config"),
                        icon: "pi pi-cog",
                        command: () => {
                          dispatch(
                            openModal({
                              title: t("base_form_config_row"),
                              primaryButtonText: t("base_form_confirm"),
                              form: {
                                config: updateConfigForm,
                                form: {
                                  className: row.config?.className,
                                  style: row.config?.style,
                                },
                              },
                              primaryButtonClickFn: async ({
                                closeFn,
                                form,
                              }) => {
                                const layoutConfig = state.layoutConfig;
                                layoutConfig.rows[rowIndex].config = form;
                                setState({
                                  ...state,
                                  layoutConfig,
                                });
                                closeFn();
                              },
                              secondButtonClickFn: ({ closeFn }) => {
                                closeFn();
                              },
                            })
                          );
                        },
                      },
                      {
                        label: t("base_form_delete"),
                        icon: "pi pi-trash",
                        command: () => {
                          const layoutConfig = state.layoutConfig;
                          layoutConfig.rows.splice(rowIndex, 1);
                          setState({
                            ...state,
                            layoutConfig,
                          });
                        },
                      },
                    ]}
                    popup
                    className="p-menu-custom-overlay"
                    appendTo={document.body}
                    easing="ease-in"
                  ></Menu>
                  <Button
                    type="button"
                    className="p-button-sm"
                    icon={`pi pi-ellipsis-v`}
                    label={`${t("base_form_row")}: ${rowIndex + 1}`}
                    onClick={(event) => {
                      refGr.current[`row-${rowIndex}`].toggle(event);
                    }}
                  />
                </>
              </div>
            ) : null}
            {columns}
          </div>
        );
      } //aw
    });
    return dataLayout;
  };

  /**
   * https://reactjs.org/docs/forms.html
   * @param {*} e
   */
  function handleSubmit(e) {
    e.preventDefault();
  }

  const buildLayout = () => {
    const layoutConfig = props.config?.layout || generateDefaultLayout();

    if (!layoutConfig || !layoutConfig.rows) {
      return null;
    }
    return (
      <>
        <form
          id={id}
          className={`${props.readOnly ? "bf-main-readonly" : ""} bf-main`}
          onSubmit={handleSubmit}
        >
          {props.builder ? (
            <div className="bfb-panel">
              <>
                <Menu
                  ref={(el) => (refGr.current[`form`] = el)}
                  model={[
                    {
                      label: t("base_form_add_row"),
                      icon: "pi pi-plus",
                      command: () => {
                        layoutConfig.rows.push({
                          columns: [],
                        });
                        setState({
                          ...state,
                          layoutConfig,
                        });
                      },
                    },
                  ]}
                  popup
                  className="p-menu-custom-overlay"
                  appendTo={document.body}
                  easing="ease-in"
                ></Menu>
                <Button
                  type="button"
                  className="p-button-sm"
                  icon={`pi pi-ellipsis-v`}
                  label={`${t("base_form")} `}
                  onClick={(event) => {
                    refGr.current[`form`].toggle(event);
                  }}
                />
              </>
            </div>
          ) : null}
          {buildRows(layoutConfig.rows)}
        </form>
      </>
    );
  };
  const buildAlert = () => {
    return state.formAlert.map((alter) => {
      const param = {};
      if (alter.placeholder) {
        alter.placeholder.forEach((element, placeIndex) => {
          param["" + placeIndex] = element;
        });
      }
      return (
        <>
          <Message
            className="bf-invalid"
            severity={alter.level.toLowerCase()}
            text={t(alter.labelCode, {
              ...param,
            })}
          ></Message>
        </>
      );
    });
  };
  return (
    <>
      <span style={{ display: "none" }}>{JSON.stringify(state)}</span>
      {buildAlert()}
      {buildLayout()}
    </>
  );
});

export default BaseForm;
