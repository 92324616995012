import "./index.scss";
import { useContext, useMemo } from "react";
import { ProgressBar } from "primereact/progressbar";
import { useTranslation } from "react-i18next";
import { useTimesheetDetailContext } from "../../modules/timesheet-detail/context/useTimesheetDetailContext";
import InputControl from "../../../../../components/base-control/input-control/input-control";
import { ApplicationContext } from "../../context";
import { formatTwoDecimals } from "../../utils/format-helper";

export const TimesheetPeriodContainer = () => {
  const { t } = useTranslation();
  const [{ application }] = useContext(ApplicationContext);
  const {
    employeeWorkHrs,
    employeeLeaveHrs,
    isTimesheetOwner,
    periodFrom,
    periodTo,
    totalHrs,
  } = useTimesheetDetailContext();

  const progressVal = useMemo(() => {
    if (totalHrs === 0) {
      return totalHrs;
    }
    const val = Number(totalHrs / employeeWorkHrs) * 100;

    return val;
  }, [totalHrs, employeeWorkHrs]);

  return (
    <div className="timesheet-period-container">
      <div className="row">
        <div className="col">
          <InputControl
            label={t("timesheet_detail_container_time_period")}
            value={`${periodFrom ? periodFrom : ""} - ${
              periodTo ? periodTo : ""
            }`}
            config={{ readOnly: true }}
            className="p-displayOnly"
          />
        </div>
        <div className="col">
          {!isTimesheetOwner && application?.employeeCode && (
            <InputControl
              label={t("timesheet_detail_container_employee_code")}
              value={application.employeeCode}
              config={{ readOnly: true }}
              className="p-displayOnly"
            />
          )}
        </div>
        <div className="col">
          {!isTimesheetOwner && application?.employeeName && (
            <InputControl
              label={t("timesheet_detail_container_employee_name")}
              value={application.employeeName}
              config={{ readOnly: true }}
              className="p-displayOnly"
            />
          )}
        </div>
        <div className="col">
          {!isTimesheetOwner && application?.approverName && (
            <InputControl
              label={t("timesheet_detail_container_approver_name")}
              value={application.approverName}
              config={{ readOnly: true }}
              className="p-displayOnly"
            />
          )}
        </div>
      </div>
      {employeeLeaveHrs > -1 && (
        <div className="row">
          <div className="col">
            <InputControl
              label={t("timesheet_detail_container_total_leave_hours")}
              value={`${formatTwoDecimals(Number(employeeLeaveHrs))} Hour(s)`}
              config={{ readOnly: true }}
              className="p-displayOnly"
            />
          </div>
        </div>
      )}
      <div className="row">
        <div className="col">
          {employeeWorkHrs > -1 && (
            <InputControl
              label={t(
                "timesheet_detail_container_total_standard_working_hours"
              )}
              value={`${formatTwoDecimals(Number(employeeWorkHrs))} Hour(s)`}
              config={{ readOnly: true }}
              className="p-displayOnly"
            />
          )}
        </div>
        <div className="col">
          {employeeWorkHrs > -1 && (
            <InputControl
              label={t("timesheet_list_totalHoursReported")}
              value={`${formatTwoDecimals(totalHrs)} Hour(s)`}
              config={{ readOnly: true }}
              className="p-displayOnly"
            />
          )}
        </div>
      </div>
      <div className="breakdown-image">
        <ProgressBar value={progressVal} showValue={false} />
      </div>
    </div>
  );
};
