import { useTranslation } from "react-i18next";

import "./timesheet-status.scss";
import { TIMESHEET_STATUS } from "../../constants/status";

interface TimesheetStatusProps {
  status: TIMESHEET_STATUS;
  data?: any;
  indicator?: boolean;
  showText?: boolean;
}

export const TimesheetStatus = ({
  status,
  data,
  indicator = true,
  showText = true,
}: TimesheetStatusProps) => {
  const { t } = useTranslation();
  let statusClassName = "";
  const parseIndicatorClassName = (status: string) =>
    `timesheet-status__indicator timesheet-status__indicator--${status}`;
  let text = "";

  if (data?.isPending) {
    statusClassName = "pending-for-approval";
  }
  if (data?.isCancelled) {
    statusClassName = "cancelled";
  }
  if (data?.isApproved) {
    statusClassName = "approved";
  }
  if (data?.isRejected) {
    statusClassName = "rejected";
  }

  switch (status) {
    case TIMESHEET_STATUS.DRAFT:
      statusClassName = "draft";
      text = t("timesheet_status_draft");
      break;
    case TIMESHEET_STATUS.SUBMITTED:
      statusClassName = "submitted";
      text = t("timesheet_status_submitted");
      break;
    case TIMESHEET_STATUS.RETURNED:
    case TIMESHEET_STATUS.REJECTED:
      statusClassName = "returned";
      text = t("timesheet_status_returned");
      break;
    case TIMESHEET_STATUS.APPROVED:
      statusClassName = "approved";
      text = t("timesheet_status_approved");
      break;
    default:
      text = status;
      break;
  }

  return (
    <div className="timesheet-status">
      {indicator && (
        <span className={parseIndicatorClassName(statusClassName)}></span>
      )}
      {showText && <span>{text}</span>}
    </div>
  );
};
