import { TIMESHEET_WORKFLOW_STATUS } from "../../constants";

export const TableFilterStatuses = [
  {
    label: "timesheet_approval_filter_pendingApproval",
    value: TIMESHEET_WORKFLOW_STATUS.IN_PROGRESS,
    isClientSize: true,
  },
  {
    label: "timesheet_approval_filter_approvedApplications",
    value: TIMESHEET_WORKFLOW_STATUS.COMPLETE,
    isClientSize: true,
  },
  {
    label: "timesheet_approval_filter_rejectedApplications",
    value: TIMESHEET_WORKFLOW_STATUS.REJECT,
    isClientSize: true,
  },
  {
    label: "timesheet_approval_filter_all",
    value: -1,
    isClientSize: true,
  },
];
