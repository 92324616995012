import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useCallback, useContext, useEffect, useState } from "react";
import { ApplicationContext } from "../../../context";
import { useHistory } from "react-router-dom";
import { openModal } from "../../../../../../redux/actions/modal";
import { ApplicationAction } from "../../../context/action/application";

export const useLeaveWithoutSaveAlert = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [{ hasEdit }, dispatchApplicationAction] =
    useContext(ApplicationContext);
  const history = useHistory();
  const [triggerExit, setTriggerExit] = useState({
    onOk: false,
    path: "",
  });
  const handleGoToIntendedPage = useCallback(
    (location: string) => history.push(location),
    [history]
  );

  useEffect(() => {
    if (triggerExit.onOk) {
      handleGoToIntendedPage(triggerExit.path);
    }
    const unblock = history.block((location: any) => {
      if (hasEdit) {
        dispatch(
          openModal({
            title: t("timesheet_common_actionConfirmation"),
            content: t("timesheet_common_messageLeaveWithoutSaveConfirmation"),
            classNameMainDialog: "confirm-message-modal",
            primaryButtonText: t("timesheet_common_actionConfirm"),
            primaryButtonClickFn: async ({ closeFn }: any) => {
              setTriggerExit((obj) => ({
                ...obj,
                onOk: true,
              }));
              dispatchApplicationAction &&
                dispatchApplicationAction({
                  type: ApplicationAction.RESET_APPLICATION,
                });

              closeFn();
            },
            secondButtonClickFn: ({ closeFn }: any) => {
              closeFn();
            },
          })
        );
        setTriggerExit((obj) => ({ ...obj, path: location.pathname }));
        if (triggerExit.onOk) {
          return true;
        }
        return false;
      } else {
        return true;
      }
    });

    return () => {
      unblock();
    };
  }, [
    handleGoToIntendedPage,
    history,
    triggerExit.onOk,
    triggerExit.path,
    dispatch,
    hasEdit,
    t,
    dispatchApplicationAction,
  ]);
};
