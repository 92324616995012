import { useSelector } from "react-redux";
import { ISideBarMenu } from "src/redux/reducers/menu";
import { ClaimMenu } from "../claim-menu";

export const HeaderMenu = ({
  menuKey,
  pageKey,
}: {
  menuKey: string;
  pageKey: string;
}) => {
  const sideBarMenus = useSelector(
    (state: any) => state.menu.sideBar as ISideBarMenu[]
  );
  const menus = sideBarMenus.find((menu) => menu.key === menuKey)?.subMenus;

  return <ClaimMenu menu={menus} currentPageKey={pageKey} />;
};
