import LanguageLoader from "../../../../components/language-loader/language-loader";
import "./timesheet-configuration.scss";
import { EEPORTAL_LABEL_BASE_TABLE } from "../../../../constants";
import { Header } from "./component/header";
import { TIMESHEET_CONFIGURATION_MENU_PAGE_KEY } from "./constants";
import { useTimesheetConfigurationContext } from "./context/useTimesheetConfigurationContext";
import { TimesheetConfigurationContextProvider } from "./context/TimesheetConfigurationContextProvider";
import BaseTabScreen, {
  BaseTabScreenSideBarMenu,
} from "../../../../components/base-tab-screen/base-tab-screen";
import { TabContent } from "./tab-content";
import { TimesheetConfigurationFooter } from "./component/footer";
import { Loader } from "@progress/kendo-react-indicators";

const Main = () => {
  const { onTabChange, tabs } = useTimesheetConfigurationContext();

  return (
    <BaseTabScreen
      onChange={onTabChange}
      displayNotFound={false}
      customMenuModules={tabs as unknown as BaseTabScreenSideBarMenu[]}
    >
      <div className="main">
        <TabContent />
      </div>
    </BaseTabScreen>
  );
};

const TimesheetConfiguration = () => {
  const { tabConfig } = useTimesheetConfigurationContext();

  return (
    <>
      <LanguageLoader contexts={[EEPORTAL_LABEL_BASE_TABLE.BASE_TABLE]} />
      <div className="timesheet-configuration-container">
        <Header
          headerTitleKey={
            TIMESHEET_CONFIGURATION_MENU_PAGE_KEY.TIMESHEET_CONFIGURATION
          }
        />

        {tabConfig?.length > 0 && <Main />}

        <TimesheetConfigurationFooter />
      </div>
    </>
  );
};

const TimesheetConfigurationScreen = () => {
  return (
    <TimesheetConfigurationContextProvider>
      <TimesheetConfiguration />
    </TimesheetConfigurationContextProvider>
  );
};

export default TimesheetConfigurationScreen;
