import moment from "moment";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import BaseForm from "src/components/base-form/base-form";
import { showSpinner } from "src/redux/actions/spinner";
import ClaimService from "src/services/hrmnet-api/claims";
import { CLAIM_STORE_ACTION } from "../../constants";
import { ClaimContext } from "../../context/claim-context-provider";
import { getDetailForm } from "../../utils/form-create-helper";
import { FromDateField } from "../../utils/form-field-config";
import { FormSkeleton } from "../skeleton";
import { isResponseOk } from "src/utils/utils";
import { ClaimDefaultTimeModel } from "../../models/claim-default-time-model";

const DetailsForm = ({ formRef, formTouched }: any) => {
  const { t } = useTranslation();
  const { state, dispatch: contextDispatch } = useContext(ClaimContext);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [formControls, setFormControls] = useState<any[]>([]);
  const [formLayout, setFormLayout] = useState<any>({});
  const [changeField, setChangeField] = useState("");

  const { form } = state;
  const [interval, setInterval] = useState(1);
  const [claimTypeInfo, setClaimTypeInfo] = useState<any>({});
  const [calculateFields, setCalculateFields] = useState<any>({
    otUnits: form.otUnits,
    otMealUnits: form.otMealUnits,
    otCompLeaveUnits: form.otCompLeaveUnits,
    otTransportFare: form.otTransportFare,
    otMutualAgreed: form.otMutualAgreed,
    otRequestedBy: form.otRequestedBy,
    compLeaveDisplay: form.compLeaveDisplay,
  });

  useEffect(() => {
    if (form.claimType) {
      setLoading(true);
      (async () => {
        const response = (await ClaimService.getClaimTypeInfoByEmployee(
          form.claimType,
          {
            employeecode: form.employeeCode,
          }
        )) as any;

        setClaimTypeInfo(response?.data);
      })().finally(() => setLoading(false));
    }
  }, [form.claimType, form.employeeCode]);

  const calculate = useCallback(
    async (fromTime) => {
      if (form.fromDate && fromTime && form.toTime) {
        try {
          dispatch(showSpinner(true));
          contextDispatch({
            type: CLAIM_STORE_ACTION.FORM_ACTION_DISABLE,
          });
          const response = (await ClaimService.calculateFields({
            employeeCode: form.employeeCode,
            claimType: form.claimType,
            fromDate: form.fromDate,
            fromTime,
            toDate: form.toDate,
            toTime: form.toTime,
          })) as any;

          contextDispatch({
            type: CLAIM_STORE_ACTION.FORM_UPDATE,
            payload: {
              units: response?.data?.otUnits,
              meal: response?.data?.otMealUnits > 0,
              compLeave: response?.data?.otCompLeaveUnits > 0,
              isDayInLieuPayment: response?.data?.otCompLeaveUnits > 0,
              transport: !response?.data?.otTransportFare
                ? null
                : form.transport,
              ...response?.data,
            },
          });

          contextDispatch({
            type: CLAIM_STORE_ACTION.FORM_ACTION_ENABLE,
          });

          dispatch(showSpinner(false));
          setCalculateFields(response?.data);
        } catch (ex) {
          dispatch(showSpinner(false));
        }
      }
    },
    [
      form.toTime,
      form.employeeCode,
      form.claimType,
      form.fromDate,
      form.toDate,
      form.transport,
      dispatch,
      contextDispatch,
    ]
  );

  const onTimeChange = useCallback(async () => {
    if (changeField === FromDateField.key) {
      return;
    }

    await calculate(form.fromTime);
  }, [form.fromTime, changeField, calculate]);

  const onDateChange = useCallback(async () => {
    if (changeField !== FromDateField.key) {
      return;
    }

    if (form.fromDate) {
      (async () => {
        dispatch(showSpinner(true));
        const response = (await ClaimService.getDefaultStartTime({
          employeeCode: form.employeeCode,
          claimType: form.claimType,
          fromDate: form.fromDate,
        })) as any;

        if (isResponseOk(response) && response?.data) {
          const { defaultInterval, defaultStartTime, dateTypeDisplay } =
            response.data as ClaimDefaultTimeModel;

          setInterval(defaultInterval ?? 1);
          const time = defaultStartTime
            ? moment(defaultStartTime, "hh:mm:ss")
            : null;

          await calculate(time?.toDate());

          contextDispatch({
            type: CLAIM_STORE_ACTION.FORM_UPDATE,
            payload: { fromTime: time?.toDate(), dateTypeDisplay },
          });
        }

        dispatch(showSpinner(false));
      })();
    }
  }, [
    changeField,
    form.fromDate,
    form.employeeCode,
    form.claimType,
    dispatch,
    contextDispatch,
    calculate,
  ]);

  useEffect(() => {
    onDateChange();
  }, [onDateChange]);

  useEffect(() => {
    calculate(form.fromTime);
  }, []);

  useEffect(() => {
    const { controls, layout } = getDetailForm({
      t,
      claimTypeInfo,
      onTimeChange,
      calculateFields,
      fromTime: form.fromTime,
      interval,
    });
    contextDispatch({
      type: CLAIM_STORE_ACTION.FORM_UPDATE,
      payload: claimTypeInfo,
    });
    setFormControls(controls);
    setFormLayout(layout);
  }, [
    claimTypeInfo,
    t,
    contextDispatch,
    calculateFields,
    onTimeChange,
    form.fromTime,
    interval,
  ]);

  const onChange = useCallback(
    (change: any) => {
      setChangeField(
        change.state.touched === false ? "" : change.changed.control.key
      );

      contextDispatch({
        type: CLAIM_STORE_ACTION.FORM_UPDATE,
        payload: change.state.form,
      });
    },
    [contextDispatch]
  );

  const formProps = useMemo(
    () => ({
      ref: formRef,
      config: {
        controls: formControls,
        layout: formLayout,
      },
      form,
      touched: formTouched,
      onChange,
    }),
    [form, formControls, formLayout, formRef, formTouched, onChange]
  );

  if (loading) {
    return <FormSkeleton column={2} />;
  }

  return <BaseForm {...formProps} />;
};

export default DetailsForm;
