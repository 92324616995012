import Moment from "moment";
import { extendMoment } from "moment-range";

const moment = extendMoment(Moment as any);

interface IDate {
  start: Date;
  end: Date;
}

export const isDateRangeOverlap = (date1: IDate, date2: IDate) => {
  const dateRange1 = moment.range(date1.start, date1.end);
  const dateRange2 = moment.range(date2.start, date2.end);

  return dateRange2.overlaps(dateRange1);
};
