export const TIMESHEET_MENU_MODULE_KEY = "eTimesheet";

export enum TIMESHEET_CONTEXT_KEY {
  APPROVAL = "UI.Timesheet.Approval",
  COMMON = "UI.Timesheet.Common",
  DELEGATION = "UI.Timesheet.Delegation",
  FORM = "UI.Timesheet.Form",
  DETAIL = "UI.Timesheet.Detail",
  STATUS = "UI.Timesheet.Status",
  LIST = "UI.Timesheet.List",
  REPORT = "UI.Timesheet.Report",
  SUBMIT_APPLICATION = "UI.Timesheet.SubmitApplication",
  VIEW_APPLICATION = "UI.Timesheet.ViewApplication",
}

export enum TIMESHEET_MENU_TYPE_KEY {
  ACTION = "Action",
  MENU = "Menu",
}

export enum TIMESHEET_MENU_PAGE_KEY {
  TIMESHEET = "eTimesheet_Timesheet",
  TIMESHEET_LIST = "eTimesheet_Timesheet_List",
  APPLICATION = "eTimesheet_Application",
  PERIOD_SELECT = "eTimesheet_Period_Select",
  APPLICATION_SUBMIT = "eTimesheet_Application_Submit",
  APPROVAL_CORNER = "eTimesheet_Approval_Corner",
  AMEND_TIMESHEET = "eTimesheet_Timesheet_Amend",
  APPROVE_TIMESHEET = "eTimesheet_Timesheet_Approval",
  EDIT_TIMESHEET = "eTimesheet_Timesheet_Edit",
  SUBMIT_TIMESHEET = "eTimesheet_Timesheet_Submit",
  VIEW_TIMESHEET = "eTimesheet_Timesheet_View",
  // EDIT_TIMESHEET = "eTimesheet_Timesheet_Edit",
  ENQUIRY_REPORT = "Nwow_Report",
  ENQUIRY_OR_REPORT = "eTimesheet_Report",
  DELEGATION = "eTimesheet_Delegation",
}

export enum TIMESHEET_MENU_ACTION_KEY {
  SUBMIT_TIMESHEET = "eTimesheet_Timesheet_Application",
  EDIT_TIMESHEET = "eTimesheet_Timesheet_Edit",
  APPROVE_TIMESHEET = "eTimesheet_Timesheet_Approval",
  MODIFY_TIMESHEET = "eTimesheet_Timesheet_Modify",
  TIMESHEET_MY_APPLICATIONS = "Timesheet_List_Own_List",
  TIMESHEET_DELEGATED_APPLICATIONS = "Timesheet_List_Delegated_List",
  TIMESHEET_HR_APPLICATIONS = "Timesheet_List_HR_List",
  EDIT_TIMESHEET_ACTIVITY = "eTimesheet_Timesheet_Edit_Activity",
  ADD_TIMESHEET_ACTIVITY = "eTimesheet_Timesheet_Add_Activity",
}
