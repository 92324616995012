import { Provider, PropsWithChildren } from "react";

// helper for creating multiple context by reduce
interface IProps<T> {
  contextProviderAndStates: Array<{
    state: T;
    Provider: Provider<T>;
  }>;
}

export const ContextUnifier = <T,>({
  contextProviderAndStates,
  children,
}: PropsWithChildren<IProps<T>>) => (
  <>
    {contextProviderAndStates.reduce(
      (acc, { Provider, state }) => (
        <Provider value={state}>{acc}</Provider>
      ),
      children
    )}
  </>
);

// ProviderProps
