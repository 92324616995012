import { useTranslation } from "react-i18next";
import {
  TIMESHEET_CONTEXT_KEY,
  TIMESHEET_MENU_PAGE_KEY,
} from "../../constants";

import "./list.scss";
import LanguageLoader from "../../../../../components/language-loader/language-loader";
import { EEPORTAL_LABEL_BASE_TABLE } from "../../../../../constants";
import { useEffect, useState } from "react";
import BaseTabScreen, {
  BaseTabScreenSideBarMenu,
} from "../../../../../components/base-tab-screen/base-tab-screen";
import {
  DelegatedApplications,
  HRApplications,
  MyApplications,
  tabItem,
} from "./tab-config";
import { ToolBar } from "./table-tool-bar";
import { Table } from "./table";
import { Header } from "../../components/header";
import TimesheetService from "../../../../../services/hrmnet-api/timesheet";
import {
  IAxiosResponse,
  IApplicationTabsData,
} from "../../constants/query-response";
import { SelectControlOption } from "../../../../../components/base-control/select-control/select-control";

const Main = ({ tabConfig }: { tabConfig: tabItem[] }) => {
  const { t } = useTranslation();

  const tabs = tabConfig.map((tab: tabItem, index: number) => ({
    ...tab,
    value: index,
    name: t(tab.name),
    description: t(tab.description),
  }));

  const [filteredYear, setFilteredYear] =
    useState<SelectControlOption["value"]>("");
  const [filterYears, setFilterYears] = useState<SelectControlOption[]>([
    { label: "", value: "" },
  ]);
  const [selectedTabKey, setSelectedTabKey] = useState(tabs[0].key);

  useEffect(() => {
    (async () => {
      const response = await TimesheetService.getYearlyPeriods();
      setFilteredYear(
        response.data?.find((item: any) => !!item)?.value.toString() ||
          filteredYear
      );
      setFilterYears(
        response.data?.map((item: any) => ({
          label: item.name,
          value: item.value,
        }))
      );
    })();
    window.history.replaceState({}, document.title);
  }, []);

  return (
    <BaseTabScreen
      onChange={(value) => {
        const selectedTab = tabs.find((tab: any) => tab.value === value);
        if (!!selectedTab) {
          setSelectedTabKey(selectedTab.key);
        }
      }}
      displayNotFound={false}
      menuKey={"TIMESHEET_MENU_PAGE_KEY"}
      customMenuModules={tabs as unknown as BaseTabScreenSideBarMenu[]}
    >
      <div className="main">
        <Table
          key={selectedTabKey}
          selectedTabKey={selectedTabKey}
          year={filteredYear}
          toolbar={
            <ToolBar
              filterYears={filterYears}
              filteredYear={filteredYear}
              setFilteredYear={setFilteredYear}
              t={t}
            />
          }
        />
      </div>
    </BaseTabScreen>
  );
};

const TimesheetApplicationList = () => {
  const [tabConfig, setTabConfig] = useState<tabItem[]>([]);

  useEffect(() => {
    (async () => {
      const response: IAxiosResponse<IApplicationTabsData> =
        await TimesheetService.getApplicationsTabs();
      if (response?.data) {
        const { delegation, hrView } = response.data;

        setTabConfig([
          MyApplications,
          ...(delegation ? [DelegatedApplications] : []),
          ...(hrView ? [HRApplications] : []),
        ]);
      }
    })();
  }, []);

  return (
    <>
      <LanguageLoader
        contexts={[
          EEPORTAL_LABEL_BASE_TABLE.BASE_TABLE,
          TIMESHEET_CONTEXT_KEY.COMMON,
          TIMESHEET_CONTEXT_KEY.LIST,
        ]}
      />
      <div className="timesheet-container timesheet-application-list">
        <Header
          headerTitleKey={TIMESHEET_MENU_PAGE_KEY.TIMESHEET_LIST}
          headerDescKey={"eTimesheet_Timesheet_List_Description"}
          WithHeaderDropdown
        />

        {tabConfig?.length > 0 && <Main tabConfig={tabConfig} />}
      </div>
    </>
  );
};

export default TimesheetApplicationList;
