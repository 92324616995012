import { getColumModel } from "../../../../../components/base-table/base-table-model";
import { TimesheetStatus } from "../../components/timesheet-status";
import {
  TIMESHEET_MENU_ACTION_KEY,
  SHORT_DATE_FORMAT,
  LONG_DATE_FORMAT,
} from "../../constants";
import { getDateFromString } from "../../utils/date-helper";

const employeeDisplayNameColumn = getColumModel({
  header: "timesheet_list_employeeName",
  key: "employeeName",
  dataType: "string",
  sortable: true,
  hideMobile: false,
});

const employeeCodeColumn = getColumModel({
  header: "timesheet_list_employeeCode",
  key: "employeeCode",
  dataType: "string",
  sortable: true,
  hideMobile: true,
});

const referenceNoColumn = getColumModel({
  header: "timesheet_list_ref_no",
  key: "referenceNo",
  dataType: "string",
  sortable: true,
  width: 12,
  hideMobile: false,
});

const fromColumn = getColumModel({
  header: "timesheet_list_from",
  key: "from",
  dataType: "string",
  sortable: true,
  width: 9,
  hideMobile: true,
  render: (data) => {
    const time = getDateFromString(data.from, SHORT_DATE_FORMAT);
    return <span>{time}</span>;
  },
});

const toColumn = getColumModel({
  header: "timesheet_list_to",
  key: "to",
  dataType: "string",
  sortable: true,
  width: 9,
  hideMobile: true,
  render: (data) => {
    const time = getDateFromString(data.to, SHORT_DATE_FORMAT);
    return <span>{time}</span>;
  },
});

const totalHoursColumn = getColumModel({
  header: "timesheet_list_totalHoursReported",
  key: "totalHoursDesc",
  dataType: "string",
  sortable: false,
  hideMobile: false,
});

const totalLeaveHoursColumn = getColumModel({
  header: "timesheet_list_totalLeaveHoursReported",
  key: "totalLeaveHoursDesc",
  dataType: "string",
  sortable: false,
  hideMobile: false,
});

const statusColumn = getColumModel({
  header: "timesheet_list_status",
  key: "status",
  dataType: "string",
  sortable: true,
  width: 12,
  hideMobile: true,
  render: (data) => <TimesheetStatus status={data.trnsStatus} />,
});

const lastUpdatedColumn = getColumModel({
  header: "timesheet_list_last_updated",
  key: "lastUpdated",
  dataType: "string",
  sortable: true,
  width: 9,
  hideMobile: true,
  render: (data) => {
    const time = getDateFromString(data.lastUpdated, LONG_DATE_FORMAT);
    return <span>{time}</span>;
  },
});

export const MyListColumns = [
  referenceNoColumn,
  fromColumn,
  toColumn,
  totalHoursColumn,
  totalLeaveHoursColumn,
  statusColumn,
  lastUpdatedColumn,
];

const OtherListColumns = [
  employeeDisplayNameColumn,
  employeeCodeColumn,
  referenceNoColumn,
  fromColumn,
  toColumn,
  totalHoursColumn,
  totalLeaveHoursColumn,
  statusColumn,
  lastUpdatedColumn,
];

export const getColumns = (key: string) => {
  switch (key) {
    case TIMESHEET_MENU_ACTION_KEY.TIMESHEET_DELEGATED_APPLICATIONS:
    case TIMESHEET_MENU_ACTION_KEY.TIMESHEET_HR_APPLICATIONS:
      return OtherListColumns;

    default:
      return MyListColumns;
  }
};
