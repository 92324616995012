import { TFunction } from "react-i18next";
import { ITab, ITabItem } from "../types/types";

export const parseTabConfigIntoTabs = ({
  tabConfig,
  t,
}: {
  tabConfig: ITabItem[];
  t: TFunction<"translation">;
}): ITab => {
  return tabConfig.map((tab: ITabItem, index: number) => ({
    ...tab,
    value: index,
    name: t(tab.name),
    description: t(tab.description),
  }));
};
