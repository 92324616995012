import { DATE_TYPE, IDateTypeConfig } from "./type";

export const SHORT_DATE_FORMAT = "YYYY-MM-DD";
export const LONG_DATE_FORMAT = "YYYY-MM-DD HH:mm";
export const SHORT_DATE_WITH_WEEKDAY_FORMAT = "YYYY-MM-DD (ddd)";
export const TIME_FORMAT = "HH:mm";

export const TIMESHEET_WORKFLOW_STATUS = {
  IN_PROGRESS: 0,
  COMPLETE: 1,
  REJECT: 2,
  SUSPEND: 3,

  // Special
  DRAFT: 6,
  DELETED: 8,
  ADMIN_ENTRY_CREATED: 9,
  LEAVE_STATUS_AMENDED: 10,
};

export const DELEGATE_TYPE = {
  "Approve Timesheet": 1,
  "Submit Timesheet": 2,
};

/**
 * interface DateConfigItem
 * showMark: boolean;
 * disabled: boolean;
 * isRedDay: boolean;
 */
export const DATE_TYPE_CONFIG: IDateTypeConfig = {
  [DATE_TYPE.WORKING]: {
    disabled: false,
    isRedDay: false,
    color: "#ff0000",
  },
  [DATE_TYPE.REST]: {
    disabled: false,
    isRedDay: true,
    color: "#2d7829",
  },
  [DATE_TYPE.NON_PAID_REST]: {
    disabled: true,
    isRedDay: false,
    color: "#fece2f",
  },
  [DATE_TYPE.HOLIDAY]: {
    disabled: false,
    isRedDay: true,
    color: "#0000ff",
  },
  [DATE_TYPE.STATUTORY]: {
    disabled: false,
    isRedDay: true,
    color: "#ff00ff",
  },
};
