import moment from "moment";

export const GetDateFromString = (
  value?: string,
  format?: string,
  toUpper = true
) => {
  if (!value) {
    return value;
  }

  const date = moment(value);

  if (!date.isValid()) {
    return value;
  }

  if (format) {
    const formatDate = date.format(format);

    return toUpper ? formatDate.toUpperCase() : formatDate;
  }

  return date;
};

export const GetMonthRange = (input?: string) => {
  const date = moment(input);

  const year = date.year();
  const month = date.month();

  return {
    startDate: moment(new Date(year, month, 1)),
    endDate: moment(new Date(year, month + 1, 0)),
  };
};

export const ConcatDateTime = (date: Date, time: Date) => {
  const hour = moment(time).hour();
  const minute = moment(time).minute();
  return moment(date).startOf("day").add(hour, "hour").add(minute, "minute");
};
