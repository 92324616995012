import { FUNCTION_CODE, TIMESHEET_SUBMODULE_CODE } from "../../../../constants";

export const timesheetBasePath = `${FUNCTION_CODE.Timesheet}`;

export const timesheetFunctionRoute = {
  list: ``,
  listView: `/:timesheetId/${TIMESHEET_SUBMODULE_CODE.VIEW}`,
  listEdit: `/:timesheetId/${TIMESHEET_SUBMODULE_CODE.EDIT}`,
  listAmend: `/:timesheetId/${TIMESHEET_SUBMODULE_CODE.AMEND}`,
  application: `/${TIMESHEET_SUBMODULE_CODE.APPLICATION}`,
  applicationSubmit: `/${TIMESHEET_SUBMODULE_CODE.APPLICATION}/${TIMESHEET_SUBMODULE_CODE.SUBMIT}`,
  approvalCorner: `/${TIMESHEET_SUBMODULE_CODE.APPROVAL_CORNER}`,
  approvalCornerView: `/${TIMESHEET_SUBMODULE_CODE.APPROVAL_CORNER}/:timesheetId/${TIMESHEET_SUBMODULE_CODE.VIEW}`,
  approvalCornerApproval: `/${TIMESHEET_SUBMODULE_CODE.APPROVAL_CORNER}/:timesheetId/${TIMESHEET_SUBMODULE_CODE.APPROVAL}`,
  delegation: `/${TIMESHEET_SUBMODULE_CODE.DELEGATION}`,
  report: `/${TIMESHEET_SUBMODULE_CODE.REPORT}`,
};

export const timesheetRoute = {
  list: `/${timesheetBasePath}`,
  listView: `/${timesheetBasePath}${timesheetFunctionRoute.listView}`,
  listEdit: `/${timesheetBasePath}${timesheetFunctionRoute.listEdit}`,
  listAmend: `/${timesheetBasePath}${timesheetFunctionRoute.listAmend}`,
  application: `/${timesheetBasePath}${timesheetFunctionRoute.application}`,
  applicationSubmit: `/${timesheetBasePath}${timesheetFunctionRoute.applicationSubmit}`,
  approvalCorner: `/${timesheetBasePath}${timesheetFunctionRoute.approvalCorner}`,
  approvalCornerView: `/${timesheetBasePath}${timesheetFunctionRoute.approvalCornerView}`,
  approvalCornerApproval: `/${timesheetBasePath}${timesheetFunctionRoute.approvalCornerApproval}`,
  delegation: `/${timesheetBasePath}${timesheetFunctionRoute.delegation}`,
  report: `/${timesheetBasePath}${timesheetFunctionRoute.report}`,
};

export const timesheetApplicationPath = `${timesheetBasePath}/${TIMESHEET_SUBMODULE_CODE.APPLICATION}`;

/* 
eTimesheet_Timesheet_List /timesheet
eTimesheet_Timesheet_List_View /timesheet/{id}/view
eTimesheet_Timesheet_List_Edit /timesheet/{id}/edit
eTimesheet_Timesheet_List_Amend /timesheet/{id}/amend

eTimesheet_Application /timesheet/application
eTimesheet_Application_Submit /timesheet/application/submit

eTimesheet_Approval_Corner /timesheet/approval-corner
eTimesheet_Approval_Corner_View /timesheet/approval-corner/{id}/view
eTimesheet_Approval_Corner_Approval /timesheet/approval-corner/{id}/approval
 */
