import { useContext } from "react";
import { Footer } from "../../components";
import { ApplicationContext } from "../../context";
import { useTimesheetDetailContext } from "./context/useTimesheetDetailContext";

export const TimesheetDetailFooter = () => {
  const {
    isPrimaryButtonDisabled,
    isSecondaryButtonDisabled,
    isHideSecondaryButton,
    onBackButtonClick,
    onPrimaryButtonClick,
    onSecondaryButtonClick,
    primaryButtonLabel,
    secondaryButtonLabel,
  } = useTimesheetDetailContext();
  const [{ application }] = useContext(ApplicationContext);

  return (
    <Footer
      form={application}
      primaryButton={{
        label: primaryButtonLabel,
        onClick: onPrimaryButtonClick,
        disabled: isPrimaryButtonDisabled,
      }}
      secondaryButton={
        isHideSecondaryButton
          ? undefined
          : {
              label: secondaryButtonLabel,
              onClick: onSecondaryButtonClick,
              disabled: isSecondaryButtonDisabled,
            }
      }
      backButton={{
        label: "timesheet_common_actionBack",
        onClick: onBackButtonClick,
      }}
    />
  );
};
