import {
  IGetCalendarConfigInterval,
  IGetCalendarConfigIntervalData,
  IGetCalendarConfigMaintenance,
  IGetCalendarConfigMaintenanceData,
  IGetCostCenterList,
  IGetCostCenterListData,
  IGetEmployeeCostCenter,
  IGetEmployeeCostCenterData,
  IGetEmployeeList,
  IGetEmployeeListData,
  IGetPmActivitiesDetail,
  IGetPmActivitiesDetailData,
  IGetPmProjects,
  IGetPmProjectsData,
  IUpdateCalendarConfig,
  IUpdateCostCenterConfig,
  IUpdatePmActivityConfig,
} from "../../../portal/modules/admin/timesheet-configuration/constants";
import { IRequestConfig, axios, getConfigs } from "../serviceOptions";

const basePath = "/admin/timesheet";

const getCalendarConfigMaintenance: IGetCalendarConfigMaintenance = async (
  props
) => {
  const applicationsUrl = `${basePath}/calendar`;

  const configs: IRequestConfig = getConfigs(
    "get",
    "application/json",
    applicationsUrl,
    props?.options || {}
  );
  configs.params = props?.params;

  return await axiosAsync<IGetCalendarConfigMaintenanceData>(configs);
};

const getCalendarConfigInterval: IGetCalendarConfigInterval = async (props) => {
  const applicationsUrl = `${basePath}/calendar/interval`;

  const configs: IRequestConfig = getConfigs(
    "get",
    "application/json",
    applicationsUrl,
    props?.options || {}
  );
  configs.params = props?.params;

  return await axiosAsync<IGetCalendarConfigIntervalData>(configs);
};

const getCostCenterList: IGetCostCenterList = async (props) => {
  const applicationsUrl = `${basePath}/cost-center-list`;

  const configs: IRequestConfig = getConfigs(
    "get",
    "application/json",
    applicationsUrl,
    props?.options || {}
  );
  configs.params = props?.params;

  return await axiosAsync<IGetCostCenterListData>(configs);
};

const getEmployeeCostCenter: IGetEmployeeCostCenter = async (props) => {
  const applicationsUrl = `${basePath}/cost-center`;

  const configs: IRequestConfig = getConfigs(
    "get",
    "application/json",
    applicationsUrl,
    props?.options || {}
  );
  configs.params = props?.params;

  return await axiosAsync<IGetEmployeeCostCenterData>(configs);
};

const getEmployeeList: IGetEmployeeList = async (props) => {
  const applicationsUrl = `/employee/list/active`;

  const configs: IRequestConfig = getConfigs(
    "get",
    "application/json",
    applicationsUrl,
    props?.options || {}
  );
  configs.params = props?.params;

  return (await axiosAsync(configs)) as any;
};

const getPmActivitiesDetail: IGetPmActivitiesDetail = async (props) => {
  const applicationsUrl = `${basePath}/activities/detail`;

  const configs: IRequestConfig = getConfigs(
    "get",
    "application/json",
    applicationsUrl,
    props?.options || {}
  );
  configs.params = props?.params;

  return await axiosAsync<IGetPmActivitiesDetailData>(configs);
};

const getPmProjects: IGetPmProjects = async (props) => {
  const applicationsUrl = `${basePath}/projects`;

  const configs: IRequestConfig = getConfigs(
    "get",
    "application/json",
    applicationsUrl,
    props?.options || {}
  );
  configs.params = props?.params;

  return await axiosAsync<IGetPmProjectsData>(configs);
};

const updateCalendarConfig: IUpdateCalendarConfig = async (props: any) => {
  const applicationsUrl = `${basePath}/calendar`;

  const configs: IRequestConfig = getConfigs(
    "put",
    "application/json",
    applicationsUrl,
    props?.options || {}
  );

  configs.data = props.data;

  return await axiosAsync(configs);
};

const updateCostCenterConfig: IUpdateCostCenterConfig = async (props: any) => {
  const applicationsUrl = `${basePath}/cost-center`;

  const configs: IRequestConfig = getConfigs(
    "put",
    "application/json",
    applicationsUrl,
    props?.options || {}
  );

  configs.data = props.data;

  return await axiosAsync(configs);
};

const updatePmActivitiesDetail: IUpdatePmActivityConfig = async (
  props: any
) => {
  const applicationsUrl = `${basePath}/activities/detail`;

  const configs: IRequestConfig = getConfigs(
    "put",
    "application/json",
    applicationsUrl,
    props?.options || {}
  );

  configs.data = props.data;

  return await axiosAsync(configs);
};

const axiosAsync = async <T>(config: IRequestConfig): Promise<{ data: T }> => {
  console.log("axiosAsync configs", config);
  const promise = new Promise<{ data: T }>((resolve, reject) =>
    axios(config, resolve, reject)
  );

  return await promise;
};

const AdminTimesheetService = {
  getCalendarConfigInterval,
  getCalendarConfigMaintenance,
  getCostCenterList,
  getEmployeeCostCenter,
  getEmployeeList,
  getPmActivitiesDetail,
  getPmProjects,
  updateCalendarConfig,
  updateCostCenterConfig,
  updatePmActivitiesDetail,
};

export default AdminTimesheetService;
