import "./timesheet.scss";

import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import PageNotFound from "../public/error/employee-error-404";
import TimesheetApplicationList from "./modules/list";
import { resetBcDynamicPaths } from "../../../redux/actions/breadcrumb";
import PeriodSelection from "./modules/period-select";
import TimesheetDetail from "./modules/timesheet-detail";
import TimesheetApprovalList from "./modules/approval";
import TimesheetReport from "./modules/report";
import { ContextUnifier } from "./utils/context-helper";
import { TimesheetContext } from "./context/interface";
import { useContextProviderAndState } from "./context";
import Delegation from "./modules/delegation";
import { timesheetFunctionRoute } from "./constants";

const Timesheet = (props: any) => {
  let match = useRouteMatch();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetBcDynamicPaths());
  }, [dispatch]);

  const routes = [
    {
      id: "list",
      path: `${match.path}${timesheetFunctionRoute.list}`,
      component: TimesheetApplicationList,
      exact: true,
      strict: true,
    },
    {
      id: "listView",
      path: `${match.path}${timesheetFunctionRoute.listView}`,
      component: TimesheetDetail,
      exact: true,
      strict: true,
    },
    {
      id: "listEdit",
      path: `${match.path}${timesheetFunctionRoute.listEdit}`,
      component: TimesheetDetail,
      exact: true,
      strict: true,
    },
    {
      id: "listAmend",
      path: `${match.path}${timesheetFunctionRoute.listAmend}`,
      component: TimesheetDetail,
      exact: true,
      strict: true,
    },
    {
      id: "application",
      path: `${match.path}${timesheetFunctionRoute.application}`,
      component: PeriodSelection,
      exact: true,
      strict: true,
    },
    {
      id: "applicationSubmit",
      path: `${match.path}${timesheetFunctionRoute.applicationSubmit}`,
      component: TimesheetDetail,
      exact: true,
      strict: true,
    },
    {
      id: "approvalCorner",
      path: `${match.path}${timesheetFunctionRoute.approvalCorner}`,
      component: TimesheetApprovalList,
      exact: true,
      strict: true,
    },
    {
      id: "approvalCornerView",
      path: `${match.path}${timesheetFunctionRoute.approvalCornerView}`,
      component: TimesheetDetail,
      exact: true,
      strict: true,
    },
    {
      id: "approvalCornerApproval",
      path: `${match.path}${timesheetFunctionRoute.approvalCornerApproval}`,
      component: TimesheetDetail,
      exact: true,
      strict: true,
    },
    {
      id: "delegation",
      path: `${match.path}${timesheetFunctionRoute.delegation}`,
      component: Delegation,
      exact: true,
      strict: true,
    },
    {
      id: "report",
      path: `${match.path}${timesheetFunctionRoute.report}`,
      component: TimesheetReport,
      exact: true,
      strict: true,
    },
  ];
  return (
    <ContextUnifier<TimesheetContext>
      contextProviderAndStates={useContextProviderAndState()}
    >
      <Switch>
        {props.routeConfig ? props.routeConfig(routes) : null}
        <Route component={PageNotFound} />
      </Switch>
    </ContextUnifier>
  );
};

export default Timesheet;
