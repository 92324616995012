export enum DATE_TYPE {
  "WORKING" = "Y",
  "REST" = "P",
  "NON_PAID_REST" = "N",
  "STATUTORY" = "S",
  "HOLIDAY" = "H",
}

export enum TIMESHEET_DETAIL_TYPE {
  "AMEND" = "amend",
  "APPROVAL" = "approval",
  "EDIT" = "edit",
  "HR_AMEND" = "hrAmend",
  "SUBMIT" = "submit",
  "VIEW" = "view",
}

export enum TIMESHEET_DETAIL_BUTTON_TYPE {
  "AMEND" = "amend",
  "DRAFT" = "draft",
  "SUBMIT" = "submit",
  "EDIT_DRAFT" = "editDraft",
  "SUBMIT_DRAFT" = "submitDraft",
  "APPROVE" = "approve",
  "RETURN" = "return",
  "HR_AMEND" = "hrAmend",
}

export interface IDateTypeItemConfig {
  disabled: boolean;
  isRedDay: boolean;
  showMark?: boolean;
  color: string;
  holidayName?: string;
}

export type IDateTypeConfig = { [key in DATE_TYPE]: IDateTypeItemConfig };

export interface IStoreUser {
  profile: {
    email: string;
    emailId: string;
    employeeCode: string;
    externalJobTitle: string;
    mobileNo: string;
    name: string;
    profileImageUrl: string;
    reportTo: string;
    staffId: string;
    staffType: string;
    status: string;
  };
}
