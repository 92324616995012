import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import BaseForm from "src/components/base-form/base-form";
import { CLAIM_STORE_ACTION } from "../../constants";
import { ClaimContext } from "../../context/claim-context-provider";
import {
  getHeaderFormControls,
  getHeaderFormLayout,
} from "../../utils/form-create-helper";

const HeaderForm = ({ claimTypes, formRef, formTouched }: any) => {
  const { t } = useTranslation();
  const { state, dispatch } = useContext(ClaimContext);
  const [formControls, setFormControls] = useState<any[]>([]);
  const [formLayout, setFormLayout] = useState<any>({});

  const { form } = state;

  useEffect(() => {
    const controls = getHeaderFormControls({
      t,
      claimTypes,
    });
    const layout = getHeaderFormLayout();

    setFormControls(controls);
    setFormLayout(layout);
  }, [claimTypes, t]);

  const onChange = useCallback(
    (change: any) => {
      dispatch({
        type: CLAIM_STORE_ACTION.FORM_UPDATE,
        payload: change.state.form,
      });
      dispatch({
        type: CLAIM_STORE_ACTION.FORM_EDIT,
      });
    },
    [dispatch]
  );

  const formProps = useMemo(
    () => ({
      ref: formRef,
      config: {
        controls: formControls,
        layout: formLayout,
      },
      form,
      touched: formTouched,
      onChange,
    }),
    [form, formControls, formLayout, formRef, formTouched, onChange]
  );

  return <BaseForm {...formProps} />;
};

export default HeaderForm;
