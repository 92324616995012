import { Button } from "primereact/button";
import { BCType } from "../../../../../../components/base-control/base-control";
import { IBaseFormControlProps } from "../../../constants";
import { formatTwoDecimals } from "../../../utils/format-helper";
import { Checkbox } from "primereact/checkbox";

export const SelectionPeriodField: IBaseFormControlProps = {
  key: "period",
  label: "timesheet_form_period_display",
  type: BCType.input,
  placeholder: "timesheet_form_period_placeholder",
  config: {
    readOnly: true,
  },
  componentRender: (props) => (
    <div className="period-display">{props.value}</div>
  ),
};

export const TotalPeriodsHoursField: IBaseFormControlProps = {
  key: "totalPeriodsHours",
  label: "timesheet_form_total_periods_hours",
  type: BCType.input,
  placeholder: "timesheet_form_total_periods_hours_placeholder",
  config: {
    readOnly: true,
  },
  componentRender: (props) => (
    <div className="total-period-hours-display">{`${formatTwoDecimals(
      Number(props.value)
    )} Hour(s)`}</div>
  ),
};

export const ActivityIdField: IBaseFormControlProps = {
  key: "activityId",
  label: "timesheet_form_activityId",
  type: BCType.select,
  required: true,
  hasFilterEnum: true,
  placeholder: "timesheet_form_activityId_placeholder",
};

export const DateField: IBaseFormControlProps = {
  key: "date",
  label: "timesheet_form_date",
  type: BCType.date2,
  required: true,
  dateFormat: "yy-mm-dd",
  placeholder: "timesheet_form_date_placeholder",
  fromFilter: true,
  config: {
    monthNavigator: false,
    yearNavigator: false,
  },
};

export const FromTimeField: IBaseFormControlProps = {
  key: "fromTime",
  label: "timesheet_form_fromTime",
  type: BCType.date2,
  includeTimeZone: true,
  required: true,
  fromFilter: true,
  config: {
    tooltip: null,
    timeOnly: true,
    showIcon: false,
    hourFormat: "24",
  },
};

export const ToDateField: IBaseFormControlProps = {
  key: "toDate",
  label: "timesheet_form_toDate",
  type: BCType.date2,
  required: true,
  dateFormat: "yy-mm-dd",
  placeholder: "timesheet_form_toDate_placeholder",
  fromFilter: true,
  config: {
    monthNavigator: false,
    yearNavigator: false,
  },
};

export const ToTimeField: IBaseFormControlProps = {
  key: "toTime",
  label: "timesheet_form_toTime",
  type: BCType.date2,
  includeTimeZone: true,
  required: true,
  fromFilter: true,
  config: {
    tooltip: null,
    timeOnly: true,
    showIcon: false,
    hourFormat: "24",
  },
};

export const ExcludeMealTimeField: IBaseFormControlProps = {
  key: "excludeMealTime",
  label: "timesheet_form_excludeMealTime",
  componentRender: (props) => (
    <div>
      <Checkbox onChange={props.onClick} checked={props.value} />
    </div>
  ),
};

export const DeleteActivityButton: IBaseFormControlProps = {
  key: "deleteActivity",
  label: "",
  componentRender: (props) => (
    <Button
      icon="pi pi-trash"
      onClick={props.onClick}
      disabled={props.disabled}
      type="button"
    />
  ),
};

export const RemarksField = {
  key: "remarks",
  label: "timesheet_form_remarks",
  type: BCType.textarea2,
  config: {
    autoResize: true,
    rows: 1,
    tooltip: null,
  },
  placeholder: "timesheet_form_remarks_placeholder",
  maxLength: 150,
  hintBottom: "text_count",
};

export const ApplyMultipleField = {
  key: "applyMultiple",
  label: "timesheet_form_applyMultiple",
  type: BCType.checkbox,
  required: false,
};
