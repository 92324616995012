import moment from "moment";
import { BCType } from "../../../../../components/base-control/base-control";
import { convertApiDateFormatToPrime } from "../../../../../utils/utils";
import { ToUIOption } from "../../../public/login/components/utils";
import TimesheetService from "../../../../../services/hrmnet-api/timesheet";
import { IStoreUser } from "../../constants/type";

export const DATE_FORMAT = "YYYY-MM-DD";

export const TIMESHEET_REPORT_TYPE = {
  TIMESHEET_ACTIVITY_REPORT: "TIMESHEET_ACTIVITY",
  ALSTOM_UPLOAD_REPORT: "ALSTOM_UPLOAD",
  TIMESHEET_SUMMARY_REPORT: "TIMESHEET_SUMMARY",
};

export const TIMESHEET_REPORT_KEY = {
  TIMESHEET_ACTIVITY_REPORT: "eTimesheet_Activity_Report",
  TIMESHEET_UPLOAD_REPORT: "eTimesheet_Alstom_Upload_Report",
  TIMESHEET_SUMMARY_REPORT: "eTimesheet_Summary_Report",
};

// Filter config
const employeeCodeFilter = ({ employeeCodeFilterOption, t }: any) => ({
  key: "employeeCode",
  type: BCType.multiselect,
  label: t("timesheet_report_filter_employeeCode"),
  enum: employeeCodeFilterOption,
  placeholder: t("base_control_select_choose"),
  config: { filter: true },
});

const reportingLineFilter = ({ reportingLineFilterOption, t }: any) => ({
  key: "reportingLine",
  type: BCType.select,
  label: t("timesheet_report_filter_reportingLine"),
  enum: reportingLineFilterOption,
  placeholder: t("base_control_select_choose"),
});

const timesheetDateFromFilter = ({ dateFormat = "yyyy-MM-dd", t }: any) => ({
  key: "timesheetDateFrom",
  type: BCType.date,
  label: t("timesheet_report_filter_timesheetDateFrom"),
  dateFormat: convertApiDateFormatToPrime(dateFormat),
  placeholder: dateFormat?.toUpperCase(),
});

const timesheetDateToFilter = ({ dateFormat = "yyyy-MM-dd", t }: any) => ({
  key: "timesheetDateTo",
  type: BCType.date,
  label: t("timesheet_report_filter_timesheetDateTo"),
  dateFormat: convertApiDateFormatToPrime(dateFormat),
  placeholder: dateFormat?.toUpperCase(),
});

const timesheetDateFromMonthFilter = ({ dateFormat = "yyyy-MM", t }: any) => ({
  key: "timesheetDateFromMonth",
  type: BCType.date,
  label: t("timesheet_report_filter_timesheetDateFrom"),
  dateFormat: convertApiDateFormatToPrime(dateFormat),
  placeholder: dateFormat?.toUpperCase(),
  config: {
    monthPicker: true,
  },
});

const timesheetDateToMonthFilter = ({ dateFormat = "yyyy-MM", t }: any) => ({
  key: "timesheetDateToMonth",
  type: BCType.date,
  label: t("timesheet_report_filter_timesheetDateTo"),
  dateFormat: convertApiDateFormatToPrime(dateFormat),
  placeholder: dateFormat?.toUpperCase(),
  config: {
    monthPicker: true,
  },
});

const timesheetTypeMultiSelectFilter = ({
  timesheetTypeFilterOptions,
  t,
}: any) => ({
  key: "timesheetTypeMulti",
  type: BCType.multiselect,
  label: t("timesheet_report_filter_timesheetType"),
  enum: timesheetTypeFilterOptions,
  placeholder: t("base_control_select_choose"),
  config: {
    display: "chip",
    tooltip: false,
  },
});

const activityCodeFilter = ({ activityCodeFilterOption, t }: any) => ({
  key: "activityCode",
  type: BCType.multiselect,
  label: t("timesheet_report_filter_activityCode"),
  enum: activityCodeFilterOption,
  placeholder: t("base_control_select_choose"),
  config: { filter: true },
});

const transactionStatusFilter = ({
  transactionStatusFilterOption,
  t,
}: any) => ({
  key: "transactionStatus",
  type: BCType.multiselect,
  label: t("timesheet_report_filter_transactionStatus"),
  enum: transactionStatusFilterOption,
  placeholder: t("base_control_select_choose"),
  config: { filter: true },
});

const timesheetActivityReportFilter = (config: any, filterWithOption: any) => [
  [employeeCodeFilter(config), reportingLineFilter(config)],
  [timesheetDateFromFilter(config), timesheetDateToFilter(config)],
  [
    ...(filterWithOption.includes("timesheetTypeFilterOptions")
      ? [timesheetTypeMultiSelectFilter(config)]
      : []),
  ],
  [activityCodeFilter(config), transactionStatusFilter(config)],
];

const timesheetAlstomUploadReportFilter = (
  config: any,
  filterWithOption: any
) => [
  [employeeCodeFilter(config), reportingLineFilter(config)],
  [timesheetDateFromFilter(config), timesheetDateToFilter(config)],
  [
    ...(filterWithOption.includes("timesheetTypeFilterOptions")
      ? [timesheetTypeMultiSelectFilter(config)]
      : []),
  ],
  [activityCodeFilter(config), transactionStatusFilter(config)],
];

const timesheetSummaryReportFilter = (config: any, filterWithOption: any) => [
  [employeeCodeFilter(config), reportingLineFilter(config)],
  [timesheetDateFromMonthFilter(config), timesheetDateToMonthFilter(config)],
  [
    ...(filterWithOption.includes("timesheetTypeFilterOptions")
      ? [timesheetTypeMultiSelectFilter(config)]
      : []),
  ],
  [transactionStatusFilter(config)],
];

// Report Column Config
export const TIMESHEET_REPORT_DEFAULT_COLUMN_CONFIG = {
  width: 11,
  dataType: "string",
  sortable: false,
  filter: true,
};

// time sheet columns
const activityReportColumns = [
  "employeeCode",
  "employeeName",
  "referenceNo",
  "tspFromDate",
  "tspToDate",
  //"applicantName",
  //"surname",
  //"applicantEmployeeCode",
  //"applicationReferenceNo",
  "activityCode",
  "activityName",
  "activityFromDate",
  "activityToDate",
  // date type(format as full day of week name)
  "dateType",
  "dateOfWeek",
  "appliedUnits",
  "regularOfficeHours",
  "otNonOfficeHours",
  //"activityStatus",
  "timesheetStatus",
  "remarks",
  "approverRemarks",
  "approverCompletionDate",
];

const alstomUploadColumns = [
  "documentDescription",
  "costCenter",
  "activityType",
  "templateCostCenter",
  "receiveOrder",
  "wbsElement",
  "receiverNetwork",
  "operationNo",
  "quantity",
  "unitOfMeasure",
  "employeeName",
  "employeeCode",
  "documentDate",
];

const summaryReportColumns = [
  "employeeCode",
  "employeeName",
  "company",
  "department",
  "costCenter",
  "employeeGrade",
  "periodFrom",
  "periodTo",
  "totalHours",
  "totalWorkingDays",
  "totalWorkingHours",
  "totalOtHours",
  "variance",
  "totalHoursOnSat",
  "totalHoursOnSun",
  "totalHoursOnPh",
  "totalHoursOnPhSun",
  "totalDailyChecked",
  "approverName",
  "approvalDateTime",
  "approvalStatus",
];

// Column Config
const timesheetActivityReportColumns = (t: any, hasPayslipName: boolean) =>
  activityReportColumns.reduce((acc: any, current) => {
    if (hasPayslipName && current === "surname") {
      return [
        ...acc,
        {
          ...TIMESHEET_REPORT_DEFAULT_COLUMN_CONFIG,
          header: t(`timesheet_report_column_payslipName`),
          key: "payslipName",
        },
        {
          ...TIMESHEET_REPORT_DEFAULT_COLUMN_CONFIG,
          header: t(`timesheet_report_column_${current}`),
          key: current,
        },
      ];
    }
    return [
      ...acc,
      {
        ...TIMESHEET_REPORT_DEFAULT_COLUMN_CONFIG,
        header: t(`timesheet_report_column_${current}`),
        key: current,
      },
    ];
  }, []);

const AlstomUploadReportColumns = (t: any) =>
  alstomUploadColumns.map((columnName) => ({
    ...TIMESHEET_REPORT_DEFAULT_COLUMN_CONFIG,
    header: t(`timesheet_report_column_${columnName}`),
    key: columnName,
  }));

const timesheetSummaryReportColumns = (t: any) =>
  summaryReportColumns.map((columnName) => ({
    ...TIMESHEET_REPORT_DEFAULT_COLUMN_CONFIG,
    header: t(`timesheet_report_column_${columnName}`),
    key: columnName,
  }));

// Filter Payload
const prepareFilterPayloadTimesheetActivity = (form: any) => {
  let payload: any = {};
  if (!form) return payload;

  if (form.employeeCode) {
    payload.employeeCodes = form.employeeCode;
  }
  if (form.reportingLine) {
    payload.reportingLines = [form.reportingLine];
  } else {
    payload.reportingLines = ["ALL"];
  }
  if (form.timesheetDateFrom) {
    const value = moment(form.timesheetDateFrom).format(DATE_FORMAT);
    payload.timesheetDate = payload.timesheetDate
      ? { ...payload.timesheetDate, from: value }
      : { from: value };
  }
  if (form.timesheetDateTo) {
    const value = moment(form.timesheetDateTo).format(DATE_FORMAT);
    payload.timesheetDate = payload.timesheetDate
      ? { ...payload.timesheetDate, to: value }
      : { to: value };
  }
  if (form.timesheetTypeMulti) {
    payload.timesheetTypes = form.timesheetTypeMulti;
  }
  if (form.activityCode) {
    payload.activityCodes = form.activityCode;
  }
  if (form.transactionStatus) {
    payload.transactionStatuses = form.transactionStatus;
  }
  // if (form.transactionType) {
  //   payload.transactionTypes = form.transactionType;
  // }
  return payload;
};

const prepareFilterPayloadAlstomUpload = (form: any) => {
  let payload: any = {};
  if (!form) return payload;

  if (form.employeeCode) {
    payload.employeeCodes = form.employeeCode;
  }
  if (form.reportingLine) {
    payload.reportingLines = [form.reportingLine];
  } else {
    payload.reportingLines = ["ALL"];
  }
  if (form.timesheetType) {
    payload.timesheetCode = form.timesheetType;
  }
  if (form.timesheetDateFrom) {
    const value = moment(form.timesheetDateFrom).format(DATE_FORMAT);
    payload.timesheetDate = payload.timesheetDate
      ? { ...payload.timesheetDate, from: value }
      : { from: value };
  }
  if (form.timesheetDateTo) {
    const value = moment(form.timesheetDateTo).format(DATE_FORMAT);
    payload.timesheetDate = payload.timesheetDate
      ? { ...payload.timesheetDate, to: value }
      : { to: value };
  }
  if (form.activityCode) {
    payload.activityCodes = form.activityCode;
  }
  if (form.transactionStatus) {
    payload.transactionStatuses = form.transactionStatus;
  }

  return payload;
};

const prepareFilterPayloadTimesheetSummary = (form: any) => {
  let payload: any = {};
  if (!form) return payload;

  if (form.employeeCode) {
    payload.employeeCodes = form.employeeCode;
  }
  if (form.reportingLine) {
    payload.reportingLines = [form.reportingLine];
  } else {
    payload.reportingLines = ["ALL"];
  }
  if (form.timesheetDateFromMonth) {
    const value = moment(form.timesheetDateFromMonth)
      .startOf("month")
      .format(DATE_FORMAT);
    payload.timesheetDate = payload.timesheetDate
      ? { ...payload.timesheetDate, from: value }
      : { from: value };
  }
  if (form.timesheetDateToMonth) {
    const value = moment(form.timesheetDateToMonth)
      .endOf("month")
      .format(DATE_FORMAT);
    payload.timesheetDate = payload.timesheetDate
      ? { ...payload.timesheetDate, to: value }
      : { to: value };
  }
  if (form.timesheetTypeMulti) {
    payload.timesheetTypes = form.timesheetTypeMulti;
  }
  if (form.activityCode) {
    payload.activityCodes = form.activityCode;
  }
  if (form.transactionStatus) {
    payload.transactionStatuses = form.transactionStatus;
  }
  // if (form.transactionType) {
  //   payload.transactionTypes = form.transactionType;
  // }
  return payload;
};

// Fetch Filter
const parseApiFilterTimesheetActivity = (data: any, t: any) => {
  let config: any = {};
  if (data.employeeCodes) {
    config.employeeCodeFilterOption = data.employeeCodes.map((option: any) => ({
      label: option.name,
      value: option.value,
    }));
  }
  if (data.reportingLines) {
    config.reportingLineFilterOption = data.reportingLines.map(
      (option: any) => ({
        label: translateReportingLine(option.name, t),
        value: option.value,
      })
    );
  }
  if (data.timesheetTypes) {
    config.timesheetTypeFilterOptions = data.timesheetTypes.map(
      (option: any) => ({
        label: option.name,
        value: option.value,
      })
    );
  }
  if (data.activityCodes) {
    config.activityCodeFilterOption = data.activityCodes.map((option: any) => ({
      label: option.name,
      value: option.value,
    }));
  }
  if (data.transactionStatuses) {
    config.transactionStatusFilterOption = data.transactionStatuses.map(
      (option: any) => ({
        label: option.name,
        value: option.value,
      })
    );
  }
  return config;
};

const parseApiFilterAlstomUpload = (data: any, t: any) => {
  let config: any = {};
  if (data.employeeCodes) {
    config.employeeCodeFilterOption = data.employeeCodes.map((option: any) => ({
      label: option.name,
      value: option.value,
    }));
  }
  if (data.reportingLines) {
    config.reportingLineFilterOption = data.reportingLines.map(
      (option: any) => ({
        label: translateReportingLine(option.name, t),
        value: option.value,
      })
    );
  }
  if (data.timesheetTypes) {
    config.timesheetTypeFilterOptions = data.timesheetTypes.map(
      (option: any) => ({
        label: option.name,
        value: option.value,
      })
    );
  }
  if (data.timesheetYears) {
    config.yearsFilterOptions = data.timesheetYears.map((option: any) => ({
      label: option.name,
      value: option.value,
    }));
  }
  if (data.activityCodes) {
    config.activityCodeFilterOption = data.activityCodes.map((option: any) => ({
      label: option.name,
      value: option.value,
    }));
  }
  if (data.transactionStatuses) {
    config.transactionStatusFilterOption = data.transactionStatuses.map(
      (option: any) => ({
        label: option.name,
        value: option.value,
      })
    );
  }
  return config;
};

const parseApiFilterTimesheetSummary = (data: any, t: any) => {
  let config: any = {};
  if (data.employeeCodes) {
    config.employeeCodeFilterOption = data.employeeCodes.map((option: any) => ({
      label: option.name,
      value: option.value,
    }));
  }
  if (data.reportingLines) {
    config.reportingLineFilterOption = data.reportingLines.map(
      (option: any) => ({
        label: translateReportingLine(option.name, t),
        value: option.value,
      })
    );
  }
  if (data.timesheetTypes) {
    config.timesheetTypeFilterOptions = data.timesheetTypes.map(
      (option: any) => ({
        label: option.name,
        value: option.value,
      })
    );
  }
  if (data.activityCodes) {
    config.activityCodeFilterOption = data.activityCodes.map((option: any) => ({
      label: option.name,
      value: option.value,
    }));
  }
  if (data.transactionStatuses) {
    config.transactionStatusFilterOption = data.transactionStatuses.map(
      (option: any) => ({
        label: option.name,
        value: option.value,
      })
    );
  }
  return config;
};

const translateReportingLine = (data: any, t: any) => {
  if (data != null) {
    if (data === "FUNCTION_REPORT_TO") {
      return t("timesheet_report_filter_function_report_to");
    } else {
      return data;
    }
  }
};

// Export
export const getReportFilterConfig = (
  reportType: any,
  config: any,
  filterWithOption: any,
  commonFilter: any
) => {
  let filters: any;
  switch (reportType) {
    case TIMESHEET_REPORT_TYPE.TIMESHEET_ACTIVITY_REPORT:
      filters = timesheetActivityReportFilter(config, filterWithOption);
      break;
    case TIMESHEET_REPORT_TYPE.ALSTOM_UPLOAD_REPORT:
      filters = timesheetAlstomUploadReportFilter(config, filterWithOption);
      break;
    case TIMESHEET_REPORT_TYPE.TIMESHEET_SUMMARY_REPORT:
      filters = timesheetSummaryReportFilter(config, filterWithOption);
      break;
    default:
      break;
  }

  function convertToColumns(array: any, columns: any) {
    const result = [];
    let row = [];

    for (let i = 0; i < array.length; i++) {
      row.push(array[i]);

      if ((i + 1) % columns === 0 || i === array.length - 1) {
        result.push(row);
        row = [];
      }
    }

    return result;
  }

  if (Array.isArray(commonFilter) && commonFilter.length > 0) {
    let start = -1;
    let employeeCodeFilterIndex = filters.findIndex(
      (row: any) =>
        row.findIndex((column: any) =>
          ["employeeCode", "reportingLine"].includes(column.key)
        ) > -1
    );
    let timesheetTypeFilterIndex = filters.findIndex(
      (row: any) =>
        row.findIndex((column: any) =>
          ["timesheetType", "year"].includes(column.key)
        ) > -1
    );
    if (employeeCodeFilterIndex < 0 && timesheetTypeFilterIndex > -1) {
      start = timesheetTypeFilterIndex;
    }

    if (employeeCodeFilterIndex > -1) {
      start = employeeCodeFilterIndex;
      ++start;
    }

    if (start > -1) {
      let newRows = convertToColumns(commonFilter, 2);
      newRows.forEach((row, index) => {
        filters.splice(start + index, 0, row);
      });
    }
  }

  if (filters) {
    return {
      controls: filters.reduce((acc: any, cur: any) => {
        acc = acc.concat([...cur]);
        return acc;
      }, []),
      layout: {
        rows: filters.map((filterRow: any) => {
          return {
            columns: filterRow.map((filter: any) => {
              return {
                control: filter.key,
                config: {
                  className: "p-col-12 p-lg-4 p-md-6 p-sm-12",
                },
              };
            }),
          };
        }),
      },
    };
  }
  return {};
};

export const getReportColumnConfig = (
  reportType: any,
  t: any,
  hasPayslipName: boolean
) => {
  let columns: any = [];
  switch (reportType) {
    case TIMESHEET_REPORT_TYPE.TIMESHEET_ACTIVITY_REPORT:
      columns = timesheetActivityReportColumns(t, hasPayslipName);
      break;
    case TIMESHEET_REPORT_TYPE.ALSTOM_UPLOAD_REPORT:
      columns = AlstomUploadReportColumns(t);
      break;
    case TIMESHEET_REPORT_TYPE.TIMESHEET_SUMMARY_REPORT:
      columns = timesheetSummaryReportColumns(t);
      break;
    default:
      break;
  }
  return columns;
};

export const parseApiFilter = (
  reportType: any,
  data: any,
  translation: any
) => {
  let filters: any = {};
  let configs: any = [];
  switch (reportType) {
    case TIMESHEET_REPORT_TYPE.TIMESHEET_ACTIVITY_REPORT:
      filters = parseApiFilterTimesheetActivity(data, translation);
      break;
    case TIMESHEET_REPORT_TYPE.ALSTOM_UPLOAD_REPORT:
      filters = parseApiFilterAlstomUpload(data, translation);
      break;
    case TIMESHEET_REPORT_TYPE.TIMESHEET_SUMMARY_REPORT:
      filters = parseApiFilterTimesheetSummary(data, translation);
      break;
    default:
      return { filters, configs };
  }

  //adding common general filters
  if (!!data) {
    for (const property in data) {
      let filter = data[property];
      if (
        filter.hasOwnProperty("field") &&
        filter.hasOwnProperty("dataList") &&
        filter.hasOwnProperty("translationKey")
      ) {
        let dataList = ToUIOption(filter.dataList);
        let translationKey = filter.translationKey;
        if (Array.isArray(dataList)) {
          filters[property] = dataList;
          configs.push({
            key: property,
            type: filter?.ComponentType ?? BCType.multiselect,
            label: translation(translationKey),
            enum: dataList,
            placeholder: translation("base_control_select_choose"),
          });
        }
      }
    }
  }

  return { filters, configs };
};

const prepareDefaultFilterValueTimesheetActivity = (
  filters: any,
  currentUser: IStoreUser["profile"]
) => {
  let defaultFilters: any = {};
  if (filters.employeeCodeFilterOption?.length === 1) {
    defaultFilters.employeeCode = [filters.employeeCodeFilterOption[0].value];
    defaultFilters.employeeCode_obj = [filters.employeeCodeFilterOption[0]];
  }
  //defaultFilters.employeeCode = [currentUser.employeeCode];
  //defaultFilters.employeeCode_obj = [currentUser.name];
  // if (filters.reportingLineFilterOption.length > 0) {
  //   const targetReportingLineALL = filters.reportingLineFilterOption.filter(
  //     (rl) => rl.value === "ALL"
  //   );
  //   if (targetReportingLineALL.length > 0) {
  //     defaultFilters.reportingLine = targetReportingLineALL[0].value;
  //     defaultFilters.reportingLine_obj = targetReportingLineALL[0];
  //   }
  // }
  if (filters.reportingLineFilterOption.length > 0) {
    const targetReportingLineALL = filters.reportingLineFilterOption.find(
      (rl: any) => rl.value === "None (SELF)"
    );
    if (targetReportingLineALL) {
      defaultFilters.reportingLine = targetReportingLineALL.value;
      defaultFilters.reportingLine_obj = targetReportingLineALL;
    }
  }

  if (filters.timesheetTypeFilterOptions?.length > 0) {
    const targetTimesheetTypeALN = filters.timesheetTypeFilterOptions.filter(
      (lt: any) => lt.value === "ALN"
    );
    const targetTimesheetTypePTO = filters.timesheetTypeFilterOptions.filter(
      (lt: any) => lt.value === "PTO"
    );
    if (targetTimesheetTypeALN.length > 0) {
      defaultFilters.timesheetTypeMulti = [targetTimesheetTypeALN[0].value];
      defaultFilters.timesheetTypeMulti_obj = [targetTimesheetTypeALN[0]];
    } else if (targetTimesheetTypePTO.length > 0) {
      defaultFilters.timesheetTypeMulti = [targetTimesheetTypePTO[0].value];
      defaultFilters.timesheetTypeMulti_obj = [targetTimesheetTypePTO[0]];
    } else {
      defaultFilters.timesheetTypeMulti = [
        filters.timesheetTypeFilterOptions[0].value,
      ];
      defaultFilters.timesheetTypeMulti_obj = [
        filters.timesheetTypeFilterOptions[0],
      ];
    }
  }
  // if (filters.transactionTypeFilterOptions?.length > 0) {
  //   let transactionType:any = [];
  //   let transactionType_obj:any = [];
  //   filters.transactionTypeFilterOptions.forEach((tr:any) => {
  //     transactionType.push(tr.value);
  //     transactionType_obj.push(tr);
  //   });
  //   defaultFilters.transactionType = transactionType;
  //   defaultFilters.transactionType_obj = transactionType_obj;
  // }
  /*
  defaultFilters.timesheetDateFrom = new Date(
    moment().startOf("month").format(DATE_FORMAT)
  );
  defaultFilters.timesheetDateTo = new Date(
    moment().endOf("month").format(DATE_FORMAT)
  );
  */
  return defaultFilters;
};

const prepareDefaultFilterValueAlstomUpload = (
  filters: any,
  currentUser: IStoreUser["profile"]
) => {
  let defaultFilters: any = {};
  if (filters.employeeCodeFilterOption?.length === 1) {
    defaultFilters.employeeCode = [filters.employeeCodeFilterOption[0].value];
    defaultFilters.employeeCode_obj = [filters.employeeCodeFilterOption[0]];
  }
  //defaultFilters.employeeCode = [currentUser.employeeCode];
  //defaultFilters.employeeCode_obj = [currentUser.name];
  if (filters.reportingLineFilterOption.length > 0) {
    const targetReportingLineALL = filters.reportingLineFilterOption.find(
      (rl: any) => rl.value === "None (SELF)"
    );
    if (targetReportingLineALL) {
      defaultFilters.reportingLine = targetReportingLineALL.value;
      defaultFilters.reportingLine_obj = targetReportingLineALL;
    }
  }
  if (filters.companyCode?.length > 0) {
    defaultFilters.companyCode = filters.companyCode.map((c: any) => c.value);
  }
  if (filters.department?.length > 0) {
    defaultFilters.department = filters.department.map((c: any) => c.value);
  }
  if (filters.division?.length > 0) {
    defaultFilters.division = filters.division.map((c: any) => c.value);
  }

  return defaultFilters;
};

const prepareDefaultFilterValueTimesheetSummary = (
  filters: any,
  currentUser: IStoreUser["profile"]
) => {
  let defaultFilters: any = {};
  if (filters.employeeCodeFilterOption?.length === 1) {
    defaultFilters.employeeCode = [filters.employeeCodeFilterOption[0].value];
    defaultFilters.employeeCode_obj = [filters.employeeCodeFilterOption[0]];
  }
  //defaultFilters.employeeCode = [currentUser.employeeCode];
  //defaultFilters.employeeCode_obj = [currentUser.name];
  if (filters.reportingLineFilterOption.length > 0) {
    const targetReportingLineALL = filters.reportingLineFilterOption.find(
      (rl: any) => rl.value === "None (SELF)"
    );
    if (targetReportingLineALL) {
      defaultFilters.reportingLine = targetReportingLineALL.value;
      defaultFilters.reportingLine_obj = targetReportingLineALL;
    }
  }
  if (filters.companyCode?.length > 0) {
    defaultFilters.companyCode = filters.companyCode.map((c: any) => c.value);
  }
  if (filters.department?.length > 0) {
    defaultFilters.department = filters.department.map((c: any) => c.value);
  }
  if (filters.division?.length > 0) {
    defaultFilters.division = filters.division.map((c: any) => c.value);
  }

  return defaultFilters;
};

export const prepareDefaultFilterValue = (
  reportType: any,
  filters: any,
  currentUser: IStoreUser["profile"]
) => {
  switch (reportType) {
    case TIMESHEET_REPORT_TYPE.TIMESHEET_ACTIVITY_REPORT:
      return prepareDefaultFilterValueTimesheetActivity(filters, currentUser);
    case TIMESHEET_REPORT_TYPE.ALSTOM_UPLOAD_REPORT:
      return prepareDefaultFilterValueAlstomUpload(filters, currentUser);
    case TIMESHEET_REPORT_TYPE.TIMESHEET_SUMMARY_REPORT:
      return prepareDefaultFilterValueTimesheetSummary(filters, currentUser);
    default:
      return {};
  }
};

export const prepareFilterPayload = (reportType: any, form: any) => {
  let payload = {};
  switch (reportType) {
    case TIMESHEET_REPORT_TYPE.TIMESHEET_ACTIVITY_REPORT:
      payload = prepareFilterPayloadTimesheetActivity(form);
      break;
    case TIMESHEET_REPORT_TYPE.ALSTOM_UPLOAD_REPORT:
      payload = prepareFilterPayloadAlstomUpload(form);
      break;
    case TIMESHEET_REPORT_TYPE.TIMESHEET_SUMMARY_REPORT:
      payload = prepareFilterPayloadTimesheetSummary(form);
      break;
    default:
      break;
  }
  return payload;
};

export const getFilterOptions: Function = async (
  reportType: any,
  reportingLine: any,
  employeeCodes: any
) => {
  const body = {
    reportingLine,
    employeeCodes,
  };

  if (!!reportingLine) {
    body.employeeCodes = employeeCodes ?? [];
  }

  return TimesheetService.getTimesheetReportFilterOptions({ body, reportType });

  // switch (reportType) {
  //   case TIMESHEET_REPORT_TYPE.TIMESHEET_ACTIVITY_REPORT:
  //     return LeaveReportService.leaveReportGetLeaveTransactionFilter({
  //       body,
  //     });
  //   case TIMESHEET_REPORT_TYPE.ALSTOM_UPLOAD_REPORT:
  //     return LeaveReportService.leaveReportGetLeaveBalanceFilter({
  //       body,
  //     });
  //   default:
  //     return;
  // }
};
