import { FORM_CONTROL_DISPLAY_MODE } from "../../constants/layout";

export default function ClaimFormItemWrapper({
  displayMode,
  condition = [FORM_CONTROL_DISPLAY_MODE.NA],
  children,
}: any) {
  const isHidden = condition.includes(displayMode.toLowerCase());

  if (isHidden) {
    return <></>;
  }
  return children;
}
