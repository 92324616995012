import LanguageLoader from "../../../../../components/language-loader/language-loader";
import { EEPORTAL_LABEL_BASE_TABLE } from "../../../../../constants";
import { Header } from "../../components";
import TimesheetScheduler from "../../components/timesheet-scheduler";
import { TIMESHEET_CONTEXT_KEY } from "../../constants";

import "./index.scss";
import { useContext, useEffect } from "react";
import { TimesheetDetailFooter } from "./timesheet-detail-footer";
import { TimesheetPeriodContainer } from "../../components/timesheet-period-container";
import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";
import {
  TimesheetDetailContextProvider,
  useTimesheetDetailContext,
} from "./context/useTimesheetDetailContext";
import { TIMESHEET_DETAIL_TYPE } from "../../constants/type";
import { LoadingContext } from "../../context";
import { CreateActivityModal } from "./create-modal";
import { useLeaveWithoutSaveAlert } from "./hooks/useLeaveWithoutSaveAlert";
import ApprovalActionBar from "./approval-action-bar";
import { useDispatch } from "react-redux";
import { showSpinner } from "../../../../../redux/actions/spinner";

export const customModelFields = {
  id: "TaskID",
  title: "Title",
  description: "Description",
  start: "Start",
  end: "End",
  recurrenceRule: "RecurrenceRule",
  recurrenceId: "RecurrenceID",
  recurrenceExceptions: "RecurrenceException",
};
const Main = () => {
  const { t } = useTranslation();
  const {
    timetableBlocks,
    timesheetId,
    onClickAddTime,
    periodFromDate,
    periodToDate,
    mode,
    calendarDates,
    config,
    nElasticDataH,
  } = useTimesheetDetailContext();

  const isView =
    mode === TIMESHEET_DETAIL_TYPE.VIEW ||
    mode === TIMESHEET_DETAIL_TYPE.APPROVAL;
  const isEdit = mode === TIMESHEET_DETAIL_TYPE.EDIT;

  return (
    <>
      <TimesheetPeriodContainer />
      {!isView && (
        <div className="add-time-button-container">
          <Button
            className="p-button"
            onClick={onClickAddTime}
            disabled={false}
          >
            {t("timesheet_common_actionAddTime")}
          </Button>
        </div>
      )}
      {mode === TIMESHEET_DETAIL_TYPE.APPROVAL && <ApprovalActionBar />}
      <TimesheetScheduler
        data={timetableBlocks}
        periodFrom={periodFromDate}
        periodTo={periodToDate}
        isView={isView}
        isEdit={isEdit}
        timesheetId={timesheetId}
        calendarDates={calendarDates}
        config={config}
        nElasticDataH={nElasticDataH}
      />
      <CreateActivityModal />
    </>
  );
};

const TimesheetDetailComponent = () => {
  const { headerTitleKey, mode } = useTimesheetDetailContext();
  const [{ isLoading, isSubmitting }] = useContext(LoadingContext);
  const dispatch = useDispatch();

  useLeaveWithoutSaveAlert();

  useEffect(() => {
    dispatch(showSpinner(isLoading || isSubmitting));
  }, [dispatch, isLoading, isSubmitting]);

  return (
    <>
      <LanguageLoader
        contexts={[
          EEPORTAL_LABEL_BASE_TABLE.BASE_TABLE,
          TIMESHEET_CONTEXT_KEY.COMMON,
          TIMESHEET_CONTEXT_KEY.FORM,
          TIMESHEET_CONTEXT_KEY.DETAIL,
          TIMESHEET_CONTEXT_KEY.STATUS,
        ]}
      />
      <div
        className={`timesheet-container timesheet-detail${
          mode === TIMESHEET_DETAIL_TYPE.VIEW ? " is-view" : ""
        }${isSubmitting ? " submitting" : ""}${isLoading ? " loading" : ""}`}
      >
        <Header headerTitleKey={headerTitleKey} WithHeaderDropdown />
        <Main />
        <TimesheetDetailFooter />
      </div>
    </>
  );
};

const TimesheetDetail = (props: any) => {
  return (
    <>
      <TimesheetDetailContextProvider>
        <TimesheetDetailComponent />
      </TimesheetDetailContextProvider>
    </>
  );
};

export default TimesheetDetail;
