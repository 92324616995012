import { LoadingAction, LoadingPayload } from "../action/loading";
import { ActionMap } from "../interface";

export interface ILoadingState {
  isLoading: boolean;
  isSubmitting: boolean;
}

export const initialLoadingState: ILoadingState = {
  isLoading: true,
  isSubmitting: false,
};

export type LoadingReducer = React.Reducer<
  ILoadingState,
  ActionMap<LoadingAction, LoadingPayload>
>;
//   Reducer<S[K], A>
export const loadingReducer: LoadingReducer = (
  state = initialLoadingState,
  action
) => {
  switch (action.type) {
    case LoadingAction.SET_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case LoadingAction.SET_SUBMITTING:
      return {
        ...state,
        isSubmitting: action.payload,
      };
    default:
      return state;
  }
};
