import { BCType } from "src/components/base-control/base-control";
import { downloadFile } from "./utils";
import { MESSAGE_SEVERITY } from "src/constants/index";
import { showToast } from "src/services/utils/message";
import {
  convertApiDateFormatToPrime,
  processAttachmentControlConfig,
} from "src/utils/utils";

export enum ANALYSIS_CODE_DATA_TYPE {
  date = "date",
  string = "string",
  number = "number",
}

export interface AnalysisCode {
  id: number;
  analysisCode: string;
  description: string;
  dataType: string;
  staticValues: string;
  formatString: string;
  allowNull: boolean;
  allowMultiple: boolean;
  allowCustomValue: boolean;
  value?: string;
}

export interface AttachmentTypeInterface {
  typeCode: string;
  description: string;
  allowedFileExt?: string;
  personalContent?: boolean;
  allowedFileSize?: number;
  allowedBoOfFiles?: number;
}

export interface IInputControl {
  config: any;
  controlConfig?: any;
  t: any;
}

const convertToDateControl = ({ config, controlConfig, t }: IInputControl) => {
  return {
    key: config.analysisCode,
    type: BCType.date,
    label: config.description,
    required: !config.allowNull,
    dateFormat: convertApiDateFormatToPrime(config.formatString),
    placeholder: t("claims_form_select"),
    ...controlConfig,
  };
};

export const convertToNumberControl = ({
  config,
  controlConfig,
  t,
}: IInputControl) => {
  return {
    key: config.analysisCode,
    type: BCType.number,
    label: config.description,
    required: !config.allowNull,
    placeholder: t("base_control_number_input_number"),
    ...controlConfig,
  };
};

export const convertToTextBoxControl = ({
  config,
  controlConfig,
  t,
}: IInputControl) => {
  return {
    key: config.analysisCode,
    type: BCType.input,
    label: config.description,
    required: !config.allowNull,
    placeholder: t("base_control_input_text"),
    ...controlConfig,
  };
};

export const convertToDropdownControl = ({
  config,
  controlConfig,
  t,
}: IInputControl) => {
  const staticValueJson = JSON.parse(config.staticValues);
  const enums = staticValueJson.map((option: any) => ({
    label: option.LABEL,
    value: option.VALUE,
  }));
  return {
    key: config.analysisCode,
    type: config.allowMultiple ? BCType.multiselect : BCType.select,
    label: config.description,
    required: !config.allowNull,
    placeholder: t("base_control_input_text"),
    enum: enums,
    ...controlConfig,
  };
};

export const convertToCheckboxControl = ({
  config,
  controlConfig,
  t,
}: IInputControl) => {
  return {
    key: config.analysisCode,
    type: BCType.checkbox,
    label: config.description,
    required: !config.allowNull,
    placeholder: t("base_control_number_input_number"),
    ...controlConfig,
  };
};

export const convertToControl = ({
  config,
  controlConfig = {},
  t,
}: IInputControl) => {
  // staticValues => dropdown
  if (config.staticValues) {
    return convertToDropdownControl({ config, controlConfig, t });
  }
  switch (config.dataType) {
    case ANALYSIS_CODE_DATA_TYPE.date:
      return convertToDateControl({ config, controlConfig, t });
    case ANALYSIS_CODE_DATA_TYPE.string:
      return convertToTextBoxControl({ config, controlConfig, t });
    case ANALYSIS_CODE_DATA_TYPE.number:
      return convertToNumberControl({ config, controlConfig, t });
  }
};

export const convertToAttachmentControl = ({
  config,
  controlConfig,
  t,
}: {
  config: AttachmentTypeInterface;
  controlConfig: any;
  t: any;
}) => {
  let baseAttachmentControl = {
    key: config.typeCode,
    label: config.description,
    type: BCType.upload,
    multiple: true,
    onFileError: ({ defaultMessage }: any) =>
      showToast({
        summary: defaultMessage,
        severity: MESSAGE_SEVERITY.WARN,
      }),
    onDownload: (f: any) => {
      downloadFile(f);
    },
    ...controlConfig,
  };

  const withConfig = !!config.allowedFileExt;
  if (withConfig) {
    const attachmentConfig = processAttachmentControlConfig(
      config as any
    ) as any;
    baseAttachmentControl.config = {
      accept: attachmentConfig.allowedFileExt,
      maxFileSize: attachmentConfig.allowedFileSize,
      hideDownload: true,
      ...(controlConfig.config || {}),
    };
    const hint = `${t(
      "claims_form_fileFormatHint"
    )} ${attachmentConfig.allowedFileExt?.split(",").join(", ")}`;
    baseAttachmentControl.hintBottom = (
      <span className="hint">
        <b>{hint}</b>
      </span>
    );
  }

  return baseAttachmentControl;
};

export const convertToNoteControl = (note: string) => {
  return {
    key: "Reminder",
    noLabel: true,
    componentRender: () => (
      <div className="note" dangerouslySetInnerHTML={{ __html: note }}></div>
    ),
  };
};
