import "@progress/kendo-date-math/tz/Asia/Hong_Kong";
import {
  Scheduler,
  SchedulerResource,
  SchedulerSlot,
  SchedulerSlotProps,
  SchedulerViewItem,
  TimelineView,
} from "@progress/kendo-react-scheduler";
import moment from "moment";
import { Checkbox } from "primereact/checkbox";
import { Tooltip } from "primereact/tooltip";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { IDatesData, ITimesheetConfigData } from "../../constants";
import { DATE_TYPE } from "../../constants/type";
import { ApplicationContext } from "../../context";
import { ApplicationAction } from "../../context/action/application";
import { NElasticDataHModel } from "../../models/timesheet-application-model";
import { formatTwoDecimals } from "../../utils/format-helper";
import TimesheetSchedulerActivity from "../timesheet-scheduler-activity";
import "./scheduler.scss";
// TODO: make it as component for adding custom `Total` field
// TODO: css for first row first column (make it be fixed) and adding `Date`
// TODO: Delete Activity from draft timesheet

const CustomViewItem = (props: any) => {
  return (
    <SchedulerViewItem
      {...props}
      style={{
        ...props.style,
        height: 88,
      }}
    />
  );
};

const CustomSlot = (props: SchedulerSlotProps) => {
  const dateResource = props.group.resources[0] as SchedulerResource & {
    type: string;
  };

  return (
    <SchedulerSlot
      {...props}
      className={`${dateResource.type !== DATE_TYPE.WORKING ? "holiday" : ""}`}
    />
  );
};

const generateResource = (calendarDates: IDatesData[]) => {
  const data = calendarDates?.map((date) => ({
    text: moment(date.targetDate).format("ddd, Do MMM"),
    value: moment(date.targetDate).format("YYYY-MM-DD"),
    type: date.dateType,
    leaveApplied: date.leaveApplied,
  }));

  return [
    {
      name: "Dates",
      data,
      field: "resourceDate",
      valueField: "value",
      textField: "text",
    },
  ];
};

interface IResourceData {
  actualEnd: Date;
  actualStart: Date;
  aprvRemark?: string;
  description: string;
  end: Date; // moment date
  hasConflict: boolean;
  id: number;
  isSameDay: boolean;
  order: number;
  remarks?: string;
  resourceDate: string; // date string to identify with resources
  start: Date; // moment date;
  status: string;
  title: string;
  type: "activity";
  uuid?: string;
  [key: string]: any;
}

interface ILeaveData {
  actualEnd: Date;
  actualStart: Date;
  adjustedUnits: number;
  appliedUnits: number;
  committedUnits: number;
  end: Date;
  forfeitedUnits: number;
  from: string;
  hasConflict: boolean;
  id: string;
  LeaveType: string;
  order: number;
  remainingUnits: number;
  resourceDate: string;
  start: Date;
  status: string;
  testDate: Date;
  to: string;
  transactionId: number;
  type: "leave";
  [key: string]: any;
}

interface IProps {
  data: (IResourceData | ILeaveData)[];
  periodFrom: string;
  periodTo: string;
  isView: boolean;
  isEdit: boolean;
  timesheetId: number | string;
  calendarDates: IDatesData[];
  config: ITimesheetConfigData;
  nElasticDataH: NElasticDataHModel;
}

export default function TimesheetScheduler({
  data,
  isView,
  isEdit,
  timesheetId,
  calendarDates,
  config,
  nElasticDataH,
}: IProps) {
  const schedulerRef = useRef<any>();
  const scrollerViewRef = useRef<any>();
  const containerRef = useRef<HTMLDivElement>(null);
  const prevCountRef = useRef<number>(0);
  const { t } = useTranslation();
  const resources = useMemo(
    () => generateResource(calendarDates),
    [calendarDates]
  );
  const [{ application }, dispatchApplicationAction] =
    useContext(ApplicationContext);

  const [fromTime, toTime, division] = useMemo(() => {
    return [config?.fromTime, config?.toTime, 4];
  }, [config]);

  const mealFromTime = moment(config?.mealFromTime, "HH:mm:ss");
  const mealToTime = moment(config?.mealToTime, "HH:mm:ss");
  const mealTotalHours = moment
    .duration(mealToTime.diff(mealFromTime))
    .asHours();
  let remainingTime = 0;
  let deducted = false;

  const [dailyCheckbox, setDailyCheckbox] = useState<boolean>(); //show daily checkbox
  const [CheckedDates, setCheckedDates] = useState<string[]>([]);

  const dailyCheckboxOnChange = (checked: boolean, value: string) => {
    if (checked) {
      setCheckedDates([...CheckedDates, value]);
    } else {
      setCheckedDates([...CheckedDates, value].filter((date) => date != value));
    }
  };

  const dailyCheckboxDisabled = (
    dateType: string,
    leaveApplied: boolean,
    dateValue: string
  ) => {
    if (dailyCheckbox && !isView) {
      const eligibleDayTypes = config?.dailyCheckboxConfig?.eligibleDayTypes;
      let isDayTypeAllowed = true;
      if (eligibleDayTypes?.length > 0) {
        isDayTypeAllowed = eligibleDayTypes.some((t) => t === dateType);
      }
      if (isDayTypeAllowed) {
        dateValue = moment(dateValue).format("YYYY-MM-DD");
        let isTsApplied = data.some(
          (t) => t.resourceDate === dateValue && t.type === "activity"
        );
        return isTsApplied ? leaveApplied : true;
      }
    }
    return true;
  };

  // Add two on scroll to synchronize scheduler and custom fixed column scroll top
  const onScrollCustomView = (
    event: React.UIEvent<HTMLDivElement, UIEvent>
  ) => {
    if (schedulerRef.current) {
      const timelineView = schedulerRef.current.element.querySelector(
        ".k-scheduler-timeline-view"
      );
      timelineView.scrollTop = event.currentTarget.scrollTop;
    }
  };

  const dailyCheckboxHeaderText = (text: string) => {
    if (text.indexOf(" ") >= 0) return text.match(/\b(\w)/g)?.join("");
  };

  useEffect(() => {
    if (schedulerRef.current) {
      const timelineView = schedulerRef.current.element.querySelector(
        ".k-scheduler-timeline-view"
      );
      timelineView.addEventListener("scroll", (event: any) => {
        const targetElement = event.target || event.srcElement;
        if (scrollerViewRef.current) {
          scrollerViewRef.current.scrollTop = targetElement.scrollTop;
        }
      });

      //
      const fixedFirstColumn = schedulerRef.current.element.querySelector(
        ".k-scheduler-body .k-sticky-cell"
      );
      fixedFirstColumn.style.zIndex = 2;

      const firstHeader = schedulerRef.current.element.querySelector(
        ".k-scheduler-cell.k-resource-cell.k-heading-cell"
      );
      firstHeader.innerHTML = "Dates";
    }
  }, []);

  useEffect(() => {
    if (resources.length > 0 && resources[0].data.length > 0) {
      const hasCheckedDate = nElasticDataH?.dailyCheckboxDates != null;
      let showDailyCheckbox: boolean = false;
      if (!timesheetId)
        showDailyCheckbox = config.dailyCheckboxConfig?.allowOnAccess;
      else showDailyCheckbox = hasCheckedDate;
      setDailyCheckbox(showDailyCheckbox);

      if (showDailyCheckbox)
        setCheckedDates(nElasticDataH?.dailyCheckboxDates ?? []);
    }
  }, [resources]);

  useEffect(() => {
    if (dailyCheckbox && CheckedDates) {
      var temp = { ...nElasticDataH };
      if (temp?.dailyCheckboxDates) {
        nElasticDataH.dailyCheckboxDates = CheckedDates;
      } else {
        temp.dailyCheckboxDates = CheckedDates;
        dispatchApplicationAction({
          type: ApplicationAction.SET_APPLICATION,
          payload: {
            application: {
              ...application,
              nElasticDataH: temp,
            },
          },
        });
      }
    }
  }, [CheckedDates, dispatchApplicationAction]);

  const prevCount = prevCountRef.current;
  useEffect(() => {
    prevCountRef.current = data.length;
    //delete activity action
    if (
      application.periodId &&
      CheckedDates &&
      prevCountRef.current < prevCount
    ) {
      var dataDates = data
        .filter((date) => date.type === "activity")
        ?.map((date) => date.resourceDate);
      var newCheckedDates = [...CheckedDates].filter((date) =>
        dataDates.includes(date)
      );
      setCheckedDates(newCheckedDates);
    }
  }, [data]);

  return (
    <div className="container" ref={containerRef}>
      <Scheduler
        className="timeline"
        group={{
          resources: ["Dates"],
          orientation: "vertical",
        }}
        header={() => <></>}
        resources={resources}
        data={data}
        defaultDate={new Date()}
        item={(props) => {
          return (
            <TimesheetSchedulerActivity
              isEdit={isEdit}
              timesheetId={timesheetId}
              containerRef={containerRef}
              timetableDates={resources?.[0]?.data}
              {...props}
              data={data}
            />
          );
        }}
        viewItem={CustomViewItem}
        ref={schedulerRef}
        slot={CustomSlot}
      >
        <TimelineView
          slotDivisions={division}
          columnWidth={50}
          currentTimeMarker={false}
          workDayStart={fromTime}
          workDayEnd={toTime}
          dateHeaderCell={() => <></>}
        />
      </Scheduler>
      <div
        className="custom-sticky-cell k-sticky-cell k-scheduler"
        style={{ maxHeight: 551, minWidth: 75 * (dailyCheckbox ? 2 : 1) }}
      >
        <div className="k-scheduler-layout k-scheduler-layout-flex k-scheduler-timeline-view custom-scheduler-header">
          {dailyCheckbox && (
            <>
              <Tooltip target=".daily-checkbox-column" mouseTrack />
              <div
                className="k-scheduler-cell k-resource-cell k-heading-cell daily-checkbox-column"
                data-pr-tooltip={t("timesheet_scheduler_dailyCheckbox")}
                data-pr-position="right"
              >
                {dailyCheckboxHeaderText(
                  t("timesheet_scheduler_dailyCheckbox")
                )}
              </div>
            </>
          )}
          <div className="k-scheduler-cell k-resource-cell k-heading-cell">
            Total
          </div>
        </div>
        <div
          ref={scrollerViewRef}
          onScroll={onScrollCustomView}
          className="k-scheduler-layout k-scheduler-layout-flex k-scheduler-timeline-view total-column"
          style={{ marginBottom: "4px" }}
        >
          {resources.length > 0 &&
            resources[0].data.map((dat, i) => {
              const correspondingElementHeight = schedulerRef.current?.element
                .querySelector(
                  `.k-scheduler-cell.k-resource-cell[data-resource-index='${i}']`
                )
                ?.getBoundingClientRect().height;
              const height = correspondingElementHeight || 45;
              const cloneData = [...data];
              cloneData.sort((a, b) => a.order - b.order);

              const totalHrs = cloneData.reduce((acc, current) => {
                if (
                  current?.resourceDate === dat.value &&
                  current?.type === "activity"
                ) {
                  if (current.isSameDay) {
                    let duration = moment
                      .duration(moment(current.end).diff(moment(current.start)))
                      .asHours();

                    if (current.excludeMealTime) {
                      duration -= mealTotalHours;
                      return acc + duration;
                    } else {
                      return acc + duration;
                    }
                  } else {
                    let duration = moment
                      .duration(moment(current.end).diff(moment(current.start)))
                      .asHours();
                    if (current.excludeMealTime) {
                      if (duration >= 1) {
                        if (!deducted) {
                          duration = duration - mealTotalHours;
                          deducted = true;
                          return acc + duration;
                        } else if (deducted && remainingTime > 0) {
                          duration -= remainingTime;
                          deducted = false;
                          remainingTime = 0;
                          return acc + duration;
                        } else {
                          deducted = false;
                          return acc + duration;
                        }
                      } else {
                        if (!deducted) {
                          remainingTime = mealTotalHours - duration;
                          duration -= duration;
                          deducted = true;
                          return acc + duration;
                        } else if (deducted && remainingTime > 0) {
                          duration -= remainingTime;
                          deducted = false;
                          remainingTime = 0;
                          return acc + duration;
                        } else {
                          deducted = false;
                          return acc + duration;
                        }
                      }
                    } else {
                      return acc + duration;
                    }
                  }
                }
                return acc;
              }, 0);

              return (
                <div key={i}>
                  {dailyCheckbox && (
                    <div
                      className="k-scheduler-cell k-resource-cell k-resource-row k-heading-cell cell-border"
                      data-depth-index="0"
                      data-resource-index="0"
                      style={{
                        height,
                        boxSizing: "inherit",
                        float: "left",
                        width: "48%",
                      }}
                    >
                      <Checkbox
                        onChange={(e) =>
                          dailyCheckboxOnChange(e.checked, dat.value)
                        }
                        checked={
                          CheckedDates &&
                          CheckedDates.some((date) => date === dat.value)
                        }
                        readOnly={dailyCheckboxDisabled(
                          dat.type,
                          dat.leaveApplied,
                          dat.value
                        )}
                        tooltip={t("timesheet_scheduler_dailyCheckbox")}
                      />
                    </div>
                  )}
                  <div
                    className="k-scheduler-cell k-resource-cell k-resource-row k-heading-cell cell-border"
                    data-depth-index="0"
                    data-resource-index="0"
                    style={{
                      height,
                      boxSizing: "inherit",
                    }}
                  >
                    {formatTwoDecimals(totalHrs)} Hr
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}
