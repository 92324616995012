import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";

interface IButton {
  label: string;
  onClick: () => void;
  disabled?: boolean;
}

export const Footer = ({
  form,
  primaryButton,
  secondaryButton,
  backButton,
}: {
  form: any;
  primaryButton: IButton;
  secondaryButton?: IButton;
  backButton?: IButton;
}) => {
  const { t } = useTranslation();

  return (
    <div className="footer p-grid p-align-center p-justify-between">
      <div className="left-button-group">
        {!!backButton && (
          <Button
            className="p-button-outlined secondary back-button"
            onClick={backButton.onClick}
            disabled={backButton?.disabled}
          >
            {t(backButton.label)}
          </Button>
        )}
      </div>

      <div className="right-button-group">
        {form && (
          <>
            {!!secondaryButton && (
              <Button
                className="p-button-outlined secondary"
                onClick={secondaryButton.onClick}
                disabled={secondaryButton?.disabled}
              >
                {t(secondaryButton.label)}
              </Button>
            )}
            <Button
              className="p-button"
              onClick={primaryButton.onClick}
              disabled={primaryButton.disabled}
            >
              {t(primaryButton.label)}
            </Button>
          </>
        )}
      </div>
    </div>
  );
};
