import "./logout.scss";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "./index";
import { useEffect, useMemo, useState } from "react";
import LoginWrapper from "../../portal/modules/public/login/login-wrapper";
import { useTranslation } from "react-i18next";
import { AuthenticationService } from "../hrmnet-api";
import { resetAuthInfo } from "../../redux/actions/auth";
import { SSO_PROVIDER } from "../../constants";
import { useOktaAuth } from "@okta/okta-react";

const LogoutService = ({ location }) => {
  const { oktaAuth } = useOktaAuth();
  //url passed when user attempts to change to other client's settings
  const dispatch = useDispatch();
  const newLoginUrl = location?.state?.newLoginUrl;
  const history = useHistory();
  const clientInfo = useSelector((state) => state.global?.clientInfo);
  const token = useSelector((state) => state.auth?.token);
  const [isLoading, setLoading] = useState(true);
  const { t } = useTranslation();
  const resetAuth = () => dispatch(resetAuthInfo());

  useEffect(() => {
    loggingOff();

    return () => setLoading(true);
  }, []);

  const loggingOff = async () => {
    if (!!token) {
      resetAuth();
      const requestOption = {
        suppressErrorMsg: true,
        token: token, //make sure token passed if belong to current logged in clients
      };

      try {
        await AuthenticationService.authenticationLogout(requestOption);
        if (clientInfo?.ssoProvider === SSO_PROVIDER.Okta) {
          await oktaAuth.closeSession();
        }
      } catch (error) {
      } finally {
        setLoading(false);
        return await logout(clientInfo, history, newLoginUrl);
      }
    }

    if (!token) {
      setLoading(false);
      await logout(clientInfo, history, newLoginUrl);
      return;
    }
  };

  const loadingText = useMemo(() => {
    if (isLoading) {
      return <h1>{t("logout_waiting")}</h1>;
    }

    return (
      <>
        <h1>{t("logout_waiting")}</h1>
        <p>{t("logout_completed_description")}</p>
      </>
    );
  }, [isLoading]);

  return <LoginWrapper className="ep-logout">{loadingText}</LoginWrapper>;
};

export default LogoutService;
