import { Animated } from "react-animated-css";
import { useTranslation } from "react-i18next";
import EmployeeCreateForm from "./employee-create-form";
import { useSelector } from "react-redux";

const EmployeeCreate = () => {
  const { t } = useTranslation();
  const selectedLangKey = useSelector(
    (state) => state.language.language?.selectedLang?.key
  );
  const formLanguage = useSelector((state) => state.commonForm.formLanguage);
  return (
    <div>
      <h2 className="table-name">{t("Employee_Create_SectionName")}</h2>
      <Animated
        animationIn="slideInRight"
        animationOut="slideOutRight"
        animationInDuration={200}
        animationOutDuration={200}
        isVisible={true}
      />
      <div className="employee-create">
        <EmployeeCreateForm isLangChange={formLanguage != selectedLangKey} />
      </div>
    </div>
  );
};

export default EmployeeCreate;
