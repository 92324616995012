import { TFunction } from "react-i18next";
import {
  getBTConfig,
  getColumModel,
} from "src/components/base-table/base-table-model";
import { MyListColumns, OtherListColumns } from "./table-column-config";
import {
  CLAIMS_LIST_ACTIONS,
  CLAIMS_LIST_ACTION_TYPE,
  CLAIMS_MENU_ACTION_KEY,
} from "../../constants";
import { PortalLink } from "src/services/utils";
import { CLAIM_SUBMODULE_CODE, FUNCTION_CODE } from "src/constants";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";

const excludedClasses = [
  "action-column",
  "p-button-icon",
  "p-menu",
  "p-menuitem-text",
];

const renderConfigColumns = ({
  columns,
  t,
}: {
  columns: any[];
  t: TFunction;
}) => {
  return [
    ...columns,
    getColumModel({
      header: "",
      key: "",
      dataType: "string",
      sortable: false,
      width: 0.1,
      frozen: true,
      hideInMobile: true,
    }),
  ].map((column) => ({
    ...column,
    header: t(`${column.header}`),
  }));
};

const GetLink = (
  actionType: any,
  isAccrual: any,
  id: any,
  employeeCode: any,
  employeeName: any
) => {
  const path = `${FUNCTION_CODE.Claims}/${CLAIM_SUBMODULE_CODE.APPLICATION}/${id}`;
  const edit = `${FUNCTION_CODE.Claims}/${CLAIM_SUBMODULE_CODE.EDIT}/${id}`;

  if (actionType === "edit") {
    return PortalLink(`${edit}`);
  }
  if (actionType === "view") {
    return PortalLink(`${path}`);
  }
};

export const ListConfigModel = ({
  columns = MyListColumns,
  t,
  redirect,
  confirm,
  toolbar,
  expandedRows,
  setExpandedRows,
  selectedTabKey,
}: {
  columns?: any[];
  t: TFunction;
  redirect?: Function;
  confirm?: Function;
  toolbar?: JSX.Element;
  expandedRows?: any;
  setExpandedRows?: any;
  selectedTabKey?: any;
}) => {
  const path = `${FUNCTION_CODE.Claims}/${CLAIM_SUBMODULE_CODE.APPLICATION}`;
  const edit = `${FUNCTION_CODE.Claims}/${CLAIM_SUBMODULE_CODE.EDIT}`;

  return getBTConfig({
    columns: renderConfigColumns({ columns, t }),
    hasIndex: false,
    mode: "list",
    defaultMode: "list",
    showGlobal: true,
    hasColumnSelector: false,
    rowHover: true,
    responsive: true,
    customToolbar: toolbar,
    isExpandable: true,
    isExpandInMobileOnly: true,
    onRowClick: (e: any) => {
      if (!redirect || !e.data.id) {
        return;
      }

      const classString = e.originalEvent.target.classList.value;
      if (
        classString
          ?.split(" ")
          ?.some((_class: string) => excludedClasses.includes(_class))
      ) {
        return;
      }

      const actionType = e.data.actions?.includes(
        CLAIMS_LIST_ACTIONS.EDIT_APPLICATION
      )
        ? "edit"
        : "view";

      const path = GetLink(
        actionType,
        e.data.isAccrual,
        e.data.id,
        e.data?.employeeCode,
        e.data?.employeeName
      );

      redirect(path);
    },
    actionWidth: 5.3,
    actionsSingle: [
      {
        isOverflow: true,
        name: CLAIMS_LIST_ACTIONS.VIEW_APPLICATION,
        title: t("claims_applicationList_action_viewApplication"),
        clickFn: (data: any) =>
          redirect &&
          redirect(PortalLink(`${path}/${data.id}`), {
            selectedTabKey: data.selectedTabKey,
            fromPath: data.fromPath,
          }),
      },
      {
        // sample action
        isOverflow: true,
        name: CLAIMS_LIST_ACTIONS.VIEW_APPLICATION_DETAILS,
        title: t("claims_applicationList_action_viewApplicationDetails"),
        clickFn: (data: any) =>
          redirect && redirect(PortalLink(`${path}/${data.id}`)),
      },
      {
        isOverflow: true,
        name: CLAIMS_LIST_ACTIONS.EDIT_APPLICATION,
        title: t("claims_applicationList_action_editApplication"),
        clickFn: (data: any) =>
          redirect && redirect(PortalLink(`${edit}/${data.id}`)),
      },
      {
        isOverflow: true,
        name: CLAIMS_LIST_ACTIONS.DELETE_DRAFTS,
        title: t("claims_applicationList_action_deleteDraft"),
        clickFn: (data: any) => {
          confirm && confirm(CLAIMS_LIST_ACTION_TYPE.DELETE, data);
        },
      },
      {
        isOverflow: true,
        name: CLAIMS_LIST_ACTIONS.CANCEL_APPLICATION,
        title: t("claims_applicationList_action_cancelApplication"),
        clickFn: (data: any) => {
          confirm && confirm(CLAIMS_LIST_ACTION_TYPE.CANCEL, data);
        },
      },
    ],
    showActionsSingle: (action: any, actionsList: any) => {
      if (!action || !actionsList) return false;
      return actionsList.includes(action.title);
    },
    expandedRows: expandedRows,
    onRowToggle: (e: any) => {
      console.log(e);
      setExpandedRows(e.data);
    },
    rowExpansionTemplate: (data: any) => {
      console.log(data);
      let _data = [];
      _data.push(data);
      let columns = MyListColumns;
      if (selectedTabKey !== CLAIMS_MENU_ACTION_KEY.CLAIMS_MY_APPLICATIONS) {
        columns = OtherListColumns;
      }

      const renderTableCell = (rowData: any, column: any) => {
        if (column.render) {
          return <div className="bt-cell-render">{column.render(rowData)}</div>;
        } else {
          return rowData[column.key];
        }
      };

      const columnsRender: any[] = [];
      columns.forEach((column, index) => {
        const columnConfig = {
          key: `bt-column-${index}`,
          field: column.key,
          header: column.header,
          className: `bt-${column.key}`,
          body: (rowData: any, config: any) => {
            return (
              <div className={`bt-row bt-row-${config.rowIndex} bt-cell`}>
                <span className="p-column-title mobile-p-column-title">
                  {t(column.header as any)}:
                </span>
                <div className="bt-cell-value">
                  {renderTableCell(rowData, column)}
                </div>
              </div>
            );
          },
        };
        columnsRender.push(<Column {...columnConfig} />);
      });

      return (
        <DataTable
          key="info-table"
          value={_data}
          className="p-datatable-responsive table-expandable-header leave-application-list-mobile"
        >
          {columnsRender}
        </DataTable>
      );
    },
  } as any);
};
