import { saveAs } from "file-saver";
import ClaimService from "src/services/hrmnet-api/claims";

export async function downloadFile(file: any) {
  if (file.id != null && file.downloadAttachmentId != null) {
    const data = (await ClaimService.downloadAttachment(
      file.downloadAttachmentId,
      {
        responseType: "blob",
      } as any
    )) as any;

    if (data && data instanceof Blob) {
      var filename = file.name ? file.name : "Document";
      saveAs(data, filename);
    }
    return;
  }

  saveAs(file, file.name);
}
