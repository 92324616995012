import moment from "moment";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import TimesheetService from "../../../../../../services/hrmnet-api/timesheet";

import { useTranslation } from "react-i18next";
import {
  MESSAGE_SEVERITY,
  TIMESHEET_SUBMODULE_CODE,
} from "../../../../../../constants";
import { store } from "../../../../../../redux/store";
import {
  IActivitiesData,
  IDatesData,
  ITimesheetConfigData,
  ITimesheetLeavesData,
  SHORT_DATE_FORMAT,
  TIMESHEET_ACTIVITY_STATUS,
  TIMESHEET_MENU_PAGE_KEY,
  timesheetRoute,
} from "../../../constants";
import {
  parseAdjust,
  convertDateToUTC,
  parseAdjustTwoDay,
  parseAdjustWithoutConvert,
} from "../../../utils/date-helper";
import {
  TIMESHEET_DETAIL_BUTTON_TYPE,
  TIMESHEET_DETAIL_TYPE,
} from "../../../constants/type";
import {
  ApplicationContext,
  LoadingContext,
  ModalContext,
} from "../../../context";
import { ApplicationAction } from "../../../context/action/application";
import { LoadingAction } from "../../../context/action/loading";
import {
  transformDetailToModel,
  transformGetTimesheetResponseToDetail,
} from "../../../utils/request-helper";
import { ModalAction } from "../../../context/action/modal";
import { isResponseOk } from "../../../../../../utils/utils";
import { NElasticDataHModel } from "../../../models/timesheet-application-model";
import { openModal } from "../../../../../../redux/actions/modal";

interface IReceivedLocationState {
  periodId: number;
  type: TIMESHEET_DETAIL_TYPE;
  referenceNo: string;
}
// // Row height = CustomViewItem height 90 + border top and bottom 2
// const rowHeight = 92;

export const TimesheetDetailContextProvider: React.FC = ({ children }) => {
  const { state: routeState } = useLocation<IReceivedLocationState>();
  const { timesheetId } = useParams<any>();
  const { t } = useTranslation();
  const history = useHistory();

  // Default redux store
  const toast = store.getState().global.toast;
  const employeeCode = useSelector((state: any) => state.auth?.employeeCode);
  // modal related
  const [, modalDispatch] = useContext(ModalContext);
  const [{ application, mode }, dispatchApplicationAction] =
    useContext(ApplicationContext);
  const [{ isLoading, isSubmitting }, dispatchLoadingAction] =
    useContext(LoadingContext);

  const [availableActivities, setAvailableActivities] =
    useState<IActivitiesData>([]);
  const [calendarDates, setCalendarDates] = useState<IDatesData[]>([]);
  const [config, setConfig] = useState<ITimesheetConfigData>();
  const [leaves, setLeaves] = useState<ITimesheetLeavesData[]>([]);
  const [employeeWorkHrs, setEmployeeWorkHrs] = useState<number>(0);
  const [employeeLeaveHrs, setEmployeeLeaveHrs] = useState<number>(0);
  const [nElasticDataH, setNElasticDataH] = useState<NElasticDataHModel>();
  const dispatch = useDispatch();

  const isTimesheetOwner = employeeCode === application?.employeeCode;

  const setLoading = useCallback(
    (isLoading: boolean) => {
      dispatchLoadingAction({
        type: LoadingAction.SET_LOADING,
        payload: isLoading,
      });
    },
    [dispatchLoadingAction]
  );

  const setSubmitting = useCallback(
    (isSubmitting: boolean) => {
      dispatchLoadingAction({
        type: LoadingAction.SET_SUBMITTING,
        payload: isSubmitting,
      });
    },
    [dispatchLoadingAction]
  );

  const fetchData = useCallback(async () => {
    setLoading(true);

    try {
      if (routeState?.type) {
        dispatchApplicationAction!({
          type: ApplicationAction.SET_TIMESHEET_DETAIL_TYPE,
          payload: {
            mode: routeState?.type,
          },
        });
      } else {
        let modeFromRoute: TIMESHEET_DETAIL_TYPE = TIMESHEET_DETAIL_TYPE.VIEW;
        if (
          history.location.pathname?.endsWith(TIMESHEET_SUBMODULE_CODE.AMEND)
        ) {
          modeFromRoute = TIMESHEET_DETAIL_TYPE.AMEND;
        } else if (
          history.location.pathname?.endsWith(TIMESHEET_SUBMODULE_CODE.APPROVAL)
        ) {
          modeFromRoute = TIMESHEET_DETAIL_TYPE.APPROVAL;
        } else if (
          history.location.pathname?.endsWith(TIMESHEET_SUBMODULE_CODE.EDIT)
        ) {
          modeFromRoute = TIMESHEET_DETAIL_TYPE.EDIT;
        } else if (
          history.location.pathname?.endsWith(TIMESHEET_SUBMODULE_CODE.SUBMIT)
        ) {
          modeFromRoute = TIMESHEET_DETAIL_TYPE.SUBMIT;
        } else if (
          history.location.pathname?.endsWith(TIMESHEET_SUBMODULE_CODE.VIEW)
        ) {
          modeFromRoute = TIMESHEET_DETAIL_TYPE.VIEW;
        }
        dispatchApplicationAction!({
          type: ApplicationAction.SET_TIMESHEET_DETAIL_TYPE,
          payload: {
            mode: modeFromRoute,
          },
        });
      }

      if (!routeState?.periodId && !timesheetId) {
        history.push(timesheetRoute.application);
        return;
      }

      let period;
      let applicationDetail;

      if (!timesheetId) {
        const getPeriodDetailResponse = await TimesheetService.getPeriodDetail({
          params: { timetableId: routeState?.periodId },
        });

        if (!getPeriodDetailResponse?.data) {
          history.push(timesheetRoute.application);
        }

        period = getPeriodDetailResponse?.data;
      } else {
        const getApplicationResponse = await TimesheetService.getApplication({
          data: { id: timesheetId },
        });

        if (!getApplicationResponse?.data) {
          history.push(timesheetRoute.application);
        }

        applicationDetail = getApplicationResponse?.data;
      }
      setNElasticDataH(applicationDetail?.nElasticDataH);

      const getAvailableActivitiesResponse =
        await TimesheetService.getAvailableActivities({
          params: {
            employeeCode:
              applicationDetail?.employeeCode ||
              application?.employeeCode ||
              employeeCode,
            timetableId: (period?.id ||
              applicationDetail?.period?.id) as number,
          },
        });
      setAvailableActivities(getAvailableActivitiesResponse.data);

      const getLeavesResponse = await TimesheetService.getLeaves({
        data: {
          employeeCode:
            applicationDetail?.employeeCode ||
            application?.employeeCode ||
            employeeCode,
          timetableId: period?.id || applicationDetail?.period?.id,
        },
      });
      setLeaves(
        getLeavesResponse?.data?.map((leave) => ({
          ...leave,
          type: "leave",
          testDate: new Date(leave.from),
          actualStart: new Date(leave.from),
          actualEnd: new Date(leave.to),
          resourceDate: moment(new Date(leave.from)).format("YYYY-MM-DD"),
          end: parseAdjustWithoutConvert(new Date(leave.to)),
          start: parseAdjustWithoutConvert(new Date(leave.from)),
          hasConflict: false,
        }))
      );

      const getWorkHoursResponse = await TimesheetService.getWorkHours({
        data: {
          employeeCode:
            applicationDetail?.employeeCode ||
            application?.employeeCode ||
            employeeCode,
          timetableId: period?.id || applicationDetail?.period?.id,
        },
      });
      setEmployeeWorkHrs(getWorkHoursResponse?.data);

      const getLeaveHoursResponse = await TimesheetService.getLeaveHours({
        data: {
          employeeCode:
            applicationDetail?.employeeCode ||
            application?.employeeCode ||
            employeeCode,
          timetableId: period?.id || applicationDetail?.period?.id,
        },
      });
      setEmployeeLeaveHrs(getLeaveHoursResponse?.data);

      const getConfigResponse = await TimesheetService.getTimesheetConfig({
        params: {
          employeeCode:
            applicationDetail?.employeeCode ||
            application?.employeeCode ||
            employeeCode,
        },
      });
      setConfig(getConfigResponse?.data);

      let allowOnLeaveApplied =
        getConfigResponse?.data.dailyCheckboxConfig?.allowOnLeaveApplied;

      const getDatesResponse = await TimesheetService.getDates({
        params: {
          employeeCode:
            applicationDetail?.employeeCode ||
            application?.employeeCode ||
            employeeCode,
          startDate: moment(
            new Date(
              period?.startDate || applicationDetail?.period?.startDate || ""
            )
          ).format(SHORT_DATE_FORMAT),
          endDate: moment(
            new Date(
              period?.endDate || applicationDetail?.period?.endDate || ""
            )
          ).format(SHORT_DATE_FORMAT),
          leaveApplyInfo: !allowOnLeaveApplied,
        },
      });
      setCalendarDates(getDatesResponse?.data);

      if (applicationDetail) {
        // Has existing TrnsH
        const applicationPayload =
          transformGetTimesheetResponseToDetail(applicationDetail);
        dispatchApplicationAction({
          type: ApplicationAction.SET_APPLICATION,
          payload: {
            application: applicationPayload,
          },
        });
      } else {
        // No existing TrnsH
        dispatchApplicationAction({
          type: ApplicationAction.SET_APPLICATION,
          payload: {
            application: {
              periodId: routeState?.periodId,
              period,
              timesheetId,
              totalHours: 0,
              totalAppliedHours: 0,
              employeeName: application?.employeeName,
              employeeCode: application?.employeeCode || employeeCode,
              approverName: undefined,
              activities: [],
              nElasticDataH: undefined,
            },
          },
        });
      }
    } catch (e) {
      toast.show({
        severity: MESSAGE_SEVERITY.ERROR,
        summary: t("misc_axios_notification"),
        detail: e,
        life: 3000,
        sticky: false,
      });
    } finally {
      setLoading(false);
    }
  }, [
    application?.employeeCode,
    application?.employeeName,
    dispatchApplicationAction,
    employeeCode,
    history,
    routeState?.periodId,
    routeState?.type,
    setLoading,
    t,
    timesheetId,
    toast,
  ]);

  useEffect(() => {
    (async () => {
      await fetchData();
    })();
  }, []);

  const { periodFrom, periodTo, periodFromDate, periodToDate, periodId } =
    useMemo(() => {
      // TODO: Add case when there is no application
      if (!application?.period) {
        return {
          periodFrom: undefined,
          periodTo: undefined,
          periodFromDate: undefined,
          periodToDate: undefined,
          periodId: undefined,
        };
      }
      return {
        periodFrom: moment(new Date(application?.period?.startDate)).format(
          "DD MMM"
        ),
        periodTo: moment(
          new Date(application?.period?.endDate),
          "DD-MM-YYYY"
        ).format("DD MMM YYYY"),
        periodFromDate: moment(
          new Date(application?.period?.startDate),
          "DD-MM-YYYY"
        ).format("YYYY-MM-DD"),
        periodToDate: moment(
          new Date(application?.period?.endDate),
          "DD-MM-YYYY"
        ).format("YYYY-MM-DD"),
        periodId: routeState?.periodId || application?.period.id,
      };
    }, [application?.period, routeState?.periodId]);

  // id must be existed because it is the `key` of react when kendo scheduler render
  // if no id then will have some bug for rendering
  const activityList = useMemo(() => {
    let count = 0;
    const activities = application.activities.reduce((acc: any[], act) => {
      // AvailableActivities is the list of activities of the timesheet owner
      // Will be undefined if the viewer is hr
      const targetActivity = availableActivities.find(
        (a) => a.value === `${act.activityId}`
      );
      count += act.appliedUnits;

      const startTime = (act as any).from
        ? (act as any).from + "Z"
        : `${moment(act.fromDate).format("YYYY-MM-DD")}T${moment(
            act.fromTime
          ).format("HH:mm:ss")}Z`;
      const endTime = (act as any).to
        ? (act as any).to + "Z"
        : `${moment(act.toDate).format("YYYY-MM-DD")}T${moment(
            act.toTime
          ).format("HH:mm:ss")}Z`;

      const isSameDay = moment(act.fromDate).isSame(moment(act.toDate), "day");

      if (!isSameDay) {
        const [splitedDate1, splitedDate2] = parseAdjustTwoDay(
          startTime,
          endTime
        );
        return [
          ...acc,
          {
            actualStart: convertDateToUTC(new Date(startTime)),
            actualEnd: convertDateToUTC(new Date(endTime)),
            description: targetActivity?.description,
            end: splitedDate1.end,
            id: act.id || (act as any)?.uuid,
            remarks: act.remarks,
            aprvRemark: act.aprvRemark,
            resourceDate: moment(convertDateToUTC(new Date(startTime))).format(
              "YYYY-MM-DD"
            ),
            start: splitedDate1.start,
            status: act.status,
            title: targetActivity?.name || act?.activityCode?.toString(),
            // UUID is for editing newly add activity
            uuid: (act as any)?.uuid,
            isSameDay,
            type: "activity",
            hasConflict: false,
            totalUnits: count,
            excludeMealTime: act.excludeMealTime,
          },
          {
            actualStart: convertDateToUTC(new Date(startTime)),
            actualEnd: convertDateToUTC(new Date(endTime)),
            description: targetActivity?.description,
            end: splitedDate2.end,
            id: act.id || (act as any)?.uuid,
            remarks: act.remarks,
            aprvRemark: act.aprvRemark,
            resourceDate: moment(
              convertDateToUTC(moment(startTime).add(1, "d").toDate())
            ).format("YYYY-MM-DD"),
            start: splitedDate2.start,
            status: act.status,
            title: targetActivity?.name || act?.activityCode?.toString(),
            // UUID is for editing newly add activity
            uuid: (act as any)?.uuid,
            isSameDay,
            type: "activity",
            hasConflict: false,
            totalUnits: count,
            excludeMealTime: act.excludeMealTime,
          },
        ];
      }

      return [
        ...acc,
        {
          actualStart: convertDateToUTC(new Date(startTime)),
          actualEnd: convertDateToUTC(new Date(endTime)),
          description: targetActivity?.description,
          end: parseAdjust(endTime, isSameDay),
          id: act.id || (act as any)?.uuid,
          remarks: act.remarks,
          aprvRemark: act.aprvRemark,
          resourceDate: moment(convertDateToUTC(new Date(startTime))).format(
            "YYYY-MM-DD"
          ),
          start: parseAdjust(startTime),
          status: act.status,
          title: targetActivity?.name || act?.activityCode?.toString(),
          // UUID is for editing newly add activity
          uuid: (act as any)?.uuid,
          isSameDay,
          type: "activity",
          hasConflict: false,
          totalUnits: count,
          excludeMealTime: act.excludeMealTime,
        },
      ];
    }, []);
    return activities;
  }, [application.activities, availableActivities]);

  const timetableBlocks = useMemo(() => {
    const concatenatedList = [...activityList, ...leaves];

    if (!!concatenatedList?.length) {
      concatenatedList
        .sort((block1) => {
          return block1.type === "leave" ? -1 : 1;
        })
        .sort((block1, block2) => {
          return moment(block1["actualStart"]).isBefore(
            moment(block2["actualStart"])
          )
            ? -1
            : 1;
        })
        .forEach((block, i) => {
          block.order = i;
        });

      /*
       * Check if the blocks have conflicts,
       * indicate by the flag hasConflict
       */
      for (let i = 0; i < concatenatedList.length - 1; i++) {
        const currentStartTime = concatenatedList[i]["actualStart"];
        const currentEndTime = concatenatedList[i]["actualEnd"];

        let hasConflict = false;
        concatenatedList.forEach((block) => {
          if (block.order !== concatenatedList[i].order) {
            const nextStartTime = block["actualStart"];
            const nextEndTime = block["actualEnd"];

            if (
              currentStartTime < nextEndTime &&
              nextStartTime < currentEndTime
            ) {
              hasConflict = true;
              block.hasConflict = true;
            }
          }
        });
        concatenatedList[i].hasConflict = hasConflict;
      }
    }
    /* Sort activity before leave, as it affects the layout */
    concatenatedList.sort((a, b) => (a?.type === "activity" ? -1 : 1));

    return concatenatedList;
  }, [activityList, leaves]);

  const onBackButtonClick = () => {
    if (history.location.pathname.search(timesheetRoute.application) > -1) {
      history.push(timesheetRoute.application);
      return;
    } else if (
      history.location.pathname.search(timesheetRoute.approvalCorner) > -1
    ) {
      history.push(timesheetRoute.approvalCorner);
      return;
    } else {
      history.push(timesheetRoute.list);
      return;
    }
  };

  const onViewSave = useCallback(
    async (buttonType: TIMESHEET_DETAIL_BUTTON_TYPE) => {
      setSubmitting(true);
      const data = transformDetailToModel(application);
      try {
        let res: any;
        switch (buttonType) {
          case TIMESHEET_DETAIL_BUTTON_TYPE.DRAFT:
            res = await TimesheetService.saveDraft({ data });
            break;
          case TIMESHEET_DETAIL_BUTTON_TYPE.SUBMIT:
            res = await TimesheetService.submitTimesheet({ data });
            break;
          case TIMESHEET_DETAIL_BUTTON_TYPE["EDIT_DRAFT"]:
            res = await TimesheetService.editTimesheet({
              data: {
                ...data,
                activities: data.activities,
                id: Number(timesheetId),
                periodId,
              },
            });
            break;
          case TIMESHEET_DETAIL_BUTTON_TYPE.SUBMIT_DRAFT:
            res = await TimesheetService.submitDraft({
              data: {
                ...data,
                activities: data.activities,
                id: Number(timesheetId),
                periodId,
              },
            });
            break;

          case TIMESHEET_DETAIL_BUTTON_TYPE.APPROVE:
            res = await TimesheetService.approveTimesheet({
              data: {
                id: Number(timesheetId),
                comment: "",
              },
            });
            break;
          case TIMESHEET_DETAIL_BUTTON_TYPE.RETURN:
            res = await TimesheetService.returnTimesheet({
              data: {
                id: Number(timesheetId),
                comment: "",
              },
            });
            break;
          case TIMESHEET_DETAIL_BUTTON_TYPE.HR_AMEND:
            res = await TimesheetService.hrAmendTimesheet({
              data: {
                ...data,
                id: Number(timesheetId),
                periodId: Number(data.periodId),
              },
            });
            break;
          default:
            res = await TimesheetService.saveDraft({ data });
            break;
        }
        if (!isResponseOk(res)) {
          return;
        }

        toast.show({
          severity: MESSAGE_SEVERITY.SUCCESS,
          summary: t("misc_axios_notification"),
          detail: "Action Success!",
          life: 3000,
          sticky: false,
        });
        dispatchApplicationAction!({
          type: ApplicationAction.RESET_APPLICATION,
        });
        window.location.replace("/timesheet");
      } catch (err) {
        console.debug(err);
      } finally {
        setSubmitting(false);
      }
    },
    [
      setSubmitting,
      application,
      toast,
      t,
      dispatchApplicationAction,
      timesheetId,
      periodId,
    ]
  );

  // when submit it is 0, if not submitting it will return from backend, more accurate then calculation
  const totalHrs = useMemo(
    () =>
      activityList.reduce((acc, current) => {
        const duration = current.totalUnits;
        return duration;
      }, 0),
    [activityList]
  );

  const onClickAddTime = useCallback(() => {
    modalDispatch({
      type: ModalAction.SET_ACTIVITY_MODAL_DETAILS,
      payload: {
        activityModalDetails: {
          periodId: periodId as number,
          period: application?.period?.desc as string,
          isFromView: true,
          isEdit:
            mode === TIMESHEET_DETAIL_TYPE.EDIT ||
            mode === TIMESHEET_DETAIL_TYPE.AMEND ||
            mode === TIMESHEET_DETAIL_TYPE.HR_AMEND,
        },
      },
    });
    modalDispatch({
      type: ModalAction.SET_IS_SHOW_ACTIVITY_MODAL,
      payload: true,
    });
  }, [modalDispatch, periodId, application?.period?.desc, mode]);

  const headerTitleKey = useMemo(() => {
    switch (mode) {
      case TIMESHEET_DETAIL_TYPE.SUBMIT:
        return TIMESHEET_MENU_PAGE_KEY.SUBMIT_TIMESHEET;
      case TIMESHEET_DETAIL_TYPE.EDIT:
        return TIMESHEET_MENU_PAGE_KEY.EDIT_TIMESHEET;
      case TIMESHEET_DETAIL_TYPE.AMEND:
      case TIMESHEET_DETAIL_TYPE.HR_AMEND:
        return TIMESHEET_MENU_PAGE_KEY.AMEND_TIMESHEET;
      case TIMESHEET_DETAIL_TYPE.APPROVAL:
        return TIMESHEET_MENU_PAGE_KEY.APPROVE_TIMESHEET;
      case TIMESHEET_DETAIL_TYPE.VIEW:
      default:
        return TIMESHEET_MENU_PAGE_KEY.VIEW_TIMESHEET;
    }
  }, [mode]);

  const onPrimaryButtonClick = useCallback(() => {
    switch (mode) {
      case TIMESHEET_DETAIL_TYPE.HR_AMEND:
        onViewSave(TIMESHEET_DETAIL_BUTTON_TYPE.HR_AMEND);
        break;
      case TIMESHEET_DETAIL_TYPE.AMEND:
      case TIMESHEET_DETAIL_TYPE.EDIT:
        onViewSave(TIMESHEET_DETAIL_BUTTON_TYPE.SUBMIT_DRAFT);
        break;
      case TIMESHEET_DETAIL_TYPE.SUBMIT:
        onViewSave(TIMESHEET_DETAIL_BUTTON_TYPE.SUBMIT);
        break;
      case TIMESHEET_DETAIL_TYPE.APPROVAL:
        dispatch(
          openModal({
            title: t("timesheet_common_actionConfirmation"),
            content: t("timesheet_approval_confirmApproveTimesheet"),
            classNameMainDialog: "confirm-message-modal",
            primaryButtonText: t("timesheet_common_actionConfirm"),
            primaryButtonClickFn: async ({ closeFn }: any) => {
              closeFn();
              onViewSave(TIMESHEET_DETAIL_BUTTON_TYPE.APPROVE);
            },
            secondButtonClickFn: ({ closeFn }: any) => {
              closeFn();
            },
          })
        );
        break;
      case TIMESHEET_DETAIL_TYPE.VIEW:
      default:
        break;
    }
  }, [onViewSave, dispatch, mode]);

  const onSecondaryButtonClick = useCallback(() => {
    switch (mode) {
      case TIMESHEET_DETAIL_TYPE.AMEND:
      case TIMESHEET_DETAIL_TYPE.EDIT:
        onViewSave(TIMESHEET_DETAIL_BUTTON_TYPE.EDIT_DRAFT);
        break;
      case TIMESHEET_DETAIL_TYPE.APPROVAL:
        dispatch(
          openModal({
            title: t("timesheet_common_actionConfirmation"),
            content: t("timesheet_approval_confirmReturnTimesheet"),
            classNameMainDialog: "confirm-message-modal",
            primaryButtonText: t("timesheet_common_actionConfirm"),
            primaryButtonClickFn: async ({ closeFn }: any) => {
              closeFn();
              onViewSave(TIMESHEET_DETAIL_BUTTON_TYPE.RETURN);
            },
            secondButtonClickFn: ({ closeFn }: any) => {
              closeFn();
            },
          })
        );
        break;
      case TIMESHEET_DETAIL_TYPE.SUBMIT:
        onViewSave(TIMESHEET_DETAIL_BUTTON_TYPE.DRAFT);
        break;
      case TIMESHEET_DETAIL_TYPE.HR_AMEND:
      case TIMESHEET_DETAIL_TYPE.VIEW:
      default:
        break;
    }
  }, [onViewSave, dispatch, mode]);

  const primaryButtonLabel = useMemo(() => {
    switch (mode) {
      case TIMESHEET_DETAIL_TYPE.APPROVAL:
        return "timesheet_common_actionApproveTimesheet";
      case TIMESHEET_DETAIL_TYPE.AMEND:
      case TIMESHEET_DETAIL_TYPE.HR_AMEND:
      case TIMESHEET_DETAIL_TYPE.EDIT:
      case TIMESHEET_DETAIL_TYPE.SUBMIT:
        return "timesheet_common_actionSubmit";
      case TIMESHEET_DETAIL_TYPE.VIEW:
      default:
        return "";
    }
  }, [mode]);

  const secondaryButtonLabel = useMemo(() => {
    switch (mode) {
      case TIMESHEET_DETAIL_TYPE.APPROVAL:
        return "timesheet_common_actionReturnTimesheet";
      case TIMESHEET_DETAIL_TYPE.AMEND:
        return "timesheet_common_actionSave";
      case TIMESHEET_DETAIL_TYPE.EDIT:
      case TIMESHEET_DETAIL_TYPE.SUBMIT:
        return "timesheet_common_actionSaveDraft";
      case TIMESHEET_DETAIL_TYPE.HR_AMEND:
      case TIMESHEET_DETAIL_TYPE.VIEW:
      default:
        return "";
    }
  }, [mode]);

  const isPrimaryButtonDisabled = useMemo(() => {
    switch (mode) {
      case TIMESHEET_DETAIL_TYPE.APPROVAL:
        return (
          application.activities.filter(
            (activity) => activity.status !== TIMESHEET_ACTIVITY_STATUS.APPROVED
          )?.length > 0 ||
          isLoading ||
          isSubmitting
        );
      case TIMESHEET_DETAIL_TYPE.AMEND:
        return (
          application.activities?.length === 0 ||
          application.activities?.some(
            (act) => act.status === TIMESHEET_ACTIVITY_STATUS.REJECTED
          ) ||
          isLoading ||
          isSubmitting
        );
      case TIMESHEET_DETAIL_TYPE.HR_AMEND:
      case TIMESHEET_DETAIL_TYPE.EDIT:
      case TIMESHEET_DETAIL_TYPE.SUBMIT:
      case TIMESHEET_DETAIL_TYPE.VIEW:
      default:
        return (
          application.activities?.length === 0 || isLoading || isSubmitting
        );
    }
  }, [application.activities, isLoading, isSubmitting, mode]);

  const isSecondaryButtonDisabled = useMemo(() => {
    switch (mode) {
      case TIMESHEET_DETAIL_TYPE.APPROVAL:
        return (
          application.activities.every(
            (activity) => activity.status === TIMESHEET_ACTIVITY_STATUS.APPROVED
          ) ||
          isLoading ||
          isSubmitting
        );
      case TIMESHEET_DETAIL_TYPE.AMEND:
      case TIMESHEET_DETAIL_TYPE.HR_AMEND:
      case TIMESHEET_DETAIL_TYPE.EDIT:
      case TIMESHEET_DETAIL_TYPE.SUBMIT:
      case TIMESHEET_DETAIL_TYPE.VIEW:
      default:
        return (
          application.activities?.length === 0 || isLoading || isSubmitting
        );
    }
  }, [application.activities?.length, isLoading, isSubmitting, mode]);

  const isHideSecondaryButton = useMemo(
    () =>
      mode === TIMESHEET_DETAIL_TYPE.VIEW ||
      mode === TIMESHEET_DETAIL_TYPE.HR_AMEND,
    [mode]
  );

  const body = document.body; // <body> tag
  const totalColumn = document.getElementsByClassName(
    "k-scheduler-layout k-scheduler-layout-flex k-scheduler-timeline-view total-column"
  );
  const schedulerLayout = document.querySelector(
    `.k-scheduler-layout, [role="presentation"]`
  );
  const disableScroll = useCallback(() => {
    body.style.height = "100%";
    body.style.overflow = "hidden";
    (schedulerLayout as HTMLElement).style.height = "100%";
    (schedulerLayout as HTMLElement).style.overflow = "hidden";
    (totalColumn[0] as HTMLElement).style.overflow = "hidden";
  }, [body.style, schedulerLayout, totalColumn]);
  const enableScroll = useCallback(() => {
    body.style.height = "auto";
    body.style.overflow = "auto";
    (schedulerLayout as HTMLElement).style.height = "auto";
    (schedulerLayout as HTMLElement).style.overflow = "auto";
    (totalColumn[0] as HTMLElement).style.overflow = "auto";
  }, [body.style, schedulerLayout, totalColumn]);

  return (
    <TimesheetDetailContext.Provider
      value={{
        activityList,
        employeeWorkHrs,
        employeeLeaveHrs,
        fetchData,
        headerTitleKey,
        isPrimaryButtonDisabled,
        isSecondaryButtonDisabled,
        isHideSecondaryButton,
        isTimesheetOwner,
        leaves,
        mode,
        onBackButtonClick,
        onClickAddTime,
        onPrimaryButtonClick,
        onSecondaryButtonClick,
        periodFrom,
        periodTo,
        periodFromDate,
        periodToDate,
        primaryButtonLabel,
        secondaryButtonLabel,
        timesheetId,
        timetableBlocks,
        totalHrs,
        calendarDates,
        config,
        nElasticDataH,
        disableScroll,
        enableScroll,
      }}
    >
      {children}
    </TimesheetDetailContext.Provider>
  );
};

export const TimesheetDetailContext = React.createContext<{
  activityList?: any;
  employeeWorkHrs?: any;
  employeeLeaveHrs?: any;
  fetchData?: any;
  form?: any;
  headerTitleKey?: any;
  isPrimaryButtonDisabled?: any;
  isSecondaryButtonDisabled?: any;
  isHideSecondaryButton?: any;
  isTimesheetOwner?: any;
  leaves?: any;
  mode?: any;
  onBackButtonClick?: any;
  onClickAddTime?: any;
  onPrimaryButtonClick?: any;
  onSecondaryButtonClick?: any;
  periodFrom?: any;
  periodTo?: any;
  periodFromDate?: any;
  periodToDate?: any;
  primaryButtonLabel?: any;
  secondaryButtonLabel?: any;
  timesheetId?: any;
  timetableBlocks?: any;
  totalHrs?: any;
  calendarDates?: any;
  config?: any;
  nElasticDataH?: any;
  disableScroll?: any;
  enableScroll?: any;
}>({});

export const useTimesheetDetailContext = () =>
  useContext(TimesheetDetailContext);
