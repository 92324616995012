import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { DEFAULT_BT_SEARCH } from "src/components/base-table/base-table-model";
import BaseTable from "src/components/base-table/base-table-portal-rental";
import LanguageLoader from "src/components/language-loader/language-loader";
import { EEPORTAL_LABEL_BASE_TABLE } from "src/constants";
import { HeaderMenu } from "../../components/header-menu";
import {
  CLAIMS_LIST_ACTION_TYPE,
  CLAIMS_MENU_ACTION_KEY,
  CLAIMS_MENU_MODULE_KEY,
  CLAIMS_MENU_PAGE_KEY,
  CLAIMS_T_CONTEXT_KEY,
} from "../../constants";
import { ListConfigModel } from "./table-config";

import moment from "moment";
import { Dropdown } from "primereact/dropdown";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { BCType } from "src/components/base-control/base-control";
import BaseTabScreen from "src/components/base-tab-screen2/base-tab-screen";
import { openModal } from "src/redux/actions/modal";
import { showSpinner } from "src/redux/actions/spinner";
import ClaimService, {
  IAcceptedApplicationTypes,
} from "src/services/hrmnet-api/claims";
import { showTimestampToastSuccess } from "src/services/utils/message";
import { isResponseOk } from "src/utils/utils";
import "./history.scss";
import {
  AllApplcations,
  DelegatedApplcations,
  MyApplcations,
} from "./tab-config";
import { MyListColumns, OtherListColumns } from "./table-column-config";

const Header = () => {
  const { t } = useTranslation();

  return (
    <div className="header">
      <HeaderMenu
        menuKey={CLAIMS_MENU_MODULE_KEY}
        pageKey={CLAIMS_MENU_PAGE_KEY.CLAIM_APPLICATIONS}
      />

      <div className="title">{t("claims_applicationList_title")}</div>
    </div>
  );
};

const Main = ({ tabConfig }: any) => {
  const { t } = useTranslation();

  const tabs = tabConfig.map((tab: any, index: number) => ({
    ...tab,
    value: index,
    name: t(tab.name),
    description: t(tab.description),
  }));

  const location = useLocation();
  const history = useHistory();
  const { state, pathname } = location;

  const [filterYear, setFilterYear] = useState(null);
  const [filterYears, setFilterYears] = useState([]);
  const [filterYearsObject, setFilterYearsObject] = useState({});
  const [selectedTabKey, setSelectedTabKey] = useState(tabs[0].key);

  useEffect(() => {
    if (state && state.selectedTabKey) {
      setSelectedTabKey(state.selectedTabKey);
      history.replace(pathname, {});
    }
  }, [state, pathname]);

  useEffect(() => {
    (async () => {
      const currentTab = tabConfig.map((config: any) => {
        if (config.key === CLAIMS_MENU_ACTION_KEY.CLAIMS_MY_APPLICATIONS) {
          return "myapplication";
        } else if (
          config.key === CLAIMS_MENU_ACTION_KEY.CLAIMS_DELEGATED_APPLICATIONS
        ) {
          return "delegated";
        } else {
          return "hr";
        }
      });

      const yearsResponse = await Promise.all(
        (currentTab as IAcceptedApplicationTypes[]).map(async (type) => {
          return ClaimService.getFilterYears({}, type);
        })
      );
      const filterYearsObject = tabConfig.reduce(
        (acc: any, current: any, index: number) => {
          return {
            ...acc,
            [current.key]: yearsResponse[index],
          };
        },
        {}
      );
      setFilterYearsObject(filterYearsObject);

      setFilterYear(
        filterYearsObject[selectedTabKey].data?.find((item: any) => !!item)
          ?.value
      );
      setFilterYears(
        filterYearsObject[selectedTabKey]?.data?.map((item: any) => ({
          label: item.name,
          value: item.value,
        }))
      );
    })();
  }, []);

  const ToolBar = () => {
    return (
      <div className="bt-toolbar-content">
        <b>{t("claims_applicationList_currentYear")}: </b>
        <div className="filter-year-container">
          <Dropdown
            value={filterYear}
            options={filterYears}
            onChange={(e) => {
              setFilterYear(e.value);
            }}
            disabled={Object.keys(filterYearsObject).length === 0}
          />
        </div>
      </div>
    );
  };

  return (
    <BaseTabScreen
      onChange={(value) => {
        const selectedTab = tabs.find((tab: any) => tab.value === value);
        if (!!selectedTab) {
          setSelectedTabKey(selectedTab.key);
          setFilterYear(
            (filterYearsObject as any)[selectedTab.key]?.data?.find(
              (item: any) => !!item
            )?.value
          );
          setFilterYears(
            (filterYearsObject as any)[selectedTab.key]?.data?.map(
              (item: any) => ({
                label: item.name,
                value: item.value,
              })
            )
          );
        }
      }}
      displayNotFound={false}
      menuKey={CLAIMS_MENU_PAGE_KEY.VIEW_ALL_APPLICATIONS}
      defaultActionKey={selectedTabKey}
      customMenuModules={tabs}
    >
      <div className="main">
        <List
          key={selectedTabKey}
          selectedTabKey={selectedTabKey}
          year={filterYear}
          toolbar={<ToolBar />}
        />
      </div>
    </BaseTabScreen>
  );
};

const getDataSource = (key: string) => {
  switch (key) {
    case CLAIMS_MENU_ACTION_KEY.CLAIMS_DELEGATED_APPLICATIONS:
      return ClaimService.getDelegatedApplications;

    case CLAIMS_MENU_ACTION_KEY.CLAIMS_ALL_APPLICATIONS:
      return ClaimService.getAllApplications;

    default:
      return ClaimService.getApplications;
  }
};

const getColumns = (key: string) => {
  switch (key) {
    case CLAIMS_MENU_ACTION_KEY.CLAIMS_DELEGATED_APPLICATIONS:
    case CLAIMS_MENU_ACTION_KEY.CLAIMS_ALL_APPLICATIONS:
      return OtherListColumns;

    default:
      return MyListColumns;
  }
};

const List = ({ selectedTabKey, year, toolbar }: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const tableRef = useRef();
  const history = useHistory() as any;
  const [tableConfig, setTableConfig] = useState<any>(null);
  const [expandedRows, setExpandedRows] = useState(null);
  const location = useLocation();

  const searchConfig = useMemo(
    () => ({
      ...DEFAULT_BT_SEARCH,
      sortObj: {
        key: "lastUpdated",
        direction: -1,
      },
    }),
    []
  );

  const onSuccess = useCallback(() => {
    showTimestampToastSuccess({
      message: "Success",
      t,
    } as any);
  }, [t]);

  const onDelete = useCallback(
    async (data) => {
      dispatch(
        openModal({
          title: t("claims_applicationList_deleteModalTitle"),
          content: t("claims_applicationList_deleteModalContent", {
            date: moment(data.from).format("YYYY-MM-DD"),
          }),
          classNameMainDialog: "confirm-message-modal form-modal",
          primaryButtonText: t("claims_common_actionYes"),
          secondaryButtonText: t("claims_common_actionNo"),
          primaryButtonClickFn: async ({ closeFn }: any) => {
            try {
              dispatch(showSpinner(true));
              const response = await ClaimService.deleteDraft(data.id);
              if (!isResponseOk(response)) {
                return;
              }
              onSuccess();
            } finally {
              closeFn();
              if (tableRef.current) {
                (tableRef.current as any).reload();
              }
              dispatch(showSpinner(false));
            }
          },
          secondButtonClickFn: ({ closeFn }: any) => {
            closeFn();
          },
          force_touched: true,
        })
      );
    },
    [onSuccess, dispatch, openModal]
  );

  const onConfirmCancel = useCallback(
    async (id, comment) => {
      try {
        dispatch(showSpinner(true));
        const response = await ClaimService.cancel(id, { comment });
        if (!isResponseOk(response)) {
          return;
        }
        onSuccess();
      } finally {
        if (tableRef.current) {
          (tableRef.current as any).reload();
        }
        dispatch(showSpinner(false));
      }
    },
    [dispatch, onSuccess]
  );

  const onCancel = useCallback(
    async (data) => {
      dispatch(
        openModal({
          title: t("claims_viewApplication_cancelTitle"),
          content: t("claims_viewApplication_cancelMessage", {
            date: moment(data.from).format("YYYY-MM-DD"),
          }),
          classNameMainDialog: "confirm-message-modal form-modal",
          primaryButtonText: t("claims_common_actionYes"),
          secondaryButtonText: t("claims_common_actionNo"),
          form: {
            config: {
              controls: [
                {
                  key: "Remarks",
                  label: t("claims_viewApplication_cancelReason"),
                  type: BCType.input,
                },
              ],
            },
            form: {},
          },
          primaryButtonClickFn: async ({ closeFn, form }: any) => {
            await onConfirmCancel(data.id, form.Remarks);
            closeFn();
          },
          secondButtonClickFn: ({ closeFn }: any) => {
            closeFn();
          },
          force_touched: true,
        })
      );
    },
    [dispatch, onConfirmCancel, t]
  );

  useEffect(() => {
    const tableGetData = async () => {
      if (!year) {
        return {
          datas: [],
          searchConfig: searchConfig,
          total: 0,
        };
      }
      const datasource = getDataSource(selectedTabKey);
      const response = (await datasource({
        year,
      })) as any;

      return {
        datas: response?.data.map((d: any) => ({
          ...d,
          selectedTabKey,
          fromPath: location.pathname,
        })),
        searchConfig: searchConfig,
        total: response?.data?.length,
      };
    };

    const onConfirm = async (type: string, data: any) => {
      if (type === CLAIMS_LIST_ACTION_TYPE.CANCEL) {
        await onCancel(data);
        return;
      }

      if (type === CLAIMS_LIST_ACTION_TYPE.DELETE) {
        await onDelete(data);
        return;
      }
    };

    setTableConfig({
      key: `${selectedTabKey} - ${year}`,
      isClientSize: true,
      configModel: ListConfigModel({
        t,
        toolbar: toolbar,
        columns: getColumns(selectedTabKey),
        redirect: history.push,
        confirm: onConfirm,
        expandedRows,
        setExpandedRows,
        selectedTabKey,
      }),
      searchConfig: searchConfig,
      searchFn: tableGetData,
    });
  }, [year, t, toolbar, selectedTabKey, expandedRows, setExpandedRows]);

  return (
    <div className="claim-application-list-datatable">
      {tableConfig && <BaseTable ref={tableRef} {...tableConfig} />}
    </div>
  );
};

const ClaimApplicationList = () => {
  const [tabs, setTabs] = useState<any>([]);
  useEffect(() => {
    (async () => {
      const response = (await ClaimService.getApplicationsTabs()) as any;
      if (response.data) {
        const { delegation, hrView } = response.data;
        setTabs(
          [
            MyApplcations,
            delegation ? DelegatedApplcations : null,
            hrView ? AllApplcations : null,
          ].filter((tab) => !!tab)
        );
      }
    })();
  }, []);

  return (
    <>
      <LanguageLoader
        contexts={[
          EEPORTAL_LABEL_BASE_TABLE.BASE_TABLE,
          CLAIMS_T_CONTEXT_KEY.COMMON,
          CLAIMS_T_CONTEXT_KEY.LIST,
          CLAIMS_T_CONTEXT_KEY.FORM,
        ]}
      />
      <div className="claim-container claim-applicaiton-list">
        <Header />

        {tabs?.length > 0 && <Main tabConfig={tabs} />}
      </div>
    </>
  );
};

export default ClaimApplicationList;
