import { useTranslation } from "react-i18next";

export const Header = ({
  headerTitleKey,
  headerDescKey,
}: {
  headerTitleKey: string;
  headerDescKey?: string;
}) => {
  const { t } = useTranslation();

  return (
    <div className="header">
      <div className="title">
        {t(headerTitleKey)}
        {headerDescKey ? (
          <div className="title-desc">{t(headerDescKey)}</div>
        ) : (
          <div className="" style={{ height: "1rem" }} />
        )}
      </div>
    </div>
  );
};
