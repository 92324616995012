import { BCType } from "../../../../../components/base-control/base-control";
import { getColumModel } from "../../../../../components/base-table/base-table-model";
import { convertApiDateFormatToPrime } from "../../../../../utils/utils";

export const DATE_FORMAT = "YYYY-MM-DD";

export const getColumns = ({ showAction, t }: any) => [
  getColumModel({
    header: t("claim_delegation_columnDelegateeEEID"),
    key: "delegateeEEID",
    dataType: "string",
    // width: "20",
    columnConfig: {
      sortable: true,
    },
  }),
  getColumModel({
    header: t("claim_delegation_columnDelegateeName"),
    key: "delegateeName",
    dataType: "string",
    // width: "20",
    columnConfig: {
      sortable: true,
    },
  }),
  getColumModel({
    header: t("claim_delegation_columnDelegateeTask"),
    key: "delegationTask",
    dataType: "string",
    // width: "20",
    columnConfig: {
      sortable: true,
    },
  }),
  getColumModel({
    header: t("claim_delegation_columnStartDate"),
    key: "startDate",
    dataType: "date",
    // width: "20",
    columnConfig: {
      dateFormat: "YYYY-MM-DD",
      sortable: true,
    },
  }),
  getColumModel({
    header: t("claim_delegation_columnEndDate"),
    key: "endDate",
    dataType: "date",
    // width: "20",
    columnConfig: {
      dateFormat: "YYYY-MM-DD",
      sortable: true,
    },
  }),
  // getColumModel({
  //   header: t("claim_delegation_columnStatus"),
  //   key: "status",
  //   dataType: "string",
  //   // width: "20",
  //   config: {
  //     sortable: true,
  //   },
  // }),
  // If show frozen action column, add dummy for BaseTable
  ...(showAction
    ? [
        getColumModel({
          header: "",
          key: "",
          dataType: "string",
          sortable: false,
          width: 0.1,
          frozen: true,
        }),
      ]
    : []),
];

// Add delegation form
export const getDelegationFormConfigStepOne = ({
  taskOptions,
  onUpdateTask,
  t,
}: any) => ({
  controls: [
    {
      required: true,
      key: "taskId",
      label: t("claim_delegation_formTaskId"),
      placeholder: t("base_control_select_choose"),
      type: BCType.select,
      enum: taskOptions,
      onTrueUpdateValue: onUpdateTask,
    },
  ],
  layout: {
    rows: [
      {
        columns: [
          {
            control: "taskId",
            config: {
              className: "p-col-12 p-lg-6 p-md-6 p-sm-12",
            },
          },
        ],
      },
    ],
  },
});

export const getDelegationFormConfigStepTwo = ({
  form,
  employeeOptions,
  taskOptions,
  onUpdateTask,
  dateFormat = "yyyy-MM-dd",
  t,
}: any) => ({
  controls: [
    {
      required: true,
      key: "taskId",
      label: t("claim_delegation_formTaskId"),
      placeholder: t("base_control_select_choose"),
      type: BCType.select,
      enum: taskOptions,
      onTrueUpdateValue: onUpdateTask,
    },
    {
      required: true,
      key: "delegateTo",
      label: t("claim_delegation_formDelegateTo"),
      placeholder: t("base_control_input_text"),
      type: BCType.select,
      enum: employeeOptions,
    },
    {
      required: true,
      key: "effectiveFrom",
      label: t("claim_delegation_formStartDate"),
      type: BCType.date,
      dateFormat: convertApiDateFormatToPrime(dateFormat),
      placeholder: dateFormat?.toUpperCase(),
      config: {
        maxDate: form?.["effectiveTo"],
        viewDate:
          form?.["effectiveTo"] &&
          form?.["effectiveTo"] instanceof Date &&
          !isNaN(form?.["effectiveTo"] as any)
            ? form?.["effectiveTo"]
            : new Date(Date.now()),
      },
    },
    {
      required: true,
      key: "effectiveTo",
      label: t("claim_delegation_formEndDate"),
      type: BCType.date,
      dateFormat: convertApiDateFormatToPrime(dateFormat),
      placeholder: dateFormat?.toUpperCase(),
      config: {
        minDate: form?.["effectiveFrom"],
        viewDate:
          form?.["effectiveFrom"] &&
          form?.["effectiveFrom"] instanceof Date &&
          !isNaN(form?.["effectiveFrom"] as any)
            ? form?.["effectiveFrom"]
            : new Date(Date.now()),
      },
    },
  ],
  layout: {
    rows: [
      {
        columns: [
          {
            control: "taskId",
            config: {
              className: "p-col-12 p-lg-6 p-md-6 p-sm-12",
            },
          },
          {
            control: "delegateTo",
            config: {
              className: "p-col-12 p-lg-6 p-md-6 p-sm-12",
            },
          },
        ],
      },
      {
        columns: [
          {
            control: "effectiveFrom",
            config: {
              className: "p-col-12 p-lg-6 p-md-6 p-sm-12",
            },
          },
          {
            control: "effectiveTo",
            config: {
              className: "p-col-12 p-lg-6 p-md-6 p-sm-12",
            },
          },
        ],
      },
    ],
  },
});
