import "./legend.scss";

interface LegendProps {
  renderLegend?: any;
  legend: "round" | "square";
  legendColor?: string;
  text?: string;
  textColor?: string;
  className?: string;
}

const SquareSVG = (props: any) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1 1" {...props}>
    <path d="M0 0v1h1V0" />
  </svg>
);

const Legend = (props: LegendProps) => {
  if (props.renderLegend) {
    return <div className="legend-icon">{props.renderLegend()}</div>;
  }

  if (props.legend === "square") {
    return (
      <span className="legend-icon legend-square">
        <SquareSVG fill={props.legendColor} />
      </span>
    );
  }

  const style = props.legendColor ? { color: props.legendColor } : {};
  return (
    <span
      style={style}
      className="legend-icon legend-round pi pi-circle-on"
    ></span>
  );
};

const LegendText = (props: LegendProps) => {
  const style = props.textColor ? { color: props.textColor } : {};
  return (
    <span className="legend-text" style={style}>
      {props.text}
    </span>
  );
};

export const ClaimLegend = (props: LegendProps) => {
  return (
    <div className={`custom-legend ${props.className || ""}`}>
      <Legend {...props} />
      <LegendText {...props} />
    </div>
  );
};
