import { CLAIMS_WORKFLOW_STATUS } from "../../constants";

export const TableFilterStatuses = [
  {
    label: "claims_approval_filter_pendingApproval",
    value: CLAIMS_WORKFLOW_STATUS.IN_PROGRESS,
    isClientSize: true,
  },
  {
    label: "claims_approval_filter_approvedApplications",
    value: CLAIMS_WORKFLOW_STATUS.COMPLETE,
    isClientSize: true,
  },
  {
    label: "claims_approval_filter_rejectedApplications",
    value: CLAIMS_WORKFLOW_STATUS.REJECT,
    isClientSize: true,
  },
  {
    label: "claims_approval_filter_all",
    value: -1,
    isClientSize: true,
  },
];
