import BaseForm from "../../../../../components/base-form/base-form";
import { useTimesheetConfigurationContext } from "../context/useTimesheetConfigurationContext";

export const CostCenterPanel: React.FC = () => {
  const { formProps, formControls, formLayout } =
    useTimesheetConfigurationContext();

  return (
    <div className="cost-center-panel-container panel-container">
      <div className="cost-center-form">
        {formControls?.length && formLayout && <BaseForm {...formProps} />}
      </div>
    </div>
  );
};
